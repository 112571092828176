import { memo, useMemo, useState, useEffect } from "react";
import moment from "moment";
import { ethers } from "ethers";
import styled from "styled-components";
import Tabs from "../../layout/tabs";
import AssetsToSupply from "./assets2supply";
import MySupplies from "./MySupplies";
import AssetsToBorrow from "./AssetsToBorrow";
import MyBorrow from "./MyBorrow";
import BackNav from "components/common/backNav";
import Dashboard from "./dashboard";
import { useWeb3React } from "@web3-react/core";
import { useAaveActionContract } from "hooks/useContract";
import { useNetworkData } from "hooks/useNetwork";
import { useDaoContext } from "views/dao/provider";
import { useDefiContext } from "components/provider/defiProvider";
import { VAULT_MANAGER } from "utils/constant";

import { GraphQLClient } from "graphql-request";
import { getShortDisplay } from "utils/publicJs";
import {
  getBalance,
  getBalanceInUSD,
  getSupplyAPY,
  getVariableBorrowAPY,
  getStableBorrowAPY,
  getvariableBorrows,
  getstableBorrows,
} from "utils/aave";
import { Query_All_Reserves, Query_User_Reserves } from "api/graphql/aave";
import BigNumber from "bignumber.js";

import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import { useTranslation } from "react-i18next";
import { GlassBG } from "assets/styles/common";

const DefiContainer = () => {
  const { chainId } = useWeb3React();
  const { t } = useTranslation();

  const {
    state: { client },
    dispatch,
  } = useDefiContext();

  const networkData = useNetworkData(chainId);
  const contract = useAaveActionContract(
    networkData && networkData.mainAddress.actionVaultAave
  );

  const {
    state: { daoId, componentAddressMap, reqState },
  } = useDaoContext();

  const vaultAddress = useMemo(() => {
    return componentAddressMap.get(VAULT_MANAGER);
  }, [componentAddressMap]);

  const [current, setCurrent] = useState(0);

  const nav = useMemo(() => {
    return [t("defi.tab1"), t("defi.tab2"), t("defi.tab3"), t("defi.tab4")];
  }, []);

  const getUserData = async () => {
    if (!contract || !networkData || !vaultAddress) {
      return;
    }
    const pool = networkData.aaveLendingPool;
    if (!pool) {
      return;
    }
    try {
      const data = await contract.getUserAccountData(
        pool,
        vaultAddress.toLowerCase()
      );
      dispatch({
        type: "SET_USER_DATA",
        payload: data,
      });
    } catch (error) {
      console.error("aave getUserAccountData failed", error);
    }
  }

  useEffect(async () => {
    getUserData();
  }, [networkData, contract, vaultAddress]);

  // get all reserves
  const getAllReveres = async () => {
    if (!client || !networkData || !networkData.aaveLendingPoolProvider) {
      return;
    }
    try {
      const resp = await client.request(Query_All_Reserves, {
        provider: networkData.aaveLendingPoolProvider,
      });
      const usdPriceEth = resp.priceOracles[0].usdPriceEth;
      dispatch({ type: "SET_USD_PRICE_ETH", payload: usdPriceEth });
      const reserves = [];
      resp.reserves.forEach((r) => {
        if (r.isActive && !r.isFrozen) {
          // borrow
          let variableBorrowAPY = getVariableBorrowAPY(
            r.variableBorrowRate
          ).toNumber();
          if (isNaN(variableBorrowAPY)) {
            variableBorrowAPY = 0;
          }
          let stableBorrowAPY = getStableBorrowAPY(
            r.stableBorrowRate
          ).toNumber();
          if (isNaN(stableBorrowAPY)) {
            stableBorrowAPY = 0;
          }
          // supply
          const supplyAPY = getSupplyAPY(r.liquidityRate).toNumber();
          let wtoken = {}
          if (r.underlyingAsset === networkData.wrappedToken) {
            wtoken = networkData.nativeCurrency;
          }
          reserves.push({
            ...r,
            ...wtoken,
            logo:
              wtoken?.logoURI ||
              `https://app.aave.com/icons/tokens/${r.symbol.toLowerCase()}.svg`,
            supplyAPY,
            variableBorrowAPY,
            stableBorrowAPY,
          });
        }
      });
      dispatch({ type: "SET_RESERVES", payload: reserves });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    if (reqState === 2) {
      return;
    }
    getAllReveres();
  }, [client, networkData, reqState]);

  // get user reserves
  const getUserReveres = async () => {
    if (!client || !vaultAddress) {
      return;
    }
    try {
      const resp = await client.request(Query_User_Reserves, {
        user: vaultAddress.toLowerCase(),
      });

      let positiveProportion = BigNumber(0);
      let negativeProportion = BigNumber(0);

      let totalSupplyInUsd = BigNumber(0);
      let totalDebtInUsd = BigNumber(0);

      const userDebtReserves = [];
      const userSupplyReserves = [];

      resp.userReserves.forEach((ur) => {
        const r = ur.reserve;

        let variableBorrowAPY;
        let stableBorrowAPY;
        let supplyAPY = 0;

        const usdPriceEth = r.price.oracle.usdPriceEth;
        const priceInEth = r.price.priceInEth;

        let amountBN;
        let amountInUsdBN;
        let balanceInUsdVal;
        let borrowData = {};
        if (ur.currentTotalDebt !== "0") {
          // borrow
          variableBorrowAPY = getVariableBorrowAPY(r.variableBorrowRate);
          stableBorrowAPY = getStableBorrowAPY(r.stableBorrowRate);

          const variableBorrows = getvariableBorrows(ur);
          const stableBorrows = getstableBorrows(ur);
          const mod = variableBorrows.gt(0) > 0 ? 2 : 1;
          borrowData = { variableBorrows, stableBorrows, mod };

          amountBN = variableBorrows.gt(0) ? variableBorrows : stableBorrows;
          amountInUsdBN = getBalanceInUSD(usdPriceEth, priceInEth, amountBN);

          balanceInUsdVal = amountInUsdBN.shiftedBy(-r.decimals);

          if (variableBorrowAPY.gt(0)) {
            negativeProportion = negativeProportion.plus(
              variableBorrowAPY.multipliedBy(balanceInUsdVal)
            );
          } else {
            negativeProportion = negativeProportion.plus(
              stableBorrowAPY.multipliedBy(balanceInUsdVal)
            );
          }

          totalDebtInUsd = totalDebtInUsd.plus(balanceInUsdVal);
          let logo = `https://app.aave.com/icons/tokens/${r.symbol.toLowerCase()}.svg`;
          if (ur.reserve.underlyingAsset === networkData.wrappedToken) {
            ur.reserve.name = networkData.nativeCurrency.name;
            ur.reserve.symbol = networkData.nativeCurrency.symbol;
            if (networkData.nativeCurrency.logoURI) {
              logo = networkData.nativeCurrency.logoURI;
            }
          }
          userDebtReserves.push({
            ...ur,
            logo,
            amountBN,
            balance: amountBN.shiftedBy(-r.decimals).toString(),
            amountInUsdBN,
            balanceInUsdVal: balanceInUsdVal.toNumber(),
            supplyAPY,
            variableBorrowAPY,
            stableBorrowAPY,
            ...borrowData,
          });
        }
        if (ur.currentATokenBalance !== "0") {
          // supply
          supplyAPY = getSupplyAPY(r.liquidityRate);

          amountBN = getBalance(ur, moment().unix(), r.lastUpdateTimestamp);
          amountInUsdBN = getBalanceInUSD(usdPriceEth, priceInEth, amountBN);
          balanceInUsdVal = amountInUsdBN.shiftedBy(-r.decimals);

          totalSupplyInUsd = totalSupplyInUsd.plus(balanceInUsdVal);
          positiveProportion = positiveProportion.plus(
            supplyAPY.multipliedBy(balanceInUsdVal)
          );
          let logo = `https://app.aave.com/icons/tokens/${r.symbol.toLowerCase()}.svg`
          if (ur.reserve.underlyingAsset === networkData.wrappedToken) {
            ur.reserve.name = networkData.nativeCurrency.name;
            ur.reserve.symbol = networkData.nativeCurrency.symbol;
            if (networkData.nativeCurrency.logoURI) {
              logo = networkData.nativeCurrency.logoURI;
            }
          }
          userSupplyReserves.push({
            ...ur,
            logo,
            amountBN,
            balance: amountBN.shiftedBy(-r.decimals).toString(),
            amountInUsdBN,
            balanceInUsdVal: balanceInUsdVal.toNumber(),
            supplyAPY: supplyAPY.toNumber(),
            variableBorrowAPY,
            stableBorrowAPY,
            ...borrowData,
          });
        }
      });

      const earnedAPY = positiveProportion
        .dividedBy(totalSupplyInUsd)
        .toNumber();
      const debtAPY = negativeProportion.dividedBy(totalDebtInUsd).toNumber();

      dispatch({
        type: "SET_USER_APY",
        payload: {
          totalSupplyInUsd: totalSupplyInUsd.toNumber(),
          totalDebtInUsd: totalDebtInUsd.toNumber(),
          earnedAPY,
          debtAPY,
        },
      });
      console.log("****userDebtReserves", userDebtReserves);
      console.log("****userSupplyReserves", userSupplyReserves);
      dispatch({ type: "SET_USER_DEBT_RESERVES", payload: userDebtReserves });
      dispatch({
        type: "SET_USER_SUPPLY_RESERVES",
        payload: userSupplyReserves,
      });

      dispatch({
        type: "SET_USER_USD_DATA",
        payload: {
          totalCollateralUsdVal: totalSupplyInUsd,
          totalDebtUsdVal: totalDebtInUsd,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    if (reqState === 2) {
      return;
    }
    getUserReveres();
  }, [client, vaultAddress, reqState]);

  useEffect(() => {
    if (!networkData || !networkData.aaveGraph) {
      return;
    }
    const clt = new GraphQLClient(networkData.aaveGraph);
    dispatch({ type: "SET_CLIENT", payload: clt });
  }, [networkData]);

  useSubcribe(TransactionEvent.Defi, async (_, { daoId: _daoId }) => {
    if (daoId === _daoId) {
      if (reqState === 2) {
        return;
      }
      dispatch({ type: "SET_REQUEST_STATE", payload: 2 });
      await Promise.all([getAllReveres(), getUserReveres(), getUserData()]);
      dispatch({ type: "SET_REQUEST_STATE", payload: 0 });
    }
  });

  const getTabContent = useMemo(() => {
    switch (current) {
      case 0:
        return <AssetsToSupply />;
      case 1:
        return <MySupplies />;
      case 2:
        return <AssetsToBorrow />;
      case 3:
        return <MyBorrow />;
      default:
        return <></>;
    }
  }, [current]);

  return (
    <Box>
      <TopBox>
        <BackNav to="app_settings" />
      </TopBox>
      <ContentBox id="contentBox">
          <TopPadding>
            <GlassBG />
            <Collection>{t("defi.tips")}</Collection>
            <Dashboard />
          </TopPadding>
          <div className="bottomBox">
            <GlassBG />
            <FirstLine>
              <Tabs
                navList={nav}
                current={current}
                handleCurrent={setCurrent}
              />
            </FirstLine>
            <Content>{getTabContent}</Content>
          </div>
      </ContentBox>
    </Box>
  );
};
export default DefiContainer;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 2px 0;
  position: relative;
`;

const Content = styled.div`
  //flex: 1;
  //height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const TopBox = styled.div``;

const ContentBox = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  flex: 1;
  height: 100%;
  position: relative;
 
  .bottomBox {
    margin-top: 16px;
    position: relative;
    min-height: calc(100% - 143px);
  }
 
`;
const Collection = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 0 0 30px;
  position: relative;
  @media (max-width: 1440px) {
    font-size: 14px;
    padding-bottom: 20px;
  }
`;
const TopPadding = styled.div`
  padding: 25px 30px;
  position: relative;
  @media (max-width: 1440px) {
    padding: 15px 20px;
  }
`;
