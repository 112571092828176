import mainAddress from "./address";
import ETH from "../../assets/chain/ETH.svg";
import etherscan from "img/network/etherscan.png";
import Dapps from "../dapps";

export default {
  opensea: "https://opensea.io/assets/ethereum/",
  name: "Ethereum",
  simple: "ethereum",
  mainAddress: mainAddress,
  serverApi: "https://api.subdao.network/v1/query",
  daos: {
    url: "https://api.gitrows.com/@github/SubDAO-Network/daos/ethereum.json",
    key: "testnet",
  },
  // aave
  aaveGraph: "https://api.thegraph.com/subgraphs/name/aave/protocol-v2",
  aaveLendingPool: "0x7d2768de32b0b80b7a3454c06bdac94a69ddc7a9",
  aaveLendingPoolProvider: "0xb53c1a33016b2dc2ff3653530bff1848a515c8c5",
  multicall: "0x507988A12348C592D89fbed4855472c501aAd454",
  swapRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  swapRouterV3: "0xE592427A0AEce92De3Edee1F18E0157C05861564",
  // nft
  nftName: "Gem",
  nftLink: "https://www.gem.xyz/",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  multiSignDefaultSupportTokens: ["0xdac17f958d2ee523a2206206994597c13d831ec7"],
  tokenLogo: ETH,
  scan: {
    img: etherscan,
    host: "https://etherscan.io/",
  },
  chainId: 1,
  chainName: "Ethereum",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
    logoURI: "https://app.aave.com/icons/networks/ethereum.svg",
  },
  wrappedToken: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  logo: ETH,
  rpcUrls: ["https://cloudflare-eth.com", "https://rpc.flashbots.net/"],
  blockExplorerUrls: ["https://etherscan.io"],
  walletConnectDapps: [
    {
      name: "Uniswap",
      link: "https://app.uniswap.org/#/swap",
      logo: Dapps.Uniswap,
    },
    {
      name: "AAVE",
      link: "https://app.aave.com/",
      logo: Dapps.AAVE,
    },
    {
      name: "Compound",
      link: "https://app.compound.finance/?market=1",
      logo: Dapps.Compound,
    },
    {
      name: "Sushi",
      link: "https://app.sushi.com/swap?chainId=1",
      logo: Dapps.Sushi,
    },
    {
      name: "Curve",
      link: "https://curve.fi/#/ethereum/swap",
      logo: Dapps.Curve,
    },
    {
      name: "Instadapp",
      link: "https://defi.instadapp.io/",
      logo: Dapps.Instadapp,
    },
    {
      name: "Balancer",
      link: "https://app.balancer.fi/",
      logo: Dapps.Balancer,
    },
    {
      name: "1inch Network",
      link: "https://app.1inch.io/",
      logo: Dapps.Inch,
    },
    {
      name: "ENS",
      link: "https://app.ens.domains/",
      logo: Dapps.ENS,
    },
    {
      name: "Snapshot",
      link: "https://snapshot.org/#/",
      logo: Dapps.Snapshot,
    },
    {
      name: "Lido",
      link: "https://stake.lido.fi/",
      logo: Dapps.LIDO,
    },
  ],
};
