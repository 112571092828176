import React from "react";
import Queue from "./queue";
import History from "./history";
import VoteDetail from "./detail";
import { Navigate, Routes, Route } from "react-router-dom";


export default function Portfolio() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="queue" />} />
      <Route path="queue" element={<Queue />} />
      <Route path="history" element={<History />} />
      <Route path=":id" element={<VoteDetail />} />

    </Routes>
  );
}

