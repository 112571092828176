import { BigNumber } from "ethers";

const modifiedDaoBase = async (contract, signer, daoid, data) => {
  const res = await contract
    .connect(signer)
    .editDaoInfo(daoid, data.name, data.logo, data.desc, data.socialUrls);
  await res.wait();
};

const getDaoBaseInfo = async (contract, id) => {
  return await contract.instanceMap(BigNumber.from(id));
};

export default {
  getDaoBaseInfo,
  modifiedDaoBase,
};
