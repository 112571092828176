export default {
  "DaoFactory": "0x596d75157C306dD7Eb97CdE12F0d94338aE64074",
  "actionGnosisConfirmProposal": "0xB31ca1Ff22B1F167f8766A529307BD22B722AF44",
  "actionGnosisSubmitProposal": "0xF5968FAB3C05e75bF8fF37821C31Dcf558D12f2d",
  "actionGrantMethodApply": "0x4fC6AA6704bd039eAeC06c990c7cFF3845696ee0",
  "actionGrantMethodRegister": "0xfaD81CDdcF91A39803327d7682a38BC94422fa0E",
  "actionGrantMethodVoteNewAuth": "0x84ff18A4f57CEdfAe60c263110740b6Dc5f9bf33",
  "actionOrgApplyJoin": "0x099539064395E12d94A2D2c540342DeFb014d3bd",
  "actionOrgMemberJoin": "0xEB6ED8f9E864a6694C2bdd36eFBc196B078be4a1",
  "actionOrgMemberQuit": "0x8B71502Db66e61B0e28de61D5FF6c02E7E000d45",
  "actionOrgMgr": "0x675fbb3fD9A3f55Ca34102AEbB369DE3482BB369",
  "actionOrgSetJoinMode": "0x56613b69e063E69933Ae7180788f9a946f13C77e",
  "actionOrgTransferOwner": "0xffa5a8712D9db147037677CCc22b7282B602686A",
  "actionOrgUpdateMembers": "0x1Ce1f02166dEa02b874C4F4327553Af6FB4a45C3",
  "actionVaultAddNFT": "0x1d180932D58A114065Fe0eD0C03054854D16d969",
  "actionVaultDepositToken": "0x03bFe1A536eFaA604958e53E5c1EBe07e6B268e5",
  "actionVaultExeWithdrawToken": "0x7de5795944C2264d3d5710D4F3Fc399372CAa858",
  "actionVaultGPAuthorize": "0x94Ac8867b625F3c5cE72E83E9A95220Bd2D75BD6",
  "actionVaultOptToken": "0x6bB5f77A39F22E1a51a0641ead8da2e713A93bB3",
  "actionVaultWithdrawToken": "0xA1759fA6730b32E1059AC3a5c2Bfd86F340F0C13",
  "actionVenturesClaimBonus": "0xf83f7217930CAFd0c7D986Ed9e2ae70D47B2A4FF",
  "actionVenturesClaimShare": "0x84162eC511C9D367A1c5D59b706E5B93AFaF7e07",
  "actionVenturesRaiseFailClaim": "0x320DA15C16D3E54f566bc0f4DEb35CC47f0b474c",
  "actionVenturesRaiseMoney": "0x320B99A4bc0ce3F7c36b8EB6BE81B729789Ca237",
  "actionVenturesTestToSettlementPeriod": "0x80c335248185E3d205CC8b6cF074D3862e320089",
  "actionVenturesToFreezingPeriod": "0x1488F56e91E23E6323A5116fF12b92EF49B8D6d3",
  "actionVenturesToRaiseFail": "0xe90C5fECDf0A8eA1116e0f870Ad3B08aD0C729CF",
  "actionVoteAcceptGpApply": "0x92D2BbC43D1f632594557fB57E141a56d7820dd6",
  "actionVoteAcceptGpRegister": "0x81D7ED87F8cBaD781A3E7CF63E9BAbb49a69E10B",
  "actionVoteCancel": "0xA05db94D27AfF41a129e87C71a213D2D9F446A8A",
  "actionVoteExecute": "0x3752F01B84E0638465f8925f504208D421b89238",
  "actionVoteImpeachApply": "0x51195cbdD3eadbCCF493A2aE23B0DFdAD94B8800",
  "actionVoteImpeachRegister": "0x50323A462079d55C532940951a8Ae5729aD9B8d5",
  "actionVoteNew": "0x5f3e13C4CE9141Daf8f661297229fBeD0A870FCb",
  "actionVoteTransferManageFeeApply": "0x6D9217120c94F0ce923fb5FdE92d5668d189E8a9",
  "actionVoteTransferManageFeeRegister": "0xA0e92Fc2A0E975D78709D60928AA08379E00a138",
  "actionVoteTransferNFTApply": "0xd9a0d1f302C4Caa291e59954029A4559fe27EAf3",
  "actionVoteTransferNFTRegister": "0x9d0aAD3f9C6989837Bcf02732100FCb07ad383F5",
  "actionVoteTransferPeriodApply": "0xC8A84aab1c26512AE8615ae9a81C42858F50b3A2",
  "actionVoteTransferPeriodRegister": "0x6425016a92508Bc1F0e2a58E91e7A4C0Bf4e9257",
  "actionVoteVote": "0xA805A7cE343d8822d5C168c0BA2326e807C7e9E5",
  "actionVaultUniswapV2Router02Swap": "0x4DF185Dd6592B36fac21b2C3b05adb09b30Fb727",
  "actionVenturesSetRaiseSubTime": "0xb6bD16133c892f1cD462f8438cf244D596e82837",
  "actionVenturesVestingWithdraw": "0xA04c128F7a60177691dE684703b89243941D9A16",
  "actionGrantMethodExe": "0x5A432bcc9E0AFfB11322589Ec357bF70AeE5B853",
  "actionManageFeeTransferExe": "0x43E18793c724173f5A97c5Abfb43374F2CDd04A4",
  "actionOrgAcceptGPExe": "0xc6960947D49ae82DBb80f40c0FD350102A1C619F",
  "actionOrgImpeachGPExe": "0xAd8Dc273655905CF52dDf2CeeeA136b96FE4Cc75",
  "actionVaultTransferExe": "0x298f269A50b79Af004a0Fe33c5f24B2dD914c734",
  "actionVaultTransferNFTExe": "0x6ace4E116dEB17fDd15401145397EbeC566cf420",
  "actionVentureTransferPeriodExe": "0x77f2270D09E4ccB0997d51978dC7Df2025A620dC",
  "actionOrgUpdateMinRequireExe": "0xD47ebF868a2aE523A6762191F27088c477dC1829"
};
