import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import SearchImg from "../../assets/images/Search.svg";

import Button from "components/common/button";
import MyToken from "./myToken";
import SearchTab from "./tables/searchTab";

import AllDao from "./tables/all";
import LikeDao from "./tables/likedao";
import MyDao from "./tables/mydao";
import TrendingDao from "./tables/trending";
import useCheckLogin from "hooks/useCheckLogin";
import { useAppContext } from "components/provider/appProvider";
import Headertop from "../../components/header";
import BgMP4 from "../../assets/mp4/video.mp4";
import SelectBox from "../../components/new/selectBox";
import AnimationBg from "components/animation";

export default function Explore() {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();

  const isLogin = useCheckLogin();
  const {
    dispatch,
    state: { gateway},
  } = useAppContext();
  const [showBg, setShowBg] = useState(false);

  const tabsList = useMemo(() => {
    return [
      t("Explore.All"),
      t("Explore.Trending"),
      t("Explore.MyDAO"),
      t("Explore.Liked"),
      t("Explore.MyTokens"),
    ];
  }, []);

  useEffect(()=>{
    if (showBg) {
      document.body.setAttribute('style', "overflow: hidden;")
    } else {
      document.body.removeAttribute('style')
    }
  },[showBg])

  const [currentTab, setCurrentTab] = useState(0);
  const [searchVal, setSearchVal] = useState();

  useEffect(() => {
    const queryVal = searchParams.get("search");
    if (queryVal) {
      setSearchVal(queryVal);
      return;
    }
    const tabVal = searchParams.get("tab");
    if (tabVal === "tokens") {
      setCurrentTab(4);
    }
  }, [searchParams]);

  const changeTab = useCallback((num) => {
    if (!isLogin && num !== 0) {
      dispatch({ type: "CONNECT_WALLET", payload: true });
      return
    }
    setCurrentTab(num);
  }, [isLogin]);

  const go2create = useCallback(() => {
    if (!isLogin) {
      dispatch({ type: "CONNECT_WALLET", payload: true });
      return
    }
    // navigator("/create");
    setShowBg(true)
  }, [isLogin]);

  const showTab = useMemo(() => {
    switch (currentTab) {
      case 0:
        return <AllDao gateway={gateway} />;
      case 1:
        return <TrendingDao />;
      case 2:
        return <MyDao gateway={gateway} />;
      case 3:
        return <LikeDao gateway={gateway} />;
      case 4:
        return <MyToken gateway={gateway} />;
      default:
        return <></>
    }
  }, [currentTab, gateway]);

  const onChangeSearch = useCallback(({ target: { value } }) => {
    setSearchVal(value);
  }, []);

  return (<Box>
      {showBg && (
        <AnimationBg step={2} handleClose={() => setShowBg(false)} state="/explore" />
      )}
        <HeaderBox>
          <Headertop />
        </HeaderBox>
        <MainContent>
          <MainTop>

            {/*<SearchLine>*/}
            {/*  <div className="searchBox">*/}
            {/*    <input type="text" placeholder={t("Header.Search")} onChange={handleKeyword}   onPressEnter={go2Search}/>*/}
            {/*    <img src={SearchImg} alt="" onClick={go2Search} />*/}
            {/*  </div>*/}
            {/*  <CreateBtn onClick={()=>ShowAnimation()} data-nosnippet>{t("CreateDao")}</CreateBtn>*/}
            {/*</SearchLine>*/}

        <SearchLine>
          <div className="searchBox">
            <input type="text" value={searchVal} onChange={onChangeSearch} placeholder={t("Explore.placeholder")} />
            <img src={SearchImg} alt="" />
          </div>
          <CreateBtn
            primary
            className="btnRed"
            onClick={go2create}
            width={235}
            height={56}
          >
            <span className="font-medium">{t("Explore.Create")}</span>
          </CreateBtn>
        </SearchLine>
        <ContentBox id="contentBox">
          {searchVal ? (
            <div id="inner">
              <SearchTab keyword={searchVal} gateway={gateway}/>
            </div>
          ) : (
            <>
              <TabLine>
                <TabBox>
                  {tabsList.map((item, index) => (
                      <li
                          key={index}
                          className={currentTab === index ? "active font-medium" : ""}
                          onClick={() => changeTab(index)}
                      >
                        {item}
                        <div className="line" />
                      </li>
                  ))}
                </TabBox>
                {/*<SelectBox />*/}
              </TabLine>

              <div id="inner">{showTab}</div>
            </>
          )}
        </ContentBox>
          </MainTop>
        </MainContent>
        <BrdrBox>
          <VideoBox>
            <video src={BgMP4} autoPlay loop muted />
          </VideoBox>
        </BrdrBox>
      </Box>

  );
}

const Box = styled.div`
  width: 100%;
  position: relative;
`;
const HeaderBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
`;

const MainContent = styled.div`
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: 70px;
    z-index: 9;
  width: 100%;
`;

const MainTop = styled.div`
  width: 1240px;
  margin: 0 auto;
`

const BrdrBox = styled.div`
    width: 100vw;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
`;
const VideoBox = styled.div`
    width: 1920px;
    margin-left: calc((100vw - 1920px) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    video {
        width: 100%;
    }
`;

const SearchLine = styled.div`
  margin: 140px 0 90px;
  .searchBox {
    width: 604px;
    height: 56px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 24px;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2);
      img {
        opacity: 1;
      }
    }
  }
  input {
    height: 54px;
    flex-grow: 1;
    background: transparent;
    border: 0;
    color: #ffffff;
    font-size: 18px;

    &::placeholder {
      opacity: 0.3;
      font-size: 18px;
    }
  }
  img {
    width: 24px;
    height: 24px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.5;
  }
`;

const CreateBtn = styled(Button)`
  margin-top: 28px;
`;

const TabBox = styled.ul`
  display: flex;
  cursor: pointer;
  li{
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    opacity: 0.5;
    min-width: 48px;
    text-align: center;
    width: 128px;
    .line{
      background: transparent;
      border-radius: 3px;
      width: 48px;
      margin: 5px auto 0;
      height: 3px;
    }
    &:last-child{
      margin-right: 40px;
    }
    &.active{
      opacity: 1;
      .line{
        background: linear-gradient(123deg, #61E1FF 0%, #326CFF 100%);
        border-radius: 30px;
      }
    }
  }
`;

const ContentBox = styled.div`
  width: 100%;
  #inner {
    width: 100%;
  }
`;

const TabLine = styled.div`
display: flex;
  align-items: center;
`
