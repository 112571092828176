import { memo } from "react";
import styled from "styled-components";
import General from "components/modal/general";
import Button, {ButtonGroup} from "components/common/button";
import { useTranslation } from "react-i18next";
import CloseDate from "components/common/closeDate";
import { useState } from "react";
import { useAppContext } from "components/provider/appProvider";

const EditDateModal = memo(({ show, oldDate, closeModal, submit }) => {
  const { t } = useTranslation();
  const { dispatch } = useAppContext();
  const [targetTime, setTargetTime] = useState(oldDate);

  const onClickSubmit = async () => {
    if (targetTime < Date.now()) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Period.EditDateError") },
      });
      return;
    }
    try {
      await submit(Math.ceil(targetTime / 1000));
      closeModal();
    } catch (error) {
      console.error("submit new date failed", error);
    }
  };
  return (
    <General
      show={show}
      width="358"
      height="262"
      closeModal={closeModal}
      glass
      title={t("Period.EditDate")}
    >
      <ModalBody>
        <Label>{t("Period.CloseDate")}</Label>
        <CloseDate
          notUseQuick={true}
          targetTime={targetTime}
          updateTargetTime={setTargetTime}
        />
        <ButtonGroup style={{ marginTop: "40px" }}>
          <Button onClick={closeModal}>{t("Cancel")}</Button>
          <Button primary onClick={onClickSubmit}>
            {t("Submit")}
          </Button>
        </ButtonGroup>
      </ModalBody>
    </General>
  );
});

export default EditDateModal;


const ModalBody = styled.div`
  padding-top: 22px;
  text-align: left;
`;

const Label = styled.div`
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 10px;
  opacity: 0.5;
  text-align: left;
`;
