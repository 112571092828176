import styled, { css } from "styled-components";
import Loading from "assets/gif/Loading.gif";
import { useTranslation } from "react-i18next";

const LoadingBox = ({ loading, type, pure, ...rest }) => {
  const { t } = useTranslation();
  return loading ? (
    <LoadingBoxStyled {...rest}>
      <LoadingWrapper type={type} pure={pure}>
        <img src={Loading} alt="" />
        <span>{t("Loading")}</span>
      </LoadingWrapper>
    </LoadingBoxStyled>
  ) : (
    <></>
  );
};

export default LoadingBox;

const LoadingBoxStyled = styled.div`
  width: 100%;
  /* min-height: 300px; */
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
`;

const DefaultBg = css`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(32px);
`;

const DataBg = css`
  background: rgba(36, 53, 79, 0.97);
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 150px;
  border-radius: 18px;
  ${({ type, pure }) => (type === "data" ? DataBg : !pure && DefaultBg)};
  img {
    width: 63px;
    margin-bottom: 15px;
  }
  span {
    color: rgba(255, 255, 255, 0.5);
  }
`;
