import { memo, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { InputNumberItem } from "components/common/inputComp";

const VoteConfigBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .vote-tip {
    margin-right: 10px;
  }
  .vote-end {
    margin-left: 10px;
  }
  .wrapper {
    padding: 0 10px;
    .ant-input-number-input {
      text-align: center;
    }
  }
`;

const VentureVoteConfig = memo((props) => {
  const { t } = useTranslation();
  const { minSupportNumber, updateMinSupportNumber, gpLength, ...rest } = props;

  return (
    <VoteConfigBox className="line">
      <span className="vote-tip">{t("Vote.VoteConfigTip")}</span>
      <InputNumberItem
        width="50px"
        className="min-support"
        name="minSupportNumber"
        min={1}
        controls={false}
        value={minSupportNumber}
        onChange={(v) => updateMinSupportNumber(Math.floor(v))}
        {...rest}
      />
      <span className="vote-end">
        {t("Vote.VoteConfigNumber", { gp: gpLength })}
      </span>
    </VoteConfigBox>
  );
});
export default VentureVoteConfig;

// set min vote time to 1min
export const getValidVoteTime = (duation) => {
  let duration = Math.round(duation / 1000);
  if (duration < 60) {
    duration = 60;
  }
  return duration;
};
