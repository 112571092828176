import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import TxDetail from "./TxDetail";
import BackNav from "components/common/backNav";
import { SETTINGS_KEY } from "views/appAndsettings";
import ConnectInput from "./connectInput";

import Tabs from "../../layout/tabs";
import TxQueue from "./WCT/txQueue";
import TxHistory from "./WCT/txHistory";
import Dapps from "./WCT/dapps";
import {CONNECTION_STATUS} from "../../../utils/constant";
import WalletConnectDefault from "./walletConnect";
import { useWCTContext } from "../../provider/WCTProvider";
import { SubgraphApiQuery, checkWCTlist } from "api/graphql/subQuery";
import LoadingBox from "components/common/loading";
import { useClientContext } from "components/provider/clientProvider";
import { useDaoContext } from "views/dao/provider";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import { useNetworkData } from "hooks/useNetwork";
import { GlassBG } from "assets/styles/common";

export default function SettingList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const txId = query.get("tx");
  const txHash = query.get("txHash");

  const tab = query.get("tab");

  const {
    state: { loading },
  } = useWCTContext();

  const { chainId } = useWeb3React();
  const network = useNetworkData(chainId);

  const [current, setCurrent] = useState(0);
  const { getClient } = useClientContext();
  const { state: { daoId, daoChainId } } = useDaoContext();
  const [showDefault, setShowDefault] = useState(true);

  useEffect(() => {
    if (tab === "queue") {
      setCurrent(1);
    } else if (tab === "history") {
      setCurrent(2);
    }
  }, [tab]);


  const backTo = useMemo(() => {
    return txId ? `app/${SETTINGS_KEY.WCT}` : "app_settings";
  }, [txId]);

  const nav = useMemo(() => {
    return ["dapps", "queue", "history"];
  }, []);
  const navList = useMemo(() => {
    return [t("WCT.Dapps"), t("WCT.Queue"), t("WCT.History")];
  }, []);

  const handleCurrent = (num) => {
    setCurrent(num);
    navigate(location.pathname + `?tab=${nav[num]}`);
  };
  const {
    state: { status: connectionStatus, wcClientData },
  } = useWCTContext();

  // useEffect(async () => {
  //   if (!txId && daoChainId) {
  //     try {
  //       const client = getClient(daoChainId);
  //       const resp = await client.request(SubgraphApiQuery, {
  //         chainId: daoChainId,
  //         query: checkWCTlist(daoId, 1, 1000, network?.nftName),
  //       })
  //       const data = JSON.parse(resp.subgraphApi);
  //       const lst = data.data.transactionRecords || [];
  //       if (lst.length) {
  //         setShowDefault(false)
  //       }
  //     } catch (error) {
        
  //     }
  //   }
  // }, [txId, daoChainId])

  // if ( !txId && (showDefault && !(connectionStatus === CONNECTION_STATUS.CONNECTED && wcClientData))) {
  //   return <WalletConnectDefault />;
  // }
  return (
    <Box>
      {/* <BackNav to={backTo} state={txId ? "" : nav[current]} /> */}
      {/* <ContentBox id="contentBox"> */}
      {txId ? (
        <TxDetail id={txId} txHash={txHash} />
      ) : (
        <TableContent>
          <ConnectInput />
          <Container>
            {/* <GlassBG /> */}
            <FirstLine>
              <Tabs
                navList={navList}
                current={current}
                handleCurrent={handleCurrent}
              />
            </FirstLine>
            {current === 0 && <Dapps network={network} />}
            {current === 1 && <TxQueue />}
            {current === 2 && <TxHistory />}
          </Container>
        </TableContent>
      )}
      {/* </ContentBox> */}
      <LoadingStyle loading={loading} type="data" />
    </Box>
  );
}
const Box = styled.div`
  position: relative;
  height: 100%;
`;

const ContentBox = styled.div`
  height: calc(100% - 56px);
`;

const TableContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  position: relative;
  margin-bottom: 20px;
  & > ul {
    padding: 0;
  }
`;

const Container = styled.div`
  position: relative;
  min-height: calc(100% - 134px);
`;

const LoadingStyle = styled(LoadingBox)`
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
`;