import { ethers } from "ethers";
import { valueToBigNumber } from "@aave/math-utils";
import { LOCALSTORAGE } from "./constant";
import moment from "moment";

export const getVoteIdByEvent = (event) => {
  const data = event.data;
  const ABI = [
    "event StartVote(uint256 voteId, address creator, uint256 startTime, uint256 voteTime, string metadata)",
  ];
  try {
    const iface = new ethers.utils.Interface(ABI);
    const r = iface.decodeEventLog("StartVote", data);
    if (r) {
      return r.voteId.toNumber();
    }
  } catch (error) {
    console.error("parse vote event data failed", error);
    console.warn("parse vote event data failed: ", data);
  }
};

export const getTxIdFromEvent = (event) => {
  return parseInt(event.topics[2]);
}

export const parseVoteEvent = (event) => {
  const data = event.data;
  const ABI = [
    "event StartVote(uint256 voteId, address creator, uint256 startTime, uint256 voteTime, string metadata)",
  ];
  try {
    const iface = new ethers.utils.Interface(ABI);
    const r = iface.decodeEventLog("StartVote", data);
    if (r) {
      return {
        voteId: r.voteId.toNumber(),
        creator: r.creator,
        startTime: r.startTime.toNumber(),
        voteTime: r.voteTime.toNumber(),
        metadata: r.metadata
      }
    }
  } catch (error) {
    console.error("parse vote event data failed", error);
    console.warn("parse vote event data failed: ", data);
  }
}

export const getHigherGas = (gas) => {
  return Math.ceil(gas.toNumber() * 2);
}

export const checkCount = (amount, deci) => {
  const str = String(amount);
  const sp = str.split(".");
  if (sp.length === 1) {
    return true;
  } 
  if (sp[1].length <= deci) {
    return true;
  }
}

export const formatInput2bignumber = (amount_or_str, deci) => {
  const vbn = valueToBigNumber(amount_or_str).shiftedBy(deci);
  return ethers.BigNumber.from(vbn.toNumber().toFixed(0));
}

export const formatSwapNumber = (amount, deci) => {
  const valid_amount = amount.toFixed(deci);
  return Number(valid_amount)
}

export const checkExecuteStatus = (agree, minSupport, lefttime) => {
  if (lefttime > 0) {
    return false;
  }
  if (agree < minSupport) {
    return false;
  }
  return true;
}


export const formatLeftTime = (leftTime) => {
  const lt = leftTime;
  const days = parseInt(lt / 86400);
  let left = lt % 86400;
  const hours = parseInt(left / 3600);
  left = left % 3600;
  const minutes = parseInt(left / 60);
  const seconds = parseInt(left);
  if (days > 0) {
    return `${days}d ${hours}h ${minutes}min`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes}min`;
  }
  if (minutes > 0) {
    return `${minutes}min`;
  }
  if (minutes <= 0 && seconds > 0) {
    return "1min";
  }
  return "0min";
};


export const formatImgUrl = (url, gateway) => {
  if (url.startsWith("https://")) {
    return url;
  } else if (url.startsWith("data:image")) {
    return url
  } else {
    return gateway + url;
  }
};

export const setChainId = (chainId) => {
  localStorage.setItem(LOCALSTORAGE.CHAINID, chainId);
};

export const formatUTCTime = (m) => {
  const utc_offset_str = m.format("Z");
  let utc_offset;
  const split_utc_offset = utc_offset_str.split(":");
  if (split_utc_offset[1] === "00") {
    utc_offset = "+" + parseInt(split_utc_offset[0]);
  } else {
    utc_offset = utc_offset_str;
  }
  return m.format("YYYY-MM-DD HH:mm:ss") + " UTC" + utc_offset;
};

export const formatTextTime = (time) => {
  return moment(time * 1000).format("MMMM Do YYYY, h:mm A");
};