import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import BackSvg from "assets/images/Back.svg";

const BackNav = memo(({ to, state, children }) => {
  const { t } = useTranslation();
  const { fullhandle, network: networkSimple } = useParams();
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    return state
      ? navigate(`/${networkSimple}/${fullhandle}/${to}`, { state: state })
      : navigate(`/${networkSimple}/${fullhandle}/${to}`);
  }, [fullhandle, to]);

  return (
    <NavBox>
      <div className="left" onClick={goBack}>
        <img src={BackSvg} alt="" />
        {t("Back")}
      </div>
      {children}
    </NavBox>
  );
});
export default BackNav;

const NavBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(32px);
    color: #ffffff;
    cursor: pointer;
    img {
      width: 24px;
      margin-right: 5px;
    }
  }
`;
