import { useAppContext } from "components/provider/appProvider";
import { useState, useEffect } from "react";
import { getJsonFileByFetch } from "../api/apiHTTP";
import { SupportedChainId } from "network/chain";
import { ethers } from "ethers";

const tokenComparator = (a, b) => {
  if (a.symbol && b.symbol) {
    return a.symbol.toLowerCase() < b.symbol.toLowerCase() ? -1 : 1;
  }
  return -1;
};

const summarize = (arr) => {
  let chainList = {};
  arr.forEach((item) => {
    if (!chainList.hasOwnProperty(item.chainId)) {
      chainList[item.chainId] = new Map();
    }
    chainList[item.chainId].set(item.address.toLowerCase(), item);
  });
  return chainList;
};

const useTokenAssets = (chainId, network) => {
  const {
    dispatch,
    state: { fundList },
  } = useAppContext();
  const [chainTokensMap, setChainTokensMap] = useState({});

  const getTokens = async () => {
    const uniswapData = getJsonFileByFetch(`https://tokens.uniswap.org`);
    const geminiData = getJsonFileByFetch(
      `https://www.gemini.com/uniswap/manifest.json`
    );
    const pancake = getJsonFileByFetch(
      `https://raw.githubusercontent.com/pancakeswap/token-list/main/lists/pancakeswap-extended.json`
    );
    const quick = getJsonFileByFetch(
      "https://unpkg.com/quickswap-default-token-list@latest/build/quickswap-default.tokenlist.json"
    );
    const stella = getJsonFileByFetch(
      "https://raw.githubusercontent.com/SubDAO-Network/daos/main/tokens/stellaswap.json"
    );

    const arbitrum = getJsonFileByFetch(
      "https://tokenlist.arbitrum.io/ArbTokenLists/arbed_arb_whitelist_era.json"
    );

    const bsc_testnet = getJsonFileByFetch(
      "https://raw.githubusercontent.com/SubDAO-Network/daos/main/tokens/pancake_testnet.json"
    );

    const zksync = getJsonFileByFetch(
      "https://raw.githubusercontent.com/muteio/token-directory/main/zksync.json"
    );

    const zksync_testnet = [
      {
        name: "USD Coin",
        symbol: "USDC",
        decimals: 6,
        logoURI:
          "https://raw.githubusercontent.com/muteio/token-directory/main/icons/usdc.png",
        address: "0x0faF6df7054946141266420b43783387A78d82A9",
        chainId: SupportedChainId.ZKSYNC_TESTNET,
      },
    ];

    // NOTE：under same network, the lower index is lower priority
    const res = await Promise.all([
      // mainnet
      geminiData,
      uniswapData,
      // bsc
      pancake,
      // polygon
      quick,
      // moonbeam
      stella,
      // arbitrum
      arbitrum,
      // bsc testnet
      bsc_testnet,
      // zksync
      zksync,
    ]);

    const AllList = [
      ...res[0].tokens.filter((t) => t.chainId === 1),
      ...res[1].tokens.filter((t) => t.chainId === 1),
      ...res[2].tokens,
      ...res[3].tokens,
      ...res[4],
      ...res[5].tokens,
      ...res[6],
      ...res[7].filter((t) => t.address !== ethers.constants.AddressZero),
      ...zksync_testnet,
    ];
    setChainTokensMap(summarize(AllList));
  };

  useEffect(() => {
    getTokens();
  }, []);

  useEffect(() => {
    if (!chainId || !fundList.length || !network) {
      return;
    }
    const wrappedTokenAddress = network.wrappedToken;
    const data = chainTokensMap[chainId];
    if (data) {
      const arrValues = data.values();
      const arrList = [...arrValues];
      const tokens = [...arrList.sort(tokenComparator)];
      dispatch({
        type: "SET_ALL_TOKENS",
        payload: tokens.map((t) => {
          if (t.address.toLowerCase() === wrappedTokenAddress) {
            return {
              ...t,
              ...network.nativeCurrency,
              deci: network.nativeCurrency.decimals,
              logoURI:
                network.nativeCurrency.logoURI || t.logoURI || network.logo,
            };
          } else {
            return t;
          }
        }),
      });
    }
  }, [chainId, network, chainTokensMap, fundList]);
};

export default useTokenAssets;
