import { useMemo, useCallback } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";

import { useAppContext } from "components/provider/appProvider";
import { SUPPORTED_WALLETS } from "components/wallet/wallet";
import Button from "components/common/button";
import api from "api";
import useSigner from "hooks/useSigner";
import SendModal from "./sendModal";
import { useState } from "react";
import LogoSVG from "img/defaultLogo.svg";
import {useNavigate} from "react-router-dom";
import {useNetworkData} from "../../hooks/useNetwork";
import { useAuthContext } from "components/provider/authProvider";
import makeBlockie from "ethereum-blockies-base64";
import { NFTTokenAvatar, TokenAvatar } from "components/common/avatar";
import ClaimRecordModal from "./claimRecordModal";
import ClaimWrapper from "./claimWrapper";
import RecordImg from "../../assets/images/icon-Record.svg";

const TableList = (props) => {
  const { list, balanceMap, nftMap, tokenV2Map } = props;
  const { t } = useTranslation();
  const { provider,chainId } = useWeb3React();
  const {
    dispatch,
    state: { gateway },
  } = useAppContext();
  const { state: { selectedWallet } } = useAuthContext();
  const navigator = useNavigate();
  const networkData = useNetworkData(chainId);

  const [showClaimToken, setShowClaimtoken] = useState();
  const [showClaimTokenAmount, setShowClaimtokenAmount] = useState();

  const wallet = useMemo(() => {
    const currentWallet = Object.values(SUPPORTED_WALLETS).find(
      (w) => w.wallet === selectedWallet
    );
    return currentWallet?.iconURL;
  }, [selectedWallet]);

  const [sendToken, setSendToken] = useState()

  const getMoreData = (item) => {
    if (!item) {
      return;
    }
    if (item.et_type === 3) {
      return nftMap[item.address];
    } else if (item.dao_version === 1) {
      return balanceMap[item.address];
    } else if (item.dao_version > 1) {
      return tokenV2Map[item.address];
    }
  };

  const addToken = useCallback(
    async (token) => {
      const b = getMoreData(token);
      if (!b) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: t("Msg.AddTokenLoadTip"), closable: true },
        });
        return;
      }
      // dispatch({ type: "LOADINGTYPE", payload: t("Msg.AddingToken") });
      let wasAdd;
      try {
        wasAdd = await provider.send("wallet_watchAsset", {
          type: "ERC20",
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: b.deci,
            image: makeBlockie(token.address),
          },
        });
      } catch (error) {
        console.error("addToken failed", error);
      } finally {
        // dispatch({ type: "LOADINGTYPE", payload: null });
        // if (!wasAdd) {
        //   dispatch({
        //     type: "MSGTYPE",
        //     payload: { msg: t("Msg.AddTokenFailed"), closable: true },
        //   });
        // }
      }
    },
    [provider, balanceMap, nftMap, tokenV2Map]
  );

  const closeTransfer = () => {
    setSendToken(undefined);
  };

  const closeClaimRecord = () => {
    setShowClaimtoken(undefined);
  }
  const closeClaimToken = () => {
    setShowClaimtokenAmount(undefined);
  };


  const jumpDetail =(item) => {
    console.log(item)
    navigator(`/dao/${networkData?.simple}:${item.dao_handle}/dashboard/info`);
  };

  const getLogo = (item) => {
    if (gateway && item.dao_logo) {
      return <img src={gateway + item.dao_logo} alt="" className="logo" />;
    } else if (item.et_type === 3 && item.dao_address) {
      return <TokenAvatar address={item.dao_address} className="logo" />;
    } else if (item.address) {
      return <TokenAvatar address={item.address} className="logo" />;
    } else {
      return <img src={LogoSVG} alt="" className="logo" />;
    }
  }
  

  const getTag = (moredData) => {
    if (moredData?.canTransfer === undefined) {
      return
    }
    return moredData?.canTransfer ? (
      <TokenTag className="transfer">{t("Create.Circulable")}</TokenTag>
    ) : (
      <TokenTag>{t("Create.NoCirculable")}</TokenTag>
    );
  }

  const transferToken = async (token) => {
    const moreData = getMoreData(token);
    setSendToken({...token, ...moreData})
  };

  return (
    <TableBox>
      <SendModal
        show={!!sendToken}
        token={sendToken}
        balance={getMoreData(sendToken)?.balance}
        balanceDisplay={getMoreData(sendToken)?.balanceDisplay}
        closeModal={closeTransfer}
      />
      {showClaimToken && (
        <ClaimRecordModal
          token={showClaimToken}
          closeModal={closeClaimRecord}
        />
      )}
      <ClaimWrapper token={showClaimTokenAmount} closeModal={closeClaimToken} />
      <table cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <th>No.</th>
            <th>{t("Explore.DAOName")}</th>
            <th>{t("Explore.EquityToken")}</th>
            <th>{t("Explore.Amount")}</th>
            <th>{t("Explore.Action")}</th>
            <th>{t("Explore.Add2wallet")}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index) => (
            <tr key={index}>
              <td>
                <IndexTag>{index}</IndexTag>
              </td>
              <td onClick={() => jumpDetail(item)}>
                <DAOName>
                  {getLogo(item)}
                  <span>{item.dao_name}</span>
                </DAOName>
              </td>
              <td>
                <FlexBox>
                  {item.et_logo ? (
                    <NFTTokenAvatar
                      src={gateway + item.et_logo}
                      className="logo"
                    />
                  ) : (
                    <TokenAvatar address={item.address} className="logo" />
                  )}
                  <span className="displayName">{item.name_display}</span>
                  {getTag(getMoreData(item))}
                </FlexBox>
              </td>
              <td>
                <Amount>{getMoreData(item)?.balanceDisplay}</Amount>
              </td>
              <td>
                <Flex>
                  {!!getMoreData(item)?.canTransfer && (
                    <PrimaryButton
                      onClick={() => {
                        transferToken(item);
                      }}
                    >
                      {t("Send")}
                    </PrimaryButton>
                  )}
                  {item.dao_version === 2 && item.et_type !== 3 && (
                  <>
                  <LightButton onClick={() => setShowClaimtokenAmount(item)}>
                    {t("Claim.Claim")}
                  </LightButton>
                  <RecordButton
                    onClick={() => setShowClaimtoken(item)}
                    src={RecordImg}
                    alt=""
                  ></RecordButton>
                  </>
                )}
                </Flex>
              </td>
              <td>
                {item.et_type !== 3 && (
                  <WalletIcon onClick={() => addToken(item)} src={wallet} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableBox>
  );
};

export default TableList;

const TableBox = styled.div`
  margin-top: 30px;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  table {
    width: 1240px;
    background: rgba(255,255,255,0.1);
    border-radius: 16px;
    backdrop-filter: blur(32px);
    overflow: hidden;
  }
  th {
    font-size: 14px;
    font-weight: 400;
    color: #4A84FF;
    height: 72px;
    text-align: left;
    &:first-child {
      border-top-left-radius: 16px;

      padding-left:30px;
    }
    &:last-child {
      border-top-right-radius: 16px;
      text-align: center;
    }
  }
  td {
    white-space: nowrap;
    height: 62px;
    font-size: 16px;
    font-weight: 400;
    color: #10164b;
    vertical-align: middle;
    &:first-child {
      text-align: left;
      padding-left: 20px;
    }
    &:last-child{
      text-align: center;
    }
  }
  tr:nth-child(odd){
    td{
      background: rgba(255,255,255,0.06);
    }
  }
`;

const WalletIcon = styled.img`
  cursor: pointer;
  width: 24px;
  position: relative;
  top: 2px;
`;

const PrimaryButton = styled.div`
  width: 68px;
  height: 24px;
  background: #ffa4d1;
  border-radius: 13px;
  margin-right: 16px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #041735;
  line-height: 24px;
  cursor: pointer;
`;

const LightButton = styled.div`
  width: 68px;
  height: 24px;
  background: #79dfc4;
  border-radius: 13px;
  margin-right: 16px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #041735;
  line-height: 24px;
  cursor: pointer;
`;

const RecordButton = styled.img`
    width: 24px;
    height: 24px;
    opacity: 0.4;
    cursor: pointer;
`;

const TokenTag = styled.span`
  width: 116px;
  height: 24px;
  display: inline-block;
  background: #326CFF;
  border-radius: 13px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 24px;
  text-align: center;
`;

const IndexTag = styled.div`
  width: 28px;
  height: 20px;
  background: rgba(255,255,255,0.16);
  border-radius: 10px;
  border: 1px solid rgba(255,255,255,0.08);
  backdrop-filter: blur(12px);
  text-align: center;
  font-size: 10px;
  font-family: "Rubik-Medium";
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;
`

const DAOName = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  img{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
  }
`
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  .logo{
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .displayName{
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 6px;
    width: 100px;
  }
    `
const Amount = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  `
const Flex = styled.div`
  display: flex;
  align-items: center;
`