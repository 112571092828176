import { memo, useEffect, useState, useMemo, useCallback } from "react";
import { DatePicker } from "antd";
import styled from "styled-components";
import moment from "moment";
import DateSVG from "components/svg/shape/date";
import { useTranslation } from "react-i18next";
import { formatUTCTime } from "utils/utils";

const DateBox = (props) => {
  const { targetTime, updateTargetTime, notUseQuick, disabledDate, disabledTime } = props;
  const { t } = useTranslation();

  const [selectTag, setSelectTag] = useState(1);
  const [open, setOpen] = useState(false);
  const [showCustom, setShowCustom] = useState(false);

  const times = useMemo(() => {
    return [
      { label: "1hour", value: 3600000 },
      { label: "3hour", value: 10800000 },
      { label: "1day", value: 86400000 },
      // { label: "3days", value: 259200000 },
      { label: "1week", value: 604800000 },
      // { label: "1month", value: 2592000000 },
    ];
  }, []);

  const chooseTag = (idx) => {
    if (selectTag === idx) {
      return;
    }
    setSelectTag(idx);
    updateTargetTime(Date.now() + times[idx - 1].value);
  };

  const handleChange = (value) => {
    setSelectTag(undefined);
    updateTargetTime(value.unix()*1000);
  };

  // const disabledDate = useCallback((current) => {
  //   return current && current < moment().startOf("day");
  // }, []);

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // const disabledDateTime = useCallback((dates) => {
  //   let hours = moment().hours(); //0~23
  //   let minutes = moment().minutes(); //0~59
  //   let seconds = moment().seconds(); //0~59
  //   if (dates && moment(dates[1]).date() === moment().date()) {
  //     return {
  //       disabledHours: () => range(0, hours),
  //       disabledMinutes: () => range(0, minutes),
  //       disabledSeconds: () => range(0, seconds + 1),
  //     };
  //   }
  // }, []);

  const formatDate = useCallback((m) => {
    return formatUTCTime(m);
  }, []);

  const timeShow = useMemo(() => {
    let m;
    if (targetTime) {
      m = moment(targetTime);
    }
    if (m) {
      return formatDate(m);
    }
  }, [targetTime]);

  const openDate = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (!targetTime && selectTag) {
      !notUseQuick && updateTargetTime(Date.now() + times[selectTag - 1].value);
    }
  }, []);

  return (
    <>
      {!notUseQuick && (
        <TagBoxStyled>
          {times.map((time, idx) => (
            <span
              key={idx}
              className={selectTag === idx + 1 ? "active" : ""}
              onClick={() => chooseTag(idx + 1)}
            >
              {time.label}
            </span>
          ))}
          <span className={showCustom ? "active" : ""} onClick={openDate}>
            {t("Vote.Custom")}
          </span>
        </TagBoxStyled>
      )}

      <DisplayDateBox active={open}>
        <DisplayDate onClick={openDate}>{timeShow}</DisplayDate>
        <DateSVG className="icon" />
        <BoxBrdr
          open={open}
          onOpenChange={setOpen}
          placeholder=""
          style={{ width: "100%" }}
          format={formatDate}
          disabledDate={disabledDate}
          // disabledTime={disabledTime}
          allowClear={false}
          onChange={handleChange}
          showTime={true}
          showNow={false}
        />
      </DisplayDateBox>
    </>
  );
};

export default DateBox;

const TagBoxStyled = styled.div`
  display: flex;
  margin-bottom: 12px;
  cursor: pointer;
  span {
    text-align: center;
    width: 88px;
    height: 32px;
    line-height: 32px;
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    line-height: 32px;
    &:hover,
    &.active {
      background: #45beff;
      color: #182b47;
    }
  }
`;

const BoxBrdr = styled(DatePicker)`
  visibility: hidden;
  padding: 0 20px;
  border: 0;
  box-shadow: 0px 0px 4px 0px rgba(16, 22, 75, 0.1);
  border-radius: 8px;
  height: 44px;

`;

const DisplayDateBox = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: rgba(4, 23, 53, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(12px);
  height: 44px;
  border: ${(props) => (props.active ? "1px solid #328aff" : "unset")};
  .icon {
    position: absolute;
    top: 11px;
    right: 20px;
  }
`;

const DisplayDate = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;
  height: 44px;
  line-height: 44px;
  box-shadow: 0px 0px 4px 0px rgba(16, 22, 75, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
`;
