import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M17.331 13.2a.667.667 0 0 0-.667.666V16a.668.668 0 0 1-.667.667H8a.668.668 0 0 1-.667-.667v-8c0-.366.298-.666.667-.666h2.132a.667.667 0 1 0 0-1.333H8c-1.103 0-2 .897-2 2v7.999c0 1.103.897 2 2 2h7.999c1.103 0 2-.897 2-2v-2.133a.667.667 0 0 0-.668-.667Zm.255-7.148A.65.65 0 0 0 17.331 6H14a.667.667 0 1 0 0 1.334h1.723l-4.195 4.193A.665.665 0 0 0 12 12.665a.67.67 0 0 0 .472-.196l4.195-4.194v1.724A.667.667 0 1 0 18 10V6.667a.682.682 0 0 0-.414-.615Z"
        fill={props.color || "#878AA7"}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
