import React, { memo, useState, useEffect, useMemo } from "react";
import styled from "styled-components";

import DecorationImg from "assets/images/router.svg";
import LogoImg from "img/swap/logo.png";
import DownArrow from "img/swap/downArrow.png";
import TopArrowImg from "img/swap/topArrow.png";
import {useTranslation} from "react-i18next";
import arrow from "assets/images/Open.svg";

const RouteRow = memo(({ paths }) => {
  // const p = [
  //   [{ symbol: "ABC" }],
  //   [{ symbol: "ABC" }, { symbol: "ABC" }],
  //   [{ symbol: "ABC" }, { symbol: "ABC" }],
  //   [{ symbol: "ABC" }],
  // ];
  return (
    <AutoMid>
      {paths.map((t, i) => {
        if (t.length === 1) {
          return (
            <React.Fragment key={i}>
              <div className="first">
                <img src={t[0].logoURI} alt="" />
              </div>
              {i === t.length - 1 && <div className="line" />}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={i}>
              <div className="second">
                <div>
                  <img src={t[0].logoURI || LogoImg} alt="" />
                  <img src={t[1].logoURI || LogoImg} alt="" />
                </div>
              </div>
              <div className="line" />
            </React.Fragment>
          );
        }
      })}
    </AutoMid>
  );
});

const SwapRoute = memo(({ tokenPath, tokens }) => {
  const [showAuto, setShowAuto] = useState(false);
  const { t } = useTranslation();

  const handleAuto = () => {
    setShowAuto(!showAuto);
  };

  const paths = useMemo(() => {
    const map = {};
    // const p = [
    //   "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    //   "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    //   "0xbc91347e80886453f3f8bbd6d7ac07c122d87735",
    // ];
    const tokenPairs = [];
    tokenPath.forEach((addr, i) => {
      const findToken = tokens.find(
        (f) => f.address.toLowerCase() === addr.toLowerCase()
      );
      if (findToken) {
        map[addr] = { ...findToken };
      } else {
        map[addr] = { address: addr };
      }
      if (i === 0) {
        tokenPairs.push([map[addr]]);
      } else {
        tokenPairs.push([map[tokenPath[i - 1]], map[addr]]);
      }
      if (i === tokenPath.length - 1) {
        tokenPairs.push([map[addr]]);
      }
    });

    return tokenPairs;
  }, [tokens, tokenPath]);

  return (
    <RouterBox className="b12">
      <div className="auto" onClick={() => handleAuto()}>
        <div className="lftDecor">
          <img src={DecorationImg} alt="" />
          <span>{t("Swap.Router")}</span>
        </div>
        <img
          src={arrow}
          className={showAuto ? "arrow up" : "arrow down"}
          alt=""
        />
      </div>
      {showAuto && (
        <>
          <RouteRow paths={paths} />
          <div className="tipsBtm">{t("Swap.RouterTips")}</div>
        </>
      )}
    </RouterBox>
  );
});

export default SwapRoute;

const RouterBox = styled.li`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  margin-bottom: 10px;
  padding: 14px 20px;
  box-sizing: border-box;
  .auto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .arrow {
      width: 24px;
      &.up {
        transform: rotate(180deg);
      }
    }
  }
  .lftDecor {
    display: flex;
    align-items: center;
    img {
      margin-right: 9px;
    }
  }
  .tipsBtm {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 16px;
    opacity: 0.5;
  }
`;

const AutoMid = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  .line {
    flex: 1;
    height: 4px;
    opacity: 0.9;
    background: linear-gradient(
      278deg,
      rgba(110, 218, 255, 1),
      rgba(110, 218, 255, 0)
    );
    margin: 0 20px 0 10px;
  }
  img {
    width: 20px;
    border-radius: 50%;
  }
  .first {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
  .second {
    display: flex;
    align-items: center;
    img:last-child {
      margin-left: -8px;
    }
    span {
      margin-left: 6px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }
`;
