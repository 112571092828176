import React, {
  memo,
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useDaoContext } from "../../views/dao/provider";
import {
  useDaoOrgContract,
  useERC20Contract,
  useVentureContract,
} from "../../hooks/useContract";
import { DAO_ORG, VENTURE_MANAGER } from "../../utils/constant";
import DepositModal from "../modal/deposit/index";
import Withdraw from "../modal/withdraw";
import WithdrawBonus from "../modal/withdrawBonus";
import ConfirmBox from "components/modal/confirmModal";
import useSigner from "hooks/useSigner";
import { useAppContext } from "components/provider/appProvider";
import { PERIOD_NO } from "utils/constant";
import api from "api";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import useCheckLogin from "hooks/useCheckLogin";
import EditSvg from "assets/images/icon-edit.svg";
import EditDateModal from "./editDateModal";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import Button from "components/common/button";
import ClockAnim from "assets/gif/clock.gif";
import { AnimationBox } from "assets/styles/common";
import DiscountText from "components/common/discount";

const VenturePeriodPanel = memo(({ isReady }) => {
  const {
    state: {
      period,
    },
    dispatch,
  } = useDaoContext();

  const PeriodComponent = useMemo(() => {
    switch (period) {
      case PERIOD_NO.RAISE:
        return <RaisingPeriod />;
      case PERIOD_NO.INRETURN:
        return <InReturnPeriod />;
      case PERIOD_NO.MANAGEMENT:
        return <ManagementPeriod />;
      case PERIOD_NO.REDEMPTION:
        return <RedemptionPeriod />;
      default:
        return <Period />;
    }
  }, [period]);

  useEffect(() => {
    if (period) {
      dispatch({ type: "SET_PERIOD_STATUS", payload: true });
    }
  }, [period]);

  return (
    <AnimationBox className={isReady ? "animation" : ""}>{PeriodComponent}</AnimationBox>
  );
  // return <RedemptionPeriod />
});

export default VenturePeriodPanel;

// period = 1
const RaisingPeriod = memo(() => {
  const { t } = useTranslation();
  const isLogin = useCheckLogin();

  const { state: daoState, dispatch: dispatchEvent } = useDaoContext();
  const {
    daoId,
    daoChainId,
    componentAddressMap,
    whoAmI: { isOwner, isGP },
    stockToken,
    fundInfo,
    tokenDiscount,
  } = daoState;
  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();

  const ventureContract = useVentureContract(
    componentAddressMap.get(VENTURE_MANAGER),
    daoChainId
  );
  const daoOrg = useDaoOrgContract(
    componentAddressMap.get(DAO_ORG),
    daoChainId
  );
  const signer = useSigner();

  const [targetTime, setTargetTime] = useState(0);
  const [leftTime, setLeftTime] = useState(0);
  const TargetRef = useRef(leftTime);

  const [showEdit, setShowEdit] = useState(false);

  const [showDeposit, setShowDeposit] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    TargetRef.current = targetTime;
  });

  const reachTarget = useMemo(() => {
    console.log("[reachTarget]", fundInfo);

    if (!fundInfo) return undefined;

    return Number(fundInfo.raised) >= Number(fundInfo.goal) ? true : false;
  }, [fundInfo]);

  const generateCard = useCallback((num) => {
    if (!num && isNaN(num)) {
      return <></>;
    }
    const numstr = `${num < 10 ? "0" : ""}${num}`.split("");
    return numstr;
    // return numstr.map((s, i) => (
    //   <div key={i} >
    //     {s}
    //   </div>
    // ));
  }, []);

  useEffect(() => {
    const timerFunc = () => {
      const currentTime = parseInt(Date.now() / 1000);
      const delta = TargetRef.current - currentTime;
      // TODO check
      //   if (delta === 0) {
      //     props.update && props.update();
      //   }
      if (delta < 0) return;
      setLeftTime(delta);
    };
    timerFunc();
    const timer = setInterval(timerFunc, 1000);
    return () => clearInterval(timer);
  }, []);

  const getTargetTime = async () => {
    const stopTime = await ventureContract.RaiseSubTime();
    setTargetTime(stopTime.toNumber());
    dispatchEvent({ type: "SET_PERIOD_STATUS", payload: true });
  };

  useEffect(async () => {
    if (ventureContract) {
      getTargetTime();
    }
  }, [ventureContract]);

  const LeftTimeDisplay = useMemo(() => {
    const days = parseInt(leftTime / 86400);
    let left = leftTime % 86400;
    const hours = parseInt(left / 3600);
    left = left % 3600;
    const minutes = parseInt(left / 60);
    const seconds = left % 60;

    return {
      days: generateCard(days),
      hours: generateCard(hours),
      minutes: generateCard(minutes),
      seconds: generateCard(seconds),
    };
  }, [leftTime]);

  const handleFreeze = useCallback(async () => {
    try {
      const res = await api.venture.freeze(daoOrg, signer);
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.Freeze"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.Freeze,
          data: { daoId },
        },
      });
    } catch (error) {
      console.error("confirm failed", error);
      // showNotification(
      //   NotificationType.Fail,
      //   t("Notification.FreezeFailed")
      // );
    }
  }, [daoOrg]);

  const handleClose = useCallback(async () => {
    try {
      const res = await api.venture.closeVenture(daoOrg, signer);
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.Return"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.Return,
          data: { daoId },
        },
      });
    } catch (error) {
      console.error("confirm failed", error);
    }
  }, [daoOrg]);

  const submitEditTime = async (newtime) => {
    try {
      const res = await api.venture.editCloseDate(daoOrg, signer, newtime);
      const txHash = res.hash;
      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.ModifyCloseTime"),
        `${exploreScan}tx/${txHash}`
      );
      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.EditCloseDate,
          data: { daoId, time: newtime },
        },
      });
    } catch (error) {
      if (error && error.code === 4001) {
        dispatch({ type: "MSGTYPE", payload: { msg: error.message } });
      } else {
        dispatch({ type: "MSGTYPE", payload: { msg: error?.error?.message } });
      }
      throw Error(error);
    }
  };

  const closeEditModal = useCallback(() => {
    setShowEdit(false);
  });

  const showEditModal = useCallback(() => {
    setShowEdit(true);
  });

  useSubcribe(TransactionEvent.Freeze, (_, { daoId: _daoId }) => {
    if (daoId === _daoId) {
      dispatchEvent({ type: "SET_PERIOD", payload: PERIOD_NO.MANAGEMENT });
    }
  });

  useSubcribe(TransactionEvent.Return, (_, { daoId: _daoId }) => {
    if (daoId === _daoId) {
      dispatchEvent({ type: "SET_PERIOD", payload: PERIOD_NO.INRETURN });
    }
  });

  useSubcribe(
    TransactionEvent.ModifyCloseDate,
    (_, { daoId: _daoId, time }) => {
      if (daoId === _daoId) {
        setTargetTime(time);
      }
    }
  );

  const canOperate = useMemo(() => {
    return isOwner && isLogin;
  }, [isOwner, isLogin]);

  if (!targetTime || reachTarget === undefined || fundInfo === undefined) {
    return <Period />;
  }

  const getOptions = () => {
    if (!canOperate || reachTarget || leftTime > 0) {
      return <></>;
    }
    return (
      <Button primary width="141" onClick={handleClose}>
        {t("Period.Close")}
      </Button>
    );
  };

  return (
    <RemainBox>
      <div className="clock">
        <img src={ClockAnim} alt="" />
      </div>
      <NumLine>
        <div>
          <div className="num">{LeftTimeDisplay.days}</div>
          <div className="title">{t("Period.Day")}</div>
        </div>
        <div className="gap">:</div>
        <div>
          <div className="num">{LeftTimeDisplay.hours}</div>
          <div className="title">{t("Period.Hour")}</div>
        </div>
        <div className="gap">:</div>
        <div>
          <div className="num">{LeftTimeDisplay.minutes}</div>
          <div className="title">{t("Period.Minute")}</div>
        </div>
        <div className="gap">:</div>
        <div>
          <div className="num">{LeftTimeDisplay.seconds}</div>
          <div className="title">{t("Period.Second")}</div>
        </div>

        {/*<span>:</span>*/}
        {/*{LeftTimeDisplay.minutes}*/}
        {/*<span>:</span>*/}
        {/*{LeftTimeDisplay.seconds}*/}
        {/*<span className="leftBox">{t("Period.Left")}</span>*/}
        {isOwner && isLogin && (
          <>
            {showEdit && (
              <EditDateModal
                show={showEdit}
                oldDate={targetTime * 1000}
                closeModal={closeEditModal}
                submit={submitEditTime}
              />
            )}
            <img
              src={EditSvg}
              alt=""
              className="edit"
              onClick={showEditModal}
            />
          </>
        )}
      </NumLine>
      <RightBox className="rht">
        {leftTime > 0 ? (
          <>
            <DepositModal
              show={showDeposit}
              stockToken={stockToken || {}}
              closeDeposit={() => {
                setShowDeposit(false);
              }}
            />
            {tokenDiscount && <DiscountTextStyled />}
            <Button
              width="141"
              primary
              className="deposit"
              onClick={() => {
                isLogin
                  ? setShowDeposit(true)
                  : dispatch({ type: "CONNECT_WALLET", payload: true });
              }}
            >
              {t("Period.Deposit")}
            </Button>
          </>
        ) : (
          <></>
        )}
        {getOptions()}
        {canOperate && reachTarget && (
          <StartBox className="font-medium">
            {showConfirm && (
              <ConfirmBox
                show={showConfirm}
                message={t("Period.ConfirmStartMsg")}
                handleConfirm={handleFreeze}
                handleClose={() => setShowConfirm(false)}
              />
            )}
            {leftTime === 0 ? (
              <StartPrimaryButton primary onClick={() => setShowConfirm(true)}>
                {t("Period.Start2Management")}
              </StartPrimaryButton>
            ) : (
              <StartButton onClick={() => setShowConfirm(true)}>
                {t("Period.Start2Management")}
              </StartButton>
            )}
          </StartBox>
        )}
      </RightBox>
    </RemainBox>
  );
});

// period = 2
const InReturnPeriod = memo(() => {
  const { t } = useTranslation();
  const { account, provider } = useWeb3React();
  const { state: daoState } = useDaoContext();
  const { daoId, componentAddressMap, stockToken } = daoState;
  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();

  const isLogin = useCheckLogin();

  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const signer = useSigner();

  const [showBtn, setShowBtn] = useState(true);

  const handleConfirm = async () => {
    try {
      const res = await api.venture.refund(daoOrg, signer);

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.Refund"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.Refund,
          data: { daoId },
        },
      });

      // dispatchEvent({ type: "UPDATE_FUND_OR_PERIOD", payload: "fund" });
    } catch (error) {
      // dispatch({
      //   type: "MSGTYPE",
      //   payload: {
      //     msg: "withdraw failed",
      //     closable: true,
      //   },
      // });
      // showNotification(NotificationType.Fail, t("Notification.RefundFailed"));
      console.error("confirm failed", error);
    }
  };

  const checkStockBalance = async () => {
    const templateConfigAddress = await daoOrg.templateConfig();
    const data = await api.venture.getRefundEstimate(
      provider,
      account,
      templateConfigAddress
    );
    if (data[0].eq(ethers.BigNumber.from(0))) {
      setShowBtn(false);
    }
    console.log(">>>getRefundEstimate:", data[0].toString());
  };

  useEffect(() => {
    if (!stockToken || !account) {
      return;
    }
    checkStockBalance();
  }, [stockToken, account]);

  useSubcribe(TransactionEvent.Refund, (_, { daoId: _daoId }) => {
    if (daoId === _daoId) {
      checkStockBalance();
    }
  });

  return (
    <Period>
      <div className="clock">
        <img src={ClockAnim} alt="" />
      </div>
      <span className="mainText">{t("Period.InReturnPeriod")}</span>
      {isLogin && showBtn && (
        <RightBox className="rht">
          <Button onClick={handleConfirm} primary>
            {t("Period.Withdraw")}
          </Button>
        </RightBox>
      )}
    </Period>
  );
});

// period = 3
const ManagementPeriod = memo(() => {
  const { t } = useTranslation();
  return (
    <Period>
      <div className="clock">
        <img src={ClockAnim} alt="" />
      </div>
      <span className="mainText">{t("Period.FreezingPeriod")}</span>
    </Period>
  );
});

// period = 4
const RedemptionPeriod = memo(() => {
  const { t } = useTranslation();
  const [showBonus, setShowBonus] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);

  const isLogin = useCheckLogin();
  const {
    state: {
      whoAmI: { isOwner, isGP },
    },
  } = useDaoContext();

  return (
    <Period>
      <div className="clock">
        <img src={ClockAnim} alt="" />
      </div>
      <Withdraw
        show={showWithdraw}
        close={() => {
          setShowWithdraw(false);
        }}
      />
      <WithdrawBonus
        show={showBonus}
        close={() => {
          setShowBonus(false);
        }}
      />
      <span className="mainText">{t("Period.RedemptionPeriod")}</span>
      {isLogin && (
        <RightBox className="rht">
          <Button
            width="141"
            primary
            onClick={() => {
              setShowWithdraw(true);
            }}
          >
            {t("Period.Withdraw")}
          </Button>
          {(isOwner || isGP) && (
            <Button
              width="141"
              primary
              onClick={() => {
                setShowBonus(true);
              }}
              className="bonus"
              style={{ marginLeft: "14px" }}
            >
              {t("Period.Bonus")}
            </Button>
          )}
        </RightBox>
      )}
    </Period>
  );
});

const TextStyled = css`
  font-size: 24px;
  font-family: "Poppins-Bold";
  font-weight: bold;
  color: #45beff;
  line-height: 35px;
  height: 33px;
`;

const RemainBox = styled.div`
  padding: 0 24px 0 142px;
  height: 64px;
  background: #234488;
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  .clock {
    position: absolute;
    height: 64px;
    width: 980px;
    left: 0;
    top: 0;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
`;

const NumLine = styled.div`
  text-align: center;
  display: flex;
  align-items: flex-start;
  position: relative;
  .num {
    font-size: 28px;
    font-family: "Rubik-Bold";
    font-weight: bold;
    color: #45beff;
    line-height: 30px;
  }
  .title {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    opacity: 0.5;
    text-transform: uppercase;
  }
  .gap {
    font-size: 32px;
    height: 28px;
    font-family: "Rubik-ExtraBold";
    font-weight: 800;
    color: #ffffff;
    line-height: 28px;
    opacity: 0.32;
    padding: 0 36px;
  }
  .edit {
    width: 24px;
    margin-left: 20px;
    margin-top: 3px;
    cursor: pointer;
    opacity: 0.5;
  }
`;

const Period = styled(RemainBox)`
  height: 64px;
  .mainText {
    position: relative;
    ${TextStyled};
  }
`;

const RightBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const StartBox = styled.div`
  line-height: 19px;
`;

const StartButton = styled.div`
  margin-left: 14px;
  font-size: 16px;
  color: #469fff;
  cursor: pointer;
`;

const StartPrimaryButton = styled(Button)`
  width: unset;
  margin-left: 14px;
  padding: 0 20px;
`;

const DiscountTextStyled = styled(DiscountText)`
  margin-right: 20px;
`