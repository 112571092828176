import { memo } from "react";
import { Radio } from "antd";
import styled from "styled-components";
import { InputLabel } from "components/common/inputComp";
import { FlexRow } from "./style";

export const TokenPrice = {
  Fixed: 0,
  BondingCurve: 1,
};

const PriceRatio = memo(({ t, value, onChange }) => {
  return (
    <>
      <InputLabel required title={t("BondingCurve.PriceType")} />

      <FlexRow>
        <Radio.Group onChange={onChange} value={value}>
          <RadioBox value={TokenPrice.Fixed}>{t("Create.FixedPrice")}</RadioBox>
          <RadioBox value={TokenPrice.BondingCurve}>
            {t("Create.BondingCurve")}
          </RadioBox>
        </Radio.Group>
      </FlexRow>
    </>
  );
});

export default PriceRatio;

const RadioBox = styled(Radio)`
  color: #ffffff;
  margin-left: 40px;
  &:first-child {
    margin-left: 0;
  }
  .ant-radio-inner {
    border-color: rgba(255, 255, 255, 0.08);
    background-color: rgba(255, 255, 255, 0.06);
  }
`;
