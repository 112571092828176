import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import CopySvg from "../../components/svg/common/copy";
import LinkSvg from "../../components/svg/common/link";
import { LoadingBox } from "components/LoadingModal";
import { useAppContext } from "components/provider/appProvider";
import AlertSvg from "../../img/alert.svg";
import publicJs from "utils/publicJs";
import TwitterIcon from "img/twitter.svg";
import BaseModal from "components/modal/general";
import DoneIcon from "assets/images/create/done.svg";

const STEP_STATUS = {
  OFF: 0,
  ON: 1,
  FINISH: 2,
};

const StepItem = memo((props) => {
  const { label, step, currentStep, showStep } = props;

  const status = useMemo(() => {
    if (currentStep > step) {
      return STEP_STATUS.FINISH;
    }
    if (currentStep === step) {
      return STEP_STATUS.ON;
    }
    return STEP_STATUS.OFF;
  }, [step, currentStep]);

  return (
    <Step>
      <StepIcon status={status}>{showStep || step}</StepIcon>
      <StepLabel status={status}>{label}</StepLabel>
    </Step>
  );
});

const CreatingModal = memo((props) => {
  const { t } = useTranslation();
  const { show, step, closeModal, transHash, name, link } = props;

  const {
    state: { exploreScan },
  } = useAppContext();

  const STEPS = useMemo(() => {
    return [...Array(2)];
  }, []);

  const share = () => {
    const text = t("Create.Share", { name });
    window.open(
      `https://twitter.com/intent/tweet?text=${publicJs.encodeUrl(
        text
      )}&url=${publicJs.encodeUrl(link)}`,
      "_target"
    );
  };

  const showStepContent = useMemo(() => {
    const TEXT = (
      <>
        <MainText className="font-medium">
          {t(`Create.Step${step}MainText`)}
        </MainText>
        <SubText step={step}>{t(`Create.Step${step}Text`)}</SubText>
        {step === 1 && (
          <SubTips>
            <img src={AlertSvg} alt="" />
            <span>{t("Create.StepIntro")}</span>
          </SubTips>
        )}
      </>
    );
    switch (step) {
      case 1:
        return (
          <>
            <LoadingBox />
            {TEXT}
          </>
        );
      case 3:
        return (
          <>
            <img src={DoneIcon} alt="" className="icon" />
            {TEXT}
            <SubTransaction>
              <SubTransText>{transHash}</SubTransText>
              <CopySvg color="#FFFFFF" />
              <a
                href={`${exploreScan}tx/${transHash}`}
                rel="noreferrer"
                target="_blank"
              >
                <LinkSvg
                  style={{ position: "relative", top: "3px" }}
                  color="#FFFFFF"
                />
              </a>
            </SubTransaction>
            <ShareBox onClick={share}>
              <img src={TwitterIcon} alt="" />
              <span>{t("Create.ShareButton")}</span>
            </ShareBox>
          </>
        );
      default:
        return <></>;
    }
  }, [step]);

  const handleClose = () => {
    closeModal();
  };

  return (
    <BaseModal
      show={show}
      width={708}
      closable={step === 3}
      closeModal={handleClose}
      style={{ padding: 0 }}
      glass
    >
      <StepBody>{showStepContent}</StepBody>
      <StepFooter>
        <StepItem label={t(`Create.Step1Label`)} step={1} currentStep={step} />
        <StepItem
          label={t(`Create.Step3Label`)}
          step={3}
          currentStep={step}
          showStep={2}
        />
      </StepFooter>
    </BaseModal>
  );
});

export default CreatingModal;

const SubTips = styled.div`
  width: 525px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  padding: 14px 0 22px;
  display: flex;
  align-items: flex-start;
  span {
    padding-left: 7px;
    opacity: 0.5;
    padding-top: 2px;
  }
`;

const StepBody = styled.div`
  text-align: center;
  min-height: 217px;
  padding-top: 50px;
  .icon {
    margin-bottom: 25px;
  }
`;

const MainText = styled.div`
  font-size: 22px;
  font-family: "Poppins-Medium";
`;

const SubText = styled.div`
  line-height: 19px;
  margin-top: 8px;
  width: 400px;
  font-size: 14px;
  display: inline-block;
  opacity: ${(props) => (props.step === 3 ? 0.5 : 1)};
`;
const SubTransText = styled.span`
  font-size: 14px;
  margin-right: 8px;
`;

const SubTransaction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  svg {
    cursor: pointer;
  }
`;

const StepFooter = styled.div`
  width: 100%;
  height: 75px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: center;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  /* margin-left: 63px; */
  &:last-child {
    margin-left: 76px;
  }
`;
const StepIconOn = css`
  background: linear-gradient(311deg, #61e1ff 0%, #326cff 100%);
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
`;

const StepIconOff = css`
  background: rgba(255, 255, 255, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
`;

const StepIcon = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-family: "Rubik-Medium";
  ${(props) =>
    props.status === STEP_STATUS.ON || props.status === STEP_STATUS.FINISH
      ? StepIconOn
      : StepIconOff};
`;

const StepLabel = styled.span`
  margin-left: 6px;
  line-height: 24px;
  color: ${(props) =>
    props.status === STEP_STATUS.ON || props.status === STEP_STATUS.FINISH
      ? "#45BEFF"
      : "unset"};
`;

const ShareBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 44px;
  line-height: 44px;
  margin: 0 auto;
  margin-top: 37px;
  margin-bottom: 18px;
  cursor: pointer;

  background: linear-gradient(311deg, #61e1ff 0%, #326cff 100%);
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  border-radius: 12px;

  img {
    margin-right: 11px;
  }
`;
