import General from "components/modal/general";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InputNumber } from "antd";
import { TokenAvatar } from "components/common/avatar";
import { useState, useEffect, useMemo } from "react";
import { ContractVestingType } from "utils/constant";
import Button, { ButtonGroup } from "components/common/button";
import api from "api";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import BigNumber from "bignumber.js";
import { useAppContext } from "components/provider/appProvider";
import { useDaoContext } from "views/dao/provider";
import { DAO_ORG } from "../../utils/constant";
import useSigner from "hooks/useSigner";
import { useDaoOrgContract } from "../../hooks/useContract";

const LinearRelease = ({ t, data }) => {
  return (
    <Line>
      <Label>{t("Claim.ReleasePeriod")}</Label>
      <Value>
        {data?.startDate}-{data?.endDate}
      </Value>
    </Line>
  );
};

const StageRelease = ({ t, data }) => {
  return (
    <>
      <PeriodLine className="top">
        <Label className="period">{t("Claim.ReleasePeriod")}</Label>
        <Label className="ratio">{t("Claim.Ratio")}</Label>
        {/* <span className="state">{t("Claim.State")}</span> */}
      </PeriodLine>
      {(data || []).map((d) => (
        <PeriodLine>
          <span className="period">{d.date}</span>
          <span className="ratio">{d.ratio}</span>
          {/* <span className="state">1000</span> */}
        </PeriodLine>
      ))}
    </>
  );
};

const ClaimModal = ({
  show,
  ventureContract,
  stockToken,
  closeModal,
  vestingData,
  vestingType,
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();

  const [value, setValue] = useState();
  const [total, setTotal] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [remain, setRemain] = useState(0);

  const {
    state: { exploreScan },
    dispatch,
  } = useAppContext();
  const {
    state: { componentAddressMap, daoChainId },
  } = useDaoContext();

  const daoOrg = useDaoOrgContract(
    componentAddressMap.get(DAO_ORG),
    daoChainId
  );
  const signer = useSigner();

  const getPeriod = () => {
    if (vestingType === ContractVestingType.LinearRelease) {
      return <LinearRelease t={t} data={vestingData} />;
    } else if (vestingType === ContractVestingType.StagedRelease) {
      return <StageRelease t={t} data={vestingData} />;
    } else {
      return <></>;
    }
  };

  const checkInputValue = () => {
    if (!value) {
      return true;
    } else if (BigNumber(value).gt(BigNumber(remain))) {
      return true;
    }
  };

  const goClaim = async () => {
    try {
      if (checkInputValue()) {
        // todo alert
        return;
      }
      const num = ethers.utils.parseUnits(String(value), stockToken.deci);
      const res = await api.venture.claimToken(daoOrg, signer, num);

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.ClaimToken"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.ClaimToken,
          data: {
            address: stockToken.address,
            symbol: stockToken.symbol,
            amount: value,
          },
        },
      });

      closeModal();
    } catch (error) {
      console.error(error);
      // todo user denied
    }
  };

  const getAmount = async () => {
    try {
      const data = await ventureContract.availableBalance(account, 0);
      setTotal(ethers.utils.formatUnits(data[2], stockToken.deci));
      setClaimed(ethers.utils.formatUnits(data[1], stockToken.deci));
      setRemain(ethers.utils.formatUnits(data[3], stockToken.deci));
    } catch (error) {
      console.error("get claim failed", error);
    }
  };

  useEffect(() => {
    if (!ventureContract || !stockToken || !account) {
      return;
    }
    getAmount();
  }, [ventureContract, stockToken, account]);

  return (
    <General
      width="440"
      closeModal={closeModal}
      show={show}
      title={t("Claim.Claim")}
      titleBold={true}
    >
      <ModalBody>
        <InputBox>
          <LeftBox>
            <Label>{t("Claim.Amount")}</Label>
            <InputStyled
              autoFocus
              controls={false}
              value={value}
              onChange={setValue}
            />
          </LeftBox>

          <RightBox>
            <SymbolBox>
              <TokenAvatar address={stockToken?.address} size="24px" />
              <span>{stockToken?.symbol}</span>
            </SymbolBox>
            <div className="amount">
              {t("Claim.Available")}: {remain}
            </div>
          </RightBox>
        </InputBox>
        <Line>
          <Label>{t("Claim.Released")}</Label>
          <Value>{total}</Value>
        </Line>
        <Line>
          <Label>{t("Claim.Claimed")}</Label>
          <Value>{claimed}</Value>
        </Line>
        <Line>
          <Label>{t("Claim.Circulation")}</Label>
          <Value>
            {stockToken.canTransfer
              ? t("Create.Circulable")
              : t("Create.NoCirculable")}
          </Value>
        </Line>
        {getPeriod()}
        <ButtonGroup style={{ marginTop: "45px", marginBottom: "10px" }}>
          <Button height="44" width="180" onClick={closeModal}>
            {t("Cancel")}
          </Button>
          <Button primary height="44" width="180" onClick={goClaim}>
            {t("Claim.Claim")}
          </Button>
        </ButtonGroup>
      </ModalBody>
    </General>
  );
};

export default ClaimModal;

const ModalBody = styled.div`
  padding-top: 24px;
`;

const Label = styled.span`
  height: 19px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 18px;
`;

const InputBox = styled.div`
  width: 100%;
  height: 76px;
  border-radius: 8px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  padding-top: 5px;
  box-sizing: border-box;
`;

const InputStyled = styled(InputNumber)`
  flex: 1;
  background: transparent;
  border: none;
  &.ant-input-number-focused {
    box-shadow: none;
  }
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 5px;
  .ant-input-number-input {
    color: #fff;
    font-family: "Rubik-Medium";
    padding: 0;
  }
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .amount {
    line-height: 24px;
    margin-top: 10px;
    font-family: "Poppins-Light";
    opacity: 0.5;
    font-size: 12px;
  }
`;

const SymbolBox = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  span {
    font-size: 16px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
  margin-bottom: 14px;
`;
const Value = styled.span``;

const PeriodLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  &.top {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
    margin-top: 20px;
  }
`;
