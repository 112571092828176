const TOKEN_IDENTITY_MAP = {
  // moonbeam/USDC
  "0x931715fee2d06333043d11f658c8ce934ac61d0c": "Wormhole",
};

const getIdentity = (address) => {
  if (!address) {
    return "";
  }
  const addressLower = address.toLowerCase();
  return TOKEN_IDENTITY_MAP[addressLower];
};

export default getIdentity;
