import { ethers } from "ethers";
import BigNumber from "bignumber.js";

export const checkBonus = (list) => {
    const bonus_arr = list.map(a => BigNumber(a.bonus || 0))
    const v = bonus_arr.reduce(function (
      accumulator,
      currentValue,
      currentIndex,
      array
    ) {
      return accumulator.plus(currentValue);
    });
    return v.toNumber() === 100;
};

export const formatBonus = (bonus) => {
  return ethers.BigNumber.from((BigNumber(bonus || 0).multipliedBy(100)).toString());
}
  
export const checkInvalidBonus = (addressList) => {
  for (const a of addressList) {
    if (a.bonus && a.bonus < 0) return true;
  }
};

export const checkInvalidAddress = (addressList) => {
  for (const a of addressList) {
    if (!ethers.utils.isAddress(a.address)) return a.address;
  }
};

export const checkRepeatAddress = (addressList) => {
  const map = {};
  addressList.forEach(a => { map[a.address] = true });
  return Object.keys(map).length < addressList.length;
}


export const checkInvalidStr = (str) => {
  return !(/^[a-zA-Z0-9_-]*$/g.test(str));
}