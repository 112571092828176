import { useNetworkData } from "./useNetwork";
import { useDaoContext } from "views/dao/provider";
import { useMemo } from "react";
import { useAppContext } from "components/provider/appProvider";

export default function useFundToken(daoChainId) {
  const network = useNetworkData(daoChainId);

  const {
    state: { fundToken },
  } = useDaoContext();

  const {
    state: { fundList },
  } = useAppContext();

  return useMemo(() => {
    if (fundToken?.address?.toLowerCase() === network.wrappedToken) {
      return {
        ...fundToken,
        logo: network.logo,
        name: network.nativeCurrency.name,
        symbol: network.nativeCurrency.symbol,
      };
    } else if (fundToken) {
      const token = fundList.find(
        (f) => f.address.toLowerCase() === fundToken.address.toLowerCase()
      );
      return {
        ...fundToken,
        logo: token?.logo,
      };
    }
    return fundToken;
  }, [fundToken]);
}
