import { useEffect, useState, useMemo, useRef } from "react";
import BaseModal from "./general";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Transfer from "./vote/transfer";
import SuperManager from "./vote/super";
import Fee from "./vote/fee";
import AddManager from "./vote/add";
import Redemption from "./vote/redemption";
import RemoveManager from "./vote/remove";
import Multisign from "./vote/multisign";

import { Label, VoteItem } from "./vote/components/style";
import ModalFooter from "components/common/modalFooter";
import VerticalScroller from "components/common/verticalScroller";
import Select from "components/common/textSelect";
import { TransType } from "components/transaction/checkTransaction";
import showNotification, {
  NotificationType,
} from "components/common/notification";
import { DAO_ORG, ORG_MANAGER, VAULT_MANAGER } from "utils/constant";
import { useVaultContract } from "hooks/useContract";
import useSigner from "hooks/useSigner";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import { useDaoContext } from "views/dao/provider";
import { useAppContext } from "components/provider/appProvider";
import { ethers } from "ethers";

export default function NewVote(props) {
  const { showVoteModal, CloseModal, selectedAction, selectedType } = props;
  const { t } = useTranslation();
  const { account, chainId } = useWeb3React();
  const { fullhandle, network: networkSimple } = useParams();

  const {
    state: { daoId, componentAddressMap },
  } = useDaoContext();
  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();

  const vaultContract = useVaultContract(componentAddressMap.get(VAULT_MANAGER));
  const signer = useSigner();

  const [voteTypes, setVoteTypes] = useState([
    { title: t("Vote.AddManager"), value: 0 },
    { title: t("Vote.RemoveManager"), value: 1 },
    { title: t("Vote.Transfer"), value: 2 },
    { title: t("Vote.SuperManager"), value: 3 },
    { title: t("Vote.ManagementFeePool"), value: 4 },
    { title: t("Vote.ChangePeriod"), value: 5 },
    { title: t("Vote.Multisign"), value: 6 },
  ]);
  const [currentType, setCurrentType] = useState(0);
  const [boxHeight, setBoxHeight] = useState(undefined);
  const [submitLoading, setSubmitLoading] = useState(false);

  const addRef = useRef();
  const removeRef = useRef();
  const transferRef = useRef();
  const superRef = useRef();
  const feeRef = useRef();
  const redemptionRef = useRef();
  const multisignRef = useRef();

  useEffect(() => {
    if (!selectedType) return;
    if (selectedType === "wallet") {
      setVoteTypes([
        { title: t("Vote.AddManager"), value: 0 },
        { title: t("Vote.RemoveManager"), value: 1 },
        { title: t("Vote.Transfer"), value: 2 },
        { title: t("Vote.SuperManager"), value: 3 },
        { title: t("Vote.Multisign"), value: 6 },
      ]);
      setCurrentType(0);
    } else if (selectedType === "addRemove") {
      setVoteTypes([
        { title: t("Vote.AddManager"), value: 0 },
        { title: t("Vote.RemoveManager"), value: 1 },
      ]);
      setCurrentType(0);
    } else if (selectedType === "redemption") {
      setVoteTypes([
        { title: t("Vote.SuperManager"), value: 3 },
        { title: t("Vote.ManagementFeePool"), value: 4 },
      ]);
      setCurrentType(3);
    } else if (selectedType === "redemption_moonbeam") {
      setVoteTypes([{ title: t("Vote.ManagementFeePool"), value: 4 }]);
      setCurrentType(4);
    } else if (selectedType === "moonbeam") {
      setVoteTypes(voteTypes.filter((v) => v.value !== 3));
      setCurrentType(0);
    } else {
      setCurrentType(selectedType);
    }
  }, [selectedType]);

  useEffect(() => {
    switch (currentType) {
      case 5:
        setBoxHeight(450);
        break;
      default:
        setBoxHeight(620);
        break;
    }
  }, [currentType]);

  const onChangeSelect = (val) => {
    setCurrentType(val);
  };

  const showVoteContent = useMemo(() => {
    console.log("currentType:", currentType);
    switch (currentType) {
      case 0:
        return <AddManager ref={addRef} />;
      case 1:
        return <RemoveManager ref={removeRef} />;
      case 2:
        return <Transfer ref={transferRef} />;
      case 3:
        return <SuperManager ref={superRef} />;
      case 4:
        return <Fee ref={feeRef} />;
      case 5:
        return <Redemption ref={redemptionRef} />;
      case 6:
        return <Multisign ref={multisignRef} />;
      default:
        return <></>;
    }
  }, [currentType, voteTypes]);

  const ref = useMemo(() => {
    switch (currentType) {
      case 0:
        return addRef;
      case 1:
        return removeRef;
      case 2:
        return transferRef;
      case 3:
        return superRef;
      case 4:
        return feeRef;
      case 5:
        return redemptionRef;
      case 6:
        return multisignRef;
      default:
        return;
    }
  }, [currentType]);

  const generateBytes = (voteBytes) => {
    const iface = new ethers.utils.Interface([
      "function action(bytes calldata data)",
    ]);
    return iface.encodeFunctionData("action", [voteBytes]);
  };

  const submitVote = async () => {
    if (!ref || !ref.current) {
      return;
    }
    if (!ref.current.checkValid()) {
      return;
    }
    const bytes = generateBytes(ref.current.getVoteBytes());
    try {
      setSubmitLoading(true);
      const res = await vaultContract
        .connect(signer)
        .submitTransaction(componentAddressMap.get(DAO_ORG), 0, "vote", bytes);
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.VoteCreating"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.New_Vote,
          data: {
            orgContractAddress: componentAddressMap.get(ORG_MANAGER),
            fullhandle,
            networkSimple,
            chainId,
            daoId,
            bytes, 
          },
        },
      });
      CloseModal();
    } catch (error) {
      // setStatus(VoteTxStatus.Failed);
      // setTxHash(txHash);
      console.error("newAddGPVote failed", error);
      // showNotification(NotificationType.Fail, t("Notification.VoteFailed"));
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <BaseModal
      show={showVoteModal}
      glass
      closeModal={CloseModal}
      width="578"
      height={boxHeight}
    >
      <VoteContainer height={`${boxHeight - 110}px`}>
        <VoteItem>
          <Label>{t("Vote.VoteType")}</Label>
          <Select
            list={voteTypes}
            onSelect={onChangeSelect}
            selected={currentType}
            banSelect={selectedAction}
          />
        </VoteItem>
        {showVoteContent}
      </VoteContainer>
      <ModalFooter
        t={t}
        onCancel={CloseModal}
        onSubmit={submitVote}
        submitLoading={submitLoading}
      />
    </BaseModal>
  );
}

const VoteContainer = styled(VerticalScroller)`
  padding: 0 20px;
  margin-top: 12px;
`;
