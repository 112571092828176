import { useCallback, useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import OpenArrow from "assets/images/Open.svg";

export default function Select({ list, selected, onSelect, banSelect }) {
  const [show, setShow] = useState(false);

  const clickCallback = (e) => {
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", clickCallback);
    return () => {
      document.removeEventListener("click", clickCallback);
    };
  }, []);

  const showSelect = useCallback(
    (e) => {
      e.nativeEvent.stopImmediatePropagation();
      if (banSelect) {
        return;
      }
      setShow(!show);
    },
    [show]
  );

  const choose = useCallback(
    (val, e) => {
      e.stopPropagation();
      setShow(false);
      onSelect(val);
    },
    [selected]
  );

  const selectDisplay = useMemo(() => {
    if (selected === undefined) {
      return "";
    }
    const find = list.find((item) => item.value === selected);
    return find ? find.title : "";
  }, [selected]);

  return (
    <SelectBox onClick={showSelect}>
      <OptionDisplay fullExpand={show}>
        {selectDisplay}
        {!banSelect && (
          <>
            {show && <Line />}
            <img className="arrow" src={OpenArrow} alt="" />
          </>
        )}
      </OptionDisplay>
      {show && (
        <OptionContainer>
          {list.map((item, i) => (
            <Option key={item.value} onClick={(e) => choose(item.value, e)}>
              {item.title}
            </Option>
          ))}
        </OptionContainer>
      )}
    </SelectBox>
  );
}

const BgStyled = css`
  background-color: #1e2b3f;
`;

const SelectBox = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;
  height: 44px;
`;

const OptionContainer = styled.ul`
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-top: none;
  ${BgStyled};
`;

const OptionDisplay = styled.div`
  height: 100%;
  line-height: 44px;
  padding-left: 23px;
  border-radius: ${(props) => (props.fullExpand ? "10px 10px 0 0" : "10px")};
  overflow: hidden;
  position: relative;
  border: ${(props) =>
    props.fullExpand ? "1px solid rgba(255, 255, 255, 0.08)" : "unset"};
  ${BgStyled};
  .arrow {
    width: 24px;
    opacity: 0.5;
    position: absolute;
    right: 20px;
    top: 10px;
    transform: ${(props) =>
      props.fullExpand ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const Line = styled.div`
  width: calc(100% - 46px);
  height: 1px;
  position: absolute;
  left: 23px;
  bottom: 1px;
  background: rgba(255, 255, 255, 0.08);
`;

const Option = styled.li`
  height: 34px;
  line-height: 34px;
  padding: 0 13px;
  cursor: pointer;
  &:hover {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
  }
`;
