import React, { memo, useState } from "react";
import { InputNumber, Tooltip } from "antd";
import styled from "styled-components";
import InvalidSvg from "../svg/common/invalid";
import { InputBackground } from "assets/styles/common";
import RequiredStar from "assets/images/RequiredStar.svg"
import Tip from "assets/images/Tip.svg";

export const InputLabel = memo((props) => {
  const { title, required, icon, labelSize, focus, titleTip, ...rest } = props;

  return (
    <InputLabelStyled {...rest} labelSize={labelSize} focus={focus}>
      {icon && <span className="label-icon">{icon}</span>}
      {required && <img src={RequiredStar} alt="" className="star-icon" />}
      <span className="label-text">{title}</span>
      {titleTip && (
        <Tooltip title={titleTip}>
          <img className="tip" src={Tip} alt="" />
        </Tooltip>
      )}
    </InputLabelStyled>
  );
});

export const InputItem = ({
  title,
  required,
  icon,
  area,
  overlayClass,
  width,
  prefix,
  outPrefix,
  outSuffix,
  suffix,
  labelSize,
  errorMsg,
  isError,
  areaHeight,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const handleFocus = () => {
    setIsFocus(true);
    onFocus && onFocus();
  };
  const handleBlur = () => {
    setIsFocus(false);
    onBlur && onBlur();
  };
  return (
    <InputBg className={overlayClass} width={width} title={title}>
      {title && (
        <InputLabel
          focus={isFocus}
          title={title}
          required={required}
          icon={icon}
          labelSize={labelSize}
        />
      )}
      <InputWrapper
        focus={isFocus}
        area={area}
        width={width}
        className={isError ? "errorBound" : ""}
      >
        {area ? (
          <TextAreaStyled
            height={areaHeight}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...rest}
          />
        ) : (
          <>
            {prefix && <Prefix>{prefix}</Prefix>}
            <InputStyled onFocus={handleFocus} onBlur={handleBlur} {...rest} />
            {suffix && <Suffix>{suffix}</Suffix>}
          </>
        )}
      </InputWrapper>

      {errorMsg && (
        <Error>
          <InvalidSvg />
          <span>{errorMsg}</span>
        </Error>
      )}
    </InputBg>
  );
};

export const InputNumberItem = ({
  title,
  required,
  icon,
  area,
  overlayClass,
  width,
  labelSize,
  outPrefix,
  outSuffix,
  prefix,
  suffix,
  errorMsg,
  avaliableMsg,
  toolTip,
  toolTipStyle,
  titleTip,
  onFocus,
  onBlur,
  isError,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleFocus = () => {
    setIsFocus(true);
    setShowTooltip(true);
    onFocus && onFocus();
  };
  const handleBlur = () => {
    setIsFocus(false);
    setShowTooltip(false);
    onBlur && onBlur();
  };
  return (
    <InputBg className={overlayClass} width={width}>
      {title && (
        <InputLabel
          focus={isFocus}
          title={title}
          required={required}
          icon={icon}
          labelSize={labelSize}
          titleTip={titleTip}
        />
      )}
      <InputWrapper
        focus={isFocus}
        area={area}
        width={width}
        className={isError ? "errorBound wrapper" : "wrapper"}
      >
        {prefix && <Prefix>{prefix}</Prefix>}
        <InputNumberStyled
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {suffix && <Suffix>{suffix}</Suffix>}
        {outSuffix && <OutSuffix>{outSuffix}</OutSuffix>}
      </InputWrapper>
      {toolTip && showTooltip && (
        <TooltipStyle style={toolTipStyle}>{toolTip}</TooltipStyle>
      )}
      {errorMsg && (
        <Error>
          <InvalidSvg />
          <span>{errorMsg}</span>
        </Error>
      )}
    </InputBg>
  );
};

const InputLabelStyled = styled.div`
  font-size: ${(props) => props.labelSize || "14px"};
  color: ${(props) => (props.focus ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)")};
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  height: 22px;
  position: relative;
  white-space: nowrap;
  .label-icon {
    margin-right: 4px;
    height: 100%;
    svg {
      margin-top: -1px;
    }
  }
  .star-icon {
    margin-right: 6px;
  }
  .label-text {
    line-height: 22px;
  }
  .tip {
    width: 24px;
    margin-left: 4px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`;

const InputBg = styled.div`
  width: ${(props) => (props.width && !props.title ? props.width : "100%")};
  flex: ${(props) => (props.width && !props.title ? "unset" : 1)};;
  position: relative;
  &.error {
    border: 1px solid #ff6868;
  }
  position: relative;
`;

const InputWrapper = styled.div`
  ${InputBackground};
  display: flex;
  align-items: center;
  border: ${(props) => (props.focus ? "1px solid #328aff" : "unset")};
  height: ${(props) => (props.area ? "unset" : "")};
  width: ${(props) => props.width || "100%"};
  position: relative;
  &.errorBound {
    border: 1px solid #ff6868;
  }
`;

const InputStyled = styled.input`
  flex: 1;
  background: transparent;
  height: 100%;
  color: #ffffff;
  &::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
`;

const TextAreaStyled = styled.textarea`
  flex: 1;
  background: transparent;
  height: ${(props) => props.height || "80px"};
  resize: none;
  color: #ffffff;
  padding: 15px 0;
  box-sizing: border-box;
  &::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
`;

const Prefix = styled.span`
  margin-right: 3px;
`;
const Suffix = styled.span`
  margin-left: 3px;
`;

const OutSuffix = styled.span`
  position: absolute;
  right: -22px;
  top: 0px;
  opacity: 0.5;
`;

const Error = styled.div`
  position: absolute;
  font-size: 12px;
  color: #e00000;
  bottom: -23px;
  left: 3px;
  display: flex;
  align-items: center;
  line-height: 14px;
  & > span {
    margin-left: 6px;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  flex: 1;
  height: 44px;
  box-shadow: none;
  &.ant-input-number {
    border: none;
    background-color: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-number-input {
    height: 44px;
    color: #ffffff;
    padding: 0;
  }
  .ant-input-number-input-wrap {
    line-height: 44px;
  }
  .ant-input-number-input::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
  input:focus {
    border: none;
  }
`;

const TooltipStyle = styled.div`
  width: 220px;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(12px);
  position: absolute;
  right: -255px;
  top: 20px;
  border-radius: 8px;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 16px;
  font-size: 12px;
  z-index: 999;
`;
