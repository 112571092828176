import { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSwapContext } from "components/provider/swapProvider";
import SwapLoading from "./swaploading";
import SwapPending from "./SwapPending";
import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/common/button";
import LoadingNew from "components/loadingNEW";
import { Spin } from "antd";
const Pending = memo(() => {
  const { chainId, provider } = useWeb3React();
  const {
    state: { transactions },
    dispatchEvent,
  } = useSwapContext();
  const { t } = useTranslation();

  const [pendingStatus, setPendingStatus] = useState(false);

  const closePending = useCallback(() => {
    setPendingStatus(false);
  }, []);

  const showPending = useCallback(() => {
    setPendingStatus(true);
  }, []);

  const checker = useCallback(() => {
    for (const hash in transactions) {
      const tx = transactions[hash]
      if (tx.status !== 0) {
        continue
      }
      provider.getTransactionReceipt(hash).then(r => {
        if (r) {
          dispatchEvent({
            type: "UPDATE_TX", payload: {
            chainId,
            hash,
            data: {
              ...tx,
              status: r.status,
            }
          }})
        }
      })
    }
  }, [transactions, provider])

  useEffect(() => {
    const timer = setInterval(checker, 1000);
    return () => clearInterval(timer);
    
  }, [transactions])

  const pendingLen = useMemo(() => {
    let len = 0;
    for (const hash in transactions) {
      if (!transactions[hash].status) {
        len += 1;
      }
    }
    return len
  }, [transactions])

  if (!pendingLen) {
    return <></>;
  }
  return (
    <>
      {pendingStatus && (
        <SwapPending
          closeModal={closePending}
          list={Object.values(transactions)}
          show={pendingStatus}
        />
      )}

      <BtnRht primary onClick={showPending}>
        <div className="content">
          <span className="text">
            {pendingLen} {t("Swap.Pending")}
          </span>{" "}
          {pendingLen > 0 && <Spin size="small" />}
        </div>
      </BtnRht>
    </>
  );
});

export default Pending;

const BtnRht = styled(Button)`
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      margin-right: 6px;;
    }
  }
  height: 40px;
  width: 160px;
`;
