import { useState, useEffect } from "react";
import MsgModal from "components/MsgModal";
import LoadingModal from "components/LoadingModal";
import { useAppContext } from "components/provider/appProvider";

export default function GlobalModal() {
  const {
    state: { msgType, loadingType },
    dispatch,
  } = useAppContext();
  const [showMsg, setshowMsg] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setshowMsg(msgType != null);
  }, [msgType]);

  useEffect(() => {
    setloading(loadingType != null);
  }, [loadingType]);

  return (
    <>
      <MsgModal
        showMsg={showMsg}
        handleClose={() => {
          setshowMsg(false);
          dispatch({ type: "MSGTYPE", payload: null });
        }}
      />
      {loading && (
        <LoadingModal
          loading={loading}
          handleClose={() => {
            setloading(false);
            dispatch({ type: "LOADINGTYPE", payload: null });
          }}
        />
      )}
    </>
  );
}
