import {
  memo,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DropSvg from "components/svg/common/select";
import { ReceiveBox, DropRC } from "./style";
import { ethers } from "ethers";
import useVaultNFTs from "hooks/useVaultNFTs";
import NoneNFT from "img/common/none.png";
import { useAppContext } from "components/provider/appProvider";
import useGateway from "hooks/useGateway";
import { useVaultContract } from "hooks/useContract";
import { useDaoContext } from "views/dao/provider";
import { VAULT_MANAGER } from "utils/constant";
import { formatImgUrl } from "utils/utils";
import { InputBackground } from "assets/styles/common";
import { Label, VoteItem } from "../components/style";
import NFTSelect from "../components/nftSelect";

const TransferNFT = memo(
  forwardRef((props, ref) => {
    const { t } = useTranslation();

    const { list: nftList } = useVaultNFTs();
    const { dispatch } = useAppContext();

    const [filterList, setFilterList] = useState([]);

    const [currentIdx, setCurrentIdx] = useState();
    const [tokenAmount, setTokenAmount] = useState();
    const [currentToken, setCurrentToken] = useState();
    const gateway = useGateway();

    const {
      state: { daoChainId, componentAddressMap },
    } = useDaoContext();
    const vaultContract = useVaultContract(
      componentAddressMap.get(VAULT_MANAGER),
      daoChainId
    );

    useEffect(async () => {
      if (!nftList.length || !vaultContract) {
        return;
      }
      try {
        const reqs = [
          vaultContract.getNFT721List(),
          vaultContract.getNFT1155List(),
        ];
        const res = await Promise.all(reqs);
        const map = {};
        [...res[0], ...res[1]].forEach((d) => (map[d.toLowerCase()] = true));
        const nfts = [];
        nftList.forEach((nft) => {
          if (map[nft.contract_address]) {
            nfts.push(nft);
          }
        });
        setFilterList(nfts);
      } catch (error) {
        console.error("filter nft failed", error);
      }
    }, [nftList, vaultContract]);

    const updateTokenAmount = useCallback((e) => {
      setTokenAmount(Number(e.target.value));
    }, []);

    const chooseToken = (nft, e) => {
      e.stopPropagation();
      setCurrentToken(nft);
    };

    useImperativeHandle(ref, () => ({
      data: currentToken && {
        nftAddress: currentToken.contract_address,
        tokenId: currentToken.id,
        tokenAmount: ethers.BigNumber.from(
          currentToken.is_erc721 ? 0 : tokenAmount || 1
        ),
      },
      checkValid: () => {
        if (!currentToken) {
          return false;
        }
        if (currentToken.amount < tokenAmount || tokenAmount < 1) {
          dispatch({
            type: "MSGTYPE",
            payload: { msg: t("Msg.InvalidAmount"), closable: true },
          });
          return false;
        }
        return true;
      },
    }));

    return (
      <>
        <VoteItem>
          <NFTSelect
            list={filterList}
            onSelect={chooseToken}
            selected={currentToken}
            placeholder={t("Vote.SelectToken")}
            gateway={gateway}
          />
        </VoteItem>
        <VoteItem>
          <Label>{t("Vote.TokenID")}</Label>
          <TokenDD>{currentToken && currentToken.id}</TokenDD>
        </VoteItem>
        {currentToken && currentToken.amount > 1 && (
          <VoteItem>
            <Label>{t("Vote.Amount")}</Label>
            <AmountBox>
              <input
                type="number"
                value={tokenAmount}
                onChange={updateTokenAmount}
                onWheel={() => document.activeElement.blur()}
              />
              <span>{currentToken && currentToken.name}</span>
            </AmountBox>
          </VoteItem>
        )}
      </>
    );
  })
);

export default TransferNFT;

const AmountBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(16, 22, 75, 0.1);
  border-radius: 8px;
  padding: 0 20px;
  min-height: 44px;
  input {
    flex-grow: 1;
    border: 0;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: #0e164e;
    line-height: 19px;
  }
`;

const TokenDD = styled.dd`
  ${InputBackground};
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const ReceiveBoxHead = styled.div`
  width: 100%;
  position: relative;
  .drop {
    position: absolute;
    right: 0px;
  }
`;
