import { useState } from "react";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import WalletIcon from "assets/images/create/wallet.svg";
import VentureIcon from "assets/images/create/venture.svg";
import { useCreateContext } from "./provider";
import AnimationBg from "components/animation";
import LftImg from "assets/images/left.png";
import RhtImg from "assets/images/right.png";
import RadioOnIcon from "assets/images/checkbox/radio_on.svg";
import RadioOffIcon from "assets/images/checkbox/radio_off.svg";
import CheckBoxOnIcon from "assets/images/checkbox/checkbox_on.svg";
import CheckBoxOffIcon from "assets/images/checkbox/checkbox_off.svg";

const KEY = "choose_type";
export const LOCAL_KEY = `CreateDao_${KEY}`;

export const DaoType = {
  wallet: "multi-sign",
  venture: "venture-DAO",
};

const TagRow = memo(({ active, text, subTexts }) => {
  return (
    <RowStyled active={active}>
      <div className="mainLine">
        <img src={active ? CheckBoxOnIcon : CheckBoxOffIcon} alt="" />
        <span>{text}</span>
      </div>
      {subTexts &&
        subTexts.map((sub, i) => (
          <div
            className="subLine"
            key={i}
            style={i === 0 ? { marginTop: "12px" } : {}}
          >
            <span className="point"></span>
            <span>{sub}</span>
          </div>
        ))}
    </RowStyled>
  );
});

const ChooseType = memo(({ showFullNavs }) => {
  const { t } = useTranslation();
  const { dispatch } = useCreateContext();

  const [chooseType, setChooseType] = useState();

  const [showAnim, setShowAnim] = useState(false);

  useEffect(() => {
    const selectType = sessionStorage.getItem(LOCAL_KEY);
    if (selectType) {
      setChooseType(selectType);
      showFullNavs(selectType);
      dispatch({ type: "ChooseType", payload: selectType });
    }
  }, []);

  const clickNext = (v) => {
    sessionStorage.setItem(LOCAL_KEY, v);
    dispatch({ type: "ChooseType", payload: v });
    showFullNavs(v, true);
  };

  const SelectIcon = memo(({ isSelect }) => {
    return (
      <SelectStyled>
        {isSelect ? (
          <img src={RadioOnIcon} alt="" />
        ) : (
          <img src={RadioOffIcon} alt="" />
        )}
      </SelectStyled>
    );
  });

  const goLearnMore = (e) => {
    e.stopPropagation();
    setShowAnim(true);
  };

  const CardBorder = () => {
    return (
      <>
        <img className="border leftBorder" src={LftImg} alt="" />
        <img className="border rightBorder" src={RhtImg} alt="" />
      </>
    );
  };

  return (
    <>
      <SelectBox>
        <Card onClick={() => clickNext(DaoType.wallet)}>
          <CardBorder />
          <CardContainer>
            <img src={WalletIcon} alt="" />
            <div className="name">{t("Create.TypeMultiName")}</div>
            {/* <div className="text">{t("Create.TypeMultiText")}</div> */}
            <Tag className="first">
              <TagRow active={1} text={t("Create.ChooseTypeLine1")} />
              <TagRow text={t("Create.ChooseTypeLine2")} />
              <TagRow text={t("Create.ChooseTypeLine3")} />
              <TagRow text={t("Create.ChooseTypeLine4")} />
            </Tag>
            <SelectIcon isSelect={chooseType === DaoType.wallet} />
          </CardContainer>
        </Card>
        <Card onClick={() => clickNext(DaoType.venture)}>
          <CardBorder />
          <CardContainer>
            <img src={VentureIcon} alt="" />
            <div className="name">{t("Create.TypeVentureName")}</div>
            {/* <div className="text">{t("Create.TypeVentureText")}</div> */}
            <Tag className="last">
              <TagRow active={1} text={t("Create.ChooseTypeLine1")} />
              <TagRow active={1} text={t("Create.ChooseTypeLine2")} />
              <TagRow
                active={1}
                text={t("Create.ChooseTypeLine5")}
                subTexts={[
                  t("Create.ChooseTypeLine5Sub1"),
                  t("Create.ChooseTypeLine5Sub2"),
                  t("Create.ChooseTypeLine5Sub3"),
                ]}
              />
              <TagRow active={1} text={t("Create.ChooseTypeLine4")} />
            </Tag>
            <div className="learn" onClick={goLearnMore}>
              {t("Create.LearnMore")}
            </div>
            <SelectIcon isSelect={chooseType === DaoType.venture} />
          </CardContainer>
        </Card>
      </SelectBox>
      {showAnim && (
        <AnimationBg step={1} handleClose={() => setShowAnim(false)} />
      )}
    </>
  );
});

export default ChooseType;

const SelectBox = styled.ul`
  margin-top: 14px;
  margin-left: 13px;
  display: flex;
  @media (max-width: 1440px) {
    margin-top: 0;
  }
`;

const Card = styled.li`
  width: 400px;
  height: 450px;
  position: relative;
  &:first-child {
    margin-right: 120px;
  }

  @media (max-width: 1440px) {
    height: 436px;
  }

  .border {
    position: absolute;
    &.leftBorder {
      left: -1px;
      top: -1px;
    }
    &.rightBorder {
      right: -1px;
      bottom: -1px;
    }
  }

  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(20px);
  overflow: hidden;
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  padding-top: 38px;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.13) 100%
    );
  }

  .name {
    margin-top: 5px;
    margin-bottom: 22px;
    font-size: 20px;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    height: 28px;
    line-height: 30px;
  }
  .text {
    color: #606793;
    line-height: 19px;
  }

  .learn {
    text-align: center;
    margin-top: 10px;
    color: #328aff;
    @media (max-width: 1440px) {
      margin-top: 0;
    }
  }
`;

const SelectStyled = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`;
const UnSelectStyled = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #cfd0db;
`;

const Tag = styled.div`
  width: 293px;
  /* &.first {
    padding-left: 150px;
  }
  &.last {
    padding-left: 80px;
  } */
`;

const RowStyled = styled.div`
  width: 100%;
  margin-bottom: 14px;
  text-align: left;
  .mainLine {
    display: flex;
    align-items: center;
    justify-content: left;
    line-height: 20px;
    color: ${(props) => (props.active ? "#FFFFFF" : "rgba(255,255,255,0.5)")};
    img {
      margin-right: 8px;
      width: 20px;
    }
  }
  .subLine {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 24px;
    line-height: 22px;
    font-size: 12px;
    &:first-child {
      margin-top: 12px;
    }
    .point {
      width: 4px;
      height: 4px;
      background: #328aff;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
`;
