import styled from "styled-components";
import { InputNumber } from "antd";


const MathButton = ({ symbol, primary, ...rest }) => {
  return (
    <MathButtonStyled {...rest} primary={primary}>
      {symbol}
    </MathButtonStyled>
  );
};

export default function TokenInput({ value, onChange, ...rest }) {
  const changeTokenAmount = (v) => {
    onChange(v);
  };

  const minusAmount = () => {
    if (value <= 1) {
      onChange(1, "button");
    } else {
      onChange(value - 1, "button");
    }
  };
  const plusAmount = () => {
    onChange((value || 0) + 1, "button");
  };

  return (
    <EnterInput>
      <MathButton
        symbol="-"
        className="button"
        primary={value > 1}
        onClick={minusAmount}
      />
      <InputAmountStyled
        autoFocus
        controls={false}
        min={1}
        value={value}
        onChange={changeTokenAmount}
        {...rest}
      />
      <MathButton
        symbol="+"
        className="button"
        primary={true}
        onClick={plusAmount}
      />
    </EnterInput>
  );
}

const EnterInput = styled.div`
  display: flex;
  align-items: center;
  .button {
    cursor: pointer;
  }
`;


const MathButtonStyled = styled.div`
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  line-height: 30px;
  text-align: center;
  opacity: ${(props) => (props.primary ? 1 : 0.5)};
`;


export const InputAmountStyled = styled(InputNumber)`
  font-size: 18px;
  margin: 0 8px;
  width: 82px;
  &.ant-input-number-focused {
    box-shadow: none;
  }

  &.ant-input-number {
    border: none;
    background: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-number-input {
    height: 30px;
    text-align: center;
    border-radius: 8px;
    color: #ffffff;
    font-family: "Rubik-Medium";

    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  .ant-input-number-input-wrap {
    line-height: 30px;
  }
`;

