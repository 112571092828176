import { useEffect, memo, useState } from "react";
import { ethers } from "ethers";
import { useDaoContext } from "views/dao/provider";

import { PercentBox } from "./styles";
import Avatar from "components/common/avatar";
import { useTranslation } from "react-i18next";
import { Block } from "./styles";
import AddressBox from "components/common/addressBox";

const RemoveManagerVote = memo(({ data, orgMembersMap }) => {
  const { t } = useTranslation();
  const {
    state: { baseData },
  } = useDaoContext();

  const [toAddress, setToAddress] = useState();
  const [gplist, setGplist] = useState([]);
  const [minSuportRequire, setMinSuportRequire] = useState();

  useEffect(() => {
    if (!data || !Object.keys(orgMembersMap).length) {
      return;
    }
    const wdata = ethers.utils.defaultAbiCoder.decode(
      ["address", "uint256", "uint256", "address[]", "uint256[]"],
      data
    );
    setToAddress(wdata[0]);
    const lst = wdata[3].map((address, i) => ({
      address,
      bonus: wdata[4][i].toNumber() / 100,
      name: orgMembersMap[address],
    }));
    setMinSuportRequire(wdata[2].toNumber());
    setGplist(lst);
  }, [data, orgMembersMap]);

  return (
    <>
      <Block title={t("Vote.Address")}>
        {toAddress && (
          <PercentBox>
            <div className="manager">
              <Avatar
                size="39px"
                address={toAddress}
                style={{ marginRight: "12px" }}
              />
              <div className="info">
                <div className="name">{orgMembersMap[toAddress]}</div>
                <AddressBox address={toAddress} />
              </div>
            </div>
          </PercentBox>
        )}
      </Block>
      {baseData?.dao_type !== 2 && gplist.length > 0 && (
        <Block title={t("Vote.ResetInterest")}>
          <PercentBox>
            {gplist.map((item, index) => (
              <li key={index}>
                <div className="manager">
                  <Avatar
                    size="39px"
                    address={item.address}
                    style={{ marginRight: "12px" }}
                  />
                  <div className="info">
                    <div className="name">{item.name}</div>
                    <AddressBox address={item.address} />
                  </div>
                </div>
                <div className="numBox">
                  {item.bonus}
                  <span>%</span>
                </div>
              </li>
            ))}
          </PercentBox>
        </Block>
      )}

      <Block title={t("Vote.VoteConfigTip")}>
        <span className="confirmBox">{minSuportRequire}</span>{" "}
        {t("Vote.VoteConfigNumber", { gp: gplist.length })}
      </Block>
    </>
  );
});

export default RemoveManagerVote;
