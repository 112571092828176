import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import enUsTrans from "./locales/en-us.json";
import zhCnTrans from "./locales/zh-cn.json";
import viVnTrans from "./locales/vi-vn.json";
import {
    initReactI18next
} from 'react-i18next';

function getStoreLang() {
    return localStorage.getItem('lang');
}

function saveStoreLang(lang) {
    localStorage.setItem('lang', lang)
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enUsTrans,
            },
            zh: {
                translation: zhCnTrans,
            },
            vi: {
                translation: viVnTrans,
            },
        },
        lng: getStoreLang() || "en",
        fallbackLng: "en",
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
i18n.on("languageChanged", (lang)=> {
    saveStoreLang(lang)
})

export default i18n;
