import React, { useEffect, useState, useCallback } from "react";

import { GetGateway } from "api/graphql/dao";
import { useClientContext } from "components/provider/clientProvider";

const useGateway = () => {
  const [gateway, setGateway] = useState();
  const { getClient } = useClientContext();

  useEffect(async () => {
    const data = await getClient(1).request(GetGateway);
    setGateway(data.gateway);
  }, []);

  return gateway;
};

export default useGateway;
