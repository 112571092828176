import General from "components/modal/general";
import styled from "styled-components";

import DropdownImg from "img/swap/dropdown.png";
import NextImg from "assets/images/RightArrow.svg";
import GasImg from "img/gas.png";

import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { useGrantManagerContract, useDaoOrgContract } from "hooks/useContract";
import { useDaoContext } from "views/dao/provider";
import { ethers } from "ethers";
import { useState } from "react";
import { getShortDisplay } from "utils/publicJs";
import { InputNumber } from "antd";
import { useAppContext } from "components/provider/appProvider";
import { useTranslation } from "react-i18next";
import { DAO_ORG, GRANT_MANAGER, VAULT_MANAGER } from "utils/constant";
import useSigner from "hooks/useSigner";
import Done from "./Done";
import FailedModal from "./Failed";
import api from "api";
import { useEffect } from "react";
import { useMemo } from "react";
import { useNetworkData } from "hooks/useNetwork";
import BigNumber from "bignumber.js";
import { valueToBigNumber } from "@aave/math-utils";
import { useDefiContext } from "components/provider/defiProvider";
import { getBalanceInUSD, calculateHealth, formatHealth } from "utils/aave";
import { formatInput2bignumber } from "utils/utils";
import ApplyModal from "components/apps/swap/ApplyModal";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import Button from "components/common/button";
import Max from "components/common/max";
import { formatError } from "api/aave";

const STATUS = {
  Default: 0,
  Success: 1,
  Failed: 2,
};

export default function SupplyModal(props) {
  const { show, closeModal, reserve } = props;
  const { t } = useTranslation();

  const { account } = useWeb3React();
  const {
    state: {
      userUsdData,
      usdPriceEth,
      userData: { currentLiquidationThreshold, healthFactor },
      userDebtReserves,
    },
  } = useDefiContext();

  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();
  const {
    state: { daoId, componentAddressMap },
  } = useDaoContext();
  const grantContract = useGrantManagerContract(
    componentAddressMap.get(GRANT_MANAGER)
  );
  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const signer = useSigner();

  const [balance, setBalance] = useState(0);
  const [balanceDisplay, setBalanceDisplay] = useState(0);
  const [amount, setAmount] = useState();

  const [status, setStatus] = useState(STATUS.Default);
  const [txHash, setTxHash] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [showApply, setShowApply] = useState(false);

  const [newHealth, setNewHealth] = useState(0);

  const heathDisplay = useMemo(() => {
    if (healthFactor) {
      return formatHealth(
        BigNumber(healthFactor.toString()).div(BigNumber(1e18))
      );
    } else {
      return 0;
    }
  }, [healthFactor]);
  
  const hasDebt = useMemo(() => {
    return !!userDebtReserves.length;
  }, [userDebtReserves]);

  const txLink = useMemo(() => {
    if (exploreScan && txHash) {
      return `${exploreScan}tx/${txHash}`;
    }
    return "";
  }, [txHash, exploreScan]);

  const getLimitInfo = useCallback(
    async (tokenAddress, userAccount) => {
      try {
        const result = await grantContract.getTokenLimit(
          tokenAddress,
          userAccount
        );
        const b = result[1].sub(result[0]);
        setBalance(b.toNumber());
        setBalanceDisplay(getShortDisplay(b.toString()));
      } catch (error) {
        console.error("getTokenLimit failed", error);
        return ethers.BigNumber.from(0);
      }
    },
    [grantContract]
  );

  useEffect(() => {
    if (!reserve) {
      return;
    }
    if (!amount || amount < 0) {
      setNewHealth(0);
      return;
    }
    const { totalCollateralUsdVal, totalDebtUsdVal } = userUsdData;
    if (!totalCollateralUsdVal || !totalDebtUsdVal) {
      return;
    }
    const totalCollateralUSD_BN = valueToBigNumber(totalCollateralUsdVal);
    const totalBorrowsUSD_BN = valueToBigNumber(totalDebtUsdVal);

    const currentLiquidationThreshold_BN = valueToBigNumber(
      currentLiquidationThreshold.toString()
    );

    const amountBN = BigNumber(amount).shiftedBy(reserve.decimals);
    const amountUSD_BN = getBalanceInUSD(
      usdPriceEth,
      reserve.price.priceInEth,
      amountBN
    );
   
    const v = calculateHealth(
      totalCollateralUSD_BN.plus(amountUSD_BN.shiftedBy(-reserve.decimals)),
      totalBorrowsUSD_BN,
      currentLiquidationThreshold_BN
    );
    setNewHealth(formatHealth(BigNumber(v)));
  }, [amount, userUsdData, reserve, currentLiquidationThreshold, usdPriceEth]);

  const onClickSuppy = async () => {
    let unlimitPermission = false;
    const vaultAddress = componentAddressMap.get(VAULT_MANAGER);
    try {
      unlimitPermission = await grantContract.checkMethodPermission(
        account,
        vaultAddress,
        "GP_Loan_unlimited"
      );
    } catch (error) {
      console.error("checkMethodPermission GP_Buy_unlimited failed", error);
    }
    if (!unlimitPermission) {
      try {
        const data = await grantContract.checkMethodPermission(
          account,
          vaultAddress,
          "GP_Loan"
        );
        if (!data) {
          setShowApply(true);
          return;
        }
      } catch (error) {
        console.error("checkMethodPermission failed", error);
      }
      try {
        const data = await grantContract.getTokenLimit(
          1,
          reserve.underlyingAsset,
          account,
          ethers.constants.AddressZero
        );
        const delta = data[1].sub(data[0]);
        if (
          BigNumber(amount).gt(
            BigNumber(
              ethers.utils.formatUnits(delta, reserve.decimals).toString()
            )
          )
        ) {
          dispatch({
            type: "MSGTYPE",
            payload: { msg: "Insufficient spending limit" },
          });
          return;
        }
      } catch (error) {
        console.error("getTokenLimit failed", error);
      }
    }

    // dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });
    const params = { amount: amount, symbol: reserve.symbol };

    try {
      const amountBN = formatInput2bignumber(amount, reserve.decimals);
      const res = await api.aave.aaveSupply(daoOrg, signer, {
        amount: amountBN,
        tokenAddress: reserve.underlyingAsset,
      });
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.DefiSupply"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.DefiSupply,
          data: {
            daoId,
            ...params,
          },
        },
      });
      closeModal();
    } catch (error) {
      console.error("aaveSupply failed", error);
      const msg = error?.reason || error?.data?.message;
      if (msg) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: formatError(msg) },
        });
      }
      // setErrorMsg(error?.error?.message);
      // showNotification(
      //   NotificationType.Fail,
      //   t("Notification.DefiSupplyFailed", params)
      // );
    }
  };

  const closeDone = () => {
    setStatus(STATUS.Default);
    closeModal();
  };

  const closeFailed = () => {
    setStatus(STATUS.Default);
    setTxHash();
    closeModal();
  };

  useEffect(() => {
    // if (grantContract && reserve.underlyingAsset) {
    //   getLimitInfo(reserve.underlyingAsset, account);
    // }
    if (reserve && reserve.balance) {
      setBalance(reserve.balance);
      setBalanceDisplay(reserve.balanceDisplay);
    }
  }, [reserve]);
  return (
    <>
      <General
        width="440"
        height="490"
        closeModal={closeModal}
        show={show}
        title={`${t("defi.supply.Supply")} ${reserve.symbol}`}
        titleBold
      >
        <Label className="fist">{t("Amount")}</Label>
        <InputBoxBg>
          <div className="input">
            <InputStyled
              autoFocus
              value={amount}
              min={0}
              controls={false}
              onChange={(v) => setAmount(v)}
            />
            {/* <BalanceBg>1054.97</BalanceBg> */}
            {/* <PriceBox>
            $1.052.70 <span>(0.155%)</span>
          </PriceBox> */}
          </div>
          <div>
            <SelectBox>
              <span className="name">
                <img src={reserve.logo} alt="" />
                {reserve.symbol}
              </span>
            </SelectBox>
            <RhtBalance>
              <div className="balance">
                {t("Balance")} : {balanceDisplay}
              </div>
              <Max t={t} onMax={() => setAmount(balance)} />
            </RhtBalance>
          </div>
        </InputBoxBg>
        <TransactionBox>
          <Label>{t("defi.supply.overview")}</Label>
          <BorderBox>
            <dl>
              <dt>{t("defi.supply.SupplyAPY")}</dt>
              <dd className="first font-medium">
                {reserve.supplyAPYDisplay} %
              </dd>
            </dl>
            <dl>
              <dt>{t("defi.supply.Collateralization")}</dt>
              <dd className="green font-medium">{t("defi.supply.Enabled")}</dd>
            </dl>
            {hasDebt && (
              <dl>
                <dt>{t("defi.supply.HealthFactor")}</dt>
                <dd>
                  <div className="green font-medium">
                    {heathDisplay}
                    {amount > 0 && (
                      <>
                        <img src={NextImg} className="arrow" alt="" />
                      </>
                    )}
                  </div>
                  <div className="tips">
                    {t("defi.supply.Liquidation")} &lt; 1.0
                  </div>
                </dd>
              </dl>
            )}
          </BorderBox>
        </TransactionBox>
        {/* <LastLine>
        <img src={GasImg} alt="" /> $1.77
      </LastLine> */}
        <BtnGroup>
          <Button
            disabled={!amount || amount > balance}
            onClick={onClickSuppy}
            primary
          >
            {t("defi.supply.Supply")} {reserve.symbol}
          </Button>
        </BtnGroup>
      </General>
      {status === STATUS.Success && (
        <Done
          amount={amount}
          symbol={reserve.symbol}
          handleClose={closeDone}
          txLink={txLink}
        />
      )}
      {status === STATUS.Failed && (
        <FailedModal
          title={t("defi.fail.failed")}
          content={t("defi.fail.failedCotent")}
          subContent={errorMsg}
          handleClose={closeFailed}
        />
      )}
      {showApply && (
        <ApplyModal show={showApply} closeModal={() => setShowApply(false)} />
      )}
    </>
  );
}

const Label = styled.div`
  line-height: 20px;
  margin-top: 28px;
  margin-bottom: 12px;
  opacity: 0.5;
  &.fist {
    margin-top: 20px;
  }
`

const InputBoxBg = styled.div`
  width: 100%;
  height: 76px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  display: flex;
  justify-content: flex-between;
  align-items: center;
  padding: 20px 20px;
  .input {
    flex: 1;
  }
`;

const PriceBox = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 10px;
  span {
    color: #62ba46;
  }
`;
const BalanceBg = styled.div`
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
`;

const SelectBox = styled.div`
  height: 34px;
  border-radius: 19px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
 font-size: 16px;
  .name {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-right: 8px;
    }
  }
`;

const BtnGroup = styled.div`
  width: calc(100% - 30px);
  position: absolute;
  bottom: 16px;
  button {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    margin-top: 20px;
  }
`;

const RhtBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  .balance {
    margin-right: 10px;
    opacity: 0.5;
  }
`;

const TransactionBox = styled.div`
  .title {
    padding: 30px 0 10px;
  }
`;
const BorderBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 10px;
  padding: 15px 19px;
  dl {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
 
  dd {
    text-align: right;
    font-size: 16px;
  }
  .green {
    font-size: 14px;
    font-weight: 500;
    color: #62ba46;
    line-height: 19px;
  }
  .tips {
    font-size: 12px;
    line-height: 14px;
    opacity: 0.5;
    margin-top: 10px;
  }
  .arrow {
    position: relative;
    top: -3px;
  }
`;

const InputStyled = styled(InputNumber)`
  background: transparent;
  border: none;
  &.ant-input-number-focused {
    box-shadow: none;
  }
  .ant-input-number-input {
    color: #fff;
    padding: 0;
    font-family: "Rubik-Medium";
  }
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
`;
