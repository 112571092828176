import styled from "styled-components";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import api from "api";

export default forwardRef(function Redemption(props, ref) {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    name: "redemption",
    checkValid() {
      return true;
    },
    getVoteBytes() {
      return api.vote.newTransfrtPeriod();
    },
  }));

  return (
    <div>
      <Tips>
        <div className="tips">{t("Vote.RedemptionTip")}</div>
      </Tips>
    </div>
  );
});

const Tips = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 11px;
  margin-top: -20px;
  margin-bottom: 28px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 16px;
  }
`;
