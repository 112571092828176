import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import General from "../general";
import CopyStr from "../../copy";
import Button, { ButtonGroup } from "../../common/button";

import LinkImg from "img/newHome/link.png";
import { LoadingBox } from "components/LoadingModal";

import SuccessSvg from "components/svg/common/success";
import FailedSvg from "components/svg/common/failed";
import { useCallback } from "react";

export const VoteTxStatus = {
  Default: 0,
  Loading: "loading",
  Success: "success",
  Failed: "failed",
};

const VoteLoadingModal = memo((props) => {
  const {
    status,
    txHash,
    voteId,
    handleRetry,
    endTime,
    handleGoto,
    handleClose,
    handleCloseAll,
  } = props;
  switch (status) {
    case VoteTxStatus.Loading:
      return <VoteLoading handleClose={handleClose} />;
    case VoteTxStatus.Success:
      return (
        <VoteSuccess
          handleClose={handleCloseAll}
          handleGoto={handleGoto}
          voteId={voteId}
          endTime={endTime}
        />
      );
    case VoteTxStatus.Failed:
      return (
        <VoteFailed
          txHash={txHash}
          handleClose={handleClose}
          handleRetry={handleRetry}
        />
      );
    case VoteTxStatus.Default:
    default:
      return <></>;
  }
});
export default VoteLoadingModal;

function VoteLoading(props) {
  const { handleClose } = props;
  const { t } = useTranslation();
  return (
    <General width="365" height="170" closeModal={handleClose}>
      <ImgBox>
        <LoadingBox />
      </ImgBox>
      <TitleBox>{t("Msg.Loading")}</TitleBox>
    </General>
  );
}

const VoteFailed = memo((props) => {
  const { handleClose, txHash, handleRetry } = props;
  const { t } = useTranslation();
  return (
    <General width="365" height="348" closeModal={handleClose}>
      <Box>
        <FailedSvg />
        <Slogan className="font-medium">{t("Msg.VoteFailed")}</Slogan>
        {txHash && (
          <>
            <Tips>{t("Vote.VoteTx")}</Tips>
            <Address>
              {" "}
              <span>{txHash}</span> <CopyStr address={txHash} />
              <img src={LinkImg} alt="" />
            </Address>
          </>
        )}
        <ButtonGroup gap="10px" style={{ marginTop: "38px" }}>
          <Button className="font-medium" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button className="font-medium" onClick={handleRetry} primary>
            {t("Retry")}
          </Button>
        </ButtonGroup>
      </Box>
    </General>
  );
});

const VoteSuccess = memo((props) => {
  const { handleClose, voteId, endTime } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fullhandle, network: networkSimple } = useParams();

  const FormatLeftTime = useCallback(({ leftTime }) => {
    const lt = (leftTime );
    const days = parseInt(lt / 86400);
    let left = lt % 86400;
    const hours = parseInt(left / 3600);
    left = left % 3600;
    const minutes = parseInt(left / 60);
    const seconds = parseInt(left);
    console.log(seconds,minutes)
    if (days > 0) {
      return (
          <>
            <span>{days}</span>d{" "}
            <span>{hours}</span>h{" "}
            <span>{minutes}</span>min left
          </>
      );
      // return `${days} d ${minutes} min left`;
    }
    if (hours > 0) {
      return (
          <>
            <span>{hours}</span>h{" "}
            <span>{minutes}</span>min left
          </>
      );
    }
    if (minutes > 0) {
      return (
          <>
            <span>{minutes}</span>min left
          </>
      );
      // return `${minutes} min left`;
    }
    if(minutes <=0 && seconds>0){
      return <span>1 min left</span>;
    }
    return <span>0 min left</span>;
  }, []);
  const go2voteDetail = useCallback(() => {
    if (voteId !== undefined) {
      navigate(`/${networkSimple}/${fullhandle}/vote/${voteId}`, {
        state: "queue",
      });
    } else {
      navigate(`/${networkSimple}/${fullhandle}/vote/queue`);
    }
    handleClose()
  }, [voteId]);
  return (
    <General width="365" height="308" closeModal={handleClose}>
      <Box>
        <SuccessSvg />
        <Slogan className="font-medium">{t("Success")}</Slogan>
        <Tips>{t("Vote.VoteDuration")}
          <FormatLeftTime leftTime={endTime} /></Tips>
        <ButtonGroup gap="10px" style={{ marginTop: "38px" }}>
          <Button className="font-medium" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button className="font-medium" onClick={go2voteDetail} primary>
            {t("Vote.Go2Vote")}
          </Button>
        </ButtonGroup>
      </Box>
    </General>
  );
});

const ImgBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  img {
    width: 30px;
  }
`;
const TitleBox = styled.div`
  text-align: center;
  padding: 8px 0;
  font-size: 16px;
`;

const Box = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  img {
    width: 34px;
    height: 34px;
  }
`;
const Slogan = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #10164b;
  line-height: 25px;
  margin: 14px 0 16px;
`;
const Tips = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ababbd;
  line-height: 16px;
  span{
    margin-left: 5px;
  }
`;
const Address = styled.div`
  width: 270px;
  font-size: 16px;
  font-weight: 400;
  color: #10164b;
  line-height: 22px;
  word-break: break-all;
  text-align: center;
  margin-bottom: 30px;
  img {
    width: 16px !important;
    height: 16px !important;
  }
`;
