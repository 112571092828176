export const DAO_ORG = "DAO_ORG";
// components
export const VOTE_MANAGER = "VoteManager";
export const VAULT_MANAGER = "VaultManager";
export const ORG_MANAGER = "OrgManager";
export const VENTURE_MANAGER = "VenturesManager";
export const VENTURE_STOCK_MANAGER = "VenturesStockManager";
export const VENTURE_NFT_STOCK_MANAGER = "VenturesNFTStockManager";
export const VENTURE_MANAGEFEE_MANAGER = "ManageFeeVaultManager";
export const GRANT_MANAGER = "GrantMethodManager";
export const VOTE_EXECUTION = "VoteExecutionManager";
export const DAO_ORGANIZATION = "DAO_ORG";

// create status
export const CREATE_STATUS = {
  FAIL: 0,
  PASS: 1,
};

export const LOCALSTORAGE = {
  USER_TOKEN: "SubDAO_user_token",
  TOKEN_EXPIRE: "SubDAO_user_token_expire_at",
  WALLET: "SubDAO_selected_wallet",
  CHAINID: "SubDAO_chain_id",
};

export const PERIOD_NO = {
  RAISE: 0,
  INRETURN: 1,
  MANAGEMENT: 2,
  REDEMPTION: 3,
};

export const LIKE_STATUS = {
  NOT_LIKE: 0,
  LIKE: 1,
};

export const CONNECTION_STATUS = {
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  CONNECTING: "CONNECTING",
};

export const SWAP_CENTER = {
  INDEX: 0,
  MODAL: 1,
  TOKEN: 2,
};

export const SECONDS_PER_YEAR = 31536000;

export const DEV_ENDPOINT = "https://test-backend.subdao.org/dev/query";
export const PROD_ENDPOINT = "https://api.subdao.network/v3/query";


export const DEV_BACKEND = {
  cache: "https://cache-hole-test.shuttleapp.rs",
};
export const PROD_BACKEND = {
  cache: "https://cache-hole-prod.shuttleapp.rs",
};

export const TransactionEvent = {
  NewVote: "new_event",
  HandleVote: "handle_vote", // vote and execute
  CheckVote: "check_vote",
  SendToken: "send_token",
  AddNFT: "add_nft",
  EditName: "edit_name",
  TransferPeriod: "transfer_period",
  Refund: "refund",
  Freeze: "freeze",
  Return: "return",
  Defi: "defi",
  WalletConnect: "wct",
  DoWalletConnect: "do_wct",
  ModifyCloseDate: "close_date",
  ClaimToken: "claim_token",
};


export const ContractVestingType = {
  OneTimeRelease: 0,
  LinearRelease: 1,
  StagedRelease: 2,
};

export const MIN_DURATION = 86400000;

export const NATIVE_TOKEN = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

export const VOTE_TYPE = {
  TRANSFER_TOKEN: "transfer",
  TRANSFER_NFT: "transferNFT",
  TRANSFER_MANAGE_FEE: "transferManageFee",
  TRANSFER_PERIOD: "transferperiod",
  ADD_MANAGER: "acceptgp",
  REMOVE_MANAGER: "impeach",
  APPROVE_MANAGER: "grantmethod",
  MODIFY_MULTISIGN: "multisign",
};