import React, { useReducer, createContext, useContext } from "react";
const AppContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_GATEWAY":
      return { ...state, gateway: action.payload };
    case "CHANGE_WALLET":
      return { ...state, selectedWallet: action.payload };
    case "CHANGE_ACCOUNT":
      return { ...state, selectedAccount: action.payload };
    case "LOADINGTYPE":
      return { ...state, loadingType: action.payload };
    case "MSGTYPE":
      return { ...state, msgType: action.payload };
    case "SWITCH_CHAIN":
      return { ...state, switch2chain: action.payload };
    case "CONNECT_WALLET":
      return { ...state, showWallet: action.payload };
    case "SET_FUNDS_MAP":
      return { ...state, fundsMap: action.payload };
    case "SET_FUNDLIST":
      return { ...state, fundList: action.payload };
    case "SET_ALL_TOKENS":
      return { ...state, tokens: action.payload };
    case "SET_TRENDINGS":
      return { ...state, trendingDaos: action.payload };
    case "SET_RECOMMEND":
      return { ...state, recommendDaos: action.payload };
    case "SET_EXPLORE_SCAN":
      return { ...state, exploreScan: action.payload };
    case "SET_DEFAULT_CHAIN_ID":
      return { ...state, pathChainId: action.payload };
    case "SET_TRANSACTIONS":
      return { ...state, transactions: action.payload };
    case "UPDATE_TRANSACTION":
      const newTrans = state.transactions.map((tx) => {
        if (action.payload.id === tx.id) {
          return action.payload;
        } else {
          return { ...tx };
        }
      });
      return { ...state, transactions: newTrans };
    case "PUT_TRANSACTION":
      console.log("[put]", action.payload);
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      };
    case "APPEND_MULTISIGN_TX":
      const newTx = action.payload;
      const newTransactions = { ...state.txTransactions };
      if (!newTransactions[newTx.daoId]) {
        newTransactions[newTx.daoId] = {};
      }
      newTransactions[newTx.daoId][newTx.id] = newTx.hash;
      return { ...state, txTransactions: newTransactions };
    case "REMOVE_MULTISIGN_TX":
      const tx = action.payload;
      const updateTransactions = { ...state.txTransactions };
      if (updateTransactions[tx.daoId] && updateTransactions[tx.daoId][tx.id]) {
        delete updateTransactions[tx.daoId][tx.id];
      }
      return { ...state, txTransactions: updateTransactions };
    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};

const initState = {
  tokens: [],
  selectedWallet: null,
  msgType: null,
  loadingType: null,
  fundList: [],
  fundsMap: {},
  trendingDaos: [],
  recommendDaos: [],
  exploreScan: "",
  transactions: [],
  gateway: "",
  txTransactions: {},
};

const AppProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => ({ ...useContext(AppContext) });

export default AppProvider;
