import React, { memo } from "react";
import styled from "styled-components";
import InvalidSvg from "../svg/common/invalid";
import { VoteInputBg } from "assets/styles/common";

const CheckInput = memo((props) => {
  const { errorMsg, ...rest } = props;
  return (
    <BorderBox>
      <Input type="text" {...rest} className={errorMsg ? "error" : ""} />
      {errorMsg && (
        <Error>
          <InvalidSvg />
          <span>{errorMsg}</span>
        </Error>
      )}
    </BorderBox>
  );
});

export default CheckInput;

const BorderBox = styled.div`
  position: relative;
`;

const Input = styled.input`
  ${VoteInputBg};
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  box-sizing: border-box;
  border: 0;
  color: #ffffff;
  &.error {
    border: 1px solid rgba(255, 69, 69, 0.5);
  }
`;

const Error = styled.div`
  font-size: 12px;
  color: #853240;
  margin-top: 13px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  line-height: 14px;
  & > span {
    margin-left: 6px;
  }
`;
