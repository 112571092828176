import TagManager from "react-gtm-module";
import { LOCALSTORAGE } from "utils/constant";

export const GTM_EVENT = {
  PAGEVIEW: "pageview",
  CLICK: "customClick",
  META: "metadata",
};

function track(dataLayer) {
  if (process.env.NODE_ENV !== "production") {
    console.info("[GTM]", dataLayer);
  }

  TagManager.dataLayer({
    dataLayer,
  });
}

const _getChainId = () => {
    return localStorage.getItem(LOCALSTORAGE.CHAINID);
}

export const loadGoogleTagManager = () => {
  const GTM_ENVIRONMENT = "test"
  const GOOGLE_TAG_MANAGER_ID = "GTM-5BD8T6P";

  if (!GOOGLE_TAG_MANAGER_ID || !GTM_ENVIRONMENT) {
    console.warn(
      "[GTM] - Unable to initialize Google Tag Manager. `id` or `gtm_auth` missing."
    );
    return;
  }

  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_ID,
    ...GTM_ENVIRONMENT,
    dataLayer: {
      // Must emit (custom) event in order to trigger page tracking
      event: GTM_EVENT.PAGEVIEW,
      chainId: _getChainId(),
      // Block JS variables and custom scripts
      // @see https://developers.google.com/tag-platform/tag-manager/web/restrict
      "gtm.blocklist": ["j", "jsm", "customScripts"],
    },
  });
};


export const trackEvent = ({ event, category, action, label, account }) => {
  const dataLayer = {
    event,
    chainId: _getChainId(),
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    account
  };

  track(dataLayer);
};
