import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useDaoContext } from "views/dao/provider";
import api from "api";
import { useDaoOrgContract, useOrgContract } from "hooks/useContract";
import useSigner from "hooks/useSigner";
import { DAO_ORG, ORG_MANAGER } from "utils/constant";
import CopyStr from "../copy";

import EditSvg from "../../assets/images/icon-edit-blue.svg";
import DoneSvg from "../../assets/images/Completion.svg";
import { useTranslation } from "react-i18next";
import { useAppContext } from "components/provider/appProvider";
import Avatar from "components/common/avatar";
import EmptyBox from "../common/empty";
import LoadingBox from "components/common/loading";
import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import PublicJS from "../../utils/publicJs";
import LftImg from "../../assets/images/left.png";
import RhtImg from "../../assets/images/right.png";

export default function Managers() {
  const { t } = useTranslation();
  const { state: daoState } = useDaoContext();
  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();

  const {
    daoId,
    daoChainId,
    componentAddressMap,
    whoAmI: { isOwner },
  } = daoState;
  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG), daoChainId);
  const orgContract = useOrgContract(componentAddressMap.get(ORG_MANAGER), daoChainId);
  const signer = useSigner();

  const [gplist, setGplist] = useState([]);
  const [loading, setLoading] = useState(true);

  const getList = useCallback(async () => {
    if (!orgContract) {
      return;
    }
    try {
      setLoading(true);
      const { lst } = await api.org.getDaoMembersList(orgContract, 0, 1000);
      console.error(lst)
      setGplist(lst);
    } catch (error) {
      console.error("getDaoMembersList failed", error);
    } finally {
      setLoading(false);
    }
  }, [orgContract]);

  useEffect(async () => {
    getList();
  }, [orgContract]);

  const showEdit = useCallback(
    (index) => {
      const lst = [];
      gplist.forEach((gp, i) => {
        lst.push({
          name: gp.name,
          address: gp.address,
          isEdit: i === index ? true : gp.isEdit,
          newName: i === index ? gp.name : gp.newName,
        });
      });
      setGplist(lst);
    },
    [gplist]
  );

  const cancelEdit = useCallback(
    (index, isSave) => {
      const lst = [];
      gplist.forEach((gp, i) => {
        lst.push({
          name: isSave && i === index ? gp.newName : gp.name,
          address: gp.address,
          isEdit: false,
        });
      });
      setGplist(lst);
    },
    [gplist]
  );

  const saveEdit = async (index) => {
    const gp = gplist[index];
    if (gp.name === gp.newName) {
      // no change
      cancelEdit(index);
      return;
    }
    try {
      // dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });

      const res = await api.org.editMemberName(daoOrg, signer, gp.address, gp.newName);

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.EditName"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.EditName,
          data: { daoId },
        },
      });

      cancelEdit(index);

    } catch (error) {
      console.error("EditMemberName Failed:", error);
      cancelEdit(index);
      // dispatch({
      //   type: "MSGTYPE",
      //   payload: {
      //     msg: "Edit failed",
      //     closable: true,
      //   },
      // });
      // showNotification(NotificationType.Fail, t("Notification.EditNameFailed"));
    }
  };

  const updateGpNewName = useCallback(
    (index, value) => {
      setGplist(
        gplist.map((gp, i) => {
          if (i === index) {
            return { ...gp, newName: value };
          } else {
            return { ...gp };
          }
        })
      );
    },
    [gplist]
  );

  // event
  useSubcribe(TransactionEvent.EditName, (_, { daoId: _daoId }) => {
    if (_daoId === daoId) {
      getList();
    }
  });

  return (
    <Content id="contentBox">
      <div id="inner">
        <LoadingBox loading={loading} />
        <UlBox>
          {!loading && !gplist.length && <EmptyBox />}
          {!!gplist.length && gplist.map((gp, index) => (
            <li key={index}>
              <LftBg />
              <RhtBg />
              <MainContent>
                <Avatar size="48px" address={gp.address} />
                <div>
                  {
                    (isOwner && gp.isEdit ? (
                        <EditLine>
                          <input
                              type="text"
                              className="font-medium"
                              value={gp.newName}
                              onChange={(e) => {
                                updateGpNewName(index, e.target.value);
                              }}
                          />
                          <img src={DoneSvg}
                              style={{ cursor: "Pointer",marginLeft:"12px" }}
                              onClick={() => {
                                saveEdit(index);
                              }}
                              alt=""
                          />
                        </EditLine>
                    ) : (
                        <NameLine>
                          {gp.name && <span className="text">{gp.name}</span>}
                          {
                            isOwner &&   <EditButton className="editButton" src={EditSvg}
                                                     onClick={() => {
                                                       showEdit(index);
                                                     }}
                            />
                          }

                        </NameLine>
                    ))}
                  <div className="address">
                    <span className="addr">{PublicJS.AddresstoShow(gp.address)}</span>
                    <CopyStr address={gp.address} />{" "}
                  </div>
                </div>
              </MainContent>

            </li>
          ))}
        </UlBox>
      </div>
    </Content>
  );
}

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  /* padding: 0 30px 10px 40px; */
  box-sizing: border-box;
  #inner {
    overflow-y: auto;
    position: relative;
    height: 100%;
  }
`;
const UlBox = styled.ul`
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  li {
    width: 281px;
    background: rgba(255,255,255,0.1);
    border-radius: 16px;
    border: 1px solid  rgba(255,255,255,0.1);
    backdrop-filter: blur(20px);
    box-sizing: border-box;
    position: relative;
    float: left;
    
    padding: 24px;
    word-break: break-all;
    margin-right: 16px;
    margin-bottom: 24px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .avatar{
      border-radius:8px!important;
    }
    &:hover{
      background: linear-gradient(136deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.13) 100%);
      .editButton {
        display: block;
      }
    }
    .address{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .addr{
      opacity: 0.32;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }
  }
`;

const NameLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  height: 36px;
  line-height: 36px;
  font-size: 18px;
  .editButton {
    display: none;
  }
  .text {
    font-size: 20px;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    color: #ffffff;
    line-height: 30px;
    margin-right: 12px;
  }
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const EditLine = styled(NameLine)`
  height: 36px;
  line-height: 36px;
  

  img {
    width: 24px;
    height: 24px;
  }

  input {
    
    background: rgba(4,23,53,0.9);
    border-radius: 10px;
    backdrop-filter: blur(12px);
    
    width: calc(100% - 24px);
    height: 32px;
    box-sizing: border-box;
    border: 0;
    padding: 0 15px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
`;

const EditButton = styled.img`
  cursor: pointer;
  width: 24px;
`;
const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`
const RhtBg = styled.div`
  width: 245px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;
`
const MainContent = styled.div`
  position: relative;
  z-index: 9;
`
