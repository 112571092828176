import { memo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import DropSvg from "components/svg/common/select";
import OpenImg from "../../assets/images/Open.svg";

const Select = memo((props) => {
  const { list, onChange, selected, ...rest } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(false);
    });
  }, []);

  const showList = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    setShow(true);
  };

  const choose = useCallback(
    (index, e) => {
      e.stopPropagation();
      setShow(false);
      if (index !== selected) {
        onChange(index);
      }
    },
    [selected]
  );

  return (
    <DropBoxBg onClick={(e) => showList(e)} {...rest}>
        {
            !show &&<div className="inner">
                <span>{list[selected].label}</span>
                <img src={OpenImg} alt=""/>
            </div>
        }

      {show && (
        <DropBox>
          {list.map((item, index) => (
            <li key={index} onClick={(e) => choose(index, e)}>
              {item.label}
            </li>
          ))}
        </DropBox>
      )}
    </DropBoxBg>
  );
});

export default Select;

const DropBoxBg = styled.div`
  width: 200px;
  height: 44px;
  position: relative;
  .inner{

    background: rgba(255,255,255,0.08);
    border-radius: 20px;
    border: 1px solid rgba(255,255,255,0.08);
    padding: 9px 20px 9px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  span{
    padding-left: 24px;
  }
  img{
    width: 20px;
    opacity: 0.32;
  }
`;

const DropBox = styled.ul`
  position: absolute;
  width: 200px;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #1e2b3f;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 12px 16px;

  li {
    padding: 6px 23px;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
    }
  }
`;
