import mainAddress from "./address";
import ArbitrumIcon from "../../assets/chain/Arbitrum.svg";
import ETH from "../../assets/chain/ETH.svg";
import Dapps from "../dapps";

export default {
  name: "Arbitrum",
  simple: "arbitrum",
  mainAddress: mainAddress,
  // aave not support
  aaveGraph: "",
  aaveLendingPool: "",
  aaveLendingPoolProvider: "",
  multicall: "0x5f3e13C4CE9141Daf8f661297229fBeD0A870FCb",
  // swap
  swapRouterV3: "0xE592427A0AEce92De3Edee1F18E0157C05861564",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  // multi-sign default tokens
  multiSignDefaultSupportTokens: ["0x82af49447d8a07e3bd95bd0d56f35241523fbab1"],
  tokenLogo: ETH,
  scan: {
    img: "",
    host: "https://arbiscan.io/",
  },
  chainId: 42161,
  chainIdHex: "0xa4b1",
  chainName: "Arbitrum",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
    logoURI: "https://app.aave.com/icons/networks/ethereum.svg",
  },
  wrappedToken: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
  logo: ArbitrumIcon,
  rpcUrls: ["https://arb1.arbitrum.io/rpc"],
  blockExplorerUrls: ["https://arbiscan.io/"],
  walletConnectDapps: [
    {
      name: "Uniswap",
      link: "https://app.uniswap.org/#/swap?chainId=137",
      logo: Dapps.Uniswap,
    },
    {
      name: "AAVE",
      link: "https://app.aave.com/",
      logo: Dapps.AAVE,
    },
    {
      name: "Sushi",
      link: "https://app.sushi.com/swap?chainId=137",
      logo: Dapps.Sushi,
    },
    {
      name: "Curve",
      link: "https://curve.fi/#/ethereum/swap",
      logo: Dapps.Curve,
    },
    {
      name: "Instadapp",
      link: "https://defi.instadapp.io/",
      logo: Dapps.Instadapp,
    },
    {
      name: "Balancer",
      link: "https://app.balancer.fi/#/",
      logo: Dapps.Balancer,
    },
  ],
};
