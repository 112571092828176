import { ethers } from "ethers";
import React, {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InputNumber } from "antd";

import { useDaoContext } from "../../../views/dao/provider";
import { useOrgContract } from "../../../hooks/useContract";
import { ORG_MANAGER } from "../../../utils/constant";
import api from "../../../api";
import publicJs from "../../../utils/publicJs";

import CheckInput from "../../common/checkInput";
import { useAppContext } from "components/provider/appProvider";
import VentureVoteConfig from "./voteConfig";
import { checkBonus, checkInvalidBonus, formatBonus } from "utils/check";
import { VoteInputBg } from "assets/styles/common";

export default forwardRef(function AddManager(props, ref) {

  const { t } = useTranslation();
  const { state: daoState } = useDaoContext();
  const { componentAddressMap, daoChainId, baseData } = daoState;
  const {
    dispatch,
  } = useAppContext();

  const orgContract = useOrgContract(
    componentAddressMap.get(ORG_MANAGER),
    daoChainId
  );

  const [newGpAddress, setNewGpAddress] = useState();
  const [addressInvalidMsg, setAddressInvalidMsg] = useState();

  const [newGpBonus, setNewGpBonus] = useState();
  const [gplist, setGplist] = useState([]);

  const [minSupportNumber, setMinSupportNumber] = useState();

  const getGpList = useCallback(async () => {
    const { lst } = await api.org.getDaoMembersList(orgContract, 0, 1000);
    console.log(lst);
    setGplist(lst);
  }, [orgContract]);

  useEffect(() => {
    if (orgContract) {
      getGpList();
    }
  }, [orgContract]);

  const onChangeNewGpAddress = useCallback((e) => {
    const v = e.target.value;
    setNewGpAddress(v);
    if (!ethers.utils.isAddress(v)) {
      setAddressInvalidMsg(t("Msg.InvalidAddress"));
    } else {
      setAddressInvalidMsg(null);
    }
  }, []);

  const handleChangeBonus = useCallback(
    (i, v) => {
      const newArray = [...gplist];
      newArray[i].bonus = Number(v);
      setGplist(newArray);
    },
    [gplist]
  );
  const checkRepeatGp = useCallback((list, address) => {
    if (list.find((a) => a.address.toLowerCase() === address.toLowerCase())) {
      return true;
    }
  }, []);

  const checkValid = () => {
    if (!newGpAddress || !ethers.utils.isAddress(newGpAddress)) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidAddressTip"), closable: true },
      });
      return;
    }
    // if (!newGpBonus) {
    //   dispatch({
    //     type: "MSGTYPE",
    //     payload: { msg: "invalid new manager's bonus", closable: true },
    //   });
    //   return;
    // }
    if (
      !minSupportNumber ||
      minSupportNumber <= 0 ||
      minSupportNumber > gplist.length + 1
    ) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidMinSupport"), closable: true },
      });
      return;
    }
    const checkList = [
      ...gplist,
      {
        address: newGpAddress,
        bonus: newGpBonus,
      },
    ];
    if (checkRepeatGp(gplist, newGpAddress)) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: t("Msg.RepeatGP"),
          closable: true,
        },
      });
      return;
    }
    if (baseData?.dao_type === 2) {
      return true;
    }
    if (checkInvalidBonus(checkList)) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: t("Msg.InvalidBonus"),
          closable: true,
        },
      });
      return;
    }
    if (!checkBonus(checkList)) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.BonusMatch"), closable: true },
      });
      return;
    }

    return true;
  };

  useImperativeHandle(ref, () => ({
    name: "add",
    checkValid,
    getVoteBytes() {
      return api.vote.newAddGPVote({
        newGP: newGpAddress,
        minSurportRequire: minSupportNumber,
        gpList: gplist.map((g) => g.address).concat([newGpAddress]),
        gpBonus:
          baseData?.dao_type === 2
            ? gplist.map((g) => formatBonus(0)).concat([formatBonus(100)])
            : gplist
                .map((g) => formatBonus(g.bonus))
                .concat([formatBonus(newGpBonus)]),
      });
    },
  }));

  return (
    <AddBox>
      <dl>
        <dt>{t("Vote.NewAddress")}</dt>
        <dd>
          <CheckInput
            autoFocus
            value={newGpAddress}
            onChange={onChangeNewGpAddress}
            errorMsg={addressInvalidMsg}
          />
        </dd>
      </dl>
      {baseData?.dao_type !== 2 && (
        <dl>
          <dt>{t("Vote.ResetInterest")}</dt>
          <dd>
            <UlBox>
              {gplist.map((gp, index) => (
                <li key={index}>
                  <div className="lft">
                    <span>{t("Vote.Address")}</span>
                    <div className="address">
                      {publicJs.AddresstoShow(gp.address)}
                    </div>
                  </div>
                  <div className="rht">
                    <span>{t("Vote.Bonus")}</span>
                    <BonusInput>
                      <InputNumberStyled
                        value={gp.bonus}
                        controls={false}
                        precision={2}
                        max={100}
                        onChange={(v) => handleChangeBonus(index, v)}
                      />
                      <span>%</span>
                    </BonusInput>
                  </div>
                </li>
              ))}
              <li>
                <div className="lft">
                  <span>{t("Vote.Address")}</span>
                  <div className="address">
                    {newGpAddress && publicJs.AddresstoShow(newGpAddress)}
                  </div>
                </div>
                <div className="rht">
                  <span>{t("Vote.Bonus")}</span>
                  <BonusInput>
                    <InputNumberStyled
                      value={newGpBonus}
                      controls={false}
                      precision={2}
                      max={100}
                      onChange={(v) => setNewGpBonus(v)}
                    />
                    <span>%</span>
                  </BonusInput>
                </div>
              </li>
            </UlBox>
          </dd>
        </dl>
      )}

      <VentureVoteConfig
        minSupportNumber={minSupportNumber}
        updateMinSupportNumber={setMinSupportNumber}
        gpLength={gplist.length + 1}
      />
    </AddBox>
  );
});

const BonusInput = styled.div`
  flex: 1;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const AddBox = styled.div`
  dt {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 19px;
    margin-bottom: 10px;
  }
  dd {
    position: relative;
  }
  dl {
    margin-bottom: 20px;
  }
`;

const UlBox = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }
  input {
    flex-grow: 1;
    width: 50px;
    border: 0;
    background: transparent;
    font-size: 14px;
  }
  .lft,
  .rht {
    ${VoteInputBg};
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .lft {
    margin-right: 20px;
    justify-content: flex-start;
  }
  .rht {
    /* justify-content: space-between; */
  }
  .address {
    padding-left: 10px;
  }
  span {
    opacity: 0.6;
    font-size: 12px;
    white-space: nowrap;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  flex: 1;
  border: none;
  background: transparent;
  .ant-input-number-input-wrap {
    height: 44px;
    line-height: 44px;
  }
  &.ant-input-number-focused {
    border: none;
    box-shadow: none;
  }
  &.ant-input-number .ant-input-number-input {
  }
  input.ant-input-number-input {
    width: 100%;
    height: 44px;
    line-height: 44px;
    color: #ffffff;
  }
`;