import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import General from "./general";
import { InputItem } from "components/common/inputComp";
import Button, { ButtonGroup } from "components/common/button";

import TwitterSvg from "assets/images/create/Twittter.svg";
import DiscordSvg from "assets/images/create/Discord.svg";
import TelegramSvg from "assets/images/create/Telegram.svg";

import { useMutation, useLazyQuery } from "@apollo/client";
import { UpdateDao, CheckHandleUnique } from "api/graphql/dao";
import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "components/provider/appProvider";
import { useDaoContext } from "views/dao/provider";
import { useNavigate } from "react-router-dom";
import getNetworkData from "network";
import { checkInvalidStr } from "utils/check";
import DaoLogo from "components/upload/daoLogo";

export default function EditDAO(props) {
  const { t } = useTranslation();
  const { data: baseData, closeModal } = props;
  const navigate = useNavigate();
  const { chainId } = useWeb3React();
  const { dispatch } = useAppContext();
  const {
    state: { daoId, daoChainId },
    dispatch: dispatchEvent,
  } = useDaoContext();

  const [name, setName] = useState("");
  const [daohandle, setDaohandle] = useState("");
  const [description, setdescription] = useState("");

  const [imgUrl, setimgUrl] = useState("");

  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [telegram, setTelegram] = useState("");

  const [invalidHandle, setInvalidHandle] = useState();

  const logoRef = useRef();

  useEffect(() => {
    if (!baseData) {
      return;
    }
    setName(baseData.name);
    setDaohandle(baseData.handle);
    setdescription(baseData.desc);
    setimgUrl(baseData.logo);

    setTwitter(baseData.twitter_url);
    setDiscord(baseData.discord_url);
    setTelegram(baseData.telegram_url);
  }, [baseData]);

  const [doCheckHandle] = useLazyQuery(CheckHandleUnique, {
    onCompleted: (data) => {
      if (data && data.checkDaoHandleAvailable) {
        setInvalidHandle(undefined);
      } else {
        setInvalidHandle(t("Msg.HandleError"));
      }
    },
    onError: (error) => {
      console.error("====", error);
    },
  });

  const checkDaoHandle = useCallback(
    async (handle) => {
      if (!handle) {
        setInvalidHandle();
        return;
      }
      if (checkInvalidStr(handle)) {
        setInvalidHandle(t("Create.HandleCheck"));
        return;
      }
      doCheckHandle({
        variables: {
          chainId: daoChainId,
          handle: handle,
          isCreate: false,
        },
        fetchPolicy: "network-only",
      });
    },
    [daoChainId]
  );

  useEffect(() => {
    checkDaoHandle(daohandle);
  }, [daohandle]);

  const [updateDao, { loading }] = useMutation(UpdateDao, {
    onCompleted: (data) => {
      console.log("====", data);
      if (data && data.updateDao) {
        closeModal();
        dispatchEvent({
          type: "SET_BASE_DATA",
          payload: {
            ...baseData,
            logo: imgUrl,
            desc: description,
            handle: daohandle,
            name,
            twitter_url: twitter,
            discord_url: discord,
            telegram_url: telegram,
          },
        });
      }
      dispatch({ type: "LOADINGTYPE", payload: null });
      if (daohandle !== baseData.handle) {
        const network = getNetworkData(daoChainId);
        navigate(`/dao/${network.simple}:${daohandle}/dashboard/info`);
      }
    },
    onError: (error) => {
      console.error("BundDao failed", error);
      dispatch({
        type: "MSGTYPE",
        payload: { closable: true, msg: t("Msg.EditBasicFailed") },
      });
      dispatch({ type: "LOADINGTYPE", payload: null });
    },
  });

  useEffect(() => {
    if (loading) {
      dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });
    }
  }, [loading]);

  const submitModify = () => {
    if (!name || !daohandle || !description) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          closable: true,
          msg: t("Msg.EditRequire"),
        },
      });
      return;
    }
    if (invalidHandle) {
      return;
    }
    dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });

    updateDao({
      variables: {
        chainId: chainId,
        daoId: daoId,
        logo: imgUrl,
        desc: description,
        handle: daohandle,
        name,
        twitter_url: twitter,
        discord_url: discord,
        telegram_url: telegram,
      },
    });
  };

  return (
    <General
      closeModal={closeModal}
      width="954"
      height="739"
      sheight="590"
      show={true}
      glass
    >
      <FirstLine>
        <Lft>
          <div className="label">{t("Create.DAOImage")}</div>
          <DaoLogo t={t} path={imgUrl} onChange={(url) => setimgUrl(url)} />
          <div className="tips">{t("Create.DAOImageTip")}</div>
        </Lft>
        <Rht>
          <RhtForm>
            <div className="flexLine">
              <dl>
                <dd>
                  <InputItem
                    required
                    labelSize="16px"
                    title={t("Create.DAOName")}
                    placeholder={t("Create.FillName")}
                    maxLength={32}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </dd>
              </dl>
              <dl>
                <dd>
                  <InputItem
                    required
                    labelSize="16px"
                    title={t("Create.DAOHandle")}
                    placeholder={t("Create.DAOHandleTip")}
                    errorMsg={invalidHandle}
                    maxLength={32}
                    value={daohandle}
                    onChange={(e) => {
                      setDaohandle(e.target.value);
                    }}
                  />
                </dd>
              </dl>
            </div>
            <div className="flexLine">
              <dl>
                <dd>
                  <InputItem
                    labelSize="16px"
                    icon={
                      <img
                        src={TwitterSvg}
                        alt=""
                        style={{
                          width: "20px",
                          marginRight: "6px",
                          verticalAlign: "unset",
                        }}
                      />
                    }
                    title={t("Create.twitter")}
                    value={twitter}
                    prefix="@"
                    onChange={(e) => {
                      setTwitter(e.target.value);
                    }}
                  />
                </dd>
              </dl>
              <dl>
                <dd>
                  <InputItem
                    labelSize="16px"
                    icon={
                      <img
                        src={TelegramSvg}
                        alt=""
                        style={{
                          width: "20px",
                          marginRight: "6px",
                          verticalAlign: "unset",
                        }}
                      />
                    }
                    title={t("Create.telegram")}
                    value={telegram}
                    prefix="http://t.me/"
                    onChange={(e) => {
                      setTelegram(e.target.value);
                    }}
                  />
                </dd>
              </dl>
            </div>
            <Line>
              <dd>
                <InputItem
                  labelSize="16px"
                  icon={
                    <img
                      src={DiscordSvg}
                      alt=""
                      style={{
                        width: "20px",
                        marginRight: "6px",
                        verticalAlign: "unset",
                      }}
                    />
                  }
                  title={t("Create.discord")}
                  placeholder={t("Create.discordTip")}
                  value={discord}
                  onChange={(e) => {
                    setDiscord(e.target.value);
                  }}
                />
              </dd>
            </Line>
            <dl>
              <dd>
                <InputItem
                  required
                  area={true}
                  autoSize={false}
                  areaHeight="106px"
                  labelSize="16px"
                  maxLength={1024}
                  title={t("Create.Description")}
                  placeholder={t("Create.FillDAODescription")}
                  value={description}
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                />
              </dd>
            </dl>
          </RhtForm>
          <ButtonGroup>
            <Button onClick={closeModal}>{t("Cancel")}</Button>
            <Button primary onClick={submitModify}>
              {t("Submit")}
            </Button>
          </ButtonGroup>
        </Rht>
      </FirstLine>
    </General>
  );
}

const FirstLine = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 50px;
  @media (max-width: 1440px) {
    padding-top: 20px;
  }
`;
const Lft = styled.div`
  margin-left: 35px;
  .label {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .tips {
    margin-top: 10px;
  }
  img {
    //width: 124px;
    //height: 124px;
    //border-radius: 14px;
  }
  .title {
    font-size: 16px;
    font-weight: 400;
    color: #10164b;
    line-height: 22px;
  }
  .tips {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6b7;
    line-height: 19px;
    width: 124px;
  }
`;
const Rht = styled.div`
  width: 653px;
  margin-right: 50px;
  dl {
    width: 100%;
  }
  .flexLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    &:first-child {
      margin-bottom: 40px;
    }
    dl {
      width: 48%;
    }
    @media (max-width: 1440px) {
      margin-bottom: 20px;
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }

  /* dd {
    margin-bottom: 20px;
  } */
`;
const Line = styled.dl`
  margin-bottom: 30px;
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
`;
const RhtForm = styled.div`
  margin-bottom: 57px;
  @media (max-width: 1440px) {
    margin-bottom: 24px;
  }
`;
