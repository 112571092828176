import VenturesABI from "abi/web3Abi/VenturesManager.json";
import TemplateConfigABI from "abi/web3Abi/TemplateConfig.json";
import VenturesNFTStockABI from "abi/web3Abi/VenturesNFTStockManager.json";
import VenturesStockABI from "abi/web3Abi/VenturesStockManager.json";

export const getVenturesInfo = async (multicall, venturesAddress) => {
  const calls = [
    "totalGPBonusPercent",
    "ManageFeePercent",
    "getRaisedAmount",
    "RaiseMinSoftCap",
    "RaiseMaxHardCap",
  ].map((name) => ({
    reference: name,
    methodName: name,
    methodParameters: [],
  }));
  const result = await multicall.call([
    {
      reference: "ventures",
      contractAddress: venturesAddress,
      abi: VenturesABI,
      calls,
    },
  ]);
  const r = {};
  result.results.ventures.callsReturnContext.forEach((d) => {
    r[d.reference] = d.returnValues[0];
  });
  return r;
};

export const getAllComponents = async (multicall, templateConfigAddress) => {
  const calls = [
    "VaultManager",
    "ManageFeeVaultManager",
    "VenturesStockManager",
    "VenturesNFTStockManager",
    "VenturesManager",
    "OrgManager",
    "VoteManager",
    "GrantMethodManager",
    "VoteExecutionManager",
  ].map((name) => ({
    reference: name,
    methodName: "labelComponent",
    methodParameters: [name],
  }));
  const result = await multicall.call([
    {
      reference: "templateConfig",
      contractAddress: templateConfigAddress,
      abi: TemplateConfigABI,
      calls,
    },
  ]);
  const r = {};
  result.results.templateConfig.callsReturnContext.forEach((d) => {
    r[d.reference] = d.returnValues[0];
  });
  return r;
};

export const getNftToken = async (
  multicall,
  tokenAddress,
  fundTokenAddress
) => {
  const _calls = [
    "BuyMode",
    "TokenMaxPrice",
    "DiscountExpire",
  ].map((name) => ({
    reference: name,
    methodName: name,
    methodParameters: [],
  }));
  const calls = [
    ..._calls,
    {
      reference: "canTransfer",
      methodName: "CouldTransfer",
      methodParameters: [],
    },
    {
      reference: "price",
      methodName: "BuyPrice",
      methodParameters: [],
    },
    {
      reference: "uri",
      methodName: "uri",
      methodParameters: [0],
    },
  ];
  const result = await multicall.call([
    {
      reference: "nft",
      contractAddress: tokenAddress,
      abi: VenturesNFTStockABI,
      calls,
    },
  ]);
  const r = {};
  result.results.nft.callsReturnContext.forEach((d) => {
    r[d.reference] = d.returnValues[0];
  });
  return r;
};

export const getErc20Token = async (
  multicall,
  tokenAddress,
  fundTokenAddress
) => {
  const calls = [
    "symbol",
    "decimals",
    "name",
    "BuyMode",
    "TokenMaxPrice",
    "DiscountExpire",
  ].map((name) => ({
    reference: name,
    methodName: name,
    methodParameters: [],
  }));
  calls.push({
    reference: "canTransfer",
    methodName: "CouldTransfer",
    methodParameters: [],
  });
  calls.push({
    reference: "price",
    methodName: "BuyPrice",
    methodParameters: [],
  });
  const result = await multicall.call([
    {
      reference: "erc20",
      contractAddress: tokenAddress,
      abi: VenturesStockABI,
      calls,
    },
  ]);
  const r = {};
  result.results.erc20.callsReturnContext.forEach((d) => {
    r[d.reference] = d.returnValues[0];
  });
  return r;
};

export const getFundToken = async (multicall, tokenAddress) => {
  const calls = ["symbol", "decimals", "name"].map((name) => ({
    reference: name,
    methodName: name,
    methodParameters: [],
  }));
  const result = await multicall.call([
    {
      reference: "fund",
      contractAddress: tokenAddress,
      abi: VenturesStockABI,
      calls,
    },
  ]);
  const r = {};
  result.results.fund.callsReturnContext.forEach((d) => {
    r[d.reference] = d.returnValues[0];
  });
  return r;
};

export default {
  getAllComponents,
  getVenturesInfo,
  getNftToken,
  getErc20Token,
  getFundToken,
};
