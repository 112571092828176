import { useEffect, memo, useState } from "react";
import { ethers } from "ethers";
import { useDaoContext } from "views/dao/provider";

import api from "api";
import { AddressBox, Block } from "./styles";
import CopyStr from "components/copy";
import { useAppContext } from "../../provider/appProvider";
import styled from "styled-components";
import Link from "components/common/LinkIcon";
import { useClientContext } from "components/provider/clientProvider";
import TokenDefault from "../../../img/defaultToken.svg";
import { useTranslation } from "react-i18next";
import getIdentity from "utils/tokenIdentity";
import { useNetworkData } from "hooks/useNetwork";
import { getShortDisplay } from "utils/publicJs";

const TransferTokenVote = memo(({ data }) => {
  const { t } = useTranslation();

  const { providers } = useClientContext();
  const {
    state: { tokens },
  } = useAppContext();

  const {
    state: { daoChainId },
  } = useDaoContext();

  const network = useNetworkData(daoChainId);

  const [toAddress, setToAddress] = useState();
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [toValue, setTovalule] = useState("");
  const [logo, setLogo] = useState("");
  const [identity, setIdentity] = useState("");

  const handleToken = async (address, amount) => {
    if (address.toLowerCase() === network.wrappedToken) {
      const nativeCurrency = network.nativeCurrency;
      setTovalule(
        getShortDisplay(
          ethers.utils.formatUnits(amount, nativeCurrency.decimals)
        )
      );
      setTokenSymbol(nativeCurrency.symbol);
      setLogo(network.logo);
      return;
    }

    const provider = providers[daoChainId];
    const erc20 = await api.erc20.getContract(provider, address);
    const token = {
      symbol: await erc20.symbol(),
      deci: await erc20.decimals(),
    };

    setTokenSymbol(token.symbol);
    setTovalule(ethers.utils.formatUnits(amount.toString(), token.deci));
    setIdentity(getIdentity(address));
    const imgArr = tokens.filter(
      (item) => item.address.toLowerCase() === address.toLowerCase()
    );
    setLogo(imgArr[0]?.logoURI);
  };

  useEffect(() => {
    if (!data || !daoChainId) {
      return;
    }
    const wdata = ethers.utils.defaultAbiCoder.decode(
      ["address", "address", "uint256"],
      data
    );
    setToAddress(wdata[1]);
    handleToken(wdata[0], wdata[2]);
  }, [data, daoChainId]);

  const handleError = (e) => {
    e.target.src = TokenDefault;
    e.target.οnerrοr = null;
  };

  return (
    <Box>
      <Block title={t("Vote.Recipient")}>
        <AddressBox>
          {toAddress}
          <div className="optionGrp">
            <CopyStr address={toAddress} />
            <Link hash={toAddress} type="address" />
          </div>
        </AddressBox>
      </Block>
      <Block title={t("Vote.Amount")} className="flexBox">
        <span className="num">{toValue}</span>
        <img src={logo} alt="" className="logo" onError={handleError} />
        <span className="symbol">{tokenSymbol}</span>
        {identity && <span className="identity">{identity}</span>}
      </Block>
    </Box>
  );
});

export default TransferTokenVote;

const Box = styled.div`
  .num {
    font-family: "Rubik-Medium";
    margin-right: 10px;
  }
  .flexBox {
    img {
      width: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .identity {
    margin-left: 5px;
    opacity: 0.5;
  }
`;
