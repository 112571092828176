import Tabs from "../layout/tabs";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import Info from "./Info";
import Contracts from "./contract";
import { useParams, useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMatchRoute, { HomePageRoutes } from "hooks/useMatchRoute";

export default function HomeDefault() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const subRoute = useMatchRoute(HomePageRoutes);

  const { fullhandle, network: networkSimple } = useParams();
  const [current, setCurrent] = useState(0);

  const nav = useMemo(() => {
    return [t("Home.Info"), t("Home.Contracts")];
  }, []);
  const navRoutes = useMemo(() => {
    return ["info", "contracts"];
  }, []);

  useEffect(() => {
    if (!subRoute) {
      setCurrent(0);
      return;
    }
    for (let i = 0; i < navRoutes.length; i++) {
      if (navRoutes[i] === subRoute) {
        setCurrent(i);
        break;
      }
    }
  }, [subRoute]);


  const handleCurrent = (num) => {
    setCurrent(num);
    navigate(`/${networkSimple}/${fullhandle}/dashboard/${navRoutes[num]}`);
  };

  return (
    <InfoBox>
      <Tabs navList={nav} current={current} handleCurrent={handleCurrent} />
      <Content id="contentBox">
        <div id="inner">
          <Routes>
            <Route path="info" element={<Info />} />
            <Route path="contracts" element={<Contracts />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={`/${networkSimple}/${fullhandle}/dashboard/info`}
                />
              }
            />
          </Routes>
        </div>
      </Content>
    </InfoBox>
  );
}

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  margin-top: 16px;
  #inner {
    overflow-y: auto;
    /* height: calc(100vh - 330px); */
    height: 100%;
    position: relative;
  }
`;
