import styled from "styled-components";
import { GlassBG } from "assets/styles/common";
import LftImg from "assets/images/left.png";
import RhtImg from "assets/images/right.png";
import { AnimationBox } from "assets/styles/common";

export default function CardLayout({ children, isReady, hideAnimation, hideBlure, ...rest }) {
  return (
    <Card>
      <AnimateCard
        className={hideAnimation ? "noAnim" : isReady ? "animation" : ""}
      >
        <CardGlassBG hideBlure={hideBlure} />
        <LftBg />
        <RhtBg />
        <CardContainer {...rest}>{children}</CardContainer>
      </AnimateCard>
    </Card>
  );
}

const Card = styled.li`
  width: 24%;
  box-sizing: border-box;
  /* display: flex;
  align-items: flex-start;
  justify-content: space-between; */
  margin-bottom: 16px;
  position: relative;
  height: 160px;
`;

const AnimateCard = styled(AnimationBox)`
  box-sizing: border-box;
  position: relative;
  height: 100%;
  &.noAnim {
    width: 100%;
    overflow: visible;
  }
`;

const CardGlassBG = styled(GlassBG)`
  backdrop-filter: ${({ hideBlure })=> hideBlure ? "unset": "blur(20px)"};
`;

const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`;
const RhtBg = styled.div`
  width: 245px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 20px;
  left: 0;
  top: 0;
  box-sizing: border-box;
`;
