import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg width={34} height={34} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#00C172" strokeWidth={2} cx={17} cy={17} r={16} />
      <path
        d="M16.66 23.447 9.294 17.69l1.047-1.34 6.027 4.71 7.85-10.047 1.34 1.047-8.896 11.387Z"
        fill="#00C172"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
