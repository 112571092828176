import { useState, useEffect } from "react";
import { useOrgContract } from "hooks/useContract";
import { useDaoContext } from "views/dao/provider";

export default function useOrgMembers(orgAddress) {
  const { state: { daoChainId } } = useDaoContext();
  const orgContract = useOrgContract(orgAddress, daoChainId);

  const [gpMap, setgpMap] = useState({});

  useEffect(async () => {
    if (!orgContract) {
      return;
    }
    try {
      const data = await orgContract.getDaoMembersList(0, 5000);
      const { totalMembers, totalNames, total } = data;
      const addressNameMap = {};
      totalMembers.forEach((address, i) => {
        addressNameMap[address] = totalNames[i];
      });
      setgpMap(addressNameMap);
    } catch (error) {
      console.error("getDaoMembersList failed", error);
    }
  }, [orgContract]);

  return gpMap;
}
