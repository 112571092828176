import React, { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAppContext } from "./provider/appProvider";
import Loading from "assets/gif/Loading.gif";

export const LoadingAnim = memo(() => {
  return <img src={Loading} alt="" />;
});

export const LoadingBox = memo(() => {
  return (
    <LoadingBoxStyled>
      <LoadingAnim />
    </LoadingBoxStyled>
  );
});

const LoadingModal = ({ loading }) => {
  const { t } = useTranslation();
  const {
    state: { loadingType },
  } = useAppContext();

  return (
    <LoadingBg>
      <LoadingWrapper>
        <img src={Loading} alt="" />
        <span>{loadingType}</span>
      </LoadingWrapper>
    </LoadingBg>
  );
};

export default LoadingModal;

const LoadingBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(10px);
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 150px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 18px;
  backdrop-filter: blur(32px);
  img {
    width: 63px;
    margin-bottom: 15px;
  }
`;

const LoadingBoxStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 63px;
  }
`;
