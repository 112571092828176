import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import AddManager from "./addManager";
import ManagerTable from "./managerTable";
import BackNav from "components/common/backNav";

import Button from "components/common/button";
import NewVote from "components/modal/newVote";

import { useLazyQuery } from "@apollo/client";
import { getSuperList, SubgraphApiQuery } from "api/graphql/subQuery";
import { useWeb3React } from "@web3-react/core";
import { useDaoContext } from "views/dao/provider";
import api from "api";
import { ethers } from "ethers";
import PaginationBox from "components/common/pagination";
import { useAppContext } from "../../provider/appProvider";
import LoadingBox from "components/common/loading";
import useCheckLogin from "hooks/useCheckLogin";
import { useClientContext } from "components/provider/clientProvider";
import AddImg from "../../../assets/images/icon-add.svg";
import { useNetworkData } from "hooks/useNetwork";
import { formatTextTime } from "utils/utils";

export default function SuperManager() {
  const { t } = useTranslation();

  const { getClient } = useClientContext();
  const { chainId, provider } = useWeb3React();
  const {
    state: { tokens },
  } = useAppContext();
  const {
    state: { daoId, daoChainId },
  } = useDaoContext();

  const network = useNetworkData(chainId);

  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [total, setToal] = useState(0);
  const isLogin = useCheckLogin();
  const [loading, setLoading] = useState(true);


  const CloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleModal = useCallback(() => {

    console.log("====handleModal=====",handleModal)
    setShowModal(true);
  }, []);

  const handleResult = async (tokenAddress) => {
    try {
      const contract = await api.erc20.getContract(provider, tokenAddress);
      const symbol = await contract.symbol();
      const deci = await contract.decimals();
      return {
        symbol,
        deci,
        tokenAddress,
      };
    } catch (error) {
      return {};
    }
  };
  const handleQueryData = useCallback(async(data) => {
    try {
      const res = JSON.parse(data.subgraphApi);
      console.log("====data", res);

      const lst = res.data?.spendLimitRecords || [];
      const reqs = lst.map((l) => handleResult(l.tokenIn));
      const result = await Promise.all(reqs);
      const totalCount = res.data?.totalCount;
      setToal(totalCount && totalCount.count && Number(totalCount.count));
      const pureList = lst.map((r, i) => {
        let imgArr;
        let symbol = result[i].symbol;
        if (r.tokenIn.toLowerCase() === network.wrappedToken) {
          imgArr = network.logo;
          symbol = network.nativeCurrency.symbol;
        } else if (r.tokenIn !== ethers.constants.AddressZero) {
          imgArr = tokens.find(
            (item) =>
              item.address.toLowerCase() === r.tokenIn.toLowerCase()
          )?.logoURI;
        }
        
        let isUnlimit = false
        if (ethers.BigNumber.from(r.limit).eq(ethers.constants.MaxUint256)) {
          isUnlimit = true;
        }
        if (r.tokenIn === ethers.constants.AddressZero && r.tokenOut === ethers.constants.AddressZero) {
          isUnlimit = true;
        }
          return {
            ...r,
            method: r.method,
            address: r.gp,
            expireAt: formatTextTime(Number(r.expire)),
            logo: imgArr,
            all: isUnlimit
              ? "-1"
              : ethers.utils.formatUnits(r.limit, result[i].deci || 18),
            spent: isUnlimit
              ? "-1"
              : ethers.utils.formatUnits(r.spend, result[i].deci || 18),
            symbol: result[i].symbol,
            tokenAddress: result[i].tokenAddress,
          };
      });
      setList(pureList);
    } catch (error) {
      console.error(error);
    }
    setLoading(false)
  }, [tokens]);

  const [queryList] = useLazyQuery(SubgraphApiQuery, {
    onCompleted: async (data) => {
      handleQueryData(data);
    },
    onError(error) {
      console.error("====error", error);
      setLoading(false)
    },
  });

  useEffect(async () => {
    if (!daoId || !daoChainId) {
      return;
    }
    if (isLogin) {
      queryList({
        variables: {
          query: getSuperList(daoId, page, pageSize),
          chainId,
        },
        fetchPolicy: "network-only"
      });
    } else {
      const res = await getClient(daoChainId).request(SubgraphApiQuery, {
        query: getSuperList(daoId, page, pageSize),
        chainId: daoChainId,
      })
      handleQueryData(res);
    }
   
  }, [daoId, daoChainId, page, pageSize, isLogin]);

  const onChangePage = useCallback((v) => {
    setPage(v);
  }, []);

  const onShowSizeChange = useCallback((c, size) => {
    setPagesize(size);
  }, []);

  return (
    <Box>
      {showModal && (
        <NewVote
          selectedAction={true}
          selectedType={3}
          CloseModal={CloseModal}
          showVoteModal={showModal}
        />
      )}
      <BtnBox>
        <BackNav to="app_settings" />
        <Button primary onClick={handleModal}>
          <span>{t("Super.New")}</span>
          <img src={AddImg} alt="" />
        </Button>
      </BtnBox>

      <ContentBox id="contentBox">
        <LoadingBox loading={loading} type="data" />

        <div id="inner">
          {!loading && list.length === 0 && <AddManager />}
          {list.length > 0 && (
            <TopBox>
              <ManagerTable list={list} />
              <Pagination>
                <PaginationBox
                  showQuickJumper
                  pageSize={pageSize}
                  defaultCurrent={page}
                  total={total}
                  onChange={onChangePage}
                  onShowSizeChange={onShowSizeChange}
                />
              </Pagination>
            </TopBox>
          )}
        </div>
      </ContentBox>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentBox = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  position: relative;
  padding: 10px 0 20px;
  box-sizing: border-box;
  #inner {
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
`;
const TopBox = styled.div`
  flex-grow: 1;
  height: 100%;
  position: relative;
`;

const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button{
    width: 238px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    img{
      width: 24px;
      margin-left: 4px;
    }
  }
`;

const Pagination = styled.div`
  text-align: right;
  margin-top: 10px;
  margin-right: 80px;
`;