import SetImg from "assets/images/Setting.svg";
import UniswapImg from "assets/images/vote/Uniswap.svg";

import arrow from "assets/images/Open.svg";
import SwapIcon from "assets/images/Swap.svg"

import styled from "styled-components";
import { useCallback, useEffect, useState, useMemo, memo } from "react";
import SwapConfirm from "./swapConfirm";
import SwapSetting from "./swapSetting";
import SwapLoadingProgress from "./swapLoadingProgress";

import ApplyModal from "./ApplyModal";
import SelectTokenModal from "./tokenModal";

import { useTranslation } from "react-i18next";
import SwapRoute from "./swapRoute";
import useRouter from "hooks/useRouter";
import { useDaoContext } from "views/dao/provider";
import { VAULT_MANAGER, DAO_ORG, GRANT_MANAGER } from "utils/constant";
import { ethers } from "ethers";
import api from "api";
import { useDaoOrgContract, useGrantManagerContract } from "hooks/useContract";
import useSigner from "hooks/useSigner";
import useVaultTokens from "hooks/useVaultTokens";
import { useAppContext } from "components/provider/appProvider";

import { InputNumber } from "antd";
import { useWeb3React } from "@web3-react/core";
import { TokenLogo } from "components/common/avatar";
import { getShortDisplay } from "utils/publicJs";
import { useNetworkData } from "hooks/useNetwork";
import TokenDefault from "../../../img/defaultToken.svg";
import BigNumber from "bignumber.js";
import { useSwapContext } from "components/provider/swapProvider";
import { formatSwapNumber, checkCount } from "utils/utils";
import SwapPrice from "./swapPrice";
import Button from "components/common/button";
import Max from "components/common/max";
import FlagIcon from "assets/images/Flag.svg";
import BgIcon from "assets/images/ModalBg.svg";

const SwapLogo = memo(({ swapApp }) => {
  return swapApp ? (
    <SwapTitle className="font-bold">{swapApp}</SwapTitle>
  ) : (
    <img src={UniswapImg} alt="" />
  );
});

export default function SwapIndex(props) {
  const { t } = useTranslation();

  const { chainId, provider, account } = useWeb3React();

  const {
    state: { componentAddressMap },
  } = useDaoContext();

  const vaultAddress = useMemo(() => {
    return componentAddressMap.get(VAULT_MANAGER);
  }, [componentAddressMap]);
  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const grantContract = useGrantManagerContract(componentAddressMap.get(GRANT_MANAGER));
  const signer = useSigner();

  const {
    state: { tokens },
    dispatch,
  } = useAppContext();

  const {
    dispatchEvent,
    state: { tolerance },
  } = useSwapContext();

  const [showPrice, setShowPrice] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showApply, setShowApply] = useState(false);
  const [showTokenType, setShowTokenType] = useState(0);

  const [fromToken, setFromToken] = useState();
  const [toToken, setToToken] = useState();

  const [fromAmount, setFromAmount] = useState();
  const [toAmount, setToAmount] = useState();
  const [lessToAmount, setLessToAmount] = useState();

  const [swapPrice, setSwapPrice] = useState();
  const [tokenPath, setTokenPath] = useState([]);

  const [swapPath, setSwapPath] = useState([])
  const [version, setVersion] = useState("");

  const networkData = useNetworkData(chainId);

  const swapRouterAddress = useMemo(() => {
    if (networkData) {
      return networkData.swapRouter;
    }
  }, [networkData]);

  const [priceError, setPriceError] = useState("");
  const [gasUSD, setGasUSD] = useState()

  const { swapRoute, routeState } = useRouter(fromToken, toToken, fromAmount);

  const { tokens: vaultTokens, loading: tokenLoading } = useVaultTokens(componentAddressMap.get(VAULT_MANAGER));
  const displayTokens = useMemo(() => {
    return showTokenType === 1 ? vaultTokens : tokens;
  }, [showTokenType, tokens, vaultTokens]);

  const CloseSetting = () => {
    setShowSetting(false);
  };
  const handleSetting = () => {
    setShowSetting(true);
  };

  const closeLoading = useCallback(() => {
    setLoading(false);
  }, []);
 
  // token modal
  const closeToken = useCallback(
    (token) => {
      if (showTokenType === 1) {
        if (token.address) {
          if (!fromToken || fromToken.address !== token.address) {
            setFromToken(token);
            setFromAmount();
            setToAmount();
          } 
          if (toToken && toToken.address === token.address) {
            // clear
            setToToken();
            setToAmount();
          }
        }
      } else if (showTokenType === 2) {
        if (token.address) {
          if (!toToken || toToken.address !== token.address) {
            setToToken(token);
            setToAmount();
          }
          if (fromToken && fromToken.address === token.address) {
            setFromToken();
            setFromAmount();
          }
        }
      }
      setShowTokenType(0);
    },
    [showTokenType]
  );

  useEffect(() => {
    if (swapRoute) {
      setTokenPath(swapRoute.path);
    } else {
      if (fromToken && toToken && swapPrice) {
        setTokenPath([fromToken, toToken]);
      }
    }
  }, [swapRoute, fromToken, toToken, swapPrice]);

  const swap = async () => {
    let unlimitPermission = false;
    try {
      unlimitPermission = await grantContract.checkMethodPermission(
        account,
        vaultAddress,
        "GP_Buy_unlimited"
      );
    } catch (error) {
      console.error("checkMethodPermission GP_Buy_unlimited failed", error);
    }
    if (!unlimitPermission) {
      try {
        const data = await grantContract.checkMethodPermission(
          account,
          vaultAddress,
          "GP_Buy"
        );
        if (!data) {
          setShowApply(true);
          return;
        }
      } catch (error) {
        console.error("checkMethodPermission failed", error);
      }
      try {
        const data = await grantContract.getTokenLimit(
          0,
          fromToken.address,
          account,
          ethers.constants.AddressZero
        );
        const delta = data[1].sub(data[0]);
        if (
          BigNumber(fromAmount).gt(
            BigNumber(
              ethers.utils.formatUnits(delta, fromToken.deci).toString()
            )
          )
        ) {
          dispatch({
            type: "MSGTYPE",
            payload: { msg: t("Swap.Insufficient") },
          });
          return;
        }
      } catch (error) {
        console.error("getTokenLimit failed", error);
      }
    }
    setShowModal(true);
  };
  const confirmSwap = async () => {
    let res
    try {
      setLoading(true);
      console.log("version: ", version)
      if (version === "v3") {
        res = await api.venture.buyTokenV3(daoOrg, signer, {
          swapRouterAddress: networkData?.swapRouterV3,
          paths: swapPath,
          fromAmount: ethers.utils.parseUnits(
            String(fromAmount),
            fromToken.deci || fromToken.decimals
          ),
          toAmount: ethers.utils.parseUnits(
            String(lessToAmount),
            toToken.deci || toToken.decimals
          ),
        });
      } else {
        res = await api.venture.buyToken(daoOrg, signer, {
          swapRouterAddress,
          paths: swapPath.length ? swapPath : [fromToken.address, toToken.address],
          fromAmount: ethers.utils.parseUnits(
            String(fromAmount),
            fromToken.deci || fromToken.decimals
          ),
          toAmount: ethers.utils.parseUnits(
            String(lessToAmount),
            toToken.deci || toToken.decimals
          ),
        });
      }
      dispatchEvent({
        type: "SET_CURRTX",
        payload: {
          from: fromTokenInfo,
          to: toTokenInfo,
          hash: res.hash,
          status: 0
        }
      })
      dispatchEvent({
        type: "ADD_NEW_TX",
        payload: {
          chainId,
          hash: res.hash,
          data: {
            from: fromTokenInfo,
            to: toTokenInfo,
            status: 0
          }
        }
      })
    } catch (error) {
      console.error("cancel swap", error);
      if (error && error.code === 4001) {
        dispatch({ type: "MSGTYPE", payload: { msg: error.message } });
      } else {
        dispatch({ type: "MSGTYPE", payload: { msg: error?.error?.message } });
      }
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  // useEffect(() => {
  //   setFromAmount();
  // }, [fromToken]);
  // useEffect(() => {
  //   setToAmount();
  // }, [fromToken, toToken]);

  useEffect(() => {
    setPriceError("")
  }, [toToken]);

  const fromTokenInfo = useMemo(() => {
    if (!fromToken) {
      return;
    }
    return {
      symbol: fromToken.symbol,
      amount: fromAmount,
      amountDisplay: getShortDisplay(fromAmount, 9),
    };
  }, [fromAmount, fromToken]);

  const toTokenInfo = useMemo(() => {
    if (!toToken) {
      return;
    }
    return {
      symbol: toToken.symbol,
      amount: toAmount,
      amountDisplay: getShortDisplay(toAmount, 9),
    };
  }, [toAmount, toToken]);

  useEffect(() => {
    if (!swapPrice || fromAmount === undefined || !toToken || !fromToken) {
      return;
    }
    console.log("tolerance", tolerance);
    const toBN = BigNumber(swapPrice).multipliedBy(BigNumber(fromAmount));
    const lessBN = toBN.multipliedBy(BigNumber(100 - tolerance).dividedBy(100));
    const toAmountStr = getShortDisplay(
      toBN.shiftedBy(-toToken.decimals).toString(),
      toToken.decimals
    );
    const lessAmountStr = getShortDisplay(
      lessBN.shiftedBy(-toToken.decimals).toString(),
      toToken.decimals
    );
    setToAmount(toAmountStr);
    setLessToAmount(lessAmountStr);
  }, [fromAmount, swapPrice, tolerance]);

  const onChangeFromAmount = (v) => {
    if (v === null) {
      setFromAmount();
      return
    }
    if (!fromToken) {
      setFromAmount(Number(v));
    } else {
      const str = getShortDisplay(v, fromToken.decimals);
      setFromAmount(Number(str));
    }
  }

  const swapData = useMemo(() => {
    if (!toToken || !swapPrice) {
      return {}
    }
    return {
      fromToken,
      toToken,
      fromAmount,
      toAmount,
      swapPrice: swapPrice && BigNumber(swapPrice).shiftedBy(-toToken.decimals).toString(),
      swapRoute,
      lessToAmount,
    };
  }, [fromToken, toToken, fromAmount, toAmount, swapRoute, swapPrice, lessToAmount]);

  const swapBtnDisabled = useMemo(() => {
    if (priceError) {
      return true;
    }
    if (!fromToken || !toToken) {
      return true;
    }
    if (!fromAmount || !toAmount) {
      return true;
    }
    if (!fromToken.balance) {
      return true;
    }
    if (BigNumber(fromToken.balance).lt(BigNumber(fromAmount))) {
      return true;
    }
    // if (!tokenPath.length) {
    //   return;
    // }
    // check from amount deci
    const deci = fromToken.deci || fromToken.decimals;
    if (!checkCount(fromAmount, deci)) {
      return;
    }
  }, [fromToken, toToken, fromAmount, toAmount, tokenPath, priceError]);

  const swapButtonText = useMemo(() => {
    if (!fromToken || !toToken) {
      return;
    }
    if (priceError) {
      return priceError;
    }
    if (!fromToken.balance) {
      return t("Swap.InsufficientBalance", { symbol: fromToken.symbol });
    }

    if (fromAmount) {
      if (BigNumber(fromToken.balance).lt(BigNumber(fromAmount))) {
        return t("Swap.InsufficientBalance", { symbol: fromToken.symbol });
      }
    }
  }, [fromToken, fromAmount, priceError]);

  const selectedAddressList = useMemo(() => {
    switch (showTokenType) {
      case 1:
        return fromToken?.address ? [fromToken.address.toLowerCase()] : [];
      case 2:
        return toToken?.address ? [toToken.address.toLowerCase()] : [];
      default:
        return []
    }
  }, [showTokenType, fromToken, toToken]);

  if (showTokenType > 0) {
    return (
      <SelectTokenModal
        loading={tokenLoading}
        tokens={displayTokens}
        closeModal={closeToken}
        selectedAddressList={selectedAddressList}
      />
    );
  }

  const handleError = (e) =>{
    e.target.src=TokenDefault;
    e.target.οnerrοr=null;
  }

  return (
    <Box>
      {showApply && (
        <ApplyModal show={showApply} closeModal={() => setShowApply(false)} />
      )}
      {showModal && (
        <SwapConfirm
          show={showModal}
          closeModal={() => setShowModal(false)}
          data={swapData}
          handleConfirm={confirmSwap}
        />
      )}
      {loading && (
        <SwapLoadingProgress
          closeModal={closeLoading}
          fromToken={fromTokenInfo}
          toToken={toTokenInfo}
        />
      )}

      <Tips>
        <img src={FlagIcon} alt="" />
        <div>{t("Swap.Intro", { app: networkData?.swapApp || "Uniswap" })}</div>
      </Tips>
      <BoxBtm>
        <BoxShadow>
          <div className="topClose">
            {showSetting && <SwapSetting CloseSetting={CloseSetting} />}

            <img
              src={SetImg}
              alt=""
              className="settingIcon"
              onClick={() => handleSetting()}
            />
          </div>
          <div className="topImg">
            <SwapLogo swapApp={networkData?.swapApp} />
          </div>
          <InputBoxBg>
            <InputStyled
              placeholder="0.0"
              controls={false}
              // precision={2}
              value={fromAmount}
              onChange={onChangeFromAmount}
            />
            <div className="tokenSelectBox">
              <SelectBox
                className={!fromToken ? "redBg" : ""}
                onClick={() => setShowTokenType(1)}
              >
                {fromToken ? (
                  <>
                    <span className="name">
                      <TokenLogo
                        src={fromToken.logoURI}
                        onError={handleError}
                      />
                      {fromToken.symbol}
                    </span>
                    <img src={arrow} alt="" className="arrow" />
                  </>
                ) : (
                  <>
                    <span className="tips">{t("Swap.Select")}</span>
                    <img src={arrow} alt="" className="arrow" />
                  </>
                )}
              </SelectBox>
              {fromToken && (
                <RhtBalance>
                  <div className="balance">
                    {t("Balance")} : {fromToken.balanceDisplay}
                  </div>
                  <Max
                    t={t}
                    onMax={() => {
                      setFromAmount(fromToken.balance);
                    }}
                  />
                </RhtBalance>
              )}
            </div>
          </InputBoxBg>
          <CenterBox>
            <img src={SwapIcon} alt="" />
          </CenterBox>
          <InputBoxBg>
            <InputStyled
              placeholder="0.0"
              controls={false}
              // precision={2}
              // min={0}
              value={toAmount}
              onChange={(v) => setToAmount(Number(v))}
            />
            <div className="tokenSelectBox">
              <SelectBox
                className={!toToken ? "redBg" : ""}
                onClick={() => setShowTokenType(2)}
              >
                {toToken ? (
                  <>
                    <span className="name">
                      <TokenLogo src={toToken.logoURI} onError={handleError} />
                      {toToken.symbol}
                    </span>
                    <img src={arrow} alt="" className="arrow" />
                  </>
                ) : (
                  <>
                    <span className="tips">{t("Swap.Select")}</span>
                    <img src={arrow} alt="" className="arrow" />
                  </>
                )}
              </SelectBox>
            </div>
          </InputBoxBg>
          <MidBox>
            {fromToken && toToken && (
              <>
                <SwapPrice
                  fromToken={fromToken}
                  toToken={toToken}
                  fromAmount={fromAmount}
                  updateSwapPriceValue={setSwapPrice}
                  updatePath={setSwapPath}
                  setVersion={setVersion}
                  swapRouterAddress={swapRouterAddress}
                  updatePriceError={setPriceError}
                  updateGasUSD={setGasUSD}
                >
                  <InsufficientBox>
                    <li>
                      <div className="dl output">
                        <div>
                          <div>{t("Swap.Output")}</div>
                          <div>
                            {getShortDisplay(toAmount)} {toToken.symbol}
                          </div>
                        </div>
                        {/* <div>
                        <div>Price Impact</div>
                        <div>0.24%</div>
                      </div> */}
                      </div>
                      <div className="dl">
                        <div>
                          <div>{t("Swap.Minimum", { tolerance })}</div>
                          <div>
                            {getShortDisplay(lessToAmount)} {toToken.symbol}
                          </div>
                        </div>
                        <div>
                          <div>{t("Swap.NetworkFee")}</div>
                          <div>~${gasUSD}</div>
                        </div>
                      </div>
                    </li>
                  </InsufficientBox>
                  <SwapRoute tokenPath={swapPath} tokens={tokens} />
                </SwapPrice>
              </>
            )}
          </MidBox>

          <ButtonBox>
            <Button
              disabled={swapBtnDisabled}
              onClick={swap}
              height={56}
              primary
            >
              {swapButtonText || t("Swap.Swap")}
            </Button>
          </ButtonBox>
        </BoxShadow>
      </BoxBtm>
    </Box>
  );
}

const Box = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const RhtBalance = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 14px;
  .balance {
    margin-right: 8px;
  }
`;

const InsufficientBox = styled.ul`
  li {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(15px);
    margin-bottom: 10px;
  }
  .dl {
    padding: 14px 12px;
    color: rgba(255, 255, 255, 0.5);
    &.output {
      color: rgba(255, 255, 255, 1);
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-bottom: 12px;
      }
    }
    &:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
    &:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }
`;

const CenterBox = styled.div`
  text-align: center;
  height: 24px;
  img {
    width: 24px;
    transform: rotate(180deg);
  }
`;
const MidBox = styled.div`
  min-height: 30px;
`;

const ButtonBox = styled.div`
  button {
    width: 100%;
  }
`;

const SelectBox = styled.div`
  width: 159px;
  height: 40px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  cursor: pointer;
  .name {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-right: 8px;
    }
  }
  .arrow {
    width: 24px;
  }
  .tips {
    margin-left: 4px;
  }
`;

const InputBoxBg = styled.div`
  width: calc(100% - 40px);
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 16px;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);

  .tokenSelectBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
const BoxBtm = styled.div`
  flex-grow: 1;
`;
const BoxShadow = styled.div`
  margin: 30px auto 0;
  width: 440px;
  box-shadow: 0px 0px 20px 0px rgba(16, 22, 75, 0.1);
  border-radius: 18px;
  padding: 24px 20px;
  position: relative;
  background: ${(props) => props.theme.colors.main} url(${BgIcon}) no-repeat
    left top;

  .topClose {
    position: absolute;
    right: 15px;
    top: 15px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .topImg {
    margin-bottom: 20px;
    text-align: center;
    img {
      width: 136px;
    }
  }
  .settingIcon {
    width: 24px;
  }
`;
const Tips = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #667285;
  width: 280px;
  position: absolute;
  top: 64px;
  left: 40px;
  img {
    width: 24px;
    opacity: 0.6;
    margin-bottom: 30px;
  }
`;

const InputStyled = styled(InputNumber)`
  background: transparent;
  border: none;
  flex-grow: 1;
  &.ant-input-number-focused {
    box-shadow: none;
  }
  .ant-input-number-input {
    color: #ffffff;
    font-size: 22px;
    font-family: "Rubik-Medium";

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const SwapTitle = styled.div`
  font-size: 18px;
`;
