import { gql } from "@apollo/client";
import { GraphQLClient } from "graphql-request";
import { PROD_ENDPOINT, DEV_ENDPOINT } from "utils/constant";

// 01-1-1
export const LoginQuery = gql`
  query MyQuery($sign: String!, $account: String!, $timestamp: Int!, $chainId: Int!) {
    login(sign: $sign, input: { chain_id: $chainId, wallet: $account, timestamp: $timestamp }) {
      token
      tokenExp
    }
  }
`;
