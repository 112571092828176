import { memo, useState, useMemo, useEffect } from "react";
import styled, { css } from "styled-components";
import { Slider } from "antd";
import BigNumber from "bignumber.js";
import { CardBG, FlexRow, Block } from "./style";
import {
  InputLabel,
  InputItem,
  InputNumberItem,
} from "components/common/inputComp";
import { useTranslation } from "react-i18next";
import { useAppContext } from "components/provider/appProvider";
import TokenSelect from "components/common/tokenSelect";
import { OneTimeRelease, LinerRelease, StageRelease } from "./releaseDate";
import CirculateRatio, { Circulation } from "./circulateRadio";
import BondingCurveChart from "./bondingCurveChart";
import DateBox from "components/common/closeDate";
import PriceRatio from "./priceRadio";
import { ethers } from "ethers";
import { getShortDisplay } from "utils/publicJs";

const TokenOneAmount = BigNumber(10 ** 9);

export const ReleaseType = {
  OneTime: "OneTime",
  Liner: "Liner",
  Stage: "staged",
};

const TokenFundraising = memo(({ data, savePage, raiseTime }) => {
  const { t } = useTranslation();

  const [selectToken, setSelectToken] = useState({});
  const [equityToken, setEquityToken] = useState({});

  const [softCap, setSoftCap] = useState();
  const [hardCap, setHardCap] = useState();
  const [circulation, setCirculation] = useState(Circulation.Yes);
  const [price, setPrice] = useState();
  const [priceSelect, setPriceSelect] = useState(0);

  const [originalPrice, setOriginalPrice] = useState();
  const [discountRate, setDiscountRate] = useState();
  const [discountTokenRate, setDiscountTokenRate] = useState();

  const [discountEndTime, setDiscountEndTime] = useState();

  const [curveRate, setCurveRate] = useState(100);

  const releaseTypes = useMemo(() => {
    return [
      {
        label: t("Create.OneTime"),
        value: ReleaseType.OneTime,
      },
      {
        label: t("Create.Liner"),
        value: ReleaseType.Liner,
      },
      {
        label: t("Create.Staged"),
        value: ReleaseType.Stage,
      },
    ];
  }, []);

  const [releaseType, setReleaseType] = useState(ReleaseType.OneTime);

  const [releaseData, setReleaseData] = useState();

  const [tokenValue, setTokenValue] = useState(0);

  const {
    state: { fundList, tokens },
  } = useAppContext();

  const tokenAmount = useMemo(() => {
    if (!originalPrice || !softCap || !discountTokenRate) {
      return 0;
    }
    const tokenOriginalPrice = BigNumber(originalPrice);
    const tokenTarget = BigNumber(softCap).dividedBy(tokenOriginalPrice);
    const discountTokenSupply = tokenTarget.multipliedBy(
      BigNumber(discountTokenRate).dividedBy(BigNumber(100))
    );
    return discountTokenSupply.toFixed(9);
  }, [originalPrice, softCap, discountTokenRate]);

  const initPrice = useMemo(() => {
    if (!originalPrice || !discountRate) {
      return 0;
    }
    return BigNumber(originalPrice)
      .multipliedBy(BigNumber(discountRate))
      .dividedBy(BigNumber(100))
      .toNumber();
  }, [originalPrice, discountRate]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.selectToken) {
      setSelectToken(data.selectToken);
    }
    if (
      data.equityToken &&
      (data.equityToken.name || data.equityToken.symbol)
    ) {
      setEquityToken(data.equityToken);
    }
    if (data.softCap) {
      setSoftCap(data.softCap);
    }
    if (data.hardCap) {
      setHardCap(data.hardCap);
    }
    if (data.price) {
      setPrice(data.price);
    }
    if (data.circulation) {
      setCirculation(data.circulation);
    }
    if (data.releaseType) {
      setReleaseType(data.releaseType);
    }

    if (data.releaseData) {
      setReleaseData(data.releaseData);
    }
    if (data.priceSelect !== undefined) {
      setPriceSelect(data.priceSelect);
    }
    if (data.originalPrice) {
      setOriginalPrice(data.originalPrice);
    }
    if (data.discountRate) {
      setDiscountRate(data.discountRate);
    }
    if (data.discountTokenRate !== undefined) {
      setDiscountTokenRate(data.discountTokenRate);
    }
    if (data.discountEndTime) {
      setDiscountEndTime(data.discountEndTime);
    }
    if (data.curveRate !== undefined) {
      setCurveRate(data.curveRate);
    }
  }, [data]);

  const bindData = (k, v) => {
    return {
      equityToken,
      softCap,
      hardCap,
      selectToken,
      price,
      circulation,
      releaseType,
      releaseData,
      priceSelect,

      originalPrice,
      discountRate,
      discountTokenRate,
      discountEndTime,
      curveRate,
      [k]: v,
    };
  };

  const saveData = (key, value) => {
    const bdata = bindData(key, value);
    savePage(bdata);
  };

  const onChangeFundToken = (token) => {
    setSelectToken(token);
    saveData("selectToken", token);
  };

  const save = () => {
    savePage({
      equityToken,
      softCap,
      hardCap,
      selectToken,
      price,
      circulation,
      releaseType,
      releaseData,
      priceSelect,
      originalPrice,
      discountRate,
      discountTokenRate,
      discountEndTime,
      curveRate,
    });
  };

  const handleEquitToken = (e) => {
    console.error("handleEquitToken");

    const { name, value } = e.target;
    setEquityToken({
      ...equityToken,
      [name]: value,
    });
  };

  const hardcapError = useMemo(() => {
    return !hardCap ? false : softCap >= hardCap;
  }, [softCap, hardCap]);

  const onSelectCirculation = (e) => {
    setCirculation(e.target.value);
    saveData("circulation", e.target.value);
  };

  const onSelectPriceType = (e) => {
    setPriceSelect(e.target.value);
    saveData("priceSelect", e.target.value);
  };

  const onChangeCurveRateSlider = (v) => {
    setCurveRate(v);
    saveData("curveRate", v);
  };

  const onChangeDiscountEndTime = (v) => {
    setDiscountEndTime(v);
    saveData("discountEndTime", v);
  };

  const onChangeReleaseType = (v) => {
    if (v === releaseType) {
      return;
    }
    setReleaseType(v);
    setReleaseData(undefined);
    const bdata = bindData("releaseType", v);
    bdata["releaseData"] = undefined;
    savePage(bdata);
  };

  const onChangeReleaseData = (v) => {
    setReleaseData(v);
    saveData("releaseData", v);
  };

  const _accumulatedPrice = (x, TokenSupply, initialPrice) => {
    const s = TokenSupply.multipliedBy(TokenOneAmount);
    const o = BigNumber(originalPrice)
      .multipliedBy(BigNumber(10 ** selectToken.deci))
      .minus(initialPrice);

    return o
      .multipliedBy(x)
      .multipliedBy(
        x
          .exponentiatedBy(2)
          .dividedBy(3)
          .dividedBy(s)
          .minus(
            BigNumber(curveRate)
              .multipliedBy(x.exponentiatedBy(2))
              .dividedBy(300)
              .dividedBy(s)
          )
      )
      .dividedBy(s)
      .plus(
        o
          .multipliedBy(BigNumber(curveRate).multipliedBy(x.exponentiatedBy(2)))
          .dividedBy(200)
          .dividedBy(s)
      );
  };

  useEffect(() => {
    if (
      !originalPrice ||
      !softCap ||
      !discountRate ||
      !discountTokenRate ||
      !selectToken ||
      !selectToken.deci
    ) {
      setTokenValue(0);
      return;
    }
    const selectTokenDeci = BigNumber(10 ** selectToken.deci);

    const tokenOriginalPrice =
      BigNumber(originalPrice).multipliedBy(selectTokenDeci);

    const tokenTarget = BigNumber(softCap)
      .multipliedBy(selectTokenDeci)
      .dividedBy(tokenOriginalPrice)
      .multipliedBy(TokenOneAmount);
    const TokenSupply = tokenTarget.multipliedBy(
      BigNumber(discountTokenRate).dividedBy(BigNumber(100))
    );

    const initialPrice = tokenOriginalPrice
      .multipliedBy(BigNumber(discountRate))
      .dividedBy(100);

    let totalPrice = _accumulatedPrice(
      TokenSupply,
      TokenSupply,
      initialPrice
    ).minus(_accumulatedPrice(BigNumber(0), TokenSupply, initialPrice));
    totalPrice = totalPrice
      .plus(initialPrice.multipliedBy(TokenSupply))
      .dividedBy(TokenOneAmount);

    setTokenValue(
      getShortDisplay(
        ethers.utils
          .formatUnits(
            ethers.BigNumber.from(totalPrice.toFixed(0)),
            selectToken.deci
          )
          .toString()
      )
    );
  }, [
    originalPrice,
    softCap,
    discountTokenRate,
    discountRate,
    tokenAmount,
    curveRate,
    selectToken,
  ]);

  return (
    <Box>
      <FlexRow>
        <FlexRowBlock>
          <InputLabel required title={t("Create.FundType")} />
          <TokenSelect
            list={fundList.map((f) => {
              const _t = tokens?.find(
                (t) => t.address.toLowerCase() === f.address.toLowerCase()
              );
              const d = { ...f };
              if (_t) {
                d.logo = _t.logoURI;
              }
              return d;
            })}
            selected={selectToken}
            onSelect={onChangeFundToken}
          />
        </FlexRowBlock>
        <FlexRowBlock>
          <InputNumberItem
            required
            title={t("Create.softcap")}
            placeholder={t("Create.Enter")}
            value={softCap}
            controls={false}
            onChange={(v) => {
              (v > 0 || v === null) && setSoftCap(v);
            }}
            onBlur={save}
            suffix={selectToken.symbol}
            // toolTip={t("Hover.Create.Target", {
            //   token: equityToken.symbol,
            //   fund: selectToken.symbol,
            // })}
          />
        </FlexRowBlock>
        <FlexRowBlock>
          <InputNumberItem
            title={t("Create.hardcap")}
            value={hardCap}
            controls={false}
            onChange={(v) => {
              (v > 0 || v === null) && setHardCap(v);
            }}
            onBlur={save}
            isError={hardcapError}
            suffix={selectToken.symbol}
          />
          <UpperTip>{t("Create.UpperTip")}</UpperTip>
        </FlexRowBlock>
      </FlexRow>
      <FlexRow>
        <Block>
          <InputItem
            required
            title={t("Create.TokenName")}
            placeholder={t("Create.FillTokenName")}
            name="name"
            onChange={handleEquitToken}
            onBlur={save}
            autoComplete="off"
            value={equityToken.name}
          />
        </Block>
        <Block>
          <InputItem
            required
            title={t("Create.TokenSymbol")}
            placeholder={t("Create.FillTokenSymbol")}
            name="symbol"
            onChange={handleEquitToken}
            onBlur={save}
            autoComplete="off"
            value={equityToken.symbol}
          />
        </Block>
        <Block />
      </FlexRow>
      <Divider />
      <PriceRatio t={t} value={priceSelect} onChange={onSelectPriceType} />
      {priceSelect === 0 ? (
        <FlexRow>
          <Block>
            <InputNumberItem
              required
              title={t("BondingCurve.TokenPrice")}
              placeholder={t("Create.InputPrice")}
              name="symbol"
              controls={false}
              onChange={(v) => {
                (v > 0 || v === null) && setPrice(v);
              }}
              onBlur={save}
              autoComplete="off"
              value={price}
              suffix={selectToken.symbol}
            />
          </Block>
          <Block></Block>
          <Block></Block>
        </FlexRow>
      ) : (
        <>
          <FlexRow>
            <Block>
              <InputNumberItem
                required
                title={t("BondingCurve.OriginalPrice")}
                name="symbol"
                controls={false}
                onChange={(v) => {
                  (v > 0 || v === null) && setOriginalPrice(v);
                }}
                onBlur={save}
                autoComplete="off"
                value={originalPrice}
                suffix={selectToken.symbol}
              />
            </Block>
            <Block>
              <InputNumberItem
                required
                title={t("BondingCurve.DiscountRate")}
                name="discountRate"
                controls={false}
                precision={0}
                max={100}
                onChange={(v) => {
                  (v > 0 || v === null) && setDiscountRate(v);
                }}
                onBlur={save}
                autoComplete="off"
                value={discountRate}
                suffix="%"
                titleTip={t("BondingCurve.DiscountRateTip")}
              />
            </Block>
            <Block>
              <InputNumberItem
                required
                title={t("BondingCurve.DiscountTokenRate")}
                name="discountTokenRate"
                controls={false}
                precision={0}
                max={100}
                onChange={(v) => {
                  (v > 0 || v === null) && setDiscountTokenRate(v);
                }}
                onBlur={save}
                autoComplete="off"
                value={discountTokenRate}
                suffix="%"
                titleTip={t("BondingCurve.DiscountTokenRateInfo")}
              />
            </Block>
          </FlexRow>
          <FlexRow>
            <Block>
              <InputLabel
                required
                title={t("BondingCurve.DiscountEndDate")}
                titleTip={t("BondingCurve.DiscountEndDateTip")}
              />
              <DateBox
                targetTime={discountEndTime}
                updateTargetTime={onChangeDiscountEndTime}
                notUseQuick={true}
              />
            </Block>
            <Block></Block>
            <Block></Block>
          </FlexRow>
          <FlexRow>
            <Block>
              <InputLabel
                required
                title={t("BondingCurve.BondingCurveRate")}
                titleTip={t("BondingCurve.BondingCurveRateTip")}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <SliderStyled
                  value={curveRate}
                  onChange={onChangeCurveRateSlider}
                />
                <InputNumberItem
                  precision={0}
                  max={100}
                  controls={false}
                  width="120px"
                  value={curveRate}
                  onChange={setCurveRate}
                />
              </div>
            </Block>
            <Block />
          </FlexRow>
          <BondingCurveChart
            graphSize={300}
            value={curveRate}
            tokenAmount={tokenAmount}
            tokenValue={`${tokenValue} ${selectToken?.symbol || ""}`}
            initPrice={initPrice}
            isInitPriceLower={discountRate < 100}
          />
        </>
      )}
      <Divider />
      <CirculateRatio
        t={t}
        value={circulation}
        onChange={onSelectCirculation}
      />
      <InputLabel required title={t("Create.Release")} />
      <FlexRow style={{ marginBottom: 0 }}>
        <div style={{ flex: 1 }}>
          <ReleaseSelect>
            {releaseTypes.map((r, i) => (
              <ReleaseSelectItem
                key={r.value}
                onClick={() => onChangeReleaseType(r.value)}
                selected={releaseType === r.value}
              >
                {r.label}
              </ReleaseSelectItem>
            ))}
          </ReleaseSelect>
          <ReleaseContent>
            {(() => {
              switch (releaseType) {
                case ReleaseType.OneTime:
                  return (
                    <OneTimeRelease
                      data={releaseData}
                      onChange={onChangeReleaseData}
                      closeTime={raiseTime}
                    />
                  );
                case ReleaseType.Liner:
                  return (
                    <LinerRelease
                      data={releaseData}
                      onChange={onChangeReleaseData}
                      closeTime={raiseTime}
                    />
                  );
                case ReleaseType.Stage:
                  return (
                    <StageRelease
                      data={releaseData}
                      onChange={onChangeReleaseData}
                    />
                  );
                default:
                  <></>;
              }
            })()}
          </ReleaseContent>
          <Divider />
        </div>
      </FlexRow>
    </Box>
  );
});

export default TokenFundraising;

const Box = styled.div``;

const ReleaseSelect = styled.div`
  display: flex;
  width: 400px;
  height: 52px;
  line-height: 36px;
  text-align: center;
  box-sizing: border-box;
`;

const ReleaseSelectItemSelect = css`
  border-radius: 8px 8px 0 0;
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
`;

const ReleaseSelectItem = styled.div`
  flex: 1;
  cursor: pointer;
  line-height: 52px;
  color: rgba(255, 255, 255, 0.5);
  ${(props) => props.selected && ReleaseSelectItemSelect};
`;

const UpperTip = styled.div`
  height: 16px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
`;

const FlexRowBlock = styled(Block)`
  margin-left: 40px;
  flex: unset;
  width: 255px;
`;

const SliderStyled = styled(Slider)`
  flex: 1;
  margin-right: 15px;
  .ant-slider-rail {
    height: 6px;
    background: rgba(4, 23, 53, 0.5);
    border-radius: 3px;
  }
  &:hover {
    .ant-slider-rail {
      background: rgba(4, 23, 53, 0.5);
    }
  }
`;
const ReleaseContent = styled.div`
  border-radius: 0 0 8px 8px;
  background: rgba(255, 255, 255, 0.05);
  padding: 28px 24px;
`;

const Divider = styled.div`
  height: 1px;
  opacity: 0.1;
  border-bottom: 1px solid #ffffff;
  margin: 26px 0;
`;
