import { useEffect, useState, useCallback } from "react";
import { useDaoContext } from "views/dao/provider";
import { DaoNFTs } from "api/graphql/dao";
import { useClientContext } from "components/provider/clientProvider";

const formatLogo = (url) => {
  // https://docs.nftscan.com/nftscan/Asset%20Model
  if (!url) return;
  if (url.startsWith("https://")) {
    return url;
  } else if (url.startsWith("data:image")) {
    return url;
  } else if (url.startsWith("ar://")) {
    return `https://arweave.net/${url.slice(5)}`;
  } else if (url.startsWith("ipfs/")) {
    return url.slice(5);
  } else {
    return url
  }
};

const useVaultNFTs = () => {
  const {
    state: { daoId, daoChainId },
  } = useDaoContext();

  const { getClient } = useClientContext();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getList = async () => {
    const client = getClient(daoChainId);
    try {
      const data = await client.request(DaoNFTs, {
        chainId: daoChainId,
        daoId,
      });
      console.log("===data", data);
      const lst = [];
      (data.daoNfts || []).forEach((nft) => {
        let metadata = {};
        try {
          metadata = JSON.parse(nft.metadata_json);
        } catch (error) {}
        if (metadata)
          lst.push({
            ...nft,
            tokenId: nft.id,
            name: metadata.name || nft.contract_name,
            desc: metadata.description || nft.id,
            image: formatLogo(nft.image_uri),
          });
      })
      setList(lst);
    } catch (error) {
      console.error("query DaoNFTs failed", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!daoChainId || !daoId) {
      return;
    }
    getList();
  }, [daoId, daoChainId]);

  return { list, loading };
};

export default useVaultNFTs;
