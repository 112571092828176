import { memo } from "react";
import styled, { css } from "styled-components";
import EmptyIcon from "assets/images/Empty.svg";

const EmptyBox = memo((props) => {
  const { msg, pure, ...rest } = props;
  return (
    <Empty {...rest} pure={pure}>
      <EmptyWrapper pure={pure}>
        <img src={EmptyIcon} alt="" />
        {<Message>{msg || "No datas"}</Message>}
      </EmptyWrapper>
    </Empty>
  );
});
export default EmptyBox;

const Empty = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(props) => (props.pure ? "unset" : "100px")};
  padding-bottom: ${(props) => (props.pure ? "unset" : "30px")};
  img {
    width: 100px;
    margin-top: 10px;
    margin-left: 10px;
  }
`;

const Message = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 18px;
  margin-top: 6px;
`;

const BoxStyle = css`
  width: 190px;
  height: 150px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 18px;
  backdrop-filter: blur(32px);
`;

const EmptyWrapper = styled.div`
  ${({ pure }) => !pure && BoxStyle}
`;
