import React from "react";
import { Routes, Route } from "react-router-dom";

import { SETTINGS_KEY } from "views/appAndsettings";
import SuperManager from "./superManager";
import Period from "./period";

export default function Settings() {
  return (
    <Routes>
      <Route path={SETTINGS_KEY.SUPER_MGR} element={<SuperManager />} />
      <Route path={SETTINGS_KEY.PERIOD} element={<Period />} />
      {/* TODO redirect to app_settings */}
    </Routes>
  );
}
