import mainAddress from "./address";
import BNB from "../../assets/chain/BNB.png";
import BscScan from "img/bscScan.jpeg";

export default {
  name: "BNB Chain",
  simple: "bnbchain",
  mainAddress: mainAddress,
  // aave not support
  aaveGraph: "",
  aaveLendingPool: "",
  aaveLendingPoolProvider: "",
  multicall: "0xA10278BB73774240cfEfC5bE3AE33daDb3a7C755",
  // swap
  swapApp: "PancakeSwap",
  swapRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  // multi-sign default tokens
  multiSignDefaultSupportTokens: ["0xe9e7cea3dedca5984780bafc599bd69add087d56"],
  tokenLogo: BNB,
  scan: {
    img: BscScan,
    host: "https://bscscan.com/",
  },
  chainId: 56,
  chainIdHex: "0x38",
  chainName: "Binance Chain",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
    logoURI: "https://tokens.pancakeswap.finance/images/symbol/bnb.png",
  },
  wrappedToken: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  logo: BNB,
  rpcUrls: ["https://bsc-dataseed1.binance.org"],
  blockExplorerUrls: ["https://bscscan.com/"],
};
