import { useState, useEffect, memo, useMemo, useCallback } from "react";
import styled from "styled-components";
import { ethers } from "ethers";

import { useDaoContext } from "views/dao/provider";
import { VAULT_MANAGER } from "utils/constant";

import { SubgraphApiQuery, getVaultHistory } from "api/graphql/subQuery";

import PaginationBox from "components/common/pagination";
import EmptyBox from "components/common/empty";
import { useAppContext } from "components/provider/appProvider";
import LoadingBox from "components/common/loading";
import { useClientContext } from "components/provider/clientProvider";
import { useNetworkData } from "hooks/useNetwork";
import { useMulticallContract } from "hooks/useContract";
import { QueryTransferList } from "api/graphql/dao";
import NFTsTable from "./nftsTable";
import TokensTable from "./tokensTable";
import { formatTextTime } from "utils/utils";

const TransferHistory = memo(({ selectType }) => {
  const { getClient, providers } = useClientContext();

  const {
    state: { exploreScan, tokens },
  } = useAppContext();
  const { state: daoState } = useDaoContext();
  const { daoId, componentAddressMap, daoChainId } = daoState;

  const network = useNetworkData(daoChainId);
  const contract = useMulticallContract(network?.multicall, daoChainId);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [historylist, sethistorylist] = useState([]);

  const [nftsList, setNftsList] = useState([]);
  const [nftPageList, setNftPageList] = useState([]);

  const [loading, setLoading] = useState(false);

  const vaultAddress = useMemo(() => {
    return componentAddressMap.get(VAULT_MANAGER);
  }, [componentAddressMap]);

  const group = useCallback((array, subGroupLength) => {
    var index = 0;
    var newArray = [];

    while (index < array.length) {
      newArray.push(array.slice(index, (index += subGroupLength)));
    }

    return newArray;
  }, []);

  const parseTokenInfo = useCallback((dataArray) => {
    // symbol
    const symbol = ethers.utils.parseBytes32String(
      "0x" + dataArray[0].slice(130)
    );
    // decimals
    const deci = ethers.BigNumber.from(dataArray[1]).toNumber();
    return {
      symbol,
      deci,
    };
  }, []);

  const handleTokens = async (addresses) => {
    if (!addresses || !addresses.length) {
      return [];
    }
    const iface = new ethers.utils.Interface([
      "function symbol() view returns (string)",
      "function decimals() public view returns (uint8)",
    ]);

    const calls = [];
    for (let i = 0; i < addresses.length; i++) {
      const symbolCallData = iface.encodeFunctionData("symbol", []);
      const decimals = iface.encodeFunctionData("decimals", []);
      const call1 = [addresses[i], symbolCallData];
      const call2 = [addresses[i], decimals];
      calls.push(call1, call2);
    }

    const tokensInfo = await contract.multiCall(calls);
    const groupInfo = group(tokensInfo, 2);

    let tempAddr;
    const tokenMap = {};
    groupInfo.forEach((data, i) => {
      tempAddr = addresses[i].toLowerCase();
      const info = parseTokenInfo(data);
      console.log(info);
      const img = tokens.find(
        (item) => item.address.toLowerCase() === tempAddr
      );
      tokenMap[addresses[i]] = {
        ...info,
        logoURI: img?.logoURI,
      };
    });
    return tokenMap;
  };

  const handleResult = async (data) => {
    const res = JSON.parse(data.subgraphApi);
    const vaults = res.data.vaults;
    const addressMap = {};
    vaults.forEach((v) => (addressMap[v.tokenAddress] = true));
    const tokenMap = await handleTokens(Object.keys(addressMap));

    try {
      const lst = vaults.map((v, i) => {
        const data = tokenMap[v.tokenAddress];
        let img;
        let symbol = data.symbol;
        let name = data.name;
        if (v.tokenAddress.toLocaleLowerCase() === network.wrappedToken) {
          img = network.logo;
          symbol = network.nativeCurrency.symbol;
          name = network.nativeCurrency.name;
        } else {
          const _t = tokens.find(
            (item) =>
              item.address.toLowerCase() === v.tokenAddress.toLowerCase()
          );
          img = _t?.logoURI;
        }
        return {
          ...v,
          symbol,
          name,
          amount: ethers.utils.formatUnits(v.amount, data.deci),
          logoURI: img,
          fromAddress: v.fromAddress || v.vaultContractAddress,
          toAddress: v.toAddress || v.vaultContractAddress,
          date: formatTextTime(Number(v.blockTimestamp)),
          link: `${exploreScan}tx/${v.txHash}`,
        };
      });
      sethistorylist(lst);
    } catch (error) {
      console.error("handle failed", error);
    }
    setTotal(res.data.totalCount && Number(res.data.totalCount.count));
    setLoading(false);
  };

  useEffect(async () => {
    if (selectType !== "nft") return;
    if (!vaultAddress || !daoChainId || !daoId) return;

    try {
      setLoading(true);
      const data = await getClient(daoChainId).request(QueryTransferList, {
        chainId: daoChainId,
        daoId,
        vaultAddress,
      });
      const list = data.nftTransferHistory.data || [];
      setTotal(data.nftTransferHistory.total);
      setNftsList(
        list.map((l) => ({
          ...l,
          link: `${exploreScan}tx/${l.txHash}`,
          date: formatTextTime(Number(l.timestamp)),
          isIn: l.to.toLocaleLowerCase() === vaultAddress.toLocaleLowerCase(),
        }))
      );
    } catch (error) {
      console.error("getNftList failed", error);
    } finally {
      setLoading(false);
      setTotal(0);
    }
  }, [selectType, vaultAddress]);

  useEffect(() => {
    if (selectType !== "nft") return;
    setNftPageList(
      nftsList.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
    );
  }, [page, pageSize, nftsList]);

  useEffect(async () => {
    if (!vaultAddress || !daoChainId) return;
    if (selectType !== "token") return;

    setLoading(true);
    try {
      const data = await getClient(daoChainId).request(SubgraphApiQuery, {
        query: getVaultHistory(
          vaultAddress.toLocaleLowerCase(),
          page,
          pageSize
        ),
        chainId: daoChainId,
      });
      handleResult(data);
    } catch (error) {
      console.error("getVaultHistory", error);
      setLoading(false);
      setTotal(0);
    }
  }, [vaultAddress, page, pageSize, selectType]);

  const onChangePage = useCallback((v) => {
    setPage(v);
  }, []);

  const onShowSizeChange = useCallback((c, size) => {
    setPageSize(size);
  }, []);

  // useEffect(() => {
  //   if (selectType !== "tokens") return;
  // }, [selectType])

  const showEmpty = useMemo(() => {
    if (selectType === "token") return !historylist.length;
    if (selectType === "nft") return !nftsList.length;
    return;
  }, [selectType]);

  return (
    <>
      <TableBox>
        {selectType === "nft" && <NFTsTable list={nftPageList} />}
        {selectType === "token" && <TokensTable list={historylist} />}
        <Pagniation>
          <PaginationBox
            showQuickJumper
            pageSize={pageSize}
            defaultCurrent={page}
            total={total}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
          />
        </Pagniation>
        {!loading && !total && <Empty pure={true} />}
      </TableBox>
      <Loading loading={loading} />
    </>
  );
});

export default TransferHistory;

const TableBox = styled.div`
  min-height: calc(100%);
  box-sizing: border-box;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  overflow: hidden;
  table {
    width: 100%;

    overflow: hidden;
  }
  th {
    font-size: 14px;
    font-weight: 400;
    color: #4a84ff;
    height: 72px;
    text-align: left;
    &:first-child {
      border-top-left-radius: 16px;

      padding-left: 30px;
    }
    &:last-child {
      border-top-right-radius: 16px;
      text-align: center;
    }
  }
  td {
    white-space: nowrap;
    height: 62px;
    font-size: 16px;
    font-weight: 400;
    color: #10164b;
    vertical-align: middle;
    &:first-child {
      text-align: left;
      padding-left: 20px;
    }
    &:last-child {
      text-align: center;
    }
  }
  .walletIcon {
    width: 24px;
  }
  tr:nth-child(odd) {
    td {
      background: rgba(255, 255, 255, 0.04);
    }
  }
`;

const Pagniation = styled.div`
  text-align: right;
  margin: 30px 80px;
`;


const Loading = styled(LoadingBox)`
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
`;
const Empty = styled(EmptyBox)`
  padding-top: 150px;
`;