export default {
  DaoFactory: "0x18447858c04533a68f90bDd5DCEEB649AaaB4c2D",
  actionGrantMethodApply: "0xD5677426349B57dE20F90E08B564a33a994053a6",
  actionGrantMethodRegister: "0x503df0770c739DAF83825BCaD2700fCB6a7a0751",
  actionGrantMethodVoteNewAuth: "0x9d4d4B81AA679571F8C3658FFcC439AAF76275F7",
  actionOrgApplyJoin: "0x5a029F70963f93a34ED47adBdC3B5B3162Bd0f2d",
  actionOrgMemberJoin: "0xf6c9662b09a9b1f0A08519fC61114383764493c1",
  actionOrgMemberQuit: "0xe2e43bad27689eB2a0c01F74F468f25ad498C9Ba",
  actionOrgMgr: "0x2F6370be28DBba2502F52D2a153031625F7019aD",
  actionOrgSetJoinMode: "0x0DF4e525aA4fc6b9Fc2EF71B42B41e45A5d3c5B9",
  actionOrgTransferOwner: "0xfd1eaBd4A62B65BB239011a5A924221aAAFE2Bb6",
  actionOrgUpdateMembers: "0x27BE248E4199c56D8014E55709E02248B2003110",
  actionVaultAddNFT: "0x6ff1CF18c27bB2F71d8133A0795E6591dcccCC13",
  actionVaultDepositToken: "0xa074cEBFB8638FeD0aC477dbEA363410993Cc549",
  actionVaultExeWithdrawToken: "0x4f7b6d2B9b7001B03d7bdA8Ead0679dE77d93afB",
  actionVaultGPAuthorize: "0x0990a24ea4FE1dc9f41A2BaF1764644154aC9EAb",
  actionVaultOptToken: "0x6447c7248417dDc7c6CBe8511e6C85ed39602913",
  actionVaultWithdrawToken: "0x0E4f5A5162E176BD8cfd419f00Df700AC0020d61",
  actionVenturesClaimBonus: "0xbc97A71955a14a01344c03a806fe66e8d4fc1cEE",
  actionVenturesClaimShare: "0x01A01ec083503B938e948465b18E8D3ACA997Dce",
  actionVenturesRaiseFailClaim: "0x1466C69b174e818Bb9979ebE9Cc887fA1Cae6893",
  actionVenturesRaiseMoney: "0xe5d9baCe154eA7F57904Cc052c2f24EB3a319E38",
  actionVenturesSetRaiseSubTime: "0x3377fEC4d81c7888BFA074a20936649f720c69c0",
  actionVenturesToFreezingPeriod: "0x226d3BFd2E9D700C7ed8d0F2C98a0884d4547841",
  actionVenturesToRaiseFail: "0x35F0a239Aeb9D6590CE330C420C1DDe8f399E2E4",
  actionVenturesVestingWithdraw: "0x266487D981dB4E2b30994f3AEb8A488EA956f6f1",
  actionGrantMethodExe: "0x36C5373DC33868CF3f04cE3cC36C413103596757",
  actionManageFeeTransferExe: "0xbA79f44eCfA86d4b9dc9D95d9d65040691f1f631",
  actionOrgAcceptGPExe: "0x724a4b5609d0c1a84420a98b5049FAfB810d483b",
  actionOrgImpeachGPExe: "0xBab47398e6D6317e52C38864aBceBc3dF9ae4720",
  actionVaultTransferExe: "0x61f0Ccf170dad8f976B20B7B8a0Ac4870B096eE7",
  actionVaultTransferNFTExe: "0x1aca2Bea0d09632AE70fB9A420d447C1480334da",
  actionVentureTransferPeriodExe: "0x1616adD140038579319EFbDdB29b913C79C9bC83",
  actionOrgUpdateMinRequireExe: "0xD43B9aa29220f2D8460aDFe6E3c23f157f093C69",
  actionVaultUniswapV3Router: "",
  actionVaultUniswapV2Router02Swap:
    "0x1b0C317b0978D24D8Fd03f31D51B1114448d378E",
};