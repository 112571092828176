export default {
  "DaoFactory": "0x596d75157C306dD7Eb97CdE12F0d94338aE64074",
  "actionGnosisConfirmProposal": "0x64637C4447084649C9F27E653aAC8Ca1C10e0555",
  "actionGnosisSubmitProposal": "0x8E86dBdbD86c488f31D713479899751e806fACd0",
  "actionGrantMethodApply": "0xB99a2832CDfc8cfCB45e03fFca83AD68EC834879",
  "actionGrantMethodRegister": "0xdd6D256Cc3DFA6da6446f253AcCb896d607D356C",
  "actionGrantMethodVoteNewAuth": "0x559D719c257a8485F84d7c08A0b0ce7e5729ef31",
  "actionOrgApplyJoin": "0xdEAD3bDBe5d11eD53FA20e0fA7702C564beFc6dB",
  "actionOrgMemberJoin": "0x431a7C6B28787dff79648D0d61bc0790D073cd52",
  "actionOrgMemberQuit": "0x84ff18A4f57CEdfAe60c263110740b6Dc5f9bf33",
  "actionOrgMgr": "0x099539064395E12d94A2D2c540342DeFb014d3bd",
  "actionOrgSetJoinMode": "0xEB6ED8f9E864a6694C2bdd36eFBc196B078be4a1",
  "actionOrgTransferOwner": "0x8B71502Db66e61B0e28de61D5FF6c02E7E000d45",
  "actionOrgUpdateMembers": "0x675fbb3fD9A3f55Ca34102AEbB369DE3482BB369",
  "actionVaultAave": "0x77f2270D09E4ccB0997d51978dC7Df2025A620dC",
  "actionVaultAddNFT": "0x56613b69e063E69933Ae7180788f9a946f13C77e",
  "actionVaultDepositToken": "0xffa5a8712D9db147037677CCc22b7282B602686A",
  "actionVaultExeWithdrawToken": "0x1Ce1f02166dEa02b874C4F4327553Af6FB4a45C3",
  "actionVaultGPAuthorize": "0x1d180932D58A114065Fe0eD0C03054854D16d969",
  "actionVaultOptToken": "0x03bFe1A536eFaA604958e53E5c1EBe07e6B268e5",
  "actionVaultUniswapV2Router02Swap": "0x6ace4E116dEB17fDd15401145397EbeC566cf420",
  "actionVaultWithdrawToken": "0x7de5795944C2264d3d5710D4F3Fc399372CAa858",
  "actionVenturesClaimBonus": "0x94Ac8867b625F3c5cE72E83E9A95220Bd2D75BD6",
  "actionVenturesClaimShare": "0x6bB5f77A39F22E1a51a0641ead8da2e713A93bB3",
  "actionVenturesRaiseFailClaim": "0xA1759fA6730b32E1059AC3a5c2Bfd86F340F0C13",
  "actionVenturesRaiseMoney": "0x320B99A4bc0ce3F7c36b8EB6BE81B729789Ca237",
  "actionVenturesTestToSettlementPeriod": "0xA5070B4E81CD64D00Caed3e476Bd2AbA9574B411",
  "actionVenturesToFreezingPeriod": "0x320DA15C16D3E54f566bc0f4DEb35CC47f0b474c",
  "actionVenturesToRaiseFail": "0x3c522bdD4aB28c57FF3e30e03a8B81e7F1Ae61A8",
  "actionVoteAcceptGpApply": "0x7A307B339e03Ee10b0e1B9D84953Ad5f19E81Fbe",
  "actionVoteAcceptGpRegister": "0x91d472EBd47aB3e1C102714BCF2D2f6a50736759",
  "actionVoteCancel": "0x6BbA70b2f243c9fB63e89B96f4F56dd796D9Fabe",
  "actionVoteExecute": "0x31c1C4c8b66063E1E0D139F9AD9b5EC983734736",
  "actionVoteImpeachApply": "0x5b792f897160c90b46420e551FaCd2Ebc756e672",
  "actionVoteImpeachRegister": "0xc2f76172cb23e6740Ccd1623968B229bcf05e151",
  "actionVoteNew": "0x33A8b7f4df887018457674026C01e0E6Af3e80c2",
  "actionVoteTransferManageFeeApply": "0x4d5bB427A35aa4B01A44598478D45C3dD90ABCC6",
  "actionVoteTransferManageFeeRegister": "0x991DcE8Dc546adAe6582877bD2A44EBE8944F2B9",
  "actionVoteTransferNFTApply": "0xbB6B88507F07128591Ed6415FFc6eA156A52dEE2",
  "actionVoteTransferNFTRegister": "0xB66E837f6f75640Cb5c8C410D06a8d21795d3953",
  "actionVoteTransferPeriodApply": "0xb73F76857F75437823C3889370D79eaa9a8cC63e",
  "actionVoteTransferPeriodRegister": "0x2Ea510d4A06254e142Ff906e7E3592BDe79d2507",
  "actionVoteVote": "0x66350555C00e0E43db14703BCcFBbcb592038a47",
  "actionVenturesSetRaiseSubTime": "0x84162eC511C9D367A1c5D59b706E5B93AFaF7e07",
  "actionVenturesVestingWithdraw": "0xb6bD16133c892f1cD462f8438cf244D596e82837",
  "actionVaultUniswapV3Router": "0x1464fF35A96635dB0Eab8f24452cF737AE261743",
  "actionGrantMethodExe": "0x1488F56e91E23E6323A5116fF12b92EF49B8D6d3",
  "actionManageFeeTransferExe": "0xe90C5fECDf0A8eA1116e0f870Ad3B08aD0C729CF",
  "actionOrgAcceptGPExe": "0xA04c128F7a60177691dE684703b89243941D9A16",
  "actionOrgImpeachGPExe": "0x5A432bcc9E0AFfB11322589Ec357bF70AeE5B853",
  "actionVaultTransferNFTExe": "0xc6960947D49ae82DBb80f40c0FD350102A1C619F",
  "actionVentureTransferPeriodExe": "0xAd8Dc273655905CF52dDf2CeeeA136b96FE4Cc75",
  "actionVaultTransferExe": "0x43E18793c724173f5A97c5Abfb43374F2CDd04A4",
  "actionOrgUpdateMinRequireExe": "0x298f269A50b79Af004a0Fe33c5f24B2dD914c734"
};
