import React, { useCallback, useMemo, useState } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import LoadingNew from "components/loadingNEW";

import { CONNECTION_STATUS } from "utils/constant";
import { useWCTContext } from "components/provider/WCTProvider";
import BackNav from "../../common/backNav";
import { SETTINGS_KEY } from "../../../views/appAndsettings";
import { useLocation } from "react-router-dom";
import WalletConnectImg from "assets/app/wallet.svg";
import Qrcode from "assets/images/Qrcode.svg";

const WalletConnectDefault = (props) => {
  const { t } = useTranslation();

  const {
    state: { status: connectionStatus, wcClientData },
    dispatch: dispatchEvent,
  } = useWCTContext();

  const handlePaste = (event) => {
    const items = event.clipboardData.items;

    for (const index in items) {
      const item = items[index];

      if (item.kind === "string" && item.type === "text/plain") {
        dispatchEvent({
          type: "SET_STATUS",
          payload: CONNECTION_STATUS.CONNECTING,
        });
        dispatchEvent({
          type: "SET_WCURI",
          payload: event.clipboardData.getData("Text"),
        });
      }
    }
  };

  const statusDisplay = useMemo(() => {
    switch (connectionStatus) {
      case CONNECTION_STATUS.CONNECTING:
        return <LoadingNew />;
      default:
        // return connectionStatus;
        return <></>;
    }
  }, [connectionStatus]);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const txId = query.get("tx");

  const backTo = useMemo(() => {
    return txId ? `app/${SETTINGS_KEY.WCT}` : "app_settings";
  }, [txId]);

  const DisconnectDisplay = () => {
    return (
      <MidBox>
        <WalletConnectStyled>
          <div className="content">
            <div className="connectWrapper">
              <LogoBox>
                <img src={WalletConnectImg} alt="" />
              </LogoBox>
              <span className="logo">{t("walletConnect.title")}</span>
            </div>
            <Tips>{t("walletConnect.beforeConnectIntro")}</Tips>
            <InputBox className="connectWrapper">
              <QrcodeStyled src={Qrcode} alt="" />
              <InputStyled
                onPaste={handlePaste}
                placeholder={t("walletConnect.qrcodePlaceholder")}
              />
              <span className="status">{statusDisplay}</span>
            </InputBox>
          </div>
        </WalletConnectStyled>
      </MidBox>
    );
  };

  return (
    <Box>
      <BackNav to={backTo} state="" />
      <DisconnectDisplay />
    </Box>
  );
};

export default WalletConnectDefault;

const Box = styled.div`
  height: 100%;
`;
const Tips = styled.div`
  margin: 16px 0;
  opacity: 0.5;
`;

const WalletConnectStyled = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 77px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .connectWrapper {
    display: flex;
    align-items: center;

    .logo {
      font-size: 16px;
      font-family: "Poppins-Medium";
      font-weight: 500;
      color: #328aff;
      margin-left: 16px;
    }
  }
`;

const MidBox = styled.div`
  height: calc(100% - 56px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
`;

const InputBox = styled.div`
  width: 442px;
  height: 40px;
  background: rgba(4, 23, 53, 0.5);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  position: relative;
`;

const InputStyled = styled.input`
  background-color: transparent;
  flex: 1;
  padding: 0 15px;
  color: #fff;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const QrcodeStyled = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 12px;
`;

const LogoBox = styled.div`
  width: 48px;
  height: 48px;
  background: rgba(50, 108, 255, 0.28);
  border-radius: 8px;
  text-align: center;
  padding-top: 14px;
  box-sizing: border-box;
`;
