import styled from "styled-components";
import Button, { ButtonGroup } from "components/common/button";

export default function ModalFooter({ t, onCancel, onSubmit, submitLoading, ...rest }) {
    return (
      <Footer {...rest}>
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button primary onClick={onSubmit} loading={submitLoading}>
          {t("Submit")}
        </Button>
      </Footer>
    );
}

const Footer = styled(ButtonGroup)`
    position: absolute;
    bottom: 24px;
    right: 25px;
`;
