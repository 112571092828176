import General from "../../modal/general";
import styled from "styled-components";
import RemoveImg from "assets/images/clear.svg";
import SuccessImg from "../../../img/swap/sucess.png";
import { useSwapContext } from "components/provider/swapProvider";
import { useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function SwapPending(props) {
  const { t } = useTranslation();
  const { closeModal, list, show } = props;
  const { dispatchEvent } = useSwapContext();

  const clearTx = useCallback(() => {
    dispatchEvent({ type: "CLEAR_TX" });
  }, []);

  return (
    <General width="440" height="283" closeModal={closeModal} show={show} glass>
      <TitleBox className="font-medium">{t("Swap.Pending")}</TitleBox>
      <FirstLine>
        <div className="lft font-medium ">{t("Swap.RecentTransactions")}</div>
        <div className="rht font-medium " onClick={clearTx}>
          <img src={RemoveImg} alt="" />
          {t("Swap.ClearAll")}
        </div>
      </FirstLine>
      <DlBox>
        {list.map((item, index) => (
          <dl key={index}>
            <dt>
              {t("Swap.SwapExactly")}{" "}
              <span className="font-medium">
                {item.from.amountDisplay} {item.from.symbol}
              </span>{" "}
              {t("Swap.for")}{" "}
              <span className="font-medium">{item.to.amountDisplay}</span>{" "}
              {item.to.symbol}{" "}
            </dt>
            <dd>
              {!item.status && (
                <LoadingOutlined
                  className="loading"
                  style={{ position: "relative", top: "2px" }}
                />
              )}
              {item.status === 1 && <img className="success" src={SuccessImg} alt="" />}
            </dd>
          </dl>
        ))}
      </DlBox>
    </General>
  );
}

const TitleBox = styled.div`
  padding: 15px 0;
  font-size: 16px;
  text-align: center;
`;
const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .lft {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  .rht {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
`;
const DlBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 20px 14px;
  margin: 10px 0 0;
  height: 152px;
  box-sizing: border-box;
  overflow-y: auto;

  dl {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .success {
    position: relative;
    top: 3px;
  }
`;
