import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button, { ButtonGroup } from "../common/button";
import General from "components/modal/general";
import WarningIcon from "assets/images/Warning.svg";

export default function ConfirmModal(props) {
  const { t } = useTranslation();
  const { show, handleClose, message, handleConfirm, title, cancelText, confirmText, withoutDanger } = props;

  const clickConfirm = () => {
    handleClose();
    handleConfirm();
  };

  return (
    <General
      show={show}
      width="357"
      height="250"
      closeModal={handleClose}
      glass
      title={title}
    >
      <ModalContainer>
        {!withoutDanger && <img src={WarningIcon} alt="" />}
        <div className="msg">{message}</div>
      </ModalContainer>
      <ButtonGroup
        style={{
          position: "absolute",
          bottom: "20px",
          width: "calc(100% - 40px)",
        }}
      >
        <Button width="120" onClick={handleClose}>
          {cancelText || t("Cancel")}
        </Button>
        <Button width="120" primary onClick={clickConfirm}>
          {confirmText || t("Confirm")}
        </Button>
      </ButtonGroup>
    </General>
  );
}

const ModalContainer = styled.div`
  text-align: center;
  padding-top: 35px;
  img {
    margin-bottom: 18px;
  }
  .msg {
    margin-bottom: 27px;
  }
  position: relative;
`;
