import General from "components/modal/general";
import styled from "styled-components";

import DropdownImg from "img/swap/dropdown.png";
import NextImg from "assets/images/RightArrow.svg";
import GasImg from "img/gas.png";

import { useDaoOrgContract, useVaultContract, useGrantManagerContract } from "hooks/useContract";
import { useDaoContext } from "views/dao/provider";
import { ethers } from "ethers";
import { useState } from "react";
import { getShortDisplay } from "utils/publicJs";
import { InputNumber } from "antd";
import { useAppContext } from "components/provider/appProvider";
import { useTranslation } from "react-i18next";
import { DAO_ORG, VAULT_MANAGER, GRANT_MANAGER } from "utils/constant";
import useSigner from "hooks/useSigner";
import Done from "./Done";
import FailedModal from "./Failed";
import api from "api";
import { useEffect } from "react";
import { useMemo } from "react";

import { useDefiContext } from "components/provider/defiProvider";
import { getBalanceInUSD, calculateHealth, formatHealth } from "utils/aave";
import BigNumber from "bignumber.js";
import { valueToBigNumber } from "@aave/math-utils";
import { formatInput2bignumber } from "utils/utils";
import { useWeb3React } from "@web3-react/core";
import ApplyModal from "components/apps/swap/ApplyModal";
import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import Button from "components/common/button";
import Max from "components/common/max";
import { formatError } from "api/aave";

const STATUS = {
  Default: 0,
  Success: 1,
  Failed: 2,
};

export default function RepayModal(props) {
  const { show, closeModal, userReserve } = props;
  const { t } = useTranslation();

  const { account } = useWeb3React();

  const {
    state: {
      usdPriceEth,
      userUsdData,
      userData: { currentLiquidationThreshold, healthFactor },
    },
  } = useDefiContext();

  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();
  const {
    state: { daoId, componentAddressMap },
  } = useDaoContext();
  const vaultContract = useVaultContract(
    componentAddressMap.get(VAULT_MANAGER)
  );
  const grantContract = useGrantManagerContract(
    componentAddressMap.get(GRANT_MANAGER)
  );
  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const signer = useSigner();

  const [balance, setBalance] = useState(0);
  const [debt, setdebt] = useState(0);
  const [amount, setAmount] = useState();

  const [status, setStatus] = useState(STATUS.Default);
  const [txHash, setTxHash] = useState();
  const [newHealth, setNewHealth] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [showApply, setShowApply] = useState(false);


  const reserve = useMemo(() => {
    return userReserve ? userReserve.reserve : {};
  }, [userReserve]);

  const txLink = useMemo(() => {
    if (exploreScan && txHash) {
      return `${exploreScan}tx/${txHash}`;
    }
    return "";
  }, [txHash, exploreScan]);

  const heathDisplay = useMemo(() => {
    if (healthFactor) {
      return formatHealth(BigNumber(healthFactor.toString()).div(BigNumber(1e18)))
    } else {
      return 0;
    }
  }, [healthFactor]);

  const remainDebt = useMemo(() => {
    const debtBN = userReserve.debtBN;
    const symbol = userReserve.reserve && userReserve.reserve.symbol;
    if (debtBN) {
      const debtNum = debtBN.shiftedBy(-userReserve.reserve.decimals).toNumber();
        
      const debt = getShortDisplay(debtNum);
      let remain;
      if (!amount) {
        remain = debt;
      } else if (amount >= debtNum) {
        remain = 0;
      } else {
        remain = getShortDisplay(debtNum - Number(amount));
      }
      return { debt: `${debt} ${symbol}`, remain: `${remain} ${symbol}` };
    }
    return;
  }, [userReserve, amount]);

  useEffect(() => {
    if (!reserve) {
      return;
    }
    if (!amount || amount < 0) {
      setNewHealth(0);
      return;
    }
    if (amount >= balance) {
      setNewHealth("∞");
      return
    } 
    const { totalCollateralUsdVal, totalDebtUsdVal } = userUsdData;

    if (!totalCollateralUsdVal || !totalDebtUsdVal) {
      return;
    }

    const totalCollateralUSD_BN = valueToBigNumber(totalCollateralUsdVal);
    const totalBorrowsUSD_BN = valueToBigNumber(totalDebtUsdVal);

    const currentLiquidationThreshold_BN = valueToBigNumber(
      currentLiquidationThreshold.toString()
    );

    const amountBN = BigNumber(amount).shiftedBy(reserve.decimals);
    const amountUSD_BN = getBalanceInUSD(
      usdPriceEth,
      reserve.price.priceInEth,
      amountBN
    );
 
    const remainDebt = totalBorrowsUSD_BN.minus(
      amountUSD_BN.shiftedBy(-reserve.decimals)
    );

    const v = calculateHealth(
      totalCollateralUSD_BN,
      remainDebt.lt(0) ?  BigNumber(0): remainDebt,
      currentLiquidationThreshold_BN
    );
    setNewHealth(formatHealth(BigNumber(v)));
    
  }, [
    amount,
    userUsdData,
    userReserve,
    currentLiquidationThreshold,
    usdPriceEth,
  ]);

  const onClickRepay = async () => {
    let unlimitPermission = false;
    const vaultAddress = componentAddressMap.get(VAULT_MANAGER);
    try {
      unlimitPermission = await grantContract.checkMethodPermission(
        account,
        vaultAddress,
        "GP_Loan_unlimited"
      );
    } catch (error) {
      console.error("checkMethodPermission GP_Buy_unlimited failed", error);
    }
    if (!unlimitPermission) { 
      try {
        const data = await grantContract.checkMethodPermission(
          account,
          vaultAddress,
          "GP_Loan"
        );
        if (!data) {
          setShowApply(true);
          return;
        }
      } catch (error) {
        console.error("checkMethodPermission failed", error);
      }
      try {
        const data = await grantContract.getTokenLimit(
          1,
          reserve.underlyingAsset,
          account,
          ethers.constants.AddressZero
        );
        const delta = data[1].sub(data[0]);
        if (
          BigNumber(amount).gt(
            BigNumber(
              ethers.utils.formatUnits(delta, reserve.decimals).toString()
            )
          )
        ) {
          dispatch({
            type: "MSGTYPE",
            payload: { msg: t("defi.repay.Insufficient") },
          });
          return;
        }
      } catch (error) {
        console.error("getTokenLimit failed", error);
      }
    }
    
    // dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });
    const params = { amount: amount, symbol: reserve.symbol };

    try {
      
      const amountBN = formatInput2bignumber(amount, reserve.decimals)
      const res = await api.aave.aaveRepay(daoOrg, signer, {
        amount: amountBN,
        tokenAddress: reserve.underlyingAsset,
        mod: userReserve.mod,
      });
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.DefiRepay"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.DefiRepay,
          data: {
            daoId,
            ...params,
          },
        },
      });
      closeModal();
    } catch (error) {
      console.error("aaveRepay failed", error);
      const msg = error?.reason || error?.data?.message;
      if (msg) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: formatError(msg) },
        });
      }
      // setErrorMsg(error?.error?.message)
      // showNotification(
      //   NotificationType.Fail,
      //   t("Notification.DefiRepaySuccess", params)
      // );
    }
  };

  const closeDone = () => {
    setStatus(STATUS.Default);
    closeModal();
  };

  const closeFailed = () => {
    setStatus(STATUS.Default);
    setTxHash();
    closeModal();
  };

  useEffect(async () => {
    if (userReserve && userReserve.balance) {
      setdebt(userReserve.balance)
    }

    if (userReserve && vaultContract) {
      try {
        const balance = await api.vault.getTokenBalance(
          vaultContract,
          reserve.underlyingAsset
        );
        const balanceVal = ethers.utils.formatUnits(balance, reserve.decimals);
        setBalance(Number(balanceVal));
      } catch (error) {
        console.error("getTokenBalance failed", error);
      }
    }
  }, [userReserve, vaultContract]);
  return (
    <>
      <General
        width="440"
        height="490"
        closeModal={closeModal}
        show={show}
        title={`${t("defi.repay.Repay")} ${reserve && reserve.symbol}`}
      >
        <Label>{t("Amount")}</Label>
        <InputBoxBg>
          <div className="input">
            <InputStyled
              autoFocus
              value={amount}
              min={0}
              controls={false}
              onChange={(v) => setAmount(v)}
            />
            {/* <BalanceBg>1054.97</BalanceBg> */}
            {/* <PriceBox>
            $1.052.70 <span>(0.155%)</span>
          </PriceBox> */}
          </div>
          <div>
            <SelectBox>
              <span className="name font-bold">
                <img src={userReserve.logo} alt="" />
                {reserve && reserve.symbol}
              </span>
            </SelectBox>
            <RhtBalance>
              <div className="balance">
                {t("defi.repay.Balance")} : {getShortDisplay(balance)}
              </div>
              <Max t={t} onClick={() => setAmount(debt)} />
            </RhtBalance>
          </div>
        </InputBoxBg>
        <Label>{t("defi.repay.overview")}</Label>
        <TransactionBox>
          <BorderBox>
            <LineDL>
              <dt>{t("defi.repay.debt")}</dt>
              <dd className="first">
                {remainDebt && (
                  <>
                    {remainDebt.debt}
                    <img src={NextImg} alt="" /> {remainDebt.remain}
                  </>
                )}
              </dd>
            </LineDL>
            <LineDL>
              <dt>{t("defi.repay.HealthFactor")}</dt>
              <dd>
                <div className="green">
                  {heathDisplay}{" "}
                  {amount > 0 && amount <= balance && (
                    <>
                      <img src={NextImg} className="arrow" alt="" />
                    </>
                  )}
                </div>
                <div className="tips">
                  {t("defi.repay.Liquidation")}&lt; 1.0
                </div>
              </dd>
            </LineDL>
          </BorderBox>
        </TransactionBox>
        {/* <LastLine>
        <img src={GasImg} alt="" /> $1.77
      </LastLine> */}
        <BtnGroup>
          <Button
            disabled={!amount || amount < 0 || amount > balance}
            onClick={onClickRepay}
            primary
          >
            {t("defi.repay.Repay")} {reserve && reserve.symbol}
          </Button>
        </BtnGroup>
      </General>
      {status === STATUS.Success && (
        <Done
          amount={amount}
          title={t("defi.repay.repaied")}
          symbol={reserve && reserve.symbol}
          handleClose={closeDone}
          txLink={txLink}
        />
      )}
      {status === STATUS.Failed && (
        <FailedModal
          title={t("defi.repay.Failed")}
          content={t("defi.repay.RepayedContent")}
          subContent={errorMsg}
          handleClose={closeFailed}
        />
      )}
      {showApply && (
        <ApplyModal show={showApply} closeModal={() => setShowApply(false)} />
      )}
    </>
  );
}

const Label = styled.div`
  line-height: 20px;
  margin-top: 28px;
  margin-bottom: 12px;
  opacity: 0.5;
  &.fist {
    margin-top: 20px;
  }
`;
const InputBoxBg = styled.div`
  width: 100%;
  height: 76px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  display: flex;
  justify-content: flex-between;
  align-items: center;
  padding: 20px 20px;
  .input {
    flex: 1;
  }
`;

const PriceBox = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #94949a;
  line-height: 14px;
  margin-top: 10px;
  span {
    color: #62ba46;
  }
`;
const BalanceBg = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: #0c1651;
  line-height: 25px;
`;

const SelectBox = styled.div`
  height: 34px;
  border-radius: 19px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .name {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-right: 8px;
    }
  }
`;

const BtnGroup = styled.div`
  width: calc(100% - 30px);
  position: absolute;
  bottom: 16px;
  button {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    margin-top: 20px;
  }
`;

const RhtBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  .balance {
    margin-right: 10px;
    opacity: 0.5;
  }
`;
const TransactionBox = styled.div`
  .title {
    padding: 30px 0 10px;
  }
`;
const BorderBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 10px;
  padding: 15px 19px;
  dl {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  dd {
    font-size: 16px;
    text-align: right;
  }
  .green {
    font-size: 14px;
    font-weight: 500;
    color: #62ba46;
    line-height: 19px;
  }
  .tips {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-top: 10px;
    opacity: 0.5;
  }
  .arrow {
    position: relative;
    top: -3px;
  }
`;

const LastLine = styled.div`
  display: flex;
  margin-top: 20px;

  font-size: 12px;
  font-weight: 500;
  color: #94949a;
  line-height: 16px;
  img {
    width: 14px;
    height: 12px;
    margin: 0 10px 0 5px;
  }
`;

const InputStyled = styled(InputNumber)`
  background: transparent;
  border: none;
  &.ant-input-number-focused {
    box-shadow: none;
  }
  .ant-input-number-input {
    color: #fff;
    padding: 0;
    font-family: "Rubik-Medium";
  }
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
`;

const LineDL = styled.dl`
  margin-bottom: 10px;
`;
