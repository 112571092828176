import { memo, useMemo, useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import { useLazyQuery } from "@apollo/client";

import General from "components/modal/general";
import { LoadingBox } from "components/LoadingModal";

import { SUPPORTED_WALLETS } from "./wallet";
import { getConnectorForWallet } from "./connector";
import { signMessage } from "utils/sign";
import { LoginQuery } from "api/graphql/auth";
import { useAuthContext } from "components/provider/authProvider";
import { useClientContext } from "components/provider/clientProvider";
import getNetworkData from "network";
import { useAppContext } from "components/provider/appProvider";
import { useParams } from "react-router-dom";
import { NetworkList, defaultChainId } from "network";
import { trackEvent } from "utils/tagManager";

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
  PENDING: "pending",
};

const WalletModal = memo((props) => {
  const { t } = useTranslation();
  const { show, closeModal } = props;
  const { fullhandle, network: networkSimple } = useParams();;
  const { chainId, connector, account, provider } = useWeb3React();

  const { getClient } = useClientContext();
  const { dispatchAuth } = useAuthContext();
  const {
    dispatch,
    state: { pathChainId },
  } = useAppContext();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.options);
  const [pendingConnector, setPendingConnector] = useState();

  const useChainId = useMemo(() => {
    if (!fullhandle) {
      return pathChainId || defaultChainId;
    }
    const network = NetworkList.find((n) => n.simple === networkSimple);
    return network?.chainId || pathChainId || defaultChainId;
  }, [fullhandle, networkSimple, pathChainId]);

  useEffect(() => {
    if (chainId && pathChainId && chainId !== pathChainId) {
      dispatch({
        type: "SWITCH_CHAIN",
        payload: pathChainId,
      });
    }
  }, [pathChainId, chainId])

  useEffect(async () => {
    if (
      pendingConnector &&
      chainId &&
      account &&
      walletView === WALLET_VIEWS.PENDING
    ) {
      try {
        await handleLogin();
        setPendingConnector(undefined);
        setWalletView(WALLET_VIEWS.OPTIONS);
        // close modal
        closeModal();
      } catch (error) {
        console.error("handleLogin failed", error);
        setWalletView(WALLET_VIEWS.OPTIONS);
        // connector.deactivate
        //   ? connector.deactivate()
        //   : connector.resetState();
      }
    }
  }, [pendingConnector, account, chainId, walletView]);

  const handleLogin = async () => {
    if (!account || !chainId) return;
    const now = Date.now();
    if (!getNetworkData(chainId)) {
      dispatch({ type: "SWITCH_CHAIN", payload: useChainId });
      throw Error("unsupport chain");
    }

    // sign...
    const signData = await provider.send("personal_sign", [
      signMessage(chainId, account, now),
      account,
    ]);
    trackEvent({
      event: "sign",
      category: "sign",
      action: "sign",
      label: "sign_success",
      account,
    });
    // login...
    const client = getClient(chainId);
    const resp = await client.request(LoginQuery, {
      sign: signData,
      account,
      timestamp: now,
      chainId,
    });
    const loginData = resp.login;
    console.log("====loginData", loginData);
    dispatchAuth({
      type: "SET_AUTH_TOKEN",
      payload: {
        account,
        chainId,
        token: loginData.token,
        expireAt: Date.now() + loginData.tokenExp * 1000,
      },
    });
    trackEvent({
      event: "login",
      category: "login",
      action: "login",
      label: "login_success",
      account,
    });
  };

  const tryActivation = useCallback(async (wallet) => {
    const connector = getConnectorForWallet(wallet);
    try {
      setPendingConnector(connector);
      setWalletView(WALLET_VIEWS.PENDING);

      await connector.activate();

      dispatchAuth({ type: "CHANGE_WALLET", payload: wallet });
    } catch (error) {
      console.error(`web3-react connection error: ${error}`);
      setWalletView(WALLET_VIEWS.OPTIONS);
    }
  }, []);

  const getOptions = useCallback(() => {
    if (!(window.web3 || window.ethereum)) {
      const link = "https://metamask.io/";
      return (
        <WalletModalOption
          id="install"
          header={t("Wallet.Install")}
          link={link}
          icon={SUPPORTED_WALLETS["METAMASK"].iconURL}
          onClick={() => {
            window.open(link, "_target");
          }}
        />
      );
    }
    return Object.keys(SUPPORTED_WALLETS).map((key, index) => {
      const option = SUPPORTED_WALLETS[key];
      const optionProps = {
        id: `connect-${key}`,
        link: option.href,
        header: option.name,
        color: option.color,
        key,
        icon: option.iconURL,
        onClick() {
          tryActivation(option.wallet);
        },
      };
      return <WalletModalOption {...optionProps} />;
    });
  }, [connector]);

  const getModalContent = useCallback(() => {
    if (walletView === WALLET_VIEWS.PENDING) {
      return (
        <PendingView>
          <LoadingBox />
          <SubHeader>{t("Wallet.Connecting")}</SubHeader>
        </PendingView>
      );
    } else {
      return getOptions();
    }
  }, [walletView]);

  return (
    <General
      show={show}
      width={423}
      height={366}
      closeModal={closeModal}
      glass
      titleBold
      title={t("Wallet.Connect")}
    >
      {getModalContent()}
    </General>
  );
});

export default WalletModal;

const WalletModalOption = memo((props) => {
  const { clickable, size, onClick, color, header, icon, active, id } = props;
  return (
    <OptionCard id={id} onClick={onClick} active={active}>
      <HeaderText color={color}>{header}</HeaderText>
      <IconWrapper src={icon} alt="" />
    </OptionCard>
  );
});


const OptionCard = styled.div`
  &:first-child {
    margin-top: 30px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  padding: 20px;
  box-sizing: border-box;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  backdrop-filter: blur(32px);

  height: 62px;
  line-height: 64px;
  cursor: pointer;
  &:hover {
    border: 1px solid #328aff;
  }
`;

const HeaderText = styled.div`
  font-size: 18px;
`;

const SubHeader = styled.div`
  text-align: center;
  margin-top: 15px;
`;

const IconWrapper = styled.img`
  height: 34px;
`;

const PendingView = styled.div`
  margin-top: 20%;
`;
