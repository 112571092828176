import React, { useCallback, useMemo, useState } from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import LoadingNew from "components/loadingNEW";
import Tip from "components/svg/tip";
import Qrcode from "assets/images/Qrcode.svg";

import { CONNECTION_STATUS } from "utils/constant";
import { useWCTContext } from "components/provider/WCTProvider";
import WalletConnectImg from "assets/app/wallet.svg";
import Button from "components/common/button";

const WalletConnect = (props) => {
  const { t } = useTranslation();

  const {
    state: { status: connectionStatus, wcClientData },
    dispatch: dispatchEvent,
  } = useWCTContext();

  const handlePaste = (event) => {
    const items = event.clipboardData.items;

    for (const index in items) {
      const item = items[index];

      if (item.kind === "string" && item.type === "text/plain") {
        dispatchEvent({
          type: "SET_STATUS",
          payload: CONNECTION_STATUS.CONNECTING,
        });
        dispatchEvent({
          type: "SET_WCURI",
          payload: event.clipboardData.getData("Text"),
        });
      }
    }
  };

  const onClickDisconnect = useCallback(() => {
    dispatchEvent({ type: "SET_BEHAVIOR", payload: "disconnect" });
  }, []);

  const statusDisplay = useMemo(() => {
    switch (connectionStatus) {
      case CONNECTION_STATUS.CONNECTING:
        return <LoadingNew size="small" />;
      default:
        // return connectionStatus;
        return <></>;
    }
  }, [connectionStatus]);

  const ConnectedDisplay = () => {
    return (
      <ConnectedStyled>
        <div className="connectWrapper">
          <LogoBox>
            <img src={WalletConnectImg} alt="" />
          </LogoBox>
          <span className="logo">{t("walletConnect.title")}</span>
        </div>
        <div className="bottom">
          <DappStyled>
            <img src={wcClientData.icons && wcClientData.icons[0]} alt="" />
            <div>
              <div className="title">{wcClientData.name}</div>
              <div className="desc">{wcClientData.description}</div>
            </div>
          </DappStyled>
          <CancelConnectStyled>
            <ConnectTip>
              <span className="text">{t("WCT.Connected")}</span>
              <Tooltip
                overlayClassName="tipTool"
                placement="top"
                title={t("walletConnect.ConnectedTip")}
                arrowPointAtCenter
              >
                <Tip />
              </Tooltip>
            </ConnectTip>
            <DisconnectBtn primary width="132" onClick={onClickDisconnect}>
              {t("walletConnect.DisconnectBtn")}
            </DisconnectBtn>
          </CancelConnectStyled>
        </div>
      </ConnectedStyled>
    );
  };

  const DisconnectDisplay = () => {
    return (
      <WalletConnectStyled>
        <div className="connectWrapper">
          <LogoBox>
            <img src={WalletConnectImg} alt="" />
          </LogoBox>
          <span className="logo">{t("walletConnect.title")}</span>
        </div>
        <div className="intro">{t("walletConnect.beforeConnectIntro")}</div>
        <InputBox className="connectWrapper">
          <QrcodeStyled src={Qrcode} alt="" />
          <InputStyled
            onPaste={handlePaste}
            placeholder={t("walletConnect.qrcodePlaceholder")}
          />
          <span className="status">{statusDisplay}</span>
        </InputBox>
      </WalletConnectStyled>
    );
  };

  if (connectionStatus === CONNECTION_STATUS.CONNECTED && wcClientData) {
    return <ConnectedDisplay />;
  } else {
    return <DisconnectDisplay />;
  }
};

export default WalletConnect;

const ConnectBox = styled.div`
  height: 118px;
  margin-bottom: 16px;
  .connectWrapper {
    display: flex;
    align-items: center;
    position: relative;
    .status {
      position: absolute;
      right: -30px;
      top: 8px;
    }
    .logo {
      font-size: 16px;
      font-family: "Poppins-Medium";
      font-weight: 500;
      color: #328aff;
      margin-left: 7px;
    }
  }
`;

const WalletConnectStyled = styled(ConnectBox)`
  .intro {
    margin: 16px 0;
    opacity: 0.5;
    margin-top: 15px;
  }
`;

const LogoBox = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(50, 108, 255, 0.28);
  border-radius: 8px;
  text-align: center;
  padding-top: 2px;
  box-sizing: border-box;
  img {
    width: 16px;
  }
`;

const InputBox = styled.div`
  width: 442px;
  height: 40px;
  background: rgba(4, 23, 53, 0.5);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  position: relative;
`;

const InputStyled = styled.input`
  background-color: transparent;
  flex: 1;
  padding: 0 15px;
  color: #fff;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const QrcodeStyled = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 12px;
`;

const ConnectedStyled = styled(ConnectBox)`
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
  }
`;

const DappStyled = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 44px;
    margin-right: 17px;
  }
  .title {
    font-family: "Poppins-Medium";
    font-size: 16px;
    line-height: 25px;
  }
  .desc {
    font-size: 14px;
    line-height: 21px;
    margin-top: 3px;
    opacity: 0.5;
  }
`;

const CancelConnectStyled = styled.div`
  display: flex;
`;

const DisconnectBtn = styled(Button)`
  margin-left: 10px;
`;

const ConnectTip = styled.div`
  display: flex;
  align-items: center;
  .text {
    margin-right: 5px;
    opacity: 0.5;
  }
`;
