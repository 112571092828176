import styled from "styled-components";

export default function Max({t, onMax, ...rest}) {
    return (
      <MaxStyled onClick={onMax} {...rest}>
        {t("Max")}
      </MaxStyled>
    );
}

const MaxStyled = styled.span`
  display: inline-block;
  text-align: center;
  width: 48px;
  background: #326cff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;
  cursor: pointer;
`;