import { useCallback, useState, memo, useMemo } from "react";
import { Tooltip } from "antd";
import { Multicall } from "ethereum-multicall";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import moment from "moment";

import {
  SubgraphApiQuery,
  getWctCount,
  CHAIN_NFT_SOURCE,
} from "api/graphql/subQuery";
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import api from "api";

import Empty from "../empty";
import PaginationBox from "components/common/pagination";
import Loading from "components/common/loading";
import useSigner from "hooks/useSigner";
import { useDaoContext } from "views/dao/provider";
import { VAULT_MANAGER } from "utils/constant";
import { useVaultContract } from "hooks/useContract";
import { useAppContext } from "components/provider/appProvider";
import { useTranslation } from "react-i18next";
import useCheckLogin from "hooks/useCheckLogin";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import { useNetworkData } from "hooks/useNetwork";
import { IndexTag } from "assets/styles/common";
import ConfirmModal from "components/modal/confirmModal";

import { getTxList } from "api/graphql/tx";
import useMulticallTxs from "../useMulticallTxs";
import { useClientContext } from "components/provider/clientProvider";
import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import Tip from "assets/images/Tip.svg";
import { formatTextTime } from "utils/utils";
import { useWCTContext } from "components/provider/WCTProvider";

export default function TxList(props) {
  const navigate = useNavigate();
  const { fullhandle, network: networkSimple } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isLogin = useCheckLogin();
  const { getClient } = useClientContext();

  const { chainId, account, provider } = useWeb3React();
  const network = useNetworkData(chainId);

  const { dispatch: dispatchEvent } = useWCTContext();

  const signer = useSigner();
  const {
    state: { exploreScan },
    dispatch,
  } = useAppContext();
  const {
    state: {
      daoId,
      componentAddressMap,
      whoAmI: { isOwner, isGP },
    },
  } = useDaoContext();

  const vaultContract = useVaultContract(componentAddressMap.get(VAULT_MANAGER));

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [list, setList] = useState([]);
  const [currentId, setCurrentId] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const multicall = useMemo(() => {
    if (!provider) {
      return;
    }

    const _config = {
      ethersProvider: provider,
      tryAggregate: true,
    };
    if (network.multicallCustomContractAddress) {
      _config.multicallCustomContractAddress =
        network.multicallCustomContractAddress;
    }
    return new Multicall(_config);
  }, [provider]);

  const [getTxDatas, fetchLoading] = useMulticallTxs(
    multicall,
    componentAddressMap.get(VAULT_MANAGER),
    account
  );

  const toGo = (id) => {
    navigate(`/${networkSimple}/${fullhandle}/app/nft?tx=${id}`);
  };

  const handleConfirm = async (flag) => {
    try {
      const res = await api.vault.confirmTransaction(vaultContract, signer, {
        voteId: currentId,
        flag,
      });

      closeConfirm();
      closeReject();
      setCurrentId(undefined);

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        flag
          ? t("Notification.proposalApprove")
          : t("Notification.proposalReject"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.ConfirmProposal,
          data: {
            txId: currentId,
            daoId,
            support: flag,
            pathname,
          },
        },
      });
    } catch (error) {
      console.error(error);
      // showNotification(
      //   NotificationType.Fail,
      //   flag
      //     ? t("Notification.proposalApproveFailed")
      //     : t("Notification.proposalRejectFailed"),
      // );
      // dispatch({
      //   type: "MSGTYPE",
      //   payload: { msg: "excuted failed", closable: true },
      // });
    } finally {
      // setCurrentId(undefined);
    }
  };

  const showConfirm = useCallback((e, id) => {
    e.stopPropagation();
    setConfirmVisible(true);
    setCurrentId(id);
  }, []);

  const showReject = useCallback((e, id) => {
    e.stopPropagation();
    setRejectVisible(true);
    setCurrentId(id);
  }, []);

  const closeConfirm = useCallback(() => {
    setConfirmVisible(false);
  }, []);

  const closeReject = useCallback(() => {
    setRejectVisible(false);
  }, []);

  const getTotal = async () => {
    const resp = await getClient().request(SubgraphApiQuery, {
      query: getWctCount(daoId),
      chainId,
    });
    const data = JSON.parse(resp.subgraphApi).data;
    if (data) {
      setTotal(Number(data.totalCount?.count || 0));
    }
  };

  const getTxQueue = async () => {
    setLoading(true);
    const resp = await getTxList(
      chainId,
      daoId,
      page,
      pageSize,
      `source: "nft"`,
      page === 1
    );
    setLoading(false);

    if (resp.code !== 0) {
      return;
    }

    const data = resp.data;
    console.log("txs:", data);

    const txIds = data.map((tx) => Number(tx.txID));
    const txs = await getTxDatas(txIds);
    console.log("txs: ", txs);
    if (!txs) {
      setList([]);
      return;
    }

    setList(
      data.map((tx) => {
        return {
          ...txs[tx.txID],
          source: CHAIN_NFT_SOURCE[chainId],
          createAt: formatTextTime(tx.createAt),
        };
      })
    );
  };

  useEffect(() => {
    if (!chainId || !daoId) {
      return;
    }
    if (!isLogin) {
      return;
    }
    getTxQueue();
    getTotal();
  }, [daoId, chainId, page, pageSize, isLogin]);

  useEffect(() => {
    if (loading || fetchLoading) {
      dispatchEvent({ type: "SWITCH_LOADING", payload: true });
    } else {
      dispatchEvent({ type: "SWITCH_LOADING", payload: false });
    }
  }, [loading, fetchLoading]);

  const onChangePage = useCallback((v) => {
    setPage(v);
  }, []);

  const onShowSizeChange = useCallback((c, size) => {
    setPageSize(size);
  }, []);

  useSubcribe(TransactionEvent.WalletConnect, (_, data) => {
    console.log("====listen===", data);
    if (daoId !== data.daoId) {
      return;
    }
    getTxQueue();
    getTotal();
  });

  useSubcribe(
    TransactionEvent.DoWalletConnect,
    (_, { daoId: _daoId, txId: _txId }) => {
      if (daoId === _daoId) {
        getTxQueue();
        getTotal();
      }
    }
  );

  const UserStateAction = memo(({ txId, state, isManager }) => {
    if (!account || !isManager) {
      return <></>;
    }
    if (state === 0) {
      return (
        <>
          <AgreeButton
            onClick={(e) => {
              showConfirm(e, txId);
            }}
          >
            {t("WCT.Confirm")}
          </AgreeButton>{" "}
          <DisagreeButton onClick={(e) => showReject(e, txId)}>
            {t("WCT.Reject")}
          </DisagreeButton>
        </>
      );
    }
    return (
      <StatusButton>{state === 1 ? "Confirmed" : "Rejected"}</StatusButton>
    );
  });

  return (
    <TableBox>
      <ConfirmModal
        show={confirmVisible}
        handleClose={closeConfirm}
        message={t("walletConnect.ConfirmApprove")}
        title={t("walletConnect.ConfirmTitle")}
        handleConfirm={() => handleConfirm(true)}
        confirmText={t("walletConnect.submit")}
        withoutDanger={true}
      />
      <ConfirmModal
        show={rejectVisible}
        handleClose={closeReject}
        message={t("walletConnect.RejectApprove")}
        title={t("walletConnect.RejectTitle")}
        handleConfirm={() => handleConfirm(false)}
        confirmText={t("walletConnect.RejectBtn")}
        withoutDanger={true}
      />
      <div className="tableBr">
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>{t("WCT.No")}</th>
              <th>{t("WCT.Source")}</th>
              {/*<th>Contract interaction</th>*/}
              <th>{t("WCT.Created")}</th>
              <th className="Left">
                {t("WCT.CR")}
                <Tooltip title={t("WCT.tip")}>
                  <img className="tip" src={Tip} alt="" />
                </Tooltip>
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {list.map((tx) => (
              <tr key={tx.id} onClick={() => toGo(tx.id)}>
                <td>
                  <IndexTag>{tx.id}</IndexTag>
                </td>
                <td>
                  {/* {tx.source === "Gem" ? <img src={GemLogo} alt="" /> : <></>} */}
                  <span>
                    {tx.source.length > 20
                      ? tx.source.slice(0, 20) + "..."
                      : tx.source}
                  </span>
                </td>
                {/*<td >{tx.interaction}</td>*/}
                <td>{tx.createAt}</td>
                <td className="Left">
                  <span className="percent">
                    {tx.agree_count} : {tx.oppose_count}
                  </span>{" "}
                </td>
                <td>
                  <UserStateAction
                    txId={tx.id}
                    state={tx.senderState}
                    isManager={isOwner || isGP}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination>
        <PaginationBox
          showQuickJumper
          pageSize={pageSize}
          defaultCurrent={page}
          total={total}
          onChange={onChangePage}
          onShowSizeChange={onShowSizeChange}
        />
      </Pagination>
      {!list.length && <Empty />}
    </TableBox>
  );
}

const TableBox = styled.div`
  position: relative;
  padding-bottom: 30px;
  table {
    width: 100%;
    margin-bottom: 12px;
  }

  th {
    height: 65px;
    color: #328aff;
    white-space: nowrap;
    &:first-child {
      padding-left: 30px;
      text-align: left;
    }
    &.status {
      text-align: left;
      margin-left: 70px;
    }
    .tip {
      position: relative;
      top: 7px;
      left: 5px;
      opacity: 0.5;
      width: 24px;
    }
  }

  td {
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    height: 70px;
    font-weight: 400;

    &:first-child {
      padding-left: 30px;
    }
    img {
      display: inline-block;
      margin: 0 9px 3px 0;
      width: 24px;
    }
  }

  .Left {
    text-align: left;
    padding-left: 30px;
  }
  .percent {
    width: 80px;
    text-align: center;
    display: inline-block;
  }
  .symbol {
    width: 46px;
    height: 46px;
    border-radius: 46px;
    margin: 8px 0;
  }
  .symbolLft {
    width: 22px;
    height: 22px;
    border-radius: 22px;
    margin: 0 3px;
  }
  .edit {
    margin-right: 12px;
  }

  tbody tr:nth-child(2n + 1) {
    background: rgba(255, 255, 255, 0.04);
  }
`;

const ButtonStyled = styled.span`
  display: inline-block;
  text-align: center;
  width: 111px;
  height: 36px;
  color: #ffffff;
  line-height: 36px;
  font-weight: 400;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  color: #041735;
`;

const AgreeButton = styled(ButtonStyled)`
  background: #79dfc4;
`;

const DisagreeButton = styled(ButtonStyled)`
  background: #ffa4d1;
  margin-left: 20px;
`;

const StatusButton = styled(ButtonStyled)`
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.32) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
`;

const Pagination = styled.div`
  text-align: right;
  margin: 0 40px;
`;
