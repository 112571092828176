import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useVaultContract } from "./useContract";
import api from "../api";
import { getShortDisplay } from "../utils/publicJs";
import { useDaoContext } from "views/dao/provider";
import { VENTURE_MANAGEFEE_MANAGER } from "utils/constant";

const useManageFeeBalance = () => {
  const {
    state: { daoChainId, manageFeeToken: token, componentAddressMap },
  } = useDaoContext();

  const manageFeeContract = useVaultContract(
    componentAddressMap.get(VENTURE_MANAGEFEE_MANAGER),
    daoChainId
  );

  const [balance, setBalance] = useState(0);
  const [balanceDisplay, setBalanceDisplay] = useState("0.00");

  useEffect(async () => {
    try {
      const _balance = await manageFeeContract.getBalanceOf(token.address);
      const _format_balance = ethers.utils.formatUnits(_balance, token.deci);
      setBalance(_format_balance);
      setBalanceDisplay(getShortDisplay(_format_balance, 2));
    } catch (error) {}
  }, [manageFeeContract]);

  return {balance, balanceDisplay}
};

export default useManageFeeBalance;
