import { useAppContext } from "components/provider/appProvider";
import LinkImg from "assets/images/link.svg";
import { memo } from "react";

const Link = memo(({ hash, type, href, ...rest }) => {
  const {
    state: { exploreScan },
  } = useAppContext();

  const handleClick = (e) => {
    e.stopPropagation();
  }

  return (
    <a
      href={href ? exploreScan + href : `${exploreScan}${type}/${hash}`}
      rel="noreferrer"
      target="_blank"
      onClick={handleClick}
      style={{ height: "24px" }}
      {...rest}
    >
      <img src={LinkImg} alt="" style={{ width: "24px" }} />
    </a>
  );
});

export default Link;
