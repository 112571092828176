import { memo, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useMatchRoute, { VoteRoutes } from "hooks/useMatchRoute";

import Tabs from "../layout/tabs";
import NewVote from "../modal/newVote";
import { useDaoContext } from "views/dao/provider";
import { useAppContext } from "components/provider/appProvider";
import { useVoteContract } from "hooks/useContract";
import { VOTE_MANAGER } from "utils/constant";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "network";
import Button from "components/common/button";

const TabLayout = memo((props) => {
  const { t } = useTranslation();
  const subRoute = useMatchRoute(VoteRoutes);
  const navigate = useNavigate();
  const { fullhandle, network: networkSimple } = useParams();

  const { dispatch } = useAppContext();
  const { chainId } = useWeb3React();
  const {
    state: {
      period,
      componentAddressMap,
      daoChainId,
      whoAmI: { isOwner, isGP },
      isMultiSignWallet,
    },
  } = useDaoContext();

  const [currentNav, setCurrentNav] = useState();
  const [show, setShow] = useState(false);
  const [voteDisabled, setVoteDisabled] = useState(false);
  const [selectedType, setSelectedType] = useState(0)

  const voteContract = useVoteContract(componentAddressMap.get(VOTE_MANAGER), daoChainId);

  const tabs = useMemo(() => {
    return [t("Vote.Queue"), t("Vote.History")];
  }, []);

  const tabsRoute = useMemo(() => {
    return ["queue", "history"];
  }, []);


  useEffect(() => {
    setCurrentNav(subRoute === "history" ? 1 : 0);
  }, [subRoute]);

  const handleCurrent = (tabIndex) => {
    setCurrentNav(tabIndex);
    navigate(`/${networkSimple}/${fullhandle}/vote/${tabsRoute[tabIndex]}`);
  };

  const handleNew = () => {
    if (voteDisabled) {
      dispatch({ type: 'MSGTYPE', payload: { msg: t('Msg.VoteDisabled'), closable: true } });
      return;
    }
    setShow(true);
  };
  const CloseModal = () => {
    setShow(false);
  };

  useEffect(async () => {
    if (isMultiSignWallet) {
      setVoteDisabled(false);
      setSelectedType("wallet");
      return
    }
    if (period === 2) {
      setVoteDisabled(false);
      // if (chainId === SupportedChainId.MOONBEAM) {
      //   setSelectedType("moonbeam");
      // } else {
      //   setSelectedType(0);
      // }
       setSelectedType(0);
      return;
    }
    if (period === 3) {
      setVoteDisabled(false);
      // if (chainId === SupportedChainId.MOONBEAM) {
      //   setSelectedType("redemption_moonbeam");
      // } else {
      //   setSelectedType("redemption");
      // }
      setSelectedType("redemption");
      return;
    }
    setVoteDisabled(true);
  }, [period, chainId, isMultiSignWallet]);

  return (
    <InfoBox>
      {/* <NewVote
        showVoteModal={show}
        selectedType={selectedType}
        CloseModal={CloseModal}
      /> */}
      <FirstLine>
        <Tabs
          navList={tabs}
          current={currentNav}
          handleCurrent={handleCurrent}
        />
        {/* {(isGP || isOwner) && (
          <VoteButton primary onClick={() => handleNew()}>{t("Vote.NewVote")}</VoteButton>
        )} */}
      </FirstLine>

      <Content id="contentBox">
        <div id="inner">{props.children}</div>
      </Content>
    </InfoBox>
  );
});

export default TabLayout;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  flex: 1;
  height: 100%;
  #inner {
    overflow-y: auto;
    position: relative;
    height: calc(100% - 20px);
    box-sizing: border-box;
    margin-top: 20px;
    box-sizing: border-box;
  }
`;
const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
`;

const VoteButton = styled(Button)`
  width: unset;
  padding: 0 24px;
`