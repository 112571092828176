import { memo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConfirmModal from "components/modal/confirmModal";

const ApplyModal = memo(({ show, closeModal }) => {
  const navigate = useNavigate();
  const { fullhandle, network: networkSimple } = useParams();
  const { t } = useTranslation();

  const go2super = useCallback(() => {
    navigate(`/${networkSimple}/${fullhandle}/settings/super_manager`);
  }, [fullhandle, networkSimple]);

  return (
    <ConfirmModal
      show={show}
      message={t("defi.apply.tips")}
      cancelText={t("Close")}
      confirmText={t("defi.apply.ApplyNow")}
      handleClose={closeModal}
      handleConfirm={go2super}
    />
  );
});

export default ApplyModal;