import { memo, useMemo, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import SearchImg from "assets/images/Search.svg";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import api from "api";
import { useWeb3React } from "@web3-react/core";
import LogoSVG from "../../../img/defaultLogo.svg";
import TokenDefault from "img/defaultToken.svg";
import { COMMON_BASES } from "../../../tokens/recommend";
import LoadingBox from "components/common/loading";
import EmptyBox from "components/common/empty";
import BackIcon from "assets/images/Back.svg";
import BgIcon from "assets/images/ModalBg.svg";

const SelectTokenModal = memo((props) => {
  const {
    closeModal,
    tokens,
    selectedAddressList,
    loading: tokenLoading,
  } = props;
  const { t } = useTranslation();

  const [searchVal, setSearchVal] = useState("");
  const [showList, setShowList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showRecommendList, setShowRecommendList] = useState([])

  const { provider, chainId } = useWeb3React();

  const recommend = useMemo(() => {
    let arrList = [];
    COMMON_BASES[chainId].forEach((item) => {
      const temp = tokens.find((t) => {
        return t.symbol === item;
      });
      temp && arrList.push(temp);
    });
    return arrList;
  }, [tokens, chainId]);

  const checkExist = (address) => {
    if (!selectedAddressList || !selectedAddressList.length) {
      return;
    }
    if (address) {
      return selectedAddressList.includes(address.toLowerCase());
    }
    return (tokens || []).find((t) =>
      selectedAddressList.includes(t.address.toLowerCase())
    );
  };

  const handleSearchToken = useCallback(async (address) => {
    try {
      setLoading(true);
      const contract = await api.erc20.getContract(provider, address);
      const deci = await contract.decimals();
      setShowList([
        {
          name: await contract.name(),
          symbol: await contract.symbol(),
          decimals: deci,
          address,
        },
      ]);
    } catch (error) {
      console.error("handleSearchToken failed", error);
      setShowList([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchVal) {
      const v = searchVal.toLowerCase();
      const filterList = tokens.filter((t) => {
        return (
          t.address.toLowerCase() === v ||
          (t.name || "").toLowerCase().includes(v) ||
          (t.symbol || "").toLowerCase().includes(v)
        );
      });
      if (!filterList.length && ethers.utils.isAddress(searchVal)) {
        handleSearchToken(searchVal);
      } else {
        setShowList(
          filterList.sort((a, b) => a.symbol.length - b.symbol.length)
        );
      }
      setShowRecommendList(recommend.filter((t) => {
        return (
          t.address.toLowerCase() === v ||
          (t.name || "").toLowerCase().includes(v) ||
          (t.symbol || "").toLowerCase().includes(v)
        );
      }));
    } else {
      // setShowList(tokens.slice(0, 50));
      setShowList(tokens);
      setShowRecommendList(recommend);
    }
  }, [tokens, searchVal]);

  const updateSearchVal = useCallback(({ target: { value } }) => {
    setSearchVal(value);
  }, []);

  const handleClickToken = (token) => {
    if (token.address && checkExist(token.address)) {
      return;
    } else {
      closeModal(token);
    }
  };

  const handleError = (e) => {
    e.target.src = TokenDefault;
    e.target.οnerrοr = null;
  };
  const showLoading = useMemo(() => {
    return tokenLoading || loading;
  }, [tokenLoading, loading]);

  return (
    <AddBox>
      <Bg>
        <Title>
          <CloseBox src={BackIcon} alt="" onClick={handleClickToken} />
          <span className="font-medium">{t("Swap.SelectToken")}</span>
        </Title>
        <SearchBox>
          <img src={SearchImg} alt="" />
          <input
            type="text"
            placeholder={t("Explore.TokenPlaceholder")}
            onChange={updateSearchVal}
          />
        </SearchBox>
        {!!showRecommendList.length && (
          <BtnGroup>
            {showRecommendList.map((item, index) => (
              <Btn
                key={index}
                onClick={() => handleClickToken(item)}
                className={checkExist(item.address) ? "disabled" : ""}
              >
                {/*<img src={item.logo} alt="" />*/}
                <img
                  src={item.logoURI ? item.logoURI : LogoSVG}
                  alt=""
                  onError={handleError}
                />
                {item.symbol}
              </Btn>
            ))}
          </BtnGroup>
        )}
        <ScrollBox short={showRecommendList.length > 3}>
          <LoadingBox
            loading={showLoading}
            pure={true}
            style={{ minHeight: "100px", paddingTop: "0" }}
          />
          {!showLoading && !showList.length && (
            <EmptyBox pure={true} className="empty" msg={t("Empty")} />
          )}
          {!showLoading && (
            <UlBox>
              {showList.map((item, index) => (
                <li
                  key={`list_${index}`}
                  onClick={() => handleClickToken(item)}
                  className={checkExist(item.address) ? "disabled" : ""}
                >
                  <img
                    src={item.logoURI ? item.logoURI : LogoSVG}
                    alt=""
                    onError={handleError}
                  />
                  <div>
                    <div className="name">{item.symbol}</div>
                    <div className="tips">{item.name}</div>
                  </div>
                </li>
              ))}
            </UlBox>
          )}
        </ScrollBox>
      </Bg>
    </AddBox>
  );
});

export default SelectTokenModal;

const AddBox = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
`;
const Bg = styled.div`
  width: 440px;
  border-radius: 10px;
  padding: 30px 0 20px 0;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.main} url(${BgIcon}) no-repeat
    left top;
`;
const Title = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  padding: 0 20px;
  font-family: "Poppins-Medium";
  display: flex;
  align-items: center;
`;
const CloseBox = styled.img`
  cursor: pointer;
  width: 24px;
`;
const SearchBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  border-radius: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin: 20px;
  img {
    width: 18px;
    margin-right: 7px;
    opacity: 0.5;
  }
  input {
    border: 0;
    flex-grow: 1;
    background: transparent;
    color: #ffffff;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const UlBox = styled.div`
  padding: 0 30px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    cursor: pointer;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 12px;
    }
    .name {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      height: 23px;
    }
    .tips {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 14px;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
const ScrollBox = styled.div`
  overflow-y: auto;
  height: ${(props) => (props.short ? "148px" : "200px")};
  .empty {
    box-sizing: border-box;
    width: calc(100% - 40px);
    margin-left: 20px;
    border-radius: 12px;
    min-height: unset;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(15px);
    img {
      margin-top: 0;
    }
  }
`;
const BtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  margin: 0 20px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;
const Btn = styled.div`
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px 10px 0;

  height: 40px;
  background: rgba(69, 190, 255, 0.24);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);

  cursor: pointer;
  padding: 0 11px;
  &.active {
  }
  img {
    width: 24px;
    height: 24px;
    border-radius: 14px;
    margin-right: 12px;
  }
  &:hover {
    border: 1px solid #45beff;
  }
  &.disabled {
    opacity: 0.5;
    border: 1px solid rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
  }
`;
