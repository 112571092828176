import { memo, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import DropSvg from "components/svg/common/select";
import DropImg from "img/header/downdrop.png";

const TransferSelect = memo((props) => {
  const { list, onChange, selected } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(false);
    });
  }, []);

  const showList = useCallback(
    (e) => {
      e.nativeEvent.stopImmediatePropagation();
      setShow(!show);
    },
    [show]
  );

  const choose = useCallback(
    (item, e) => {
      e.stopPropagation();
      setShow(false);
      console.log(item, selected);
      if (!selected || item.value !== selected.value) {
        onChange(item);
      }
    },
    [selected]
  );

  return (
    <BorderBox onClick={showList}>
        {
            !show &&<ReceiveBox><div className="box">
                {selected && <div className="title">{selected?.name}</div>}
            </div>
                <Icon src={DropImg} alt="" show={show} /></ReceiveBox>
        }

      {show && (
        <DropRC>
          {list.map((item, index) => (
            <li onClick={(e) => choose(item, e)} key={`rc_${index}`}>
              <div className="title">{item.name}</div>
            </li>
          ))}
        </DropRC>
      )}
    </BorderBox>
  );
});

export default TransferSelect;

const Icon = styled.img`
  transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0deg)")};
`;

const BorderBox = styled.div`
    position: relative;
  width: 157px;
  height: 40px;
  cursor: pointer;
`
const ReceiveBox = styled.div`
  width: 157px;
  height: 40px;
  background: rgba(255,255,255,0.08);
  border-radius: 20px;
  border: 1px solid rgba(255,255,255,0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 20px;
  position: relative;
  box-sizing: border-box;
  .box {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .lft {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-right: 10px;
  }

  .Total {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    line-height: 24px;
  }
`;
const DropBox = styled.ul`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  padding: 10px 0;
  background-color: #1e2b3f;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  width: 100%;
  li {
    padding: 6px 23px;
    margin: 0 10px;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;

const DropRC = styled(DropBox)`
  li {
    display: flex;
    margin-bottom: 10px;
  }
  .title {
    line-height: 24px;
    color: #fff;
    width: 100%;
    text-align: left;
  }
`;
