import styled from "styled-components";
import Empty from "assets/images/create/image.svg";
import { useAppContext } from "components/provider/appProvider";
import { uploadByFetch } from "api/graphql/dao";
import { useMemo, useState, useEffect } from "react";
import ImageCropper from "components/common/imageCropper";
import LoadingNew from "components/loadingNEW";
import axios from "axios";

const UploadLogo = ({ t, path, data, onChange }) => {
  const {
    state: { gateway },
    dispatch,
  } = useAppContext();

  const [file, setFile] = useState();
  const [imgData, setImgData] = useState();
  const [loading, setLoading] = useState(false);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        resolve(e.target.result);
      };
      // readAsDataURL
      fileReader.readAsDataURL(blob);
      fileReader.onerror = () => {
        reject(new Error('blobToBase64 error'));
      };
    });
  }

  const getBase64 = async (url) => {
    try {
      setLoading(true);
      setImgData("");
      const response = await axios.get(url, {
        responseType: "arraybuffer",
      });
      const bs64 = Buffer.from(response.data, "binary").toString("base64");
      setImgData(`data:image/png;base64,${bs64}`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const checkFile = (f) => {
    if (f.type !== "image/jpeg" && f.type !== "image/png") {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Upload.ErrorType"), closable: true },
      });
      return true;
    }
    // 1M = 1024KB
    // f.size = x bytes
    if (f.size / 1024 > 500) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: `${t("Create.DAOImageTip")}`,
          closable: true,
        },
      });
      return true;
    }
  };
  const closeCropper = () => {
    setFile();
  };

  const uploadAction = async (blob) => {
    try {
      dispatch({ type: "LOADINGTYPE", payload: t("Create.UploadLogo") });
      const resp = await uploadByFetch(blob);
      if (resp.status === 200) {
        const data = await resp.json();

        const src = await blobToBase64(blob);
        setImgData(src);

        onChange && onChange(data.data.fileUpload, src);
      } else {
        throw new Error(`status is ${resp.status}`);
      }
      closeCropper();
    } catch (error) {
      console.error("Upload logo failed: ", error);
      dispatch({
        type: "MSGTYPE",
        payload: { msg: `${t("Create.UploadLogoFailed")}`, closable: true },
      });
    } finally {
      dispatch({ type: "LOADINGTYPE", payload: null });
    }
  };

  const onChangeImage = async (e) => {
    const files = e.target.files;
    if (files == null) return;
    const f = files[0];
    if (checkFile(f)) {
      return;
    }
    setFile(f);
  };

  const imgPath = useMemo(() => {
    if (loading) {
      return ""
    }
    return imgData || "";
  }, [imgData, loading]);

  useEffect(() => {
    if (data) {
      setImgData(data);
    } else if (path && gateway) {
      getBase64(gateway + path);
    }
  }, [data, path, gateway]);

  return (
    <Logo className="imgBox" path={imgPath} loading={loading}>
      {file && (
        <ImageCropper
          show={!!file}
          handleCloseCropper={closeCropper}
          file={file}
          uploadAction={uploadAction}
          t={t}
        />
      )}
      <label htmlFor="file-input" />
      <input
        type="file"
        id="file-input"
        onChange={onChangeImage}
        accept="image/*"
      />
      {loading && (
        <LoadingNew
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "160px",
            height: "160px",
            minHeight: "unset",
          }}
        />
      )}
    </Logo>
  );
};

export default UploadLogo;

const Logo = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  //backdrop-filter: blur(12px);
  //overflow: hidden;
  background: ${({ loading, path }) =>
    `rgba(4, 23, 53, 0.5) url(${
      loading ? "" : path ? path : Empty
    }) no-repeat center center`};
  background-size: ${({ path }) => (path ? "contain" : "32px")};
  #file-input {
    display: none;
  }
  label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .loading .ant-spin-dot-item {
    background-color: #666;
  }
`;
