import * as React from "react";

const SvgComponent = (props) => (
  <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M8.262 15.998a2.057 2.057 0 0 1-1.459-.603L1.017 9.61a3.444 3.444 0 0 1-.928-3.224l.718-4.442A1.371 1.371 0 0 1 1.944.807L6.386.089a3.444 3.444 0 0 1 3.224.928l5.787 5.786c.39.39.603.907.603 1.459 0 .551-.215 1.068-.603 1.458l-5.679 5.675c-.386.387-.91.604-1.456.603ZM7.166 1.374a2.16 2.16 0 0 0-.488.057l-.048.01-4.464.723-.734 4.512a2.08 2.08 0 0 0 .557 1.962l5.787 5.787c.259.26.713.26.972 0l5.677-5.679a.688.688 0 0 0 0-.972L8.64 1.987a2.082 2.082 0 0 0-1.474-.613Z"
        fill={props.color || (props.active ? "#00CA81" : "#ccc")}
      />
      <path d="M2.597 8.03a1.225 1.225 0 0 1-.328-1.156l.012-.055.632-3.908L6.82 2.28l.055-.012a1.23 1.23 0 0 1 1.156.328l5.136 5.136a.749.749 0 0 1 0 1.058l-4.379 4.379a.749.749 0 0 1-1.058 0L2.597 8.03Z" />
      <path
        d="M5.364 7.424a2.057 2.057 0 0 1-1.458-.603 2.063 2.063 0 0 1 0-2.915 2.063 2.063 0 0 1 2.915 0 2.063 2.063 0 0 1 0 2.915c-.4.402-.928.603-1.457.603Zm0-2.747a.687.687 0 1 0-.003 1.373.687.687 0 0 0 .003-1.373Zm5.023 1.797L6.499 10.36a.688.688 0 0 1-.972-.972L9.414 5.5a.688.688 0 0 1 .973 0 .692.692 0 0 1 0 .973Z"
        fill={props.color || (props.active ? "#00CA81" : "#ccc")}
      />
    </g>
  </svg>
);

export default SvgComponent;
