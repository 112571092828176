import { memo, useEffect, useState, useMemo, useCallback } from "react";
import { DatePicker } from "antd";
import styled, { css } from "styled-components";
import moment from "moment";
import DateSVG from "components/svg/shape/date";
import AlertSvg from "assets/images/Alert.svg";
import { useTranslation } from "react-i18next";
import { Label, VoteItem } from "./style";
import { formatUTCTime } from "utils/utils";

const DateBox = memo((props) => {
  const { t } = useTranslation();
  const { label, targetTime, updateTime, showTip, ...rest } = props;

  const [selectTag, setSelectTag] = useState(1);
  const [open, setOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  const times = useMemo(() => {
    return [
      { label: "10 min", value: 600000 },
      { label: "30 min", value: 1800000 },
      { label: "1 h", value: 3600000 },
      { label: "2 h", value: 7200000 },
      // { label: "1day", value: 86400000 },
      // { label: "3days", value: 259200000 },
      // { label: "1week", value: 604800000 },
      // { label: "1month", value: 2592000000 },
    ];
  }, []);

  const chooseTag = useCallback(
    (idx) => {
      if (selectTag === idx) {
        return;
      }
      setSelectTag(idx);
      setIsCustom(false);
      updateTime(Date.now() + times[idx - 1].value);
    },
    [selectTag]
  );

  const handleChange = useCallback((value) => {
    setSelectTag(undefined);
    setIsCustom(true);
    updateTime(value.unix() * 1000);
  }, []);

  const disabledDate = useCallback((current) => {
    // return current && current < moment().startOf("day");
    return false;
  }, []);

  const range = useCallback((start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  });

  // const disabledDateTime = useCallback((dates) => {
  //     let hours = moment().hours(); //0~23
  //     let minutes = moment().minutes(); //0~59
  //     let seconds = moment().seconds(); //0~59
  //     if (dates && moment(dates[1]).date() === moment().date()) {
  //         return {
  //             disabledHours: () => range(0, hours),
  //             disabledMinutes: () => range(0, minutes),
  //             disabledSeconds: () => range(0, seconds + 1),
  //         };
  //     }
  // }, []);

  const formatDate = useCallback((m) => {
    return formatUTCTime(m);
  }, []);

  const timeShow = useMemo(() => {
    let m;
    if (targetTime) {
      m = moment(targetTime);
    }
    if (m) {
      return formatDate(m);
    }
  }, [targetTime]);

  const openDate = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!targetTime && selectTag) {
      updateTime(Date.now() + times[selectTag - 1].value);
    }
  }, []);

  const showTagBox = (showCustom) => {
    return (
      <TagBoxStyled popup={!showCustom}>
        {times.map((time, idx) => (
          <span
            key={idx}
            className={selectTag === idx + 1 ? "active" : ""}
            onClick={() => chooseTag(idx + 1)}
          >
            {time.label}
          </span>
        ))}
        {showCustom && (
          <span className={isCustom ? "active" : ""} onClick={openDate}>
            {t("Vote.Custom")}
          </span>
        )}
      </TagBoxStyled>
    );
  };

  return (
    <VoteItem {...rest}>
      <Label>{label}</Label>
      <>
        {showTagBox(true)}
        <DisplayDateBox active={open}>
          <DisplayDate className="123" onClick={openDate}>
            {timeShow}
          </DisplayDate>
          <DateSVG className="icon" />
          <DateStyled
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            open={open}
            onOpenChange={setOpen}
            placeholder=""
            format={formatDate}
            disabledDate={disabledDate}
            // disabledTime={disabledDateTime}
            allowClear={false}
            onChange={handleChange}
            showTime={true}
            showNow={false}
          />
        </DisplayDateBox>
        {showTip && (
          <Tip>
            <img src={AlertSvg} alt="" />
            <span>{t("Vote.VoteTip")}</span>
          </Tip>
        )}
      </>
    </VoteItem>
  );
});

export default DateBox;

const FloatTag = css`
  position: absolute;
  bottom: 340px;
  left: 0;
`;

const TagBoxStyled = styled.div`
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;
  span {
    text-align: center;
    width: 88px;
    height: 32px;
    line-height: 32px;
    margin-right: 10px;

    background: rgba(255, 255, 255, 0.08);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.08);

    &:hover,
    &.active {
      background: #45beff;
      color: #182b47;
    }
  }
  ${(props) => props.popup && FloatTag}
`;

const DatePanel = styled.div`
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 44px;
  left: 0;
`;

const DateStyled = styled(DatePicker)`
  visibility: hidden;
  height: 0;
`;

const DisplayDateBox = styled.div`
  width: 100%;
  position: relative;
  height: 44px;
  background-color: #1e2b3f;
  border-radius: 10px;
  box-sizing: border-box;
  border: ${(props) => (props.active ? "1px solid #328aff" : "unset")};

  .icon {
    position: absolute;
    top: 11px;
    right: 20px;
  }
`;

const DisplayDate = styled.div`
  width: 100%;
  padding: 0 20px;
  height: 44px;
  line-height: 44px;
`;

const Tip = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  img {
    margin-right: 10px;
  }
  span {
    opacity: 0.5;
    font-size: 12px;
  }
`;
