export default {
  "DaoFactory": "0x09e97E2Aec33d194968b4e5A5C7Ae6eD6A90da4A",
  "actionGnosisConfirmProposal": "0xB31ca1Ff22B1F167f8766A529307BD22B722AF44",
  "actionGnosisSubmitProposal": "0xF5968FAB3C05e75bF8fF37821C31Dcf558D12f2d",
  "actionGrantMethodApply": "0x1e36d720E529134671330453BaD6CaC3134ebd8c",
  "actionGrantMethodRegister": "0x6fca191a0Ea521598299bD5D5218E9cE9aab6628",
  "actionGrantMethodVoteNewAuth": "0x6Ca778E9Bc9f45DFe47bd4B081cdF48d0cb12172",
  "actionOrgApplyJoin": "0x0FC2BF4025B13Be203Dd0fB8F3E5254c27a56936",
  "actionOrgMemberJoin": "0x0524E6E315B1C619F18c5C6e604c580B5Bc36E94",
  "actionOrgMemberQuit": "0xA5D3D28D70110B4BF69cDDCBBc4C11Bd39eF3f80",
  "actionOrgMgr": "0xAaE465650938beaF8F966C8114A972c3755f0F7e",
  "actionOrgSetJoinMode": "0x649c384afF16381366B7e30Aa9Bd7e5a439B5C8f",
  "actionOrgTransferOwner": "0xC6c2903Ad5F16Ccddd2cE16A66166Fe390C87Cc3",
  "actionOrgUpdateMembers": "0x8302E6cBA121bC9fb841cfe486ca92fF6D09C449",
  "actionVaultAddNFT": "0xd2c84263153E229739a7e2f08945ca5242D8164d",
  "actionVaultDepositToken": "0x79caEc12887E523261B3f9B94C28CEFEc0F63bDE",
  "actionVaultExeWithdrawToken": "0xA3cb73BCD0d4FDd703f983FCFD92C20232E4d153",
  "actionVaultGPAuthorize": "0xfDB49602efF97A45A3e7FE5dC55fA56A4EedC821",
  "actionVaultOptToken": "0x951EC1241671BD22b02d5EBEA156Cf53Aa0c1C2a",
  "actionVaultWithdrawToken": "0x02dE6ae2Cf9e77085bEfE92822b20518e1A5424A",
  "actionVenturesClaimBonus": "0x5db03b460E041C7Ea8570Fe3faeE3371EefDfbA7",
  "actionVenturesClaimShare": "0x8D5956e2D83aBc1289c3878C1b25D5028333993F",
  "actionVenturesRaiseFailClaim": "0x4539de7fFE8b62F52E545220F8167363F1076E29",
  "actionVenturesRaiseMoney": "0x9AF61f3aAe8c0CE6806250784c2DFb29cF3dfe06",
  "actionVenturesTestToSettlementPeriod": "0x80c335248185E3d205CC8b6cF074D3862e320089",
  "actionVenturesToFreezingPeriod": "0x941db4b9b878159524b696cA111d632Db2b63e4e",
  "actionVenturesToRaiseFail": "0x9176754735164A9141E74BabA15391213BEd18fd",
  "actionVoteAcceptGpApply": "0x92D2BbC43D1f632594557fB57E141a56d7820dd6",
  "actionVoteAcceptGpRegister": "0x81D7ED87F8cBaD781A3E7CF63E9BAbb49a69E10B",
  "actionVoteCancel": "0xA05db94D27AfF41a129e87C71a213D2D9F446A8A",
  "actionVoteExecute": "0x3752F01B84E0638465f8925f504208D421b89238",
  "actionVoteImpeachApply": "0x51195cbdD3eadbCCF493A2aE23B0DFdAD94B8800",
  "actionVoteImpeachRegister": "0x50323A462079d55C532940951a8Ae5729aD9B8d5",
  "actionVoteNew": "0x5f3e13C4CE9141Daf8f661297229fBeD0A870FCb",
  "actionVoteTransferManageFeeApply": "0x6D9217120c94F0ce923fb5FdE92d5668d189E8a9",
  "actionVoteTransferManageFeeRegister": "0xA0e92Fc2A0E975D78709D60928AA08379E00a138",
  "actionVoteTransferNFTApply": "0xd9a0d1f302C4Caa291e59954029A4559fe27EAf3",
  "actionVoteTransferNFTRegister": "0x9d0aAD3f9C6989837Bcf02732100FCb07ad383F5",
  "actionVoteTransferPeriodApply": "0xC8A84aab1c26512AE8615ae9a81C42858F50b3A2",
  "actionVoteTransferPeriodRegister": "0x6425016a92508Bc1F0e2a58E91e7A4C0Bf4e9257",
  "actionVoteVote": "0xA805A7cE343d8822d5C168c0BA2326e807C7e9E5",
  "actionVaultUniswapV2Router02Swap": "0xCc81bCA3b09bBC436EE8F8dF560C2A55166f3ec1",
  "actionVenturesSetRaiseSubTime": "0xc5FE55AEeFc326b36a0633676E8BB3f20974CBd7",
  "actionVenturesVestingWithdraw": "0x093D6817960bD9465Bc85ca2F436c739689206eF",
  "actionGrantMethodExe": "0xe8362568c79A0fbDD75A67b5DA868Dc8380F7EDC",
  "actionManageFeeTransferExe": "0x9f12B25C1F014b6366C89dB178d0FA1e36676cC9",
  "actionOrgAcceptGPExe": "0x3ce16AFFB8B928347ece66e25E9f4C71a8F06603",
  "actionOrgImpeachGPExe": "0x3F8e419847EEc1ecb2e47f611CBB72499ed3Be82",
  "actionVaultTransferExe": "0x1273C614a6C9C9aC21fbcC0b04C3A3C3148aEc18",
  "actionVaultTransferNFTExe": "0xeCa2605EDdBcD8255605391e536Dd33d87BAA52E",
  "actionVentureTransferPeriodExe": "0x9762427710329526644E148E78886911df22eD7b",
  "actionOrgUpdateMinRequireExe": "0x2347fCE7E94EAc66FdD73686A83e44BE2650FEc2"
};
