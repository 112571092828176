import { memo, useMemo } from "react";
import styled from "styled-components";
import SubmitedImg from "assets/images/Submit.svg";
import SwapLoading from "./swaploading";
import { useSwapContext } from "components/provider/swapProvider";
import { getShortDisplay } from "utils/publicJs";
import { useTranslation } from "react-i18next";
import SuccessSvg from "assets/images/Success.svg";
import FailedSvg from "assets/images/Failed.svg";
import Link from "components/common/LinkIcon";
import CloseIcon from "assets/images/Close.svg";
import Button from "components/common/button";

import { useNavigate, useParams } from "react-router-dom";

const TxStatus = ({ closeModal, children }) => {
  return (
    <BoxBg>
      <Box>
        <CloseBox onClick={closeModal}>
          <img src={CloseIcon} alt="" />
        </CloseBox>
        {children}
      </Box>
    </BoxBg>
  );
};

export const PendingBox = memo((props) => {
  const { t } = useTranslation();

  return (
    <TxStatus closeModal={props.closeModal}>
      <SubmitedBox>
        <div className="top">
          <img src={SubmitedImg} alt="" />
        </div>
        <TitleBox className="font-medium">
          {t("Swap.TransactionSubmitted")}{" "}
          <Link type="tx" hash={props.txHash} />
        </TitleBox>
      </SubmitedBox>
    </TxStatus>
  );
});

export const SwapSuccessBox = memo(({ closeModal, from, to, txHash }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { fullhandle, network: networkSimple } = useParams();

  const go2Vault = () => {
    navigate(`/${networkSimple}/${fullhandle}/portfolio/tokens`);
  }
  return (
    <TxStatus closeModal={closeModal}>
      <SubmitedBox style={{marginTop: "50px"}}>
        <div className="top">
          <img src={SuccessSvg} alt="" />
        </div>
        <SwapBox>
          <div>
            {t("Swap.SwapExactly")}{" "}
            <span className="font-bold">
              {getShortDisplay(from.amountDisplay, 9)} {from.symbol}
            </span>{" "}
          </div>
          <div>
            {t("Swap.for")}{" "}
            <span className="font-bold center">
              {getShortDisplay(to.amountDisplay, 9)} {to.symbol}
            </span>
          </div>
        </SwapBox>
        <LastLine>
          <span>{t("Swap.View")}</span>
          <Link type="tx" hash={txHash} />
        </LastLine>
      </SubmitedBox>
      <Button primary onClick={go2Vault} width="unset" style={{marginTop: "26px", marginBottom: "20px"}}>
        {t("Swap.CheckVault")}
      </Button>
    </TxStatus>
  );
});

export const SwapFailedBox = memo(({ closeModal, txHash }) => {
  const { t } = useTranslation();
  return (
    <TxStatus closeModal={closeModal}>
      <SubmitedBox>
        <div className="top">
          <img src={FailedSvg} alt="" />
        </div>
        <TitleBox>{t("Swap.TransactionFailed")}</TitleBox>
        {txHash && (
          <LastLine>
            <span>{t("Swap.View")}</span>
            <Link hash={txHash} type="tx" />
          </LastLine>
        )}
      </SubmitedBox>
    </TxStatus>
  );
});

const SwapStatus = memo(() => {
  const {
    state: { currentTx },
    dispatchEvent,
  } = useSwapContext();

  const closeStatusModal = () => {
    dispatchEvent({ type: "SET_CURRTX", payload: null });
  };
  const hashLink = useMemo(() => {
    return currentTx?.hash || "";
  }, [currentTx]);

  if (!currentTx) {
    return <></>;
  }

  if (currentTx.status === 0) {
    return <PendingBox closeModal={closeStatusModal} txHash={hashLink} />;
  }
  if (currentTx.status === 1) {
    return (
      <SwapSuccessBox
        from={currentTx.from}
        to={currentTx.to}
        txHash={hashLink}
        closeModal={closeStatusModal}
      />
    );
  }
  if (currentTx.status === 2) {
    return <SwapFailedBox txHash={hashLink} closeModal={closeStatusModal} />;
  }
  return <></>;
});

export default SwapStatus;

const BoxBg = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
`;
const Box = styled.div`
  width: 365px;
  min-height: 262px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const SubmitedBox = styled.div`
  .top {
    text-align: center;
    margin-bottom: 26px;
  }
`;
const TitleBox = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  align-items: center;
  img {
    margin-left: 8px;
  }
  span {
    padding: 0 5px;
  }
`;

const CloseBox = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  img {
    cursor: pointer;
    width: 24px;
  }
`;

const LastLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  span {
    margin-right: 7px;
    opacity: 0.5;
  }
`;

const SwapBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  .font-bold {
    font-family: "Poppins-Bold";
  }
`;
