import React from "react";
import styled from "styled-components";
import { useAppContext } from "./provider/appProvider";
import BaseModal from "./modal/general";
import WarningIcon from "assets/images/Warning.svg";

const MsgModal = (props) => {
  const { showMsg, handleClose } = props;
  const {
    state: { msgType },
  } = useAppContext();

  return (
    <BaseModal
      width={364}
      closable={msgType && msgType.closable !== false}
      show={showMsg}
      closeModal={handleClose}
      glass
    >
      <Msg>
        <img src={WarningIcon} alt="" />
        <div className="msg">{msgType && msgType.msg}</div>
      </Msg>
    </BaseModal>
  );
};

export default MsgModal;

const Msg = styled.div`
  text-align: center;
  .msg {
    margin-top: 20px;
  }
`;
