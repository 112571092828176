import * as React from "react";
import styled from "styled-components";

const CircleSVG = (props) => (
  <svg
    className="circle-container"
    // viewBox="2 -2 28 36"
    width={48}
    height={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle className="circle-container__background" r={20} cx={24} cy={24} />
    <circle className="circle-container__progress" r={20} cx={24} cy={24} />
  </svg>
);

export default function CircleProgress({ color, progress }) {
  // React.useEffect(() => {
  //     console.log(
  //       "getTotalLength:",
  //       document.querySelector(".circle-container__progress").getTotalLength()
  //     );
  // }, [])
  return (
    <CircleProgressStyled
      color={color}
      progress={(124.8 * (100 - progress)) / 100}
    >
      <CircleSVG />
    </CircleProgressStyled>
  );
}

const CircleProgressStyled = styled.div`
  margin-bottom: 16px;
  .circle-container {
    transform: rotate(-90deg);
  }

  .circle-container__background {
    fill: none;
    stroke: #000;
    stroke-width: 8px;
  }

  .circle-container__progress {
    fill: none;
    stroke-linecap: round;
    stroke: ${(props) => props.progress === 124.8 ? "unset" : (props.color || "#1ac0b4")};
    stroke-dasharray: 124.8;
    stroke-linecap: round;
    stroke-width: 8px;
    stroke-dashoffset: ${(props) => props.progress || 0};
  }
`;
