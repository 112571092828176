import React, { useEffect, useMemo, useState } from "react";

import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import { useCallback } from "react";
import styled from "styled-components";

const Box = styled.div`
  position: relative;
`;
const InnerBox = styled.div`
  position: absolute;
  left: 115px;
  top: 148px;
  dl {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    dt {
      width: 10px;
      height: 10px;
      background: #ffa4d1;
      border-radius: 10px;
      margin-right: 4px;
    }
    &:first-child {
      dt {
        background: #79dfc4;
      }
    }
  }
`;

const Status = styled.div`
  color: #4f88ff;
  font-family: "Poppins-Medium";
  text-align: center;
`;

// const option = {
//   title: {
//     show: true,
//     text: "Ongoing",
//     x: "center",
//     y: "45%",
//     backgroundColor: "#FFEBF5",
//     borderRadius: 8,
//     padding: 10,
//     textStyle: {
//       fontFamily: "AvenirNext-Regular",
//       fontSize: 14,
//       fontStyle: "normal",
//       fontWeight: "normal",
//
//       color: "#D51172",
//     },
//   },
//   tooltip: {
//     trigger: "item",
//     confine: false,
//     formatter: "{c} <br/>{b}:  ({d}%)",
//   },
//   color: ["#79DFC4", "#FFA4D1", "#7DDC8B", "#96E9F2", "#FFC475", "#D51172"],
//   legend: {
//     show: true,
//     orient: "horizontal",
//     x: "center",
//     y: "80%",
//     align: "left",
//     icon: "circle",
//     textStyle: {
//       fontWeight: 300,
//       color: "#10164B",
//       fontSize: "16px",
//     },
//   },
//   series: [
//     {
//       name: "",
//       type: "pie",
//       radius: ["43%", "60%"],
//       center: ["50%", "47%"],
//       avoidLabelOverlap: false,
//       label: {
//         show: false,
//       },
//       emphasis: {
//         label: {
//           show: false,
//         },
//       },
//       labelLine: {
//         normal: {
//           show: true,
//         },
//       },
//       data: [],
//     },
//   ],
// };

export default function VoteEcharts({ status, optionlist }) {
  const statusText = useMemo(() => {
    switch (status) {
      case 0:
        return "Ongoing";
      case 1:
        return "Agreed";
      case 2:
        return "Rejected";
      default:
        return "";
    }
  }, [status]);

  useEffect(() => {
    let arr = [];
    optionlist.forEach((item, index) => {
      if (!index) {
        arr.push({
          value: item.value,
          name: item.name,
          itemStyle: {
            color: "#79DFC4",
            borderWidth: 10,
          },
        });
      } else {
        arr.push({
          value: item.value,
          name: item.name,
          itemStyle: {
            color: "rgba(0,0,0,0)",
            borderWidth: 0,
          },
        });
      }
    });

    if (optionlist.length) {
      const c = echarts.init(document.getElementById("main"));
      c.setOption({
        tooltip: {
          trigger: "none",
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },
        animation: false,
        series: [
          {
            name: "background",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#25344d",
              color: "#FFA4D1",
              borderWidth: 10,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              disabled: true,
            },
            data: [{ value: 1, name: "" }],
          },
          {
            name: "foreground",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 80,
              borderColor: "#25344d",
              borderWidth: 10,
            },
            label: {
              show: false,
              position: "center",
            },

            labelLine: {
              show: false,
            },
            emphasis: {
              disabled: true,
            },
            data: arr,
          },
        ],
      });
    }
  }, [optionlist]);

  return (
    <Box>
      <Status>{statusText}</Status>
      <div id="main" style={{ width: "100%", height: "300px" }} />
      <InnerBox>
        {optionlist.map((item, index) => (
          <dl key={index}>
            <dt />
            <dd>{item.name}</dd>
          </dl>
        ))}
      </InnerBox>
    </Box>
  );
}
