import { useMemo, useState, useCallback } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import VaultABI from "../abi/web3Abi/VaultManager.json";
import VentureABI from "../abi/web3Abi/VenturesManager.json";
import DaoOrgABI from "../abi/web3Abi/DaoOrganization.json";
import VoteABI from "../abi/web3Abi/VoteManager.json";
import OrgABI from "../abi/web3Abi/OrgManager.json";
import ERC20ABI from "../abi/web3Abi/MockERC20.json";
import MainABI from "../abi/web3Abi/DaoFactory.json";
import VoteExcutionABI from "../abi/web3Abi/VoteExecutionManager.json";
import GrantABI from "../abi/web3Abi/GrantMethodManager.json";
import AaveABI from "../abi/web3Abi/ActionVaultAave.json";
import MulticallABI from "../abi/web3Abi/MultiCall.json";
import VenturesStockABI from "../abi/web3Abi/VenturesStockManager.json";

import { useNetworkData } from "hooks/useNetwork";
import { useClientContext } from "components/provider/clientProvider";
import { useAuthContext } from "components/provider/authProvider";
import useCheckLogin from "./useCheckLogin";

const AddressZero = "0x0000000000000000000000000000000000000000";

// account is not optional
function getSigner(provider, account) {
  return provider.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(provider, account) {
  return account ? getSigner(provider, account) : provider;
}

function getContract(address, ABI, provider, account) {
  if (!ethers.utils.isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new ethers.Contract(address, ABI, provider);
}

export const useContract = (address, ABI, chainId) => {
  // const isLogin = useCheckLogin();
  const { provider } = useWeb3React();
  const { providers } = useClientContext();

  return useMemo(() => {
    if (!address || !ABI) return null;
    if (!chainId && provider) {
      return getContract(address, ABI, provider);
    }
    // console.warn("chainId>>>> ", chainId, providers[chainId])
    // return getContract(address, ABI, isLogin ? provider : providers[chainId]);
    return getContract(address, ABI, providers[chainId]);
  }, [address, providers, chainId, provider]);
};

const useDaoOrganization = (address, chainId) => {
  return useContract(address, chainId);
};

export const useVaultContract = (address, chainId) => {
  return useContract(address, VaultABI, chainId);
};

export const useManageFeeContract = (address, chainId) => {
  return useContract(address, VaultABI, chainId);
};

export const useERC20Contract = (address, chainId) => {
  return useContract(address, ERC20ABI, chainId);
};

export const useVentureContract = (address, chainId) => {
  return useContract(address, VentureABI, chainId);
};

export const useOrgContract = (address, chainId) => {
  return useContract(address, OrgABI, chainId);
};

export const useDaoOrgContract = (address, chainId) => {
  return useContract(address, DaoOrgABI, chainId);
};

export const useVoteContract = (address, chainId) => {
  return useContract(address, VoteABI, chainId);
};

export const useDaoFactoryContract = (chainId) => {
  const networkData = useNetworkData(chainId);
  return useContract(
    networkData && networkData.mainAddress.DaoFactory,
    MainABI,
    chainId
  );
};

export const useVoteExecutionManagerContract = (address, chainId) => {
  return useContract(address, VoteExcutionABI, chainId);
};

export const useGrantManagerContract = (address, chainId) => {
  return useContract(address, GrantABI, chainId);
};

export const useAaveActionContract = (address, chainId) => {
  return useContract(address, AaveABI);
};


export const useMulticallContract = (address, chainId) => {
  return useContract(address, MulticallABI, chainId);
}

export const useErc20StockContract = (address, chainId) => {
  return useContract(address, VenturesStockABI, chainId);
}