import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  useNavigate,
  Routes,
  Route,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import { BigNumber, ethers } from "ethers";
import { Multicall } from "ethereum-multicall";

import HomeDefault from "components/home";
import Portfolio from "components/portfolio/portfolio";

import HomeImg from "../../assets/gif/icon/Home.svg";
import HomeHover from "../../assets/gif/home.gif";
import PortfolioImg from "../../assets/gif/icon/chart.svg";
import PortfolioHover from "../../assets/gif/chart.gif";
import VoteImg from "../../assets/gif/icon/Vote.svg";
import VoteHover from "../../assets/gif/vote.gif";
import ManagersImg from "../../assets/gif/icon/Manager.svg";
import ManagersHover from "../../assets/gif/manager.gif";
import ManagementImg from "../../assets/gif/icon/Apps.svg";
import ManagementHover from "../../assets/gif/book.gif";
import WalletConnectHover from "assets/gif/walletConnect.gif";
import WalletConnectImg from "assets/gif/icon/Connect.svg";

import { useDaoContext } from "views/dao/provider";
// import useDaoIdByHandle from "hooks/useDaoIdByHandle";
import useDaoDetailByHandle from "hooks/useDaoDetailByHandle";
import api from "api/index";
import {
  VENTURE_MANAGER,
  VENTURE_MANAGEFEE_MANAGER,
  VENTURE_NFT_STOCK_MANAGER,
  VENTURE_STOCK_MANAGER,
  ORG_MANAGER,
  DAO_ORG,
  NATIVE_TOKEN,
  PERIOD_NO,
} from "utils/constant";
import VoteNV from "components/newVersionVote";
import Managers from "components/manager/managers";
import Settings from "components/settings";
import Apps from "components/apps";
import AppAndSettings from "views/appAndsettings";
import WalletConnect from "components/apps/walletConnect/normal";
import SideNavLayout from "components/layout";
import {
  useDaoFactoryContract,
  useOrgContract,
  useVentureContract,
  useERC20Contract,
} from "hooks/useContract";
import DaoHeader from "./header";
import NewEvent from "./newEvent";
import useCheckLogin from "hooks/useCheckLogin";
import { useClientContext } from "components/provider/clientProvider";
import { walletConnect } from "components/wallet/connector";
import { useTranslation } from "react-i18next";
import { useAppContext } from "components/provider/appProvider";
import { useNetworkData } from "hooks/useNetwork";
import { useMulticallContract } from "hooks/useContract";
import { getJsonFileByFetch } from "api/apiHTTP";
import BgImg from "../../assets/Bg.png";

import calls from "./multicalls";
import { call } from "file-loader";

const STEPS = [
  {
    name: "Left.Home",
    key: "dashboard",
    icon: HomeImg,
    iconHover: HomeHover,
    newDot: false,
  },
  {
    name: "Left.Portfolio",
    key: "portfolio",
    icon: PortfolioImg,
    iconHover: PortfolioHover,
    newDot: false,
  },
  {
    name: "Left.Vote",
    key: "vote",
    icon: VoteImg,
    iconHover: VoteHover,
    newDot: false,
  },
  {
    name: "Left.Managers",
    key: "managers",
    icon: ManagersImg,
    iconHover: ManagersHover,
    newDot: false,
  },
  {
    name: "Left.Management",
    key: "app_settings",
    icon: ManagementImg,
    iconHover: ManagementHover,
    newDot: false,
  },
  {
    name: "Settings.WalletConnect",
    key: "wallet_connect",
    icon: WalletConnectImg,
    iconHover: WalletConnectHover,
    newDot: false,
  },
];

export default function Frame(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fullhandle, network: networkSimple } = useParams();
  const { pathname } = useLocation();

  const { subRoute } = props;
  const { id, daoChainId, dao } = useDaoDetailByHandle(
    fullhandle,
    networkSimple
  );

  const isLogin = useCheckLogin();
  const { providers } = useClientContext();
  const {
    dispatch: dispatchEvent,
    state: { gateway, fundList },
  } = useAppContext();

  const {
    state: {
      componentAddressMap,
      fundOrPeriod,
      fundInfo,
      fundToken,
      hasVotes,
      hasWct,
      period,
    },
    dispatch,
  } = useDaoContext();
  const { account, chainId } = useWeb3React();
  const maincontract = useDaoFactoryContract(daoChainId);

  const orgContract = useOrgContract(
    componentAddressMap.get(ORG_MANAGER),
    daoChainId
  );
  const ventureContract = useVentureContract(
    dao?.ventures_addr || componentAddressMap.get(VENTURE_MANAGER),
    daoChainId
  );

  const network = useNetworkData(daoChainId);
  const contract = useMulticallContract(network?.multicall, daoChainId);

  const multicall = useMemo(() => {
    if (!daoChainId || !providers[daoChainId]) {
      return;
    }
    const _config = {
      ethersProvider: providers[daoChainId],
      tryAggregate: true,
    };
    if (network.multicallCustomContractAddress) {
      _config.multicallCustomContractAddress =
        network.multicallCustomContractAddress;
    }
      return new Multicall(_config);
  }, [providers, daoChainId]);

  const [step, setStep] = useState("dashboard");
  const [navs, setNavs] = useState(STEPS);
  const [isNewDao, setIsNewDao] = useState(false);

  useEffect(async () => {
    if (isLogin && daoChainId && chainId && chainId !== daoChainId) {
      dispatchEvent({ type: "SWITCH_CHAIN", payload: daoChainId });
    }
  }, [chainId, daoChainId, isLogin]);

  useEffect(() => {
    return () => {
      dispatchEvent({ type: "SWITCH_CHAIN", payload: null });
    };
  }, []);

  useEffect(() => {
    setStep(subRoute);
  }, [subRoute]);

  useEffect(() => {
    if (dao && dao.dao_version === 1) {
      window.open(
        `${window.location.protocol}//${window.location.host}/v1${pathname}`,
        "_self"
      );
    }
  }, [dao]);

  useEffect(() => {
    if (hasVotes !== undefined && hasWct !== undefined) {
      const newNavs = STEPS.map((s) => {
        let newDot = false;
        if (s.key === "vote") {
          newDot = hasVotes;
        } else if (s.key === "app_settings") {
          newDot = !!hasWct;
        }
        return {
          ...s,
          newDot: newDot,
        };
      });
      setNavs(newNavs);
    }
  }, [hasVotes, hasWct]);

  const onChangeStep = (key) => {
    if (key === "wallet_connect") {
      if (dao.dao_type === 1 && period < PERIOD_NO.MANAGEMENT) {
        setStep(step);
        dispatchEvent({ type: "MSGTYPE", payload: {msg: t("Msg.WalletConnect")} });
        return;
      }
    }
    setStep(key);
    navigate(`/${networkSimple}/${fullhandle}/${key}`);

    // clearr status
    dispatch({ type: "SET_PERIOD_STATUS", payload: false });
    dispatch({ type: "SET_TAV_STATUS", payload: false });
    if (dao.et_token_type === 1) {
      dispatch({ type: "SET_CLAIM_STATUS", payload: false });
    }
  };

  const formatFundToken = ({address, ...rest}) => {
    if (address.toLowerCase() === network.wrappedToken) {
      return {
        address,
        ...network.nativeCurrency,
        deci: network.nativeCurrency.decimals,
        logo: network.logo,
        isWrapped: true,
        origin: {
          address,
          ...rest,
          logo: network.logo,
        },
      };
    } else {
      const findToken = fundList.find(
        (f) => f.address.toLowerCase() === address.toLowerCase()
      );
      return {
        address,
        ...rest,
        logo: findToken?.logoURI,
      };
    }
  }

  useEffect(() => {
    if (!id || !dao) {
      return;
    }
    dispatch({
      type: "SET_BASE_DATA",
      payload: {
        id: id,
        ...dao,
      },
    });
    if (dao.dao_type === 1) {
      dispatch({ type: "SET_MULTI_SIGN", payload: false });
    }
    if (dao.fund_token_address) {
      const fund_token = formatFundToken({
        address: dao.fund_token_address,
        deci: dao.fund_token_decimal,
        name: dao.fund_token_name,
        symbol: dao.fund_token_symbol,
      });
      dispatch({ type: "SET_FUND_TOKEN", payload: fund_token });
      dispatch({ type: "SET_MANAGE_FEE_TOKEN", payload: fund_token });
      try {
        initStockToken(
          dao.et_token_address,
          "SET_STOCK_TOKEN",
          fund_token.address,
          fund_token.deci,
          dao.et_token_type === 1 ? "token" : "nft"
        );
      } catch (error) {
        console.error("initStockToken failed", error);
      }
    } else {
      setIsNewDao(true);
    }

    dispatch({
      type: "SET_PERIOD",
      payload: dao.period,
    });
    dispatch({
      type: "SET_DAO_ID",
      payload: id,
    });
    dispatch({
      type: "SET_DAO_CHAIN",
      payload: daoChainId,
    });
    dispatch({
      type: "SET_FULL_HANDLE",
      payload: fullhandle,
    });
  }, [id, dao, fullhandle]);

  const initFundToken = async (address) => {
    const result = await calls.getFundToken(multicall, address);
    const origiToken = {
      address,
      ...result,
      deci: result.decimals,
    };

    let token;
    if (address.toLowerCase() === network.wrappedToken) {
      token = {
        address,
        ...network.nativeCurrency,
        deci: network.nativeCurrency.decimals,
        logo: network.logo,
        isWrapped: true,
        origin: {
          ...origiToken,
          logo: network.logo,
        },
      };
    } else {
      const findToken = fundList.find(
        (f) => f.address.toLowerCase() === address.toLowerCase()
      );
      token = {
        ...origiToken,
        logo: findToken?.logoURI || findToken?.logo,
        logoURI: findToken?.logoURI || findToken?.logo,
      };
    }
    dispatch({ type: "SET_MANAGE_FEE_TOKEN", payload: token });
    dispatch({ type: "SET_FUND_TOKEN", payload: token });
  };

  const initStockToken = async (
    address,
    type,
    fundTokenAddress,
    fundDecimal,
    tokentType
  ) => {
    let token;
    if (tokentType === "nft") {
      const result = await calls.getNftToken(
        multicall,
        address,
        fundTokenAddress
      );
      const uri = result.uri;
      const data = await getJsonFileByFetch(gateway + uri);
      token = {
        ...result,
        canTransfer: result.canTransfer,
        price: ethers.utils.formatUnits(
          BigNumber.from(
            result.BuyMode === 1 ? result.TokenMaxPrice : result.price
          ),
          fundDecimal
        ),
        ...data,
        image: gateway + data.image,
      };
      dispatch({ type: "SET_CLAIM_STATUS", payload: true });
    } else {
      const result = await calls.getErc20Token(
        multicall,
        address,
        fundTokenAddress
      );
      token = {
        ...result,
        deci: result.decimals,
        price: ethers.utils.formatUnits(
          BigNumber.from(
            result.BuyMode === 1 ? result.TokenMaxPrice : result.price
          ),
          fundDecimal
        ),
      };
    }

    dispatch({
      type,
      payload: {
        type: tokentType,
        address,
        ...token,
      },
    });
    dispatch({ type: "SET_STOCK_STATUS", payload: true });
  };

  useEffect(async () => {
    if (!isNewDao) {
      return;
    }
    if (ventureContract) {
      try {
        const fundTokenAddress = await ventureContract.getTokenType();
        console.log("fundTokenAddress:", fundTokenAddress);
        if (!fundTokenAddress) {
          return;
        }
        if (
          fundTokenAddress &&
          fundTokenAddress === ethers.constants.AddressZero
        ) {
          // multisign
          return;
        } else {
          dispatch({ type: "SET_MULTI_SIGN", payload: false });
        }
        try {
          initFundToken(fundTokenAddress);
        } catch (error) {
          console.error("initFundToken failed", error);
        }
      } catch (error) {
        console.error("handleFundToken failed", error);
      }
    }
  }, [isNewDao, ventureContract, componentAddressMap]);

  useEffect(() => {
    if (!fundToken) {
      return;
    }
    const nftAddress = componentAddressMap.get(VENTURE_NFT_STOCK_MANAGER);
    const stockAddress = componentAddressMap.get(VENTURE_STOCK_MANAGER);
    if (!nftAddress && !stockAddress) {
      return;
    }
    if (nftAddress) {
      initStockToken(
        nftAddress,
        "SET_STOCK_TOKEN",
        fundToken.address,
        fundToken.deci,
        "nft"
      );
    } else if (stockAddress) {
      initStockToken(
        stockAddress,
        "SET_STOCK_TOKEN",
        fundToken.address,
        fundToken.deci,
        "token"
      );
    }
  }, [fundToken, componentAddressMap]);

  const initDaoBase = useCallback(
    async (_id) => {
      try {
        return maincontract.instanceMap(_id);
      } catch (error) {
        console.error("[instanceMap] failed", error);
      }
    },
    [maincontract]
  );

  const initDaoContract = async (_daoOrganization, provider) => {
    const componentMap = new Map();

    let templateConfigAddress;
    try {
      componentMap.set(DAO_ORG, _daoOrganization);
      const daoOrg = await api.daoOrg.getContract(provider, _daoOrganization);
      templateConfigAddress = await daoOrg.templateConfig();
    } catch (error) {
      console.error("get templateConfigAddress failed", error);
      return;
    }

    const result = await calls.getAllComponents(
      multicall,
      templateConfigAddress
    );
    for (const k in result) {
      if (result[k] !== ethers.constants.AddressZero) {
        componentMap.set(k, result[k]);
        console.log(`-> ${k} - ${result[k]}`);
      }
    }
    dispatch({ type: "SET_COMPONENTS_ADDRESS", payload: componentMap });
  };

  useEffect(async () => {
    if (fundOrPeriod === "fund") {
      try {
        const raised = await ventureContract.getRaisedAmount();
        dispatch({
          type: "SET_FUND_INFO",
          payload: {
            ...fundInfo,
            raised: ethers.utils.formatUnits(raised, fundToken.deci),
          },
        });
        dispatch({ type: "UPDATE_FUND_OR_PERIOD", payload: null });
      } catch (error) {
        console.error("fundOrPeriod failed", error);
      }
    }
  }, [fundOrPeriod]);

  const handleVenturesInfo = async () => {
    try {
      const result = await calls.getVenturesInfo(
        multicall,
        componentAddressMap.get(VENTURE_MANAGER)
      );
      const bonus = BigNumber.from(result.totalGPBonusPercent).toNumber();
      dispatch({ type: "SET_CARRIED_INTEREST", payload: bonus });

      const feePercent = BigNumber.from(result.ManageFeePercent).toNumber();
      dispatch({ type: "SET_MANAGE_FEE", payload: feePercent });

      const { deci } = fundToken;

      const fundInfo = {
        raised: ethers.utils.formatUnits(
          BigNumber.from(result.getRaisedAmount),
          deci
        ),
        goal: ethers.utils.formatUnits(
          BigNumber.from(result.RaiseMinSoftCap),
          deci
        ),
        hard: BigNumber.from(result.RaiseMaxHardCap).eq(
          ethers.constants.MaxUint256
        )
          ? ""
          : ethers.utils.formatUnits(
              BigNumber.from(result.RaiseMaxHardCap),
              deci
            ),
      };
      dispatch({ type: "SET_FUND_INFO", payload: fundInfo });
      dispatch({ type: "SET_FUND_STATUS", payload: true });
    } catch (error) {
      console.error("handleVenturesInfo failed:", error);
    }
  };

  useEffect(() => {
    if (!fundToken) {
      return;
    }
    if (!componentAddressMap.get(VENTURE_MANAGER)) {
      return;
    }
    handleVenturesInfo();
  }, [fundToken, componentAddressMap]);

  useEffect(async () => {
    if (!orgContract) {
      return;
    }
    if (!isLogin) {
      return;
    }
    try {
      await api.org.whoAmI(orgContract, account).then((data) => {
        if (!data) return;
        dispatch({
          type: "WhO_AM_I",
          payload: {
            isOwner: data[0],
            isGP: data[2],
          },
        });
      });
    } catch (error) {
      console.error("get whoAmI failed", error);
    }
  }, [orgContract, isLogin]);

  useEffect(async () => {
    if (!id || !maincontract || !multicall) {
      return;
    }
    // base data
    try {
      const data = await initDaoBase(id);
      // base contract
      if (data) {
        dispatch({ type: "SET_OWNER", payload: data.owner });
        initDaoContract(data.daoOrganization, providers[daoChainId]);
      }
    } catch (error) {
      console.error("???error:", error);
    }
  }, [id, maincontract, contract]);

  return (
    <Box>
      {/* <NewEvent /> */}
      <DaoHeader />
      <SideNavLayout navs={navs} onChangeNav={onChangeStep} step={step}>
        <Routes>
          <Route path="dashboard/*" element={<HomeDefault />} />
          <Route path="portfolio/*" element={<Portfolio />} />
          <Route path="vote/*" element={<VoteNV />} />
          <Route path="managers" element={<Managers />} />
          <Route path="wallet_connect" element={<WalletConnect />} />
          {/* app & settings */}
          <Route path="app_settings" element={<AppAndSettings />} />
          <Route path="app/*" element={<Apps />} />
          <Route path="settings/*" element={<Settings />} />
        </Routes>
      </SideNavLayout>
    </Box>
  );
}

const Box = styled.div`
  background: url(${BgImg});
  background-size: 100%;
  height: 100vh;
`;
