import { useWeb3React, useMemo } from "@web3-react/core";
import { memo, useState, useEffect } from "react";

import { useDaoContext } from "views/dao/provider";
import useWalletConnect from "hooks/useWalletConnect";
import useSigner from "hooks/useSigner";
import { useVaultContract } from "hooks/useContract";
import { DAO_ORG, ORG_MANAGER, VAULT_MANAGER } from "utils/constant";
import api from "api";
import TxSubmitModal from "./modals/TxSubmitModal";

import { useWCTContext } from "components/provider/WCTProvider";
import { CONNECTION_STATUS } from "utils/constant";
import { useAppContext } from "components/provider/appProvider";
import { useTranslation } from "react-i18next";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import { generateSafeTxHash } from "utils/safe/index";
import { ethers } from "ethers";
import { getTxIdFromEvent } from "utils/utils";

import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import { NFT_SOURCE } from "api/graphql/subQuery";
import { useParams } from "react-router-dom";

const WalletConnectIndex = memo(() => {
  const { account, chainId } = useWeb3React();
  const { t } = useTranslation();

  const { fullhandle, network: networkSimple } = useParams();

  const {
    state: { exploreScan },
    dispatch,
  } = useAppContext();

  const {
    state: { status, connectURI, behavior, source },
    dispatch: dispatchEvent,
  } = useWCTContext();

  const {
    state: {
      daoId,
      componentAddressMap,
      whoAmI: { isOwner, isGp },
    },
  } = useDaoContext();
  const vaultContract = useVaultContract(componentAddressMap.get(VAULT_MANAGER));

  const signer = useSigner();

  const {
    wcClientData,
    wcConnect,
    wcDisconnect,
    rejectWithMessage,
    approveCallback,
  } = useWalletConnect(componentAddressMap.get(VAULT_MANAGER), chainId);

  const [txdata, setTxdata] = useState({});
  const [showTxModal, setShowTxModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  // callback
  const onReceivedTx = (data) => {
    // eth_sendTransaction
    console.log("[onReceivedTx]", data);
    const tx = (data.txs || [])[0];
    if (tx) {
      tx.id = data.id;
      setTxdata(tx);
      setShowTxModal(true);
    }
  };
  const onReceivedMultiTx = (data) => {
    // gs_multi_send
    console.log("[onReceivedMultiTx]", data);
    setTxdata(data);
  };
  const onReceivedSign = (data) => {
    // eth_sign
    console.log("[onReceivedSign]", data);
  };
  const onReceivedPersionalSign = (data) => {
    // personal_sign
  };

  const checkSourceAppIsNFT = (source) => {
    for (const key in NFT_SOURCE) {
      if (NFT_SOURCE[key] === source) {
        return "nft"
      }
    }
    return "wallet_connect";
  }

  const onSubmitTx = async (tx_id) => {
    // call contract
    try {
      setSubmitLoading(true);
      const res = await vaultContract
        .connect(signer)
        .submitTransaction(txdata.to, txdata.value, source, txdata.data);
      
      const txHash = res.hash;

      const vaultAddress = componentAddressMap.get(VAULT_MANAGER).toLowerCase();

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.proposalCreating"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.CreateProposal,
          data: {
            chainId,
            daoId,
            tx_id,
            pathname: `/${networkSimple}/${fullhandle}/wallet_connect`,
            source,
            orgContractAddress: componentAddressMap
              .get(ORG_MANAGER)
              .toLowerCase(),
            txData: {
              vaultAddress,
              networkId: chainId,
              safeAddress: vaultAddress, // vaultaddress
              safeVersion: "",
              baseGas: "0",
              data: txdata.data,
              gasPrice: "0",
              gasToken: ethers.constants.AddressZero,
              // nonce: 0,  // txid
              operation: 0,
              refundReceiver: ethers.constants.AddressZero,
              safeTxGas: "0",
              to: txdata.to,
              valueInWei: txdata.value,
            },
          },
        },
      });
    } catch (error) {
      console.error(error);
      // showNotification(NotificationType.Fail, t("Notification.proposalFailed"));
      rejectWithMessage(tx_id);
    } finally {
      setShowTxModal(false);
      setSubmitLoading(false);
    }
  };

  useSubcribe(TransactionEvent.WalletConnect, (_, data) => {
    console.log("====listen===", data);
    if (daoId !== data.daoId) {
      return;
    }
    const txData = data.txData
    const vaultAddress = txData.vaultAddress;
    const id = data.newId;
  
    const txHash = generateSafeTxHash(vaultAddress, "", {
      ...txData,
      nonce: id,
    });
    console.log("txHash: ", txHash);
    approveCallback(data.tx_id, txHash);
  });

  const onCancelTx = () => {
    setShowTxModal(false);
    rejectWithMessage(txdata.id);
  };

  useEffect(() => {
    console.log("wcClientData:", wcClientData);
    if (wcClientData) {
      dispatchEvent({
        type: "SET_STATUS",
        payload: CONNECTION_STATUS.CONNECTED,
      });
      dispatchEvent({
        type: "SET_SOURCE",
        payload: wcClientData.name,
      });
    } else {
      dispatchEvent({
        type: "SET_STATUS",
        payload: CONNECTION_STATUS.DISCONNECTED,
      });
    }
    dispatchEvent({ type: "SET_WCDATA", payload: wcClientData });
  }, [wcClientData]);

  useEffect(() => {
    if (status === CONNECTION_STATUS.CONNECTING) {
      try {
        wcConnect({
          uri: connectURI,
          callbacks: [
            onReceivedTx,
            onReceivedMultiTx,
            onReceivedSign,
            onReceivedPersionalSign,
          ],
        });
      } catch (error) {
        console.error("connected failed", error);
      }
    }
  }, [connectURI]);

  useEffect(() => {
    if (behavior === "disconnect") {
      wcDisconnect();
    }
  }, [behavior, wcDisconnect]);

  useEffect(() => {
    const vaultAddress = componentAddressMap.get(VAULT_MANAGER);
    if (!wcClientData || !vaultAddress) {
      const session = localStorage.getItem(`SubDAO_SESSION_${vaultAddress}`);
      if (session) {
        wcConnect({
          session: JSON.parse(session),
          callbacks: [
            onReceivedTx,
            onReceivedMultiTx,
            onReceivedSign,
            onReceivedPersionalSign,
          ],
        });
      }
    }
  }, [wcClientData, componentAddressMap]);

  return (
    <TxSubmitModal
      visible={showTxModal}
      data={txdata}
      onSubmit={onSubmitTx}
      onCancel={onCancelTx}
      submitLoading={submitLoading}
    />
  );
});

export default WalletConnectIndex;
