import styled from "styled-components";
import React, { memo, useMemo, useState } from "react";
import CopyStr from "../copy";
import { useDaoContext } from "../../views/dao/provider";
import {
  DAO_ORG,
  VOTE_MANAGER,
  VAULT_MANAGER,
  ORG_MANAGER,
  VENTURE_MANAGER,
  VENTURE_STOCK_MANAGER,
  GRANT_MANAGER,
  VOTE_EXECUTION,
  VENTURE_MANAGEFEE_MANAGER,
} from "../../utils/constant";
import UpgradeIcon from "assets/images/Upgrade.svg";
import { useTranslation } from "react-i18next";
import UpgradeContractsModal from "components/modal/upgrade";
import { IndexTag } from "assets/styles/common";

const Contracts = memo(() => {
  const { t } = useTranslation();
  const { state: daoState } = useDaoContext();
  const { componentAddressMap } = daoState;
  const [showModal, setShowModal] = useState(false);

  const contractsList = useMemo(() => {
    return [
      { name: "Vault", key: VAULT_MANAGER },
      { name: "Vote", key: VOTE_MANAGER },
      { name: "Org", key: ORG_MANAGER },
      { name: "Venture", key: VENTURE_MANAGER },
      { name: "Token", key: VENTURE_STOCK_MANAGER },
      { name: "Instance", key: DAO_ORG },
      { name: "Grant", key: GRANT_MANAGER },
      { name: "Executor", key: VOTE_EXECUTION },
      { name: "Fee Vault", key: VENTURE_MANAGEFEE_MANAGER },
    ];
  }, []);

  return (
    <>
      {showModal && (
        <UpgradeContractsModal
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      <UlBox>
        {/* <UpgradeStyled>
          <UpgradeBg />
          <span onClick={()=>setShowModal(true)}>{t("Home.Upgrade")}</span>
        </UpgradeStyled> */}
        {contractsList.map((item, index) => {
          return componentAddressMap.get(item.key) ? (
            <li key={index}>
              <div style={{ width: "270px" }}>
                <TagBox>{index + 1}</TagBox>
                <NameBox>{item.name}</NameBox>
                {/* <VersionBox>v1</VersionBox> */}
              </div>
                <AddressBox>{componentAddressMap.get(item.key)}</AddressBox>
                <CopyStr
                  address={componentAddressMap.get(item.key)}
                  gray={true}
                />
            </li>
          ) : (
            <React.Fragment key={index} />
          );
        })}
      </UlBox>
    </>
  );
});

export default Contracts;

const UlBox = styled.ul`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  padding: 10px 0;
  li {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    &:nth-child(even) {
      background: rgba(255, 255, 255, 0.06);
    }
  }
`;

const TagBox = styled(IndexTag)`
  display: inline-block;
  margin-right: 28px;
`;
const NameBox = styled.span`
  font-size: 14px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
`;
const VersionBox = styled.span`
  min-width: 36px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-family: "Poppins-Medium";
  background: linear-gradient(127deg, rgba(50, 108, 255, 0.4) 0%, #2f467a 100%);
  border-radius: 10px 10px 10px 1px;
  margin-left: 16px;
  display: inline-block;
`;
const AddressBox = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  width: 600px;
  opacity: 0.6;
  width: 380px;
  margin-right: 20px;
`;

const UpgradeBg = styled.div`
  background: linear-gradient(123deg, #61e1ff 0%, #326cff 100%);
  border-radius: 8px;
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

const UpgradeStyled = styled.div`
  width: calc(100% - 80px);
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  margin: 5px 40px 15px;
  position: relative;
  color: #45beff;
  padding-left: 18px;
  span {
    position: relative;
    cursor: pointer;
  }
`;
