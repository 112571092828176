import TestnetBSC from "./testnet_bsc";
import TestnetMOON from "./testnet_moon";
import TestnetMumbai from "./testnet_mumbai";
import Rinkeby from "./rinkeby";
import Etherum from "./etherum";
import Polygon from "./polygon";
import Bsc from "./bsc";
import Moonbeam from "./moonbeam"
import Arbitrum from "./arbitrum";
import Zksync from "./zksync";
import ZksyncTest from "./testnet_zksync";

export const SupportedChainId = {
  BSC_TESTNET: 97,
  // MOON_BASE: 1287,
  // POLYGON_MUMBAI: 80001,
  MAINNET: 1,
  POLYGON: 137,
  BSC: 56,
  MOONBEAM: 1284,
  ARBITRUM_ONE: 42161,
  ZKSYNC: 324,
  ZKSYNC_TESTNET: 280,
};

export const NetworkList =
  process.env.NODE_ENV === "production"
    ? [Polygon, Etherum, Moonbeam, Bsc, Arbitrum, Zksync]
    : [Polygon, TestnetBSC, Arbitrum, ZksyncTest];

export default function getNetworkData(chainId) {
  return NetworkList.find(n => n.chainId === chainId);
}

export const defaultChainId = NetworkList[0].chainId;