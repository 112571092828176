import { useCallback, useEffect, useMemo, useState } from "react";
import General from "components/modal/general";
import styled from "styled-components";
import { ethers } from "ethers";
import { InputNumber, Radio } from "antd";

import DropdownImg from "img/swap/dropdown.png";
import NextImg from "assets/images/RightArrow.svg";
import GasImg from "img/gas.png";
import { useWeb3React } from "@web3-react/core";
import { useGrantManagerContract, useDaoOrgContract } from "hooks/useContract";
import { useDaoContext } from "views/dao/provider";
import { getShortDisplay } from "utils/publicJs";
import { useAppContext } from "components/provider/appProvider";
import { useTranslation } from "react-i18next";
import { DAO_ORG, GRANT_MANAGER, VAULT_MANAGER } from "utils/constant";
import useSigner from "hooks/useSigner";
import Done from "./Done";
import FailedModal from "./Failed";
import api from "api";
import { useDefiContext } from "components/provider/defiProvider";
import { getBalanceInUSD, calculateHealth, formatHealth } from "utils/aave";
import BigNumber from "bignumber.js";
import { valueToBigNumber } from "@aave/math-utils";
import { formatInput2bignumber } from "utils/utils";
import ApplyModal from "components/apps/swap/ApplyModal";
import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import Button from "components/common/button";
import Max from "components/common/max";
import { formatError } from "api/aave";

const STATUS = {
  Default: 0,
  Success: 1,
  Failed: 2,
};

export default function BorrowModal(props) {
  const { show, closeModal, reserve, stableEnbale } = props;
  const { t } = useTranslation();

  const { account } = useWeb3React();

  const {
    state: {
      userUsdData,
      usdPriceEth,
      userData: { currentLiquidationThreshold, healthFactor },
      userDebtReserves,
    },
  } = useDefiContext();

  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();
  const {
    state: { daoId, componentAddressMap },
  } = useDaoContext();
  const grantContract = useGrantManagerContract(
    componentAddressMap.get(GRANT_MANAGER)
  );
  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const signer = useSigner();

  const [balance, setBalance] = useState(0);
  const [balanceDisplay, setBalanceDisplay] = useState(0);
  const [amount, setAmount] = useState();

  const [status, setStatus] = useState(STATUS.Default);
  const [txHash, setTxHash] = useState();

  const [newHealth, setNewHealth] = useState(0);
  const [type, setType] = useState(2);
  const [errorMsg, setErrorMsg] = useState("");
  const [showApply, setShowApply] = useState(false);

  const heathDisplay = useMemo(() => {
    if (healthFactor) {
      return formatHealth(
        BigNumber(healthFactor.toString()).div(BigNumber(1e18))
      );
    } else {
      return 0;
    }
  }, [healthFactor]);
  
  const hasDebt = useMemo(() => {
    return !!userDebtReserves.length;
  }, [userDebtReserves]);

  const txLink = useMemo(() => {
    if (exploreScan && txHash) {
      return `${exploreScan}tx/${txHash}`;
    }
    return "";
  }, [txHash, exploreScan]);

  const getLimitInfo = useCallback(
    async (tokenAddress, userAccount) => {
      try {
        const result = await grantContract.getTokenLimit(
          tokenAddress,
          userAccount
        );
        const b = result[1].sub(result[0]);
        setBalance(b.toNumber());
        setBalanceDisplay(getShortDisplay(b.toString()));
      } catch (error) {
        console.error("getTokenLimit failed", error);
        return ethers.BigNumber.from(0);
      }
    },
    [grantContract]
  );

  useEffect(() => {
    if (!reserve) {
      return;
    }
    if (!amount || amount < 0) {
      setNewHealth(0);
      return;
    }
    const { totalCollateralUsdVal, totalDebtUsdVal } = userUsdData;

    if (!totalCollateralUsdVal || !totalDebtUsdVal) {
      return;
    }

    const totalCollateralUSD_BN = valueToBigNumber(totalCollateralUsdVal);
    const totalBorrowsUSD_BN = valueToBigNumber(totalDebtUsdVal);

    const currentLiquidationThreshold_BN = valueToBigNumber(
      currentLiquidationThreshold.toString()
    );

    const amountBN = BigNumber(amount).shiftedBy(reserve.decimals);
    const amountUSD_BN = getBalanceInUSD(
      usdPriceEth,
      reserve.price.priceInEth,
      amountBN
    );
   
    const remainDebt = totalBorrowsUSD_BN.plus(
      amountUSD_BN.shiftedBy(-reserve.decimals)
    );

    const v = calculateHealth(
      totalCollateralUSD_BN,
      remainDebt,
      currentLiquidationThreshold_BN
    );
    setNewHealth(formatHealth(BigNumber(v)));
  }, [amount, userUsdData, reserve, currentLiquidationThreshold, usdPriceEth]);

  const onClickBorrow = async () => {
    let unlimitPermission = false;
    const vaultAddress = componentAddressMap.get(VAULT_MANAGER);
    try {
      unlimitPermission = await grantContract.checkMethodPermission(
        account,
        vaultAddress,
        "GP_Loan_unlimited"
      );
    } catch (error) {
      console.error("checkMethodPermission GP_Buy_unlimited failed", error);
    }
    if (!unlimitPermission) {
      try {
        const data = await grantContract.checkMethodPermission(
          account,
          vaultAddress,
          "GP_Loan"
        );
        if (!data) {
          setShowApply(true);
          return;
        }
      } catch (error) {
        console.error("checkMethodPermission failed", error);
      }
      try {
        const data = await grantContract.getTokenLimit(
          1,
          reserve.underlyingAsset,
          account,
          ethers.constants.AddressZero
        );
        const delta = data[1].sub(data[0]);
        if (
          BigNumber(amount).gt(
            BigNumber(
              ethers.utils.formatUnits(delta, reserve.decimals).toString()
            )
          )
        ) {
          dispatch({
            type: "MSGTYPE",
            payload: { msg: t("defi.borrow.Insufficient") },
          });
          return;
        }
      } catch (error) {
        console.error("getTokenLimit failed", error);
      }
    }
    // dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });
    const params = { amount: amount, symbol: reserve.symbol };

    try {
      const amountBN = formatInput2bignumber(amount, reserve.decimals);
      const res = await api.aave.aaveBorrow(daoOrg, signer, {
        amount: amountBN,
        tokenAddress: reserve.underlyingAsset,
        mod: type,
      });
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.DefiBorrow"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.DefiBorrow,
          data: {
            daoId,
            ...params,
          },
        },
      });
      closeModal();
    } catch (error) {
      console.error("aaveSupply failed", error);
      const msg = error?.reason || error?.data?.message
      if (msg) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: formatError(msg) },
        });
      }
      // setErrorMsg(error?.error?.message);
      // showNotification(
      //   NotificationType.Fail,
      //   t("Notification.DefiBorrowFailed", params)
      // );
    }
  };

  const closeDone = () => {
    setStatus(STATUS.Default);
    closeModal();
  };

  const closeFailed = () => {
    setStatus(STATUS.Default);
    setTxHash();
    closeModal();
  };

  useEffect(() => {
    // if (grantContract && reserve.underlyingAsset) {
    //   getLimitInfo(reserve.underlyingAsset, account);
    // }
    if (reserve && reserve.canBorrow) {
      setBalance(Number(reserve.canBorrow));
      setBalanceDisplay(reserve.canBorrowDisplay);
    }
  }, [reserve]);
  return (
    <>
      <General
        width="440"
        height="490"
        closeModal={closeModal}
        show={show}
        title={`${t("defi.borrow.Borrow")} ${reserve.symbol}`}
        titleBold
      >
        <Label className="fist">{t("Amount")}</Label>
        <InputBoxBg>
          <div className="input">
            <InputStyled
              autoFocus
              value={amount}
              min={0}
              controls={false}
              onChange={(v) => setAmount(v)}
            />
            {/* <BalanceBg>1054.97</BalanceBg> */}
            {/* <PriceBox>
            $1.052.70 <span>(0.155%)</span>
          </PriceBox> */}
          </div>
          <div>
            <SelectBox>
              <span className="name">
                <img src={reserve.logo} alt="" />
                {reserve.symbol}
              </span>
            </SelectBox>
            <RhtBalance>
              <div className="balance">
                {t("defi.borrow.Balance")} : {balanceDisplay}
              </div>
              <Max t={t} onMax={() => setAmount(Number(balance))} />
            </RhtBalance>
          </div>
        </InputBoxBg>
        <TransactionBox>
          <Label className="fist">{t("defi.borrow.rate")}</Label>
          <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
            <Radio value={2}>
              {t("defi.borrow.Variable")} {reserve.variableBorrowAPYDisplay}%
            </Radio>
            {stableEnbale && (
              <Radio value={1}>
                {t("defi.borrow.Stable")} {reserve.stableBorrowAPYDisplay}%
              </Radio>
            )}
          </Radio.Group>
          <Label>{t("defi.supply.overview")}</Label>
          <BorderBox>
            <dl>
              <dt>{t("defi.borrow.HealthFactor")}</dt>
              <dd>
                <div className="green">
                  {hasDebt ? heathDisplay : "∞"}
                  {newHealth > 0 && (
                    <>
                      <img src={NextImg} className="arrow" alt="" />
                    </>
                  )}
                </div>
                <div className="tips">
                  {t("defi.borrow.Liquidation")} &lt; 1.0
                </div>
              </dd>
            </dl>
          </BorderBox>
        </TransactionBox>
        {/* <LastLine>
        <img src={GasImg} alt="" /> $1.77
      </LastLine> */}
        <BtnGroup>
          <Button
            disabled={!amount || amount > balance}
            onClick={onClickBorrow}
            primary
          >
            {t("defi.borrow.Borrow")} {reserve.symbol}
          </Button>
        </BtnGroup>
      </General>
      {status === STATUS.Success && (
        <Done
          amount={amount}
          title={t("defi.borrow.borrowed")}
          symbol={reserve.symbol}
          handleClose={closeDone}
          txLink={txLink}
        />
      )}
      {status === STATUS.Failed && (
        <FailedModal
          title={t("defi.borrow.failed")}
          content={t("defi.borrow.failedContent")}
          subContent={errorMsg}
          handleClose={closeFailed}
        />
      )}
      {showApply && (
        <ApplyModal show={showApply} closeModal={() => setShowApply(false)} />
      )}
    </>
  );
}

const Label = styled.div`
  line-height: 20px;
  margin-top: 28px;
  margin-bottom: 12px;
  opacity: 0.5;
  &.fist {
    margin-top: 20px;
  }
`;
const InputBoxBg = styled.div`
  width: 100%;
  height: 76px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  display: flex;
  justify-content: flex-between;
  align-items: center;
  padding: 20px 20px;
  .input {
    flex: 1;
  }
`;

const PriceBox = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #94949a;
  line-height: 14px;
  margin-top: 10px;
  span {
    color: #62ba46;
  }
`;
const BalanceBg = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: #0c1651;
  line-height: 25px;
`;

const SelectBox = styled.div`
  height: 34px;
  border-radius: 19px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .name {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-right: 8px;
    }
  }
`;

const BtnGroup = styled.div`
  width: calc(100% - 30px);
  position: absolute;
  bottom: 16px;
  button {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    margin-top: 20px;
  }
`;

const RhtBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  .balance {
    margin-right: 10px;
    opacity: 0.5;
  }
`;

const TransactionBox = styled.div`
  .title {
    padding: 30px 0 10px;
  }
  .ant-radio-wrapper {
    color: #fff !important;
  }
`;
const BorderBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 10px;
  padding: 15px 19px;
  dl {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
  }
  dd {
    font-size: 16px;
    text-align: right;
  }
  .green {
    font-size: 14px;
    font-weight: 500;
    color: #62ba46;
    line-height: 19px;
  }
  .tips {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-top: 10px;
    opacity: 0.5;
  }
  .arrow {
    position: relative;
    top: -3px;
  }
`;

const LastLine = styled.div`
  display: flex;
  margin-top: 20px;

  font-size: 12px;
  font-weight: 500;
  color: #94949a;
  line-height: 16px;
  img {
    width: 14px;
    height: 12px;
    margin: 0 10px 0 5px;
  }
`;


const InputStyled = styled(InputNumber)`
  background: transparent;
  border: none;
  &.ant-input-number-focused {
    box-shadow: none;
  }
  .ant-input-number-input {
    color: #fff;
    padding: 0;
    font-family: "Rubik-Medium";
  }
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
`;
