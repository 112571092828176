import {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";
import { Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CameraSvg from "../svg/create/camera";
import { useAppContext } from "components/provider/appProvider";
import { uploadByFetch } from "api/graphql/dao";
import useGateway from "hooks/useGateway";
import AddLogoSVG from "components/svg/investment/addLogo";
import { useWeb3React } from "@web3-react/core";

const ImageUploader = forwardRef((props, ref) => {
  const { disabled } = props;

  const { t } = useTranslation();
  const { dispatch } = useAppContext();

  const gateway = useGateway();
  const { chainId } = useWeb3React();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imgCode, setImgCode] = useState(null);

  useEffect(() => {
    if (!gateway) {
      return;
    }
    setImgCode(props.imgCode);
    if (props.imgCode) {
      setImageUrl(gateway + props.imgCode);
    }
  }, [props.imgCode, gateway]);

  const getFileData = async (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      setImageUrl(reader.result);
    });
    reader.readAsDataURL(file);
  };

  const checkImageSize = useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const image = new Image();
        image.onload = () => {
          resolve({ width: Number(image.width), height: Number(image.height) });
        };
        image.src = data;
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const checkFile = useCallback(async (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Upload.ErrorType"), closable: true },
      });
      return false;
    }
    const isLt100k = file.size / 1024 < 500;
    if (!isLt100k) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Upload.ErrorSize"), closable: true },
      });
      return false;
    }
    // const { width, height } = await checkImageSize(file);
    // if (width > 200 || height > 200) {
    //   return false;
    // }
    return true;
  }, []);

  const beforeUpload = (file) => {
    goUpload(file);
    return false;
  };

  const goUpload = async (file) => {
    if (!(await checkFile(file))) return;
    dispatch({ type: "LOADINGTYPE", payload: t("Create.UploadLogo") });
    setLoading(true);
    try {
      const resp = await uploadByFetch(file, chainId);
      if (resp.status === 200) {
        const data = await resp.json();
        props.updateUrl(data.data.fileUpload);
        getFileData(file);
      } else {
        throw new Error(`status is ${resp.status}`);
      }
    } catch (error) {
      console.error("Upload logo failed: ", error);
      dispatch({
        type: "MSGTYPE",
        payload: { msg: `${t("Create.UploadLogoFailed")}`, closable: true },
      });
    } finally {
      setLoading(false);
      dispatch({ type: "LOADINGTYPE", payload: null });
    }
  };

  useImperativeHandle(ref, () => ({
    upload: goUpload,
    imgCode: imgCode,
    clear: () => {
      setImageUrl(null);
      setImgCode(null);
    },
  }));

  if (disabled) {
    return <ImgStyled alt="" src={imageUrl}></ImgStyled>;
  }

  return (
    <LOGO
      name="logo"
      listType="picture-card"
      className="logo-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      // onChange={handleChange}
    >
      <div className="mask">
        {loading && <LoadingOutlined className="loading" />}
        {!loading && !imageUrl && (
          <>
            <AddLogoSVG className="occupy-img" />
          </>
        )}
      </div>
      {imageUrl && (
        <img className="image-item" src={imageUrl} alt="" />
      )}
    </LOGO>
  );
});

export default ImageUploader;

const LOGO = styled(Upload)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  text-align: center;
  .ant-upload.ant-upload-select-picture-card {
    width: 36px;
    height: 36px;
    background: #f8f8f9;
    border: none;
    border-radius: 36px;
  }
  .ant-upload.ant-upload-select{
    background: transparent!important;
    width: 36px!important;
    height: 36px!important;
    border: none!important;
    border-radius: 36px!important;
  }
  .mask {
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;

  }
  .image-item {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -9px;
  }
`;

const ImgStyled = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
