import React, { useState, useEffect } from "react";
import General from "components/modal/general";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { getClaimRecords, SubgraphApiQuery } from "api/graphql/subQuery";
import { useClientContext } from "components/provider/clientProvider";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import VerticalScroller from "components/common/verticalScroller";
import { formatUTCTime } from "utils/utils";

const ClaimRecordModal = ({ token, closeModal }) => {
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const { account, chainId } = useWeb3React();

  const { authClient } = useClientContext();

  const formatTime = (time) => {
    return formatUTCTime( moment(time));
  };

  const getRecords = async () => {
    try {
      const data = await authClient.request(SubgraphApiQuery, {
        chainId,
        query: getClaimRecords(token?.dao_id, account),
      });

      const res = JSON.parse(data.subgraphApi);
      console.log("====data", res);

      setRecords(res.data.equityTokenFreeRecords.map((t) => ({
        amount: ethers.utils.formatUnits(t.amount, token.deci),
        time: formatTime(parseInt(t.blockTimestamp) * 1000),
      })));

    } catch (error) {
      console.error("getRecords", error);
    }
  };

  useEffect(() => {
    if (!token || !authClient) {
      return;
    }
    getRecords();
  }, [token, authClient]);

  return (
    <General
      width="462"
      height="390"
      closeModal={closeModal}
      show={!!token}
      glass
      title={t("Explore.ClaimeRecord")}
    >
      <ModalBody>
        <LineRow className="header">
          <span>{t("Explore.ClaimAmount")}</span>
          <span>{t("Explore.ClaimTime")}</span>
        </LineRow>
        {records.map((r, i) => (
          <LineRow key={i}>
            <span>{r.amount}</span>
            <span>{r.time}</span>
          </LineRow>
        ))}
      </ModalBody>
    </General>
  );
};

export default ClaimRecordModal;

const ModalBody = styled(VerticalScroller)`
  padding: 0 21px;
  height: 255px;
  overflow-y: auto;
  margin-top: 35px;
`;

const LineRow = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 22px;
  margin-bottom: 20px;
  &.header {
    line-height: 24px;
    margin-bottom: 15px;
    opacity: 0.5;
  }
`;