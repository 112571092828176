import styled from "styled-components";
import React, {
  memo,
  useState,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { useDaoContext } from "views/dao/provider";
import { useOrgContract } from "hooks/useContract";
import useVaultTokens from "hooks/useVaultTokens";
import api from "api";
import { ethers } from "ethers";

import UniswapLogo from "assets/images/vote/Uniswap.svg";
import AaveLogo from "assets/images/vote/aave.svg";
import RequiredStar from "assets/images/RequiredStar.svg";

import { Checkbox } from "antd";
import { useAppContext } from "components/provider/appProvider";
import { VAULT_MANAGER, ORG_MANAGER } from "utils/constant";
import TokenSelect from "components/common/tokenBalanceSelect";
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "network";
import { useNetworkData } from "hooks/useNetwork";

import { VoteItem, Label } from "./components/style";
import ManagersSelect from "./components/managersSelect";
import DateBox from "./components/date";
import AmountBox from "./components/amountBox";
import Max from "components/common/max";
import RadioOnIcon from "assets/images/checkbox/checkbox_on.svg";
import RadioOffIcon from "assets/images/checkbox/checkbox_off.svg";

const PRODUCT = {
  SWAP: "swap",
  AAVE: "aave",
};

export default forwardRef(function SuperManager(props, ref) {
  const { t } = useTranslation();

  const { chainId } = useWeb3React();
  const { state: daoState } = useDaoContext();
  const { componentAddressMap, daoChainId } = daoState;
  const orgContract = useOrgContract(
    componentAddressMap.get(ORG_MANAGER),
    daoChainId
  );

  const { dispatch } = useAppContext();

  const { tokens, loading } = useVaultTokens(
    componentAddressMap.get(VAULT_MANAGER)
  );

  const networkData = useNetworkData(chainId);

  const [selectToken, setSelectToken] = useState();
  const [time, setTime] = useState();

  const [selectedProduct, setSelectedProduct] = useState(PRODUCT.SWAP);
  const [gplist, setGplist] = useState([]);
  const [selectGP, setSelectGP] = useState();
  const [limitAmount, setLimitAmount] = useState("");

  const list = useMemo(() => {
    setSelectToken(tokens ? tokens[0] : undefined);
    return tokens ? tokens.map((t) => ({ ...t })) : [];
  }, [tokens]);

  const getGpList = useCallback(async () => {
    const { lst } = await api.org.getDaoMembersList(orgContract, 0, 1000);
    setSelectGP(lst[0]);
    setGplist(lst);
  }, [orgContract]);

  useEffect(() => {
    if (orgContract) {
      getGpList();
    }
  }, [orgContract]);

  const checkValid = () => {
    if (selectToken && selectToken.all) {
      return true;
    }
    if (!limitAmount || limitAmount <= 0) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidSpendingLimit"), closable: true },
      });
      return;
    }
    if (
      selectedProduct === PRODUCT.SWAP &&
      limitAmount > Number(selectToken.balance)
    ) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidSpendingLimit"), closable: true },
      });
      return;
    }
    return true;
  };

  const formatApproveTime = useCallback((t) => {
    let approveTime = Math.ceil(t / 1000);
    const now = Math.ceil(Date.now() / 1000);
    if (approveTime - now < 60) {
      approveTime += 60;
    }
    return approveTime;
  }, []);

  const setMax = () => {
    setLimitAmount(Number(selectToken?.balance || 0));
  };
  useEffect(() => {
    setLimitAmount("");
  }, [selectToken]);

  useImperativeHandle(ref, () => ({
    name: "super",
    checkValid,
    getVoteBytes() {
      let method;
      if (selectedProduct === PRODUCT.SWAP) {
        method = selectToken.all ? "GP_Buy_unlimited" : "GP_Buy";
      } else {
        method = selectToken.all ? "GP_Loan_unlimited" : "GP_Loan";
      }
      return api.vote.newApproveGPVote(
        {
          gpAddress: selectGP,
          approvTime: formatApproveTime(time),
          callee: componentAddressMap.get(VAULT_MANAGER),
          limit: selectToken.all
            ? {}
            : {
                address: selectToken.address,
                amount: ethers.utils.parseUnits(
                  String(limitAmount),
                  selectToken.deci
                ),
              },
          method,
        },
        !!selectToken.all
      );
    },
  }));

  return (
    <SuperBox>
      <VoteItem>
        <SuperTip>{t("Vote.SuperTip")}</SuperTip>
      </VoteItem>
      <VoteItem>
        <Label>{t("Vote.Recipient")}</Label>
        <ManagersSelect
          list={gplist}
          onSelect={setSelectGP}
          selected={selectGP}
        />
      </VoteItem>
      <VoteItem>
        <Label>{t("Vote.AvailableDApp")}</Label>
        <TypeChecked
          selected={selectedProduct}
          onChange={setSelectedProduct}
          swapApp={networkData?.swapApp}
          hideAAVE={[
            SupportedChainId.BSC,
            SupportedChainId.MOONBEAM,
            SupportedChainId.ARBITRUM_ONE,
            SupportedChainId.ZKSYNC,
            SupportedChainId.ZKSYNC_TESTNET,
          ].includes(chainId)}
        />
      </VoteItem>
      <VoteItem>
        <Label>{t("Vote.SpendLimit")}</Label>
        <SpendingBox>
          <TokenSelect
            list={[
              {
                symbol: t("Vote.AllTokens"),
                balanceDisplay: t("Vote.Unlimited"),
                all: true,
                address: "0x",
              },
            ].concat(list)}
            selected={selectToken}
            onSelect={setSelectToken}
          />
          {selectToken && !selectToken.all ? (
            <AmountBox
              value={limitAmount}
              onChange={(e) => setLimitAmount(e.target.value)}
            >
              <SymbolBox>
                <span className="symbol">
                  {selectToken && selectToken.symbol}
                </span>
                <Max t={t} onMax={setMax} />
              </SymbolBox>
            </AmountBox>
          ) : (
            <div />
          )}
        </SpendingBox>
      </VoteItem>
      <DateBox
        label={t("Vote.approveTime")}
        targetTime={time}
        updateTime={setTime}
      />
    </SuperBox>
  );
});

const TypeChecked = memo((props) => {
  const { t } = useTranslation();

  const { selected, onChange, hideAAVE, isPancake, swapApp } = props;

  return (
    <>
      <UlBox>
        <DappChoice onClick={() => onChange(PRODUCT.SWAP)}>
          <img
            className="radio"
            src={selected === PRODUCT.SWAP ? RadioOnIcon : RadioOffIcon}
            alt=""
          />
          {swapApp ? (
            <span style={{ fontSize: "16px" }}>{swapApp}</span>
          ) : (
            <img
              className="uni"
              style={{ width: "109px" }}
              src={UniswapLogo}
              alt=""
            />
          )}
        </DappChoice>
        {!hideAAVE && (
          <DappChoice onClick={() => onChange(PRODUCT.AAVE)}>
            <img
              className="radio"
              src={selected === PRODUCT.AAVE ? RadioOnIcon : RadioOffIcon}
              alt=""
            />
            <img
              className="aave"
              style={{ width: "58px" }}
              src={AaveLogo}
              alt=""
            />
          </DappChoice>
        )}
      </UlBox>
      <UniswapTip>
        <img src={RequiredStar} alt="" />
        <span>{t("Vote.ApproveAllPairs")}</span>
      </UniswapTip>
    </>
  );
});

const SuperBox = styled.div`
  dt {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 19px;
    margin-bottom: 10px;
  }
  dd {
    position: relative;
  }
  dl {
    margin-bottom: 20px;
  }
`;

const SymbolBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  .symbol {
    opacity: 0.5;
    margin-right: 8px;
  }
`;
const SpendingBox = styled.div`
  display: flex;
  & > div {
    flex: 1;
    &:first-child {
      margin-right: 20px;
    }
  }
`;

const UlBox = styled.ul`
  display: flex;
  align-items: center;
  li {
    display: flex;
    align-items: center;
    &:first-child {
      margin-right: 25px;
    }
    .uni {
      position: relative;
      top: -4px;
    }
  }
`;

const UniswapTip = styled.div`
  font-size: 12px;
  color: #ffa4d1;
  margin-bottom: 15px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const SuperTip = styled.div`
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);

  padding: 16px 12px;
  margin-bottom: 20px;
  line-height: 18px;
  margin-top: 12px;

  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
`;

const DappItem = styled(Checkbox)`
  color: #fff;
  .aave {
    position: relative;
    top: 3px;
  }
`;

const DappChoice = styled.li`
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  .radio {
    margin-right: 6px;
  }
`;
