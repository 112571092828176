import styled from "styled-components";

const Loader = styled.div`

    font-size: 10px;
    text-indent: -9999em;
    //width: 16px;
    width: ${props => props.width ? props.width + 'px' : '16px'};
    height: ${props => props.height ?props.height + 'px' : '16px'};
    //height: 16px;
    border-radius: 50%;
    //background: #000;
    background:${props => props.foreColor ? props.foreColor : '#000'};
    background: linear-gradient(to right, ${props => props.foreColor ? props.foreColor : '#000'} 30%, rgba(0, 0, 0, 0) 62%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

  &:before {
    width: 60%;
    height: 60%;
    background:${props => props.foreColor ? props.foreColor : '#000'};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &:after {
    background: ${props => props.backColor ? props.backColor : '#fff'};
    width: 60%;
    height: 60%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export default function SwapLoading(props){
    const {width,height,backColor,foreColor, ...rest} = props;
    return <Loader width={width} height={height} backColor={backColor} foreColor={foreColor} {...rest}>Loading...</Loader>
}