import styled from "styled-components";
import LftImg from "assets/images/left.png";
import RhtImg from "assets/images/right.png";

export default function Dapps({ network }) {
  const list = network?.walletConnectDapps || [];
  return (
    <Tab>
      {list.map((dapp, i) => (
        <li key={i}>
          <LftBg />
          <RhtBg />
          <a href={dapp.link} target="_blank" rel="noopener noreferrer">
            <img src={dapp.logo} alt="" />
            <div className="name">{dapp.name}</div>
          </a>
        </li>
      ))}
    </Tab>
  );
}

const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`;
const RhtBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;
`;

const Tab = styled.ul`
  display: flex;
  flex-wrap: wrap;
  li {
    width: 220px;
    height: 128px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    backdrop-filter: blur(20px);
    box-sizing: border-box;
    position: relative;
    margin-right: 17px;
    margin-bottom: 20px;
    overflow: hidden;
    &:nth-child(5n) {
      margin-right: 0;
    }
    &:hover {
      background: linear-gradient(
        136deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.13) 100%
      );
    }
  }
  a {
    position: absolute;
    left: 24px;
    top: 24px;
    bottom: 24px;
    right: 24px;
  }
  img {
    width: 36px;
  }
  .name {
    font-size: 20px;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    line-height: 28px;
    margin-top: 14px;
  }
`;
