import { ethers } from "ethers";
import abi from "../abi/web3Abi/MockERC20.json";
import VenturesStockABI from "../abi/web3Abi/VenturesStockManager.json";
import VenturesNFTStockABI from "../abi/web3Abi/VenturesNFTStockManager.json";

function getContract(web3Instance, erc20Address) {
  return new ethers.Contract(erc20Address, abi, web3Instance);
}

function getStockContract(web3Instance, erc20Address) {
  return new ethers.Contract(
    erc20Address,
    VenturesStockABI,
    web3Instance
  );
}

function getNFTStockContract(web3Instance, address) {
  return new ethers.Contract(address, VenturesNFTStockABI, web3Instance);
}

export default {
  getContract,
  getStockContract,
  getNFTStockContract,
};
