import mainAddress from "./address";
import BscScan from "img/bscScan.jpeg";
import polygonImg from "img/newHome/polygon.png";

export default {
  name: "Mumbai",
  simple: "mumbai",
  mainAddress: mainAddress,
  serverApi: "https://test-backend.subdao.org/chapel-v2/query",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  opensea: "https://opensea.io/assets/matic/",
  // aave
  aaveGraph: "https://api.thegraph.com/subgraphs/name/aave/aave-v2-polygon-mumbai",
  aaveLendingPool: "0x9198F13B08E299d85E096929fA9781A1E3d5d827",
  aaveLendingPoolProvider: "0x178113104fecbcd7ff8669a0150721e231f0fd4b",
  tokenLogo: "",
  scan: {
    img: BscScan,
    host: "https://mumbai.polygonscan.com/",
  },
  chainId: 80001, // '0x13881'
  chainName: "Mumbai",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  logo: polygonImg,
  rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
  blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
};
