export default {
  DaoFactory: "0x9452820B0e15838643f241fbb95E1B0D023F2567",
  actionGrantMethodApply: "0xd3B3999321de78058E9C3fA0362e9fDfc84105D4",
  actionGrantMethodRegister: "0x48fa33BeB60c8B4aa4E5ECb1366a76aAb3b31880",
  actionGrantMethodVoteNewAuth: "0xB31ca1Ff22B1F167f8766A529307BD22B722AF44",
  actionOrgApplyJoin: "0xF5968FAB3C05e75bF8fF37821C31Dcf558D12f2d",
  actionOrgMemberJoin: "0x2C7c078D48a19A1164bFAF3113aA87770d95B243",
  actionOrgMemberQuit: "0x3E9090131D812Ef9A578B181d274ab378EA40923",
  actionOrgMgr: "0x4B28e3A44828a095fa6769b12085D969056245D9",
  actionOrgSetJoinMode: "0xedDAb841778EB71B305dd5dA3A7F62bAe98823fa",
  actionOrgTransferOwner: "0x5e074FE83D93f21b0AdeE71e22f8B4342F33A410",
  actionOrgUpdateMembers: "0x619fFff539E185dFE814382A7C59f2DD3ed76950",
  actionVaultAddNFT: "0xfB2951344484AE3D9d37cF75200Ee6791304cb52",
  actionVaultDepositToken: "0x8030692F67D3558f6870BC30ceddA42c7CD109b9",
  actionVaultExeWithdrawToken: "0xa7Cc1ad8BDBf5DE9d6c77FaE54b8Bc314cEe26Cf",
  actionVaultGPAuthorize: "0xCD64D697dbD24feF59c12eb223233309C82857eb",
  actionVaultOptToken: "0x60F5a071aB0166Db719616622F942Cb0bD10d9f1",
  actionVaultWithdrawToken: "0xD4af1104B0584b3EF1B4Dcd33Ad392c09D78C943",
  actionVenturesClaimBonus: "0x8E49222e8c0EFC8551426b90F4B5c1AdDaFF1422",
  actionVenturesClaimShare: "0xD8B05476D72581f86560BB5Ca7517ff3198Ae687",
  actionVenturesRaiseFailClaim: "0xEe76e34d8b4f93d480D7c8b49c74526ee825289C",
  actionVenturesRaiseMoney: "0x3c2fe3428E5Fe6a0588427fC67Fa11c7F8d46833",
  actionVenturesSetRaiseSubTime: "0xbA2f4ABd73DF0dF25e537BC3065239803A27E13d",
  actionVenturesToFreezingPeriod: "0x48782F4D30B2adA4670d822a12a43378C3E9210C",
  actionVenturesToRaiseFail: "0x9927280297b06B334471B8f2123D6d544834f3c5",
  actionVenturesVestingWithdraw: "0xCaCE272f608e10ce383C7AeB00E8E2f4D6FEaE43",
  actionGrantMethodExe: "0x80c335248185E3d205CC8b6cF074D3862e320089",
  actionManageFeeTransferExe: "0x43Cc3A24A860bAda436D1B8A96481D942b4B42CF",
  actionOrgAcceptGPExe: "0x505AB1f934c1AEd2D0Ce6E1C170C1bA5d7D6d15e",
  actionOrgImpeachGPExe: "0x92D2BbC43D1f632594557fB57E141a56d7820dd6",
  actionVaultTransferExe: "0x81D7ED87F8cBaD781A3E7CF63E9BAbb49a69E10B",
  actionVaultTransferNFTExe: "0xA05db94D27AfF41a129e87C71a213D2D9F446A8A",
  actionVentureTransferPeriodExe: "0x3752F01B84E0638465f8925f504208D421b89238",
  actionOrgUpdateMinRequireExe: "0x51195cbdD3eadbCCF493A2aE23B0DFdAD94B8800",
  actionVaultUniswapV3Router: "0x50323A462079d55C532940951a8Ae5729aD9B8d5",
};