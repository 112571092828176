import Frame from "views/dao/frame";
import DaoProvider from "./provider";
import useMatchRoute, { DaoRoutes } from "hooks/useMatchRoute";
import WCTProvider from "components/provider/WCTProvider";

export default function Demo() {
  const subRouteId = useMatchRoute(DaoRoutes);

  return (
    <DaoProvider>
      <WCTProvider>
          <Frame subRoute={subRouteId} />
      </WCTProvider>
    </DaoProvider>
  );
}
