export default {
  main: "#041735",
  text: {
    primary: "#FFFFFF",
    normal: "#041735",
    secondary: "rgba(255, 255, 0.5)",
    less: "rgba(255, 255, 0.32)",
    highlight: "#328AFF",
  },
};
