import styled from "styled-components";
import RuleImg from "assets/images/create/rule.svg";
import CreateImg from "assets/images/create/create.svg";
import ThreeImg from "assets/images/create/book.svg";
import SelectedImg from "../../img/animation/selected.svg";
import AlertImg from "../../img/animation/alert.svg";
import {useState} from "react";
import { Collapse } from 'antd';
import { useTranslation } from "react-i18next";
import Button from "components/common/button";
import VerticalScroller from "components/common/verticalScroller";

const { Panel } = Collapse;

const Box = styled.div`
  width: 100%;
  height: 764px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px 30px 20px;
  box-sizing: border-box;
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-header-text {
    font-family: "Poppins-SemiBold";
    font-size: 28px;
  }

  .li1,
  .li2,
  .li3 {
    font-size: 28px;
    font-weight: normal;
    line-height: 33px;
    .ant-collapse-header {
      padding-left: 33px !important;
      color: #fff;
      padding-right: 0;
    }
  }
  .li1 {
    .ant-collapse-header {
      background: url(${CreateImg}) no-repeat left center;
      background-size: 24px;
    }
  }
  .li2 {
    .ant-collapse-header {
      background: url(${ThreeImg}) no-repeat left center;
      background-size: 24px;
    }
  }
  .li3 {
    .ant-collapse-header {
      background: url(${RuleImg}) no-repeat left center;
      background-size: 24px;
    }
  }
  @media (max-width: 1440px) {
    height: 600px;
  }
`;

const ContentBox = styled.div`
  flex-grow: 1;
  .container {
    height: 420px;
  }
  @media (max-width: 1440px) {
    .container {
      height: 250px;
    }
  }
`;
const BtnBox = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
`
const ListBox = styled.div`
  dl {
    margin-bottom: 36px;
  }

  dd {
    margin: 0 0 0 34px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 19px;
  }
  .details {
    color: #326cff;
  }
`;

const UlBox = styled.ul`
  margin-top: 15px;
  &.top {
    margin-top: 0;
  }
  li {
    display: flex;
    justify-content: flex-start;
    font-family: "Poppins-Regular";
    margin-bottom: 13px;
    span {
      padding-left: 11px;

      line-height: 24px;
    }
    .circle {
      margin-top: 7px;
    }
  }
`;

const SecondUl = styled.ul`
  li {
    display: flex;
    align-items: flex-start;
    margin-top: 6px;
    font-family: "Poppins-Regular";
    img {
      width: 18px;
      margin: 6px 9px 0 0;
    }
    &:last-child {
      margin-top: 30px;
    }
    .circle {
      margin-top: 12px;
    }
  }
  .titleRht {
    font-size: 16px;
    font-family: "Poppins-Medium";
    font-weight: normal;
    line-height: 32px;
  }
  .con {
    line-height: 26px;
  }
  .yel {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    background: linear-gradient(180deg, #ffcd0f 0%, #ec9c00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .alert {
    width: 18px;
    margin-top: -2px;
  }
`;

const Circle = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  border: 2px solid #326cff;
  display: block;
  padding-left: 0 !important;
  margin-right: 12px;
  box-sizing: border-box;
`;

export default function CreateStep(props){
    const { t } = useTranslation();
    const { handleNext } = props;

    return (
      <Box>
        <ContentBox>
          <ListBox>
            <Collapse accordion defaultActiveKey={["1"]} ghost>
              <Panel
                header={t("Create.Modal.step1")}
                key="1"
                showArrow={false}
                className="li1"
              >
                <dd>
                  <VerticalScroller height="250px">
                    <div>{t("Create.Modal.tip1")}</div>
                    <UlBox>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <span>{t("Create.Modal.step1li1")}</span>
                      </li>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <span>{t("Create.Modal.step1li2")}</span>
                      </li>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <span>{t("Create.Modal.step1li3")}</span>
                      </li>
                    </UlBox>
                  </VerticalScroller>
                </dd>
              </Panel>
              <Panel
                header={t("Create.Modal.step2")}
                key="2"
                showArrow={false}
                className="li2"
              >
                <dd>
                  <VerticalScroller className="container">
                    <div>{t("Create.Modal.tip2")}</div>
                    <SecondUl>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <div>
                          <div className="titleRht">
                            {t("Create.Modal.step2li1Tit")}
                          </div>
                          <div className="con">
                            {t("Create.Modal.step2li1Desc")}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <div>
                          <div className="titleRht">
                            {t("Create.Modal.step2li2Tit")}
                          </div>
                          <div className="con">
                            {t("Create.Modal.step2li2Desc")}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <div>
                          <div className="titleRht">
                            {t("Create.Modal.step2li3Tit")}
                          </div>
                          <div className="con">
                            {t("Create.Modal.step2li3Desc")}
                          </div>
                        </div>
                      </li>
                      <li>
                        <img src={AlertImg} alt="" className="alert" />
                        <div className="yel">{t("Create.Modal.alert")}</div>
                      </li>
                    </SecondUl>
                  </VerticalScroller>
                </dd>
              </Panel>
              <Panel
                header={t("Create.Modal.step3")}
                key="3"
                showArrow={false}
                className="li3"
              >
                <dd>
                  <VerticalScroller height="200px">
                    <UlBox className="top">
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <span>{t("Create.Modal.step3li1")}</span>
                      </li>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <span>{t("Create.Modal.step3li2")}</span>
                      </li>
                      <li>
                        <div>
                          <Circle className="circle" />
                        </div>
                        <span>{t("Create.Modal.step3li3")}</span>
                      </li>
                    </UlBox>
                    <div className="details">
                      <a
                        href="https://wiki.subdao.network/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        &lt;{t("Create.Modal.Details")}&gt;
                      </a>{" "}
                    </div>
                  </VerticalScroller>
                </dd>
              </Panel>
            </Collapse>
          </ListBox>
        </ContentBox>
        <BtnBox>
          <Button primary onClick={() => handleNext(1)}>
            {t("Confirm")}
          </Button>
        </BtnBox>
      </Box>
    );
}