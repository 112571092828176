import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{"Group"}</title>
    <g fill="#F50000" fillRule="nonzero">
      <path d="M7 12.89A5.891 5.891 0 1 0 7 1.108 5.891 5.891 0 0 0 7 12.89ZM7 14A7 7 0 1 1 7-.002 7 7 0 0 1 7 14Z" />
      <path d="m7.912 6.986 1.895-1.87a.649.649 0 0 0 .005-.918.652.652 0 0 0-.921-.005L6.993 6.065 5.138 4.196a.65.65 0 1 0-.922.918l1.852 1.865-1.875 1.85a.648.648 0 0 0 .457 1.11.654.654 0 0 0 .459-.187l1.878-1.853 1.9 1.915a.652.652 0 0 0 .923-.918l-1.898-1.91Z" />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
