import React, { useReducer, createContext, useContext } from "react";
import { ethers } from "ethers";
import { LOCAL_KEY as BasicKey } from "./basicInfo";
import { LOCAL_KEY as AddManagerKey } from "./addManager";
import { FundraiseType, LOCAL_KEY as FundraisingKey } from "./fundraise";
import { ReleaseType } from "./fundraise/token";

import {
  checkBonus,
  checkInvalidAddress,
  checkInvalidBonus,
  checkRepeatAddress,
  checkInvalidStr,
} from "utils/check";

import { DaoType } from "./chooseType";

const checkRequire = (data_array) => {
  console.log("data_array:", data_array);
  return data_array.some((data) => !data);
};

const checkBasic = () => {
  try {
    const data = JSON.parse(sessionStorage.getItem(BasicKey) || "");
    if (data.name && data.description && data.daohandle) {
      // check handle
      if (checkInvalidStr(data.daohandle)) {
        return "Create.HandleCheck";
      }
      if (data.invalidHandle) {
        return "Msg.HandleError";
      }
      return "ok"
    } else {
      return "Msg.FillTip";
    }
  } catch (error) {
    return "Msg.FillTip";
  }
};

const checkAddManager = (isFund) => {
  try {
    const data = JSON.parse(sessionStorage.getItem(AddManagerKey) || "");
    if (!data.addressList || !data.minSupportNumber) {
      return "Msg.FillTip";
    }
    if (
      data.minSupportNumber <= 0 ||
      data.minSupportNumber > data.addressList.length
    ) {
      return "Msg.VoteConfigError"
    }

    if (isFund) {
      if (
        data.GpCarry === undefined ||
        data.GpCarry === null ||
        data.manageFee === undefined ||
        data.manageFee === null
      ) {
        return "Msg.FillTip";
      }
      if (checkInvalidBonus(data.addressList)) {
        return "Msg.InvalidBonus";
      }
      if (!checkBonus(data.addressList)) {
        return "Msg.BonusMatch";
      }
    }

    const invalidAddress = checkInvalidAddress(data.addressList);
    if (invalidAddress) {
      return "Msg.FillTip";
    }
    if (checkRepeatAddress(data.addressList)) {
      return "Msg.RepeatedAddress";
    }
    return "ok";
  } catch (error) {
    return "Msg.FillTip";
  }
};

const checkFundraising = () => {
  try {
    const jdata = JSON.parse(sessionStorage.getItem(FundraisingKey) || "");
    const fundType = jdata.fundraiseType || FundraiseType.Token;
    if (fundType === FundraiseType.NFT) {
      if (!jdata.nftData) {
        return "Msg.FillTip";
      }
      const data = jdata.nftData;
      if (
        checkRequire([
          data.selectToken,
          data.img,
          data.name,
          data.target,
        ])
      ) {
        return "Msg.FillTip";
      }
      if (!data.selectToken.address) {
        return "Msg.FillTip";
      }
      
      if (data.upper && (data.upper < data.target)) {
        return "Msg.CapInvalid";
      }

      if (data.priceSelect === 1) {
        if (
          checkRequire([
            data.originalPrice,
            data.discountRate,
            data.discountTokenRate,
            data.discountEndTime,
          ])
        ) {
          return "Msg.FillTip";
        }
        // todo how to check discountEndTime ?
      } else {
        if (checkRequire([data.price])) {
          return "Msg.FillTip";
        }
      }

    } else {
      if (!jdata.tokenData) {
        return "Msg.FillTip";
      }
      const data = jdata.tokenData;
      if (
        checkRequire([
          data.selectToken,
          data.equityToken,
          data.softCap,
          data.releaseData,
        ])
      ) {
        return "Msg.FillTip";
      }

      if (
        !data.selectToken.address ||
        !data.equityToken.symbol ||
        !data.equityToken.name
      ) {
        return "Msg.FillTip";
      }
      
      if (data.hardCap && data.softCap >= data.hardCap) {
        return "Msg.CapInvalid";
      }

      if (data.priceSelect === 1) {
        if (
          checkRequire([
            data.originalPrice,
            data.discountRate,
            data.discountTokenRate,
            data.discountEndTime,
          ])
        ) {
          return "Msg.FillTip";
        }
        // todo how to check discountEndTime ?
      } else {
        if (checkRequire([data.price])) {
          return "Msg.FillTip";
        }
      }
      
      const vestdata = data.releaseData;

      if (data.releaseType === ReleaseType.Liner) {
        if (checkRequire([vestdata.days, vestdata.startDate])) {
          return "Msg.FillTip";
        }
        if (vestdata.startDate <= jdata.raiseTime) {
          return "Msg.VestStartTime";
        }
      } else if (data.releaseType === ReleaseType.Stage) {
        if (!vestdata.length) {
          return "Msg.FillTip";
        }
        const lst = vestdata.map((v) => ({ ...v, bonus: v.ratio }));
        if (!checkBonus(lst)) {
          return "Msg.StageVestRatio";
        }
        if (checkInvalidBonus(lst)) {
          return "Msg.FillTip";
        }
        let prevDate;
        for (const v of vestdata) {
          if (!v.date) {
            return "Msg.FillTip";
          }
          if (!prevDate) {
            if (v.date < jdata.raiseTime) {
              return "Msg.VestStartTime";
            }
          } else if (v.date < prevDate) {
            return "Msg.VestStageTime";
          }
          prevDate = v.date;
        }
      } else {
        if (!vestdata) {
          return "Msg.FillTip";
        }
        if (vestdata <= jdata.raiseTime) {
          return "Msg.VestStartTime";
        }
      }
    }
    if (!jdata.raiseTime || Date.now() > jdata.raiseTime) {
      return "Msg.CloseDate";
    }
    if (jdata.addressInput) {
      for (const a of jdata.addressInput.split("\n")) {
        if (!a.trim()) {
          continue
        }
        if (!ethers.utils.isAddress(a.trim())) {
          return "Msg.InvalidWhitelist";
        }
      }
    }
    return "ok";
  } catch (error) {
    return "Msg.FillTip";
  }
};

const CreateContext = createContext();
const reducer = (state, action) => {
  switch (action.type) {
    case "ChooseType":
      return { ...state, chooseType: action.payload };
    case "BasicInfo":
      return { ...state, basic: action.payload };
    case "CheckNav":
      return {
        ...state,
        navStatus: {
          basic_info: checkBasic(),
          add_manager: checkAddManager(state.chooseType === DaoType.venture),
          fundraising: checkFundraising(),
        },
      };
    case "SET_DAO_IMAGE":
      return { ...state, daoImg: action.payload };
    case "SET_NFT_IMAGE":
      return { ...state, nftImg: action.payload };
    default:
      return { ...state };
  }
};

const INIT_STATE = {};

const CreateProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  return (
    <CreateContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CreateContext.Provider>
  );
};

export const useCreateContext = () => ({ ...useContext(CreateContext) });

export default CreateProvider;
