import { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import OpenArrow from "assets/images/Open.svg";

export default function TokenSelect({ list, onSelect, selectIdx }) {
  const [show, setShow] = useState(false);

  const clickCallback = (e) => {
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", clickCallback);
    return () => {
      document.removeEventListener("click", clickCallback);
    };
  }, []);

  const showSelect = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    setShow(!show);
  };

  const select = useMemo(() => {
    if (!list || !list.length || selectIdx === undefined) {
      return;
    }
    return list[selectIdx];
  }, [list, selectIdx]);

  const choose = (i, e) => {
    e.stopPropagation();
    setShow(false);
    onSelect(i);
  };

  return (
    <SelectBox onClick={showSelect}>
      {select && (
        <DisplaySelect fullExpand={show}>
          <img src={select.logo} alt="" />
          <span>{list[selectIdx].symbol}</span>
          {list.length > 1 && <img className="arrow" src={OpenArrow} alt="" />}
        </DisplaySelect>
      )}
      {show && list.length > 1 && (
        <SelectDrop>
          {list.map((item, i) => (
            <SelectItem key={i} onClick={(e) => choose(i, e)}>
              <img src={item.logo} alt="" />
              <span>{item.symbol}</span>
            </SelectItem>
          ))}
        </SelectDrop>
      )}
    </SelectBox>
  );
}

const SelectBox = styled.div`
  position: relative;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
  }
`;

const SelectDrop = styled.ul`
  width: 194px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background-color: #1e2b3f;
  box-sizing: border-box;
  padding: 15px 4px;
  position: absolute;
  top: 28px;
  right: 0;
  z-index: 999;
`;

const DisplaySelect = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .arrow {
    width: 24px;
    opacity: 0.5;
    margin-left: 6px;
    transform: ${(props) =>
      props.fullExpand ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const SelectItem = styled.li`
  height: 44px;
  width: 100%;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
  display: flex;
  padding-left: 10px;
  align-items: center;
  box-sizing: border-box;
`;
