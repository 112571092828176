import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DaoLogo from "components/upload/daoLogo";

import TwitterSvg from "assets/images/create/Twittter.svg";
import DiscordSvg from "assets/images/create/Discord.svg"
import TelegramIcon from "assets/images/create/Telegram.svg";

import { CREATE_STATUS } from "../../utils/constant";
import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "components/provider/appProvider";

import { CheckHandleUnique } from "api/graphql/dao";
import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import { checkInvalidStr } from "utils/check";

import { InputLabel, InputItem } from "components/common/inputComp";

const KEY = "basic_info";
export const LOCAL_KEY = `CreateDao_${KEY}`;

const BasicInfoStep = memo((props) => {
  const { t } = useTranslation();
  const { chainId } = useWeb3React();

  const [name, setName] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [telegram, setTelegram] = useState("");
  const [description, setdescription] = useState("");
  const [daohandle, setDaohandle] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const [imgData, setImgData] = useState("");

  const [invalidHandle, setInvalidHandle] = useState();

  const logoRef = useRef();

  const [doCheckHandle, { checking }] = useLazyQuery(CheckHandleUnique, {
    onCompleted: (data) => {
      if (data && data.checkDaoHandleAvailable) {
        setInvalidHandle(undefined);
      } else {
        setInvalidHandle(t("Msg.HandleError"));
      }
    },
    onError: (error) => {
      console.error("====", error);
    },
  });

  const checkDaoHandle = useCallback(
    async (handle) => {
      if (!handle) {
        setInvalidHandle();
        return;
      }
      doCheckHandle({
        variables: {
          chainId,
          handle: handle,
          isCreate: true
        },
        fetchPolicy: "network-only"
      });
    },
    [chainId]
  );

  const savePage = async (k, v) => {
    const data = {
      name,
      description,
      imgUrl,
      daohandle,
      twitter,
      discord,
      telegram,
      invalidHandle
    };
    if (k) {
      data[k] = v
    }
    sessionStorage.setItem(LOCAL_KEY, JSON.stringify(data));
  };

  useEffect(() => {
    try {
      const logoImg = sessionStorage.getItem(`${LOCAL_KEY}_logo`);
      if (logoImg) {
        setImgData(logoImg);
      }
      const data = JSON.parse(sessionStorage.getItem(LOCAL_KEY) || "");
      if (data.name) {
        setName(data.name);
      }
      if (data.imgUrl) {
        setimgUrl(data.imgUrl);
      }
      if (data.description) {
        setdescription(data.description);
      }
      if (data.daohandle) {
        setDaohandle(data.daohandle);
      }
      data.twitter && setTwitter(data.twitter);
      data.discord && setDiscord(data.discord);
      data.telegram && setTelegram(data.telegram);
    } catch (error) {
    }
  }, []);

  const updateLogo = useCallback((url, imgData) => {
    setimgUrl(url);
    sessionStorage.setItem(`${LOCAL_KEY}_logo`, imgData);
    savePage("imgUrl", url);
  }, []);

  const descError = useMemo(() => {
    if (description && description.length > 1024) {
      return true;
    }
  }, [description]);
  useEffect(() => {
    if (daohandle && checkInvalidStr(daohandle)) {
      setInvalidHandle(t("Create.HandleCheck"));
      return
    }
    checkDaoHandle(daohandle);
  },[daohandle])

  return (
    <>
      <EditArea>
        <UploadItem>
          <InputLabel title={t("Create.DAOImage")} labelSize="16px" />
          <DaoLogo t={t} data={imgData} onChange={updateLogo} />
          <LogoTip>*{t("Create.DAOImageTip")}</LogoTip>
        </UploadItem>
        <EditRightArea>
          <FlexRow>
            <InputItem
              required
              labelSize="16px"
              title={t("Create.DAOName")}
              placeholder={t("Create.FillName")}
              maxLength={32}
              value={name}
              onBlur={savePage}
              onChange={(e) => {
                if (e.target.value.length <= 32) {
                  setName(e.target.value);
                }
              }}
            />
            <InputItem
              required
              labelSize="16px"
              title={t("Create.DAOHandle")}
              placeholder={t("Create.DAOHandleTip")}
              maxLength={32}
              value={daohandle}
              errorMsg={invalidHandle}
              onBlur={() => {
                savePage();
              }}
              onChange={(e) => {
                if (e.target.value.length <= 32) {
                  setDaohandle(e.target.value);
                }
              }}
            />
          </FlexRow>
          <FlexRow>
            <InputItem
              labelSize="16px"
              icon={
                <img
                  src={TwitterSvg}
                  alt=""
                  style={{
                    width: "20px",
                    marginRight: "6px",
                    verticalAlign: "unset",
                  }}
                />
              }
              title={t("Create.twitter")}
              value={twitter}
              prefix="@"
              onBlur={savePage}
              onChange={(e) => {
                setTwitter(e.target.value);
              }}
            />
            <InputItem
              labelSize="16px"
              icon={
                <img
                  src={TelegramIcon}
                  alt=""
                  style={{
                    width: "20px",
                    marginRight: "6px",
                    verticalAlign: "unset",
                  }}
                />
              }
              title={t("Create.telegram")}
              value={telegram}
              prefix="http://t.me/"
              onBlur={savePage}
              onChange={(e) => {
                setTelegram(e.target.value);
              }}
            />
          </FlexRow>
          <FlexRow>
            <InputItem
              labelSize="16px"
              icon={
                <img
                  src={DiscordSvg}
                  alt=""
                  style={{
                    width: "20px",
                    marginRight: "6px",
                    verticalAlign: "unset",
                  }}
                />
              }
              title={t("Create.discord")}
              placeholder={t("Create.discordTip")}
              value={discord}
              onBlur={savePage}
              onChange={(e) => {
                setDiscord(e.target.value);
              }}
            />
          </FlexRow>
          <FlexRow>
            <InputItem
              required
              area={true}
              isError={descError}
              autoSize={false}
              areaHeight="88px"
              labelSize="16px"
              title={t("Create.Description")}
              placeholder={t("Create.FillDAODescription")}
              value={description}
              onBlur={savePage}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
            />
            <LimitBox val={description && description.length} max={1024} />
          </FlexRow>
        </EditRightArea>
      </EditArea>
    </>
  );
});

export default BasicInfoStep;

const LimitBox = memo(({ val, max }) => {
  return (
    <LimitBoxStyled>
      {val || 0}/{max}
    </LimitBoxStyled>
  );
});

const LimitBoxStyled = styled.div`
  line-height: 18px;
  font-size: 12px;
  position: absolute;
  right: 20px;
  bottom: 13px;
  opacity: 0.5;
`;

const EditArea = styled.div`
  display: flex;
`;

const EditRightArea = styled.div`
  flex: 1;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 28px;
  position: relative;
  & > div {
    flex: 1;
    &:nth-child(2n) {
      margin-left: 80px;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
const UploadItem = styled.div`
  width: 240px;
`;

const LogoTip = styled.div`
  margin-top: 12px;
  position: relative;
  font-size: 12px;
  opacity: 0.5;
`;
