import styled from "styled-components";
import SetImg from "../../../img/shutdown.png";
import { useTranslation } from "react-i18next";
import {
  useSwapContext,
  Default_Tolerance,
} from "components/provider/swapProvider";
import { useEffect, useState, useCallback } from "react";
import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { getShortDisplay } from "utils/publicJs";
import dangerTip from "img/swap/dangerTip.svg";
import CloseSVG from "components/svg/common/close";
import TipSVG from "components/svg/tip";
import { Tooltip } from "antd";
import { InputBackground } from "assets/styles/common";

export default function SwapSetting(props) {
  const { CloseSetting } = props;
  const { t } = useTranslation();
  const { chainId } = useWeb3React();

  const {
    state: { tolerance },
    dispatchEvent,
  } = useSwapContext();
  const [tol, setTol] = useState();

  useEffect(() => {
    setTol(tolerance);
  }, [tolerance]);

  const onChangeTolerance = useCallback((e) => {
    if (!e.target.value) {
      setTol();
      return;
    }
    const value = Number(e.target.value);
    setTol(value);
  }, []);

  const errorMsg = useMemo(() => {
    if (tol === undefined) {
      return "";
    } else if (tol > 50) {
      return t("Swap.ToleranceInvalid");
    } else if (tol > 1) {
      return t("Swap.ToleranceFrontrun");
    } else if (tol === 0) {
      return t("Swap.ToleranceFailed");
    }
  }, [tol]);

  const handleTol = useCallback(() => {
    if (tol > 50) {
      setTol("");
    }
    const val = tol ? Number(getShortDisplay(tol, 2)) : tol;
    dispatchEvent({
      type: "SET_Tolerance",
      payload: {
        chainId,
        val: val > 50 ? undefined : val,
      },
    });
  }, [tol]);

  return (
    <BorderBox>
      <TitleBox className="font-medium">{t("Swap.settings.Settings")}</TitleBox>
      <div className="topCloseBox">
        <CloseSVG onClick={() => CloseSetting()} />
      </div>
      <ContentBox>
        <Label>
          <span>{t("Swap.settings.tolerance")}</span>
          <Tooltip title={t("Swap.ToleranceTip")}>
            <TipSVG />
          </Tooltip>
        </Label>
        <div className="line">
          <Tolerance>
            <InpputStyled
              type="number"
              value={tol}
              onChange={onChangeTolerance}
              onBlur={handleTol}
              placeholder={Default_Tolerance}
            />
            <span>%</span>
          </Tolerance>
          {errorMsg && (
            <div className="msg">
              <img src={dangerTip} alt="" className="info" />
              {errorMsg}
            </div>
          )}
        </div>
        <Label>{t("Swap.settings.Interface")}</Label>
        <div className="auto">
          <span>{t("Swap.settings.Auto")}</span>
          <span className="gray">{t("Swap.settings.Opened")}</span>
        </div>
      </ContentBox>
    </BorderBox>
  );
}

const BorderBox = styled.div`
  position: absolute;
  left: 0;
  top: -100px;
  z-index: 999;
  padding: 30px 25px;
  width: 340px;
  height: 262px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  box-sizing: border-box;

  .topCloseBox {
    position: absolute;
    right: 16px;
    top: 16px;
    transform: scale(0.9);
    cursor: pointer;
  }
`;
const TitleBox = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  text-align: center;
`;
const ContentBox = styled.div`
  .line {
    margin-bottom: 14px;
    width: 100%;
  }
  .auto {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
  }
  .gray {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 16px;
  }
  .msg {
    font-size: 12px;
    margin-top: 6px;
    color: #d52473;
    line-height: 16px;
    display: flex;
    align-items: center;
    img.info {
      width: 18px;
      margin-right: 5px;
    }
  }
`;

const Tolerance = styled.div`
  position: relative;
  width: 230px;
  ${InputBackground};
  height: 34px;
  line-height: 34px;

  input {
    color: #ffffff;
  }
  span {
    position: absolute;
    right: 13px;
  }
`;
const InpputStyled = styled.input`
  border: none;
  height: 100%;
  width: calc(100% - 20px);
  text-align: right;
  padding-right: 15px;
  background: transparent;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  svg {
    transform: scale(0.8);
    cursor: pointer;
  }
  margin-bottom: 10px;
`;
