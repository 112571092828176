import { useMemo, useState, useCallback, useEffect } from "react";
import WalletConnect from "@walletconnect/client";
import { isMetaTxArray } from "utils/transactions";
import { areStringsEqual } from "utils/strings";

const useWalletConnect = (vaultcontractAddres, chainId) => {
  
  const safe = useMemo(() => {
    if (!vaultcontractAddres || !chainId) {
      return {}
    } else { 
      return {
        safeAddress: vaultcontractAddres,
        chainId: chainId, // TODO chainID
      };
    } 
  }, [vaultcontractAddres, chainId]);

  const localStorageSessionKey = useMemo(() => {
    if (safe && safe.safeAddress) {
      return `SubDAO_SESSION_${safe.safeAddress}`
    }
  }, [safe])

  const [wcClientData, setWcClientData] = useState();
  const [connector, setConnector] = useState();

  const wcDisconnect = useCallback(async () => {
    try {
      await connector?.killSession();
      setConnector(undefined);
      setWcClientData(null);
    } catch (error) {
      console.log("Error trying to close WC session: ", error);
    }
  }, [connector]);

  const wcConnect = useCallback(
    async ({ uri, callbacks, session }) => {
      // const [onReceivedTx, onReceivedMultiTx, onReceivedSign, onReceivedPersionalSign] = callbacks
      const onReceivedTx = callbacks && callbacks[0]
      const onReceivedMultiTx = callbacks &&callbacks[1]
      const onReceivedSign = callbacks &&callbacks[2]
      const onReceivedPersionalSign = callbacks && callbacks[3]
      const wcConnector = new WalletConnect({
        uri,
        session,
        storageId: localStorageSessionKey,
      });
      setConnector(wcConnector);
      setWcClientData(wcConnector.peerMeta);

      wcConnector.on("session_request", (error, payload) => {
        if (error) {
          throw error;
        }
        console.log("[ON]-session_request", payload);

        wcConnector.approveSession({
          accounts: [safe.safeAddress],
          chainId: safe.chainId,
        });

        setWcClientData(payload.params[0].peerMeta);
      });

      wcConnector.on("call_request", async (error, payload) => {
        if (error) {
          throw error;
        }

        console.log("[ON]-call_request", payload);
        try {
          let result = "0x";

          switch (payload.method) {
            case "eth_sendTransaction": {
              const txInfo = payload.params[0];
              console.log("eth_sendTransaction: ",txInfo)
              await onReceivedTx({
                id: payload.id,
                txs: [{
                  to: txInfo.to,
                  value: txInfo.value || '0x0',
                  data: txInfo.data || '0x',
                }]
              })
              break;
            }
            case "gs_multi_send": {
              const txs = payload.params;

              if (!isMetaTxArray(txs)) {
                throw new Error("INVALID_TRANSACTIONS_PROVIDED");
              }
              await onReceivedMultiTx({
                id: payload.id,
                txs: txs.map(txInfo => ({
                  to: txInfo.to,
                  value: (txInfo.value || '0x0').toString(),
                  data: txInfo.data || '0x',
                }))
              })
              break;
            }

            case "personal_sign": {
              const [message, address] = payload.params;

              if (!areStringsEqual(address, safe.safeAddress)) {
                throw new Error("The address or message hash is invalid");
              }
              await onReceivedPersionalSign({
                id: payload.id,
                message
              })
              break;
            }

            case "eth_sign": {
              const [address, messageHash] = payload.params;

              if (
                !areStringsEqual(address, safe.safeAddress) ||
                !messageHash.startsWith("0x")
              ) {
                throw new Error("The address or message hash is invalid");
              }
              await onReceivedSign({
                id: payload.id,
                message: messageHash
              })
              break;
            }
            default: {
              rejectWithMessage(
                payload.id,
                "METHOD_NOT_SUPPORTED"
              );
              break;
            }
          }

        } catch (err) {
          console.error(err)
          rejectWithMessage(payload.id, err.message);
        }
      });

      wcConnector.on("disconnect", (error, payload) => {
        if (error) {
          throw error;
        }
        console.log("[ON]-disconnect");

        wcDisconnect();
      });
    },
    [safe, wcDisconnect]
    // [safe, sdk, wcDisconnect],
  );

  const rejectWithMessage = useCallback((id, message) => {
    if (connector) {
      connector.rejectRequest({ id, error: { message } }); 
    }
  }, [connector])

  const approveCallback = useCallback(
    (id, hash) => {
      if (connector) {
        connector.approveRequest({
          id: id,
          result: hash,
        });
      }
    },
    [connector]
  );

  return {
    wcClientData,
    wcConnect,
    wcDisconnect,
    rejectWithMessage,
    approveCallback,
    connector,
  };
};

export default useWalletConnect;
