import Tabs from "../layout/tabs";
import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import Tokens from "./tokens";
import NFT from "./nft";
import Investment from "./investment";
import TransferHistory from "./transfer/index";
import {
  useParams,
  useNavigate,
  Navigate,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import useMatchRoute, { PortfolioRoutes } from "hooks/useMatchRoute";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import TransferSelect from "./transferSelect";

export default function Portfolio() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subRoute = useMatchRoute(PortfolioRoutes);

  const { pathname } = useLocation();

  const { fullhandle, network: networkSimple } = useParams();;
  const [current, setCurrent] = useState(0);

  const nav = useMemo(() => {
    return [t("Portfolio.Tokens"), t("Portfolio.NFT"), t("Portfolio.Offchain"), t("Home.History")];
  }, []);

  const navRoutes = useMemo(() => {
    return ["tokens", "nft", "investment", "transfer"];
  }, []);

  const transferTypes = useMemo(() => {
    return [
      { name: "Tokens", value: "token" },
      { name: "NFTs", value: "nft" },
    ];
  }, [])
  const [selectType, setSelectType] = useState(transferTypes[0]);


  useEffect(() => {
    if (!subRoute) {
      setCurrent(0);
      return;
    }
    const idx = navRoutes.findIndex((n) => n === subRoute);
    setCurrent(idx > -1 ? idx : 0);
  }, [subRoute]);

  const handleCurrent = (num) => {
    setCurrent(num);
    navigate(`/${networkSimple}/${fullhandle}/portfolio/${navRoutes[num]}`);
  };

  return (
      <InfoBox>
        <FirstLine>
          <Tabs navList={nav} current={current} handleCurrent={handleCurrent}/>
          {pathname.endsWith("/transfer") && (
              <TransferSelect
                  list={transferTypes}
                  selected={selectType}
                  onChange={(v) => setSelectType(v)}
              />
          )}
        </FirstLine>
        <Content id="contentBox">
          <div id="inner">
            <Routes>
              <Route path="tokens" element={<Tokens />}/>
              <Route path="nft" element={<NFT/>}/>
              <Route path="investment" element={<Investment/>}/>
              <Route
                  path="transfer"
                  element={<TransferHistory selectType={selectType.value}/>}
              />
              <Route path="*" element={<Navigate to="./tokens"/>}/>
            </Routes>
          </div>
        </Content>
      </InfoBox>
  );
}

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: hidden;
  height: 100%;
  margin-top: 0;
  #inner {
    overflow-y: auto;
    position: relative;
    margin-top: 20px;
    height: calc(100% - 20px);
  }
`;
