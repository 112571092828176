import { memo, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { InputLabel, InputNumberItem } from "components/common/inputComp";

const VoteConfigBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .vote-tip {
    margin-bottom: 0;
    margin-right: 12px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-left: 12px;
    line-height: 44px;
    opacity: 0.5;
  }
`;

const VentureVoteConfig = memo((props) => {
  const { t } = useTranslation();
  const {
    setInvalid,
    minSupportNumber,
    updateMinSupportNumber,
    gpLength,
    inputWidth,
  } = props;

  useEffect(() => {
    setInvalid(!gpLength || !minSupportNumber || minSupportNumber <= 0);
  }, [minSupportNumber, gpLength]);

  return (
    <VoteConfigBox className="line">
      <InputLabel className="vote-tip" required title={t("Vote.VoteConfigTip")} />
      <ContentBox>
        <div className="input-line">
          <InputNumberItem
            // style={{width: "82px", display:"inline-block"}}
            width={inputWidth}
            className="min-support"
            name="minSupportNumber"
            min={1}
            controls={false}
            value={minSupportNumber}
            onChange={(v) => updateMinSupportNumber(Math.floor(v))}
            placeholder={t("Create.Enter")}
          />
        </div>
        <span>{t("Vote.VoteConfigNumber", { gp: gpLength })}</span>
      </ContentBox>
    </VoteConfigBox>
  );
});
export default VentureVoteConfig;
