import { useState, memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "antd";
import styled from "styled-components";
import BgMP4 from "../assets/mp4/video.mp4";

import Headertop from "../components/header";
import Marquee from "react-fast-marquee";


import CMS from "../assets/images/support/CMS.svg";
import SIGNUM from "../assets/images/support/SIGNUMCAPITAL.svg";
import OKEX from "../assets/images/support/OKX.svg";
import Kenetic from "../assets/images/support/Kenetic.svg";
import HERPER from "../assets/images/support/HYPERSPHERE.svg";
import NGC from "../assets/images/support/NGC.svg";
import Seven from "../assets/images/support/SevenX-Ventures.svg";
import Gate from "../assets/images/support/gate-io.svg";
import HUOBI from "../assets/images/support/Huobi.svg";
import LANCER from "../assets/images/support/lancer.png";
import SPARK from "../assets/images/support/Sparklogo.png";
import DFG from "../assets/images/support/dfg.png";
import FBG from "../assets/images/support/FBG.png";

import DaoItem from "components/common/daoItem";
import FooterBox from "../components/home/footerBox";
import { useAppContext } from "components/provider/appProvider";
import { QueryAllDaos } from "api/graphql/explore";
import { useWeb3React } from "@web3-react/core";
import { useNetworkData } from "hooks/useNetwork";
import useCheckLogin from "hooks/useCheckLogin";
import { useClientContext } from "components/provider/clientProvider";
import getNetworkData from "network";
import SearchImg from "../assets/images/Search.svg";
import AnimationBg from "components/animation";

import { defaultChainId } from "network/index";
import Button from "components/common/button";

const LOGO = memo(({ src, width, right }) => {
  return <LOGOImg src={src} width={width} right={right} alt="" />;
});

export default function NewHome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { network } = useParams();

  const [keyword, setKeyword] = useState('');
  const [showBg, setShowBg] = useState(false);
  const isLogin = useCheckLogin();

  const {
    state: { gateway, pathChainId },
    dispatch
  } = useAppContext();

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const { getClient } = useClientContext();
  const { chainId } = useWeb3React();

  const useChainId = useMemo(() => {
    return pathChainId || (getNetworkData(chainId) ? chainId : defaultChainId);
  }, [chainId, pathChainId]);

  const networkData = useNetworkData(useChainId);

  const navigatePrefix = useMemo(() => {
    return pathChainId && network ? `/${network.simple}` : "";
  }, [pathChainId, network]);

  useEffect(() => {
    if (!chainId) {
      return;
    }
    if (!getNetworkData(chainId)) {
      dispatch({ type: "SWITCH_CHAIN", payload: useChainId });
    }
  }, [chainId])

  const handleKeyword = (e) =>{
    setKeyword(e.target.value)
  }
  
  const go2Search = (e) => {
    if (!e || e.key !== "Enter") {
      return
    }
    if (keyword) {
      navigate(`${navigatePrefix}/explore?search=${keyword}`);
    }
  };

  useEffect(() => {
    let canceled = false;
    const getList = async ()=> {
      try {
        const client = getClient(useChainId);
        const data = await client.request(QueryAllDaos, {
          page: 1,
          size: 10,
          sort: "tav",
          chainId: useChainId,
          active: true,
        })
        if (!canceled) {
          setList(data.allDaoList.data);
          setTotal(data.allDaoList.total);
        }

      } catch (error) {
        console.error("get home daos failed", error);
      }
    }
    getList()
    return ()=> {
      canceled = true;
    }
  }, [useChainId])


  const ShowAnimation = () =>{
    if (isLogin) {
      setShowBg(true)
    } else {
      dispatch({ type: "CONNECT_WALLET", payload: true });
    }
  }

  const go2create = () => {
    if (isLogin) {
      navigate("create")
    } else {
      dispatch({ type: "CONNECT_WALLET", payload: true });
    }
  }
  const toMore = () =>{
    navigate("explore")
  }


  return (
    <div>
      {showBg && (
        <AnimationBg step={2} handleClose={() => setShowBg(false)} state="/" />
      )}
      <Box>
        <HeaderBox>
          <Headertop />
        </HeaderBox>
        <MainContent>
          <MainTop>
            {/*<TitleBox>{t("BannerText")}</TitleBox>*/}
            <TitleBox>
              <div>{t("HomeTitleLine1")}</div>
              <div>{t("HomeTitleLine2")}</div>
              <div>{t("HomeTitleLine3")}</div>
            </TitleBox>
            <Slogan>{t("BannerSubText")}</Slogan>

            <SearchLine>
              <div className="searchBox">
                <input
                  type="text"
                  placeholder={t("Header.Search")}
                  onChange={handleKeyword}
                  onKeyDown={go2Search}
                />
                <img src={SearchImg} alt="" onClick={() => go2Search({key: "Enter"})} />
              </div>
              <CreateBtn
                primary
                width={160}
                height={56}
                onClick={() => ShowAnimation()}
                data-nosnippet
              >
                {t("CreateDao")}
              </CreateBtn>
            </SearchLine>

            {/*<FlexLine>*/}
            {/*  <SearchBox>*/}
            {/*    <SearchInput*/}
            {/*        bordered={false}*/}
            {/*        placeholder={t("Header.Search")}*/}
            {/*        onChange={handleKeyword}*/}
            {/*        onPressEnter={go2Search}*/}
            {/*    />*/}
            {/*    <BtnBox onClick={go2Search}>*/}
            {/*      <img src={SearchImg} alt="" />*/}
            {/*    </BtnBox>*/}
            {/*  </SearchBox>*/}
            {/*{showBg && <AnimationBg bg="fff" step={2} />}*/}
            {/*  /!*<CreateDaoButton onClick={()=>ShowAnimation()} data-nosnippet>{t("CreateDao")}</CreateDaoButton>*!/*/}
            {/*</FlexLine>*/}
            <ListBox>
              <UlBox>
                {list.map((dao, i) => (
                  <DaoItem
                    key={i}
                    data={dao}
                    gateway={gateway}
                    networkSimple={networkData && networkData.simple}
                  />
                ))}
              </UlBox>
              {total > 10 && (
                <LineBox>
                  <Button
                    primary
                    width={229}
                    height={56}
                    onClick={() => toMore()}
                  >
                    {t("MoreDaos")}
                  </Button>
                </LineBox>
              )}
            </ListBox>
          </MainTop>
          <SupportBox>
            <SupportNav data-nosnippet>{t("Supported")}</SupportNav>
            <Marquee gradient={false}>
              <SupportedLogo>
                <ul>
                  <li>
                    <img src={Kenetic} alt="" />
                  </li>
                  <li>
                    <img src={NGC} alt="" />
                  </li>
                  <li>
                    <img src={Seven} alt="" />
                  </li>
                  <li>
                    <img src={Gate} alt="" />
                  </li>
                  <li>
                    <img src={LANCER} alt="" />
                  </li>
                  <li>
                    <img src={OKEX} alt="" />
                  </li>
                  <li>
                    <img src={HERPER} alt="" />
                  </li>
                  <li>
                    <img src={HUOBI} alt="" />
                  </li>
                  <li>
                    <img src={CMS} alt="" />
                  </li>
                  <li>
                    <img src={SIGNUM} alt="" />
                  </li>
                  <li>
                    <img src={SPARK} alt="" />
                  </li>
                  <li>
                    <img src={DFG} alt="" />
                  </li>
                </ul>
              </SupportedLogo>
            </Marquee>
          </SupportBox>
          <MainTop>
            <FooterBox t={t} />
          </MainTop>
          <Copyright>{t("Footer")}</Copyright>
        </MainContent>
        <BrdrBox>
          <VideoBox>
            <video src={BgMP4} autoPlay loop muted />
          </VideoBox>
        </BrdrBox>
      </Box>
    </div>
  );
}

const MainTop = styled.div`
  width: 1240px;
  margin: 0 auto;
`

const SupportNav = styled.div`
  font-size: 24px!important;
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #FFFFFF;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 24px;

`;
const SupportedLogo = styled.div`
  width: 100%;
  ul{
    display: flex;
    flex-wrap: nowrap;
  }
  li{
    width: 222px;
    height: 104px;
    background: rgba(255,255,255,0.06);
    border-radius: 16px;
    backdrop-filter: blur(24px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 17px;
    flex-shrink: 0;
  }
  img{
    max-width: 58%;
    max-height: 53%;
  }
`;

const LOGOImg = styled.img`
`;

const SearchLine = styled.div`
    display: flex;
    align-items: center;
    margin: 30px 0 90px;
    .searchBox {
        width: 604px;
        height: 56px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(10px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 24px;
        &:hover {
          border: 1px solid rgba(255, 255, 255, 0.2);
          img {
            opacity: 1;
          }
        }
    }
    input {
        height: 54px;
        flex-grow: 1;
        background: transparent;
        border: 0;
        color: #ffffff;
        font-size: 18px;
        &::placeholder {
            opacity: 0.3;
        }
    }
    img {
        width: 24px;
        height: 24px;
        margin-left: 30px;
        cursor: pointer;
        opacity: 0.5;
    }
`;


const CreateBtn = styled(Button)`
    margin-left: 32px;
`;

const Box = styled.div`
  width: 100%;
  position: relative;
`;

const HeaderBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
`;
const TitleBox = styled.div`
  margin-top: 52px;
  div {
    font-family: "Poppins-Bold";
    font-weight: bold;
    color: #ffffff;
    line-height: 64px;
    font-size: 48px;
  }
`;
const Slogan = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  margin-top: 16px;
`;

const UlBox = styled.ul`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const MainContent = styled.div`
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: 70px;
    z-index: 9;
`;

const BrdrBox = styled.div`
    width: 100vw;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
`;
const VideoBox = styled.div`
    width: 1920px;
    margin-left: calc((100vw - 1920px) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    video {
        width: 100%;
    }
`;

const SupportBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  margin-bottom: 80px;
`
const LineBox = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

const ListBox = styled.div`
    margin-bottom: 90px;
`
const Copyright = styled.div`
  height: 58px;
  width: 100vw;
  background: rgba(255,255,255,0.06);
  text-align: center;
  line-height: 58px;
  opacity: 0.5;
  margin-top: 40px;
`