import styled from "styled-components";
import General from "components/modal/general";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useRef, useEffect, useState } from "react";
import Button, { ButtonGroup } from "components/common/button";

const ImageCropper = ({ handleCloseCropper, file, uploadAction, t }) => {
  const [url, setUrl] = useState();
  const cropperRef = useRef();

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
  };

  useEffect(() => {
    if (!file) return;
    var _URL = window.URL || window.webkitURL;
    // let image = new Image();
    let src = _URL.createObjectURL(file);
    setUrl(src);
  }, [file]);

  const handleConfirm = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      return uploadAction(blob);
    });
  };

  return (
    <General show={!!file} closeModal={handleCloseCropper} height={560}>
      <Title></Title>
      <Cropper
        src={url}
        style={{ height: 400, width: 400 }}
        // Cropper.js options
        initialAspectRatio={1}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
      />
      <ButtonGroup style={{ marginTop: "20px" }}>
        <Button onClick={handleCloseCropper}>{t("Cancel")}</Button>
        <Button onClick={handleConfirm} primary>
          {t("Confirm")}
        </Button>
      </ButtonGroup>
    </General>
  );
};

export default ImageCropper;

const Title = styled.div`
    width: 100%;
    height: 50px;
`;

const ButtonBox = styled.div`
    margin-top: 20px;
    text-align: center;
`
