import { useState, useEffect, useCallback, memo, useMemo } from "react";
import styled from "styled-components";
import { ethers } from "ethers";

import { getShortDisplay } from "utils/publicJs";
import { useDefiContext } from "components/provider/defiProvider";
import WithdrawModal from "./modals/withdrawModal";
import { getValueDisplay } from "utils/aave";
import EmptyBox from "components/common/empty";
import { formatBalanceDisplay } from "utils/publicJs";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import Button, { ButtonGroup } from "components/common/button";
import LoadingBox from "components/common/loading";

const MySupplies = memo(() => {
  const {
    state: {
      userSupplyReserves: userReserves,
      userUsdData,
      userData,
      userAPY: { earnedAPY },
      reqState,
    },
  } = useDefiContext();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const [list, setList] = useState([]);
  const [openReserve, setOpenReserve] = useState({});
  const [collateral, setCollateral] = useState(0);

  const balanceDisplay = useMemo(() => {
    const { totalCollateralUsdVal } = userUsdData;
    if (!totalCollateralUsdVal) return 0;
    return getValueDisplay(totalCollateralUsdVal.toNumber(), true);
  }, [userUsdData]);

  const maxAllWithdrawUsdBN = useMemo(() => {
    const { totalCollateralUsdVal, totalDebtUsdVal } = userUsdData;
    if (!totalCollateralUsdVal || !totalDebtUsdVal) {
      return BigNumber(0)
    }
    return totalCollateralUsdVal.minus(totalDebtUsdVal);
  }, [userUsdData]);

  const openWithdraw = useCallback((reserve) => {
    setOpenReserve(reserve);
    setShowModal(true);
  }, []);

  const closeModal = () => {
    setShowModal(false);
    setOpenReserve({});
  };

  useEffect(() => {
    const { totalDebtETH, totalCollateralETH } = userData;
    if (!totalDebtETH || !totalCollateralETH || !maxAllWithdrawUsdBN) {
      return;
    }

    const lst = [];
    let collateralBN = BigNumber(0);
    userReserves?.forEach((ur) => {
      if (ur.currentATokenBalance !== "0") {
        const r = ur.reserve;
        if (r.usageAsCollateralEnabled) {
          collateralBN = collateralBN.plus(
            ur.amountInUsdBN.shiftedBy(-r.decimals)
          );
        }
        let maxWithdrawAmount;
        if (totalDebtETH.toNumber() === 0) {
          maxWithdrawAmount = ur.balance;
        } else {
          const balanceUsd = BigNumber(ur.balanceInUsdVal);
          if (maxAllWithdrawUsdBN.gt(balanceUsd)) {
            maxWithdrawAmount = ur.balance;
          } else {
            const remain = maxAllWithdrawUsdBN
              .multipliedBy(ur.amountBN.shiftedBy(-r.decimals))
              .dividedBy(balanceUsd);
            maxWithdrawAmount = remain.toNumber();
          }
        }
        lst.push({
          ...ur,
          balance: ur.balance,
          maxWithdrawAmount,
          balanceInUsd: getValueDisplay(ur.balanceInUsdVal, true),
          supplyAPYDisplay: getShortDisplay(ur.supplyAPY * 100, 2),
        });
      }
    });

    setCollateral(collateralBN.toNumber());
    setList(lst);
  }, [userReserves, userData]);

  const earnedApyDisplay = useMemo(() => {
    return earnedAPY ? getShortDisplay(earnedAPY * 100, 2) : 0;
  }, [earnedAPY]);

  const hasSupply = useMemo(() => {
    return !!userReserves?.find((ur) => ur.currentATokenBalance !== "0");
  }, [userReserves]);

  if (reqState === 2) {
    return <LoadingBox loading={true} />;
  }

  return (
    <>
      {showModal && (
        <WithdrawModal
          userReserve={openReserve}
          closeModal={closeModal}
          show={showModal}
        />
      )}
      <TableBox>
        {!hasSupply ? (
          <EmptyBox msg={t("defi.MySupplies.empty")} className="empty" />
        ) : (
          <>
            <GrayLine>
              <li className="font-demiBold">
                {t("defi.MySupplies.Balance")} {balanceDisplay}
              </li>
              <li className="font-demiBold">
                {t("defi.MySupplies.APY")} {earnedApyDisplay}%
              </li>
              <li className="font-demiBold">
                {t("defi.MySupplies.Collateral")}{" "}
                {getValueDisplay(collateral, true)}
              </li>
            </GrayLine>
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th>{t("defi.MySupplies.Asset")}</th>
                  <th>{t("defi.MySupplies.Balance")}</th>
                  <th>{t("defi.MySupplies.APY")}</th>
                  {/* <th>Collateral</th> */}
                  <th style={{ textAlign: "center" }}>
                    {t("defi.MySupplies.Action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((r, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: "left" }} className="info">
                      <img className="logo" src={r.logo} alt="" />
                      {r.reserve.symbol}
                    </td>
                    <td className="number">
                      {formatBalanceDisplay(Number(r.balance))}
                      <div className="tips">{r.balanceInUsd}</div>
                    </td>
                    <td className="number">{r.supplyAPYDisplay}%</td>
                    {/* <td>
                      <SwitchBox className={switchStatus?"on":"off"} onClick={()=>handleSwitch()}><div className="mid"/></SwitchBox>
                  </td> */}
                    <td>
                      <ButtonGroup>
                        <Button
                          primary
                          width="112"
                          onClick={() => openWithdraw(r)}
                        >
                          {t("defi.MySupplies.Withdraw")}
                        </Button>
                        <Button className="borderLine" width="112">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://app.aave.com/reserve-overview/?underlyingAsset=${r.reserve.underlyingAsset}`}
                          >
                            {t("defi.MySupplies.Details")}
                          </a>
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </TableBox>
    </>
  );
});

export default MySupplies;

const TableBox = styled.div`
  position: relative;
  height: 100%;
  table {
    width: 100%;
  }
  th {
    height: 65px;
    color: #328aff;
    font-size: 16px;
    white-space: nowrap;
    &:first-child {
      padding-left: 30px;
    }
    text-align: left;
  }
  td {
    text-align: left;
    white-space: nowrap;
    height: 65px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
    }
    &.info {
      font-size: 16px;
      display: flex;
      align-items: center;
      line-height: 22px;
    }
    .logo {
      margin-right: 13px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    .tips {
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      opacity: 0.5;
    }
    &.number {
      font-family: "Rubik-SemiBold";
      font-size: 18px;
      font-weight: 600;
    }
  }
  tbody tr:nth-child(2n + 1) {
    background: rgba(255, 255, 255, 0.04);
  }
  @media (max-width: 1440px) {
    th {
      height: 45px;
    }
  }
`;

const GrayLine = styled.ul`
  display: flex;
  align-items: center;
  height: 55px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  backdrop-filter: blur(32px);
  padding: 0 20px;
  margin: 0 30px;
  li {
    margin-right: 50px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
`;

const SwitchBox = styled.div`
  width: 40px;
  height: 20px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  .mid {
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    border-radius: 16px;
    top: 2px;
  }
  &.on {
    background: #62ba46;
    .mid {
      left: 22px;
      animation: toOn 0.5s;
      -webkit-animation: toOn 0.5s;
    }
  }
  &.off {
    background: #959bb4;
    .mid {
      left: 2px;
      animation: toOff 0.5s;
      -webkit-animation: toOff 0.5s;
    }
  }

  @keyframes toOn {
    0% {
      left: 2px;
    }
    100% {
      left: 22px;
    }
  }

  @-webkit-keyframes toOn {
    0% {
      left: 2px;
    }
    100% {
      left: 22px;
    }
  }

  @keyframes toOff {
    0% {
      left: 22px;
    }
    100% {
      left: 2px;
    }
  }

  @-webkit-keyframes toOff {
    0% {
      left: 22px;
    }
    100% {
      left: 2px;
    }
  }
`;
