import React, { createContext, useContext } from "react";
import { GraphQLClient } from "graphql-request";
import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core/dist";
import { useAuthContext } from "./authProvider";
import useCheckLogin from "hooks/useCheckLogin";
import { PROD_ENDPOINT, DEV_ENDPOINT } from "utils/constant";
import { ethers, providers } from "ethers";
import { useCallback } from "react";
import { SupportedChainId } from "network";

const ClientContext = createContext();

const DevClient = new GraphQLClient(DEV_ENDPOINT);
const ProdClient = new GraphQLClient(PROD_ENDPOINT);

const getUri = (chainId) => {
  return process.env.NODE_ENV === "production" ? PROD_ENDPOINT : DEV_ENDPOINT;
  // console.log(process.env.NODE_ENV, chainId);
  // if (process.env.NODE_ENV === "production") {
  //   return PROD_ENDPOINT;
  // } else {
  //   switch (chainId) {
  //     case SupportedChainId.POLYGON:
  //       console.log("polygon")
  //       return DEV_ENDPOINT;
  //     case SupportedChainId.MAINNET:
  //     case SupportedChainId.BSC:
  //     case SupportedChainId.MOONBEAM:
  //     default:
  //       return PROD_ENDPOINT;
  //   }
  // }
};


const ClientProvider = (props) => {

  const { chainId } = useWeb3React();

  const {
    state: { token },
  } = useAuthContext();

  const providers = useMemo(() => {
    return {
      [SupportedChainId.MAINNET]: new ethers.providers.StaticJsonRpcProvider(
        "https://eth-mainnet.g.alchemy.com/v2/YuNeXto27ejHnOIGOwxl2N_cHCfyLyLE"
      ),
      [SupportedChainId.POLYGON]: new ethers.providers.StaticJsonRpcProvider(
        "https://polygon-mainnet.g.alchemy.com/v2/rVDt1QgtXh3cJsXmU-ikr3Q2tbpBx7Hp"
      ),
      [SupportedChainId.BSC]: new ethers.providers.StaticJsonRpcProvider(
        "https://bsc-dataseed1.binance.org"
      ),
      [SupportedChainId.MOONBEAM]: new ethers.providers.StaticJsonRpcProvider(
        "https://rpc.api.moonbeam.network"
      ),
      [SupportedChainId.BSC_TESTNET]:
        new ethers.providers.StaticJsonRpcProvider(
          "https://dimensional-magical-sun.bsc-testnet.discover.quiknode.pro/8d7ff025c5d4a1aa94bfcdf8a563bf156c120ca7/"
        ),
      [SupportedChainId.ARBITRUM_ONE]:
        new ethers.providers.StaticJsonRpcProvider(
          "https://arb-mainnet.g.alchemy.com/v2/Y1T8EYy0PlU8k3RYTRse5G4sUx3gaAbt"
        ),
      [SupportedChainId.ZKSYNC]: new ethers.providers.StaticJsonRpcProvider(
        "https://mainnet.era.zksync.io"
      ),
      [SupportedChainId.ZKSYNC_TESTNET]:
        new ethers.providers.StaticJsonRpcProvider(
          "https://zksync2-testnet.zksync.dev"
        ),
    };
  }, []);

  const clients = useMemo(() => {
    return {
      dev: DevClient,
      propd: ProdClient,
    }
  }, [])

  const getClient = useCallback((chainId) => {
    return process.env.NODE_ENV === "production" ? clients.propd : clients.dev;
    // if (process.env.NODE_ENV === "production") {
    //   return clients.propd
    // } else {
    //   switch (chainId) {
    //     case SupportedChainId.POLYGON:
    //       return clients.dev
    //     case SupportedChainId.MAINNET:
    //     case SupportedChainId.BSC:
    //     case SupportedChainId.MOONBEAM:
    //     default:
    //       return clients.propd;
    //   }
    // }
  }, [clients])

  
  const authClient = useMemo(() => {
    return new GraphQLClient(getUri(chainId), {
      headers: {
        Authorization: token,
      },
    });
  }, [chainId, token]);

  return (
    <ClientContext.Provider value={{ authClient, getClient, providers }}>
      {props.children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => ({ ...useContext(ClientContext) });

export default ClientProvider;
