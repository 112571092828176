import {
  RAY,
  rayPow,
  valueToZDBigNumber,
  getLinearBalance,
  valueToBigNumber,
  getCompoundedBalance,
  getCompoundedStableBalance,
  calculateHealthFactorFromBalancesBigUnits,
} from "@aave/math-utils";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import moment from "moment";
import { getShortDisplay } from "./publicJs";

export const SECONDS_PER_YEAR = 31536000;

export const getSupplyAPY = (liquidityRate) => {
  const supplyAPY = rayPow(
    valueToZDBigNumber(liquidityRate).dividedBy(SECONDS_PER_YEAR).plus(RAY),
    SECONDS_PER_YEAR
  ).minus(RAY);

  return BigNumber(supplyAPY.toString()).div(BigNumber(RAY.toString()));
};

export const getVariableBorrowAPY = (variableBorrowRate) => {
  const apy = rayPow(
    valueToZDBigNumber(variableBorrowRate)
      .dividedBy(SECONDS_PER_YEAR)
      .plus(RAY),
    SECONDS_PER_YEAR
  ).minus(RAY);

  if (apy.isNaN()) {
    return BigNumber(0);
  }
  const res = BigNumber(apy.toString()).div(BigNumber(RAY.toString()));
  return res.isNaN() ? BigNumber(0) : res;
};

export const getStableBorrowAPY = (stableBorrowRate) => {
  const apy = rayPow(
    valueToZDBigNumber(stableBorrowRate).dividedBy(SECONDS_PER_YEAR).plus(RAY),
    SECONDS_PER_YEAR
  ).minus(RAY);

  if (apy.isNaN()) {
    return BigNumber(0);
  }
    
  const res = BigNumber(apy.toString()).div(BigNumber(RAY.toString()));
  return res.isNaN() ? BigNumber(0) : res;
};

export const getBalanceInUSD = (usdPriceEth, priceInEth, amountBN) => {
  const ether1 = BigNumber(1).shiftedBy(18);
  const usdPriceEthNum = BigNumber(usdPriceEth);
  const priceInEthNum = BigNumber(priceInEth);
  const realPrice = priceInEthNum.multipliedBy(
    ether1.dividedBy(usdPriceEthNum)
  );
  const value = amountBN.multipliedBy(realPrice);
  return value.div(ether1);
};;

export const getBalance = (reserve, currentTimestamp, lastUpdateTimestamp) => {
  const underlyingBalance = getLinearBalance({
    balance: reserve.scaledATokenBalance,
    index: reserve.reserve.liquidityIndex,
    rate: reserve.reserve.liquidityRate,
    lastUpdateTimestamp,
    currentTimestamp,
  });
  return BigNumber(underlyingBalance.toString());
};

export const calculateHealth = (
  totalCollateralUsd,
  totalDebtUsd,
  currentLiquidationThreshold
) => {
  return calculateHealthFactorFromBalancesBigUnits({
    collateralBalanceMarketReferenceCurrency: totalCollateralUsd,
    borrowBalanceMarketReferenceCurrency: totalDebtUsd,
    currentLiquidationThreshold: currentLiquidationThreshold.shiftedBy(-4),
  }).toString(10);
};

export const getValueDisplay = (v, showDollar) => {
  if (v === 0) {
    return `${showDollar ? "$" : ""}0`;
  } else if (v < 0.01) {
    return `< ${showDollar ? "$" : ""}0.01`;
  } else {
    return `${showDollar ? "$" : ""}${getShortDisplay(v, 2)}`;
  }
};

export const getvariableBorrows = (ur) => {
  const variableBorrows = getCompoundedBalance({
    principalBalance: ur.scaledVariableDebt,
    reserveIndex: ur.reserve.variableBorrowIndex,
    reserveRate: ur.reserve.variableBorrowRate,
    lastUpdateTimestamp: ur.reserve.lastUpdateTimestamp,
    currentTimestamp: moment().unix(),
  });
  return variableBorrows;
};

export const getstableBorrows = (ur) => {
  const stableBorrows = getCompoundedStableBalance({
    principalBalance: ur.principalStableDebt,
    userStableRate: ur.reserve.stableBorrowRate,
    lastUpdateTimestamp: ur.stableBorrowLastUpdateTimestamp,
    currentTimestamp: moment.unix(),
  });
  return stableBorrows;
};

export const formatHealth = (health_BN) => {
  if (health_BN.gt(1000000)) {
    return getShortDisplay(health_BN.dividedBy(1000000).toString(), 2) + "M";
  } else if (health_BN.gt(1000)) {
    return getShortDisplay(health_BN.dividedBy(1000).toString(), 2) + "K";
  }
  return getShortDisplay(health_BN.toString(), 2);
};
