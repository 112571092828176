import mainAddress from "./address";
import Logo from "../../assets/chain/zkSync.jpeg";
import ETH from "../../assets/chain/ETH.svg";
import Dapps from "../dapps";

export default {
  name: "zkSync",
  simple: "zksync",
  mainAddress: mainAddress,
  // aave not support
  aaveGraph: "",
  aaveLendingPool: "",
  aaveLendingPoolProvider: "",
  multicall: "0xa777ce38dB92763ec54D909F7A42C07B63967fA1",
  multicallCustomContractAddress: "0x0E1f7AA4E6bf38E5786c23b04Fe907cA910983be",
  // swap
  swapApp: "MuteSwap",
  swapRouter: "0x8B791913eB07C32779a16750e3868aA8495F5964",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  // multi-sign default tokens
  multiSignDefaultSupportTokens: ["0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4"],
  tokenLogo: ETH,
  scan: {
    img: "",
    host: "https://explorer.zksync.io/",
  },
  chainId: 324,
  chainIdHex: "0x144",
  chainName: "zkSync",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
    logoURI: "https://app.aave.com/icons/networks/ethereum.svg",
  },
  wrappedToken: "",
  logo: Logo,
  rpcUrls: ["https://mainnet.era.zksync.io"],
  blockExplorerUrls: ["https://explorer.zksync.io/"],
  walletConnectDapps: [],
};
