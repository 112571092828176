import { ethers } from "ethers";
import { hexToUtf8, isHexStrict } from "web3-utils";

const sign = (msg) => {
  try {
    const sign_1 = `0x${Buffer.from(msg, "utf8").toString("hex")}`;
    const sign_2 = ethers.utils.keccak256(sign_1);
    const sign_3 = `0x${Buffer.from(sign_2, "utf8").toString("hex")}`;
    return sign_3;
  } catch (err) {
    console.error(err);
  }
}

export const signMessage = (chainId, account, timestamp) => {
  const msg = `rfa#${chainId}#${account}#${timestamp}`;

  try {
    const sign_1 = `0x${Buffer.from(msg, "utf8").toString("hex")}`;
    const sign_2 = ethers.utils.keccak256(sign_1);
    const sign_3 = `0x${Buffer.from(sign_2, "utf8").toString("hex")}`;
    return sign_3;
  } catch (err) {
    console.error(err);
  }
};


export const signProjectMessage = (data, timestamp) => {
  //            logo:       name:       amount          :round      :valuationAmount          :valuationToken:timestamp
  const msg = `${data.logo}:${data.name}:${data.amount}:${data.round}:${data.valuationAmount}:${data.token}:${timestamp}`
  console.log("==>signProjectMessage: ", msg)
  return sign(msg)
};


export const signDeleteProjectMessage = (data, timestamp) => {
// projectID:chainID:timestamp
  const msg = `${data.id}:${data.chainId}:${timestamp}`
  console.log("==>signDeleteProjectMessage: ", msg)
  return sign(msg)
}


export const convertToHumanReadableMessage = (message) => {
  const isHex = isHexStrict(message.toString());

  let humanReadableMessage = message;
  if (isHex) {
    try {
      humanReadableMessage = hexToUtf8(message);
    } catch (e) {
      // do nothing
    }
  }

  return humanReadableMessage;
};