import { TypedDataUtils } from "eth-sig-util";
import { generateTypedDataFrom } from "./EIP712Signer";

export const generateSafeTxHash = (safeAddress, safeVersion, txArgs) => {
  const typedData = generateTypedDataFrom({
    safeAddress,
    safeVersion,
    ...txArgs,
  });
  return `0x${TypedDataUtils.sign(typedData).toString('hex')}`
};
