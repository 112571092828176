import React, { useReducer, createContext, useContext } from "react";
import { CONNECTION_STATUS } from "utils/constant";
import TxManager from "components/apps/walletConnect/index";

const WCTContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_WCDATA":
      return { ...state, wcClientData: action.payload };
    case "SET_WCURI":
      return { ...state, connectURI: action.payload };
    case "SET_BEHAVIOR":
      return { ...state, behavior: action.payload };
    case "SET_SOURCE":
      return { ...state, source: action.payload };
    case "SWITCH_LOADING":
      return { ...state, loading: action.payload };
    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};

const INIT_STATE = {
  status: CONNECTION_STATUS.DISCONNECTED,
  source: ""
};

const WCTProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  return (
    <WCTContext.Provider value={{ state, dispatch }}>
      <TxManager />
      {props.children}
    </WCTContext.Provider>
  );
};

export const useWCTContext = () => ({ ...useContext(WCTContext) });

export default WCTProvider;
