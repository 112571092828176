import styled from "styled-components";
import { useCallback, useState, useEffect, memo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Multicall } from "ethereum-multicall";
import { useWeb3React } from "@web3-react/core";
import PaginationBox from "components/common/pagination";
import EmptyBox from "components/common/empty";
import { useDaoContext } from "views/dao/provider";
import useSigner from "hooks/useSigner";
import { useAppContext } from "components/provider/appProvider";
import { useTranslation } from "react-i18next";
import api from "api";
import { useVaultContract } from "hooks/useContract";
import { VAULT_MANAGER } from "utils/constant";
import { VOTE_INFO } from "./detail";
import moment from "moment";
import LoadingBox from "components/common/loading";
import { useClientContext } from "components/provider/clientProvider";
import useCheckLogin from "hooks/useCheckLogin";
import { TransactionEvent } from "utils/constant";
import useSubcribe from "hooks/useSubscribe";
import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import { useMemo } from "react";
import { IndexTag } from "./detail/styles";

import { getVoteList } from "api/graphql/tx";
import useMulticallTxs from "components/apps/walletConnect/useMulticallTxs";
import { parseVoteData } from "utils/parseVoteDataUtil";
import { SubgraphApiQuery, getWctCount } from "api/graphql/subQuery";
import { useNetworkData } from "hooks/useNetwork";

const UserVoteStatus = {
  Default: 0,
  Agree: 1,
  Disagree: 2,
};

export default function Queue() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { fullhandle, network: networkSimple } = useParams();

  const { chainId, account } = useWeb3React();
  const {
    state: {
      daoId,
      daoChainId,
      componentAddressMap,
      whoAmI: { isOwner, isGP },
    },
  } = useDaoContext();

  const {
    state: { exploreScan, txTransactions },
    dispatch,
  } = useAppContext();

  const { providers, getClient } = useClientContext();
  const isLogin = useCheckLogin();

  const vaultContract = useVaultContract(componentAddressMap.get(VAULT_MANAGER));

  const signer = useSigner();

  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState([]);

  const network = useNetworkData(chainId);

  const multicall = useMemo(() => {
    if (!daoChainId || !providers[daoChainId]) {
      return;
    }
    const _config = {
      ethersProvider: providers[daoChainId],
      tryAggregate: true,
    };
    if (network.multicallCustomContractAddress) {
      _config.multicallCustomContractAddress =
        network.multicallCustomContractAddress;
    }
    return new Multicall(_config);
  }, [providers, daoChainId]);

  const [getTxDatas, fetchLoading] = useMulticallTxs(
    multicall,
    componentAddressMap.get(VAULT_MANAGER),
    account
  );

  const toGo = (id) => {
    navigate(`/${networkSimple}/${fullhandle}/vote/${id}`, { state: "queue" });
  };

  const formatStartTime = useCallback((time) => {
    return moment(time * 1000).format("MMMM Do YYYY, h:mm A");
  }, []);

  const getTotal = async () => {
    const resp = await getClient().request(SubgraphApiQuery, {
      query: getWctCount(daoId, "vote"),
      chainId,
    });
    const data = JSON.parse(resp.subgraphApi).data;
    if (data) {
      setTotal(Number(data.totalCount?.count || 0));
    }
  };

  const getVoteQueue = async () => {
    setLoading(true);
    const resp = await getVoteList(chainId, daoId, page, pageSize);
    setLoading(false);

    if (resp.code !== 0) {
      return;
    }

    const data = resp.data;
    console.log("txs1:", data);

    const txIds = data.map((tx) => Number(tx.txID));
    const txs = await getTxDatas(txIds);
    console.log("txs_map: ", txs);

    setList(
      data.map((tx) => {
        const txData = txs[tx.txID];
        const { voteType } = parseVoteData(txData.data);
        return {
          ...txData,
          startAt: formatStartTime(tx.createAt),
          vote_type: voteType,
        };
      })
    );
  };

  const doChoose = async (e, vid, selected, pending, voteType) => {
    e.stopPropagation();
    if (pending) {
      return;
    }
    try {
      const res = await api.vault.confirmTransaction(vaultContract, signer, {
        voteId: vid,
        flag: selected,
      });
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t(selected ? "Notification.AgreeVote" : "Notification.DisagreeVote"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.VoteVote,
          data: {
            voteId: vid,
            chainId,
            daoId,
            account,
            support: selected,
            voteType,
          },
        },
      });

      dispatch({
        type: "APPEND_MULTISIGN_TX",
        payload: {
          id: vid,
          hash: txHash,
          daoId,
          chainId,
          account,
        },
      });
    } catch (error) {
      console.error("vote failed", error);
    }
  };

  const onChangePage = useCallback((v) => {
    setPage(v);
  }, []);

  const onShowSizeChange = useCallback((c, size) => {
    setPagesize(size);
  }, []);

  useEffect(() => {
    if (!daoId || !daoChainId || !componentAddressMap.get(VAULT_MANAGER)) {
      return;
    }
    getVoteQueue();
    getTotal();
  }, [daoId, daoChainId, page, pageSize, isLogin, componentAddressMap]);

  const getVotePending = (txId) => {
    console.log(">>txTransactions: ", txTransactions, daoId, txId);
    if (!txTransactions[daoId]) {
      return false
    } else {
      return !!txTransactions[daoId][txId]
    }
  }

  const Action = memo(({ userState, voteId, voteType }) => {
    if (!isLogin || (!isOwner && !isGP)) {
      return <></>;
    }
    if (userState === UserVoteStatus.Default) {
      const pending = getVotePending(voteId);
      return (
        <ActionStyled pending={pending}>
          <span
            className="agree"
            onClick={(e) => doChoose(e, voteId, true, pending, voteType)}
          >
            {t("Vote.Agree")}
          </span>{" "}
          <span
            className="disagree"
            onClick={(e) => doChoose(e, voteId, false, pending, voteType)}
          >
            {t("Vote.Disagree")}
          </span>
        </ActionStyled>
      );
    }
    if (userState === UserVoteStatus.Agree) {
      return (
        <ActionStyled>
          <span className="agreed">{t("Vote.queue.Agreed")}</span>
        </ActionStyled>
      );
    }
    if (userState === UserVoteStatus.Disagree) {
      return (
        <ActionStyled>
          <span className="agreed">{t("Vote.queue.Disagree")}</span>
        </ActionStyled>
      );
    }
    return <></>;
  });

  const refreshList = (_, { daoId: _daoId }) => {
    if (_daoId === daoId) {
      getVoteQueue();
    }
  };

  // event
  useSubcribe(TransactionEvent.NewVote, refreshList);
  useSubcribe(TransactionEvent.HandleVote, refreshList);

  return (
    <>
      <TableBox>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>{t("Vote.queue.No")}</th>
              <th>{t("Vote.queue.Type")}</th>
              <th>{t("Vote.queue.time")}</th>
              <th className="center">{t("Vote.queue.Agree")}</th>
              <th className="center">Action</th>
            </tr>
          </thead>
          <tbody>
            {list.map((vote) => (
              <tr key={vote.id} onClick={() => toGo(vote.id)}>
                <td>
                  <IndexTag>{vote.id}</IndexTag>
                </td>
                <td className="flexBox">
                  <img src={VOTE_INFO[vote.vote_type]?.icon} alt="" />
                  <span>{t(VOTE_INFO[vote.vote_type]?.shortName)}</span>
                </td>
                <td className="time">{vote.startAt}</td>
                <td className="center">
                  <span className="percent">
                    {vote.agree_count} : {vote.oppose_count}
                  </span>{" "}
                </td>
                <td className="center">
                  <Action voteId={vote.id} userState={vote.senderState} voteType={vote.vote_type} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!loading && !list.length && (
          <EmptyBox pure={true} style={{ marginTop: "100px" }} />
        )}
        {!!list.length && (
          <Pagination>
            <PaginationBox
              showQuickJumper
              pageSize={pageSize}
              defaultCurrent={page}
              total={total}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
            />
          </Pagination>
        )}
      </TableBox>
      <LoadingStyle loading={loading} type="data" />
    </>
  );
}

const TableBox = styled.div`
  min-height: 100%;
  scrollbar-width: none;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 16px;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  table {
    width: 100%;
  }
  th.center,
  td.center {
    text-align: center;
  }
  th {
    font-size: 14px;
    font-weight: 400;
    color: #4a84ff;
    height: 72px;
    text-align: left;
    &:first-child {
      border-top-left-radius: 16px;
      padding-left: 32px;
    }
    &:last-child {
      border-top-right-radius: 16px;
      text-align: center;
    }
  }

  td {
    white-space: nowrap;
    font-weight: 400;
    vertical-align: middle;
    .percent {
      font-family: "Rubik-Medium";
      font-size: 18px;
    }
    &:first-child {
      text-align: left;
      padding-left: 32px;
    }
    &:last-child {
      text-align: center;
    }
    &.time {
      font-family: "Poppins-Light";
    }
    &.flexBox {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        margin-right: 8px;
      }
    }
  }
  .walletIcon {
    width: 24px;
  }
  tbody tr {
    height: 62px;
    line-height: 62px;
  }
  tbody tr:nth-child(odd) {
    background: rgba(255, 255, 255, 0.04);
  }
`;

const Num = styled.span`
  letter-spacing: 5px;
`;

const Pagination = styled.div`
  text-align: right;
  margin: 10px 80px 0 0;
  height: 70px;
`;

const ActionStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .agree,
  .disagree,
  .agreed {
    height: 24px;
    line-height: 24px;
    border-radius: 13px;
    color: #041735;
    font-size: 12px;
    padding: 0 12px;
    margin-left: 15px;
    min-width: 88px;
    box-sizing: border-box;
  }
  .agree {
    background-color: #79dfc4;
    cursor: ${({ pending }) => (pending ? "auto" : "pointer")};
    opacity: ${({ pending }) => (pending ? "0.5" : "1")};
  }
  .disagree {
    background-color: #ffa4d1;
    cursor: ${({ pending }) => (pending ? "auto" : "pointer")};
    opacity: ${({ pending }) => (pending ? "0.5" : "1")};
  }
  .agreed {
    color: #ffffff;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.32) 0%,
      rgba(255, 255, 255, 0.5) 100%
    );
    margin: 0;
  }
`;

const LoadingStyle = styled(LoadingBox)`
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 99;
`;