import styled from "styled-components";
import Link from "components/common/LinkIcon";
import CopyStr from "components/copy";
import publicJs from "utils/publicJs";

const AddressBox = ({ address }) => {
  return (
    <AddressStyled>
      <span>
        {publicJs.AddresstoShow(address)}
      </span>
      <div>
        <CopyStr address={address} />
        <Link hash={address} type="address" />
      </div>
    </AddressStyled>
  );
};

export default AddressBox;

const AddressStyled = styled.div`
  width: 188px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 0 16px;
  font-family: "Poppins-Light";
  font-weight: 300;
  & > div {
    height: 24px;
  }
  img {
    margin-right: 0;
    margin-left: 0 !important;
  }
  > span {
    margin-right: 2px;
  }
`;
