export default {
  "0x2f6F833fAb26Bf7F81827064f67ea4844BdEa03F":{
    "id":0,
    "token0":{
      "id":"0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c",
      "name":"Tether USD",
      "symbol":"USDT",
      "decimals":6,
      "logoURI":"/images/tokens/usdt.png",
      "bridgeVia":"ce"
    },
    "token1":{
      "id":"0xc9baa8cfdde8e328787e29b4b078abf2dadc2055",
      "name":"Binance",
      "symbol":"BNB",
      "decimals":18,
      "logoURI":"/images/tokens/bnb.png"
    },
    "name":"StellaSwap",
    "symbol":"BNB/USDT.ce SLP"
  },
  "0xEc8aC4CCBBAe310f9e83D102b35178d6C10EDFeA":{
    "id":1,
    "token0":{
      "id":"0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c",
      "name":"Tether USD",
      "symbol":"USDT",
      "decimals":6,
      "logoURI":"/images/tokens/usdt.png",
      "bridgeVia":"ce"
    },
    "token1":{
      "id":"0xCb4A7569a61300C50Cf80A2be16329AD9F5F8F9e",
      "name":"Binance USD",
      "symbol":"BUSD",
      "decimals":18,
      "logoURI":"/images/tokens/busd.png",
      "bridgeVia":"ce"
    },
    "name":"StellaSwap",
    "symbol":"BUSD.ce/USDT.ce SLP"
  },
  "0x6f71389426efe6fF4e357Ca6DD012B210A6a3c9c":{
    "id":2,
    "token0":{
      "id":"0x8ece0d14d619fe26e2c14c4a92c2f9e8634a039e",
      "name":"Blockchain Monster Coin",
      "symbol":"BCMC",
      "decimals":18,
      "logoURI":"/images/tokens/BCMC.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"BCMC/WGLMR SLP"
  },
  "0x55Db71E6bEaB323290f6571C428C171e15CDBAD2":{
    "id":4,
    "token0":{
      "id":"0x3D632d9e1a60a0880Dd45E61f279D919b5748377",
      "name":"ApeCoin",
      "symbol":"APE",
      "decimals":18,
      "logoURI":"/images/tokens/APE.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"APE/WGLMR SLP"
  },
  "0xf4C10263f2A4B1f75b8a5FD5328fb61605321639":{
    "id":5,
    "token0":{
      "id":"0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA",
      "name":"Cosmos",
      "symbol":"ATOM",
      "decimals":6,
      "logoURI":"/images/tokens/ATOM.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"ATOM/GLMR SLP"
  },
  "0x051FcF8986B30860a1341e0031e5622Bd18d8A85":{
    "id":6,
    "token0":{
      "id":"0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA",
      "name":"Cosmos",
      "symbol":"ATOM",
      "decimals":6,
      "logoURI":"/images/tokens/ATOM.png"
    },
    "token1":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "name":"StellaSwap",
    "symbol":"ATOM/USDC SLP"
  },
  "0xb6835036C9A2A96D3BfB8DBE1722971fEb456a83":{
    "id":18,
    "token0":{
      "id":"0x085416975fe14C2A731a97eC38B9bF8135231F62",
      "name":"Terra Classic USD",
      "symbol":"USTC",
      "decimals":6,
      "logoURI":"/images/tokens/UST.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"USTC/GLMR SLP"
  },
  "0xa3eE3a0a36Dc915fDc93062e4B386DF37d00217E":{
    "id":8,
    "token0":{
      "id":"0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
      "name":"Ethereum | Multichain",
      "symbol":"ETH",
      "decimals":18,
      "logoURI":"/images/tokens/eth.png",
      "bridgeVia":"multi"
    },
    "token1":{
      "id":"0x30D2a9F5FDf90ACe8c17952cbb4eE48a55D916A7",
      "name":"Ethereum | Nomad",
      "symbol":"ETH",
      "decimals":18,
      "logoURI":"/images/tokens/eth.png",
      "bridgeVia":"mad"
    },
    "name":"StellaSwap",
    "symbol":"ETH.any/ETH.mad SLP"
  },
  "0xAac208af698A620047EBe75C50A4fd8d71192731":{
    "id":9,
    "token0":{
      "id":"0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c",
      "name":"Tether USD",
      "symbol":"USDT",
      "decimals":6,
      "logoURI":"/images/tokens/usdt.png",
      "bridgeVia":"ce"
    },
    "token1":{
      "id":"0x8a4b4c2acadeaa7206df96f00052e41d74a015ce",
      "name":"Wrapped BTC",
      "symbol":"WBTC",
      "decimals":8,
      "logoURI":"/images/tokens/btc.png",
      "bridgeVia":"ce"
    },
    "name":"StellaSwap",
    "symbol":"WBTC.ce/USDT.ce"
  },
  "0xa927E1e1E044CA1D9fe1854585003477331fE2Af":{
    "id":10,
    "token0":{
      "id":"0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080",
      "name":"Polkadot",
      "symbol":"DOT",
      "decimals":10,
      "logoURI":"/images/tokens/xcdot.png",
      "bridgeVia":"xc"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"GLMR/DOT.xc"
  },
  "0x9d19EDBFd29D2e01537624B25806493dA0d73bBE":{
    "id":11,
    "token0":{
      "id":"0x30D2a9F5FDf90ACe8c17952cbb4eE48a55D916A7",
      "name":"Ethereum",
      "symbol":"ETH",
      "decimals":18,
      "logoURI":"/images/tokens/eth.png",
      "bridgeVia":"mad"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"GLMR/ETH.mad"
  },
  "0x9bFcf685e641206115dadc0C9ab17181e1d4975c":{
    "id":12,
    "token0":{
      "id":"0x8f552a71EFE5eeFc207Bf75485b356A0b3f01eC9",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"mad"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"GLMR/USDC.mad"
  },
  "0xE28459075c806b1bFa72A38E669CCd6Fb4125f6a":{
    "id":13,
    "token0":{
      "id":"0x1DC78Acda13a8BC4408B207c9E48CDBc096D95e0",
      "name":"Wrapped BTC",
      "symbol":"WBTC",
      "decimals":8,
      "logoURI":"/images/tokens/wbtc.png",
      "bridgeVia":"mad"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"GLMR/WBTC.mad"
  },
  "0xb536c1F9A157B263B70A9a35705168ACC0271742":{
    "id":15,
    "token0":{
      "id":"0x511ab53f793683763e5a8829738301368a2411e3",
      "name":"Moonwell",
      "symbol":"WELL",
      "decimals":18,
      "logoURI":"/images/tokens/well.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"WELL/GLMR"
  },
  "0x91b11E7649614EaBe97D96d75bE11d1068059FF1":{
    "id":17,
    "token0":{
      "id":"0xdFA46478F9e5EA86d57387849598dbFB2e964b02",
      "name":"MAI",
      "symbol":"MAI",
      "decimals":18,
      "logoURI":"/images/tokens/MAI.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"MAI/GLMR"
  },
  "0x015c6B2d98969e3bF066110769E53D734e48Ebf6":{
    "id":18,
    "token0":{
      "id":"0x322E86852e492a7Ee17f28a78c663da38FB33bfb",
      "name":"Frax",
      "symbol":"FRAX",
      "decimals":18,
      "logoURI":"/images/tokens/FRAX.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"FRAX/GLMR"
  },
  "0xD3DFb90f7996a97f9f394E130342485e37DD28F7":{
    "id":19,
    "token0":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "token1":{
      "id":"0xFffFFFFF4C1cbCd97597339702436d4F18a375Ab",
      "name":"Interlay",
      "symbol":"INTR",
      "decimals":10,
      "logoURI":"/images/tokens/INTR.png"
    },
    "name":"StellaSwap",
    "symbol":"xcINTR/GLMR"
  },
  "0xd95cab0Ed89269390f2aD121798e6092Ea395139":{
    "id":20,
    "token0":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "token1":{
      "id":"0xfFfFFFFF52C56A9257bB97f4B2b6F7B2D624ecda",
      "name":"Acala Dollar",
      "symbol":"aUSD",
      "decimals":12,
      "logoURI":"/images/tokens/aUSD.png",
      "bridgeVia":"xc"
    },
    "name":"StellaSwap",
    "symbol":"aUSD/GLMR"
  },
  "0x4C5f99045AF91D2b6d4fa0Ea89FC47cF42711555":{
    "id":21,
    "token0":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "token1":{
      "id":"0xFFFFFfFf5AC1f9A51A93F5C527385edF7Fe98A52",
      "name":"Interlay BTC",
      "symbol":"iBTC",
      "decimals":8,
      "logoURI":"/images/tokens/iBTC.png",
      "bridgeVia":"xc"
    },
    "name":"StellaSwap",
    "symbol":"iBTC/GLMR"
  },
  "0x420aaA13722A191765D86bc55212A54D9f8b5ceb":{
    "id":22,
    "token0":{
      "id":"0xCBABEe0658725b5B21e1512244734A5D5C6B51D6",
      "name":"Athos Finance",
      "symbol":"ATH",
      "decimals":18,
      "logoURI":"/images/tokens/ATH.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"ATH/GLMR SLP"
  },
  "0x48F2D5f75Ce88a1acd262658d06ba8500D171e2c":{
    "id":24,
    "token0":{
      "id":"0x568e579A447ae1C6fEf3c7c0456C947Cb63aBC3e",
      "name":"Crystal",
      "symbol":"CRYSTL",
      "decimals":18,
      "logoURI":"/images/tokens/CRYSTL.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"CRYSTL/GLMR SLP"
  },
  "0x00870B0e6994fFb142a91173a882d2F6a9a8Ac4a":{
    "id":25,
    "token0":{
      "id":"0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0",
      "name":"Lido DAO",
      "symbol":"LDO",
      "decimals":18,
      "logoURI":"/images/tokens/LDO.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"LDO/GLMR SLP"
  },
  "0x4EfB208eeEb5A8C85af70e8FBC43D6806b422bec":{
    "id":26,
    "token0":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "token1":{
      "id":"0xFFfffFFecB45aFD30a637967995394Cc88C0c194",
      "name":"POOP",
      "symbol":"POOP",
      "decimals":18,
      "logoURI":"/images/tokens/POOP.png"
    },
    "name":"StellaSwap",
    "symbol":"POOP/GLMR SLP"
  },
  "0x8CCBbcAF58f5422F6efD4034d8E8a3c9120ADf79":{
    "id":28,
    "token0":{
      "id":"0x931715FEE2d06333043d11F658C8CE934aC61D0c",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"wh"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"USDC.wh/GLMR SLP"
  },
  "0x8577273FB3B72306F3A59E26ab77116f5D428DAa":{
    "id":29,
    "token0":{
      "id":"0xab3f0245B83feB11d15AAffeFD7AD465a59817eD",
      "name":"Ethereum",
      "symbol":"ETH",
      "decimals":18,
      "logoURI":"/images/tokens/eth.png",
      "bridgeVia":"wh"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"ETH.wh/GLMR SLP"
  },
  "0xf8f5E8B9Ee84664695B14862212D8092E16331F6":{
    "id":30,
    "token0":{
      "id":"0xE57eBd2d67B462E9926e04a8e33f01cD0D64346D",
      "name":"Wrapped BTC",
      "symbol":"WBTC",
      "decimals":8,
      "logoURI":"/images/tokens/wbtc.png",
      "bridgeVia":"wh"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"WBTC.wh/GLMR SLP"
  },
  "0x7F5Ac0FC127bcf1eAf54E3cd01b00300a0861a62":{
    "id":0,
    "token0":{
      "id":"0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2",
      "name":"StellaSwap",
      "symbol":"STELLA",
      "decimals":18,
      "logoURI":"/images/tokens/stella.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0xAcc15dC74880C9944775448304B263D191c6077F":{
    "id":1,
    "token0":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"WGLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"Wrapped GLMR",
    "symbol":"WGLMR"
  },
  "0x8BC3CceeF43392B315dDD92ba30b435F79b66b9e":{
    "id":2,
    "token0":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "token1":{
      "id":"0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73",
      "name":"Tether USD",
      "symbol":"USDT",
      "decimals":6,
      "logoURI":"/images/tokens/usdt.png",
      "bridgeVia":"multi"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x5Ced2f8DD70dc25cbA10ad18c7543Ad9ad5AEeDD":{
    "id":3,
    "token0":{
      "id":"0x765277eebeca2e31912c9946eae1021199b39c61",
      "name":"Dai Stablecoin",
      "symbol":"DAI",
      "decimals":18,
      "logoURI":"/images/tokens/dai.png"
    },
    "token1":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0xAc2657ba28768FE5F09052f07A9B7ea867A4608f":{
    "id":4,
    "token0":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "token1":{
      "id":"0xc9baa8cfdde8e328787e29b4b078abf2dadc2055",
      "name":"Binance",
      "symbol":"BNB",
      "decimals":18,
      "logoURI":"/images/tokens/bnb.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x555B74dAFC4Ef3A5A1640041e3244460Dc7610d1":{
    "id":5,
    "token0":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x81e11a9374033d11Cc7e7485A7192AE37D0795D6":{
    "id":6,
    "token0":{
      "id":"0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2",
      "name":"StellaSwap",
      "symbol":"STELLA",
      "decimals":18,
      "logoURI":"/images/tokens/stella.png"
    },
    "token1":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x49a1cC58dCf28D0139dAEa9c18A3ca23108E78B3":{
    "id":7,
    "token0":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "token1":{
      "id":"0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
      "name":"Ethereum",
      "symbol":"ETH",
      "decimals":18,
      "logoURI":"/images/tokens/eth.png",
      "bridgeVia":"multi"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x367c36dAE9ba198A4FEe295c22bC98cB72f77Fe1":{
    "id":8,
    "token0":{
      "id":"0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F",
      "name":"Binance USD",
      "symbol":"BUSD",
      "decimals":18,
      "logoURI":"/images/tokens/busd.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x2AB268c6025F7Cd76A4B6a8A5bc7fE780Fbbfe98":{
    "id":9,
    "token0":{
      "id":"0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2",
      "name":"StellaSwap",
      "symbol":"STELLA",
      "decimals":18,
      "logoURI":"/images/tokens/stella.png"
    },
    "token1":{
      "id":"0x4792c1ecb969b036eb51330c63bd27899a13d84e",
      "name":"Avalanche",
      "symbol":"AVAX",
      "decimals":18,
      "logoURI":"/images/tokens/avax.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x2F412C140080039eaF9dE01c95e84cFb1c8D98aD":{
    "id":10,
    "token0":{
      "id":"0x4792c1ecb969b036eb51330c63bd27899a13d84e",
      "name":"Avalanche",
      "symbol":"AVAX",
      "decimals":18,
      "logoURI":"/images/tokens/avax.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x4CDf77828b839506bF6B6e219017B65623042400":{
    "id":11,
    "token0":{
      "id":"0x922d641a426dcffaef11680e5358f34d97d112e1",
      "name":"Wrapped BTC",
      "symbol":"WBTC",
      "decimals":8,
      "logoURI":"/images/tokens/wbtc.png",
      "bridgeVia":"multi"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x6775ff3828a19EB5926C0C4D572183cA15Aa4C08":{
    "id":12,
    "token0":{
      "id":"0x3405a1bd46b85c5c029483fbecf2f3e611026e45",
      "name":"Polygon",
      "symbol":"MATIC",
      "decimals":18,
      "logoURI":"/images/tokens/matic.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0xF69F219ad163d66e7300731AF3C6b9E5B44954B9":{
    "id":13,
    "token0":{
      "id":"0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2",
      "name":"StellaSwap",
      "symbol":"STELLA",
      "decimals":18,
      "logoURI":"/images/tokens/stella.png"
    },
    "token1":{
      "id":"0x3405a1bd46b85c5c029483fbecf2f3e611026e45",
      "name":"Polygon",
      "symbol":"MATIC",
      "decimals":18,
      "logoURI":"/images/tokens/matic.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x0Aa48bF937ee8F41f1a52D225EF5A6F6961e39FA":{
    "id":14,
    "token0":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "token1":{
      "id":"0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
      "name":"Ethereum",
      "symbol":"ETH",
      "decimals":18,
      "logoURI":"/images/tokens/eth.png",
      "bridgeVia":"multi"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x2Afab635EA2DC4B498EF1C00E63b7a7dBa9c93C6":{
    "id":15,
    "token0":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "token1":{
      "id":"0xc19281f22a075e0f10351cd5d6ea9f0ac63d4327",
      "name":"Fantom",
      "symbol":"FTM",
      "decimals":18,
      "logoURI":"/images/tokens/FTM.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0x077Fc7B4455050249D7F5511ED588a665E03e6C5":{
    "id":16,
    "token0":{
      "id":"0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "name":"USD Coin",
      "symbol":"USDC",
      "decimals":6,
      "logoURI":"/images/tokens/usdc.png",
      "bridgeVia":"multi"
    },
    "token1":{
      "id":"0xc19281f22a075e0f10351cd5d6ea9f0ac63d4327",
      "name":"Fantom",
      "symbol":"FTM",
      "decimals":18,
      "logoURI":"/images/tokens/FTM.png"
    },
    "name":"StellaSwap",
    "symbol":"SLP"
  },
  "0xE2BFE2F1437f249B15B7111011219995ecc43155":{
    "id":17,
    "token0":{
      "id":"0x31DAB3430f3081dfF3Ccd80F17AD98583437B213",
      "name":"Terra",
      "symbol":"LUNC",
      "decimals":6,
      "logoURI":"/images/tokens/LUNA.png"
    },
    "token1":{
      "id":"0xAcc15dC74880C9944775448304B263D191c6077F",
      "name":"Moonbeam",
      "symbol":"GLMR",
      "decimals":18,
      "logoURI":"/images/tokens/glmr.png"
    },
    "name":"StellaSwap",
    "symbol":"LUNC/GLMR SLP"
  },
  "0x": {
    "token0": {
      "id": "0xffffffffa922fef94566104a6e5a35a4fcddaa9f",
      "symbol": "ACA.xc",
    },
    "token1": {
      "id": "0xAcc15dC74880C9944775448304B263D191c6077F",
      "symbol": "GLMR",
    },
  },
}