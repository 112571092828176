import { gql } from "graphql-request";

export const Query_All_Reserves = gql`
  query AAVE($provider: String!) {
    priceOracles {
      usdPriceEth
    }
    reserves(where: { pool: $provider }) {
      id
      underlyingAsset
      symbol
      name
      decimals
      borrowingEnabled
      isFrozen
      isActive
      usageAsCollateralEnabled
      pool {
        id
        active
        paused
      }
      price {
        id
        priceInEth
        priceSource
      }
      liquidityRate
      averageStableRate
      variableBorrowRate

      availableLiquidity
      stableBorrowRate
      totalLiquidity

      totalATokenSupply
      totalCurrentVariableDebt
      totalScaledVariableDebt

      aEmissionPerSecond
      vEmissionPerSecond
      sEmissionPerSecond

      aToken {
        id
        underlyingAssetAddress
        underlyingAssetDecimals
      }
    }
  }
`;

export const Query_User_Reserves = gql`
  query AAVE($user: String!) {
    priceOracles {
      usdPriceEth
    }
    userReserves(where: { user: $user }) {
      id
      currentTotalDebt
      currentStableDebt
      scaledATokenBalance
      principalStableDebt
      currentATokenBalance
      lastUpdateTimestamp
      usageAsCollateralEnabledOnUser
      scaledVariableDebt
      stableBorrowLastUpdateTimestamp
      reserve {
        id
        symbol
        name
        decimals
        totalLiquidity
        reserveInterestRateStrategy
        stableBorrowRateEnabled: stableBorrowRate
        variableBorrowRate
        variableBorrowIndex
        totalScaledVariableDebt
        liquidityRate
        underlyingAsset
        lastUpdateTimestamp
        stableDebtLastUpdateTimestamp
        liquidityIndex
        aToken {
          id
        }
        usageAsCollateralEnabled
        price {
          oracle {
            usdPriceEth
          }
          priceInEth
        }
      }
      user {
        id
        borrowedReservesCount
        unclaimedRewards
        lifetimeRewards
        reserves {
          id
        }
      }
    }
  }
`;
