import styled from "styled-components";

export default function SLoading(props) {
  return (
    <Loading {...props}>
      <div></div>
      <div></div>
      <div></div>
    </Loading>
  );
}

const Loading = styled.div`
  display: block;
  font-size: 0;
  color: #fff;
  &,
  & > div {
    position: relative;
    box-sizing: border-box;
  }
  div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    width: 6px;
    height: 6px;
    margin: 4px;
    border-radius: 100%;
    animation: ball-beat 0.7s -0.15s infinite linear;
  }

  div:nth-child(2n-1) {
    animation-delay: -0.5s;
  }

  @keyframes ball-beat {
    50% {
      opacity: 0.2;
      transform: scale(0.75);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
