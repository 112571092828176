import mainAddress from "./address";
import MOVR from "../../assets/chain/Moonbeam.png";
import MoonbaseImg from "img/newHome/moonbase.png";
import Dapps from "../dapps";

export default {
  name: "Moonbeam",
  simple: "moonbeam",
  mainAddress: mainAddress,
  multicall: "0xEB41a8cbdAe9ed2CFf456988C421B48AaD5a2E9D",
  // aave not support
  aaveGraph: "",
  aaveLendingPool: "",
  aaveLendingPoolProvider: "",
  // swap
  swapApp: "StellaSwap",
  swapRouter: "0x70085a09D30D6f8C4ecF6eE10120d1847383BB57",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  multiSignDefaultSupportTokens: ["0x931715fee2d06333043d11f658c8ce934ac61d0c"],
  tokenLogo: MOVR,
  scan: {
    img: MoonbaseImg,
    host: "https://moonbeam.moonscan.io/",
  },
  chainId: 1284,
  chainIdHex: "0x504",
  chainName: "Moonbeam",
  nativeCurrency: {
    name: "GLMR",
    symbol: "GLMR",
    decimals: 18,
    logoURI:
      "https://raw.githubusercontent.com/stellaswap/assets/main/native/glmr.png",
  },
  wrappedToken: "0xacc15dc74880c9944775448304b263d191c6077f",
  logo: MOVR,
  rpcUrls: ["https://rpc.api.moonbeam.network"],
  blockExplorerUrls: ["https://moonbeam.moonscan.io/"],
  walletConnectDapps: [
    {
      name: "StellaSwap",
      link: "https://app.stellaswap.com/exchange/swap",
      logo: Dapps.Stellaswap,
    },
    {
      name: "Sushi",
      link: "https://app.sushi.com/swap?chainId=1287",
      logo: Dapps.Sushi,
    },
    {
      name: "Curve",
      link: "https://curve.fi/#/ethereum/swap",
      logo: Dapps.Curve,
    },
  ],
};
