import styled from "styled-components";
import { DepositContent, Line } from "./style";
import {
  useMemo,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import MathInput from "./mathInput";
import { STATUS } from "./status";
import DiscountText from "components/common/discount";
import TokenSelect from "./tokenTextSelect";
import { NFTTokenAvatar } from "components/common/avatar";
import moment from "moment/moment";
import { getShortDisplay } from "utils/publicJs";
import { InputAmountStyled, LoadingStyled } from "./style";
import SLoading from "components/common/sloading";

const DefaultTolerance = 0.5;

export default forwardRef(function NFTBondingCurve(
  {
    t,
    status,
    stockToken,
    fundToken,
    fundTokenList,
    setBalanceEnough,
    stockContract,
    loadingFund,
  },
  ref
) {
  const [tokenAmount, setTokenAmount] = useState();

  const [fundAmount, setFundAmount] = useState();
  const [tolerance, setTolerance] = useState();
  const [averagePrice, setAveragePrice] = useState(0);
  const [newPrice, setNewPrice] = useState(0);
  const [discount, setDiscount] = useState(false);

  const [selectFundIdx, setSelectFundIdx] = useState(0);
  const [loading, setLoading] = useState(false);

  const selectFund = useMemo(() => {
    return fundTokenList[selectFundIdx];
  }, [selectFundIdx, fundTokenList]);

  const showFull = useMemo(() => {
    return status === STATUS.Default;
  }, [status]);

  const getNewPrice = async () => {
    const data = await stockContract.estimateExByDemand(
      fundToken.address,
      ethers.utils.parseUnits("1", 0)
    );
    const v = ethers.utils.formatUnits(data, fundToken.deci);
    setNewPrice(v);
    setDiscount(Number(v) !== Number(stockToken.price));
  };

  const minAmount = useMemo(() => {
    if (!tokenAmount || !stockToken) {
      return 0;
    }
    const _tolerance = tolerance || DefaultTolerance; 
    return BigNumber(tokenAmount)
      .multipliedBy(BigNumber(100 - _tolerance))
      .dividedBy(BigNumber(100))
      .toFixed(0);
  }, [tokenAmount, stockToken, tolerance]);

  const expireAt = useMemo(() => {
    if (stockToken.DiscountExpire) {
      return moment(ethers.BigNumber.from(stockToken.DiscountExpire)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
  }, [stockToken]);

  const handleTokenAmount = (v, type) => {
    if (v === undefined || v === null) {
      setTokenAmount();
      return;
    }
    const value = Number(getShortDisplay(v));
    setTokenAmount(value);
    if (type === "button") {
      handleFundAmount(value);
    }
  };

  const handleFundAmount = async (_tokenAmount) => {
    setLoading(true);
    if (!fundToken || !stockContract || !_tokenAmount) return;
    try {
      const data = await stockContract.estimateExByDemand(
        fundToken.address,
        ethers.utils.parseUnits(String(_tokenAmount), 0)
      );
      const f = Number(ethers.utils.formatUnits(data, fundToken.deci));
      setFundAmount(f);
      const a = getShortDisplay(
        BigNumber(f).dividedBy(BigNumber(_tokenAmount)).toString(),
        fundToken.deci
      );
      setAveragePrice(a);
    } catch (error) {
      console.error("estimateExByDemand", error);
      setFundAmount(0);
      setAveragePrice(0);
    } finally {
      setLoading(false);
    }
  };

  const onChangeTolerance = (v) => {
    if (v === undefined || v === null) {
      setTolerance();
    } else if (v <= 0 || v >= 100) {
      setTolerance();
    } else {
      setTolerance(v);
    }
  };

  useEffect(() => {
    if (!fundToken || !stockContract || !stockToken) return;
    getNewPrice();
  }, [fundToken, stockContract, stockToken]);

  useEffect(() => {
    if (
      !fundAmount ||
      !selectFund ||
      selectFund.balance.eq(ethers.BigNumber.from(0))
    ) {
      setBalanceEnough(true);
      return;
    }
    const amountBN = ethers.utils.parseUnits(
      String(fundAmount),
      selectFund.deci
    );
    setBalanceEnough(amountBN.gt(selectFund.balance) ? true : false);
  }, [fundAmount, selectFund]);

  useImperativeHandle(ref, () => ({
    data: {
      token: selectFund,
      useNative: selectFund?.isNative,
      amount: fundAmount,
      tokenAmount: tokenAmount,
      minAmount,
    },
  }));

  return (
    <DepositContent className="font-regular">
      <NFTImage>
        <NFTTokenAvatar src={stockToken?.image} size="76px" />
        <span>{stockToken?.name}</span>
      </NFTImage>
      <CurveLine>
        <span className="left discount">
          <span className="leftPrice">{t("Period.Price")}</span>
          {discount && <DiscountText />}
        </span>
        <div className="price doublePrice">
          <div>
            <span className="strong newPrice">{newPrice}</span>
            {fundToken?.symbol}
          </div>
          {showFull && (
            <div>
              {t("BondingCurve.OriginalPrice")} {stockToken?.price}{" "}
              {fundToken?.symbol}
            </div>
          )}
        </div>
      </CurveLine>

      {showFull && (
        <>
          {!!stockToken.properties &&
            Object.keys(stockToken.properties).map((attr, val) => (
              <CurveLine key={val}>
                <span className="left">{attr}</span>
                <span className="price">{stockToken.properties[attr]}</span>
              </CurveLine>
            ))}
          <CurveLine>
            <span className="left">{t("BondingCurve.DiscountEndDate")}</span>
            <span className="price">{expireAt}</span>
          </CurveLine>
        </>
      )}
      <CurveLine>
        <span className="left">{t("Period.NftAmount")}</span>
        {showFull ? (
          <MathInput
            value={tokenAmount}
            onChange={handleTokenAmount}
            onBlur={() => handleFundAmount(tokenAmount)}
          />
        ) : (
          <span className="price">{tokenAmount}</span>
        )}
      </CurveLine>
      <CurveLine>
        <span className="left">{t("BondingCurve.SlippageTolerance")}</span>
        {showFull ? (
          <InputBox>
            <InputAmountStyled
              controls={false}
              precision={2}
              min={0}
              max={100}
              placeholder={DefaultTolerance}
              value={tolerance}
              onChange={onChangeTolerance}
            />
            <span>%</span>
          </InputBox>
        ) : (
          <span className="price">{tolerance || DefaultTolerance}%</span>
        )}
      </CurveLine>
      {showFull ? (
        <>
          <CurveLine>
            <span className="left">{t("BondingCurve.AveragePrice")}</span>
            <span className="price average">
              {loading ? (
                <LoadingStyled size="small" />
              ) : (
                <span className="averagePrice">{averagePrice} </span>
              )}
              <span>{fundToken?.symbol}</span>
            </span>
          </CurveLine>
          <Divider />
          <BalanceLine>
            <span className="left">{t("Period.MintAmount")}</span>
            <span className="select">
              <span className="amount">
                {loading ? <LoadingStyled size="small" /> : fundAmount}
              </span>
              <TokenSelect
                list={fundTokenList || []}
                selectIdx={selectFundIdx}
                onSelect={setSelectFundIdx}
                up={true}
              />
            </span>
          </BalanceLine>
          <Line>
            <span />
            <div className="balance">
              <span style={{marginRight: "5px"}}>{t("Balance")}: </span>
              {loadingFund ? (
                <SLoading />
              ) : (
                <span>
                  {selectFund?.balanceDisplay} {selectFund?.symbol}
                </span>
              )}
            </div>
          </Line>
          <TipBpx>{t("BondingCurve.PriceTip")}</TipBpx>
        </>
      ) : (
        <CurveLine>
          <span className="left">{t("Period.MintAmount")}</span>
          <span className="minAmount">
            {fundAmount} {selectFund?.symbol}
          </span>
        </CurveLine>
      )}
    </DepositContent>
  );
});

const NFTImage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  span {
    margin-left: 13px;
    font-size: 16px;
    margin-top: -15px;
  }
`;

const CurveLine = styled(Line)`
  margin-bottom: 28px;
  .doublePrice {
    text-align: right;
    .newPrice {
      margin-right: 3px;
    }
  }
  .strong {
    font-family: "Poppins-Bold";
    font-size: 18px;
  }
  .origin {
    opacity: 0.5;
  }
  .discount {
    display: flex;
    opacity: 1;
    span.leftPrice {
      opacity: 0.5;
    }
    & > span {
      margin-right: 5px;
    }
  }
  .average {
    display: flex;
    align-items: center;
    .averagePrice {
      margin-right: 5px;
    }
  }
`;

const BalanceLine = styled(Line)`
  margin-bottom: 4px;
  .select {
    display: flex;
    align-items: center;
    .amount {
      margin-right: 5px;
      font-size: 18px;
      color: #45beff;
      font-family: "Rubik-Medium";
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background: #ffffff;
  margin-bottom: 28px;
`;

const TipBpx = styled.div`
  width: 100%;
  background: rgba(255, 164, 209, 0.15);
  border-radius: 8px;
  padding: 10px 12px;
  box-sizing: border-box;
  color: #ffa4d1;
  font-size: 12px;
  line-height: 16px;
`;

const InputBox = styled.div`
  width: 160px;
  height: 40px;
  background: rgba(4, 23, 53, 0.5);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  input {
    width: 120px;
    background: transparent;
    height: 40px;
    margin-left: 13px;
    box-sizing: border-box;
    color: #fff;
  }
  span {
    margin-right: 12px;
  }
`;
