import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TimeImg from "../../assets/images/time.svg";
import RefreshImg from "../../assets/images/Refreshicon.svg";
import RiseImg from "../../assets/images/Rise.svg";

import BackNav from "components/common/backNav";
import NewVote from "components/modal/newVote";
import AddImg from "../../assets/images/icon-add.svg";
import FlagImg from "assets/images/FlagBlue.svg";
import Button from "components/common/button";

export default function Period() {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);


  const CloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleModal = useCallback(() => {
    setShowModal(true);
  }, []);

  return (
    <Box>
      {showModal && (
        <NewVote
          showVoteModal={showModal}
          selectedAction={true}
          selectedType={5}
          CloseModal={CloseModal}
        />
      )}
      <BtnBox>
        <BackNav to="app_settings" />
        <Button primary onClick={handleModal}>
          <span>{t("Period.StartNow")} </span>
          <img src={AddImg} alt="" />
        </Button>
      </BtnBox>

      <ContentBox id="contentBox">
        <div id="inner">
          <div>
            <TitleBox>
              <img src={FlagImg} alt="" />
              {t("Period.StartRedemption")}
            </TitleBox>
            <TipsBox>{t("Period.RedemptionText")}</TipsBox>
            <UlBox>
              <li>
                <div>
                  <img src={TimeImg} alt="" />
                  <div className="title font-medium">
                    {t("Period.Redemption")}
                  </div>
                  <div className="desc">
                    {t("Period.Desc101")}
                    <span className="font-bold">{t("Period.Desc102")}</span>
                    {t("Period.Desc103")}{" "}
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <img src={RefreshImg} alt="" />
                  <div className="title font-medium">
                    {t("Period.IntroTitle2")}
                  </div>
                  <div className="desc">
                    {t("Period.Desc201")}
                    <span className="font-bold">{t("Period.Desc202")}</span>
                    {t("Period.Desc203")}{" "}
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <img src={RiseImg} alt="" />
                  <div className="title font-medium">
                    {t("Period.IntroTitle3")}
                  </div>
                  <div className="desc">
                    {t("Period.Desc301")}
                    <span className="font-bold">{t("Period.Desc302")}</span>
                    {t("Period.Desc303")}
                    <span className="font-bold">
                      {t("Period.Desc304")}
                    </span>{" "}
                    {t("Period.Desc305")}
                  </div>
                </div>
              </li>
            </UlBox>
          </div>
        </div>
      </ContentBox>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;


const ContentBox = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  flex: 1;
  box-sizing: border-box;
  padding: 30px;
  box-sizing: border-box;
  background: rgba(255,255,255,0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  #inner {
    overflow-y: auto;
    height: 100%;
    position: relative;
  }
`;

const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button{
    width: 238px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    img{
      width: 24px;
      margin-left: 4px;
    }
  }
`;

const UlBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  li {
    width: 31%;
    box-sizing: border-box;
    background: #041735;
    border-radius: 16px;
    text-align: center;
    padding: 24px 20px;
    .title {
      font-size: 18px;
      font-family: "Poppins-Medium";
      font-weight: 500;
      color: #FFFFFF;
      line-height: 27px;
      margin: 20px 0 12px;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #5D6B7E;
      line-height: 18px;
      span {
        color: #47BCFF;
      }
    }
  }

`;

const TitleBox = styled.div`
  font-size: 20px;
  font-family: "Poppins-SemiBold";
  font-weight: 600;
  color: #FFFFFF;
  line-height: 30px;
  img {
    margin-right: 8px;
    position: relative;
    top: 5px;
  }
`;

const TipsBox = styled.div`
  width: 567px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 18px;
  opacity: 0.32;
  margin: 12px 0 38px;
`;
