import { useState, useEffect, useMemo, useCallback, memo } from "react";
import styled from "styled-components";
import { toChecksumAddress } from "ethereumjs-util";
import { useLocation } from "react-router-dom";
import { Multicall } from "ethereum-multicall";
import CopyStr from "../../copy";

import OngoingImg from "assets/images/vote/ongoing.svg";
import AgreeImg from "assets/images/vote/success.svg";
import RejectedImg from "assets/images/vote/fail.svg";

import ConfirmImg from "assets/images/Success.svg";
import WrongImg from "assets/images/Failed.svg";

import moment from "moment";
import { useDaoContext } from "views/dao/provider";
import { useAppContext } from "components/provider/appProvider";
import {
  useVaultContract,
} from "hooks/useContract";
import { ORG_MANAGER, VAULT_MANAGER } from "utils/constant";
import useOrgMembers from "hooks/useOrgMembers";
import useSigner from "hooks/useSigner";

import { useWeb3React } from "@web3-react/core";
import Avatar from "components/common/avatar";
import { ethers } from "ethers";

import api from "api";
import { useTranslation } from "react-i18next";
import Link from "components/common/LinkIcon";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import useCheckLogin from "hooks/useCheckLogin";
import { useClientContext } from "components/provider/clientProvider";
import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import ConfirmModal from "components/modal/confirmModal";
import { GlassBG } from "assets/styles/common";
import {
  Block,
  AddressBox as HashBox,
} from "components/newVersionVote/detail/styles";
import AddressBox from "components/common/addressBox";

import { SubgraphApiQuery, getTxDetail, CHAIN_NFT_SOURCE } from "api/graphql/subQuery";
import { formatTextTime } from "utils/utils";
import useMulticallTxs from "components/apps/walletConnect/useMulticallTxs";
import BackNav from "components/common/backNav";
import { useNetworkData } from "hooks/useNetwork";

export const UserTxSTATUS = {
  Default: 0,
  Confirmed: 1,
  Rejected: 2,
};

export const TxSTATUS = {
  Default: 0,
  Confirmed: 1,
  Rejected: 2,
};

export default function VoteDetail(props) {
  const { id: txId, txHash: hash } = props;
  const { t } = useTranslation();

  const { chainId, account, provider } = useWeb3React();
  const {
    state: {
      componentAddressMap,
      daoChainId,
      daoId,
      whoAmI: { isOwner, isGP },
    },
  } = useDaoContext();
  const {
    state: { exploreScan, txTransactions },
    dispatch,
  } = useAppContext();

  const signer = useSigner();
  const orgMembersMap = useOrgMembers(componentAddressMap.get(ORG_MANAGER));
  const vaultContract = useVaultContract(
    componentAddressMap.get(VAULT_MANAGER),
    daoChainId
  );

  const { pathname, state: lastNav } = useLocation();

  const [txData, setTxData] = useState({});

  const [voters, setVoters] = useState([]);
  const [createAt, setCreateAt] = useState("");
  const [txHash, setTxHash] = useState("");

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);

  const [action, setAction] = useState();

  const { getClient } = useClientContext();

  const networkData = useNetworkData(chainId);

  const multicall = useMemo(() => {
    if (!provider) {
      return;
    }
    const _config = {
      ethersProvider: provider,
      tryAggregate: true,
    };
    if (networkData.multicallCustomContractAddress) {
      _config.multicallCustomContractAddress =
        networkData.multicallCustomContractAddress;
    }
    return new Multicall(_config);
  }, [provider]);

  const [getTxData, fetchLoading] = useMulticallTxs(
    multicall,
    componentAddressMap.get(VAULT_MANAGER),
    account
  );

  const getTxFromGraph = async () => {
    const client = getClient();
    try {
      const resp = await client.request(SubgraphApiQuery, {
        chainId,
        query: getTxDetail(daoId, txId),
      });
      const data = JSON.parse(resp.subgraphApi).data;
      console.log("=====> [getDetail]", data);
      setVoters(
        (data.voters || []).map((v) => ({
          address: v.sender,
          support: v.state,
        }))
      );
      if (data.info) {
        setTxHash(data.info.txHash);
        setCreateAt(formatTextTime(data.info.createAt));
      }
    } catch (error) {}
  };

  const getUserState = async () => {
    if (!account) {
      return;
    }
    const data = await vaultContract.senderConfirm(txId, account);
    setAction(data);
  };

  const getTxFromContract = async () => {
    const data = await vaultContract.proposals(ethers.BigNumber.from(txId));
    setTxData({
      state: data.period === 1 ? 2 : data.state, // 0-default,1-execute success,2-execute fail
      id: txId,
      agree_count: data.confirmCount.toNumber(),
      oppose_count: data.rejectCount.toNumber(),
      source: data.desc,
      creator: data.creator,
      data: data.data,
      createAt: "Just now",
    });
    // const txMap = await getTxData([txId]);
    // const data = txMap[txId];
    // console.log("txData", data);
    // let source = data.source;
    // if (data.source === "nft") {
    //   source = CHAIN_NFT_SOURCE[chainId];
    // }
    // setTxData({ ...data, createAt: "Just now", source });
    // setAction(data.senderState);
  };

  useEffect(() => {
    if (!account) {
      return;
    }
    if (!voters.length && txData.senderState) {
      setVoters([{ address: account, support: txData.senderState === 1 }]);
    }
  }, [voters, account]);

  useEffect(() => {
    if (!provider || !txId || !componentAddressMap.get(VAULT_MANAGER)) {
      return;
    }
    getTxFromGraph();
    getTxFromContract();
    getUserState();
  }, [provider, txId, componentAddressMap]);

  const dataBytes = useMemo(() => {
    if (txData.data) {
      try {
        return ethers.utils.arrayify(txData.data).length + "bytes";
      } catch (error) {
        return txData.data;
      }
    }
    return "";
  }, [txData]);

  const voteStatusIcon = useMemo(() => {
    switch (txData.state) {
      case 1:
        return AgreeImg;
      case 2:
        return RejectedImg;
      default:
        return OngoingImg;
    }
  }, [txData]);

  const showConfirm = (pending) => {
    if (pending) {
      return;
    }
    setConfirmVisible(true);
  };

  const showReject = (pending) => {
    if (pending) {
      return;
    }
    setRejectVisible(true);
  };

  const closeConfirm = useCallback(() => {
    setConfirmVisible(false);
  }, []);

  const closeReject = useCallback(() => {
    setRejectVisible(false);
  }, []);

  const handleConfirm = async (flag) => {
    try {
      const res = await api.vault.confirmTransaction(vaultContract, signer, {
        voteId: txId,
        flag,
      });

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        flag
          ? t("Notification.proposalApprove")
          : t("Notification.proposalReject"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.ConfirmProposal,
          data: {
            daoId,
            support: flag,
            txId,
            pathname,
            account,
          },
        },
      });
      dispatch({
        type: "APPEND_MULTISIGN_TX",
        payload: {
          id: txId,
          hash: txHash,
          daoId,
          chainId,
          account,
        },
      });
      confirmVisible && setConfirmVisible(false);
      rejectVisible && setRejectVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: "LOADINGTYPE",
      payload: fetchLoading ? t("Loading") : null,
    });
  }, [fetchLoading]);

  useSubcribe(
    TransactionEvent.DoWalletConnect,
    (_, { daoId: _daoId, txId: _txId, account: _account, support }) => {
      if (daoId === _daoId && txId === _txId) {
        getTxFromContract();
        setVoters([...voters, { address: _account, support }]);
      }
    }
  );

  const getVotePending = (txId) => {
    if (!txTransactions[daoId]) {
      return false;
    } else {
      return !!txTransactions[daoId][txId];
    }
  };

  const ActionButtons = memo(({ state, userState, isManager }) => {
    if (!isManager || state === 1 || state === 2) {
      return <></>;
    }
    if (userState === 0 && isManager) {
      const pending = getVotePending(txId);
      return (
        <BtnGroup pending={pending}>
          <div onClick={() => showConfirm(pending)}>{t("WCT.Confirm")}</div>
          <div onClick={() => showReject(pending)} className="disagree">
            {t("WCT.Reject")}
          </div>
        </BtnGroup>
      );
    }
    return <></>;
  });

  return (
    <>
      <BackNav to={`wallet_connect?tab=${lastNav || "queue"}`} />
      <ContentBox>
        <ConfirmModal
          show={confirmVisible}
          handleClose={closeConfirm}
          message={t("walletConnect.ConfirmApprove")}
          title={t("walletConnect.ConfirmTitle")}
          handleConfirm={() => handleConfirm(true)}
          confirmText={t("walletConnect.submit")}
          withoutDanger={true}
        />
        <ConfirmModal
          show={rejectVisible}
          handleClose={closeReject}
          message={t("walletConnect.RejectApprove")}
          title={t("walletConnect.RejectTitle")}
          handleConfirm={() => handleConfirm(false)}
          confirmText={t("walletConnect.RejectBtn")}
          withoutDanger={true}
        />
        <Header>
          <LftBox>
            <img style={{ width: "54px" }} src={voteStatusIcon} alt="" />
          </LftBox>
          <RhtLine>
            <dl>
              <dt>{t("WCT.Source")}</dt>
              <dd className="source">{txData.source}</dd>
            </dl>
            <dl>
              <dt>{t("WCT.Created")}</dt>
              <dd>{createAt || txData.createAt}</dd>
            </dl>
            <dl>
              <dt>{t("WCT.CR")}</dt>
              <dd className="status">{`${txData.agree_count || 0} : ${
                txData.oppose_count || 0
              }`}</dd>
            </dl>
          </RhtLine>
        </Header>
        <MainBox>
          <LeftBox>
            <GlassBG />
            <Block title={t("WCT.TxHash")}>
              <HashBox>
                {txHash || hash}
                <div className="optionGrp">
                  <CopyStr address={txHash || hash} hideTip={true} />
                  <Link hash={txHash || hash} type="tx" />
                </div>
              </HashBox>
            </Block>
            <Block title={t("WCT.Data")} className="flexBox">
              <span>{dataBytes}</span>
              <CopyStr address={txData.data} hideTip={true} />
            </Block>
            {ethers.constants.AddressZero === txData.executor && (
              <Block className="flexLine" title={t("WCT.Executed")}>
                n/a
              </Block>
            )}
          </LeftBox>
          <RightBox>
            <GlassBG />
            <Block title={t("WCT.Creator")}>
              <ItemBox>
                <Avatar size="39px" address={txData.creator} />
                <div className="manager">
                  <div className="name">
                    {txData.creator &&
                      orgMembersMap[toChecksumAddress(txData.creator)]}
                  </div>
                  <AddressBox address={txData.creator} />
                </div>
              </ItemBox>
            </Block>
            <Block
              title={`${t("WCT.Votes")} (${txData.agree_count || 0}:${
                txData.oppose_count || 0
              })`}
            >
              {voters.map((voter) => (
                <ItemLine key={`${voter.account}`}>
                  <img
                    src={voter.support ? ConfirmImg : WrongImg}
                    alt=""
                    className="agreeImg"
                  />
                  <ItemBox>
                    <Avatar size="39px" address={voter.address} />
                    <div className="manager">
                      <div>
                        {orgMembersMap[toChecksumAddress(voter.address)]}
                      </div>
                      <AddressBox address={voter.address} />
                    </div>
                  </ItemBox>
                </ItemLine>
              ))}
            </Block>
            {txData.executor &&
              ethers.constants.AddressZero !== txData.executor && (
                <Block title={t("WCT.Executor")}>
                  <ItemBox>
                    <Avatar size="39px" address={txData.executor} />
                    <div className="manager">
                      <div>
                        {txData.executor &&
                          orgMembersMap[toChecksumAddress(txData.executor)]}
                      </div>
                      <AddressBox address={txData.executor} />
                    </div>
                  </ItemBox>
                </Block>
              )}
            <ActionButtons
              state={txData.state}
              isManager={isOwner || isGP}
              userState={action}
            />
          </RightBox>
        </MainBox>
      </ContentBox>
    </>
  );
}

const ContentBox = styled.div`
  height: calc(100% - 56px);
  overflow-y: auto;
`;

const RhtLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 100px;
  flex-grow: 1;
  dt {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 4px;
    opacity: 0.5;
  }
  dd {
    font-size: 18px;
    line-height: 27px;
    font-family: "Poppins-Medium";
    &.status {
      font-family: "Rubik-Medium";
    }
  }
  .source {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const LftBox = styled.div`
  height: 54px;
  margin: 0 30px 0 23px;
`;

const MainBox = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100% - 88px);
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
  line-height: 34px;
  position: absolute;
  right: 40px;
  bottom: 36px;
  div {
    text-align: center;
    cursor: pointer;
    margin-left: 20px;
    width: 111px;
    height: 36px;
    line-height: 36px;
    background: #79dfc4;
    font-size: 14px;
    font-weight: 400;
    color: #041735;
    box-shadow: 0px 8px 20px 0px rgba(12, 11, 48, 0.16);
    border-radius: 10px;
    &.disagree {
      background: #ffa4d1;
    }
    &.action {
      background: #bfbfc1;
      color: #ffffff;
    }
  }
`;

const LeftBox = styled.div`
  position: relative;
  flex: 1;
  padding: 24px;
  margin-right: 16px;
  dl {
    position: relative;
  }
`;

const RightBox = styled.div`
  position: relative;
  width: 466px;
  padding: 24px 0 80px 80px;
  box-sizing: border-box;
  dl {
    position: relative;
  }
`;

const ItemBox = styled.div`
  display: flex;
  align-items: center;
  .manager {
    margin-left: 12px;
  }
`;
const ItemLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .agreeImg {
    width: 24px;
    margin-right: 14px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 72px;
  background: #234488;
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
