import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useMemo } from "react";
import { ethers } from "ethers";
import CopyButton from "components/copy";
import BaseModal from "components/modal/general";
import ModalFooter from "components/common/modalFooter";
import AlertImg from "img/animation/alert.svg";
import LogoSvg from "assets/images/walletConnect/logo.svg";
import { useDaoContext } from "views/dao/provider";
import { VAULT_MANAGER } from "utils/constant";

const TxSubmitModal = (props) => {
  const { t } = useTranslation();
  const { visible, onSubmit, onCancel, data, submitLoading } = props;

  const {
    state: { componentAddressMap },
  } = useDaoContext();

  const txData = useMemo(() => {
    return data || {};
  }, [data]);

  const submitTx = () => {
    onSubmit && onSubmit(txData.id);
  };
  const rejectTx = () => {
    onCancel && onCancel();
  };

  const dataBytes = useMemo(() => {
    if (txData.data && visible) {
      return ethers.utils.arrayify(txData.data).length + "bytes";
    }
    return "";
  }, [txData, visible]);

  return (
    <BaseModal
      show={visible}
      closeModal={onCancel}
      width={578}
      height={442}
      glass
    >
      <ModalHeader>
        <img src={LogoSvg} alt="" />
        <span>{t("WCT.WalletConnect")}</span>
      </ModalHeader>
      <ModalBody>
        <Line>
          <LineLabel>{t("WCT.SendingFrom")}</LineLabel>
          <SendingBox>
            <span className="name">{t("WCT.Vault")}</span>
            <span className="address">
              {componentAddressMap.get(VAULT_MANAGER)}
            </span>
          </SendingBox>
        </Line>
        <Line>
          <LineLabel>{t("WCT.Data")}</LineLabel>
          <DataBox>
            <span>{dataBytes}</span> <CopyButton address={txData.data} />
          </DataBox>
        </Line>
        <SubmitTip>
          <img src={AlertImg} alt="" />
          <span>{t("walletConnect.submitTxTip")}</span>
        </SubmitTip>
      </ModalBody>
      <ModalFooter
        t={t}
        onCancel={rejectTx}
        onSubmit={submitTx}
        submitLoading={submitLoading}
      />
    </BaseModal>
  );
};

export default TxSubmitModal;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  img {
    margin-right: 9px;
  }
`;

const ModalBody = styled.div`
  padding: 38px 16px 0;
`;

const SubmitTip = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  img {
    width: 18x;
    margin-right: 11px;
  }
`;

const Line = styled.div`
  margin-bottom: 28px;
`;

const LineLabel = styled.div`
  font-size: 14px;
  opacity: 0.5;
  margin-bottom: 12px;
`;

const SendingBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 44px;
  background: rgba(4, 23, 53, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(12px);
  padding: 0 16px;
  line-height: 44px;
  .name {
    margin-right: 30px;
    opacity: 0.5;
  }
`;

const DataBox = styled.div`
  display: flex;
  align-items: center;
`;
