import { DEV_BACKEND, PROD_BACKEND } from "utils/constant";
import { NFT_SOURCE } from "./subQuery";

const VERSION = 3;

const getUrl = (path) => {
  return (
    (process.env.NODE_ENV === "production"
      ? PROD_BACKEND.cache
      : DEV_BACKEND.cache) + path
  );
};

const handleRequest = async (
  chain_id,
  dao_id,
  page,
  size,
  where,
  module,
  enable_cache
) => {
  try {
    const resp = await fetch(getUrl("/fetch_data"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chain_id,
        dao_id,
        dao_version: VERSION,
        enable_cache,
        module,
        query: `transactionRecords(first: ${size}, skip: ${
          (page - 1) * size
        }, where: ${where}, orderBy: txID, orderDirection: desc) {
        key:txID
        txID
        source
        createAt
        state
      }`,
      }),
    });
    if (resp.status === 200) {
      const data = await resp.json();
      if (data.code !== 200) {
        return { code: data.code, msg: data.msg };
      }
      return { code: 0, data: data.data };
    } else {
      return { code: resp.status };
    }
  } catch (error) {
    return { code: -1, msg: error };
  }
};

export const getVoteList = (chain_id, dao_id, page, size) => {
  const where = `{ state: 0, source: "vote", daoID: "${dao_id}", daoVersion: ${VERSION}}`;
  return handleRequest(chain_id, dao_id, page, size, where, "vote", page === 1);
};

export const getVoteHistoryList = (chain_id, dao_id, page, size) => {
  const where = `{ state_not: 0, source: "vote", daoID: "${dao_id}", daoVersion: ${VERSION}}`;
  return handleRequest(chain_id, dao_id, page, size, where, "vote", false);
};

export const getTxList = (
  chain_id,
  dao_id,
  page,
  size,
  source,
  enable_cache
) => {
  let where;
  where = `{ state: 0, ${source}, daoID: "${dao_id}", daoVersion: ${VERSION}}`;
 
  return handleRequest(chain_id, dao_id, page, size, where, "tx", enable_cache);
};

export const putTx = (chain_id, dao_id, module, tx_id, data) => {
  return fetch(getUrl("/put_cache"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      chain_id,
      dao_id,
      dao_version: VERSION,
      module,
      key: tx_id,
      data: JSON.stringify(data),
    }),
  });
};
