import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";

import NewVote from "components/modal/newVote";

import SwapImg from "../assets/app/Transfer.svg";
import SwapImgWhite from "../assets/app/TransferWhite.svg";

import NFTImg from "../assets/app/NFT.svg";
import NFTImgWhite from "../assets/app/NFTWhite.svg";

import DeFiImg from "../assets/app/Dife.svg";
import DefiImgWhite from "../assets/app/DifeWhite.svg";

import WalletConnectImg from "../assets/app/wallet.svg";
import WalletConnectImgWhite from "../assets/app/walletWhite.svg"

import ManagersImg from "../assets/app/change-manager.svg";
import ManagersImgWhite from "../assets/app/change-managerWhite.svg";

import SuperImg from  "../assets/app/group.svg";
import SuperImgWhite from  "../assets/app/groupWhite.svg";

import Fee from "../assets/app/Management-Fee.svg";
import FeeWhite from "../assets/app/Management-Fee.svg";

import RedemptionImg from "../assets/app/swap.svg";
import RedemptionImgWhite from "../assets/app/swapWhite.svg";

import NFTWC from "img/manager/nft.png";

import { useDaoContext } from "./dao/provider";
import { useTranslation } from "react-i18next";
import { useAppContext } from "components/provider/appProvider";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "network";
import LftImg from "../assets/images/left.png";
import RhtImg from "../assets/images/right.png";

export const SETTINGS_KEY = {
  INDEX: "/",
  NFT: "nft",
  SWAP: "swap",
  DEFI: "defi",
  WCT: "wallet_connect",
  SUPER_MGR: "super_manager",
  PERIOD: "period",
};

export default function AppAndSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fullhandle, network: networkSimple } = useParams();;
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedAction, setSelectedAction] = useState(true);
  const { chainId } = useWeb3React();

  const {
    state: { whoAmI, period, isMultiSignWallet },
  } = useDaoContext();
  const { dispatch } = useAppContext();

  const canUseApp = useMemo(() => {
    if (!whoAmI || !(whoAmI.isOwner || whoAmI.isGP)) {
      return false;
    }
    if (isMultiSignWallet) {
      return true
    } else {
      return period === 2 || period === 3
    }
  }, [period, whoAmI, isMultiSignWallet]);

  // const appStyle = useMemo(() => {
  //   return canUseApp ? {} : { opacity: 1 };
  // }, [canUseApp]);
  const isRedemption = useMemo(() => {
    return period === 3;
  }, [period]);

  const isBSC = useMemo(() => {
    return chainId === SupportedChainId.BSC || chainId === SupportedChainId.BSC_TESTNET;
  }, [chainId]);

  const isMoonbeam = useMemo(() => {
    return chainId === SupportedChainId.MOONBEAM;
  }, [chainId]);

  const isPolygon = useMemo(() => {
    return chainId === SupportedChainId.POLYGON;
  }, [chainId]);

  const GemDisabled = useMemo(() => {
    return isBSC || isMoonbeam || [SupportedChainId.ZKSYNC_TESTNET, SupportedChainId.ZKSYNC].includes(chainId);
  }, [chainId]);

  const DefiDisabled = useMemo(() => {
    return (
      isBSC ||
      isMoonbeam ||
      [
        SupportedChainId.ZKSYNC_TESTNET,
        SupportedChainId.ZKSYNC,
        SupportedChainId.ARBITRUM_ONE,
      ].includes(chainId)
    );
  }, [chainId]);

  const toGo = (prefix, key) => {
    if (!canUseApp) {
      return;
    }

    if (GemDisabled && key === SETTINGS_KEY.NFT) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.ComingSoon"), closable: true },
      });
      return;
    }

    if (DefiDisabled && key === SETTINGS_KEY.DEFI) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.ComingSoon"), closable: true },
      });
      return;
    }

    if (isRedemption) {
      if (key === SETTINGS_KEY.PERIOD) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: t("Msg.VoteDisabled"), closable: true },
        });
        return;
      }
    }

    navigate(`/${networkSimple}/${fullhandle}/${prefix}/${key}`);
  };
  const CloseModal = () => {
    setShowModal(false);
  };

  const handleModal = (num) => {
    if (!canUseApp) {
      return;
    }
    // check period
    setSelectedType(num);
    if (num === "addRemove") {
      if (isRedemption) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: t("Msg.VoteDisabled"), closable: true },
        });
        return;
      }
      setSelectedAction(false);
    } else {
      setSelectedAction(true);
    }
    setShowModal(true);
  };
  return (
    <Content id="contentBox">
      <div id="inner">
        <Box>
          {showModal && (
            <NewVote
              showVoteModal={showModal}
              selectedAction={selectedAction}
              selectedType={selectedType}
              CloseModal={CloseModal}
            />
          )}
          <TitleBox>{t("Settings.Apps")}</TitleBox>
          <LineBox>
            <UlBox>
              <li
                onClick={() => toGo("app", SETTINGS_KEY.SWAP)}
                className={!canUseApp ? "notAllowed" : ""}
              >
                {/*<div className="dot" />*/}
                <LftBg />
                <RhtBg />
                <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                  <img src={!canUseApp ? SwapImgWhite : SwapImg} alt="" />
                </ImgBox>
                <VaText>{t("Settings.Swap")}</VaText>
                <Desc>{t("Settings.SwapText")}</Desc>
              </li>
              <li
                style={{ position: "relative" }}
                onClick={() => toGo("app", SETTINGS_KEY.DEFI)}
                disabled={!canUseApp ? false : DefiDisabled}
                className={!canUseApp ? "notAllowed" : ""}
              >
                <LftBg />
                <RhtBg />
                {/*<div className="dot" />*/}
                <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                  <img src={!canUseApp ? DefiImgWhite : DeFiImg} alt="" />
                </ImgBox>
                <VaText>{t("Settings.DeFi")}</VaText>
                <Desc>{t("Settings.DeFiText")}</Desc>
              </li>
            </UlBox>
          </LineBox>
          <TitleBox>{t("Settings.Settings")}</TitleBox>
          <LineBox>
            {isMultiSignWallet ? (
              <UlBox>
                <li
                  onClick={() => handleModal("addRemove")}
                  disabled={!canUseApp ? false : isRedemption}
                  className={!canUseApp ? "notAllowed" : ""}
                >
                  <LftBg />
                  <RhtBg />
                  {/*<div className="dot" />*/}
                  <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                    <img
                      src={!canUseApp ? ManagersImgWhite : ManagersImg}
                      alt=""
                    />
                  </ImgBox>
                  <VaText>{t("Settings.ChangeManagers")}</VaText>
                  <Desc>{t("Settings.ChangeManagersText")}</Desc>
                </li>
                <li
                  onClick={() => toGo("settings", SETTINGS_KEY.SUPER_MGR)}
                  className={!canUseApp ? "notAllowed" : ""}
                >
                  {/*<div className="dot" />*/}
                  <LftBg />
                  <RhtBg />
                  <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                    <img src={!canUseApp ? SuperImgWhite : SuperImg} alt="" />
                  </ImgBox>
                  <VaText>{t("Settings.SuperManager")}</VaText>
                  <Desc>{t("Settings.SuperManagerText")}</Desc>
                </li>
                <li style={{ visibility: "hidden" }} />
                <li style={{ visibility: "hidden" }} />
              </UlBox>
            ) : (
              <UlBox>
                <li
                  onClick={() => handleModal(4)}
                  className={!canUseApp ? "notAllowed" : ""}
                >
                  {/*<div className="dot" />*/}
                  <LftBg />
                  <RhtBg />
                  <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                    <img src={!canUseApp ? FeeWhite : Fee} alt="" />
                  </ImgBox>
                  <VaText>{t("Settings.ManagementFee")}</VaText>
                  <Desc>{t("Settings.ManagementFeeText")}</Desc>
                </li>
                <li
                  onClick={() => handleModal("addRemove")}
                  disabled={!canUseApp ? false : isRedemption}
                  className={!canUseApp ? "notAllowed" : ""}
                >
                  <LftBg />
                  <RhtBg />
                  {/*<div className="dot" />*/}
                  <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                    <img
                      src={!canUseApp ? ManagersImgWhite : ManagersImg}
                      alt=""
                    />
                  </ImgBox>
                  <VaText>{t("Settings.ChangeManagers")}</VaText>
                  <Desc>{t("Settings.ChangeManagersText")}</Desc>
                </li>
                <li
                  onClick={() => toGo("settings", SETTINGS_KEY.SUPER_MGR)}
                  className={!canUseApp ? "notAllowed" : ""}
                >
                  {/*<div className="dot" />*/}
                  <LftBg />
                  <RhtBg />
                  <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                    <img src={!canUseApp ? SuperImgWhite : SuperImg} alt="" />
                  </ImgBox>
                  <VaText className="title font-bold">
                    {t("Settings.SuperManager")}
                  </VaText>
                  <Desc>{t("Settings.SuperManagerText")}</Desc>
                </li>
                <li
                  onClick={() => toGo("settings", SETTINGS_KEY.PERIOD)}
                  disabled={!canUseApp ? false : isRedemption}
                  className={!canUseApp ? "notAllowed" : ""}
                >
                  <LftBg />
                  <RhtBg />
                  {/*<div className="dot" />*/}
                  <ImgBox className={!canUseApp ? "bgWhite" : ""}>
                    <img
                      src={!canUseApp ? RedemptionImgWhite : RedemptionImg}
                      alt=""
                    />
                  </ImgBox>
                  <VaText>{t("Settings.Redemption")}</VaText>
                  <Desc>{t("Settings.RedemptionText")}</Desc>
                </li>
              </UlBox>
            )}

            {/*{!canUseApp && <div className="mask" />}*/}
          </LineBox>
          {!canUseApp && (
            <NotAvaliableTip>
              <span className="strong">{t("Settings.TipFront")} </span>
              {t("Settings.TipBack")}
            </NotAvaliableTip>
          )}
        </Box>
      </div>
    </Content>
  );
}

const Content = styled.div`
  flex-grow: 1;
  /* overflow-y: auto; */
  height: 100%;
  padding: 0 0 20px;
  #inner {
    overflow-y: auto;
    position: relative;
    height: 100%;
  }
`;

const Box = styled.div`
  height: 100%;
`;

const TitleBox = styled.div`
  font-size: 20px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #FFFFFF;
  padding-bottom: 22px;
  opacity: 0.72;
`;

const LineBox = styled.div`
  position: relative;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
  }
`;
const UlBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  .nftwc {
    width: 27px;
  }
  li {
    width: 281px;
    box-sizing: border-box;
    border-radius: 16px;
    background: rgba(255,255,255,0.08);
    border: 1px solid rgba(255,255,255,0.08);
    backdrop-filter: blur(20px);
    margin-right: 16px;
    margin-bottom: 20px;
    padding: 20px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    &:hover{
      background: linear-gradient(136deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.13) 100%);
    }
    &:last-child {
      margin-right: 0;
    }
    &.notAllowed{
      cursor: not-allowed;
      background: rgba(255,255,255,0.1);
      border-radius: 16px;
      opacity: 0.5;
      border: 1px solid rgba(255,255,255,0.1);
      backdrop-filter: blur(20px);
      img{
        opacity: 0.1;
      }
      .bgWhite{
        background: rgba(255,255,255,0.08);
      }
    }
    //.title {
    //  font-size: 16px;
    //  font-weight: bold;
    //  color: #10164b;
    //  line-height: 22px;
    //  padding: 13px 0 3px;
    //}
    //.dot {
    //  position: absolute;
    //  top: 24px;
    //  right: 17px;
    //  width: 9px;
    //  height: 9px;
    //  background: #d51172;
    //  border-radius: 9px;
    //}
  }

`;
const Mask = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: flex-end;
`;

const TipsBox = styled.div`
  margin-bottom: 30px;
  width: 817px;
  height: 35px;
  background: #fcf6f9;
  border-radius: 8px;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  color: #d51172;
  span {
    font-weight: 600;
  }
`;

const NotAvaliableTip = styled.div`
  width: 100%;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 0 18px;
  color: #45beff;
  .strong {
    font-family: "Poppins-Bold";
  }
`;

const Coming = styled.div`
  color: #0c1651;
`;

const VaText = styled.div`
  font-size: 20px;
  font-family: "Poppins-SemiBold";
  font-weight: 600;
  color: #FFFFFF;
  margin-top: 28px;
  white-space: nowrap;
`;

const ImgBox = styled.div`
  width: 48px;
  height: 48px;
  background: rgba(50, 108, 255, 0.28);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 36px;
  }
`

const Desc = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 21px;
  opacity: 0.5;
`
const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`
const RhtBg = styled.div`
  width: 245px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;
`
