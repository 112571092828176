import React, { useReducer, createContext, useContext } from "react";

const DaoContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DAO_ID":
      return { ...state, daoId: action.payload };
    case "SET_DAO_CHAIN":
      return { ...state, daoChainId: action.payload };
    case "SET_FULL_HANDLE":
      return { ...state, fullHandle: action.payload };
    case "SET_BASE_DATA":
      return { ...state, baseData: action.payload };
    case "SET_OWNER":
      return { ...state, owner: action.payload };
    case "SET_PERIOD":
      return { ...state, period: action.payload };
    case "SET_COMPONENTS_ADDRESS":
      return { ...state, componentAddressMap: action.payload };
    case "SET_FUND_INFO":
      return { ...state, fundInfo: action.payload };
    case "SET_FUND_TOKEN":
      return { ...state, fundToken: action.payload };
    case "SET_MULTI_SIGN":
      return { ...state, isMultiSignWallet: action.payload };
    case "SET_STOCK_TOKEN":
      return { ...state, stockToken: action.payload };
    case "SET_MANAGE_FEE_TOKEN":
      return { ...state, manageFeeToken: action.payload };
    case "SET_MANAGE_FEE":
      return { ...state, manageFee: action.payload };
    case "SET_CARRIED_INTEREST":
      return { ...state, carriedInterest: action.payload };
    case "WhO_AM_I":
      return { ...state, whoAmI: action.payload };
    case "SET_VAULT_TOKENS":
      return { ...state, vaultTokens: action.payload };
    case "SET_VOTE_MIN_SUPPORT":
      return { ...state, minRequire: action.payload };

    case "UPDATE_FUND_OR_PERIOD":
      return { ...state, fundOrPeriod: action.payload };
    case "SET_UNDONE_VOTES":
      return { ...state, hasVotes: action.payload };
    case "SET_UNDONE_WCT":
      return { ...state, hasWct: action.payload };
    case "SET_PERIOD_STATUS":
      return { ...state, periodStatus: action.payload };
    case "SET_FUND_STATUS":
      return { ...state, fundStatus: action.payload };
    case "SET_STOCK_STATUS":
      return { ...state, stockStatus: action.payload };
    case "SET_CLAIM_STATUS":
      return { ...state, claimStatus: action.payload };
    case "SET_TAV_STATUS":
      return { ...state, tavStatus: action.payload };
    case "SET_TOKEN_DISCOUNT":
      return { ...state, tokenDiscount: action.payload };
    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};

const INIT_STATE = {
  baseData: {},
  whoAmI: {},
  componentAddressMap: new Map(),
  vaultTokens: [],
  minSupport: 0,
  // event
  votes: [],
  isMultiSignWallet: true,
};

const DaoProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  return (
    <DaoContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DaoContext.Provider>
  );
};

export const useDaoContext = () => ({ ...useContext(DaoContext) });

export default DaoProvider;
