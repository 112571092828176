import styled from "styled-components";

export const FlexRow = styled.div`
  display: flex;
  margin-bottom: 28px;
  position: relative;
  &.flex {
    display: flex;
  }
`;

export const Block = styled.div`
  flex: 1;
  margin-left: 50px;
  &:first-child {
    margin-left: 0;
  }
`;

export const AddButton = styled.button`
  width: 108px;
  height: 36px;
  background: linear-gradient(311deg, #61e1ff 0%, #326cff 100%);
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: #FFFFFF;
`;

export const CardBG = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(32px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;