import styled from "styled-components";
import Link from "components/svg/dao/link";
import NoneNFT from "img/common/none.png";
import useVaultNFTs from "hooks/useVaultNFTs";
import { useAppContext } from "components/provider/appProvider";
import EmptyBox from "components/common/empty";
import useGateway from "hooks/useGateway";
import React, { useCallback, useState, memo, useEffect } from "react";
import ETH from "img/newHome/eth.png";
import { useDaoContext } from "views/dao/provider";
import api from "api";
import { useDaoOrgContract, useVaultContract } from "hooks/useContract";
import { DAO_ORG, VAULT_MANAGER } from "utils/constant";
import useSigner from "hooks/useSigner";
import CloseSVG from "components/svg/common/close";
import CheckInput from "components/common/checkInput";
import Radio from "components/svg/common/radio";
import GoldSVG from "components/svg/shape/gold";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import LoadingBox from "components/common/loading";
import useNativeCurrency from "../../hooks/useNativeCurrency";
import {useWeb3React} from "@web3-react/core";
import { useNetworkData } from "hooks/useNetwork";
import LinkImg from "../../assets/images/link.svg";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import { formatImgUrl } from "utils/utils";
import { getShortDisplay } from "utils/publicJs";
import LftImg from "../../assets/images/left.png";
import RhtImg from "../../assets/images/right.png";
import TokenImg from "../../assets/images/token.svg";
import AddImg from "../../assets/images/icon-add.svg";
import BaseModal from "components/modal/general";
import Button, { ButtonGroup } from "components/common/button";

export default function NFT() {
  const { t } = useTranslation();
  const {
    state: { exploreScan },
    dispatch,
  } = useAppContext();
  const gateway = useGateway();

  const {
    state: {
      daoId,
      daoChainId,
      componentAddressMap,
      whoAmI: { isOwner },
    },
  } = useDaoContext();

  const vaultContract = useVaultContract(
    componentAddressMap.get(VAULT_MANAGER),
    daoChainId
  );
  const daoOrg = useDaoOrgContract(
    componentAddressMap.get(DAO_ORG),
    daoChainId
  );
  const signer = useSigner();
  const { provider, account} = useWeb3React();

  const network = useNetworkData(daoChainId);

  const { list, loading } = useVaultNFTs();

  const [showModal, setShowModal] = useState(false);
  const [safe721Map, setSafe721Map] = useState({});
  const [safe1155Map, setSafe1155Map] = useState({});

  const get721 = useCallback(async () => {
    try {
      const data = await vaultContract.getNFT721List();
      const map = {};
      data.forEach((d) => {
        map[d.toLowerCase()] = true;
      });
      setSafe721Map(map);
      console.log(">>>>getNFT721List", data);
    } catch (error) {
      console.error("getNFT721List failed",error);
    }
  }, [vaultContract, daoChainId]);

  const get1155 = useCallback(async () => {
    try {
      const data = await vaultContract.getNFT1155List();
      const map = {};
      data.forEach((d) => {
        map[d.toLowerCase()] = true;
      });
      setSafe1155Map(map);
      console.log(">>>>getNFT1155List", data);
    } catch (error) {
      console.error("getNFT1155List failed", error);
    }
    
  }, [vaultContract, daoChainId]);

  useEffect(async () => {
    if (vaultContract && daoChainId) {
      get721();
      get1155();
    }
  }, [vaultContract, daoChainId]);

  const handleAdd = async (data) => {
    // dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });
    try {
      const res = await api.vault.addNFT(daoOrg, signer, data);

      setShowModal(false);

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.AddNFT"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.AddNFT,
          data: { daoId },
        },
      });
    } catch (error) {
      console.error(error);
      // showNotification(NotificationType.Fail, t("Notification.AddNFTFailed"));
    }
    // dispatch({ type: "LOADINGTYPE", payload: null });
  };

  // event
  useSubcribe(TransactionEvent.AddNFT, (_, { daoId: _daoId }) => {
    if (_daoId === daoId) {
      get721();
      get1155();
    }
  });

  return (
    <Box>
      {isOwner && (
        <BtnBox>
          <Button primary onClick={() => setShowModal(true)}>
            <span>{t("Portfolio.AddNFT")}</span>
            <img src={AddImg} alt="" />
          </Button>
        </BtnBox>
      )}
      {showModal && (
        <AddNFTModal
          t={t}
          closeModal={() => setShowModal(false)}
          handleAdd={handleAdd}
        />
      )}
      {loading ? (
        <LoadingBox loading={loading} />
      ) : !list.length ? (
        <EmptyBox />
      ) : (
        <NFTBox>
          <TypeBox isOwner={isOwner}>
            {list.map((item, index) => (
              <li key={index}>
                <LftBg />
                <RhtBg />

                {item.image ? (
                  <Photo>
                    <img
                      src={formatImgUrl(item.image, gateway)}
                      alt=""
                      className="photo"
                    />
                    {!!item.is_erc721 && <TopBg>{item.amount}</TopBg>}
                    {(safe721Map[item.contract_address] ||
                      safe1155Map[item.contract_address]) && (
                      <img className="gold" src={TokenImg} alt="" />
                    )}
                  </Photo>
                ) : (
                  <NonPhoto>
                    <img src={NoneNFT} alt="" className="photo" />
                  </NonPhoto>
                )}
                <TitleBox>
                  {item.name}
                  <a
                    href={`${exploreScan}token/${item.contract_address}?a=${item.tokenId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={LinkImg} alt="" />
                    {/*<Link />*/}
                  </a>
                </TitleBox>
                <Description>{item.desc}</Description>
                <InfoBox>
                  {/*<span className="left">{t("Portfolio.Price")}</span>*/}
                  <img src={network?.tokenLogo} alt="" />
                  <span>{getShortDisplay(item.price)}</span>
                </InfoBox>
                {/*{item.is_erc721 ? (*/}
                {/*  <InfoBox />*/}
                {/*) : (*/}
                {/*  <InfoBox>*/}
                {/*    <span className="left">{t("Portfolio.Amount")}</span>*/}
                {/*    <span className="right">{item.amount}</span>*/}
                {/*  </InfoBox>*/}
                {/*)}*/}
              </li>
            ))}
          </TypeBox>
        </NFTBox>
      )}
    </Box>
  );
}

const AddNFTModal = memo(({ t, closeModal, handleAdd }) => {
  const [type, setType] = useState(1);
  const [address, setAddress] = useState();
  const [addressError, setAddressError] = useState();

  useEffect(() => {
    if (address && !ethers.utils.isAddress(address)) {
      setAddressError(t("Msg.InvalidAddressTip"));
    } else {
      setAddressError();
    }
  }, [address]);

  const confirmAdd = () => {
    if (!address || addressError) {
      return;
    }
    handleAdd({
      tokenAddress: address,
      type,
    });
  };

  return (
    <BaseModal
      show={handleAdd}
      closeModal={closeModal}
      title={t("Portfolio.AddNFT")}
      glass
    >
      <ModalContent>
        <div className="label">{t("Portfolio.Address")}</div>
        <CheckInput
          errorMsg={addressError}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <SelectBox>
          <RadioItem onClick={() => setType(1)}>
            <Radio selected={type === 1} /> <span>ERC721</span>
          </RadioItem>
          <RadioItem onClick={() => setType(2)} style={{ marginLeft: "50px" }}>
            <Radio selected={type === 2} /> <span>ERC1155</span>
          </RadioItem>
        </SelectBox>
        <ButtonGroup>
          <Button onClick={closeModal}>{t("Cancel")}</Button>
          <Button primary className="primary" onClick={confirmAdd}>
            {t("Submit")}
          </Button>
        </ButtonGroup>
      </ModalContent>
    </BaseModal>
  );
});

const Box = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NFTBox = styled.div`
  overflow: auto;
  height: ${(props) => `calc(100% - ${props.isOwner ? 60 : 0}px)`};
`

const TypeBox = styled.ul`
  display: flex;
  //align-items: center;
  align-items: stretch;
  margin: 20px 1px 0;
  flex-wrap: wrap;
  li {
    width: 23%;
    padding: 15px;
    margin-bottom: 20px;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(20px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-right: 2.6%;
    position: relative;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .gold {
      position: absolute;
      right: 6px;
      top: 6px;
      z-index: 99;
    }
    &:hover {
      background: linear-gradient(
        136deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.13) 100%
      );
    }
  }
`;
const SymbolBox = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .symbol {
    font-size: 14px;
    font-weight: 400;
    color: #969bb2;
  }
`;

const LftBox = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
    margin: 0 10px 0 0;
  }
`;

const Photo = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  .photo{
    width: 120px;
    height: 120px;
    border-radius: 20px;
  }
`;

const NonPhoto = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 16px;
  opacity: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  .photo{
    width: 44px;
    height: 44px;
    border-radius: 20px;
  }
`

const TextBox = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleBox = styled(TextBox)`
  font-size: 20px;
  font-family: "Poppins-SemiBold";
  font-weight: 600;
  color: #FFFFFF;
  line-height: 30px;
  margin-top: 20px;
  a {
    position: absolute;
    right: 20px;
    top: 20px;
    img{
      width: 24px;
      opacity: 0.72;
    }
  }
`;

const Description = styled(TextBox)`
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.32;
  margin-top: 4px;
`;

const Num = styled.div`
  font-size: 20px;
  color: #10164b;
  line-height: 24px;
`;

const NFTIcon = styled.img`
  width: 100%;
`;

const RtBox = styled.div`
  text-align: right;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  font-family: "Rubik-Regular";
  font-weight: 400;
  color: #FFFFFF;
  line-height: 24px;
  img {
   width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`

const BtnBox = styled.div`
  position: fixed;
  right: calc((100vw - 1240px) / 2);
  top: 235px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
  @media (max-width: 1440px) {
    top: 200px;
  }
  button {
    width: unset;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    img {
      width: 24px;
      margin-left: 4px;
    }
  }
`;

const ModalContent = styled.div`
  padding: 30px 20px 0;
  .label {
    line-height: 16px;
    margin-bottom: 12px;
    opacity: 0.5;
  }
`;

const SelectBox = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 21px;
  margin-bottom: 50px;
`;
const RadioItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 8px;
  }
`;

const ButtonGrp = styled.div`
  margin-top: 50px;
  text-align: center;
  button:first-child {
    margin-right: 30px;
    @media (max-width: 1440px) {
      margin-right: 20px;
    }
  }
  @media (max-width: 1440px) {
  margin-top: 40px;
}
`;

const MyButton = styled.button`
  width: 140px;
  height: 50px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 14px;
  border: 1px solid #d52473;
  background: #ffffff;
  color: #d52473;
  cursor: pointer;
  &.primary {
    background: #d52473;
    color: #ffffff;
  }
  @media (max-width: 1440px) {
    width: 100px;
    height: 36px;
  }
`;
const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`
const RhtBg = styled.div`
  width: 245px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;
`

const TopBg = styled.div`
  position: absolute;
  right: -14px;
  bottom: 0;
  padding: 1px 6px;
  background: rgba(255,255,255,0.16);
  border-radius: 10px;
  border: 4px solid #3E4D64;
  backdrop-filter: blur(32px);
  
  font-size: 10px;
  font-family: "Rubik-Medium";
  font-weight: 500;
  color: #FFFFFF;
`