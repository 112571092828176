import React, { useEffect, useState, useCallback } from "react";
import { InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ethers } from "ethers";

import { useDaoContext } from "../../views/dao/provider";
import api from "../../api";
import { useWeb3React } from "@web3-react/core";
import { NFTTokenAvatar, TokenAvatar } from "components/common/avatar";
import { useDaoOrgContract } from "../../hooks/useContract";
import { DAO_ORG } from "../../utils/constant";
import useSigner from "hooks/useSigner";
import { useAppContext } from "components/provider/appProvider";
import useFundLogo from "hooks/useFundLogo";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import { useMemo } from "react";
import { getShortDisplay } from "utils/publicJs";
import BaseModal from "./general";
import Max from "components/common/max";
import Button from "components/common/button";
import SwapIcon from "assets/images/Swap.svg";

export default function Withdraw(props) {
  const { show, close } = props;
  const { t } = useTranslation();

  const { provider, account } = useWeb3React();
  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();
  const { state: daoState } = useDaoContext();
  const { daoId, componentAddressMap, fundToken, stockToken } = daoState;
  const [amount, setAmount] = useState();
  const [fundAmount, setFundAmount] = useState();
  const [balance, setBalance] = useState({});

  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const signer = useSigner();

  const isNFTStock = useMemo(() => {
    return stockToken?.type === "nft";
  }, [stockToken]);

  const handleClose = () => {
    close();
  };

  const confirm = async () => {
    try {
      const res = await api.venture.withdraw(
        daoOrg,
        signer,
        isNFTStock
          ? amount
          : ethers.utils.parseUnits(String(amount), stockToken.deci)
      );
      handleClose();

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.Withdraw"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.Withdraw,
          data: { daoId, symbol: fundToken?.symbol, amount: fundAmount },
        },
      });
    } catch (error) {
      console.error(error);
      // showNotification(NotificationType.Fail, t("Notification.WithdrawFailed"));

      // dispatch({
      //   type: "MSGTYPE",
      //   payload: {
      //     msg: "withdraw failed",
      //     closable: true,
      //   },
      // });
    }
  };

  const handleChangeAmount = useCallback(
    (v) => {
      if (v === undefined || v === null) {
        setAmount(undefined);
        return;
      }
      if (v < 0) {
        setAmount(0);
      } else if (balance.balance && v > balance.balance) {
        setAmount(balance.balance);
      } else {
        setAmount(v);
      }
    },
    [fundToken, stockToken]
  );

  const goEstimate = async (_amount) => {
    try {
      const templateConfigAddress = await daoOrg.templateConfig();
      const res = await api.venture.getClaimEarningEstimate(provider, signer, {
        account,
        template: templateConfigAddress,
        amount: _amount,
      });
      // res.forEach((r, i) => {
      //   console.log(`estimate-${i}`, r.toString());
      // });
      return [res[0], res[1]];
    } catch (error) {
      console.error("getClaimEarningEstimate failed", error);
    }
  };

  const handleEstimate = async (_amount, ifBalance) => {
    const data = await goEstimate(_amount);
    if (data) {
      const balance = isNFTStock
        ? data[1].toString()
        : ethers.utils.formatUnits(data[1], stockToken?.deci);
      const maxFund = ethers.utils.formatUnits(data[0], fundToken?.deci);
      if (ifBalance) {
        setBalance({
          balance: Number(balance),
          balanceDisplay: getShortDisplay(balance, 9),
        });
        setAmount(Number(balance));
      }
      setFundAmount(getShortDisplay(maxFund, 9));
    }
  };

  useEffect(() => {
    if (!stockToken || !account || !show) {
      return;
    }
    handleEstimate(0, true);
  }, [stockToken, account, show]);

  const onBlurEstimate = async () => {
    console.log("[onBlurEstimate]amount:", amount);
    if (amount === 0) {
      setFundAmount(0);
      return;
    } else if (amount === undefined) {
      setFundAmount(undefined);
      return;
    }
    // estimate
    handleEstimate(
      isNFTStock
        ? amount
        : ethers.utils.parseUnits(String(amount), stockToken?.deci)
    );
  };

  const tokenLogo = useMemo(() => {
    if (!stockToken) {
      return <></>;
    }
    if (stockToken.type === "nft") {
      return <NFTTokenAvatar src={stockToken.image} size="24px" />;
    } else {
      return <TokenAvatar address={stockToken.address} size="24px" />;
    }
  }, [stockToken]);

  const handleMax = (bal) => {
    const v = bal.balance;
    setAmount(v);
    // estimate
    handleEstimate(isNFTStock ? v : ethers.BigNumber.from(v, stockToken?.deci));
  };

  return (
    <BaseModal
      show={show}
      closeModal={handleClose}
      width={440}
      title={t("Venture.Withdraw")}
    >
      <ModalContent>
        <InputBox>
          <div style={{ flex: 1 }}>
            <InputLabel>{t("Venture.Redeem")}</InputLabel>
            <InputStyled
              controls={false}
              placeholder={t("Create.Enter")}
              value={amount}
              precision={stockToken?.deci ? undefined : 0}
              onChange={handleChangeAmount}
              onBlur={onBlurEstimate}
            />
          </div>
          <div className="right">
            <TokenBox className="token">
              {stockToken && tokenLogo}
              <span>{stockToken && stockToken.symbol}</span>
            </TokenBox>
            <InputLabel>
              <span className="balanceTitle">{t("Balance")}: </span>
              <span className="balance">{balance.balanceDisplay}</span>
              <Max t={t} onMax={() => handleMax(balance)} />
            </InputLabel>
          </div>
        </InputBox>
        <Arrow>
          <img src={SwapIcon} alt="" />
        </Arrow>
        <InputBox>
          <div>
            <InputLabel>{t("Venture.Receiving")}</InputLabel>
            <div className="amount">{fundAmount}</div>
          </div>
          <TokenBox>
            <img src={fundToken?.logoURI} alt="" />
            <span>{fundToken && fundToken.symbol}</span>
          </TokenBox>
        </InputBox>
      </ModalContent>

      <Footer>
        <Button
          disabled={!amount || amount < 0 || amount > balance.balance}
          primary
          onClick={confirm}
          height={56}
          style={{ width: "100%" }}
        >
          {t("Venture.Withdraw")}
        </Button>
      </Footer>
    </BaseModal>
  );
}

const ModalContent = styled.div`
  margin-top: 28px;
`;

const Footer = styled.div`
  margin-top: 31px;
  button {
    border-radius: 8px !important;
  }
`;

const Arrow = styled.div`
  img {
    width: 24px;
    transform: rotate(180deg);
  }
  margin: 8px auto;
  text-align: center;
`;

const InputBox = styled.div`
  width: calc(100% - 40px);
  height: 76px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 13px 20px;

  div.amount {
    height: 27px;
    line-height: 27px;
    font-size: 20px;
    font-family: "Rubik-Medium";
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .balanceTitle {
      font-family: "Poppins-Light";
    }
    .balance {
      margin-right: 5px;
    }
  }
`;

const InputLabel = styled.div`
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  font-family: "Poppins-Light";
  color: rgba(255, 255, 255, 0.5);
  .max {
    color: #0e164e;
    line-height: 19px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
  }
`;

const InputStyled = styled(InputNumber)`
  font-size: 18px;
  width: 100%;
  &.ant-input-number {
    border: none;
    background: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  &.ant-input-number-focused {
    border: none;
    box-shadow: none;
  }
  .ant-input-number-input {
    background: transparent;
    padding: 0;
    color: #ffffff;
    font-size: 20px;
    font-family: "Rubik-Medium";
  }
`;

const TokenBox = styled.div`
  display: flex;
  align-items: center;
  &.token {
    margin-bottom: 8px;
  }
  img {
    width: 24px;
    height: 24px;
  }
  span {
    line-height: 24px;
    font-weight: 400;
    margin-left: 8px;
    font-size: 16px;
  }
`;
