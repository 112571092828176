import React, { memo } from "react";
import TabLayout from "./TabLayout";
import QueueTable from "./queueTable";

const VoteQueue = memo((props) => {
  return (
    <TabLayout>
      <QueueTable />
    </TabLayout>
  );
});
export default VoteQueue;
