import mainAddress from "./address";
import mainAddressDev from "./address_dev";
// import ETH from 'img/network/ETH.png'
import etherscan from "img/network/etherscan.png";
import polygonImg from "../../assets/chain/Polygon.png";
import Dapps from "../dapps";

export default {
  name: "Polygon",
  simple: "polygon",
  mainAddress:
    process.env.NODE_ENV === "production" ? mainAddress : mainAddressDev,
  // aave
  aaveGraph: "https://api.thegraph.com/subgraphs/name/aave/aave-v2-matic",
  aaveLendingPool: "0x8dff5e27ea6b7ac08ebfdf9eb090f32ee9a30fcf",
  aaveLendingPoolProvider: "0xd05e3e715d945b59290df0ae8ef85c1bdb684744",
  multicall: "0xcc75eE242d0aA92022Bf42468bEA4AD3f8bae3e5",
  // swap
  swapApp: "QuickSwap",
  swapRouter: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
  // nft
  nftName: "Element",
  nftLink: "https://www.element.market/polygon",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  multiSignDefaultSupportTokens: ["0xc2132d05d31c914a87c6611c10748aeb04b58e8f"],
  tokenLogo: polygonImg,
  scan: {
    img: etherscan,
    host: "https://polygonscan.com/",
  },
  chainId: 137,
  chainIdHex: "0x89",
  chainName: "polygon",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    logoURI: "https://app.aave.com/icons/networks/polygon.svg",
  },
  wrappedToken: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
  logo: polygonImg,
  rpcUrls: ["https://polygon-rpc.com"],
  blockExplorerUrls: ["https://polygonscan.com"],
  // wallet connect
  walletConnectDapps: [
    {
      name: "QuickSwap",
      link: "https://quickswap.exchange/#/swap",
      logo: Dapps.QuickSwap,
    },
    {
      name: "Uniswap",
      link: "https://app.uniswap.org/#/swap?chainId=137",
      logo: Dapps.Uniswap,
    },
    {
      name: "AAVE",
      link: "https://app.aave.com/",
      logo: Dapps.AAVE,
    },
    {
      name: "Sushi",
      link: "https://app.sushi.com/swap?chainId=137",
      logo: Dapps.Sushi,
    },
    {
      name: "Curve",
      link: "https://curve.fi/#/ethereum/swap",
      logo: Dapps.Curve,
    },
    {
      name: "Instadapp",
      link: "https://defi.instadapp.io/",
      logo: Dapps.Instadapp,
    },
    {
      name: "Balancer",
      link: "https://app.balancer.fi/#/",
      logo: Dapps.Balancer,
    },
  ],
};
