import { gql } from "@apollo/client";

export const QueryVoteQueue = gql`
  query MyQuery($chainId: Int!, $daoId: Int!, $page: Int!, $size: Int!) {
    voteQueueList(
      chainID: $chainId
      daoID: $daoId
      daoVersion: 2
      page: { page: $page, size: $size, sort_field: "vote_id", order: "desc" }
    ) {
      data {
        action
        agree_count
        creator
        start_time
        vote_time
        create_at
        dao_id
        metadata
        oppose_count
        vote_contract_address
        vote_id
      }
      page
      size
      total
    }
  }
`;

export const QueryVoteHistory = gql`
  query MyQuery($chainId: Int!, $daoId: Int!, $page: Int!, $size: Int!) {
    voteHistoryList(
      chainID: $chainId
      daoID: $daoId
      daoVersion: 2
      page: { page: $page, size: $size, sort_field: "vote_id", order: "desc" }
    ) {
      data {
        agree_count
        start_time
        vote_time
        metadata
        oppose_count
        vote_id
        status
        tx_hash
      }
      page
      size
      total
    }
  }
`;

export const QueryVoteById = gql`
  query MyQuery($chainId: Int!, $daoId: Int!, $voteId: Int!) {
    voters: castVoteList(chainID: $chainId, daoID: $daoId, daoVersion: 2, voteID: $voteId) {
      address
      support
    }
    info: vote(chainID: $chainId, daoID: $daoId, daoVersion: 2, voteID: $voteId) {
      agree_count
      create_at
      creator
      metadata
      status
      tx_hash
      vote_id
      oppose_count
      start_time
      vote_time
      executor
    }
    action: voteAction(chainID: $chainId, daoID: $daoId, daoVersion: 2, voteID: $voteId)
  }
`;

export const QueryVoteUnAuth = gql`
  query MyQuery($chainId: Int!, $daoId: Int!, $voteId: Int!) {
    voters: castVoteList(chainID: $chainId, daoID: $daoId, daoVersion: 2, voteID: $voteId) {
      address
      support
    }
    info: vote(chainID: $chainId, daoID: $daoId, daoVersion: 2, voteID: $voteId) {
      agree_count
      create_at
      creator
      metadata
      status
      tx_hash
      vote_id
      oppose_count
      start_time
      vote_time
      executor
    }
  }
`;

export const QueryVoters = gql`
  query MyQuery($chainId: Int!, $daoId: Int!, $voteId: Int!) {
    voters: castVoteList(chainID: $chainId, daoID: $daoId, daoVersion: 2, voteID: $voteId) {
      address
      support
    }
    action: voteAction(chainID: $chainId, daoID: $daoId, daoVersion: 2, voteID: $voteId)
  }
`;

export const QueryAction = gql`
  query MyQuery($chainId: Int!, $daoId: Int!, $voteId: Int!) {
    action: voteAction(chainID: $chainId, daoID: $daoId, voteID: $voteId)
  }
`;

export const CaseVote = gql`
  mutation MyMutation(
    $chainId: Int!
    $daoId: Int!
    $voteId: Int!
    $address: String!
    $support: Boolean!
  ) {
    castVote(
      chainID: $chainId
      daoID: $daoId
      daoVersion: 2
      voteID: $voteId
      address: $address
      support: $support
    )
  }
`;

export const ExecuteVote = gql`
  mutation MyMutation(
    $chainId: Int!
    $daoId: Int!
    $voteId: Int!
    $address: String!
  ) {
    executeVote(
      chainID: $chainId
      daoID: $daoId
      daoVersion: 2
      voteID: $voteId
      executor: $address
    )
  }
`;

export const CreateVote = gql`
  mutation MyMutation(
    $chainId: Int!
    $daoId: Int!
    $voteId: Int!
    $creator: String!
    $startTime: Int!
    $voteTime: Int!
    $metadata: String!
    $txHash: String!
    $voteContractAddress: String!
  ) {
    createVote(
      chainID: $chainId
      daoID: $daoId
      daoVersion: 2
      voteID: $voteId
      creator: $creator
      startTime: $startTime
      voteTime: $voteTime
      metadata: $metadata
      txHash: $txHash
      voteContractAddress: $voteContractAddress
    )
  }
`;
