import {
  memo,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ethers } from "ethers";
import { useDaoContext } from "views/dao/provider";
import DropSvg from "components/svg/common/select";
import { VAULT_MANAGER } from "utils/constant";
import api from "api";
import CheckInput from "components/common/checkInput";

import { ReceiveBox, DropBox } from "./transfer/style";
import { useAppContext } from "components/provider/appProvider";
import TransferToken from "./transfer/transferToken";
import TransferNFT from "./transfer/transferNft";
import CopyStr from "components/copy";
import { VoteItem, Label } from "./components/style";
import Select from "components/common/textSelect";
import { VoteInputBg } from "assets/styles/common";

export default forwardRef(function Transfer(props, ref) {
  const { t } = useTranslation();
  const {
    dispatch,
  } = useAppContext();

  const { state: daoState } = useDaoContext();
  const { componentAddressMap, daoId } = daoState;

  const [assetType] = useState([
    { title: "Token", value: 0 },
    { title: "NFT", value: 1 },
  ]);
  const [currentType, setCurrentType] = useState(0);

  const [toAddress, setToAddress] = useState();
  const [addressInvalidMsg, setAddressInvalidMsg] = useState();

  const transferTokenRef = useRef();
  const transferNFTRef = useRef();

  const onChangeToAddress = useCallback((e) => {
    const v = e.target.value;
    setToAddress(v);
    if (!ethers.utils.isAddress(v)) {
      setAddressInvalidMsg(t("Msg.InvalidAddress"));
    } else {
      setAddressInvalidMsg(null);
    }
  }, []);

  const changeType = useCallback((index) => {
    setCurrentType(index);
  }, []);

  const typeContent = useMemo(() => {
    if (currentType === 0) {
      return <TransferToken ref={transferTokenRef} />;
    } else if (currentType === 1) {
      return <TransferNFT ref={transferNFTRef} />;
    }
  }, [currentType]);

  const checkValid = () => {
    if (!toAddress || !ethers.utils.isAddress(toAddress)) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidRecipient"), closable: true },
      });
      return;
    }
    if (currentType === 0 && !transferTokenRef.current.checkValid()) {
      return;
    }
    if (currentType === 1 && !transferNFTRef.current.checkValid()) {
      return;
    }
    return true;
  };

  const vaultAddress = useMemo(() => {
    return componentAddressMap.get(VAULT_MANAGER);
  }, [componentAddressMap]);

  useImperativeHandle(ref, () => ({
    name: currentType === 0 ? "transfer_token" : "transfer_nft",
    checkValid,
    getVoteBytes() {
      if (currentType === 0) {
        return api.vote.newTransferTokenVote({
          toAddress,
          ...transferTokenRef.current.data,
        });
      } else if (currentType === 1) {
        return api.vote.newTransferNFTVote({
          toAddress,
          ...transferNFTRef.current.data,
        });
      }
    },
  }));

  return (
    <TransferBox>
      <VoteItem>
        <Label>{t("Vote.SendingFrom")}</Label>
        <SendBox>
          <div className="title">{t("Vote.Vault")}</div>
          <div className="address">
            {vaultAddress} <CopyStr address={vaultAddress} />
          </div>
        </SendBox>
      </VoteItem>
      <VoteItem>
        <Label>{t("Vote.AssetType")}</Label>
        <Select list={assetType} selected={currentType} onSelect={changeType} />
      </VoteItem>
      <VoteItem>
        <Label>{t("Vote.Recipient")}</Label>
        <CheckInput
          autoFocus
          value={toAddress}
          onChange={onChangeToAddress}
          errorMsg={addressInvalidMsg}
        />
      </VoteItem>
      {typeContent}
    </TransferBox>
  );
});

const TypeSelect = memo((props) => {
  const { list, onChange, selected } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(false);
    });
  }, []);

  const showList = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    setShow(true);
  };

  const choose = useCallback(
    (index, e) => {
      e.stopPropagation();
      setShow(false);
      if (index !== selected) {
        onChange(index);
      }
    },
    [selected]
  );

  return (
    <ReceiveBox onClick={(e) => showList(e)}>
      <span>{list[selected].label}</span>
      <DropSvg />
      {show && (
        <DropBox>
          {list.map((item, index) => (
            <li key={index} onClick={(e) => choose(index, e)}>
              {item.label}
            </li>
          ))}
        </DropBox>
      )}
    </ReceiveBox>
  );
});

const TransferBox = styled.div`
  dt {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 19px;
    margin-bottom: 10px;
  }
  dd {
    position: relative;
  }
  dl {
    margin-bottom: 20px;
  }
`;

const SendBox = styled.div`
  ${VoteInputBg};
  height: 77px;
  line-height: unset;
  padding-top: 20px;
  line-height: 20px;
  .address {
    display: flex;
    align-items: center;
  }
`;
