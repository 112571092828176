import { ethers } from "ethers";
import React, { useReducer, createContext, useContext } from "react";

const DefiContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CLIENT":
      return { ...state, client: action.payload };
    case "SET_RESERVES":
      return { ...state, reserves: action.payload };
    case "SET_USER_DEBT_RESERVES":
      return { ...state, userDebtReserves: action.payload };
    case "SET_USER_SUPPLY_RESERVES":
      return { ...state, userSupplyReserves: action.payload };
    case "SET_TOTAL_COLLATERAL":
      return { ...state, totalCollateralETH: action.payload };
    case "SET_USER_DATA":
      return { ...state, userData: action.payload };
    case "SET_NET_DATA":
      return { ...state, netData: action.payload };
    case "SET_USD_PRICE_ETH":
      return { ...state, usdPriceEth: action.payload };
    case "SET_USER_USD_DATA":
      return { ...state, userUsdData: action.payload };
    case "SET_USER_APY":
      return { ...state, userAPY: action.payload };
    case "SET_REQUEST_STATE":
      return { ...state, reqState: action.payload };
    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};

const INIT_STATE = {
  reserves: [],
  userReserves: [],
  totalCollateralETH: ethers.BigNumber.from(0),
  client: null,
  userData: {},
  userUsdData: {},
  netData: {},
  usdPriceEth: 0,
  userAPY: { earnedAPY: 0, debtAPY: 0 },
  userDebtReserves: [],
  userSupplyReserves: [],
  reqState: 0, // 0.default 2.requesting
};

const DefiProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  return (
    <DefiContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DefiContext.Provider>
  );
};

export const useDefiContext = () => ({ ...useContext(DefiContext) });

export default DefiProvider;
