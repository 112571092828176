import General from "../../modal/general";
import styled from "styled-components";
import { memo } from "react";
import { LoadingBox } from "components/LoadingModal";
import {useTranslation} from "react-i18next";

const SwapLoadingProgress = memo((props) => {
  const { closeModal, fromToken, toToken } = props;
  const { t } = useTranslation();

  return (
    <General width="365" height="235" closeModal={closeModal}>
      <Container>
        <LoadingBox />
        <TitleBox className="font-medium">{t("Swap.progress.Waiting")}</TitleBox>
        <Desc>
          {t("Swap.progress.Swapping")} <span className="font-medium">{fromToken.amountDisplay}</span>{" "}
          {fromToken.symbol} for{" "}
          <span className="font-medium">{toToken.amountDisplay}</span> {toToken.symbol}
        </Desc>
        <Tips> {t("Swap.progress.ConfirmTransaction")}</Tips>
      </Container>
    </General>
  );
});

export default SwapLoadingProgress;

const TitleBox = styled.div`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 16px;
`;
const Desc = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #10164b;
  line-height: 14px;
  text-align: center;
  span {
  }
`;
const Tips = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ababbd;
  line-height: 14px;
  text-align: center;
  margin-top: 8px;
`;

const Container = styled.div`
  padding-top: 30px;
`;
