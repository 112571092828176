import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

import Select from "components/common/select";

import { QueryAllDaos } from "api/graphql/explore";
import { useWeb3React } from "@web3-react/core";

import DaoItem from "components/common/daoItem";
import { useNetworkData } from "hooks/useNetwork";
import EmptyBox from "components/common/empty";
import useCheckLogin from "hooks/useCheckLogin";
import { useClientContext } from "components/provider/clientProvider";
import { useAuthContext } from "components/provider/authProvider";
import getNetworkData from "network";
import { useAppContext } from "components/provider/appProvider";
import { defaultChainId } from "network/index";
import SLoading from "components/common/sloading";

export const TAB_KEY = {
  All: 0,
  Trending: 1,
  MyDAO: 2,
  Liked: 3,
};

const DaoTable = memo((props) => {
  const { gateway } = props;
  const { t } = useTranslation();
  const { chainId, provider, connector } = useWeb3React();
  const isLogin = useCheckLogin();
  const { state: { token } } = useAuthContext();
  const {
    dispatch,
    state: { pathChainId },
  } = useAppContext();

  const useChainId = useMemo(() => {
    return pathChainId || (getNetworkData(chainId) ? chainId : defaultChainId);
  }, [chainId, pathChainId]);
  const networkData = useNetworkData(useChainId);

  const { getClient } = useClientContext();

  const [page, setPage] = useState(1);
  const [pageSize] = useState(15);
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false);

  const filters = useMemo(() => {
    return [
      { label: t("Explore.FilterCreate"), value: "create_at" },
      { label: t("Explore.TAV"), value: "tav" },
    ];
  }, []);

  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(0);

  const changeFilter = useCallback((index) => {
    setSelected(index);
    setPage(1);
    setList([]);
  }, []);

  useEffect(() => {
    if (!chainId) {
      return;
    }
    if (!getNetworkData(chainId)) {
      dispatch({ type: "SWITCH_CHAIN", payload: useChainId });
    }
  }, [chainId])

  const getUnAuthDaos = async (p, init) => {
    if (!init && loading) {
      return;
    }
    if (!init && total && list.length >= total) {
      return;
    }
    setLoading(true);
    const cp = p || page
    try {
      const client = getClient(useChainId);
      const data = await client.request(
        QueryAllDaos,
        {
          page: cp,
          size: pageSize,
          sort: filters[selected].value,
          chainId: useChainId,
          active: false,
        },
        isLogin && token
          ? {
              Authorization: token,
            }
          : {}
      );
      try {
        setList(cp === 1 ? [...data.allDaoList.data]: [...list, ...data.allDaoList.data]);
        setPage(cp + 1);
        setTotal(data.allDaoList.total)
      } catch (error) {
        console.error("parse resp failed", data);
      }
    } catch (error) {
      console.log("getUnAuthDaos failed", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPage(1)
    setList([]);
  }, [isLogin, selected, useChainId]);


  useEffect(() => {
    getUnAuthDaos(1, true);
  }, [isLogin, selected, token, useChainId])

  const handleScroll = (e) => {
    if (loading) {
      return;
    }
    const clientHeight = document.documentElement.clientHeight;
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
  
    if (clientHeight + scrollTop >= scrollHeight - 100) {
      getUnAuthDaos();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  if (list.length === 0) {
    return <EmptyBox />;
  }

  return (
    <Tab>
      {/* <Select list={filters} onChange={changeFilter} selected={selected} /> */}
      <ListBox id="scrollableDiv">
        {/* <InfiniteScroll
          dataLength={list.length}
          next={getUnAuthDaos}
          hasMore={total > list.length}
          scrollableTarget="scrollableDiv"
        > */}
        <UlBox>
          {list.map((dao, i) => (
            <DaoItem
              key={i}
              gateway={gateway}
              data={dao}
              networkSimple={networkData && networkData.simple}
            />
          ))}
        </UlBox>
        {/* </InfiniteScroll> */}
      </ListBox>
      {loading && <SLoading style={{ width: "80px", margin: "0 auto 30px" }} />}
    </Tab>
  );
});
export default DaoTable;

const Tab = styled.div`
  /* height: 100%; */
  margin-top: 30px;
`;

const ListBox = styled.div`
  /* height: 100%; */
  //padding: 50px 0;
  /* overflow: auto;
  .infinite-scroll-component {
    overflow: unset !important;
  } */
`;

const UlBox = styled.ul`
  margin-right: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
