import mainAddress from "./address";
import mainAddressDev from "./address_dev";
import BNB from "assets/chain/BNB.png";
import BscScan from "img/bscScan.jpeg";
import Dapps from "../dapps";

export default {
  name: "BSC Testnet",
  simple: "bsc_test",
  mainAddress:
    process.env.NODE_ENV === "production" ? mainAddress : mainAddressDev,
  // aave not support
  aaveGraph: "",
  aaveLendingPool: "",
  aaveLendingPoolProvider: "",
  // swap
  swapApp: "PancakeSwap",
  swapRouter: "0xdc4904b5f716Ff30d8495e35dC99c109bb5eCf81",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  multicall: "0x5F18F6f569a643Fc2Bc489531979dB86f34788FD",
  multiSignDefaultSupportTokens: ["0x931bf638fc27499506a4c1446e5f905b0ec73c81"],
  tokenLogo: BNB,
  scan: {
    img: BscScan,
    host: "https://testnet.bscscan.com/",
  },
  chainId: 97, // '0x61'
  chainName: "BSC Testnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
    logoURI: "https://tokens.pancakeswap.finance/images/symbol/bnb.png",
  },
  logo: BNB,
  wrappedToken: "0x931bf638fc27499506a4c1446e5f905b0ec73c81",
  rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
  blockExplorerUrls: ["https://explorer.bitquery.io/bsc_testnet"],
  walletConnectDapps: [
    {
      name: "PancakeSwap",
      link: "https://pancakeswap.finance/swap",
      logo: Dapps.PancakeSwap,
    },
    {
      name: "Sushi",
      link: "https://app.sushi.com/swap?chainId=56",
      logo: Dapps.Sushi,
    },
  ],
};
