import { useMemo } from "react";
import { useLocation, matchRoutes } from "react-router-dom";

const useMatchRoute = (routes) => {
  const location = useLocation();
  const result = matchRoutes(routes, location);

  return useMemo(() => {
    return result && result[0].route.id;
  }, [result]);
};

export default useMatchRoute;

const DAO_PREFIX = "/:network/:handle";
const HOMEPAGE = `${DAO_PREFIX}/dashboard`;
const PORTFOLIO = `${DAO_PREFIX}/portfolio`;
const VOTE = `${DAO_PREFIX}/vote`;
const MANAGERS = "managers";
const APP_SETTINGS = "app_settings";
const APP = "app";
const SETTINGS = "settings";
const WCT = "wallet_connect";

export const DaoRoutes = [
  {
    path: DAO_PREFIX,
    id: "dashboard",
  },
  {
    path: `${HOMEPAGE}/*`,
    id: "dashboard",
  },
  {
    path: `${PORTFOLIO}/*`,
    id: "portfolio",
  },
  {
    path: `${VOTE}/*`,
    id: "vote",
  },
  {
    path: `${DAO_PREFIX}/${MANAGERS}`,
    id: "managers",
  },
  {
    path: `${DAO_PREFIX}/${APP_SETTINGS}`,
    id: "app_settings",
  },
  {
    path: `${DAO_PREFIX}/${APP}/*`,
    id: "app_settings",
  },
  {
    path: `${DAO_PREFIX}/${SETTINGS}/*`,
    id: "app_settings",
  },
  {
    path: `${DAO_PREFIX}/${WCT}/*`,
    id: "wallet_connect",
  },
];

export const HomePageRoutes = [
  {
    path: `${HOMEPAGE}/info`,
    id: "info",
  },
  {
    path: `${HOMEPAGE}/contracts`,
    id: "contracts",
  },
];

export const PortfolioRoutes = [
  {
    path: `${PORTFOLIO}/tokens`,
    id: "tokens",
  },
  {
    path: `${PORTFOLIO}/nft`,
    id: "nft",
  },
  {
    path: `${PORTFOLIO}/investment`,
    id: "investment",
  },
  {
    path: `${PORTFOLIO}/transfer`,
    id: "transfer",
  },
];

export const VoteRoutes = [
  {
    path: `${VOTE}/queue`,
    id: "queue",
  },
  {
    path: `${VOTE}/history`,
    id: "history",
  },
  {
    path: `${VOTE}/*`,
    id: "detail",
  }
];
