import { memo } from "react";
import styled from "styled-components";
import TokenDefault from "img/common/token.png";
import makeBlockie from "ethereum-blockies-base64";

const Avatar = memo(({ address, size, ...rest }) => {
  return (
    <AvatarStyled
      alt=""
      {...rest}
      size={size}
      src={`https://avatars.dicebear.com/api/human/${address?.toLowerCase()}.svg`}
      className="avatar"
    />
  );
});
export default Avatar;

export const TokenAvatar = memo(({ address, size, ...rest }) => {
  if (!address) return <></>
  return (
    <AvatarStyled {...rest} alt="" size={size} src={makeBlockie(address)} />
  );
});

export const NFTTokenAvatar = memo(({ src, size, ...rest }) => {
  if (!src) return <></>;
  return (
    <NFTImg src={src} size={size} {...rest}>
      <div className="nftWrapper"></div>
    </NFTImg>
  );
});

export const TokenLogo = memo(({ src, size, ...rest }) => {
  return (
    <LogoStyled {...rest} alt="" size={size} src={`${src || TokenDefault}`} />
  );
});

const LogoStyled = styled.img`
  width: ${(props) => props.size || "34px"};
  height: ${(props) => props.size || "34px"};
  border-radius: 50%;
`;

const AvatarStyled = styled.img`
  width: ${(props) => props.size || "34px"};
  height: ${(props) => props.size || "34px"};
  border-radius: 50%;
  //border: 1px solid #edeef3;
`;

const NFTImg = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${(props) => props.size || "34px"};
  height: ${(props) => props.size || "34px"};
  margin-right: 10px;
  background: #0e1827;
  border-radius: 6px;
  overflow: hidden;
  .nftWrapper {
    width: 100%;
    height: 100%;
    background: ${(props) => `url(${props.src})`} no-repeat center center;
    background-size: contain;
  }
`;