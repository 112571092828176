import { memo } from "react";
import styled from "styled-components";
import LinkImg from "../../../assets/images/link.svg";
import SelectedImg from "img/Selected.png";
import CopyStr from "components/copyAddress";
import publicJs from "utils/publicJs";
import { formatBalanceDisplay } from "utils/publicJs";
import TokenDefault from "img/defaultToken.svg";
import {useTranslation} from "react-i18next";

const VaultTypeItem = memo((props) => {
    const { t } = useTranslation();
  const isIn = props.type === 2;
  return <TagBox className={isIn && "in"}>{isIn ? t("Portfolio.history.IN") : t("Portfolio.history.OUT")}</TagBox>;
});

const TokensTable = memo(({ list }) => {
    const { t } = useTranslation();
  return (
    <TableBox cellPadding="0" cellSpacing="0">
      <thead>
        <tr>
          <th>{t("Portfolio.history.No")}</th>
          <th>{t("Portfolio.history.Token")}</th>
          <th>{t("Portfolio.history.Amount")}</th>
          <th>{t("Portfolio.Type")}</th>
          <th>{t("Portfolio.history.From")}</th>
          <th>{t("Portfolio.history.To")}</th>
          {/* <th>State</th> */}
          <th>{t("Portfolio.history.Time")}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, i) => (
          <tr key={i}>
            <TD><span className="tag">{i + 1}</span></TD>
            <TD>
              <div className="c2">
                <img
                  src={item.logoURI || TokenDefault}
                  alt=""
                  className="symbol"
                />
                <span>{item.symbol}</span>
              </div>
            </TD>
            <TD>{formatBalanceDisplay(Number(item.amount))} </TD>
            <TD><VaultTypeItem type={item.vaultType} /></TD>
            <TD>
              <div className="addrBox">
                <CopyStr address={item.fromAddress} />
              </div>
            </TD>
            <TD>
              <div className="addrBox">
                <CopyStr address={item.toAddress} />
              </div>
            </TD>
            {/* <td>
              <img src={SelectedImg} alt="" className="finished" />
              Success
            </td> */}
            <td>
              {item.date}
            </td>
              <td>
                  <a href={item.link} rel="noreferrer" target="_blank">
                      <img style={{ width: "24px", marginRight:"24px" ,marginTop:"8px"}} src={LinkImg} alt="" />
                  </a>
              </td>
          </tr>
        ))}
      </tbody>
    </TableBox>
  );
});

export default TokensTable;

const TableBox = styled.table`

  td{
    color: #fff!important;
    font-size: 14px;
    font-weight: 400;
  }
  .addrBox{
    border-radius: 16px;
    border: 1px solid rgba(255,255,255,0.16);
    padding: 5px 16px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    display: inline-block;
    line-height: 18px;
    cursor: pointer;
  }
`


const TagBox = styled.span`
  width: 49px;
  height: 24px;
  display: inline-block;
  background: #F9BB5A;
  border-radius: 13px;
  text-align: center;
  line-height: 24px;
  color: #041735;
  font-size: 12px;
  &.in {
    background: #53CDD1;
    border-radius: 12px;

  }
`;

const TD = styled.td`
  > div {
    display: flex;
    align-items: center;
  }
  .symbol{
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin:0 12px;
  }
  .tag{
    width: 28px;
    height: 20px;
    background: rgba(255,255,255,0.16);
    border-radius: 10px;
    border: 1px solid rgba(255,255,255,0.08);
    backdrop-filter: blur(12px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "Rubik-Medium";
    font-weight: 500;
    color: #FFFFFF;
    line-height: 12px;
  }
`;