const SupportedChainId={
    MAINNET:1,
    ROPSTEN:3,
    RINKEBY:4,
    GOERLI:5,
    KOVAN:42,
    ARBITRUM_ONE:42161,
    ARBITRUM_RINKEBY:421611,

    OPTIMISM:10,
    OPTIMISTIC_KOVAN:69,

    POLYGON:137,
    POLYGON_MUMBAI: 80001,
    BSC: 56,
    BSC_TESTNET: 97,
    MOONVEAM: 1284,
    ZKSYNC: 324,
    ZKSYNC_TESTNET: 280,
}

export const COMMON_BASES = {
  [SupportedChainId.MAINNET]: ["DAI", "USDC", "USDT", "WBTC", "ETH"],
  [SupportedChainId.ROPSTEN]: [
    // "WETH",
  ],
  [SupportedChainId.BSC]: ["BNB", "BUSD", "BTCB"],
  [SupportedChainId.BSC_TESTNET]: ["BNB"],
  [SupportedChainId.MOONVEAM]: ["USDC", "GLMR"],
  [SupportedChainId.RINKEBY]: [
    // "WETH",
  ],
  [SupportedChainId.GOERLI]: [
    // "WETH",
  ],
  [SupportedChainId.KOVAN]: [
    // "WETH",
  ],
  [SupportedChainId.ARBITRUM_ONE]: [
    "DAI",
    "USDC",
    "USDT",
    "WBTC",
    // "WETH",
  ],
  [SupportedChainId.ARBITRUM_RINKEBY]: [
    // "WETH",
  ],
  [SupportedChainId.OPTIMISM]: ["DAI", "USDC", "USDT", "WBTC"],
  [SupportedChainId.POLYGON]: [
    // "WETH",
    "MATIC",
    "USDC",
    "DAI",
    "USDT",
    "WBTC",
  ],
  [SupportedChainId.POLYGON_MUMBAI]: [
    // "WETH",
    "MATIC",
  ],
  [SupportedChainId.ZKSYNC]: [
    "USDC",
  ],
  [SupportedChainId.ZKSYNC_TESTNET]: ["USDC"],
};
