import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Menu, Dropdown } from "antd";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";

import { NetworkList, SupportedChainId } from "../../network";

import { useAppContext } from "components/provider/appProvider";
import { walletConnect } from "components/wallet/connector";
import { useTranslation } from "react-i18next";
import { useNetworkData } from "hooks/useNetwork";
import { getJsonFileByFetch } from "api/apiHTTP";
import { LoginQuery } from "api/graphql/auth";
import { signMessage } from "utils/sign";
import DropImg from "../../assets/images/Open.svg";
import UpImg from "../../img/header/up.svg";
import { useClientContext } from "components/provider/clientProvider";
import { useAuthContext } from "components/provider/authProvider";
import getNetworkData from "network";
import useCheckLogin from "hooks/useCheckLogin";
import { useNavigate, useLocation } from "react-router-dom";
import useTokenAssets from "hooks/useTokenAssets";
import { defaultChainId } from "network/index";

const NetworkMenu = memo((props) => {
  const { t } = useTranslation();
  const { connector, chainId, account, provider } = useWeb3React();

  const {
    dispatch,
    state: { fundsMap, pathChainId },
  } = useAppContext();
  const {
    dispatchAuth,
    state: { selectedWallet, chainId: authChainId },
  } = useAuthContext();
  const isLogin = useCheckLogin();
  const { getClient } = useClientContext();
  const location = useLocation();
  const navigate = useNavigate();
  
  const getConstant = async (network) => {
    try {
      const data = await getJsonFileByFetch(
        process.env.NODE_ENV === "production"
          ? `${network.constantHost}/chain_funds.json`
          : `${network.constantHost}/chain_funds_dev.json`
      );
      dispatch({
        type: "SET_FUNDS_MAP",
        payload: data || {},
      });
    } catch (error) {
      console.error("get fundList failed", error);
    }

    

    //   try {
    //     const data = await getJsonFileByFetch(
    //       // `${network.constantHost}/tokens/1.json`
    //       `${network.constantHost}/tokens/${network.chainId}.json`
    //     );
    //     dispatch({
    //       type: "SET_ALL_TOKENS",
    //       payload: (data || []).map((token) => ({
    //         ...token,
    //         logo: token.logoURI,
    //         // logo: token.logo,
    //       })),
    //     });
    //   } catch (error) {
    //     console.error("get all tokens failed", error);
    //   }
  };

  // useEffect(() => {
  //   if (networkData && chainId && networkData.chainId !== chainId) {
  //     dispatch({
  //       type: "MSGTYPE",
  //       payload: { closable: true, msg: t("Msg.WrongChainMatch") },
  //     });
  //   }
  //   if (networkData && chainId) {
  //     // set global contract address
  //     window.mainAddress = networkData.mainAddress;
  //     getConstant(networkData);
  //   }
  // }, [networkData, chainId]);

  useEffect(() => {
    if (authChainId && pathChainId && pathChainId !== authChainId) {
      dispatchAuth({ type: "CLEAR_AUTH" });
    }
  }, [pathChainId, chainId, authChainId])

  const useChainId = useMemo(() => {
    const pathname = location.pathname;
    if (pathname.startsWith("/dao/")) {
      const fullhandle = pathname.split("/")[2];
      const arr = fullhandle.split(":");
      const network = NetworkList.find((n) => n.simple === arr[0]);
      return network && network.chainId;
    } else {
      return pathChainId || (getNetworkData(chainId) ? chainId : defaultChainId);
    }
  }, [chainId, location, pathChainId]);

  const networkData = useNetworkData(useChainId);

  useTokenAssets(useChainId, networkData);

  useEffect(() => {
    const data = getNetworkData(useChainId);
    window.mainAddress = data.mainAddress;
    if (Object.keys(fundsMap).length) {
      return;
    }
    getConstant(data);
  }, [useChainId])


  useEffect(() => {
    if (!useChainId) {
      return
    }
    const network = getNetworkData(useChainId);
    const fundlist = fundsMap[`${useChainId}`] || [];
    dispatch({
      type: "SET_FUNDLIST",
      payload: (fundlist || []).map((fund) => ({
        ...fund,
        logo: `${network.constantHost}${fund.logo}`,
      })),
    });
  }, [useChainId, fundsMap]);

  useEffect(() => {
    if (networkData && networkData.scan) {
      dispatch({ type: "SET_EXPLORE_SCAN", payload: networkData.scan.host });
    }
  }, [networkData]);

  const requestLogin = async (v) => {
    try {
      const client = getClient(chainId);
      const resp = await client.request(LoginQuery, v);
      const loginData = resp.login;
      dispatchAuth({
        type: "SET_AUTH_TOKEN",
        payload: {
          account: v.account,
          chainId: v.chainId,
          token: loginData.token,
          expireAt: Date.now() + loginData.tokenExp * 1000,
        },
      });
    } catch (error) {
      console.error("doLogin failed", error);
    } finally {
      dispatch({ type: "LOADINGTYPE", payload: null });
    }
  };
  const handleLogin = async (_chainId, _account) => {
    console.error("_chainId: ", _chainId, "_account: ", _account)
    if (!_chainId || !_account) {
      return;
    }
    if (!getNetworkData(_chainId)) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.UnsupportChain"), closable: true, }
      })
      return
    }
    dispatch({ type: "LOADINGTYPE", payload: t("Msg.Loading") });
    let signData;
    const now = Date.now();
    try {
      signData = await provider.send("personal_sign", [
        signMessage(_chainId, _account, now),
        _account,
      ]);
    } catch (error) {
      dispatch({ type: "LOADINGTYPE", payload: null });
      console.error("Sign account failed", error);
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: "Sign account failed",
          closable: true,
        },
      });
    }
    if (signData) {
      requestLogin({
        sign: signData,
        account: _account,
        timestamp: now,
        chainId: _chainId,
      });
    }
  };

  const switchChain = useCallback(
    async (chainInfo) => {
      if (pathChainId && pathChainId !== chainInfo.chainId) {
        dispatchAuth({ type: "CLEAR_AUTH" });
        window.open(`${window.location.origin}/${chainInfo.simple}`, "_self");
        return;
      }
      try {
        if (connector === walletConnect) {
          await connector.activate(chainInfo.chainId);
        } else {
          await connector.activate({
            chainId: chainInfo.chainId,
            chainName: chainInfo.chainName,
            nativeCurrency: chainInfo.nativeCurrency,
            logo: chainInfo.logo,
          });
        }
        if (location.pathname.startsWith("/dao/")) {
          navigate("/");
        }
      } catch (error) {
        if (error && error.code === -32602) {
          const p = {
            chainId: chainInfo.chainIdHex,
            chainName: chainInfo.chainName,
            nativeCurrency: chainInfo.nativeCurrency,
            rpcUrls: chainInfo.rpcUrls,
            blockExplorerUrls: chainInfo.blockExplorerUrls,
          };
          await provider.send("wallet_addEthereumChain", [
            p,
          ]);
        }
        // dispatch({
        //   type: "MSGTYPE",
        //   payload: {
        //     msg: `${t("Msg.SwitchChainError", {
        //       chainName: chainInfo.chainName,
        //     })}`,
        //     closable: true,
        //   },
        // });
      }
    },
    [connector, selectedWallet, account, chainId, location]
  );

  useEffect(() => {
    if (provider && provider.provider) {
      const _provider = provider.provider;

      const handleAccountChanged = (_account) => {
        if (!isLogin) {
          return;
        }
        dispatchAuth({type: "CLEAR_AUTH"})
        // dispatchAuth({ type: "CHANGE_WALLET", payload: undefined });
        console.warn("> changed account");
        // handleLogin(chainId, _account[0]);
      };
      const handleChainChanged = (_chainHexId) => {
        if (!isLogin) {
          return;
        }
        dispatchAuth({type: "CLEAR_AUTH"})
        // dispatchAuth({ type: "CHANGE_WALLET", payload: undefined });
        console.warn("> changed chain");
        // navigate("/");
        // handleLogin(parseInt(_chainHexId), account);
        // if (!getNetworkData(parseInt(_chainHexId))) {
        //   dispatch({"type": "SWITCH_CHAIN", payload: })
        // }
      };
      _provider.on("accountsChanged", handleAccountChanged);
      _provider.on("chainChanged", handleChainChanged);
      return () => {
        if (_provider.removeListener) {
          _provider.removeListener("accountsChanged", handleAccountChanged);
          _provider.removeListener("chainChanged", handleChainChanged);
        }
      };
    }
  }, [provider]);

  const logoColor = (_chain_id) => {
    if (_chain_id === SupportedChainId.ARBITRUM_ONE) {
      return "rgba(41, 160, 240, 0.2)";
    } else if (
      _chain_id === SupportedChainId.ZKSYNC ||
      _chain_id === SupportedChainId.ZKSYNC_TESTNET
    ) {
      return "rgba(255, 255, 255, 0.2)";
    } 
  }

  const MenuList = () => {
    return (
      <MyMenu className="myMenu">
        {NetworkList.map((item, i) => (
          <div
            key={i}
            className={
              networkData && networkData.name === item.name
                ? "menu-content selected"
                : "menu-content"
            }
            onClick={() => {
              switchChain(item);
            }}
          >
            {logoColor(item.chainId) ? (
              <ArbitrumLogo
                className="menuLogo"
                color={logoColor(item.chainId)}
              >
                <img src={item.logo} alt="" className="logo menuLogo" />
              </ArbitrumLogo>
            ) : (
              <img src={item.logo} alt="" />
            )}
            {item.name}
          </div>
        ))}
      </MyMenu>
    );
  };

  return (
    <Dropdown trigger={["click"]} dropdownRender={() => <MenuList />}>
      <LineBox>
        <Network>
          {logoColor(networkData.chainId) ? (
            <ArbitrumLogo color={logoColor(networkData.chainId)}>
              <img
                src={(networkData && networkData.logo) || NetworkList[0].logo}
                alt=""
                className="logo"
              />
            </ArbitrumLogo>
          ) : (
            <img
              src={(networkData && networkData.logo) || NetworkList[0].logo}
              alt=""
              className="logo"
            />
          )}
          {(networkData && networkData.name) || NetworkList[0].chainName}
        </Network>
        <img src={DropImg} alt="" className="img" />
      </LineBox>

      {/*<Network>*/}
      {/*  {*/}
      {/*    <img*/}
      {/*      src={(networkData && networkData.logo) || NetworkList[0].logo}*/}
      {/*      alt=""*/}
      {/*      className="logo"*/}
      {/*    />*/}
      {/*  }*/}
      {/*  {(networkData && networkData.name) || NetworkList[0].chainName}*/}
      {/*</Network>*/}
    </Dropdown>
  );
});

export default NetworkMenu;

const MyMenu = styled.div`
  margin-left: 2px;
  background: rgba(255, 255, 255, 0.08) !important;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(20px);
  overflow: hidden;
  color: #ffffff;
  padding: 10px 10px;

  .menu-content {
    padding: 0 10px;
    height: 40px;
    font-size: 14px;
    font-weight: 300;
    line-height: 40px;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      position: relative;
      top: 8px;
    }
  }
`;
const Network = styled.div`
  cursor: pointer;
  -moz-user-select: none;
  user-select: none;
  margin-top: 3px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  .logo {
    margin-top: -3px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 25px;
  }
  img.icon {
    margin-left: 10px !important;
    margin-top: -5px !important;
  }
`;

const ArbitrumLogo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ color }) => color};
  text-align: center;
  box-sizing: border-box;
  margin-right: 10px;
  &.menuLogo {
    display: inline-block;
    position: relative;
    top: 8px;
  }
  .logo {
    width: 16px !important;
    height: 16px !important;
    position: relative;
    left: 4px;
    top: 4px;
    margin-top: 0;
    border-radius: 50%;
    &.menuLogo {
      margin-right: 0;
      top: -5px;
      left: 0;
    }
  }
`;

const LineBox = styled("div")`
  padding: 6px 15px 6px 16px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  margin-right: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  box-sizing: border-box;
  &:hover {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .img {
    width: 20px;
  }
`;