import React, { memo } from "react";
import TabLayout from "./TabLayout";
import HistoryTable from "./historyTable";

const VoteHistory = memo((props) => {
  return (
    <TabLayout>
      <HistoryTable />
    </TabLayout>
  );
});
export default VoteHistory;
