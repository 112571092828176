import styled from "styled-components";
import CopyStr from "components/copy";
import Link from "./LinkIcon";
import publicJs from "utils/publicJs";

export default function TransactionBox({ address, type }) {
  return (
    <TransactionBoxStyled>
      <AddressBox>
        <span>{publicJs.AddresstoShow(address)}</span>
        <CopyStr address={address} />
      </AddressBox>
      <Link hash={address} type={type} />
    </TransactionBoxStyled>
  );
}

const TransactionBoxStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddressBox = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 5px 16px;
  margin-right: 12px;
  span {
    font-size: 14px;
    font-family: "Poppins-Light";
    font-weight: 300;
    color: #ffffff;
    line-height: 21px;
  }
  img {
    width: 24px;
  }
`;
