import styled from "styled-components";
import { ethers } from "ethers";
import { useMemo } from "react";
import { NFTTokenAvatar } from "components/common/avatar";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import {
  DepositContent,
  Line,
} from "./style";
import MathInput from "./mathInput";
import TokenSelect from "./tokenTextSelect";
import BigNumber from "bignumber.js";
import SLoading from "components/common/sloading";

export default forwardRef(function NftToken(
  { t, stockToken, fundToken, fundTokenList, setBalanceEnough, loadingFund },
  ref
) {
  const [tokenAmount, setTokenAmount] = useState();
  const [fundAmount, setFundAmount] = useState(0);
  const [selectFundIdx, setSelectFundIdx] = useState(0);

  const selectFund = useMemo(() => {
    return fundTokenList[selectFundIdx];
  }, [selectFundIdx, fundTokenList]);

  useEffect(() => {
    if (tokenAmount && stockToken?.price) {
      setFundAmount(
        BigNumber(stockToken?.price)
          .multipliedBy(BigNumber(tokenAmount))
          .toNumber()
      );
    } else {
      setFundAmount();
    }
  }, [tokenAmount]);

  useEffect(() => {
    if (
      !fundAmount ||
      !selectFund ||
      selectFund.balance.eq(ethers.BigNumber.from(0))
    ) {
      setBalanceEnough(true);
      return;
    }
    const amountBN = ethers.utils.parseUnits(
      String(fundAmount),
      selectFund.deci
    );
    setBalanceEnough(amountBN.gt(selectFund.balance) ? true : false);
  }, [fundAmount, selectFund]);

  useImperativeHandle(ref, () => ({
    data: {
      token: selectFund,
      useNative: selectFund?.isNative,
      amount: fundAmount,
      minAmount: 0,
    },
  }));

  return (
    <DepositContent className="font-regular">
      <NFTImage>
        <NFTTokenAvatar src={stockToken?.image} size="76px" />
        <span>{stockToken?.name}</span>
      </NFTImage>
      <Line>
        <span className="left">{t("Period.Price")}</span>
        <span className="price">
          {stockToken?.price} {fundToken?.symbol}
        </span>
      </Line>
      {!!stockToken.properties &&
        Object.keys(stockToken.properties).map((attr, val) => (
          <Line key={val}>
            <span className="left">{attr}</span>
            <span className="price">{stockToken.properties[attr]}</span>
          </Line>
        ))}
      <Line>
        <span className="left">{t("Period.NftAmount")}</span>
        <MathInput value={tokenAmount} onChange={setTokenAmount} />
      </Line>
      <FundLine>
        <span className="left">{t("Period.MintAmount")}</span>
        <span className="select">
          <span className="amount">{fundAmount}</span>
          <TokenSelect
            list={fundTokenList || []}
            selectIdx={selectFundIdx}
            onSelect={setSelectFundIdx}
            up={true}
          />
        </span>
      </FundLine>
      <Line>
        <span></span>
        <div className="balance">
          <span>{t("Balance")}:</span>
          {loadingFund ? (
            <SLoading />
          ) : (
            <span>{selectFund?.balanceDisplay}</span>
          )}
        </div>
      </Line>
    </DepositContent>
  );
});

const NFTImage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  span {
    margin-left: 13px;
    font-size: 16px;
    margin-top: -15px;
  }
`;


const FundLine = styled(Line)`
  margin-top: 16px;
  margin-bottom: 5px;
  .select {
    display: flex;
    align-items: center;
    .amount {
      margin-right: 5px;
      font-size: 18px;
      color: #45beff;
      font-family: "Rubik-Medium";
    }
  }
  .arrow {
    margin-right: 0;
  }
`;