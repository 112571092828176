import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

import Select from "components/common/select";

import { useLazyQuery } from "@apollo/client";
import { QueryAllDaos, QueryLikeDaos, QueryMyDaos } from "api/graphql/explore";
import { useWeb3React } from "@web3-react/core";

import PaginationBox from "components/common/pagination";
import DaoItem from "components/common/daoItem";
import { useNetworkData } from "hooks/useNetwork";
import EmptyBox from "components/common/empty";
import SLoading from "components/common/sloading";

export const TAB_KEY = {
  All: 0,
  Trending: 1,
  MyDAO: 2,
  Liked: 3,
};

const DaoTable = memo((props) => {
  const { tabKey, gateway } = props;
  const { t } = useTranslation();
  const { chainId } = useWeb3React();
  const networkData = useNetworkData(chainId);

  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(15);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const filters = useMemo(() => {
    return [
      { label: t("Explore.FilterCreate"), value: "create_at" },
      { label: t("Explore.TAV"), value: "tav" },
    ];
  }, []);

  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(0);

  const changeFilter = useCallback((index) => {
    setSelected(index);
    setPage(1)
    setList([])
  }, []);

  const [queryLikeDaos] = useLazyQuery(QueryLikeDaos, {
    onCompleted: (data) => {
      try {
        setList([...list, ...data.likeDaoList.data]);
        setPage(page + 1);
        setTotal(data.likeDaoList.total);
      } catch (error) {
        console.error("parse resp failed", data);
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log("queryLikeDaos failed", error);
      setLoading(false);
    },
  });

  const getDaos =() => {
    if (loading) {
      return;
    }
    if (total && list.length >= total) {
      return;
    }
    queryLikeDaos({
      variables: {
        page: page,
        size: pageSize,
        sort: filters[selected].value,
        chainId: chainId,
      },
      fetchPolicy: 'network-only',
    });
  }

  useEffect(() => {
    setPage(1);
    setList([]);
  }, [tabKey]);

  useEffect(() => {
    if (!chainId) {
      return;
    }
    getDaos();
  }, [chainId, tabKey, selected]);

  const handleScroll = (e) => {
    if (loading) {
      return;
    }
    const clientHeight = document.documentElement.clientHeight;
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
   
    if (clientHeight + scrollTop >= scrollHeight - 100) {
      getDaos();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  if (list.length === 0) {
    return <EmptyBox />;
  }

  return (
    <Tab>
      {/* <Select list={filters} onChange={changeFilter} selected={selected} /> */}
      <ListBox id="scrollableDiv">
        {/* <InfiniteScroll
          dataLength={list.length}
          next={getDaos}
          hasMore={list.length < 50}
          scrollableTarget="scrollableDiv"
        > */}
        <UlBox>
          {list.map((dao, i) => (
            <DaoItem
              key={i}
              gateway={gateway}
              data={{ ...dao, is_like: true }}
              networkSimple={networkData && networkData.simple}
            />
          ))}
        </UlBox>
        {/* </InfiniteScroll> */}
      </ListBox>
      {loading && <SLoading style={{ width: "80px", margin: "0 auto 30px" }} />}
    </Tab>
  );
});
export default DaoTable;

const Tab = styled.div`
  /* height: 100%; */
  margin-top: 30px;
  /* overflow: auto; */
`;

const ListBox = styled.div`
  /* height: 100%; */
  /* padding: 50px 0; */
  /* overflow: auto;
  .infinite-scroll-component {
    overflow: unset !important;
  } */
`;

const UlBox = styled.ul`
  margin-right: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;