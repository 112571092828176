import { ethers } from "ethers";
import ABI from "../abi/web3Abi/ActionVaultAave.json";
import { getHigherGas } from "utils/utils";

const getContract = async (provider) => {
  return await new ethers.Contract(window.mainAddress.actionVaultAave, ABI, provider);
};

const aaveSupply = async (daoOrg, signer, obj) => {
  const { tokenAddress, amount } = obj;
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultAave,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "address", "uint256", "uint256"],
        // [outAmount, tokenAddress, targetAmount]
        [1, tokenAddress, amount, 0]
      ),
    ]
  );
  const estimation = await daoOrg.connect(signer).estimateGas.action(templateAction);
  const higherGas = getHigherGas(estimation)
  return daoOrg.connect(signer).action(templateAction, { gasLimit: higherGas });
};

const aaveWithdraw = async (daoOrg, signer, obj) => {
  const { tokenAddress, amount } = obj;

  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultAave,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "address", "uint256", "uint256"],
        [2, tokenAddress, amount, 0]
      ),
    ]
  );
  const estimation = await daoOrg.connect(signer).estimateGas.action(templateAction);
  const higherGas = getHigherGas(estimation)
  return daoOrg.connect(signer).action(templateAction, { gasLimit: higherGas });
};

const aaveBorrow = async (daoOrg, signer, obj) => {
  const { tokenAddress, amount, mod } = obj;  // mod=1? stable mod=2? variable
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultAave,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "address", "uint256", "uint256"],
        [3, tokenAddress, amount, mod] 
      ),
    ]
  );
  const estimation = await daoOrg.connect(signer).estimateGas.action(templateAction);
  const higherGas = getHigherGas(estimation)
  return daoOrg.connect(signer).action(templateAction, { gasLimit: higherGas });
};

const aaveRepay = async (daoOrg, signer, obj) => {
  const { tokenAddress, amount, mod } = obj;  // mod=1? stable mod=2? variable
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultAave,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "address", "uint256", "uint256"],
        [4, tokenAddress, amount, mod]
      ),
    ]
  );
  const estimation = await daoOrg.connect(signer).estimateGas.action(templateAction);
  const higherGas = getHigherGas(estimation)
  return daoOrg.connect(signer).action(templateAction, { gasLimit: higherGas });
};

// https://docs.aave.com/developers/v/2.0/guides/troubleshooting-errors
export function formatError(errMsg) {
  const code = (errMsg || "").replace("execution reverted: ", "");
  switch (code) {
    case "1":
      return "AAVE: Amount must be greater than 0";
    case "2":
      return "AAVE: Action requires an active reserve";
    case "3":
      return "AAVE: Action cannot be performed because the reserve is frozen";
    case "4":
      return "AAVE: The current liquidity is not enough";
    case "5":
      return "AAVE: User cannot withdraw more than the available balance";
    case "6":
      return "AAVE: Transfer cannot be allowed";
    case "7":
      return "AAVE: Borrowing is not enabled";
    case "8":
      return "AAVE: Invalid interest rate mode selected";
    case "9":
      return "AAVE: Collateral balance is 0";
    case "10":
      return "AAVE: Health factor is lesser than the liquidation threshold";
    case "11":
      return "AAVE: There is not enough collateral to cover a new borrow";
    case "12":
      return "AAVE: Stable borrowing not enabled";
    case "13":
      return "AAVE: Collateral is same currency that is being borrowed";
    default:
      return errMsg
  }
}

export default {
  getContract,

  aaveSupply,
  aaveBorrow,
  aaveWithdraw,
  aaveRepay,
};
