import { useEffect, useCallback, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TokenAvatar, NFTTokenAvatar } from "components/common/avatar";
import { useAppContext } from "components/provider/appProvider";
import SearchImg from "assets/images/Search.svg";

import { useLazyQuery, useMutation } from "@apollo/client";
import { QueryTokens, AddMyToken } from "api/graphql/explore";
import BaseModal from "components/modal/general";
import VerticalScroller from "components/common/verticalScroller";
import { InputBackground } from "assets/styles/common";
import EmptyBox from "components/common/empty";

export default function AddModal({ show, closeModal }) {
  const { t } = useTranslation();
  const { chainId, provider } = useWeb3React();
  const {
    dispatch,
    state: { gateway },
  } = useAppContext();

  const [searchVal, setSearchVal] = useState();
  const [tokenList, setTokenList] = useState([]);
  const [showList, setShowList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [queryTokens] = useLazyQuery(QueryTokens, {
    onCompleted(data) {
      console.log("===data", data);
      setTokenList(data.tokenList.data || []);
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const [saveAddToken] = useMutation(AddMyToken, {
    onCompleted(data) {
      console.log("===data", data);
      dispatch({ type: "LOADINGTYPE", payload: null });
      closeModal();
    },
    onError(error) {
      dispatch({ type: "LOADINGTYPE", payload: null });
    },
  });

  const addToken = useCallback(
    async (token) => {
      dispatch({ type: "LOADINGTYPE", payload: t("Msg.AddingToken") });
      saveAddToken({
        variables: {
          chainId,
          daoId: token.dao_id,
          symbol: token.symbol,
          address: token.address,
          name: token.name,
          daoVersion: token.dao_version,
        },
      });
    },
    [chainId, provider, closeModal]
  );

  const updateSearchVal = useCallback(({ target: { value } }) => {
    setSearchVal(value);
  }, []);

  useEffect(() => {
    if (searchVal) {
      const v = searchVal.toLowerCase();
      const list = tokenList.filter((t) => {
        return (
          t.address.toLowerCase() === v ||
          t.name.toLowerCase().includes(v) ||
          t.symbol.toLowerCase().includes(v)
        );
      });
      setShowList(list);
    } else {
      setShowList(tokenList);
    }
  }, [tokenList, searchVal]);

  useEffect(() => {
    if (!chainId) {
      return;
    }
    queryTokens({
      variables: {
        chainId,
        page: 1,
        size: 100000,
      },
    });
    setLoading(true);
  }, [chainId]);

  return (
    <BaseModal
      show={show}
      closeModal={closeModal}
      title={t("Explore.AddToken")}
      padding="20px 0"
      glass
    >
      <SearchBox>
        <img src={SearchImg} alt="" />
        <input
          type="text"
          placeholder={t("Explore.TokenPlaceholder")}
          onChange={updateSearchVal}
        />
      </SearchBox>
      <UlBox>
        {!loading && showList.length === 0 && (
          <EmptyBox className="empty" msg={t("Empty")} pure={true} />
        )}
        <VerticalScroller>
          {showList.map((item, index) => (
            <li key={`list_${index}`} onClick={() => addToken(item)}>
              {item.et_type === 3 ? (
                <NFTTokenAvatar
                  src={gateway + item.et_logo}
                  className="avatar"
                  size="36px"
                />
              ) : (
                <TokenAvatar
                  className="avatar"
                  size="36px"
                  address={item.address}
                />
              )}

              <div>
                {item.et_type === 3 ? (
                  <div className="symbol">{item.name}</div>
                ) : (
                  <>
                    <div className="symbol">{item.symbol || item.name}</div>
                    <div className="name">{item.name}</div>
                  </>
                )}
              </div>
            </li>
          ))}
        </VerticalScroller>
      </UlBox>
    </BaseModal>
  );
}

const SearchBox = styled.div`
  ${InputBackground};
  display: flex;
  align-items: center;
  margin: 0 40px;
  box-sizing: border-box;
  width: calc(100% - 80px);
  margin: 30px 40px 10px;

  img {
    margin-right: 10px;
    width: 18px;
    opacity: 0.6;
  }
  input {
    background: transparent;
    color: #ffffff;
    flex: 1;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const UlBox = styled.div`
  height: 320px;
  .empty {
    img {
      margin-top: 20px;
    }
  }
  li {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 55px;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.04);
    }
    .avatar {
      border-radius: 50%;
      margin-right: 12px;
    }

    .symbol {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      height: 20px;
    }
    .name {
      font-size: 12px;
      font-weight: 400;
      color: #afafc0;
      line-height: 20px;
      height: 20px;
    }
  }
`;
