import styled from "styled-components";
import { useEffect, useState } from "react";
import CreateStep from "./createStep";
import Legal from "./legal";
import TriImg from "assets/images/create/tri.svg";
import "animate.css";
import RenderBG from "assets/images/create/renderBG.svg";

const Box = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(30px);
  }
  .center {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${RenderBG}) no-repeat;
    background-size: 100% 100%;
    z-index: 1;
  }
`;
const TopBox = styled.div`
  width: 580px;
  max-height: 764px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  box-sizing: border-box;
  z-index: 999999;
`;

const Orbit = styled("div")`
  width: 1000px;
  height: 1000px;
  z-index: -1;
  margin: auto;
  opacity: 70%;
  overflow: visible;
  position: relative;
  animation: 2s ease-in-out 1 grow, 90s linear 2s infinite orbit;
  will-change: orbit, grow;

  @media (prefers-reduced-motion) {
    animation-name: none;
  }

  @keyframes grow {
    from {
      transform: scale(0.05);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes orbit {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ActionBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  @keyframes anti-orbit {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;

const ItemBox = styled.div`
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-name: anti-orbit;
  animation-duration: 90s;
  position: absolute;

  &.tri_0 {
    width: ${(props) => props.size + "px"};
    height: ${(props) => props.size + "px"};
    background: #326cff;
    border-radius: 20%;
  }
  &.tri_1 {
    width: ${(props) => props.size + "px"};
    height: ${(props) => props.size + "px"};
    background: #61e1ff;
    border-radius: 100%;
  }

  &.tri_2 {
    width: ${(props) => props.size + "px"};
    height: ${(props) => props.size + "px"};
    background: url(${TriImg}) no-repeat;
    background-size: 100% 100%;
    //position: relative;
    // background-color: #E90475;
    // &:before,
    // &:after {
    //   content: '';
    //   position: absolute;
    //   background-color: #E90475;
    // }
    // &,
    // &:before,
    // &:after {
    //   width:  ${(props) => props.size + "px"};
    //   height: ${(props) => props.size + "px"};
    //   border-top-right-radius: 30%;
    // }
    // & {
    //   transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
    // }
    // &:before {
    //   transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0,-50%);
    // }
    // &:after {
    //   transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
    // }
  }
`;

export default function AnimationBg(props) {
  const [num, setNum] = useState(1);
  const [cur, setCur] = useState(0);
  const { bg, step, handleClose, state } = props;

  useEffect(() => {
    let randomNum = Math.floor(Math.random() * 10 + 15);
    setNum(randomNum);

    return () => {
      setNum(0);
    };
  }, []);

  const getRandom = () => {
    let rd = Math.random() * 100;
    return rd.toFixed(2);
  };
  const getRandomOp = () => {
    let rd = Math.random() + 0.2;
    return rd.toFixed(2);
  };
  const getRandomWidth = () => {
    let rd = Math.random() * 30 + 10;
    return rd.toFixed(2);
  };
  const handleNext = (num) => {
    handleClose && handleClose();
  };

  return (
    <Box>
      <div className="bg" />
      <ActionBox>
        <Orbit>
          {[...Array(num)].map((item, index) => (
            <ItemBox
              className={`tri_${parseInt(Math.random() * 3)}`}
              key={index}
              style={{
                left: `${getRandom()}%`,
                top: `${getRandom()}%`,
                opacity: getRandomOp(),
              }}
              size={getRandomWidth()}
            />
          ))}
        </Orbit>
      </ActionBox>
      <div className="center" />
      <TopBox>
        {step === 1 && (
          <div className="animate__animated animate__fadeIn">
            {" "}
            <CreateStep handleNext={handleNext} />
          </div>
        )}
        {step === 2 && (
          <div className="animate__animated animate__fadeIn">
            <Legal state={state} closeModal={handleClose} />
          </div>
        )}
      </TopBox>
    </Box>
  );
}
