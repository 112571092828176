import React from "react";
import { Spin } from "antd";
import styled from "styled-components";

const LoadingSpin = styled.div`
  width: 90%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function LoadingNew({ style, ...rest }) {
  return (
    <LoadingSpin style={style}>
      <Spin {...rest} />
    </LoadingSpin>
  );
}
