import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useLazyQuery } from "@apollo/client";
import { SearchDaos } from "api/graphql/explore";
import { useWeb3React } from "@web3-react/core";

import PaginationBox from "components/common/pagination";
import DaoItem from "components/common/daoItem";
import { useNetworkData } from "hooks/useNetwork";
import EmptyBox from "components/common/empty";
import { useClientContext } from "components/provider/clientProvider";
import useCheckLogin from "hooks/useCheckLogin";
import getNetworkData from "network";
import { defaultChainId } from "network/index";
import { useAppContext } from "components/provider/appProvider";

const SearchTab = memo((props) => {
  const { keyword, gateway } = props;
  const { t } = useTranslation();
  const { chainId } = useWeb3React();
  const { state: { pathChainId } } = useAppContext();

  const useChainId = useMemo(() => {
    return pathChainId || (getNetworkData(chainId) ? chainId : defaultChainId);
  }, [chainId, pathChainId]);

  const networkData = useNetworkData(useChainId);

  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(15);
  const [total, setToal] = useState(0);

  const [list, setList] = useState([]);

  const { getClient } = useClientContext();
  const isLogin = useCheckLogin();

  useEffect(() => {
    return () => {
      setList([]);
      setPage(1);
    };
  }, []);

  useEffect(async () => {
    if (isLogin && !chainId) {
      return;
    }
    const client = getClient(useChainId);

    try {
      const data = await client.request(SearchDaos, {
        page: page,
        size: pageSize,
        chainId: useChainId,
        keyword,
      });
      const search = data.searchDao;
      setToal(search.total);
      setList(search.data);
    } catch (error) {
      console.error(error);
    }
  }, [isLogin, page, pageSize, keyword, useChainId]);

  const onChangePage = useCallback((v) => {
    setPage(v);
  }, []);

  const onShowSizeChange = useCallback((c, size) => {
    setPagesize(size);
  }, []);

  return (
    <>
      <SearchLabel>{t("Explore.SearchLabel", { val: keyword })}</SearchLabel>
      <ListBox>
        <UlBox>
          {list.map((dao, i) => (
            <DaoItem
              key={i}
              gateway={gateway}
              data={dao}
              networkSimple={networkData && networkData.simple}
            />
          ))}
        </UlBox>
      </ListBox>
      {list.length ? (
        <PageBox>
          <PaginationBox
            showQuickJumper
            pageSize={pageSize}
            defaultCurrent={page}
            total={total}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
          />
        </PageBox>
      ) : (
        <EmptyBox />
      )}
    </>
  );
});
export default SearchTab;

const ListBox = styled.div`
  height: 90%;
  padding: 50px 0;
  overflow: auto;
  .infinite-scroll-component {
    overflow: unset !important;
  }
`;

const UlBox = styled.ul`
  margin-right: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const SearchLabel = styled.div`
  height: 25px;
  font-size: 18px;
  line-height: 25px;
`;

const PageBox = styled("div")`
  padding: 40px 0;
`;
