import styled from "styled-components";
import General from "components/modal/general";
import SuccessSvg from "components/svg/common/success";
import {memo} from "react"
import LinkImg from "img/newHome/link.png";
import {useTranslation} from "react-i18next";

const Done = memo(

  ({ txLink, amount, symbol, handleClose, title }) => {
      const { t } = useTranslation();
    return <General width="365" height="285" closeModal={handleClose}>
        <TopImg>
            <SuccessSvg />
        </TopImg>
        <TitleBox className="font-demiBold">{t("defi.done.done")} </TitleBox>
      <MainBox className="font-medium">You {title || "Supplied"} {amount} {symbol}</MainBox>
      <TipsBox>
        {t("defi.done.Review")}
        <a
        href={txLink}
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={LinkImg}
          style={{ width: "16px", marginLeft: "10px" }}
          alt=""
        />
      </a>
      </TipsBox>
        <ButtonBg className="font-medium" onClick={handleClose}>{t("Close")}</ButtonBg>
    </General>
}
)
export default Done

const TopImg = styled.div`
  text-align: center;
    svg{
      margin: 27px 0 14px;
      width: 34px;
      height: 34px;
    }
`

const TitleBox = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #10164B;
  line-height: 25px;
  text-align: center;
`
const MainBox = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #10164B;
  line-height: 22px;
  text-align: center;
  margin: 4px 0 8px;
`
const TipsBox = styled.div`
    text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #10164B;
  line-height: 19px;
`
const ButtonBg = styled.div`
  width: 157px;
  height: 44px;
  background: #D52473;
  border-radius: 8px;
  text-align: center;
  line-height: 44px;
  margin: 28px auto 0;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
`
