import { ethers } from "ethers";
import abi from "../abi/web3Abi/DaoOrganization.json";

async function getContract(web3Instance, daoOrgAddress) {
  return await new ethers.Contract(daoOrgAddress, abi, web3Instance);
}

export default {
  getContract,
};
