import { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import DownArrow from "img/downArrow.png";

import { ethers } from "ethers";
import RepayModal from "./modals/repayModal";
import { useDefiContext } from "components/provider/defiProvider";
import { getShortDisplay, formatBalanceDisplay } from "utils/publicJs";
import { getValueDisplay } from "utils/aave";
import EmptyBox from "components/common/empty";
import { useTranslation } from "react-i18next";
import Button, { ButtonGroup } from "components/common/button";
import LoadingBox from "components/common/loading";

export default function MyBorrow() {
  const {
    state: {
      userData,
      userUsdData,
      userDebtReserves: userReserves,
      userAPY: { debtAPY },
      reqState,
    },
  } = useDefiContext();
  const { t } = useTranslation();
  const { totalDebtETH, availableBorrowsETH, totalCollateralETH } = userData;

  const [showModal, setShowModal] = useState(false);

  const [list, setList] = useState([]);
  const [openReserve, setOpenReserve] = useState({});

  const [borrowPercent, setBorrowPercent] = useState(0);

  const balanceDisplay = useMemo(() => {
    const { totalDebtUsdVal } = userUsdData;
    if (!totalDebtUsdVal) return 0;
    return getValueDisplay(totalDebtUsdVal.toNumber(), true);
  }, [userUsdData]);

  const openRepay = useCallback((reserve) => {
    setOpenReserve(reserve);
    setShowModal(true);
  }, []);

  const closeModal = () => {
    setShowModal(false);
    setOpenReserve({});
  };

  useEffect(() => {
    if (!totalDebtETH || !availableBorrowsETH || !totalCollateralETH) {
      return;
    }
    const maxAmountETH = availableBorrowsETH.add(totalDebtETH);
    const usedAmount = totalDebtETH.mul(ethers.utils.parseEther("100"));
    if (maxAmountETH.eq(ethers.BigNumber.from("0"))) {
      setBorrowPercent(0);
    } else {
      const data = usedAmount.div(maxAmountETH);
      setBorrowPercent(getShortDisplay(ethers.utils.formatEther(data), 2));
    }
  }, [totalDebtETH, availableBorrowsETH]);

  useEffect(() => {
    const lst = [];

    userReserves?.forEach((ur) => {
      if (ur.currentTotalDebt !== "0") {
        lst.push({
          ...ur,
          debtBN: ur.amountBN,
          balance: ur.balance,
          balanceInUsd: getValueDisplay(Number(ur.balanceInUsdVal), 2),
          variableBorrowAPYDisplay: getValueDisplay(ur.variableBorrowAPY * 100),
          stableBorrowAPYDisplay: getValueDisplay(ur.stableBorrowAPY * 100),
        });
      }
    });
    setList(lst);
  }, [userReserves]);

  const debtApyDisplay = useMemo(() => {
    return debtAPY ? getShortDisplay(debtAPY * 100, 2) : 0;
  }, [debtAPY]);

  const hasDebt = useMemo(() => {
    return !!userReserves.length;
  }, [userReserves]);

  if (reqState === 2) {
    return <LoadingBox loading={true} />;
  }

  return (
    <>
      {showModal && (
        <RepayModal
          userReserve={openReserve}
          closeModal={closeModal}
          show={showModal}
        />
      )}
      <TableBox>
        {!hasDebt ? (
          <EmptyBox msg={t("defi.myBorrow.empty")} className="empty" />
        ) : (
          <>
            <GrayLine>
              <li>
                {t("defi.myBorrow.Balance")} {balanceDisplay}
              </li>
              <li>
                {t("defi.myBorrow.APY")} {debtApyDisplay}%
              </li>
              <li>
                {t("defi.myBorrow.used")} {borrowPercent}%{" "}
              </li>
            </GrayLine>
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th>{t("defi.myBorrow.Asset")}</th>
                  <th>{t("defi.myBorrow.Debt")}</th>
                  <th>{t("defi.myBorrow.APY")}</th>
                  <th style={{ textAlign: "center" }}>
                    {t("defi.myBorrow.APYType")}
                  </th>
                  <th style={{ textAlign: "center" }}>
                    {t("defi.myBorrow.Action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((r, i) => (
                  <tr key={i}>
                    <td className="info">
                      <img className="logo" src={r.logo} alt="" />
                      {r.reserve.symbol}
                    </td>
                    <td className="number">
                      {formatBalanceDisplay(Number(r.balance))}
                      <div className="tips">{r.balanceInUsd}</div>
                    </td>
                    <td className="number">
                      {r.mod === 1
                        ? r.stableBorrowAPYDisplay
                        : r.variableBorrowAPYDisplay}
                      %
                    </td>
                    <td>
                      <SelectedBox>
                        <div>
                          {r.mod === 1
                            ? t("defi.myBorrow.Stable")
                            : t("defi.myBorrow.variable")}
                        </div>
                        {/* <img src={DownArrow} alt="" /> */}
                      </SelectedBox>
                    </td>
                    <td>
                      <ButtonGroup>
                        <Button
                          primary
                          width="112"
                          onClick={() => openRepay(r)}
                        >
                          {t("defi.myBorrow.Repay")}
                        </Button>
                        <Button width="112" className="borderLine">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://app.aave.com/reserve-overview/?underlyingAsset=${r.underlyingAsset}`}
                          >
                            {t("defi.myBorrow.Details")}
                          </a>
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </TableBox>
    </>
  );
}
const TableBox = styled.div`
  position: relative;
  height: 100%;
  table {
    width: 100%;
  }
  th {
    height: 65px;
    color: #328aff;
    font-size: 16px;
    white-space: nowrap;
    text-align: left;
    &:first-child {
      padding-left: 30px;
    }
  }
  td {
    text-align: left;
    white-space: nowrap;
    height: 65px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
    }
    &.info {
      font-size: 16px;
      display: flex;
      align-items: center;
      line-height: 22px;
    }
    .logo {
      margin-right: 13px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    .tips {
      font-size: 14px;
      font-weight: 500;
      opacity: 0.5;
      line-height: 19px;
    }
    &.number {
      font-family: "Rubik-SemiBold";
      font-size: 18px;
      font-weight: 600;
    }
  }
  tbody tr:nth-child(2n + 1) {
    background: rgba(255, 255, 255, 0.04);
  }
  @media (max-width: 1440px) {
    th {
      height: 45px;
    }
  }
`;

const GrayLine = styled.ul`
  display: flex;
  align-items: center;
  height: 55px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  backdrop-filter: blur(32px);
  padding: 0 20px;
  margin: 0 30px;
  li {
    margin-right: 50px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
`;
const SelectedBox = styled.div`
  margin: 0 auto;
  width: 170px;
  height: 44px;
  /* background: #ffffff; */
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  text-align: center;
  div {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  img {
    margin-right: 10px;
  }
`;
