import React, { useMemo, useEffect, useState, useCallback } from "react";

import { QueryDaoByHandle, QueryUnAuthDaoByHandle } from "api/graphql/dao";
import { useLazyQuery } from "@apollo/client";
import { NetworkList } from "network";
import useCheckLogin from "./useCheckLogin";
import { useClientContext } from "components/provider/clientProvider";

const useDaoDetailByHandle = (full_handle, network_simple) => {
  const [id, setDaoId] = useState();
  const [dao, setDao] = useState();

  const isLogin = useCheckLogin();
  const { getClient, authClient } = useClientContext();

  const { handle, chainId } = useMemo(() => {
    const network = NetworkList.find((n) => n.simple === network_simple);
    return { handle: full_handle, chainId: network?.chainId };
  }, [full_handle, network_simple]);

  const handleResult = useCallback((data) => {
    setDao({
      ...data.dao,
      is_like: data.is_like,
    });
    setDaoId(data.dao.dao_id);
  }, []);

  const [queryDaoDetail] = useLazyQuery(QueryDaoByHandle, {
    onCompleted: (data) => {
      console.log("====", data);
      try {
        handleResult(data);
      } catch (error) {
        console.error("parse daoByHandleAndChainId failed", error);
      }
    },
    onError: (error) => {
      console.error("====", error);
    },
  });

  useEffect(async () => {
    // rquest
    if (!chainId) {
      return;
    }
    try {
      const client = isLogin ? authClient : getClient(chainId);
      const data = await client.request(
        isLogin ? QueryDaoByHandle : QueryUnAuthDaoByHandle,
        {
          chainId,
          handle,
        }
      );
      handleResult(data);
    } catch (error) {
      console.error(error);
    }
  }, [handle, isLogin]);

  return { id, handle, dao, daoChainId: chainId };
};

export default useDaoDetailByHandle;
