import { memo, useMemo } from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { useDefiContext } from "components/provider/defiProvider";
import { useState } from "react";
import { useEffect } from "react";
import { ethers } from "ethers";
import { getShortDisplay } from "utils/publicJs";
import { getValueDisplay, formatHealth } from "utils/aave";
import RiskModal from "./risk";
import { valueToBigNumber } from "@aave/math-utils";
import { useTranslation } from "react-i18next";
import RefreshIcon from "assets/images/Refresh.svg";
import { Tooltip } from "antd";
import { useDaoContext } from "views/dao/provider";
import PubSub from "pubsub-js";
import { TransactionEvent } from "utils/constant";

const Dashboard = memo(() => {
  const { t } = useTranslation();
  const { state: { daoId } } = useDaoContext();
  const {
    state: { userData, usdPriceEth, userAPY, userUsdData, userDebtReserves },
  } = useDefiContext();
  const {
    healthFactor,
    totalCollateralETH,
    totalDebtETH,
    currentLiquidationThreshold,
    ltv: currentLtv,
  } = userData;

  const [worth, setWorth] = useState(0);
  const [showRisk, setShowRisk] = useState(false);

  const [ltv, setLtv] = useState(0);
  const [healthFactorVal, sethealthFactorVal] = useState(0);

  const heathDisplay = useMemo(() => {
    if (healthFactor) {
      const bm = BigNumber(healthFactor.toString()).div(BigNumber(1e18));
      sethealthFactorVal(bm.toNumber())
      return formatHealth(bm);
    } else {
      return 0;
    }
  }, [healthFactor]);

  const hasDebt = useMemo(() => {
    return !!userDebtReserves?.length;
  }, [userDebtReserves]);

  useEffect(() => {
    if (!totalCollateralETH || !totalDebtETH || !usdPriceEth) {
      return;
    }
    if (totalCollateralETH.eq(ethers.BigNumber.from("0"))) {
      setLtv(0);
    } else {
      const tdv = valueToBigNumber(totalDebtETH.toString());
      const tcv = valueToBigNumber(totalCollateralETH.toString());
      setLtv(Number(getShortDisplay(tdv.div(tcv).shiftedBy(2).toString(), 2)));
    }
  }, [totalCollateralETH, totalDebtETH, usdPriceEth]);

  const netAPY = useMemo(() => {
    const { earnedAPY, debtAPY, totalSupplyInUsd, totalDebtInUsd } = userAPY;
    if (!earnedAPY && !debtAPY) {
      return 0;
    }
    return (
      (BigNumber(earnedAPY || 0) *
        (BigNumber(totalSupplyInUsd) / BigNumber(worth || 1)) -
        BigNumber(debtAPY || 0) *
          (BigNumber(totalDebtInUsd) / BigNumber(worth || 1))) *
      100
    );
  }, [userAPY, worth]);

  useEffect(() => {
    const { totalSupplyInUsd, totalDebtInUsd } = userAPY;
    if (!totalSupplyInUsd && !totalDebtInUsd) {
      return;
    }
    const w = BigNumber(totalSupplyInUsd).minus(BigNumber(totalDebtInUsd));
    setWorth(w.toNumber());
  }, [userAPY]);

  const refreshData = () => {
    PubSub.publish(TransactionEvent.Defi, { daoId });
  }

  return (
    <UlBox>
      <dl>
        <dt>{t("defi.worth")}</dt>
        <dd className="font-demiBold">{getValueDisplay(worth, true)}</dd>
      </dl>
      <dl>
        <dt>{t("defi.APY")}</dt>
        <dd className="font-demiBold">{getValueDisplay(netAPY)}%</dd>
      </dl>
      {hasDebt && (
        <dl>
          {showRisk && (
            <RiskModal
              show={showRisk}
              closeModal={() => setShowRisk(false)}
              data={{
                healthFactor: healthFactorVal,
                healthFactorDisplay: heathDisplay,
                loanToValue: ltv,
                currentLoanToValue: currentLtv.toNumber() / 100,
                currentLiquidationThreshold:
                  currentLiquidationThreshold.toNumber() / 100,
              }}
            />
          )}
          <dt>
            {t("defi.Health")}{" "}
            <div className="tag" onClick={() => setShowRisk(true)}>
              {t("defi.Risk")}
            </div>
          </dt>
          <dd>{heathDisplay}</dd>
        </dl>
      )}

      <Tooltip title={t("defi.RefreshTip")} placement="topRight">
        <RefreshBox onClick={refreshData}>
          <img className="showCursor" src={RefreshIcon} alt="" />
          <span className="showCursor">{t("defi.Refresh")}</span>
        </RefreshBox>
      </Tooltip>
    </UlBox>
  );
});

export default Dashboard;

const UlBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  dl {
    margin-right: 83px;
  }
  dt {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #328aff;
    font-family: "Poppins-Medium";
    .tag {
      display: inline-block;
      width: 92px;
      height: 24px;
      text-align: center;
      background: #328aff;
      color: #FFFFFF;
      border-radius: 13px;
      font-size: 12px;
      line-height: 24px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  dd {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 9px;
    font-family: "Rubik-SemiBold";
  }
  @media (max-width: 1440px) {
    padding: 0;
  }
`;

const RefreshBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  font-family: "Rubik-Medium";
  font-size: 16px;
  color: #328aff;
  .showCursor {
    cursor: pointer;
  }
  img {
    width: 24px;
    margin-right: 2px;
  }
`;