import { useMemo } from 'react';
import getNetworkData from "../network"

export const useNetworkData = (chainID) => {
    return useMemo(() => {
        if (!chainID) {
            return
        }
        return getNetworkData(chainID)
    }, [chainID])
}