import { ethers } from "ethers";
import { formatBalanceDisplay } from "utils/publicJs";

const group = (array, subGroupLength) => {
  var index = 0;
  var newArray = [];

  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }

  return newArray;
};

const parseErc20TokenInfoV1 = (address, dataArray) => {
  // decimals
  const deci = ethers.BigNumber.from(dataArray[0]).toNumber();

  // balance
  const balance = ethers.BigNumber.from(dataArray[1]).toString();
  const balanceValue = ethers.utils.formatUnits(balance, deci);

  return {
    address,
    canTransfer: true,
    balance: balanceValue,
    balanceDisplay: formatBalanceDisplay(Number(balanceValue)),
    deci,
  };
};

const parseErc20TokenInfo = (address, dataArray) => {
  // decimals
  const deci = ethers.BigNumber.from(dataArray[0]).toNumber();

  // balance
  const balance = ethers.BigNumber.from(dataArray[1]).toString();
  const balanceValue = ethers.utils.formatUnits(balance, deci);

  // cantransfer
  const canTransfer = ethers.BigNumber.from(dataArray[2]).toNumber() === 1;

  return {
    address,
    canTransfer,
    balance: balanceValue,
    balanceDisplay: formatBalanceDisplay(Number(balanceValue)),
    deci,
  };
};

const parseNFTTokenInfo = (address, dataArray) => {
  // cantransfer
  const canTransfer = ethers.BigNumber.from(dataArray[0]).toNumber();

  // balance
  const balance = ethers.BigNumber.from(dataArray[1]).toNumber();
  return {
    address,
    canTransfer,
    balance,
    balanceDisplay: balance,
  };
};

const getNFTTokens = async (contract, address_list, balanceAddress) => {
  const interfaces = [
    "function CouldTransfer() public view returns (bool)",
    "function balanceOf(address account, uint256 id) public view returns (uint256)",
  ];
  const iface = new ethers.utils.Interface(interfaces);
  const calls = [];
  for (let i = 0; i < address_list.length; i++) {
    const canTransfer = iface.encodeFunctionData("CouldTransfer", []);
    const balance = iface.encodeFunctionData("balanceOf", [balanceAddress, 0]);

    const call1 = [address_list[i], canTransfer];
    const call2 = [address_list[i], balance];

    calls.push(call1, call2);
  }

  const tokensInfo = await contract.multiCall(calls);
  const groupInfo = group(tokensInfo, 2);
  const list = groupInfo.map((tk, i) => {
    return parseNFTTokenInfo(address_list[i], tk);
  });
  console.log("list: ", list);
  return list;
};

const getErc20Tokens = async (contract, address_list, balanceAddress) => {
  const interfaces = [
    "function CouldTransfer() public view returns (bool)",
    "function balanceOf(address account) public view returns (uint256)",
    "function decimals() public view returns (uint8)",
  ];
  const iface = new ethers.utils.Interface(interfaces);
  const calls = [];

  for (let i = 0; i < address_list.length; i++) {
    const canTransfer = iface.encodeFunctionData("CouldTransfer", []);
    const balance = iface.encodeFunctionData("balanceOf", [balanceAddress]);
    const decimals = iface.encodeFunctionData("decimals", []);

    const call_transfer = [address_list[i], canTransfer];
    const call_balance = [address_list[i], balance];
    const call_deci = [address_list[i], decimals];

    calls.push(call_deci, call_balance, call_transfer);
  }
  const tokensInfo = await contract.multiCall(calls);
  const groupInfo = group(tokensInfo, 3);

  const list = groupInfo.map((tk, i) => {
    return parseErc20TokenInfo(address_list[i], tk);
  });
  return list;
};

const getErc20TokensV1 = async (contract, address_list, balanceAddress) => {
    const interfaces = [
      "function CouldTransfer() public view returns (bool)",
      "function balanceOf(address account) public view returns (uint256)",
      "function decimals() public view returns (uint8)",
    ];
    const iface = new ethers.utils.Interface(interfaces);
    const calls = [];

    for (let i = 0; i < address_list.length; i++) {
      const balance = iface.encodeFunctionData("balanceOf", [balanceAddress]);
      const decimals = iface.encodeFunctionData("decimals", []);

      const call_balance = [address_list[i], balance];
      const call_deci = [address_list[i], decimals];

      calls.push(call_deci, call_balance);
    }
    const tokensInfo = await contract.multiCall(calls);
    const groupInfo = group(tokensInfo, 2);

    const list = groupInfo.map((tk, i) => {
      return parseErc20TokenInfoV1(address_list[i], tk);
    });
    console.log("list: ", list);
    return list;
};

export default { getErc20Tokens, getNFTTokens, getErc20TokensV1 };
