import styled, { css } from "styled-components";
import CloseIcon from "assets/images/Close.svg";
import BgIcon from "assets/images/ModalBg.svg";

export default function BaseModal({
  show,
  title,
  titleBold,
  glass,
  closeModal,
  closable,
  children,
  width,
  height,
  ...rest
}) {
  if (!show) {
    return <></>
  }

  const handleClose = () => {
    closeModal && closeModal();
  };
  return (
    <BgBox>
      <BoxCenter glass={glass} width={width} height={height} {...rest}>
        {(closable || closable === undefined) && (
          <CloseBox onClick={handleClose}>
            <img src={CloseIcon} alt="" />
          </CloseBox>
        )}
        {title && <TitleBox titleBold={titleBold}>{title}</TitleBox>}
        <ContentBox>{children}</ContentBox>
      </BoxCenter>
    </BgBox>
  );
}

const GlassSkin = css`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  //backdrop-filter: blur(32px);
`;

const DefaultSkin = css`
  background: ${(props) => props.theme.colors.main} url(${BgIcon}) no-repeat left top;
`;

const BgBox = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(10px);
`;
const BoxCenter = styled.div`
  width: ${(props) => (props.width || 440) + "px"};
  ${(props) => (props.glass ? GlassSkin : DefaultSkin)};
  box-shadow: 0px 0px 20px 0px rgba(16, 22, 75, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding || "20px"};
  height: ${(props) => (props.height ? props.height + "px" : "unset")};
  position: relative;
  box-sizing: border-box;
  @media (max-width: 1440px) {
    width: ${(props) => (props.swidth || props.width || 440) + "px"};
    height: ${(props) =>
      props.sheight || props.height
        ? (props.sheight || props.height) + "px"
        : "unset"};
  }
`;
const TitleBold = css`
  font-family: Poppins-Bold, Poppins;
  font-weight: bold;
  font-size: 20px;
`;

const TitleBox = styled.div`
  height: 28px;
  font-size: 16px;
  font-weight: 400;
  ${(props) => props.titleBold && TitleBold}
  color: #ffffff;
  line-height: 30px;
  text-align: center;
`;

const CloseBox = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  z-index: 999;
  justify-content: flex-end;
  margin-bottom: 20px;
  img {
    width: 24px;
    cursor: pointer;
  }
`;
const ContentBox = styled.div``;
