import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useVaultContract } from "./useContract";
import api from "../api";
import { useCallback } from "react";
import { useDaoContext } from "views/dao/provider";
import useMulticallTokens from "./useMulticallTokens";
import { useAppContext } from "components/provider/appProvider";
import TokenDefault from "img/defaultToken.svg";
import getIdentity from "utils/tokenIdentity";
import { useNetworkData } from "./useNetwork";

const getToken = async (address, web3Instance) => {
  try {
    const mockERC20 = await api.erc20.getContract(web3Instance, address);
    const symbol = await mockERC20.symbol();
    const deci = await mockERC20.decimals();
    return { symbol, deci, address };
  } catch (error) {
    console.error(`get token failed - ${address}`, error);
  }
};

const useVaultTokens = (vaultAddress, init, refreshFlag) => {
  const { provider: web3Instance } = useWeb3React();

  const {
    state: { daoChainId },
    dispatch: dispatchEvent,
  } = useDaoContext();

  const { state: { tokens: tokenList } } = useAppContext();
  const network = useNetworkData(daoChainId);

  const vaultcontract = useVaultContract(vaultAddress, daoChainId);
  const [tokenAddress, setTokenAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const { tokens, mloading } = useMulticallTokens(
    tokenAddress,
    vaultAddress,
    daoChainId
  );

  const [vaultTokens, setVaultTokens] = useState([])

  useEffect(() => {
    daoChainId && vaultcontract && getTokens();
  }, [daoChainId, vaultcontract, refreshFlag]);

  const getTokens = useCallback(async () => {
    let data = await api.vault.getTokenList(vaultcontract);
    if (!data || data.length === 0) {
      setLoading(false);
      return;
    }
    setTokenAddress(data);
  }, [vaultcontract, web3Instance]);

  useEffect(() => {
    setVaultTokens(
      tokens.map((t) => {
        let isNative = {};
        const address = t.address.toLowerCase()
        if (address === network.wrappedToken) {
          isNative = {
            ...t,
            ...network.nativeCurrency,
            deci: network.nativeCurrency.decimals,
            logoURI: network.logo,
            isWrapped: true,
            origin: {
              ...t,
              logoURI: network.logo,
            },
          };
        }
        const img = tokenList.find(
          (item) => item.address.toLowerCase() === address.toLowerCase()
        );
        const identity = getIdentity(address)
        return {
          ...t,
          identity,
          name: identity ? `${t.name} | ${identity}` : t.name,
          ...isNative,
          logoURI: img ? img.logoURI : TokenDefault,
        };
      })
    );
  }, [tokens, tokenList]);

  useEffect(() => {
    if (vaultTokens.length) {
      setLoading(false);
      dispatchEvent({ type: "SET_VAULT_TOKENS", payload: vaultTokens });
    }
  }, [vaultTokens]);

  return { tokens: vaultTokens, loading };
};

export default useVaultTokens;
