import styled from "styled-components";
import React, { useEffect, useState } from "react";
import KeyImg from "../../../assets/images/Super-manager.svg";

export default function AddManager() {
  return (
    <MidBox>
      <div className="imgBox">
        <img src={KeyImg} alt="" />
      </div>
      <div className="name">Super Manager</div>
      <div className="tips">
        TheSuper Manager, appointed by the managers through voting, has no ownership of the funds, only the right
        to use them . In the appointment proposal, you need to (i) select the{" "}authorized address,available dApp and
        (ii) set the spending limit,expiry date
      </div>
    </MidBox>
  );
}


const MidBox = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .imgBox {
    width: 260px;
    margin: 80px 0 55px;
    img {
      width: 100%;
    }
  }
  .name {
    margin-bottom: 12px;
    opacity: 0.32;
    text-transform: uppercase;
    font-size: 24px;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    color: #FFFFFF;
    line-height: 35px;
  }
  .tips {
    width: 636px;
    opacity: 0.32;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
    text-align: center;
  }
`;
