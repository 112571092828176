import styled from "styled-components";
import General from "components/modal/general";
import FailedSvg from "components/svg/common/failed";
import { memo } from "react";
import {useTranslation} from "react-i18next";

const FailedModal = memo(({ handleClose, title, content, subContent }) => {
    const { t } = useTranslation();
  return (
    <General width="365" height="285" closeModal={handleClose}>
      <TopImg>
        <FailedSvg />
      </TopImg>
      <TitleBox className="font-demiBold">{title}</TitleBox>
      {/* <MainBox className="font-medium">{content}</MainBox> */}
      <FailedContent>{subContent}</FailedContent>
      <ButtonBg className="font-medium" onClick={handleClose}>
          {t("Close")}
      </ButtonBg>
    </General>
  );
});
export default FailedModal;

const TopImg = styled.div`
  text-align: center;
  svg {
    margin: 27px 0 14px;
    width: 34px;
    height: 34px;
  }
`;

const TitleBox = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #10164b;
  line-height: 25px;
  text-align: center;
`;
const MainBox = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #10164b;
  line-height: 22px;
  text-align: center;
  margin: 4px 0 8px;
`;
const ButtonBg = styled.div`
  width: 157px;
  height: 44px;
  background: #d52473;
  border-radius: 8px;
  text-align: center;
  line-height: 44px;
  margin: 50px auto 0;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const FailedContent = styled.div`
  text-align: center;
  font-size: 16px;
  margin-top: 10px;

`