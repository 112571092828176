import { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import LinkImg from "assets/images/link.svg";
import TipSVG from "assets/images/Tip.svg";
import { useDaoContext } from "../../views/dao/provider";
import { getShortDisplay } from "../../utils/publicJs";
import useManageFeeBalance from "hooks/useManageFee";
import {
  PERIOD_NO,
  VENTURE_MANAGER,
  VENTURE_STOCK_MANAGER,
} from "../../utils/constant";

import VenturePeriodPanel from "./period";
import { useTranslation } from "react-i18next";
import { NFTTokenAvatar, TokenAvatar } from "components/common/avatar";

import { Tooltip } from "antd";
import { useAppContext } from "components/provider/appProvider";
import { useVentureContract, useErc20StockContract } from "hooks/useContract";
import { ethers } from "ethers";
import TokenDefault from "img/defaultToken.svg";
import ClaimCard from "./claimCard";
import getIdentity from "utils/tokenIdentity";
import LftImg from "../../assets/images/left.png";
import RhtImg from "../../assets/images/right.png";
import CircleProgress from "components/common/circleProgress";
import RectProgress from "./rectProgress";

import CardLayout from "./cardLayout";
import { AnimationBox } from "assets/styles/common";

const FundPanel = ({ isReady }) => {
  const { t } = useTranslation();

  const {
    state: { exploreScan },
  } = useAppContext();
  const { state: daoState } = useDaoContext();
  const {
    daoChainId,
    componentAddressMap,
    fundInfo,
    fundToken,
    stockToken,
    carriedInterest,
    manageFee,
    manageFeeToken,
    baseData,
    isMultiSignWallet,
    period,
  } = daoState;

  const ventureContract = useVentureContract(
    componentAddressMap.get(VENTURE_MANAGER),
    daoChainId
  );
  const [raised, setRaised] = useState(0);

  const feeBalance = useManageFeeBalance();

  const goal = useMemo(() => {
    return fundInfo ? getShortDisplay(fundInfo.goal) : "";
  }, [fundInfo]);

  const hard = useMemo(() => {
    return fundInfo ? getShortDisplay(fundInfo.hard) : "";
  }, [fundInfo]);

  const progress = useMemo(() => {
    if (!fundInfo || !raised) return 0;
    return (Number(raised) * 100) / Number(fundInfo.goal);
  }, [fundInfo, raised]);

  const isNFT = useMemo(() => {
    return stockToken?.type === "nft";
  }, [stockToken]);

  const isToken = useMemo(() => {
    return stockToken?.type === "token";
  }, [stockToken]);

  const manageFeeInfo = useMemo(() => {
    return manageFee
      ? {
          display: (manageFee / 100).toFixed(2),
          progress: manageFee / 100,
        }
      : { display: 0, progress: 0 };
  }, [manageFee]);

  const carriedInterestInfo = useMemo(() => {
    return carriedInterest
      ? {
          display: (carriedInterest / 100).toFixed(2),
          progress: carriedInterest / 100,
        }
      : { display: 0, progress: 0 };
  }, [carriedInterest]);

  // useEffect(() => {
  //   if (!carriedInterestInfo.progress) {
  //     return;
  //   }
  //   setTimeout(() => {
  //     const valueDisplay = document.querySelector(".bonusValue");
  //     const interval = 100;
  //     let startValue = 0;
  //     const endValue = carriedInterestInfo.progress;
  //     const duration = Math.floor(interval / endValue);
  //     const counter = setInterval(function () {
  //       startValue += 1;
  //       valueDisplay.textContent = `${startValue}%`;
  //       if (startValue >= endValue) {
  //         valueDisplay.textContent = `${endValue}%`;
  //         clearInterval(counter);
  //       }
  //     }, duration);
  //   }, 500);
  // }, [carriedInterestInfo]);

  // useEffect(() => {
  //   if (!manageFeeInfo.display) {
  //     return;
  //   }
  //   const valueDisplay = document.querySelector(".feePercentValue");
  //   const interval = 100;
  //   let startValue = 0;
  //   const endValue = manageFeeInfo.display;
  //   const duration = Math.floor(interval / endValue);
  //   const counter = setInterval(function () {
  //     startValue += 1;
  //     valueDisplay.textContent = `${startValue}%`;
  //     if (startValue >= endValue) {
  //       valueDisplay.textContent = `${endValue}%`;
  //       clearInterval(counter);
  //     }
  //   }, duration);
  // }, [manageFeeInfo]);

  // useEffect(() => {
  //   if (!feeBalance?.balanceDisplay) {
  //     return;
  //   }
  //   const valueDisplay = document.querySelector(".feeValue");
  //   const interval = 100;
  //   let startValue = 0.00;
  //   const endValue = Number(feeBalance?.balanceDisplay);
  //   const duration = Math.floor(interval / endValue);
  //   const counter = setInterval(function () {
  //     startValue += 1;
  //     valueDisplay.textContent = `${startValue}%`;
  //     if (startValue >= endValue) {
  //       valueDisplay.textContent = `${endValue}%`;
  //       clearInterval(counter);
  //     }
  //   }, duration);
  // }, [feeBalance]);

  useEffect(async () => {
    if (baseData && baseData.period === PERIOD_NO.INRETURN) {
      if (ventureContract && fundToken) {
        const data = await ventureContract.getSettlementSnap();
        setRaised(ethers.utils.formatUnits(data[0], fundToken.deci));
      }
    } else if (fundInfo) {
      setRaised(fundInfo.raised);
    }
  }, [baseData, ventureContract, fundInfo, fundToken]);

  const getCirculateText = (v) => {
    return v ? t("Create.Circulable") : t("Create.NoCirculable");
  };

  const NntHoverCard = useMemo(() => {
    if (!stockToken || stockToken.type === "token") {
      return <></>;
    }
    return (
      <NftDetail className="nftDetail">
        <div className="wrapper">
          <div>
            <NFTTokenAvatar
              src={stockToken.image}
              size="64px"
              className="nftLogo"
            />
            <span className="name">{stockToken.name}</span>
          </div>
          <NftLine>
            <span className="left">{t("Create.Price")}</span>
            <span className="right">
              {stockToken?.price} {fundToken?.symbol}
            </span>
          </NftLine>
          {!!stockToken.properties &&
            Object.keys(stockToken.properties).map((attr, val) => (
              <NftLine key={val}>
                <span className="left">{attr}</span>
                <span className="right">{stockToken.properties[attr]}</span>
              </NftLine>
            ))}
        </div>
      </NftDetail>
    );
  }, [stockToken]);

  return (
    <>
      <VenturePeriodPanel isReady={isReady} />
      <AnimationBox className={isReady ? "animation" : ""}>
        <Fund>
          <LftBg />
          <RhtBg />
          <FirstLine>
            <div className="lft">
              <div className="titleBox">{t("Info.Raised")}</div>
              <div className="lftBtm">
                <div className="numBig">{getShortDisplay(raised)}</div>
                {/* <div className="numBig">{Number(raised).toFixed(2)}</div> */}
                <Symbol>
                  <img src={fundToken?.logo || TokenDefault} alt="" />
                  <span>{fundToken?.symbol}</span>
                  <span className="identity">
                    {getIdentity(fundToken?.address)}
                  </span>
                </Symbol>
              </div>
            </div>
            <div className="rht">
              <div className="cur">{t("Info.CurrentProgress")}</div>
              <div className="numRht">{Number(progress).toFixed(2)}%</div>
            </div>
          </FirstLine>
          <RectProgress progress={progress > 100 ? 100 : progress} />
          <LastLine>
            <div>
              <div className="tit">{t("Info.FundingTarget")}</div>
              <div className="num">{Number(goal).toFixed(2)}</div>
            </div>
            {fundInfo?.hard && (
              <div className="hard">
                <div className="tit">{t("Info.UpperLimit")}</div>
                <div className="num">{Number(hard).toFixed(2)}</div>
              </div>
            )}
          </LastLine>
        </Fund>
      </AnimationBox>

      <TypeBox>
        <CardLayout isReady={isReady}>
          <div className="logo">
            <img src={fundToken?.logo || TokenDefault} alt="" />
          </div>
          <div className="token">
            <span className="symbol">{fundToken?.symbol}</span>
            <span className="identity">{getIdentity(fundToken?.address)}</span>
          </div>
          <Title>{t("Info.FundType")}</Title>
          <a
            className="link"
            href={`${exploreScan}token/${fundToken?.address}`}
            rel="noreferrer"
            target="_blank"
          >
            <img src={LinkImg} alt="" />
          </a>
        </CardLayout>
        {isNFT && (
          <CardLayout className="nftCard" isReady={isReady}>
            {NntHoverCard}
            <SymbolBox>
              <div className="topBox">
                <NFTTokenAvatar
                  src={stockToken.image}
                  size="44px"
                  className="nftLogo"
                />
                <div className="right">
                  <TokenCirculation
                    className={stockToken?.canTransfer ? "" : "no"}
                  >
                    {getCirculateText(stockToken?.canTransfer)}
                  </TokenCirculation>
                  <div className="label">
                    {stockToken?.BuyMode === 1
                      ? t("BondingCurve.OriginalPrice")
                      : t("Create.Price")}{" "}
                    {stockToken?.price} {fundToken?.symbol}
                  </div>
                </div>
              </div>
              <div className="token">{stockToken?.name}</div>
              <Title>{t("Create.NFT")}</Title>
            </SymbolBox>
            <a
              className="link"
              href={`${exploreScan}token/${stockToken.address}?a=0`}
              rel="noreferrer"
              target="_blank"
            >
              <LinkIcon src={LinkImg} alt="" />
            </a>
          </CardLayout>
        )}
        {isToken && (
          <CardLayout isReady={isReady}>
            <SymbolBox>
              <div className="topBox">
                <TokenAvatar address={stockToken?.address} size="48px" />
                <div className="right">
                  <TokenCirculation
                    className={stockToken?.canTransfer ? "" : "no"}
                  >
                    {getCirculateText(stockToken?.canTransfer)}
                  </TokenCirculation>
                  <div className="label">
                    {stockToken?.BuyMode === 1
                      ? t("BondingCurve.OriginalPrice")
                      : t("Create.Price")}{" "}
                    {stockToken?.price} {fundToken?.symbol}
                  </div>
                </div>
              </div>
              <div className="token">{stockToken?.symbol}</div>
              <Title>{t("Info.EquityToken")}</Title>
            </SymbolBox>
            <a
              className="link"
              href={`${exploreScan}token/${stockToken?.address}`}
              rel="noreferrer"
              target="_blank"
            >
              <img src={LinkImg} alt="" />
            </a>
          </CardLayout>
        )}
        <CardLayout isReady={isReady}>
          <LineBox>
            <CircleProgress progress={manageFeeInfo.progress} color="#45BEFF" />
            <div className="first">
              <PercentNum>
                <span className="feeValue">{feeBalance?.balanceDisplay}</span>
                <span className="symbol">{manageFeeToken?.symbol}</span>
              </PercentNum>
              <Percent className="rht feePercentValue">
                {manageFeeInfo.display}%
              </Percent>
            </div>

            <Title>{t("Info.ManageFee")}</Title>
          </LineBox>
        </CardLayout>
        <CardLayout isReady={isReady}>
          <LineBox>
            <CircleProgress progress={carriedInterestInfo.progress} />
            <div className="first">
              <PercentNum
                data-val={carriedInterestInfo.display}
                className="bonusValue"
              >
                {carriedInterestInfo.progress}%
              </PercentNum>
            </div>
            <Title>{t("Info.CarriedInterest")}</Title>
          </LineBox>
          <Tooltip title={t("Hover.CarriedInterest")}>
            <img className="tip" src={TipSVG} alt="" />
          </Tooltip>
        </CardLayout>
        {isToken && (
          <ClaimCard
            t={t}
            isReady={isReady}
            period={period}
            ventureContract={ventureContract}
            fundToken={fundToken}
            stockToken={stockToken}
          />
        )}
      </TypeBox>
    </>
  );
};

export default FundPanel;

const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`;
const RhtBg = styled.div`
  width: 245px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;
`;

const Fund = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(20px);
  padding: 20px 24px;
  margin-top: 16px;
  position: relative;
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  .lftBtm {
    display: flex;
    align-items: center;
  }
  .rht {
    display: flex;
    align-items: center;
    .numRht {
      font-size: 32px;
      font-family: "Rubik-Bold";
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
    }
  }
  .cur {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    opacity: 0.5;
    margin-right: 12px;
  }
  .numBig {
    font-size: 32px;
    font-family: "Rubik-Bold";
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
  }
  .titleBox {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    opacity: 0.5;
    margin-bottom: 11px;
  }
`;
const LastLine = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    &.hard {
      margin-left: 40px;
      padding-left: 40px;
      border-left: 1px solid rgba(255, 255, 255, 0.24);
    }
  }
  .tit {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    opacity: 0.5;
    margin-right: 20px;
  }
  .num {
    font-size: 14px;
    font-family: "Rubik-Regular";
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
  opacity: 0.5;
  margin-top: 4px;
`;
const Symbol = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #6e7398;
  line-height: 16px;
  img {
    width: 16px;
    height: 16px;
    margin: 0 4px 0 12px;
    border-radius: 50%;
  }
  span {
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
  }
  .identity {
    font-size: 14px;
    margin-left: 8px;
  }
`;

const TypeBox = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-wrap: wrap;
  padding: 0 1px;
  li {
    .logo {
      height: 48px;
      img {
        width: 48px;
        border-radius: 50%;
      }
    }
    .token,
    .token .symbol {
      font-size: 24px;
      font-family: "Poppins-Bold";
      line-height: 35px;
    }
    .token {
      height: 33px;
      margin-top: 16px;
      display: flex;
      .identity {
        margin-left: 8px;
      }
    }
    .tip,
    .link {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .link img,
    .tip {
      width: 24px;
      opacity: 0.72;
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .infoIcon {
      position: absolute;
      right: -8px;
      top: 0;
      cursor: pointer;
      &:hover {
        .tip {
          display: block;
        }
      }
    }
  }
  .release {
    display: flex;
    justify-content: space-between;
  }
`;

const SymbolBox = styled.div`
  flex-grow: 1;

  .topBox {
    height: 48px;
    display: flex;
    .right {
      margin-left: 10px;
    }
  }

  .lft {
    img {
      width: 44px;
      height: 44px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
  .label {
    opacity: 0.5;
  }
`;
const PercentNum = styled.div`
  width: 56px;
  height: 28px;
  font-size: 24px;
  font-family: "Rubik-Bold";
  font-weight: bold;
  color: #ffffff;
  line-height: 28px;
  .symbol {
    font-size: 14px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    margin-left: 11px;
    opacity: 0.5;
  }
`;
const LineBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
`;

const LinkIcon = styled.img`
  width: 24px;
`;

const TokenCirculation = styled.div`
  height: 20px;
  background: #326cff;
  border-radius: 13px;
  padding: 0 14px;
  line-height: 20px;
  margin-bottom: 6px;
  font-size: 10px;
  display: inline-block;
`;

const NFTImg = styled.div`
  width: 44px;
  height: 44px;
  margin-right: 10px;
  background: ${(props) => `url(${props.src})`} no-repeat center center;
  @media (max-width: 1440px) {
    width: 32px;
    height: 32px;
  } ;
`;

const NftDetail = styled.div`
  width: 359px;
  position: absolute;
  display: none;
  padding-bottom: 20px;
  bottom: 125px;
  right: 0;
  .name {
    margin-left: 13px;
    font-size: 18px;
  }
  .wrapper {
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(16, 22, 75, 0.05);
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    padding: 23px 21px;
  }
  @media (max-width: 1440px) {
    bottom: 100px;
  }
`;

const NftLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  line-height: 21px;
  .left {
    color: #6f7395;
  }
`;

const Percent = styled.div`
  font-size: 14px;
  font-family: "Rubik-SemiBold";
  font-weight: 600;
  color: #ffffff;
  line-height: 18px;
`;
