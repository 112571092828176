import { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Store } from "react-notifications-component";
import CloseIcon from "img/close.png";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import Button from "./button";
import SuccessIcon from "assets/images/Success.svg";
import FailedIcon from "assets/images/Failed.svg";
import "animate.css";
import SwapLoading from "components/apps/swap/swaploading";
import { useTranslation } from "react-i18next";
import { GlassBG } from "assets/styles/common";

export const NotificationType = {
  Loading: "loading",
  Success: "success",
  Fail: "fail",
};

const Notification = memo(({ id, txHash, msg, type, go2where, buttonText }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const close = useCallback(() => {
    Store.removeNotification(id);
  }, [id]);
  const showStatus = useMemo(() => {
    switch (type) {
      case NotificationType.Success:
        return <img src={SuccessIcon} alt="" className="icon" />;
      case NotificationType.Fail:
        return <img src={FailedIcon} alt="" className="icon" />;
      default:
        return (
          <SwapLoading
            foreColor="#6EDAFF"
            backColor="#041735"
            width="14"
            height="14"
            style={{ position: "relative", top: "5px", marginRight: "10px" }}
          />
        );
    }
  }, []);

  const clickGo = () => {
    close();
    navigate(go2where);
  }

  return (
    <NotificationStyled>
      {showStatus}
      <Content>
        <Message className="font-medium">{msg}</Message>
        {txHash && (
          <View>
            <a href={txHash} target="_blank" rel="noreferrer">
              {t("Notification.View")}
            </a>
            {go2where && (
              <Button primary width={110} height={34} onClick={clickGo}>
                {buttonText || t("Notification.Goto")}
              </Button>
            )}
          </View>
        )}
      </Content>
      <CloseButton src={CloseIcon} alt="" onClick={close} />
    </NotificationStyled>
  );
});

const VoteNotification = memo(({ id, txHash, leftTime, fullpath }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const close = useCallback(() => {
    Store.removeNotification(id);
  }, []);

  const go2vote = useCallback(() => {
    Store.removeNotification(id);
    navigate(fullpath);
  }, []);

  return (
    <VoteNotificationStyled>
      <img src={SuccessIcon} alt="" className="icon" />
      <Content>
        <Message className="font-medium">
          {t("Notification.VoteSuccess")}
        </Message>
        <Submessage>
          {t("Notification.VoteLeft", { time: leftTime })}
        </Submessage>
        <View>
          {txHash && (
            <a href={txHash} target="_blank" rel="noreferrer">
              {t("Notification.View")}
            </a>
          )}
          <Button primary width={110} height={34} onClick={go2vote}>
            {t("Notification.Goto")}
          </Button>
        </View>
      </Content>
      <CloseButton src={CloseIcon} alt="" onClick={close} />
    </VoteNotificationStyled>
  );
});

export default function showNotification(type, msg, txHash, go2where, buttonText, config) {
  const id = uuidv4();
  return Store.addNotification({
    id: id,
    content: (
      <Notification
        id={id}
        msg={msg}
        type={type}
        txHash={txHash}
        go2where={go2where}
        buttonText={buttonText}
      />
    ),
    container: "top-right",
    insert: "bottom",
    animationIn: ["animate__animated", "animate__bounceInRight"],
    animationOut: ["animate__animated", "animate__bounceOutRight"],
    dismiss: {
      click: false,
      showIcon: true,
      duration: config?.duration,
    },
  });
}

export const showVoteNotification = (txHash, fullpath, leftTime, config) => {
  const id = uuidv4();
  return Store.addNotification({
    id: id,
    content: (
      <VoteNotification
        id={id}
        fullpath={fullpath}
        txHash={txHash}
        leftTime={leftTime}
      />
    ),
    container: "top-right",
    insert: "bottom",
    animationIn: ["animate__animated", "animate__bounceInRight"],
    animationOut: ["animate__animated", "animate__bounceOutRight"],
    dismiss: {
      click: false,
      showIcon: true,
      duration: config?.duration,
    },
  });
};

export const closeNotification = (notify_id) => {
  Store.removeNotification(notify_id);
};

const NotificationStyled = styled.div`
  width: 357px;
  border-radius: 18px;
  padding: 0 30px;
  padding-top: 38px;
  padding-bottom: 20px;
  display: flex;
  align-items: flex-start;
  position: relative;
  background-color: #24354F;
  cursor: default;
  .icon {
    width: 16px;
    position: relative;
    top: 4px;
    margin-right: 10px;
  }
`;

const VoteNotificationStyled = styled(NotificationStyled)`
  height: unset;
  padding-bottom: 20px;
`;

const Content = styled.div`
  flex: 1;
  position: relative;
`;

const Message = styled.div`
  font-size: 16px;
  margin-bottom: 9px;
`;

const Submessage = styled.div`
  line-height: 19px;
  height: 14px;
  margin-top: 6px;
  margin-bottom: 19px;
  opacity: 0.5;
`;

const View = styled.div`
  font-size: 14px;
  color: #328aff;
  line-height: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
`;
