import React from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { SETTINGS_KEY } from "views/appAndsettings";
import ManagementNFT from "./walletConnect/ManagementNFT";
import Swap from "./swap/swap";
import Defi from "./defi/index";
import WCT from "./walletConnect/normal";
import { useDaoContext } from "views/dao/provider";
import { useEffect } from "react";
import useCheckLogin from "hooks/useCheckLogin";
import { useWeb3React } from "@web3-react/core";

export default function Apps() {
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const { fullhandle, network: networkSimple } = useParams();
  const {
    state: {
      isMultiSignWallet,
      period,
      whoAmI: { isOwner, isGP },
    },
  } = useDaoContext();
  const isLogin = useCheckLogin();

  useEffect(() => {
    if (period === undefined) {
      return;
    }
    if ((isOwner !== undefined && isGP !== undefined) || account === undefined) {
      if ((!isOwner && !isGP) || !isLogin || (!isMultiSignWallet && (period !== 2 && period !== 3))) {
        navigate(`/${networkSimple}/${fullhandle}/app_settings`);
      }
    }
  }, [period, isLogin, isOwner, isGP, account, fullhandle]);
  return (
    <Routes>
      {/* <Route path={SETTINGS_KEY.NFT} element={<ManagementNFT />} /> */}
      <Route path={SETTINGS_KEY.SWAP} element={<Swap />} />
      <Route path={SETTINGS_KEY.DEFI} element={<Defi />} />
      {/* <Route path={SETTINGS_KEY.WCT} element={<WCT />} /> */}
      {/* TODO redirect to app_settings */}
    </Routes>
  );
}
