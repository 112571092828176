import General from "../../modal/general";
import styled from "styled-components";
import { TokenLogo } from "components/common/avatar";
import { getShortDisplay } from "utils/publicJs";
import TokenDefault from "../../../img/defaultToken.svg";
import { useTranslation } from "react-i18next";
import arrow from "assets/images/Open.svg";
import SwapIcon from "assets/images/Swap.svg";
import Button from "components/common/button";

export default function SwapConfirm(props) {
  const { show, closeModal, handleConfirm, data } = props;
  const { t } = useTranslation();

  const {
    fromToken,
    toToken,
    fromAmount,
    toAmount,
    swapRoute,
    swapPrice,
    lessToAmount,
  } = data;

  const handleError = (e) => {
    e.target.src = TokenDefault;
    e.target.οnerrοr = null;
  };

  return (
    <General show={show} width="400" closeModal={closeModal}>
      <TitleBox className="font-medium">{t("Swap.confirm.confirm")}</TitleBox>
      <InputBoxBg>
        {/* <div> */}
        <BalanceBg>{fromAmount}</BalanceBg>
        {/* <PriceBox>$1.052.70 <span>(0.155%)</span></PriceBox> */}
        {/* </div> */}
        <div>
          <SelectBox>
            <span className="name">
              <TokenLogo
                src={fromToken && fromToken.logoURI}
                size="24px"
                onError={handleError}
              />
              {fromToken.symbol}
            </span>
            <img src={arrow} alt="" className="arrow" />
          </SelectBox>
        </div>
      </InputBoxBg>
      <CenterBox>
        <img src={SwapIcon} alt="" />
      </CenterBox>
      <InputBoxBg>
        {/* <div> */}
        <BalanceBg>{getShortDisplay(toAmount, 6)}</BalanceBg>
        {/* <PriceBox>$1.052.70 <span>(0.155%)</span></PriceBox> */}
        {/* </div> */}
        <div>
          <SelectBox>
            <span className="name">
              <TokenLogo
                src={toToken && toToken.logoURI}
                size="24px"
                onError={handleError}
              />
              {toToken.symbol}
            </span>
            <img src={arrow} alt="" className="arrow" />
          </SelectBox>
        </div>
      </InputBoxBg>
      <MidPriceBox>
        <span>
          1 {fromToken.symbol} = {getShortDisplay(swapPrice, 6)}{" "}
          {toToken.symbol}
        </span>
        {/* <span className="gray">($0.9978)</span> */}
      </MidPriceBox>

      <InsufficientBox>
        <li>
          <div className="dl">
            <div>
              <div>{t("Swap.confirm.Output")}</div>
              <div>
                {getShortDisplay(toAmount, 6)} {toToken.symbol}
              </div>
            </div>
            {/* <div>
              <div>Price Impact</div>
              <div>0.24%</div>
            </div> */}
          </div>
          {/* <div className="dl">
            <div>
              <div>Minimum received after slippage (0.50%)</div>
              <div>1049.73 USDT</div>
            </div>
            <div>
              <div>NetworkFee</div>
              <div>~$1.89</div>
            </div>
          </div> */}
        </li>
      </InsufficientBox>
      <TipsBtm>
        {t("Swap.confirm.estimated1")}{" "}
        <span className="font-bold">
          {getShortDisplay(lessToAmount, 6)} {toToken.symbol}
        </span>{" "}
        {t("Swap.confirm.estimated2")}
      </TipsBtm>

      <BtnGroup>
        <Button primary height={44} onClick={handleConfirm}>
          {t("Submit")}
        </Button>
      </BtnGroup>
    </General>
  );
}

const InputBoxBg = styled.div`
  width: 100%;
  background: #f9f9f9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 10px;
  align-items: center;

  height: 76px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  box-sizing: border-box;
`;
const SelectBox = styled.div`
  width: 159px;
  height: 40px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  cursor: pointer;
  .name {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-right: 8px;
    }
  }
  .arrow {
    width: 24px;
  }
`;

const BalanceBg = styled.div`
  font-size: 22px;
  font-family: "Rubik-Medium";
  overflow-x: auto;
  margin-right: 15px;
`;
const CenterBox = styled.div`
  height: 24px;
  text-align: center;
  img {
    width: 24px;
    transform: rotate(180deg);
  }
`;

const TitleBox = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  margin: 15px 0 30px;
`;

const InsufficientBox = styled.ul`
  li {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(32px);
    border-radius: 12px;
    margin-bottom: 10px;
    opacity: 0.6;
  }
  .dl {
    padding: 14px 12px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        /* margin-bottom: 12px; */
      }
    }
    &:first-child {
      /* border-bottom: 1px solid #eeecee; */
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
    &:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }
`;
const MidPriceBox = styled.div`
  line-height: 24px;
  margin: 32px 0 24px;
  span {
    font-size: 20px;
    font-family: "Poppins-Medium";
  }
`;

const TipsBtm = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 20px;
  margin-bottom: 26px;
  padding-left: 5px;
`;

const BtnGroup = styled.div`
  button {
    width: 100%;
  }
`;
