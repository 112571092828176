import { memo, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { FlexRow, Block, AddButton } from "./style";
import DateBox from "components/common/closeDate";
import {
  InputLabel,
  InputNumberItem,
} from "components/common/inputComp";
import DeleteSvg from "components/svg/create/delete";
import DeleteIcon from "assets/images/create/Close.svg";
import { MIN_DURATION } from "utils/constant";
import { formatUTCTime } from "utils/utils";

export const OneTimeRelease = ({ data, onChange, closeTime }) => {
  const { t } = useTranslation();

  const disabledDate = (current) => {
    if (closeTime) {
      return current && current < moment(closeTime).startOf("day");
    } else {
      return current && current < moment().startOf("day");
    }
  };
 
  return (
    <>
      <Tip>{t("Create.OneTimeTip")}</Tip>
      <LabelBox>
        <InputLabel
          required
          title={t("Create.VestingDate")}
          titleTip={t("Hover.Create.VestStartDate")}
        />
      </LabelBox>
      <FlexRow style={{ marginBottom: 0 }}>
        <DateBlock className="date">
          <DateBox
            targetTime={data}
            updateTargetTime={onChange}
            notUseQuick={true}
            disabledDate={disabledDate}
          />
        </DateBlock>
        <DateBlock className="ratio" />
        <DateBlock />
      </FlexRow>
    </>
  );
};

export const LinerRelease = ({ data, onChange, closeTime }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState();
  const [days, setDays] = useState();
  const periods = useMemo(() => {
    return [30, 180, 360];
  });

  const endDate = useMemo(() => {
    if (!startDate || !days) {
      return "";
    }
    const m = moment(startDate + days * MIN_DURATION);
    return formatUTCTime(m);
  }, [startDate, days]);

  useEffect(() => {
    if (data) {
      data.startDate && setStartDate(data.startDate);
      data.days && setDays(data.days);
    }
  }, [data]);

  const onChangeStartDate = (v) => {
    setStartDate(v);
    onChange({
      startDate: v,
      days,
    });
  };

  const onChangeDays = () => {
    onChange({
      startDate,
      days,
    });
  };

  const selectDays = (v) => {
    setDays(v);
    onChange({
      startDate,
      days: v,
    });
  };
  const disabledDate = (current) => {
    if (closeTime) {
      return current && current < moment(closeTime).startOf("day");
    } else {
      return current && current < moment().startOf("day");
    }
  };

  return (
    <>
      <Tip>{t("Create.LinerTip")}</Tip>
      <FlexRow style={{ marginBottom: "20px" }}>
        <Block>
          <LabelBox>
            <InputLabel
              required
              title={t("Create.ReleaseStartDate")}
              titleTip={t("Hover.Create.VestStartDate")}
            />
          </LabelBox>
          <DateBox
            targetTime={startDate}
            updateTargetTime={onChangeStartDate}
            notUseQuick={true}
            disabledDate={disabledDate}
          />
        </Block>
        <VestPeriod>
          <div className="period">
            <InputLabel required title={t("Create.VestingPeriod")} />
            <div>
              {periods.map((p) => (
                <DayButton key={p} onClick={() => selectDays(p)}>
                  {t("Create.Days", { period: p })}
                </DayButton>
              ))}
            </div>
          </div>
          <InputNumberItem
            value={days}
            controls={false}
            precision={0}
            min={1}
            onChange={(v) => setDays(v)}
            onBlur={onChangeDays}
            suffix={t("Create.Day")}
          />
        </VestPeriod>
      </FlexRow>
      <InputLabel
        required
        title={`${t("Create.ReleaseEndDate")}: ${endDate}`}
      />
    </>
  );
};

export const StageRelease = ({ data, onChange }) => {
  const { t } = useTranslation();
  const [periods, setPeriods] = useState([{}]);

  const changePeriod = (index, k, v) => {
    const arr = [];
    periods.forEach((p, i) => {
      if (i !== index) {
        arr.push({ ...p });
      } else {
        arr.push({ ...p, [k]: v });
      }
    });
    setPeriods(arr);
  };

  const addPeriod = () => {
    const newPeriods = [...periods, { random: Date.now() }];
    setPeriods(newPeriods);
    onChange(newPeriods);
  };

  const deletePeriod = (index) => {
    const newPeriods = [...periods.filter((_, i) => i !== index)];
    setPeriods(newPeriods);
    onChange(newPeriods);
  };

  const onBlurEvent = () => {
    onChange(periods);
  };

  useEffect(() => {
    if (data && data.length) {
      setPeriods(data);
    }
  }, [data]);

  return (
    <>
      <Tip>{t("Create.StateTip")}</Tip>
      <DateRow>
        <DateBlock className="title date">
          <InputLabel
            title={t("Create.VestingDate")}
            titleTip={t("Hover.Create.VestStartDate")}
          />
        </DateBlock>
        <DateBlock className="title ratio">
          {t("Create.ReleaseRatio")}
        </DateBlock>
        <DateBlock></DateBlock>
      </DateRow>
      {periods.map((p, i) => (
        <DateRow key={p.random}>
          <DateItem className="date">
            <div className="num">{i + 1}.</div>
            <DateBox
              targetTime={p.date}
              updateTargetTime={(v) => changePeriod(i, "date", v)}
              notUseQuick={true}
            />
          </DateItem>
          <DateItem className="ratio">
            <InputNumberItem
              required
              placeholder="0.00"
              name="ratio"
              controls={false}
              precision={2}
              max={100}
              onChange={(v) => changePeriod(i, "ratio", v)}
              onBlur={onBlurEvent}
              autoComplete="off"
              value={p.ratio}
              suffix="%"
              toolTipStyle={{ top: "-10px" }}
              toolTip={t("Hover.Create.VestRatio")}
            />
            <img
              className="remove"
              src={DeleteIcon}
              onClick={() => deletePeriod(i)}
              alt=""
            />
          </DateItem>
          <DateBlock></DateBlock>
        </DateRow>
      ))}
      <AddButton onClick={addPeriod}>+ {t("Create.AddMore")}</AddButton>
    </>
  );
};

const VestPeriod = styled.div`
  flex: 2;
  margin-left: 50px;
  .period {
    display: flex;
    justify-content: space-between;
  }
`;
const DayButton = styled.span`
  width: 76px;
  height: 26px;
  line-height: 26px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 12px;
  margin-left: 12px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
`;

const Tip = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 19px;
  margin-bottom: 30px;
`;

const DateRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DateBlock = styled.div`
  flex: 3;
  margin-left: 50px;
  :first-child {
    margin-left: 0;
  }
  &.date {
    flex: 3;
  }
  &.ratio {
    flex: 1;
  }
  &.ratio.title {
    opacity: 0.5;
  }
  display: flex;
  align-items: center;
`;

const DateItem = styled(DateBlock)`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  .num {
    display: inline-block;
    width: 30px;
  }
  .remove {
    position: absolute;
    right: -30px;
    cursor: pointer;
  }
`;

const LabelBox = styled.div`
  display: flex;
  align-items: center;
`