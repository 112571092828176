import { useEffect, memo, useState } from "react";
import { ethers } from "ethers";
import styled from "styled-components";
import { useDaoContext } from "views/dao/provider";
import CopyStr from "components/copy";
import useFundLogo from "hooks/useFundLogo";
import Link from "components/common/LinkIcon";
import { useTranslation } from "react-i18next";
import getIdentity from "utils/tokenIdentity";
import { AddressBox, Block } from "./styles";
import useFundToken from "hooks/useFundToken";

const ManagementFeeVote = memo(({ data, orgMembersMap }) => {
  const { t } = useTranslation();

  const {
    state: { daoChainId },
  } = useDaoContext();
  const fundLogo = useFundLogo();

  const fundToken = useFundToken(daoChainId);

  const [toAddress, setToAddress] = useState();
  const [amount, setAmount] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [identity, setIdentity] = useState("");

  useEffect(() => {
    if (!data || !fundToken) {
      return;
    }
    const wdata = ethers.utils.defaultAbiCoder.decode(
      ["address", "address", "uint256"],
      data
    );
    setToAddress(wdata[1]);
    setSymbol(fundToken.symbol);
    setAmount(ethers.utils.formatUnits(wdata[2].toString(), fundToken.deci));
    setIdentity(getIdentity(wdata[0]));
  }, [data, fundToken]);

  return (
    <Box>
      <Block title={t("Vote.Address")} className="flexBox">
        <AddressBox>
          {toAddress}
          <div className="optionGrp">
            <CopyStr address={toAddress} />
            <Link hash={toAddress} type="address" />
          </div>
        </AddressBox>
      </Block>
      <Block title={t("Vote.Amount")} className="flexBox">
        <span className="num">{amount}</span>
        <img src={fundLogo} alt="" className="logo" />
        <span className="symbol">{symbol}</span>
        {identity && <span className="identity">{identity}</span>}
      </Block>
    </Box>
  );
});

export default ManagementFeeVote;

const Box = styled.div`
  .num {
    font-family: "Rubik-Medium";
    margin-right: 10px;
  }
  .flexBox {
    img {
      width: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .identity {
    margin-left: 5px;
    opacity: 0.5;
  }
`;
