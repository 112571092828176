import METAMASK_ICON_URL from "img/wallet/metamask.png";
import WALLETCONNECT_ICON_URL from "img/wallet/walletConnect.png";
import COINBASE_ICON_URL from "img/wallet/coinbase.png";

export const Wallet = {
  INJECTED: "INJECTED",
  COINBASE_WALLET: "COINBASE_WALLET",
  WALLET_CONNECT: "WALLET_CONNECT",
  FORTMATIC: "FORTMATIC",
  NETWORK: "NETWORK",
  GNOSIS_SAFE: "GNOSIS_SAFE",
};

export const SUPPORTED_WALLETS = {
  METAMASK: {
    wallet: Wallet.INJECTED,
    name: "MetaMask",
    iconURL: METAMASK_ICON_URL,
    description: "Easy-to-use browser extension.",
    href: null,
  },
  COINBASE_WALLET: {
    wallet: Wallet.COINBASE_WALLET,
    name: 'Coinbase Wallet',
    iconURL: COINBASE_ICON_URL,
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
  },
  WALLET_CONNECT: {
    wallet: Wallet.WALLET_CONNECT,
    name: 'WalletConnect',
    iconURL: WALLETCONNECT_ICON_URL,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
  },
};

export const SELECTABLE_WALLETS = [Wallet.INJECTED, Wallet.COINBASE_WALLET, Wallet.WALLET_CONNECT]