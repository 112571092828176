import mainAddress from "./address";
import MOVR from "img/movr.png";
import MoonbaseImg from "img/newHome/moonbase.png";

export default {
  name: "Moonbase Alpha",
  simple: "moonbase",
  mainAddress: mainAddress,
  serverApi: "https://test-backend.subdao.org/api/query",
  graphApi:
    "https://api.thegraph.com/subgraphs/name/xiaosongfu/subdao-subgraph-mbase",
  daos: {
    url: "https://api.gitrows.com/@github/SubDAO-Network/daos/moon.json",
    key: "testnet",
  },
  constant: {
    fundList: [
      {
        symbol: "USDT",
        address: "0xB34F7A8D8C1F8C95c5e8fE6F29E8d4f13EbEf507",
        deci: 18,
      },
    ],
  },
  tokenLogo: MOVR,
  chainId: 1287, // '0x507'
  chainName: "Moonbase Alpha",
  nativeCurrency: {
    name: "DEV",
    symbol: "DEV",
    decimals: 18,
  },
  logo: MoonbaseImg,
  rpcUrls: ["https://rpc.testnet.moonbeam.network"],
  blockExplorerUrls: [
    "https://moonbase-blockscout.testnet.moonbeam.network/",
  ],
};
