import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers';
const { ethereum } = window;

const initWeb3 =  async () => {
    const provider = await detectEthereumProvider();
    if (provider) {
        // provider === window.ethereum
        return {
            type: 'success',
            data: provider
        }
    } else {
        return {
            type: 'error'
        };
    }
}

const account = async (web3Instance) => {
    // if (typeof ethereum == 'undefined') {
    //     return {
    //         type: 'error'
    //     };
    // }

    initWeb3();

    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    const accountAfter = ethers.utils.getAddress(accounts[0])
    sessionStorage.setItem('account', accountAfter);
    return {
        type: 'success',
        data: accountAfter
    }
}

export default {
    account,
    initWeb3,
}
