import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import { InputNumber } from "antd";
import { ethers } from "ethers";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Button from "components/common/button";
import CheckInput from "components/common/checkInput";
import { TokenAvatar, NFTTokenAvatar } from "components/common/avatar";
import { Token } from "graphql";
import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "components/provider/appProvider";
import useSigner from "hooks/useSigner";
import { useERC20Contract } from "hooks/useContract";
import ModalFooter from "components/common/modalFooter";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { useParams } from "react-router-dom";
import { formatLeftTime } from "utils/utils";
import { TransType } from "components/transaction/checkTransaction";
import publicJs from "utils/publicJs";
import api from "api";
import BaseModal from "components/modal/general";
import { InputLabel, InputBackground } from "assets/styles/common";
import Max from "components/common/max";

const SendModal = memo((props) => {
  const { show, token, balance, balanceDisplay, closeModal, moreData } = props;
  const { t } = useTranslation();
  // const mockToken = {
  //   name: "USDT",
  //   symbol: "USDT",
  //   balance: 1000.9998014,
  //   balanceDisplay: "1000.99980",
  //   address: "0x174535B59f340B4C501D5543Bf8d9249342615Dd",
  // };

  const { account, provider } = useWeb3React();
  const {
    state: { exploreScan, gateway },
    dispatch,
  } = useAppContext();

  const signer = useSigner();

  const [toAddress, setToAddress] = useState();
  const [addressInvalidMsg, setAddressInvalidMsg] = useState();
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (!show) return;
    setToAddress("");
    setAmount("");
  }, [show]);

  const onChangeToAddress = useCallback((e) => {
    const v = e.target.value;
    setToAddress(v);
    if (!ethers.utils.isAddress(v)) {
      setAddressInvalidMsg(t("Msg.InvalidAddress"));
    } else {
      setAddressInvalidMsg(null);
    }
  }, []);

  const setMaxAmount = useCallback(() => {
    setAmount(balance || 0);
  }, [balance]);

  const transferErc20 = async () => {
    const ERC20contract = await api.erc20.getContract(provider, token.address);
    const deci = token.deci;
    const formatAmount = ethers.utils.parseUnits(String(amount), deci);

    let txHash;
    let params = {
      symbol: token.symbol,
      amount: amount,
      address: publicJs.AddresstoShow(toAddress),
      dao_version: token.dao_version,
    };

    try {
      const res = await ERC20contract.connect(signer).transfer(
        toAddress,
        formatAmount
      );
      txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.SendingToken", params),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.SendToken,
          data: {
            ...params,
            address: token.address,
          },
        },
      });
    } catch (error) {
      console.error("transfer failed", error);
      // showNotification(
      //   NotificationType.Fail,
      //   t("Notification.SendTokenFailed", params),
      // );
    }
  };

  const transferErc1155 = async () => {
    const contract = await api.erc20.getNFTStockContract(
      provider,
      token.address
    );
    try {
      const res = await contract
        .connect(signer)
        .safeTransferFrom(account, toAddress, 0, amount, "0x");

      const txHash = res.hash;

      const params = {
        symbol: "NFT",
        amount: amount,
        address: publicJs.AddresstoShow(toAddress),
        et_type: token.et_type,
      };

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.SendingToken", params),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.SendToken,
          data: {
            ...params,
            address: token.address,
          },
        },
      });
    } catch (error) {
      console.error("transferErc1155", error);
    }
  };

  const confirm = async () => {
    if (!ethers.utils.isAddress(toAddress)) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidAddressTip"), closable: true },
      });
      return;
    }
    if (!amount || amount <= 0 || amount > balance) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidAmount"), closable: true },
      });
      return true;
    }

    if (token.et_type === 3) {
      await transferErc1155();
    } else {
      await transferErc20();
    }

    closeModal();
  };
  return (
    <BaseModal
      show={show}
      closeModal={closeModal}
      width={580}
      glass
      title={t("Send")}
    >
      <SendContent>
        <div className="line">
          <Label>{t("Explore.SendingFrom")}</Label>
          <SendingFrom>{account}</SendingFrom>
        </div>
        <div className="line">
          <Label>{t("Vote.Recipient")}</Label>
          <CheckInput
            autoFocus
            value={toAddress}
            onChange={onChangeToAddress}
            errorMsg={addressInvalidMsg}
          />
        </div>
        <div className="line">
          <Label>{t("Vote.Amount")}</Label>
          <TokenBox>
            <AmountLeft>
              <InputStyled
                controls={false}
                value={amount}
                precision={token?.deci ? undefined : 0}
                autoComplete="off"
                type="number"
                onChange={(v) => setAmount(v)}
              />
            </AmountLeft>
            {token && (
              <InfoRight>
                <div className="tokenBox">
                  {token.et_type === 3 ? (
                    <NFTTokenAvatar size="24px" src={gateway + token.et_logo} />
                  ) : (
                    <TokenAvatar size="24px" address={token.address} />
                  )}
                  <span className="token">{token.symbol || token.name}</span>
                </div>
                <BalanceBox>
                  <span className="balance">
                    {t("Balance")}: {balanceDisplay}
                  </span>
                  <Max t={t} onMax={setMaxAmount} />
                </BalanceBox>
              </InfoRight>
            )}
          </TokenBox>
        </div>
      </SendContent>
      <ModalFooter
        t={t}
        onCancel={closeModal}
        onSubmit={confirm}
        style={{ right: "40px" }}
      />
    </BaseModal>
  );
});
export default SendModal;

const SendContent = styled.div`
  padding: 30px 20px 64px;
  .line {
    margin-top: 20px;
  }
`;

const SendingFrom = styled.div`
  ${InputBackground};
  color: rgba(255, 255, 255, 0.5);
`;

const Label = styled.div`
  ${InputLabel};
`;

const TokenBox = styled.div`
  ${InputBackground};
  height: 76px;
  position: relative;
  color: #a6a6b7;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  padding-top: 10px;
`;

const AmountLeft = styled.div`
  flex: 1;
  .lebel {
    color: #a6a6b9;
    line-height: 19px;
  }
`;

const InputStyled = styled(InputNumber)`
  width: 100%;

  &.ant-input-number {
    background: transparent;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  &.ant-input-number-focused {
    border: none;
    box-shadow: none;
  }
  .ant-input-number-input {
    background: transparent;
    color: #ffffff;
    padding: 0;
    font-size: 18px;
  }
`;

const InfoRight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  .tokenBox {
    display: flex;
    align-items: center;
  }
  .token {
    color: #ffffff;
    line-height: 24px;
    margin-left: 8px;
    font-size: 16px;
  }
`;

const BalanceBox = styled.div`
  line-height: 20px;
  .balance {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 10px;
  }
  margin-top: 10px;
`;
