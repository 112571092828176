import { useState, useEffect, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

import { useNetworkData as useNetwork } from "./useNetwork";
import { getShortDisplay } from "../utils/publicJs";

const useNativeCurrency = (provider, account, chainId) => {
  const networkData = useNetwork(chainId);
  const [nativeToken, setNativeToken] = useState({});

  const queryBalance = async () => {
    const signer = provider.getSigner(account);
    try {
      const balance = ethers.utils.formatUnits(await signer.getBalance(), 18);
      const balanceDisplay = getShortDisplay(balance);
      return { balance: Number(balance), balanceDisplay };
    } catch (error) {
      console.error("getBalance failed");
    }
    return { balance: 0, balanceDisplay: 0 };
  }

  useEffect(async () => {
    if (networkData && account && provider) {
      const { balance, balanceDisplay } = await queryBalance();
      setNativeToken({ balance, balanceDisplay, logo: networkData.tokenLogo });
    }
  }, [provider, account, networkData]);

  return nativeToken;
};

export default useNativeCurrency;
