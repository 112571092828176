import { memo } from "react";
import styled from "styled-components";
import { useAppContext } from "components/provider/appProvider";
import Button from "components/common/button";
import { useCallback } from "react";
import { useNetworkData } from "hooks/useNetwork";
import { useWeb3React } from "@web3-react/core";
import { walletConnect } from "components/wallet/connector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseModal from "components/modal/general";
import WarningIcon from "assets/images/Warning.svg";

const SwitchChainModal = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { switch2chain },
    dispatch,
  } = useAppContext();

  const { connector, chainId } = useWeb3React();

  const chainInfo = useNetworkData(switch2chain);

  const handleClose = useCallback(() => {
    window.open(window.location.origin, "_self");
  }, [switch2chain]);

  const go2switch = async () => {
    try {
      if (connector === walletConnect) {
        await connector.activate(chainInfo.chainId);
      } else {
        await connector.activate({
          chainId: chainInfo.chainId,
          chainName: chainInfo.chainName,
          nativeCurrency: chainInfo.nativeCurrency,
          logo: chainInfo.logo,
        });
      }
      dispatch({ type: "SWITCH_CHAIN", payload: null });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BaseModal width={364} show={!!switch2chain} closeModal={handleClose} glass>
      <Msg>
        <img src={WarningIcon} alt="" />
        <div className="msg">{t("Msg.SwitchNetwork")}</div>
      </Msg>

      {connector && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button primary onClick={go2switch}>
            {t("SwitchNetwork")}
          </Button>
        </div>
      )}
    </BaseModal>
  );
});

export default SwitchChainModal;

const Msg = styled.div`
  text-align: center;
  .msg {
    margin-top: 20px;
  }
`;
