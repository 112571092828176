import styled from "styled-components";
import { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Multicall } from "ethereum-multicall";
import api from "api";
import publicJs from "utils/publicJs";
import VentureVoteConfig from "./voteConfig";

import { useAppContext } from "components/provider/appProvider";
import { useDaoContext } from "views/dao/provider";
import { useOrgContract } from "hooks/useContract";
import { ORG_MANAGER, VENTURE_MANAGER } from "utils/constant";
import { VoteInputBg } from "assets/styles/common";
import { VoteItem, Label } from "./components/style";
import VenturesABI from "abi/web3Abi/VenturesManager.json";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

const getManagerBonus = async (multicall, addressList, venturesAddress) => {
  const calls = addressList.map((addr) => ({
    reference: addr,
    methodName: "GPBonusPercent",
    methodParameters: [addr],
  }));
  const result = await multicall.call([
    {
      reference: "ventures",
      contractAddress: venturesAddress,
      abi: VenturesABI,
      calls,
    },
  ]);
  const r = {};
  result.results.ventures.callsReturnContext.forEach((d) => {
    r[d.reference] = ethers.BigNumber.from(d.returnValues[0]).toNumber() / 100;
  });
  return r;
}

export default forwardRef(function Multisign(props, ref) {
  const { t } = useTranslation();

  const {
    state: { componentAddressMap, isMultiSignWallet, daoChainId },
  } = useDaoContext();
  const { dispatch } = useAppContext();
  const { provider } = useWeb3React();

  const orgContract = useOrgContract(
    componentAddressMap.get(ORG_MANAGER),
    daoChainId
  );

  const multicall = useMemo(() => {
    if (!provider) {
      return;
    }
    return new Multicall({
      ethersProvider: provider,
      tryAggregate: true,
    });
  }, [provider]);

  const [minSupportNumber, setMinSupportNumber] = useState();
  const [gplist, setGplist] = useState([]);
  const [bonusMap, setBonusMap] = useState({});

  const getGpList = async () => {
    const { lst } = await api.org.getDaoMembersList(orgContract, 0, 1000);
    // const r = await getManagerBonus(multicall, lst.map(l=>l.address), componentAddressMap.get(VENTURE_MANAGER));
    // setBonusMap(r);
    setGplist(lst);
  };

  useEffect(() => {
    if (orgContract) {
      getGpList();
    }
  }, [orgContract]);

  useImperativeHandle(ref, () => ({
    name: "multisign",
    checkValid() {
      if (
        !minSupportNumber ||
        minSupportNumber <= 0 ||
        minSupportNumber > gplist.length + 1
      ) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: t("Msg.InvalidMinSupport"), closable: true },
        });
        return;
      }
        return true
    },
    getVoteBytes() {
      return api.vote.newModifyMultisign(minSupportNumber);
    },
  }));

  return (
    <div>
      <VoteItem>
        <Label>{t("Left.Managers")}</Label>
        <UlBox>
          {gplist.map((l, index) => (
            <li key={index}>
              <div className="lft">
                <span>{t("Vote.Address")}</span>
                <div className="address">
                  {l.address}
                  {/* {isMultiSignWallet
                    ? l.address
                    : publicJs.AddresstoShow(l.address)} */}
                </div>
              </div>
              {/* {!isMultiSignWallet && (
                <div className="rht">
                  <span>{t("Vote.Bonus")}</span>
                  <BonusInput>
                    <span className="bonus">{bonusMap[l.address]}</span>
                    <span>%</span>
                  </BonusInput>
                </div>
              )} */}
            </li>
          ))}
        </UlBox>
      </VoteItem>
      <VentureVoteConfig
        autoFocus
        minSupportNumber={minSupportNumber}
        updateMinSupportNumber={setMinSupportNumber}
        gpLength={gplist.length}
      />
    </div>
  );
});

const UlBox = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }
  input {
    flex-grow: 1;
    width: 50px;
    border: 0;
    background: transparent;
    font-size: 14px;
  }
  .lft,
  .rht {
    ${VoteInputBg};
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .lft {
    justify-content: flex-start;
  }
  .rht {
    margin-left: 20px;

    /* justify-content: space-between; */
  }
  .address {
    padding-left: 10px;
  }
  span {
    opacity: 0.6;
    font-size: 12px;
  }
`;

const BonusInput = styled.div`
  flex: 1;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bonus {
    opacity: 1;
    font-size: 14px;
  }
`;
