import styled from "styled-components";

export default function VerticalScroller({ children, ...rest }) {
    return <ScrollStyled {...rest}>{children}</ScrollStyled>;
}

const ScrollStyled = styled.div`
    height: ${(props) => props.height || "100%"};
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        display: block;
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.08);
        border-radius: 6px;
        backdrop-filter: blur(32px);
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
`;
