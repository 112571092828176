import Balancer from "assets/images/dapps/Balancer.png";
import AAVE from "assets/images/dapps/AAVE.png";
import Compound from "assets/images/dapps/Compound.png";
import Curve from "assets/images/dapps/Curve.png";
import ENS from "assets/images/dapps/ENS.png";
import Gem from "assets/images/dapps/Gem.png";
import Inch from "assets/images/dapps/Inch.png";
import Instadapp from "assets/images/dapps/Instadapp.png";
import LIDO from "assets/images/dapps/LIDO.png";
import PancakeSwap from "assets/images/dapps/PancakeSwap.png";
import QuickSwap from "assets/images/dapps/QuickSwap.png";
import Snapshot from "assets/images/dapps/Snapshot.png";
import Stellaswap from "assets/images/dapps/Stellaswap.png";
import Sushi from "assets/images/dapps/Sushi.png";
import Uniswap from "assets/images/dapps/Uniswap.png";

export default {
  Balancer,
  AAVE,
  Compound,
  Curve,
  ENS,
  Gem,
  Inch,
  Instadapp,
  LIDO,
  PancakeSwap,
  QuickSwap,
  Snapshot,
  Stellaswap,
  Sushi,
  Uniswap,
};