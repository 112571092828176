import { memo, useState, useMemo } from "react";
import styled from "styled-components";
import { Slider } from "antd";
import { ethers } from "ethers";
import {
  InputLabel,
  InputItem,
  InputNumberItem,
} from "components/common/inputComp";
import { useTranslation } from "react-i18next";
import { CardBG, FlexRow, Block, AddButton } from "./style";
import CirculateRatio, { Circulation } from "./circulateRadio";
import UploadLogo from "./upload";
import { useEffect } from "react";
import { useNetworkData } from "hooks/useNetwork";
import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "components/provider/appProvider";
import TokenSelect from "components/common/tokenSelect";
import BigNumber from "bignumber.js";
import TagSvg from "components/svg/create/tag";
import TipIcon from "assets/images/Remarks.svg";
import DeleteIcon from "assets/images/create/Close.svg";
import DateBox from "components/common/closeDate";
import PriceRatio from "./priceRadio";
import BondingCurveChart from "./bondingCurveChart";
import { getShortDisplay } from "utils/publicJs";
import { useCreateContext } from "../provider";

const TokenOneAmount = 1;

const NFTPropertyTip = ({ t }) => {
  return (
    <NFTPropertyTipPanel className="tipPanel">
      <div className="wapper">
        <InputLabel
          title={t("Create.PropertyExampleTitle")}
          style={{ color: "#FFFFFF" }}
        />
        <div className="example">{t("Create.PropertyExample")}</div>
        <div className="propertyLine font-medium">
          <span className="property">{t("Create.PropertySize")}</span>
          <span className="value">{t("Create.PropertySizeValue")}</span>
        </div>
        <div className="propertyLine font-medium">
          <span className="property">{t("Create.PropertyPower")}</span>
          <span className="value">{t("Create.PropertyPowerValue")}</span>
        </div>
      </div>
    </NFTPropertyTipPanel>
  );
};

const NFTFundraising = ({ data, savePage }) => {
  const { t } = useTranslation();

  const {
    state: { fundList, tokens },
  } = useAppContext();

  const { state: { nftImg }, dispatch } = useCreateContext();

  const [name, setName] = useState();
  const [properties, setProperties] = useState([
    { name: "", value: "", random: 123 },
  ]);

  const [circulation, setCirculation] = useState(Circulation.Yes);
  const [target, setTarget] = useState();
  const [upper, setUpper] = useState();
  const [price, setPrice] = useState();
  const [img, setImg] = useState();
  const [selectToken, setSelectToken] = useState({});
  const [originalPrice, setOriginalPrice] = useState();
  const [priceSelect, setPriceSelect] = useState(0);
  const [discountRate, setDiscountRate] = useState();
  const [discountTokenRate, setDiscountTokenRate] = useState();
  const [discountEndTime, setDiscountEndTime] = useState();
  const [curveRate, setCurveRate] = useState(100);
  const [tokenValue, setTokenValue] = useState(0);

  const [softCap, setSoftCap] = useState();

  const tokenAmount = useMemo(() => {
    if (!target || !discountTokenRate) {
      return 0;
    }
    const tokenTarget = BigNumber(target);
    const discountTokenSupply = tokenTarget.multipliedBy(
      BigNumber(discountTokenRate).dividedBy(BigNumber(100))
    );

    return discountTokenSupply.toString();
  }, [target, discountTokenRate]);

  const initPrice = useMemo(() => {
    if (!originalPrice || !discountRate) {
      return 0;
    }
    return BigNumber(originalPrice)
      .multipliedBy(BigNumber(discountRate))
      .dividedBy(BigNumber(100))
      .toNumber();
  }, [originalPrice, discountRate]);

  const _accumulatedPrice = (x, TokenSupply, initialPrice) => {
    const s = TokenSupply.multipliedBy(TokenOneAmount);
    const o = BigNumber(originalPrice)
      .multipliedBy(BigNumber(10 ** selectToken.deci))
      .minus(initialPrice);

    return o
      .multipliedBy(x)
      .multipliedBy(
        x
          .exponentiatedBy(2)
          .dividedBy(3)
          .dividedBy(s)
          .minus(
            BigNumber(curveRate)
              .multipliedBy(x.exponentiatedBy(2))
              .dividedBy(300)
              .dividedBy(s)
          )
      )
      .dividedBy(s)
      .plus(
        o
          .multipliedBy(BigNumber(curveRate).multipliedBy(x.exponentiatedBy(2)))
          .dividedBy(200)
          .dividedBy(s)
      );
  };

  useEffect(() => {
    if (
      !originalPrice ||
      !target ||
      !discountRate ||
      !discountTokenRate ||
      !selectToken ||
      !selectToken.deci
    ) {
      setTokenValue(0);
      setSoftCap(0);
      return;
    }
    const selectTokenDeci = BigNumber(10 ** selectToken.deci);

    const tokenOriginalPrice =
      BigNumber(originalPrice).multipliedBy(selectTokenDeci);

    const tokenTarget = BigNumber(target);
    const TokenSupply = tokenTarget.multipliedBy(
      BigNumber(discountTokenRate).dividedBy(BigNumber(100))
    );

    const initialPrice = tokenOriginalPrice
      .multipliedBy(BigNumber(discountRate))
      .dividedBy(100);

    let totalPrice = _accumulatedPrice(
      TokenSupply,
      TokenSupply,
      initialPrice
    ).minus(_accumulatedPrice(BigNumber(0), TokenSupply, initialPrice));

    totalPrice = totalPrice
      .plus(initialPrice.multipliedBy(TokenSupply))
      .dividedBy(TokenOneAmount);

    setTokenValue(
      getShortDisplay(
        ethers.utils
          .formatUnits(
            ethers.BigNumber.from(totalPrice.toFixed(0)),
            selectToken.deci
          )
          .toString()
      )
    );
    const leftSoftAmount = tokenTarget.minus(TokenSupply);
    const leftSoftPrice = leftSoftAmount.multipliedBy(tokenOriginalPrice);
    totalPrice = totalPrice.plus(leftSoftPrice);

    setSoftCap(
      ethers.utils
        .formatUnits(
          ethers.BigNumber.from(totalPrice.toFixed(0)),
          selectToken.deci
        )
        .toString()
    );
    // if (upper) {
    //   const leftAmount = BigNumber(upper).minus(leftSoftAmount);
    //   const leftPrice = leftAmount.multipliedBy(tokenOriginalPrice);
    //   totalPrice = totalPrice.plus(leftPrice);
    //   setHardCap(
    //     ethers.utils
    //       .formatUnits(
    //         ethers.BigNumber.from(totalPrice.toFixed(0)),
    //         selectToken.deci
    //       )
    //       .toString()
    //   );
    // }
  }, [
    originalPrice,
    target,
    discountTokenRate,
    discountRate,
    tokenAmount,
    curveRate,
    selectToken,
    priceSelect
  ]);

  const onSave = () => {
    savePage({
      name,
      properties,
      circulation,
      price,
      target,
      upper,
      img,
      selectToken,
      priceSelect,
      originalPrice,
      discountRate,
      discountTokenRate,
      discountEndTime,
      curveRate,
    });
  };

  const saveBindData = (k, v) => {
    const data = {
      name,
      properties,
      circulation,
      price,
      target,
      upper,
      img,
      selectToken,
      priceSelect,
      originalPrice,
      discountRate,
      discountTokenRate,
      discountEndTime,
      curveRate,
    };
    if (k) {
      data[k] = v;
    }
    savePage(data);
  };

  const changeProperty = (index, k, v) => {
    const arr = [];
    properties.forEach((p, i) => {
      if (i !== index) {
        arr.push({ ...p });
      } else {
        arr.push({ ...p, [k]: v });
      }
    });
    setProperties(arr);
  };

  const addProperty = () => {
    const arr = [...properties, { random: Date.now() }];
    setProperties(arr);
    saveBindData("properties", arr);
  };

  const deleteProperty = (index) => {
    const arr = [...properties.filter((_, i) => i !== index)];
    setProperties(arr);
    saveBindData("properties", arr);
  };

  const onSelectCirculation = (e) => {
    setCirculation(e.target.value);
    saveBindData("circulation", e.target.value);
  };

  const changeLogo = (imgCode, blob) => {
    setImg(imgCode);
    dispatch({ type: "SET_NFT_IMAGE", payload: blob });
    saveBindData("img", imgCode);
  };

  const onChangeFundToken = (token) => {
    setSelectToken(token);
    saveBindData("selectToken", token);
  };

  const onSelectPriceType = (e) => {
    setPriceSelect(e.target.value);
    saveBindData("priceSelect", e.target.value);
  };

  const onChangeCurveRateSlider = (v) => {
    setCurveRate(v);
    saveBindData("curveRate", v);
  };

  const onChangeDiscountEndTime = (v) => {
    setDiscountEndTime(v);
    saveBindData("discountEndTime", v);
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      data.circulation && setCirculation(data.circulation);
      data.properties &&
        data.properties.length > 0 &&
        setProperties(data.properties);
      setTarget(data.target);
      setUpper(data.upper);
      setPrice(data.price);
      data.img && setImg(data.img);
      if (data.selectToken) {
        setSelectToken(data.selectToken);
      }
      if (data.priceSelect !== undefined) {
        setPriceSelect(data.priceSelect);
      }
      setOriginalPrice(data.originalPrice);
      setDiscountRate(data.discountRate);
      if (data.discountTokenRate !== undefined) {
        setDiscountTokenRate(data.discountTokenRate);
      }
      if (data.discountEndTime) {
        setDiscountEndTime(data.discountEndTime);
      }
      if (data.curveRate !== undefined) {
        setCurveRate(data.curveRate);
      }
    }
  }, [data]);

  useEffect(() => {
    if (priceSelect === 1) {
      return;
    }
    if (!price) {
      setSoftCap(0);
      // setHardCap();
      return;
    }
    setSoftCap(
      BigNumber(price)
        .multipliedBy(BigNumber(target || 0))
        .toString()
    );
    // if (upper) {
    //   setHardCap(BigNumber(price).multipliedBy(BigNumber(upper)).toString());
    // } else {
    //   setHardCap();
    // }
  }, [price, target, priceSelect]);

  const hardcapError = useMemo(() => {
    return !upper ? false : target >= upper;
  }, [target, upper]);

  return (
    <>
      <FlexRow>
        <FirstBlock>
          <InputLabel required title={t("Create.FundType")} />
          <TokenSelect
            list={fundList.map((f) => {
              const _t = tokens?.find(
                (t) => t.address.toLowerCase() === f.address.toLowerCase()
              );
              const d = { ...f };
              if (_t) {
                d.logo = _t.logoURI;
              }
              return d;
            })}
            selected={selectToken}
            onSelect={onChangeFundToken}
          />
        </FirstBlock>
        <FirstBlock>
          <InputNumberItem
            required
            width="254px"
            title={t("Create.TokenTarget")}
            placeholder={t("Create.Enter")}
            value={target}
            controls={false}
            precision={0}
            min={0}
            onChange={(v) => {
              (v > 0 || v === null) && setTarget(v);
            }}
            onBlur={onSave}
            // toolTip={t("Hover.Create.Target")}
            suffix={t("Create.NFTs")}
          />
          <PriceTip>
            {t("Create.TotalPrice")}: {getShortDisplay(softCap || 0)}{" "}
            {selectToken?.symbol}
          </PriceTip>
        </FirstBlock>
        <FirstBlock>
          <InputNumberItem
            width="254px"
            title={t("Create.UpperLimit")}
            placeholder={t("Create.Enter")}
            value={upper}
            controls={false}
            precision={0}
            min={0}
            onChange={(v) => {
              (v > 0 || v === null) && setUpper(v);
            }}
            onBlur={onSave}
            isError={hardcapError}
            // toolTip={t("Hover.Create.Target")}
            suffix={t("Create.NFTs")}
          />
          <UpperTip>{t("Create.UpperTip")}</UpperTip>
        </FirstBlock>
      </FlexRow>
      <Divider />
      <NFTTag>
        <TagSvg />
        <span>{t("Create.NFTtip")}</span>
      </NFTTag>
      <FlexRow style={{ marginBottom: 0 }}>
        <ImageBlock>
          <InputLabel title={t("Create.Image")} required />
          <UploadLogo t={t} path={img} blob={nftImg} onChange={changeLogo} />
          <div className="imgTip">
            <div>{t("Create.ImgTip1")}</div>
            <div>{t("Create.ImgTip2")}</div>
          </div>
        </ImageBlock>
        <RightBlock>
          <FlexRow>
            <RightSingleBlock>
              <InputItem
                required
                width="254px"
                title={t("Create.NFTname")}
                name="name"
                onChange={(e) => setName(e.target.value)}
                onBlur={onSave}
                autoComplete="off"
                value={name}
              />
            </RightSingleBlock>
          </FlexRow>
          <PriceRatio t={t} value={priceSelect} onChange={onSelectPriceType} />
          {priceSelect === 0 ? (
            <RightRow>
              <RightSingleBlock>
                <InputNumberItem
                  required
                  width="254px"
                  title={t("Create.NFTprice")}
                  name="price"
                  onChange={(v) => {
                    (v > 0 || v === null) && setPrice(v);
                  }}
                  onBlur={onSave}
                  autoComplete="off"
                  value={price}
                  controls={false}
                  suffix={selectToken?.symbol}
                />
              </RightSingleBlock>
            </RightRow>
          ) : (
            <>
              <RightRow>
                <Block>
                  <InputNumberItem
                    required
                    title={t("BondingCurve.OriginalPrice")}
                    name="symbol"
                    controls={false}
                    onChange={(v) => {
                      (v > 0 || v === null) && setOriginalPrice(v);
                    }}
                    onBlur={onSave}
                    autoComplete="off"
                    value={originalPrice}
                    suffix={selectToken.symbol}
                  />
                </Block>
                <Block>
                  <InputNumberItem
                    required
                    title={t("BondingCurve.DiscountRate")}
                    name="discountRate"
                    controls={false}
                    precision={0}
                    max={100}
                    onChange={(v) => {
                      (v > 0 || v === null) && setDiscountRate(v);
                    }}
                    onBlur={onSave}
                    autoComplete="off"
                    value={discountRate}
                    suffix="%"
                    titleTip={t("BondingCurve.DiscountRateTip")}
                  />
                </Block>
              </RightRow>
              <RightRow>
                <Block>
                  <InputNumberItem
                    required
                    title={t("BondingCurve.DiscountTokenRate")}
                    name="discountTokenRate"
                    controls={false}
                    precision={0}
                    max={100}
                    onChange={(v) => {
                      (v > 0 || v === null) && setDiscountTokenRate(v);
                    }}
                    onBlur={onSave}
                    autoComplete="off"
                    value={discountTokenRate}
                    suffix="%"
                    titleTip={t("BondingCurve.DiscountTokenRateInfo")}
                  />
                </Block>
                <Block>
                  <InputLabel
                    required
                    title={t("BondingCurve.DiscountEndDate")}
                    titleTip={t("BondingCurve.DiscountEndDateTip")}
                  />
                  <DateBox
                    targetTime={discountEndTime}
                    updateTargetTime={onChangeDiscountEndTime}
                    notUseQuick={true}
                  />
                </Block>
              </RightRow>
              <RightRow>
                <Block>
                  <InputLabel
                    required
                    title={t("BondingCurve.BondingCurveRate")}
                    titleTip={t("BondingCurve.BondingCurveRateTip")}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SliderStyled
                      value={curveRate}
                      onChange={onChangeCurveRateSlider}
                    />
                    <InputNumberItem
                      width="120px"
                      value={curveRate}
                      onChange={setCurveRate}
                      controls={false}
                      precision={0}
                      max={100}
                    />
                  </div>
                </Block>
              </RightRow>
              <div style={{ marginBottom: "28px" }}>
                <BondingCurveChart
                  graphSize={300}
                  value={curveRate}
                  tokenAmount={tokenAmount}
                  tokenValue={tokenValue}
                  isInitPriceLower={discountRate < 100}
                  initPrice={initPrice}
                />
              </div>
            </>
          )}

          <LabelBox>
            <InputLabel title={t("Create.CustomProperty")} />
            <div className="tip">
              <img src={TipIcon} alt="" />
              <NFTPropertyTip t={t} />
            </div>
          </LabelBox>
          {properties.map((p, i) => (
            <PropertyRow key={p.random}>
              <PropertyBlock>
                <InputItem
                  onChange={(e) => changeProperty(i, "name", e.target.value)}
                  onBlur={onSave}
                  autoComplete="off"
                  value={p.name}
                  placeholder={t("Create.PropertyKey")}
                />
              </PropertyBlock>
              <PropertyBlock>
                <InputItem
                  onChange={(e) => changeProperty(i, "value", e.target.value)}
                  onBlur={onSave}
                  autoComplete="off"
                  value={p.value}
                  placeholder={t("Create.PropertyValue")}
                />
                <img
                  className="remove"
                  src={DeleteIcon}
                  onClick={() => deleteProperty(i)}
                  alt=""
                />
              </PropertyBlock>
            </PropertyRow>
          ))}
          <FlexRow>
            <AddButton onClick={addProperty} style={{ marginLeft: 0 }}>
              {t("Create.AddMore")} +
            </AddButton>
          </FlexRow>
        </RightBlock>
      </FlexRow>
      <Divider />
      <CirculateRatio
        t={t}
        value={circulation}
        onChange={onSelectCirculation}
      />
    </>
  );
};

export default NFTFundraising;

const RightSingleBlock = styled(Block)`
  margin-left: 0;
`;

const RightBlock = styled(Block)`
  flex: 2;
`;

const FirstBlock = styled.div`
  width: 255px;
  flex: unset;
  margin-right: 40px;
`;

const RightRow = styled(FlexRow)`
  padding-right: 220px;
`;

const PropertyRow = styled(RightRow)`
  margin-bottom: 10px;
`;
const PropertyBlock = styled(Block)`
  position: relative;
  .remove {
    position: absolute;
    right: -35px;
    top: 10px;
    cursor: pointer;
  }
`;

const UpperTip = styled.div`
  height: 16px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 18px;
  text-align: right;
  padding-right: 10px;
`;

const PriceTip = styled(UpperTip)``;
const LabelBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .tip {
    position: relative;
    bottom: 8px;
    height: 24px;
  }
  img {
    width: 24px;
    margin-left: 4px;

    opacity: 0.5;
  }
  .tip:hover {
    .tipPanel {
      display: block;
    }
  }
`;

const NFTPropertyTipPanel = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 20px;
  padding-bottom: 10px;
  display: none;
  width: 396px;
  height: 198px;

  .wapper {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 16px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 32px 25px 0;
    .example {
      height: 24px;
      margin-top: 16px;
      opacity: 0.5;
    }
    .propertyLine {
      display: flex;
      line-height: 29px;
      margin-top: 10px;
      font-size: 12px;
      span {
        height: 29px;
        background: rgba(4, 23, 53, 0.5);
        border-radius: 4px;
        backdrop-filter: blur(12px);
        padding-left: 9px;
      }
      .property {
        width: 117px;
      }
      .value {
        width: 179px;
        margin-left: 8px;
      }
    }
  }
`;

const NFTTag = styled.div`
  width: 101px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  padding: 4px 7px;
  line-height: 24px;
  align-items: center;
  margin-bottom: 28px;
  position: relative;
  svg {
    margin-right: 7px;
  }
`;

const Divider = styled.div`
  height: 1px;
  opacity: 0.1;
  border-bottom: 1px solid #ffffff;
  margin: 26px 0;
`;

const ImageBlock = styled.div`
  width: 255px;
  .imgTip {
    opacity: 0.5;
    margin-top: 10px;
  }
`;

const SliderStyled = styled(Slider)`
  flex: 1;
  margin-right: 15px;
  .ant-slider-rail {
    height: 6px;
    background: rgba(4, 23, 53, 0.5);
    border-radius: 3px;
  }
  &:hover {
    .ant-slider-rail {
      background: rgba(4, 23, 53, 0.5);
    }
  }
`;
