import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Step1 from "assets/images/walletConnect/step1.svg";
import Step2 from "assets/images/walletConnect/step2.svg";
import Step3 from "assets/images/walletConnect/step3.png";
import Arrow from "assets/images/walletConnect/arrow.svg";
import { useNetworkData } from "hooks/useNetwork";
import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";

const PurchaseNFT = memo(() => {
  const { t } = useTranslation();
  const { chainId } = useWeb3React();
  const network = useNetworkData(chainId);
  const nft = useMemo(() => {
    if (network && network.nftName) {
      return {
        name: network.nftName,
        link: network.nftLink,
      };
    }
  }, [network]);
  return (
    <PurchaseNFTStyled>
      <PurchaseIntro>
        <div className="title">{t("NFT.Purchase")}</div>
        <div className="text">
          {t("NFT.Intro01")}
          <a href={nft?.link} target="_blank" rel="noreferrer">
            <span>{nft?.name}</span>
          </a>

          {t("NFT.Intro02")}
        </div>
      </PurchaseIntro>

      <PurchaseBox>
        <Step>
          <div className="img">
            <img src={Step1} alt="" />
          </div>
          <StepTitle>{t("NFT.Connect", { app: nft?.name })}</StepTitle>
          <StepText>
            {t("NFT.ConnectText01")}
            <a href={nft?.link} target="_blank" rel="noreferrer">
              <span className="blue"> {nft?.name}</span>
            </a>
            {t("NFT.ConnectText02")}
            <span className="strong">"{t("NFT.ConnectText03")}"</span>
            {t("NFT.ConnectText04")}
          </StepText>
        </Step>
        <ArrowStyle>
          <img src={Arrow} alt="" />
        </ArrowStyle>
        <Step>
          <div className="img">
            <img src={Step2} alt="" />
          </div>
          <StepTitle>{t("NFT.Checkout")}</StepTitle>
          <StepText>{t("NFT.CheckoutText")}</StepText>
        </Step>
        <ArrowStyle>
          <img src={Arrow} alt="" />
        </ArrowStyle>
        <Step>
          <div className="img">
            <img src={Step3} alt="" />
          </div>
          <StepTitle>{t("NFT.Multi")}</StepTitle>
          <StepText>{t("NFT.MultiText")}</StepText>
        </Step>
      </PurchaseBox>
    </PurchaseNFTStyled>
  );
});

export default PurchaseNFT;

const PurchaseNFTStyled = styled.div`
  padding: 20px 40px;
  position: relative;
`;

const PurchaseBox = styled.div`
  width: 100%;
  display: flex;
`;

const Step = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  .img {
    height: 140px;
    img {
      height: 100%;
    }
  }
  @media (max-width: 1440px) {
    .img {
      height: 100px;
    }
  }
`;

const ArrowStyle = styled.div`
  width: 57px;
  margin-left: 30px;
  margin-right: 60px;
  margin-top: 70px;
  @media (max-width: 1440px) {
    display: none;
  }
`;

const PurchaseIntro = styled.div`
  margin-bottom: 25px;

  .title {
    font-family: "Poppins-Medium";
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  .text {
  }
  span {
    color: #45beff;
  }
`;

const StepTitle = styled.span`
  font-size: 16px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #328aff;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const StepText = styled.span`
  color: #a6a6b7;
  font-size: 12px;
  .blue {
    color: #45beff;
  }
  .strong {
    color: #fff;
  }
`;
