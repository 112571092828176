import React from "react";
import Dao from "./views/dao";
import Home from "./views/home";
import Explore from "views/explore";
import CreateNew from "views/createNew";

import Headertop from "components/header";

import { ReactNotifications } from "react-notifications-component";
import { ToastProvider } from "react-toast-notifications";
import CheckTransaction from "components/transaction/checkTransaction";
import GlobalModal from "views/globalModal";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

function routerlink() {
  return (
    <Router>
      <ToastProvider placement="top-center">
        <ReactNotifications />
      </ToastProvider>
      <CheckTransaction />

      {/*<Headertop />*/}
      <Routes>
        <Route path="/" element={<Home />} index />

        <Route path="/explore" element={<Explore />} />
        <Route path="/create" element={<CreateNew />} />

        <Route path="/:network/explore" element={<Explore />} />
        <Route path="/:network/:fullhandle/*" element={<Dao />} />
        <Route path="/:network" element={<Home />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <GlobalModal />
    </Router>
  );
}

export default routerlink;
