import { AlphaRouter } from "@uniswap/smart-order-router";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useMemo, useState } from "react";

import { Token, TradeType, Percent, CurrencyAmount } from "@uniswap/sdk-core";
import { ethers } from "ethers";
import { useCallback } from "react";
import { getShortDisplay } from "utils/publicJs";
import { formatInput2bignumber } from "utils/utils";
import { SupportedChainId } from "network";

const RouteState = {
  Default: 0,
  Loading: 1,
  Failed: 2,
  Success: 3,
};



export default function useRoute(inToken, outToken, inAmount) {
  const { chainId, provider } = useWeb3React();
  const router = useMemo(() => {
    // if (chainId !== SupportedChainId.MAINNET) {
    //   return;
    // }
    // return new AlphaRouter({ chainId, provider });
    return;
  }, [chainId, provider]);

  const [swapRoute, setSwapRoute] = useState();
  const [routeState, setRouteState] = useState(RouteState.Default);

  const getToken = useCallback((token) => {
    return new Token(chainId, token.address, token.deci || token.decimals, token.symbol, token.name)
  }, [chainId])
 
  const swap = async () => {
    if (!inAmount || !router) {
      return;
    }
    try {
      setRouteState(RouteState.Loading);
      const it = getToken(inToken);
      const ot = getToken(outToken);
      const inAmountBN = formatInput2bignumber(inAmount, it.decimals)
      const route = await router.route(
        CurrencyAmount.fromRawAmount(it, inAmountBN.toString()),
        ot,
        TradeType.EXACT_INPUT,
        {
          slippageTolerance: new Percent(5, 1000),
        },
        // {
        //   protocols: ["v2"],
        // }
      );
      if (!route) {
        throw Error("no route")
      }
      console.log("route: ", route)
      setRouteState(RouteState.Success);

      const routes = route.trade.routes;
      if (!route.length) {
        setSwapRoute();
      }
      if (route.length === 1) {
        return routes[0]
      }
      const seed = Math.floor(Math.random() * routes.length);
      setSwapRoute(routes[seed])
    } catch (error) {
      console.error(error);
      setRouteState(RouteState.Failed);
    }
  };

  useEffect(() => {
    if (!outToken || !inAmount || !inToken) {
      setRouteState(RouteState.Default);
      setSwapRoute();
    } else {
      swap()
    }
  }, [router, inToken, outToken, inAmount]);


  return {swapRoute, routeState};
}
