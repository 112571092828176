export const SupportedChainId = {
  BSC_TESTNET: 97,
  MOON_BASE: 1287,
  POLYGON_MUMBAI: 80001,
  MAINNET: 1,
  RINKEYBY: 4,
  ARBITRUM_ONE: 42161,
  ZKSYNC: 324,
  ZKSYNC_TESTNET: 280,
};

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.BSC_TESTNET]: "BSC Testnet",
};

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS = Object.values(SupportedChainId).filter(
  (id) => typeof id === "number"
);

export const INFURA_NETWORK_URLS = {
  // [SupportedChainId.BSC_TESTNET]:
  //   "https://data-seed-prebsc-1-s1.binance.org:8545",
  // [SupportedChainId.MOON_BASE]: "https://rpc.testnet.moonbeam.network",
  // [SupportedChainId.POLYGON_MUMBAI]: "https://matic-mumbai.chainstacklabs.com",
  [SupportedChainId.RINKEYBY]:
    "https://rinkeby.infura.io/v3/b76d7212db5141be96b1e9533e2ebd0a",
  [SupportedChainId.MAINNET]:
    "https://eth-mainnet.g.alchemy.com/v2/YuNeXto27ejHnOIGOwxl2N_cHCfyLyLE",
  [SupportedChainId.POLYGON]: `https://polygon-mainnet.g.alchemy.com/v2/rVDt1QgtXh3cJsXmU-ikr3Q2tbpBx7Hp`,
  [SupportedChainId.ARBITRUM_ONE]:
    "https://arb-mainnet.g.alchemy.com/v2/Y1T8EYy0PlU8k3RYTRse5G4sUx3gaAbt",
};
