import React, { useReducer, createContext, useContext } from "react";

const AUTH_WALLET = "SUBDAO_WALLET";
const AUTH_TOKEN_KEY = "SUBDAO_AUTH_TOKEN";
// token = {
//     token: "",
//     expireAt: 0,
//     account: "0x",
//     chainId: 1,
// }
// account = "0x"

const clearStorage = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  sessionStorage.removeItem("CreateDao_basic_info");
  sessionStorage.removeItem("CreateDao_basic_info_logo");
  sessionStorage.removeItem("CreateDao_add_manager");
  sessionStorage.removeItem("CreateDao_fundraising");
  sessionStorage.removeItem("CreateDao_chain");
};

const checkTokenValid = (token, expireAt) => {
  if (!token || !expireAt) {
    return;
  }
  if (Date.now() < Number(expireAt)) {
    return true;
  }
};

const parseToken = (tokenstr) => {
  try {
    const data = JSON.parse(tokenstr);
    return { ...data };
  } catch (error) {}
  return {};
};

const AuthContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_WALLET":
      localStorage.setItem(AUTH_WALLET, action.payload);
      return { ...state, selectedWallet: action.payload };
    case "CLEAR_AUTH":
      clearStorage()
      return { selectedWallet: state.selectedWallet };
    case "SET_AUTH_TOKEN":
      const data = action.payload;
      console.log("====TOKEN", data);
      localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(data));
      return { ...state, ...data };
    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};

const INIT_STATE = {};

const AuthProvider = (props) => {
  const tokenDataStr = localStorage.getItem(AUTH_TOKEN_KEY);
  const { token, expireAt, account, chainId } = parseToken(tokenDataStr);

  const initState = { ...INIT_STATE, selectedWallet: localStorage.getItem(AUTH_WALLET) };

  if (!checkTokenValid(token, expireAt)) {
    clearStorage();
  } else {
    initState.token = token;
    initState.expireAt = expireAt;
    initState.account = account;
    initState.chainId = chainId;
  }

  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <AuthContext.Provider value={{ state, dispatchAuth: dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => ({ ...useContext(AuthContext) });

export default AuthProvider;
