import { memo } from "react";
import styled from "styled-components";
import publicJs from "utils/publicJs";
import LinkImg from "../../../assets/images/link.svg";
import SelectedImg from "img/Selected.png";
import CopyStr from "components/copyAddress";
import {useTranslation} from "react-i18next";

const VaultTypeItem = memo((props) => {
    const { t } = useTranslation();
  const isIn = props.isIn;
  return <TagBox className={isIn && "in"}>{isIn ? t("Portfolio.history.IN") : t("Portfolio.history.OUT")}</TagBox>;
});

const TagBox = styled.span`
  display: inline-block;
  width: 38px;
  height: 22px;
  background: #f8ebce;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #a68946;
  line-height: 22px;
  margin-right: 6px;
  &.in {
    background: #cef8d7;
    color: #46a66a;
  }
`;

const showShortStr = (str, maxLength) => {
  return str.length > maxLength ? str.slice(0, maxLength)+"..." : str;
}


const NFTsTable = memo(({ list }) => {
    const { t } = useTranslation();
  return (
    <table cellPadding="0" cellSpacing="0">
      <thead>
        <tr>
          <th>{t("Portfolio.history.No")}</th>
          <th className="lft">{t("Portfolio.history.Token")}</th>
          <th style={{ textAlign: "left" }}>{t("Portfolio.history.ID")}</th>
          <th>{t("Portfolio.history.Amount")}</th>
            <th>{t("Portfolio.Type")}</th>
          <th>{t("Portfolio.history.From")}</th>
          <th>{t("Portfolio.history.To")}</th>
          {/* <th>State</th> */}
          <th>{t("Portfolio.history.Time")}</th>
            <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <TD>
              <div className="c2">
                <span>{showShortStr(item.contract_name, 15)}</span>
                <Label>{item.erc_type?.toUpperCase()}</Label>
              </div>
            </TD>
            <td style={{ textAlign: "left", paddingLeft: "6px" }}>
              {showShortStr(item.token_id, 10)}
            </td>
            <TD>{item.amount} </TD>
            <TD><VaultTypeItem isIn={item.isIn} /></TD>
            <TD>
                <div className="addrBox">
                    <CopyStr address={item.from} />
                </div>

            </TD>
            <TD>
              <div>
                  <div className="addrBox">
                      <CopyStr address={item.to} />
                  </div>


                {/*<a href={item.link} rel="noreferrer" target="_blank">*/}
                {/*  <img style={{ width: "24px" }} src={LinkImg} alt="" />*/}
                {/*</a>*/}
              </div>
            </TD>
            {/* <td>
              <img src={SelectedImg} alt="" className="finished" />
              Success
            </td> */}
            <td>{item.date}</td>
              <td>
                  <a href={item.link} rel="noreferrer" target="_blank">
                      <img style={{ width: "24px", marginRight:"24px",marginTop:"8px" }} src={LinkImg} alt="" />
                  </a>
              </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default NFTsTable;

const TD = styled.td`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .addrBox{
    border-radius: 16px;
    border: 1px solid rgba(255,255,255,0.16);
    padding: 5px 16px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    display: inline-block;
    line-height: 18px;
    cursor: pointer;
  }
`;

const Label = styled.span`
  display: inline-block;
  width: 72px;
  height: 26px;
  line-height: 26px;
  background: #cedef8;
  border-radius: 6px;
  font-size: 12px;
  color: #23334d;
  margin-left: 8px;
`;
