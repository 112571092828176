import { useMemo } from "react";
import { useDaoContext } from "../../views/dao/provider";

import Tav from "./tav";
import FundPanel from "./fund";
import LoadingBox from "components/common/loading";
import styled from "styled-components";

export default function Info() {
  const {
    state: {
      isMultiSignWallet,
      periodStatus,
      fundStatus,
      stockStatus,
      claimStatus,
      tavStatus,
    },
  } = useDaoContext();

  const isReady = useMemo(() => {
    return isMultiSignWallet
      ? tavStatus
      : periodStatus && fundStatus && stockStatus && tavStatus && claimStatus;
  }, [
    isMultiSignWallet,
    periodStatus,
    fundStatus,
    stockStatus,
    tavStatus,
    claimStatus,
  ]);

  return (
    <>
      <Loading loading={!isReady} />
      <InfoBox>
        {!isMultiSignWallet && <FundPanel isReady={isReady} />}
        <Tav isReady={isReady} />
      </InfoBox>
    </>
  );
}

const Loading = styled(LoadingBox)`
  position: absolute;
  left: 0;
  top: 0;
`;

const InfoBox = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
