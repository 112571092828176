import { useMulticallContract } from "./useContract";
import { ethers } from "ethers";
import { useEffect, useState, useMemo } from "react";
import { useNetworkData } from "./useNetwork";
import { formatBalanceDisplay } from "utils/publicJs";
import { useAppContext } from "../components/provider/appProvider";
import { useDaoContext } from "views/dao/provider";
import { Multicall } from "ethereum-multicall";
import { useClientContext } from "components/provider/clientProvider";
import erc20ABI from "abi/web3Abi/MockERC20.json";
import { NATIVE_TOKEN } from "utils/constant";

const useMulticallTokens = (tokens, balanceAddress, chainId) => {
  const network = useNetworkData(chainId);
  // const contract = useMulticallContract(network?.multicall, chainId);

  const { providers } = useClientContext();

  const multicall = useMemo(() => {
    if (!chainId) {
      return
    }
    const _config = {
      ethersProvider: providers[chainId],
      tryAggregate: true,
    };
    if (network.multicallCustomContractAddress) {
      _config.multicallCustomContractAddress =
        network.multicallCustomContractAddress;
    }
    return new Multicall(_config);
  }, [chainId]);

  const {
    state: { tokens: tokensList },
  } = useAppContext();

  const [list, setList] = useState([]);
  const [mloading, setMloading] = useState(false);

  const parseTokenInfo = (address, dataArray) => {
    // balance
    const balance = ethers.BigNumber.from(dataArray[0]).toString();
    // symbol
    const symbol = ethers.utils.parseBytes32String(
      "0x" + dataArray[1].slice(130)
    );
    // decimals
    const deci = ethers.BigNumber.from(dataArray[2]).toNumber();
    const balanceValue = ethers.utils.formatUnits(balance, deci);

    const name = ethers.utils.parseBytes32String(
      "0x" + dataArray[3].slice(130)
    );
    return {
      address,
      balance: balanceValue,
      balanceDisplay: formatBalanceDisplay(Number(balanceValue)),
      symbol,
      deci,
      name,
    };
  };

  const group = (array, subGroupLength) => {
    var index = 0;
    var newArray = [];

    while (index < array.length) {
      newArray.push(array.slice(index, (index += subGroupLength)));
    }

    return newArray;
  };

  const getTokens = async (address_list) => {
    const contextCalls = address_list.map((addr) => ({
      reference: addr,
      contractAddress: addr,
      abi: erc20ABI,
      calls: ["name", "symbol", "decimals"]
        .map((name) => ({
          reference: name,
          methodName: name,
          methodParameters: [],
        }))
        .concat([
          {
            reference: "balance",
            methodName: "balanceOf",
            methodParameters: [balanceAddress],
          },
        ]),
    }));
    const result = await multicall.call(contextCalls)
    const lst = [];
    for (const address in result.results) {
      const r = {}
      result.results[address].callsReturnContext.forEach(d => {
        r[d.reference] = d.returnValues[0];
      });
      const balance = ethers.BigNumber.from(r.balance).toString();
      const balanceValue = ethers.utils.formatUnits(balance, r.decimals);

      const logo = tokensList.find((item) => {
        return item.address.toLowerCase() === address.toLowerCase();
      });

      lst.push({
        name: r.name,
        symbol: r.symbol,
        deci: r.decimals,
        balance: balanceValue,
        balanceDisplay: formatBalanceDisplay(Number(balanceValue)),
        address,
        logoURI: logo?.logoURI,
      });
    }
    return lst
  }

  useEffect(async () => {
    if (!multicall || !tokens.length) {
      return;
    }
    setMloading(true);
   
    // TODO later will remove filter
    const lst = await getTokens(
      tokens.filter((addr) => addr.toLowerCase() !== NATIVE_TOKEN)
    );
    setList(lst);

    // const iface = new ethers.utils.Interface([
    //   "function name() view returns (string)",
    //   "function symbol() view returns (string)",
    //   "function decimals() public view returns (uint8)",
    //   "function balanceOf(address account) public view returns (uint256)",
    // ]);

    // const calls = [];
    // for (let i = 0; i < tokens.length; i++) {
    //   const symbolCallData = iface.encodeFunctionData("symbol", []);
    //   const decimals = iface.encodeFunctionData("decimals", []);
    //   const balance = iface.encodeFunctionData("balanceOf", [balanceAddress]);
    //   const name = iface.encodeFunctionData("name", []);
    //   const call1 = [tokens[i], symbolCallData];
    //   const call2 = [tokens[i], decimals];
    //   const call0 = [tokens[i], balance];
    //   const call_name = [tokens[i], name];
    //   calls.push(call0, call1, call2, call_name);
    // }

    // const tokensInfo = await contract.multiCall(calls);
    // const groupInfo = group(tokensInfo, 4);

    // setList(
    //   groupInfo.map((tk, i) => {
    //     const info = parseTokenInfo(tokens[i], tk);
    //     const logo = tokensList.find((item) => {
    //       return item.address.toLowerCase() === tokens[i].toLowerCase();
    //     });
    //     return {
    //       ...info,
    //       logoURI: logo?.logoURI,
    //     };
    //   })
    // );
    setMloading(false);
  }, [multicall, tokens]);

  return { tokens: list, mloading };
};

export default useMulticallTokens;
