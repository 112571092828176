import { useEffect, memo, useState } from "react";
import { ethers } from "ethers";

import CopyStr from "components/copy";
import Link from "components/common/LinkIcon";
import { useTranslation } from "react-i18next";
import { AddressBox, Block } from "./styles";

const TransferNFTVote = memo(({ data }) => {
  const { t } = useTranslation();

  const [toAddress, setToAddress] = useState();
  const [amount, setAmount] = useState(1);

  const [tokenAddress, setTokenAddress] = useState();
  const [tokenId, setTokenId] = useState();

  useEffect(() => {
    if (!data) {
      return;
    }
    const wdata = ethers.utils.defaultAbiCoder.decode(
      ["address", "address", "uint256", "uint256"],
      data
    );
    setToAddress(wdata[1]);
    setTokenAddress(wdata[0]);
    setTokenId(wdata[2]);
    const amt = wdata[3].toNumber();
    setAmount(amt === 0 ? 1 : amt);
  }, [data]);

  return (
    <>
      <Block title={t("Vote.Recipient")}>
        <AddressBox>
          {toAddress}
          <div className="optionGrp">
            <CopyStr address={toAddress} />
            <Link hash={toAddress} type="address" />
          </div>
        </AddressBox>
      </Block>
      <Block title={t("Vote.NFTAddress")}>
        <AddressBox>
          {tokenAddress}
          <div className="optionGrp">
            <CopyStr address={tokenAddress} />
            <Link hash={tokenAddress} type="token" />
          </div>
        </AddressBox>
      </Block>
      <Block title={t("Vote.TokenID")}>
        <AddressBox>
          {tokenId}
          <div className="optionGrp">
            <CopyStr address={tokenId} />
            <Link href={`token/${tokenAddress}?a=${tokenId}`} />
          </div>
        </AddressBox>
      </Block>
      <Block title={t("Vote.Amount")}>
        <span className="num">{amount}</span>
      </Block>
    </>
  );
});

export default TransferNFTVote;
