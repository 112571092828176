import React, {
  memo,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LogoSVG from "assets/images/logo/defaultDaoLogo.svg";

import Telegram from "assets/images/Telegram.svg";
import Twitter from "assets/images/Twittter.svg";
import Discord from "assets/images/Discord.svg";

import Managers from "./managers";
import VoteConfig from "./voteConfig";
import PreviewFund from "./fund";
import { useCreateContext } from "../provider";
import { DaoType } from "../chooseType";
import Button from "components/common/button";
import CreatingModal from "../creatingModal";
import CreatingModalSteps from "../creatingModalSteps";
import { useWeb3React } from "@web3-react/core";
import { useNetworkData } from "hooks/useNetwork";
import { ethers } from "ethers";
import { useDaoFactoryContract } from "hooks/useContract";
import useSigner from "hooks/useSigner";
import { FundraiseType } from "../fundraise";
import { ReleaseType } from "../fundraise/token";
import {
  checkBonus,
  checkInvalidBonus,
  checkRepeatAddress,
  formatBonus,
} from "utils/check";
import { useAppContext } from "components/provider/appProvider";
import { useClientContext } from "components/provider/clientProvider";
import { createDaoV2, BindDaoV2 } from "api/graphql/dao";
import { Circulation } from "../fundraise/circulateRadio";
import api from "api";
import { uploadByFetch } from "api/graphql/dao";
import goMerklizedSetup, { createAndUpload } from "utils/whitelist.js";
import { SupportedChainId } from "network";
import { MIN_DURATION } from "utils/constant";
import { trackEvent } from "utils/tagManager";
import BackIcon from "assets/images/Back.svg";
import BigNumber from "bignumber.js";
import getTarget from "../fundraise/accumulate";

const EMPTY_PARAM = "0x";
const VentureRaiseType = {
  No: 0,
  ERC20: 1,
  ERC1155: 2,
};

const VestingType = {
  OneTimeRelease: 0,
  LinearRelease: 1,
  StagedRelease: 2,
};

const Preivew = memo(({ handleBack }) => {
  const { t } = useTranslation();

  const {
    state: { chooseType, navStatus },
  } = useCreateContext();
  const {
    state: { gateway },
    dispatch,
  } = useAppContext();

  const navigator = useNavigate();

  const { chainId, account, provider } = useWeb3React();
  const networkData = useNetworkData(chainId);

  const maincontract = useDaoFactoryContract(chainId);
  const signer = useSigner();

  const [addressInput, setAddressInput] = useState("");
  const [useWhite, setUseWhite] = useState(false);
  const [tree, setTree] = useState({
    root: ethers.constants.HashZero,
    recordsCode: "",
  });

  const [baseData, setBaseData] = useState({});
  const [imgData, setImgData] = useState("");
  const [minSupportNumber, setMinSupportNumber] = useState(0);
  const [addressList, setAddressList] = useState([]);

  const [GpCarry, setGpCarry] = useState(0);
  const [manageFee, setManageFee] = useState(0);

  const [fundData, setFundData] = useState({});
  const [loadingStep, setLoadingStep] = useState();
  const [transHash, setTransHash] = useState();
  const [newDaoId, setNewDaoId] = useState(null);

  const [status, setStatus] = useState(true);
  const fundRef = useRef();

  const [nftURI, setNftURI] = useState("");

  // stock token
  // const [stockAddress, setStockAddress] = useState();
  const { authClient } = useClientContext();

  const isSteps = useMemo(() => {
    return [
      SupportedChainId.MOONBEAM,
      SupportedChainId.ZKSYNC_TESTNET,
      SupportedChainId.ZKSYNC,
    ].includes(chainId);
  }, [chainId, chooseType]);

  const selectToken = useMemo(() => {
    if (fundData?.fundraiseType === FundraiseType.NFT) {
      return fundData?.nftData?.selectToken;
    } else {
      return fundData?.tokenData?.selectToken;
    }
  }, [fundData]);

  const tokenData = useMemo(() => {
    return fundData?.fundraiseType === FundraiseType.NFT
      ? fundData?.nftData
      : fundData?.tokenData;
  }, [fundData]);

  useEffect(() => {
    try {
      const img = sessionStorage.getItem("CreateDao_basic_info_logo");
      if (img) setImgData(img);
      const basicInfo = JSON.parse(
        sessionStorage.getItem("CreateDao_basic_info") || ""
      );

      setBaseData(basicInfo);
    } catch (error) {}
    try {
      const data = JSON.parse(
        sessionStorage.getItem("CreateDao_add_manager") || ""
      );
      if (data.GpCarry) {
        setGpCarry(data.GpCarry);
      }
      if (data.manageFee) {
        setManageFee(data.manageFee);
      }
      if (data.addressList) {
        setAddressList(data.addressList);
      }
      if (data.minSupportNumber) {
        setMinSupportNumber(data.minSupportNumber);
      }
    } catch (error) {}
  }, []);

  const SocailUrls = useCallback(() => {
    if (!baseData) {
      return <></>;
    }
    return (
      <>
        {[
          { key: "twitter", icon: Twitter, prefix: "https://twitter.com/" },
          { key: "telegram", icon: Telegram, prefix: "http://t.me/" },
          { key: "discord", icon: Discord, prefix: "" },
        ].map((item) => {
          return baseData[item.key] ? (
            <a
              href={`${item.prefix}${baseData[item.key]}`}
              key={item.key}
              target="_blank"
              rel="noreferrer"
            >
              {<img src={item.icon} alt="" />}
            </a>
          ) : (
            <React.Fragment key={item.key} />
          );
        })}
      </>
    );
  }, [baseData]);

  const voteConfigDisplay = useMemo(() => {
    return `${minSupportNumber}/${addressList.length}`;
  }, [addressList, minSupportNumber]);

  const handleWhitelist = async (addressInput, managers) => {
    const addressList = addressInput.split("\n");
    if (!addressList.length) {
      return { root: ethers.constants.HashZero, recordsCode: "" };
    }
    const addressWithManagers = {};
    addressList.forEach((a) => {
      if (a) {
        addressWithManagers[a.trim()] = true;
      }
    });

    // Only add manager to whitelist if they want enable whitelist, otherwise should not add them.
    if (Object.keys(addressWithManagers).length > 0) {
      managers.forEach((a) => {
        addressWithManagers[a.address] = true;
      });
    }
    try {
      // upload
      console.log("whitelist:", Object.keys(addressWithManagers));
      const { root, records } = goMerklizedSetup(
        Object.keys(addressWithManagers)
      );
      const recordsCode = await createAndUpload(records, chainId);
      console.log("[createAndUpload] recordsCode: ", recordsCode);
      return { root, recordsCode };
    } catch (error) {
      console.error("handleWhiteList failed: ", error);
      const msg =
        (error.data && error.data.message) || "create whitelist failed";
      dispatch({ type: "MSGTYPE", payload: { msg: msg, closable: true } });
    }
  };

  useEffect(() => {
    if (chooseType !== DaoType.venture) {
      return;
    }
    try {
      const data = JSON.parse(sessionStorage.getItem("CreateDao_fundraising"));
      setFundData(data);
      setUseWhite(!!data.showWhite);
      if (data.addressInput) {
        setAddressInput(data.addressInput.trim());
      }
    } catch (error) {}
  }, [chooseType]);

  const clearStorage = () => {
    sessionStorage.removeItem("CreateDao_choose_type");
    sessionStorage.removeItem("CreateDao_basic_info");
    sessionStorage.removeItem("CreateDao_basic_info_logo");
    sessionStorage.removeItem("CreateDao_add_manager");
    sessionStorage.removeItem("CreateDao_fundraising");
    sessionStorage.removeItem("CreateDao_chain");
  };

  const formatTimestamp2second = (time) => {
    return Math.ceil(time / 1000);
  };

  const checkRequire = useCallback((data_array) => {
    console.log("data_array:", data_array);
    return data_array.some((data) => !data);
  }, []);

  const getVaultManagerParamsToken = () => {
    if (chooseType === DaoType.wallet) {
      return networkData.multiSignDefaultSupportTokens[0];
    } else {
      return selectToken?.address;
    }
  };

  const getVaultManagerParams = () => {
    console.log("[debug] getVaultManagerParams:", [
      getVaultManagerParamsToken(),
      minSupportNumber,
      addressList.length - minSupportNumber + 1,
    ]);
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "uint256", "uint256"],
      [
        getVaultManagerParamsToken(),
        minSupportNumber,
        addressList.length - minSupportNumber + 1,
      ]
    );
  };

  const getOrgManagerParams = () => {
    console.log("[debug] getOrgManagerParams:", [
      2,
      addressList.map((m) => m.name || ""),
      addressList.map((a) => a.address),
    ]);
    return ethers.utils.defaultAbiCoder.encode(
      ["uint256", "string[]", "address[]"],
      [
        2,
        addressList.map((m) => m.name || ""),
        addressList.map((a) => a.address),
      ]
    );
  };

  const getUseStockManagerName = () => {
    if (fundData.fundraiseType === FundraiseType.NFT) {
      return "VenturesNFTStockManager";
    } else {
      return "VenturesStockManager";
    }
  };

  const getVenturesStockManager = (_uri) => {
    if (fundData.fundraiseType === FundraiseType.NFT) {
      // erc1155
      let priceParams;
      if (tokenData?.priceSelect === 1) {
        // bonding curve
        const tokenOriginalPrice = BigNumber(tokenData?.originalPrice);
        const tokenTarget = BigNumber(tokenData.target);
        const discountTokenSupply = tokenTarget.multipliedBy(
          BigNumber(tokenData.discountTokenRate).dividedBy(BigNumber(100))
        );
        const initialPrice = tokenOriginalPrice
          .multipliedBy(BigNumber(tokenData.discountRate))
          .dividedBy(100);

        priceParams = ethers.utils.defaultAbiCoder.encode(
          // uint256 _initialPrice, uint256 _maxPrice, uint256 _tokenSupply, uint256 _curveRate, uint256 _expire
          ["uint256", "uint256", "uint256", "uint256", "uint256"],
          [
            ethers.utils.parseUnits(initialPrice.toString(), selectToken?.deci),
            ethers.utils.parseUnits(
              tokenOriginalPrice.toString(),
              selectToken?.deci
            ),
            Number(discountTokenSupply.toFixed(0)),
            tokenData.curveRate,
            Math.ceil(tokenData.discountEndTime / 1000),
          ]
        );
      } else {
        // fixed price
        priceParams = ethers.utils.defaultAbiCoder.encode(
          ["uint256"],
          [ethers.utils.parseUnits(String(tokenData?.price), selectToken?.deci)]
        );
      }

      return ethers.utils.defaultAbiCoder.encode(
        // string memory uri,
        // address token,
        // bool couldTransfer
        ["string", "address", "bool", "uint256", "bytes"],
        [
          _uri,
          selectToken?.address,
          tokenData?.circulation !== Circulation.No,
          tokenData?.priceSelect,
          priceParams,
        ]
      );
    } else {
      // erc20
      const equityToken = tokenData?.equityToken;

      let priceParams;
      if (tokenData?.priceSelect === 1) {
        // bonding curve
        const tokenOriginalPrice = BigNumber(tokenData?.originalPrice);
        const tokenTarget = BigNumber(tokenData?.softCap).dividedBy(
          tokenOriginalPrice
        );
        const discountTokenSupply = tokenTarget.multipliedBy(
          BigNumber(tokenData.discountTokenRate).dividedBy(BigNumber(100))
        );
        const initialPrice = tokenOriginalPrice
          .multipliedBy(BigNumber(tokenData.discountRate))
          .dividedBy(100);

        priceParams = ethers.utils.defaultAbiCoder.encode(
          // uint256 _initialPrice, uint256 _maxPrice, uint256 _tokenSupply, uint256 _curveRate, uint256 _expire
          ["uint256", "uint256", "uint256", "uint256", "uint256"],
          [
            ethers.utils.parseUnits(initialPrice.toString(), selectToken?.deci),
            ethers.utils.parseUnits(
              tokenOriginalPrice.toString(),
              selectToken?.deci
            ),
            ethers.utils.parseUnits(discountTokenSupply.toFixed(9), 9),
            tokenData.curveRate,
            Math.ceil(tokenData.discountEndTime / 1000),
          ]
        );
      } else {
        // fixed price
        priceParams = ethers.utils.defaultAbiCoder.encode(
          ["uint256"],
          [ethers.utils.parseUnits(String(tokenData?.price), selectToken?.deci)]
        );
      }

      console.log("[debug] getVenturesStockManager:", [
        equityToken.name,
        equityToken.symbol,
        selectToken?.address,
        tokenData?.circulation !== Circulation.No,
        tokenData?.priceSelect,
        priceParams,
      ]);

      return ethers.utils.defaultAbiCoder.encode(
        // (
        //   string memory tokenName,
        //   string memory tokenSymbol,
        //   address token, // fund token
        //   uint256 tokenPrice, // 0.01 usdt(decimal=6) = 10000
        //   bool couldTransfer
        // )
        ["string", "string", "address", "bool", "uint256", "bytes"],
        [
          equityToken.name,
          equityToken.symbol,
          selectToken?.address,
          tokenData?.circulation !== Circulation.No,
          tokenData?.priceSelect,
          priceParams,
        ]
      );
    }
  };

  const formatCap = (v, deci) => {
    if (v) {
      return ethers.utils.parseUnits(String(v), deci);
    } else {
      return ethers.constants.MaxUint256;
    }
  };

  const getBaiscInfoParams = () => {
    let softCap, hardCap;
    if (fundData.fundraiseType === FundraiseType.NFT) {
      if (tokenData.priceSelect === 1) {
        const { soft, hard } = getTarget(
          selectToken,
          tokenData.originalPrice,
          tokenData.target,
          tokenData.upper,
          tokenData.discountTokenRate,
          tokenData.discountRate,
          tokenData.curveRate
        );
        softCap = soft;
        hardCap = hard;
      } else {
        softCap = tokenData.target * tokenData.price;
        hardCap = tokenData.upper
          ? tokenData.upper * tokenData.price
          : undefined;
      }
    } else {
      softCap = tokenData.softCap;
      hardCap = tokenData.hardCap;
    }
    console.log("[debug] getBaiscInfoParams:", [
      Math.ceil(fundData.raiseTime / 1000),
      formatBonus(GpCarry),
      formatBonus(manageFee),
      formatCap(softCap, selectToken?.deci),
      formatCap(hardCap, selectToken?.deci),
      selectToken?.address,
      account,
    ]);
    return ethers.utils.defaultAbiCoder.encode(
      [
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "address",
        "address",
      ],
      [
        formatTimestamp2second(fundData.raiseTime),
        formatBonus(GpCarry),
        formatBonus(manageFee),
        formatCap(softCap, selectToken?.deci),
        formatCap(hardCap, selectToken?.deci),
        selectToken?.address,
        account,
      ]
    );
  };

  const getLpWhiteListParams = () => {
    console.log("[debug] getLpWhiteListParams:", [
      tree.root || ethers.constants.HashZero,
      tree.recordsCode,
    ]);
    return ethers.utils.defaultAbiCoder.encode(
      ["bytes32", "string"],
      [tree.root || ethers.constants.HashZero, tree.recordsCode]
    );
  };

  const getErc20Params = () => {
    const vestType = tokenData.releaseType;
    let vt, innerParams;
    if (vestType === ReleaseType.Liner) {
      // line
      vt = VestingType.LinearRelease;
      const data = tokenData.releaseData;
      console.log("[debug] getErc20Params:", vestType, [
        formatTimestamp2second(data.startDate),
        data.days * (MIN_DURATION / 1000),
        MIN_DURATION / 1000,
      ]);

      innerParams = ethers.utils.defaultAbiCoder.encode(
        // (address token, uint256 startTime, uint256 duration, uint256 stepTime)
        ["uint256", "uint256", "uint256"],
        [
          formatTimestamp2second(data.startDate),
          data.days * (MIN_DURATION / 1000),
          MIN_DURATION / 1000,
        ]
      );
    } else if (vestType === ReleaseType.Stage) {
      // stage
      vt = VestingType.StagedRelease;
      const data = tokenData.releaseData;
      console.log("[debug] getErc20Params:", vestType, [
        data.map((d) => ({
          timeStamp: formatTimestamp2second(d.date),
          percent: formatBonus(d.ratio).toString(),
        })),
      ]);
      innerParams = ethers.utils.defaultAbiCoder.encode(
        // (address token, ScheduleItem[] memory items)
        [
          {
            components: [
              {
                internalType: "uint256",
                name: "timeStamp",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "percent",
                type: "uint256",
              },
            ],
            internalType: "struct TokenVesting.ScheduleItem[]",
            name: "items",
            type: "tuple[]",
          },
        ],
        [
          data.map((d) => ({
            timeStamp: formatTimestamp2second(d.date),
            percent: formatBonus(d.ratio),
          })),
        ]
      );
    } else {
      // one-time
      vt = VestingType.OneTimeRelease;
      console.log("[debug] getErc20Params:", vestType, [
        formatTimestamp2second(tokenData.releaseData),
      ]);
      innerParams = ethers.utils.defaultAbiCoder.encode(
        ["uint256"],
        [formatTimestamp2second(tokenData.releaseData)]
      );
    }

    return ethers.utils.defaultAbiCoder.encode(
      ["uint256", "bytes"],
      [vt, innerParams]
    );
  };

  const getErc1155Params = () => {
    return EMPTY_PARAM;
  };

  const getVenturesManagerParams = () => {
    let gp_params, raiseParam, raiseType, raiseDetail;

    if (chooseType === DaoType.wallet) {
      raiseType = VentureRaiseType.No;
      raiseDetail = ethers.utils.defaultAbiCoder.encode(["address"], [account]);
      gp_params = EMPTY_PARAM;
    } else if (chooseType === DaoType.venture) {
      let vesting;
      if (fundData.fundraiseType === FundraiseType.NFT) {
        raiseType = VentureRaiseType.ERC1155;
        vesting = getErc1155Params();
      } else {
        raiseType = VentureRaiseType.ERC20;
        vesting = getErc20Params();
      }
      raiseDetail = ethers.utils.defaultAbiCoder.encode(
        ["bytes", "bytes", "bytes"],
        [getBaiscInfoParams(), getLpWhiteListParams(), vesting]
      );

      gp_params = ethers.utils.defaultAbiCoder.encode(
        ["address[]", "uint256[]"],
        [
          addressList.map((a) => a.address),
          addressList.map((a) => formatBonus(a.bonus).toString()),
        ]
      );
      console.log("[debug] gp_params:", [
        addressList.map((a) => a.address),
        addressList.map((a) => formatBonus(a.bonus).toString()),
      ]);
    }
    raiseParam = ethers.utils.defaultAbiCoder.encode(
      ["uint256", "bytes"],
      [raiseType, raiseDetail]
    );
    if (chooseType === DaoType.wallet) {
      console.log("[debug] getVenturesManagerParams:", [gp_params, raiseParam]);
    }

    return ethers.utils.defaultAbiCoder.encode(
      ["bytes", "bytes"],
      [gp_params, raiseParam]
    );
  };

  const getManageFeeVaultManagerParams = () => {
    return ethers.utils.defaultAbiCoder.encode(
      ["address"],
      [selectToken.address]
    );
  };

  const checkInvalidAddress = useCallback((addressList) => {
    for (const a of addressList) {
      if (!ethers.utils.isAddress(a.address)) return a.address;
    }
  }, []);

  const checkData = () => {
    if (!status) {
      return true;
    }
    if (
      checkRequire([
        baseData.description,
        baseData.name,
        baseData.daohandle,
        addressList.length,
        minSupportNumber,
      ])
    ) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.FillTip"), closable: true },
      });
      return true;
    }
    if (minSupportNumber <= 0 || minSupportNumber > addressList.length) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: t("Msg.VoteConfigError"),
          closable: true,
        },
      });
      return true;
    }
    if (checkRepeatAddress(addressList)) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: t("Msg.RepeatedAddress"),
          closable: true,
        },
      });
      return true;
    }
    const invalidAddress = checkInvalidAddress(addressList);
    if (invalidAddress) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: t("Msg.GpAddressInvalid", { address: invalidAddress }),
          closable: true,
        },
      });
      return true;
    }
    if (!navStatus) {
      return true;
    }
    for (const msg of [navStatus.basic_info, navStatus.add_manager]) {
      if (msg === "ok") {
        continue;
      }
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: t(msg),
          closable: true,
        },
      });
      return true;
    }

    if (chooseType !== DaoType.wallet) {
      if (navStatus.fundraising !== "ok") {
        dispatch({
          type: "MSGTYPE",
          payload: {
            msg: t(navStatus.fundraising),
            closable: true,
          },
        });
        return true;
      }
    }
  };

  const uploadAndGetURI = async () => {
    console.log("tokenData:", tokenData);
    // 1. generage json file
    const jsonData = {
      name: tokenData.name,
      image: tokenData.img,
    };
    if (tokenData.properties && tokenData.properties.length) {
      jsonData.properties = {};
      tokenData.properties.forEach((p) => {
        if (p.name) {
          jsonData.properties[p.name] = p.value || "";
        }
      });
    }
    console.log("json:", jsonData);
    // 2. upload json file
    const resp = await uploadByFetch(
      new Blob([JSON.stringify(jsonData)], { type: "text/json" })
    );
    if (resp.status === 200) {
      const data = await resp.json();
      console.log("upload: ", data);
      return data.data.fileUpload;
    } else {
      throw new Error(`status is ${resp.status}`);
    }
  };

  const bindDAO = (_id) => {
    trackEvent({
      event: "createDAO",
      category: "createDAO",
      action: "createDAO",
      label: "createDAO_success",
      account,
    });
    return authClient.request(BindDaoV2, {
      chainId,
      daoId: Number(_id || newDaoId),
      handle: baseData.daohandle,
      daoType: chooseType === DaoType.venture ? 1 : 2,
    });
  };

  const handleMoonSecond = async () => {
    const res = await maincontract
      .connect(signer)
      .addComponents(
        newDaoId,
        [getUseStockManagerName(), "VenturesManager"],
        [getVenturesStockManager(nftURI), getVenturesManagerParams()]
      );
    await res.wait();
  };

  const handleZkSecond = async () => {
    console.log(">>>>> start s2 <<<<<");
    const s2 = await maincontract
      .connect(signer)
      .addComponents(
        newDaoId,
        ["VaultManager", "GrantMethodManager"],
        [getVaultManagerParams(), EMPTY_PARAM]
      );
    await s2.wait();
    console.log(">>>>> start s3 <<<<<");
    const s3 = await maincontract
      .connect(signer)
      .addComponents(
        newDaoId,
        ["ManageFeeVaultManager", getUseStockManagerName()],
        [getManageFeeVaultManagerParams(), getVenturesStockManager(nftURI)]
      );
    await s3.wait();
    console.log(">>>>> start s4 <<<<<");
    const s4 = await maincontract
      .connect(signer)
      .addComponents(
        newDaoId,
        ["VenturesManager"],
        [getVenturesManagerParams()]
      );
    await s4.wait();
  };

  const goSecendStep = async () => {
    if (!newDaoId) {
      return;
    }

    try {
      if (chainId === SupportedChainId.MOONBEAM) {
        await handleMoonSecond();
      } else if (
        [SupportedChainId.ZKSYNC_TESTNET, SupportedChainId.ZKSYNC].includes(
          chainId
        )
      ) {
        await handleZkSecond();
      }
    } catch (error) {
      console.error("step 2 failed", error);
      setLoadingStep(null);
      setStatus(true);
      return;
    }

    try {
      await bindDAO();
      setLoadingStep(3);
      clearStorage();
    } catch (error) {
      console.error("step 2 failed", error);
      setLoadingStep(null);
    } finally {
      setStatus(true);
    }
  };

  const goFirstStep = async () => {
    setLoadingStep(1);
    try {
      let res;
      if (chainId === SupportedChainId.MOONBEAM) {
        res = await maincontract
          .connect(signer)
          .instanceByTemplate(
            "VentureTemplate",
            [
              "OrgManager",
              "VaultManager",
              "ManageFeeVaultManager",
              "GrantMethodManager",
            ],
            [
              getOrgManagerParams(),
              getVaultManagerParams(),
              getManageFeeVaultManagerParams(),
              EMPTY_PARAM,
            ],
            baseData.daohandle
          );
      } else {
        res = await maincontract
          .connect(signer)
          .instanceByTemplate(
            "VentureTemplate",
            ["OrgManager"],
            [getOrgManagerParams()],
            baseData.daohandle
          );
      }

      setTransHash(res.hash);
      const r = await res.wait();
      const event = r.events.filter((e) => e.event === "InstanceDAO")[0];

      const newDaoId = event.args[0].toString();
      setNewDaoId(newDaoId);
      setLoadingStep(2);
      console.log("---> newDaoId: ", newDaoId);
    } catch (error) {
      setStatus(true);
      console.error("init dao failed", error);
      setLoadingStep(null);
    }
  };

  const goVentureStep = async (_uri) => {
    setLoadingStep(1);
    try {
      const res = await maincontract
        .connect(signer)
        .instanceByTemplate(
          "VentureTemplate",
          [
            "OrgManager",
            "VaultManager",
            getUseStockManagerName(),
            "VenturesManager",
            "ManageFeeVaultManager",
            "GrantMethodManager",
          ],
          [
            getOrgManagerParams(),
            getVaultManagerParams(),
            getVenturesStockManager(_uri),
            getVenturesManagerParams(),
            getManageFeeVaultManagerParams(),
            EMPTY_PARAM,
          ],
          baseData.daohandle
        );
      setTransHash(res.hash);
      const r = await res.wait();
      const event = r.events.filter((e) => e.event === "InstanceDAO")[0];

      const newDaoId = event.args[0].toString();
      setNewDaoId(newDaoId);
      console.log("---> newDaoId: ", newDaoId);
      await bindDAO(newDaoId);
      setLoadingStep(3);
      clearStorage();
    } catch (error) {
      setStatus(true);
      console.error("goVentureStep failed", error);
      setLoadingStep(null);
    }
  };

  const handleMoonWalletSecond = async () => {
    const res = await maincontract
      .connect(signer)
      .addComponents(newDaoId, ["GrantMethodManager"], [EMPTY_PARAM]);
    await res.wait();
  };

  const handleZkWalletSecond = async () => {
    const res2 = await maincontract
      .connect(signer)
      .addComponents(
        newDaoId,
        ["GrantMethodManager", "VaultManager"],
        [EMPTY_PARAM, getVaultManagerParams()]
      );
    await res2.wait();

    const res3 = await maincontract
      .connect(signer)
      .addComponents(
        newDaoId,
        ["VenturesManager"],
        [getVenturesManagerParams()]
      );
    await res3.wait();
  };

  const goWalletSecondStep = async () => {
    if (!newDaoId) {
      return;
    }
    try {
      if (chainId === SupportedChainId.MOONBEAM) {
        await handleMoonWalletSecond();
      } else {
        await handleZkWalletSecond();
      }
    } catch (error) {
      console.error("step 2 failed", error);
      setLoadingStep(null);
      setStatus(true);
      return;
    }

    try {
      await bindDAO();
      setLoadingStep(3);
      clearStorage();
    } catch (error) {
      console.error("bindDAO failed", error);
      setLoadingStep(null);
    } finally {
      setStatus(true);
    }
  };

  const goWalletFirstStep = async () => {
    setLoadingStep(1);
    try {
      let res;
      if (chainId === SupportedChainId.MOONBEAM) {
        res = await maincontract
          .connect(signer)
          .instanceByTemplate(
            "VentureTemplate",
            ["OrgManager", "VaultManager", "VenturesManager"],
            [
              getOrgManagerParams(),
              getVaultManagerParams(),
              getVenturesManagerParams(),
            ],
            baseData.daohandle
          );
      } else {
        res = await maincontract
          .connect(signer)
          .instanceByTemplate(
            "VentureTemplate",
            ["OrgManager"],
            [getOrgManagerParams()],
            baseData.daohandle
          );
      }

      setTransHash(res.hash);
      const r = await res.wait();
      const event = r.events.filter((e) => e.event === "InstanceDAO")[0];

      const newDaoId = event.args[0].toString();
      setNewDaoId(newDaoId);
      console.log("---> newDaoId: ", newDaoId);
      setLoadingStep(2);
      clearStorage();
    } catch (error) {
      setStatus(true);
      console.error("goWalletStep failed", error);
      setLoadingStep(null);
    }
  };

  const goWalletStep = async () => {
    setLoadingStep(1);
    try {
      const res = await maincontract
        .connect(signer)
        .instanceByTemplate(
          "VentureTemplate",
          [
            "OrgManager",
            "VaultManager",
            "VenturesManager",
            "GrantMethodManager",
          ],
          [
            getOrgManagerParams(),
            getVaultManagerParams(),
            getVenturesManagerParams(),
            EMPTY_PARAM,
          ],
          baseData.daohandle
        );
      setTransHash(res.hash);
      const r = await res.wait();
      const event = r.events.filter((e) => e.event === "InstanceDAO")[0];

      const newDaoId = event.args[0].toString();
      setNewDaoId(newDaoId);
      console.log("---> newDaoId: ", newDaoId);
      await bindDAO(newDaoId);
      setLoadingStep(3);
      clearStorage();
    } catch (error) {
      setStatus(true);
      console.error("goWalletStep failed", error);
      setLoadingStep(null);
    }
  };

  const initDAO = async () => {
    return authClient.request(createDaoV2, {
      owner: account,
      chainId,
      logo: baseData.imgUrl,
      desc: baseData.description,
      handle: baseData.daohandle,
      name: baseData.name,
      twitter_url: baseData.twitter,
      discord_url: baseData.discord,
      telegram_url: baseData.telegram,
      daoType: chooseType === DaoType.venture ? 1 : 2,
    });
  };

  useEffect(() => {
    if (!isSteps) {
      return;
    }
    if (newDaoId) {
      chooseType === DaoType.wallet ? goWalletSecondStep() : goSecendStep();
    }
  }, [newDaoId, isSteps]);

  const handleSubmit = async () => {
    if (checkData()) {
      return;
    }
    setStatus(false);

    try {
      await initDAO();
    } catch (error) {
      console.error(error);
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Create.DAOHandleError"), closable: true },
      });
      setStatus(true);
      return;
    }

    if (useWhite && addressInput) {
      const tree = await handleWhitelist(addressInput, addressList);
      console.log("tree: ", tree);
      if (!tree) {
        return;
      }
      setTree(tree);
    }

    if (chooseType === DaoType.wallet) {
      if (isSteps) {
        goWalletFirstStep();
      } else {
        goWalletStep();
      }
    } else {
      console.log("component:", getUseStockManagerName());
      let uri;
      if (fundData.fundraiseType === FundraiseType.NFT) {
        uri = await uploadAndGetURI();
        setNftURI(uri);
      }
      if (isSteps) {
        goFirstStep();
      } else {
        goVentureStep(uri);
      }
    }
    // getVaultManagerParams();
    // getVenturesStockManager("aaaa")
    // getOrgManagerParams();
    // getVoteManagerParams();
    // getVenturesManagerParams(ethers.constants.AddressZero)
  };

  const closeModal = useCallback(() => {
    setLoadingStep(false);
    // jump2dao
    navigator(`/${networkData.simple}/${baseData.daohandle}/dashboard/info`);
  }, [baseData, networkData]);

  const shareLink = useMemo(() => {
    return `${window.location.protocol}//${window.location.host}/dao/${networkData.simple}:${baseData.daohandle}/dashboard/info`;
  }, [networkData, baseData]);

  return (
    <Box>
      {isSteps ? (
        <CreatingModalSteps
          show={!!loadingStep}
          closeModal={closeModal}
          step={loadingStep}
          transHash={transHash}
          name={baseData.name}
          link={shareLink}
        />
      ) : (
        <CreatingModal
          show={!!loadingStep}
          closeModal={closeModal}
          step={loadingStep}
          transHash={transHash}
          name={baseData.name}
          link={shareLink}
        />
      )}

      <TopBox>
        <img src={imgData || LogoSVG} alt="" className="logo" />
        <Rht>
          <div className="headerContent">
            <TitleLine>
              <TitleBox className="font-bold">{baseData.name}</TitleBox>
              <RatioBox className="font-bold">{voteConfigDisplay}</RatioBox>
              <ImgUl>
                <SocailUrls />
              </ImgUl>
            </TitleLine>

            <Description>
              {baseData.description?.slice(
                0,
                document.body.clientWidth > 1440 ? 180 : 90
              )}
              {(document.body.clientWidth > 1440 &&
                baseData.description?.length > 180) ||
                (document.body.clientWidth < 1440 &&
                  baseData.description?.length > 90 && (
                    <span className="more">
                      <strong>...</strong>
                      more
                    </span>
                  ))}
            </Description>
          </div>
          <RhtBox>
            <PriceBox>
              <span className="num">0</span>{" "}
              <span className="symbol">USDT</span>
            </PriceBox>
          </RhtBox>
        </Rht>
      </TopBox>
      <BottomBox isWallet={chooseType === DaoType.wallet}>
        {chooseType === DaoType.venture && (
          <PreviewFund
            ref={fundRef}
            data={fundData}
            GpCarry={GpCarry}
            manageFee={manageFee}
            gateway={gateway}
          />
        )}
        <div className={chooseType === DaoType.wallet ? "" : "boxWrapper"}>
          <Managers
            addressList={addressList}
            showBonus={chooseType === DaoType.venture}
          />
          <VoteConfig
            minSupportNumber={minSupportNumber}
            minNumber={addressList.length}
          />
        </div>
      </BottomBox>
      <SubmitBtn>
        <PrevButton onClick={handleBack}>
          <img src={BackIcon} alt="" />
        </PrevButton>
        <Button primary onClick={handleSubmit} width="110">
          {t("Submit")}
        </Button>
      </SubmitBtn>
    </Box>
  );
});

export default Preivew;

const Box = styled.div`
  height: 100%;
`;

const TopBox = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 14px 0;
  margin-bottom: 40px;
  .logo {
    width: 108px;
    height: 108px;
    margin-right: 24px;
    border-radius: 50%;
  }
`;
const Rht = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  .headerContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const TitleBox = styled.div`
  font-size: 28px;
  line-height: 42px;
  height: 39px;
  font-family: "Poppins-Bold";
`;
const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.5);
  .more {
    font-family: "Poppins-SemiBold";
    font-size: 14px;
    color: #45beff;
    text-transform: capitalize;
    margin-bottom: 3px;
  }
`;
const PriceBox = styled.div`
  display: flex;
  align-items: flex-end;
  .num {
    font-family: "Rubik-Bold";
    font-size: 28px;
    color: #45beff;
    line-height: 33px;
  }
  .symbol {
    font-family: "Poppins-SemiBold";
    color: rgba(255, 255, 255, 0.5);
    margin-left: 8px;
  }
`;

const TitleLine = styled.div`
  display: flex;
  align-items: center;
`;
const ImgUl = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 24px;
    margin-right: 24px;
    cursor: pointer;
    position: relative;
    top: 3px;
  }
`;
const RatioBox = styled.div`
  font-family: "Rubik-Medium";
  margin: 0 16px 0 24px;
  min-width: 68px;
  padding: 0 16px;
  box-sizing: border-box;
  height: 24px;
  line-height: 24px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.32) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  border-radius: 12px;
  text-align: center;
`;

const RhtBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const BottomBox = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  padding: 20px 22px 46px;
  position: relative;
  padding: ${(props) => (props.isWallet ? "50px 58px" : "30px 34px")};
  .boxWrapper {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 20px 24px;
  }
  height: calc(100% - 176px);
  overflow-y: auto;
  box-sizing: border-box;
`;

const SubmitBtn = styled.div`
  position: absolute;
  right: 34px;
  bottom: 25px;
  display: flex;
  align-items: center;
`;
const PrevButton = styled.a`
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(24px);
  margin-right: 16px;
  cursor: pointer;
  img {
    width: 24px;
    opacity: 0.4;
    margin-top: 6px;
    margin-left: 6px;
  }
`;
