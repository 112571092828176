import styled from "styled-components";

export default function DiscountText({...rest}) {
    return (
      <DiscountStyled {...rest}>
        {"Discount".split("").map((text, i) => (
          <Letter key={i} delay={i * 0.1}>
            {text}
          </Letter>
        ))}
      </DiscountStyled>
    );
}

const DiscountStyled = styled.div`
  span {
    font-family: "Poppins-BoldItalic";
  }
`;

const Letter = styled.span`
  display: inline-block;
  animation: bounce 1s ease-in 0s infinite alternate;
  animation-delay: ${({ delay }) => `${delay}s`};
  font-size: 23px;
  color: #ff719b;
  &:nth-child(4) {
    background-image: linear-gradient(126deg, #ff719c 0%, #e778ad 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
  &:nth-child(5) {
    background-image: linear-gradient(126deg, #d47dba 0%, #bd81c7 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
  &:nth-child(6) {
    background-image: linear-gradient(126deg, #b486d1 0%, #b386d2 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
  &:nth-child(7) {
    background-image: linear-gradient(126deg, #9190ea 0%, #7194fa 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
  &:nth-child(8) {
    background-image: linear-gradient(126deg, #7397ff 0%, #7398ff 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
  @keyframes bounce {
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.5);
    }
  }
`;