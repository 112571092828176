import { valueToBigNumber } from "@aave/math-utils";
import BigNumber from "bignumber.js";
import React from "react";
import { useTranslation } from "react-i18next";

const LTVContent = ({
  loanToValue,
  currentLoanToValue,
  currentLiquidationThreshold,
}) => {
  const { t } = useTranslation();
  const LTVLineWidth = valueToBigNumber(loanToValue)
    .precision(20, BigNumber.ROUND_UP)
    .toNumber();

  const CurrentLTVLineWidth = valueToBigNumber(currentLoanToValue)
    .precision(20, BigNumber.ROUND_UP)
    .toNumber();

  const currentLiquidationThresholdLeftPosition = valueToBigNumber(
    currentLiquidationThreshold
  )
    .precision(20, BigNumber.ROUND_UP)
    .toNumber();

  let LTVLineColor = "green";
  if (
    +loanToValue < +currentLoanToValue &&
    +loanToValue > +currentLoanToValue - +currentLoanToValue / 2
  ) {
    LTVLineColor = "#f89f1a";
  } else if (+loanToValue > +currentLiquidationThreshold) {
    LTVLineColor = "red";
  }

  return (
    <div
      style={{
        position: "relative",
        margin: "45px 0 55px",
        background: "#EAEBEF",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "calc(100% + 6px)",
          left: `${
            currentLiquidationThresholdLeftPosition > 100
              ? 100
              : currentLiquidationThresholdLeftPosition
          }%`,
          zIndex: 2,
        }}
      >
        <div
          style={{
            position: "relative",
            whiteSpace: "nowrap",
            "&:after": {
              content: "''",
              position: "absolute",
              left: +currentLiquidationThreshold > 75 ? "auto" : "50%",
              transform:
                +currentLiquidationThreshold > 75
                  ? "translateX(0)"
                  : "translateX(-50%)",
              right: +currentLiquidationThreshold > 75 ? 0 : "auto",
              bottom: "100%",
              height: "10px",
              width: "2px",
              bgcolor: "error.main",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              left: +currentLiquidationThreshold > 75 ? "auto" : "50%",
              transform:
                +currentLiquidationThreshold > 75
                  ? "translateX(0)"
                  : "translateX(-50%)",
              right: +currentLiquidationThreshold > 75 ? 0 : "auto",
              flexDirection: "column",
              alignItems:
                +currentLiquidationThreshold > 75 ? "flex-end" : "center",
              textAlign: +currentLiquidationThreshold > 75 ? "right" : "center",
            }}
          >
            <div style={{ color: "#BC0000B8" }}>
              {currentLiquidationThreshold}%
            </div>
            <div
              style={{
                display: "flex",
                lineHeight: "12px",
                color: "#BC0000B8",
              }}
            >
              {t("defi.Liquidation")} <br /> {t("defi.threshold")}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "calc(100% + 6px)",
          left: `${LTVLineWidth > 100 ? 100 : LTVLineWidth}%`,
          zIndex: 3,
        }}
      >
        <div
          style={{
            position: "relative",
            whiteSpace: "nowrap",
            "&:after": {
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "6px 4px 0 4px",
              borderColor: `transparent transparent transparent transparent`,
              content: "''",
              position: "absolute",
              left: LTVLineWidth > 75 ? "auto" : "50%",
              right: LTVLineWidth > 75 ? 0 : "auto",
              transform:
                LTVLineWidth > 75 ? "translateX(0)" : "translateX(-50%)",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              left:
                LTVLineWidth > 75 ? "auto" : LTVLineWidth < 15 ? "0" : "50%",
              transform:
                LTVLineWidth > 75 || LTVLineWidth < 15
                  ? "translateX(0)"
                  : "translateX(-50%)",
              right: LTVLineWidth > 75 ? 0 : "auto",
              flexDirection: "column",
              alignItems:
                LTVLineWidth > 75
                  ? "flex-end"
                  : LTVLineWidth < 15
                  ? "flex-start"
                  : "center",
              textAlign:
                LTVLineWidth > 75
                  ? "right"
                  : LTVLineWidth < 15
                  ? "left"
                  : "center",
              bottom: "calc(100% + 2px)",
            }}
          >
            <div>
              <div className="font-medium" style={{ fontSize: "12px" }}>
                {loanToValue}%
              </div>
              <div style={{ color: "#A5A8B6", fontSize: "12px" }}>
                MAX {currentLoanToValue}%
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "4px",
          width: "100%",
          borderRadius: "1px",
          position: "relative",
          bgcolor: "divider",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            height: "4px",
            borderRadius: "1px",
            width: `${LTVLineWidth > 100 ? 100 : LTVLineWidth}%`,
            maxWidth: "100%",
            background: LTVLineColor,
            zIndex: 2,
          }}
        />

        <div
          style={{
            position: "absolute",
            left: 0,
            height: "4px",
            borderRadius: "1px",
            width: `${CurrentLTVLineWidth > 100 ? 100 : CurrentLTVLineWidth}%`,
            maxWidth: "100%",
            // background: `repeating-linear-gradient(-45deg, ${palette.divider}, ${palette.divider} 4px, ${LTVLineColor} 4px, ${LTVLineColor} 7px)`,
          }}
        />
      </div>
    </div>
  );
};
export default LTVContent;
