import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import CopyStr from "../copy";

import styled from "styled-components";
import { Button } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import publicJs from "utils/publicJs";

import { useTranslation } from "react-i18next";

import WalletModal from "../wallet/modal";
import { useWeb3React } from "@web3-react/core";
import NetworkMenu from "./networkMenu";
import MoreBox from "./moreBox";
import Avatar from "components/common/avatar";
import { useAppContext } from "components/provider/appProvider";
import useCheckLogin from "hooks/useCheckLogin";
import SwitchChainModal from "./switchChainModal";
import LanImg from "../../assets/images/Language.svg";
import I18List from "../../i18n/list.json";
import useGateway from "hooks/useGateway";
import getNetworkData, { NetworkList } from "network";
import { setChainId } from "utils/utils";
import { loadGoogleTagManager } from "utils/tagManager";
import LogoImg from "../../assets/images/DAOlogo.svg";

export default function Headertop() {
  const { t,i18n } = useTranslation();
  const navigate = useNavigate();

  const { account, chainId } = useWeb3React();
  const isLogin = useCheckLogin();

  const {
    state: { showWallet, pathChainId },
    dispatch,
  } = useAppContext();

  const [showLan, setShowLan] = useState(false);
  const [currentLan, setCurrentLan] = useState(0);

    const { pathname } = useLocation();

  const gateway = useGateway();

  const navigatePrefix = useMemo(() => {
    return NetworkList.find((n) => n.chainId === pathChainId)?.simple || "";
  }, [pathChainId]);


  useEffect(() => {
    if (!pathname || pathname === "ok") {
      return;
    }
    const startPath = pathname.split("/");
    if (["explore", "create"].find((p) => p === startPath[1])) {
      return;
    }
    const net = NetworkList.find(
      (n) => n.simple === startPath[1].toLowerCase()
    );
    if (!net) {
      navigate("/");
      return;
    }
    dispatch({
      type: "SET_DEFAULT_CHAIN_ID",
      payload: net.chainId,
    });
  }, [pathname, chainId]);

  useEffect(() => {
    let selectedStorage;
    if (account == null) {
      selectedStorage = sessionStorage.getItem("account");
    } else {
      selectedStorage = account;
    }
  }, [account]);

  const toFirst = () => {
    navigate(navigatePrefix ? `/${navigatePrefix}` : "/");
  };

  useEffect(() => {
    if (gateway) {
      dispatch({ type: "SET_GATEWAY", payload: gateway });
    }
  }, [gateway]);

  const toExplore = () => {
    navigate(navigatePrefix ? `/${navigatePrefix}/explore` : "/explore");
  };

  const showWalletContent = useMemo(() => {
    if (showWallet && chainId && pathChainId && chainId !== pathChainId) {
      dispatch({
        type: "SWITCH_CHAIN",
        payload: pathChainId,
      });
      dispatch({ type: "CONNECT_WALLET", payload: false });
      return <></>;
    }
    return showWallet ? (
      <WalletModal
        show={showWallet}
        closeModal={() => {
          dispatch({ type: "CONNECT_WALLET", payload: false });
        }}
      />
    ) : (
      <></>
    );
  }, [showWallet]);

  const openWallet = () => {
    dispatch({ type: "CONNECT_WALLET", payload: true });
  }

  const go2Search = useCallback(
    ({ target: { value } }) => {
      if (!value) {
        return;
      }
      navigate(`/explore?search=${value}`);
    },
    [navigate]
  );
  const handleLan = (item) =>{
      i18n.changeLanguage(item.value);
      window.location.reload();
  }
  useEffect(()=>{
      document.addEventListener("click",()=>{
          setShowLan(false);
      })
    loadGoogleTagManager();
  }, [])
  
  useEffect(() => {
    chainId && setChainId(parseInt(chainId));
  }, [chainId]);

    useEffect(()=>{
        const index = I18List.findIndex((item)=>item.value === i18n.language)
        setCurrentLan(index)
    },[i18n.language])


  const handleSwitchLan = (e) =>{
      e.nativeEvent.stopImmediatePropagation();
      setShowLan(true);
  }

  return (
    <HeaderBg >
      {showWalletContent}
      <HeaderBox>
        <LogoBox onClick={() => toFirst()}>
            {/*<ReactSVG className="img" src={LogoSVG} />*/}
            <img src={LogoImg} alt=""/>
        </LogoBox>
        <RhtBox>
              <div className="lineFlex">
                  <a href="https://wiki.subdao.network/" target="_blank" rel="noreferrer">
                      <Explore>
                          { t("Header.guide")}
                      </Explore>
                  </a>
                  <Explore onClick={() => toExplore()}>{ t("Header.Explore")}</Explore>
                  <SwitchBox onClick={(e) => handleSwitchLan(e)}>
                      {/*<EarthSVG onClick={(e) => handleSwitchLan(e)}  />*/}

                          <div className="topBox">
                              <img src={LanImg} alt=""/>
                              <span>{i18n.language}</span>
                          </div>

                      {
                          showLan && <ModalBox>
                              {
                                  I18List.map((item,index)=>( <li className={currentLan===index ?"active":""} onClick={()=>handleLan(item)} key={`lan_${index}`}>{item.name}</li>))
                              }
                          </ModalBox>
                      }

                  </SwitchBox>
              </div>

            {/*{!!account && (*/}
            {/*  <BoxBrder>*/}
            {/*    <SearchInput*/}
            {/*      bordered={false}*/}
            {/*      placeholder={t("Header.Search")}*/}
            {/*      onPressEnter={go2Search}*/}
            {/*      prefix={<img src={SearchImg} alt="" className="search" />}*/}
            {/*    />*/}
            {/*  </BoxBrder>*/}
            {/*)}*/}
              <div className="lineFlex">
                  <NetworkMenu />

                  {account && isLogin ? (
                      <BoxBrder>
                          <Avatar address={account} size="24px" className="avatar" />
                          <div className="addressBrdr">
                              <span>{publicJs.AddresstoShow(account)}</span>
                              <CopyStr address={account} />
                          </div>
                      </BoxBrder>
                  ) : (
                      <Button className="signin" onClick={openWallet}>
                          {t("Wallet.ConnectWallet")}
                      </Button>
                  )}
                  <MoreBox account={account} />
              </div>


        </RhtBox>
      </HeaderBox>
      <SwitchChainModal />
    </HeaderBg>
  );
}


const HeaderBox = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 33px;
  box-sizing: border-box;
`
const LogoBox = styled.div`
    width: 48px;
  height: 48px;
  img{
    width: 48px;
    height: 48px;
  }
`

const RhtBox = styled.div`
    display: flex;
  align-items: center;
  justify-content: flex-end;
  .lineFlex{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .signin{
    padding: 0 25px;
    background: #FFFFFF;
    border-radius: 20px;
    font-weight: 400;
    color: #131C58;
    margin-right: 16px;
    line-height: 20px;
    height: 36px;
  }
`

const SwitchBox = styled.div`
  width: 100px;
  height: 36px;
  box-sizing: border-box;
  position: relative;
  .topBox {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;
const ModalBox = styled.ul`
  position: absolute;
  left: 0;
  top:45px;
  z-index: 99;
  background: rgba(255,255,255,0.08);
  border-radius: 8px;
  border: 1px solid rgba(255,255,255,0.08);
  backdrop-filter: blur(16px);
  padding: 10px;
  .tit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,0.08);
    padding-bottom: 11px;
  }
  ul{
    margin-top: 5px;
  }
  li{
    line-height: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 5px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    &:hover{
      background:rgba(255,255,255,0.1);
    }
  }
`

const HeaderBg = styled.header`
 
`;

const BoxBrder = styled.div`
  height: 36px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  box-sizing: border-box;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .addressBrdr {
    display: flex;
    align-items: center;
  }
`;

const Explore = styled.div`
  font-size: 14px;
  font-family: "Poppins-SemiBold";
  margin-right: 24px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 21px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;
