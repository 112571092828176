import { useMemo } from "react";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";
import { initializeConnector, Web3ReactHooks } from "@web3-react/core";
import { Wallet, SELECTABLE_WALLETS } from "./wallet";
import { INFURA_NETWORK_URLS } from "network/chain";

function onError(error) {
  console.debug(`web3-react error: ${error}`);
}

export const [injected, injectedHooks] = initializeConnector(
  (actions) => new MetaMask({ actions, onError })
);
export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: "",
        appName: "SubDAO",
        appLogoUrl: "",
      },
      onError,
    })
);
export const [walletConnect, walletConnectHooks] = initializeConnector(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        rpc: INFURA_NETWORK_URLS,
        qrcode: true,
      },
      onError,
    })
);

export const getWalletForConnector = (connector) => {
  switch (connector) {
    case injected:
      return Wallet.INJECTED;
    case coinbaseWallet:
      return Wallet.COINBASE_WALLET;
    case walletConnect:
      return Wallet.WALLET_CONNECT;
    default:
      throw Error("unsupported connector");
  }
};

export const getConnectorForWallet = (wallet) => {
  switch (wallet) {
    case Wallet.INJECTED:
      return injected;
    case Wallet.COINBASE_WALLET:
      return coinbaseWallet;
    case Wallet.WALLET_CONNECT:
      return walletConnect;
    default:
      return;
  }
};

function getHooksForWallet(wallet) {
  switch (wallet) {
    case Wallet.INJECTED:
      return injectedHooks;
    case Wallet.COINBASE_WALLET:
      return coinbaseWalletHooks;
    case Wallet.WALLET_CONNECT:
      return walletConnectHooks;
    default:
      return;
  }
}

function getConnectorListItemForWallet(wallet) {
  return {
    connector: getConnectorForWallet(wallet),
    hooks: getHooksForWallet(wallet),
  };
}

export const useConnectors = (selectedWallet) => {
  return useMemo(() => {
    const connectors = [];
    if (selectedWallet) {
      connectors.push(getConnectorListItemForWallet(selectedWallet));
    }
    connectors.push(
      ...SELECTABLE_WALLETS.filter((wallet) => wallet !== selectedWallet).map(
        getConnectorListItemForWallet
      )
    );
    const web3ReactConnectors = connectors.map(({ connector, hooks }) => [
      connector,
      hooks,
    ]);
    return web3ReactConnectors;
  }, [selectedWallet]);
};
