import { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const VoteConfig = memo(({ minSupportNumber, minNumber }) => {
  const { t } = useTranslation();
  return (
    <VoteconfigStyled>
      <span>{t("Vote.VoteConfigTip")}</span>
      <span className="value font-bold">{minSupportNumber}</span>
      <span>{t("Vote.VoteConfigNumber", { gp: minNumber })}</span>
    </VoteconfigStyled>
  );
});

export default VoteConfig;

const VoteconfigStyled = styled.div`
  line-height: 28px;
  margin-top: 20px;
  .value {
    display: inline-block;
    min-width: 44px;
    height: 44px;
    line-height: 44px;
    background: rgba(4, 23, 53, 0.2);
    border-radius: 10px;
    margin: 0 8px;
    text-align: center;
  }
`;
