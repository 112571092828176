import styled from "styled-components";
import {Checkbox} from "antd";
import React, {useState} from "react";
import useCheckLogin from "../../hooks/useCheckLogin";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../provider/appProvider";
import { useTranslation } from "react-i18next";
import Button from "components/common/button";
import VerticalScroller from "components/common/verticalScroller";

const Box = styled.div`
  height: 764px;
  position: relative;
  padding: 60px 40px 20px;
  box-sizing: border-box;
  @media (max-width: 1440px) {
    height: 620px;
  }
`;
const TitleBox = styled.div`
  font-size: 28px;
  font-family: "Poppins-SemiBold";
  font-weight: normal;
  line-height: 33px;
  margin-bottom: 20px;
`;
const ContentBox = styled(VerticalScroller)`
  height: 570px;
  box-sizing: border-box;

  .desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    .mt20 {
      padding-top: 20px;
    }
  }
  @media (max-width: 1440px) {
    height: 430px;
  }
`;
const BtnBox = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 40px;
  bottom: 20px;
  span{
    line-height: 34px;
    cursor: pointer;
    margin-right: 30px;
  }
`

const Agreement = styled.div`
  display: flex;
  align-items: center;
  margin-top: 44px;
  span {
    font-size: 16px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    line-height: 26px;
    color: #fff;
  }
`;

export default function Legal(props){
    const [agree,setAgree] = useState(true);
    const isLogin = useCheckLogin();
    const navigate = useNavigate();
    const {dispatch} = useAppContext();
    const { t } = useTranslation();

    const handleCheck = (e) => {
        const {value} = e.target;
        setAgree(!JSON.parse(value));
    }
    const go2create = () => {
        console.log("=====",isLogin)
        if (isLogin) {
          navigate("/create", { state: props.state });
        }else {
            dispatch({ type: "CONNECT_WALLET", payload: true });
        }
    }
  
  const goBack = () => {
    props.closeModal && props.closeModal();
  }


    return <Box >
        <TitleBox>{t("Create.Modal.legal")}</TitleBox>
        <ContentBox>
            <div className="desc">
                <p>{t("Create.Modal.p1")}</p>
                <p>{t("Create.Modal.p2")}</p>

                <ul>
                    <li>- {t("Create.Modal.li1")}</li>
                    <li>- {t("Create.Modal.li2")}</li>
                    <li>- {t("Create.Modal.li3")}</li>
                    <li>- {t("Create.Modal.li4")}</li>
                    <li>- {t("Create.Modal.li5")}</li>
                    <li>- {t("Create.Modal.li6")}</li>
                    <li> - {t("Create.Modal.li7")}</li>
                    <li> - {t("Create.Modal.li8")}</li>
                    <li>{t("Create.Modal.li9")}</li>
                </ul>
                <p className="mt20">
                    {t("Create.Modal.audited")}
                </p>

            </div>
            <Agreement>
                <Checkbox
                    value={agree}
                    checked={agree}
                    name='admin'
                    onChange={handleCheck}
                >
                    {t("Create.Modal.agree")}
                </Checkbox>

            </Agreement>
        </ContentBox>
        <BtnBox>
            <Button onClick={goBack} style={{marginRight: "16px"}}>{ t("Cancel")}</Button>
            <Button primary onClick={()=>go2create()} disabled={!agree}>{t("Confirm")}</Button>
        </BtnBox>
    </Box>
}