import styled from "styled-components";
import { DepositContent } from "./style";
import {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { ethers } from "ethers";
import Max from "components/common/max";
import { Input } from "antd";
import TokenSelect from "./tokenSelect";
import BigNumber from "bignumber.js";
import SwapIcon from "assets/images/Swap.svg";
import { TokenAvatar } from "components/common/avatar";
import SLoading from "components/common/sloading";

export default forwardRef(function FixPrice(
  { t, canInput, stockToken, fundTokenList, setBalanceEnough, loadingFund },
  ref
) {
  const [fundAmount, setFundAmount] = useState();
  const [tokenAmount, setTokenAmount] = useState();

  const [selectFundIdx, setSelectFundIdx] = useState(0);

  const selectFund = useMemo(() => {
    return fundTokenList[selectFundIdx];
  }, [selectFundIdx, fundTokenList]);

  const setMax = () => {
    if (!selectFund) {
      return;
    }
    const v = ethers.utils.formatUnits(selectFund.balance, selectFund.deci);
    setFundAmount(v);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value) {
      if (value.split(".").length > 1) {
        const nv = BigNumber(value).shiftedBy(selectFund.deci).toFixed();
        if (nv.split(".").length > 1) {
          setFundAmount(
            BigNumber(nv.split(".")[0]).shiftedBy(-selectFund.deci).toString()
          );
          return;
        }
      }
      setFundAmount(value);
    } else {
      setFundAmount();
    }
  };

  useEffect(() => {
    if (fundAmount && stockToken?.price) {
      setTokenAmount(
        BigNumber(fundAmount).dividedBy(stockToken?.price).toNumber()
      );
    } else {
      setTokenAmount();
    }
  }, [fundAmount]);

  useEffect(() => {
    if (
      !fundAmount ||
      !selectFund ||
      selectFund.balance.eq(ethers.BigNumber.from(0))
    ) {
      setBalanceEnough(true);
      return;
    }
    const amountBN = ethers.utils.parseUnits(fundAmount, selectFund.deci);
    setBalanceEnough(amountBN.gt(selectFund.balance) ? true : false);
  }, [fundAmount, selectFund]);

  useImperativeHandle(ref, () => ({
    data: {
      token: selectFund,
      useNative: selectFund?.isNative,
      amount: fundAmount,
      minAmount: 0,
    },
  }));

  return (
    <DepositContent className="font-regular">
      <InputBox>
        <InputBG />
        <div className="leftInput">
          <InputLabel>{t("Period.DepositAmount")}</InputLabel>
          <InputStyled
            disabled={!canInput}
            placeholder={t("Create.Enter")}
            name="fundAmount"
            value={fundAmount}
            autoComplete="off"
            type="number"
            autoFocus
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <TokenBox className="pos">
          <div className="tokenTop">
            <TokenSelect
              list={fundTokenList || []}
              selectIdx={selectFundIdx}
              onSelect={setSelectFundIdx}
            />
          </div>
          <BalanceBox>
            <div className="balance">
              <span>{t("Balance")} :</span>
              {loadingFund ? (
                <SLoading />
              ) : (
                <span>{selectFund?.balanceDisplay}</span>
              )}
            </div>
            <Max t={t} onMax={setMax} />
          </BalanceBox>
        </TokenBox>
      </InputBox>
      <SwapBox>
        <img src={SwapIcon} alt="" />
      </SwapBox>
      <InputBox>
        <InputBG />
        <div className="leftInput">
          <InputLabel>{t("Venture.Receiving")}</InputLabel>
          <TokenAmount>{tokenAmount}</TokenAmount>
        </div>
        <TokenBox>
          <div className="tokenTop">
            <TokenAvatar address={stockToken?.address} size="24px" />
            <span>{stockToken?.symbol}</span>
          </div>
        </TokenBox>
      </InputBox>
    </DepositContent>
  );
});

const InputBG = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 0;
`;

const InputBox = styled.div`
  width: 100%;
  height: 76px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: relative;
  box-sizing: border-box;
  .leftInput {
    width: 50%;
    position: relative;
  }
`;

const InputLabel = styled.div`
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 19px;
  font-size: 12px;
  margin-bottom: 6px;
`;

const InputStyled = styled(Input)`
  &.ant-input {
    background: transparent;
    height: 27px !important;
    padding: 0;
    font-size: 20px;
    border: none;
    color: #ffffff;
    font-family: "Rubik-Medium";
    &:focus {
      box-shadow: none;
    }
  }
`;

const TokenBox = styled.div`
  .tokenTop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .symbol {
    font-size: 16px;
  }
  &.pos {
    position: absolute;
    right: 20px;
    top: 13px;
  }
  .token {
    display: flex;
    align-items: center;
  }
`;

const BalanceBox = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  margin-top: 8px;
  .balance {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
`;

const SwapBox = styled.div`
  text-align: center;
  height: 24px;
  margin: 8px 0;
  img {
    width: 24px;
    transform: rotate(180deg);
  }
`;

const TokenAmount = styled.div`
  height: 24px;
  font-size: 20px;
  font-family: "Rubik-Medium";
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
`;