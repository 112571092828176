import { useEffect, memo, useMemo, useState } from "react";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Multicall } from "ethereum-multicall";
import OngoingImg from "assets/images/vote/ongoing.svg";
import AgreeImg from "assets/images/vote/success.svg";
import RejectedImg from "assets/images/vote/fail.svg";

import ConfirmImg from "assets/images/Success.svg";
import WrongImg from "assets/images/Failed.svg";

import CopyStr from "components/copy";
import VoteEcharts from "./voteEcharts";

import api from "api";

import { useWeb3React } from "@web3-react/core";
import { useVaultContract } from "hooks/useContract";
import { useAppContext } from "components/provider/appProvider";
import { useDaoContext } from "views/dao/provider";
import { ORG_MANAGER, VAULT_MANAGER } from "utils/constant";

import TransferTokenVote from "./transferToken";
import TransferNFTVote from "./transferNFT";
import AddManagerVote from "./addManager";
import RemoveManagerVote from "./removeManager";
import TransferManageFeeVote from "./transferManagefee";
import ApproveVote from "./super";
import BackNav from "components/common/backNav";
import Link from "components/common/LinkIcon";

import { AddressBox as HashBox } from "./styles";

import useOrgMembers from "hooks/useOrgMembers";
import useSigner from "hooks/useSigner";

import TransferImg from "assets/images/vote/transfer.svg";
import FeeImg from "assets/images/vote/fee.svg";
import SuperImg from "assets/images/vote/super.svg";
import RedemptionImg from "assets/images/vote/redemption.svg";
import AddManagerImg from "assets/images/vote/add.svg";
import RemoveManagerImg from "assets/images/vote/remove.svg";
import MultisignImg from "assets/images/vote/multisign.svg";

import Avatar from "components/common/avatar";
import useCheckLogin from "hooks/useCheckLogin";
import { TransactionEvent } from "utils/constant";
import useSubcribe from "hooks/useSubscribe";
import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import { GlassBG } from "assets/styles/common";
import AddressBox from "components/common/addressBox";
import { Block } from "./styles";
import useMulticallTxs from "components/apps/walletConnect/useMulticallTxs";
import { parseVoteData } from "utils/parseVoteDataUtil";
import ModifyMultisign from "./multisign";
import { SubgraphApiQuery, getTxDetail } from "api/graphql/subQuery";
import { useClientContext } from "components/provider/clientProvider";
import { formatTextTime } from "utils/utils";
import { ethers } from "ethers";
import { useNetworkData } from "hooks/useNetwork";

export const VOTE_TYPE = {
  TRANSFER_TOKEN: "transfer",
  TRANSFER_NFT: "transferNFT",
  TRANSFER_MANAGE_FEE: "transferManageFee",
  TRANSFER_PERIOD: "transferperiod",
  ADD_MANAGER: "acceptgp",
  REMOVE_MANAGER: "impeach",
  APPROVE_MANAGER: "grantmethod",
  MODIFY_MULTISIGN: "multisign",
};

export const VOTE_INFO = {
  [VOTE_TYPE.TRANSFER_NFT]: {
    name: "Vote.Transfer",
    shortName: "Vote.Transfer",
    icon: TransferImg,
  },
  [VOTE_TYPE.TRANSFER_TOKEN]: {
    name: "Vote.Transfer",
    shortName: "Vote.Transfer",
    icon: TransferImg,
  },
  [VOTE_TYPE.TRANSFER_PERIOD]: {
    name: "Vote.ChangePeriod",
    shortName: "Vote.Redemption",
    icon: RedemptionImg,
  },
  [VOTE_TYPE.ADD_MANAGER]: {
    name: "Vote.AddManager",
    shortName: "Vote.AddManager",
    icon: AddManagerImg,
  },
  [VOTE_TYPE.REMOVE_MANAGER]: {
    name: "Vote.RemoveManager",
    shortName: "Vote.RemoveManager",
    icon: RemoveManagerImg,
  },
  [VOTE_TYPE.APPROVE_MANAGER]: {
    name: "Vote.SuperManager",
    shortName: "Vote.SuperManager",
    icon: SuperImg,
  },
  [VOTE_TYPE.TRANSFER_MANAGE_FEE]: {
    name: "Vote.ManagementFeePool",
    shortName: "Vote.ManagementFee",
    icon: FeeImg,
  },
  [VOTE_TYPE.MODIFY_MULTISIGN]: {
    name: "Vote.Multisign",
    shortName: "Vote.Multisign",
    icon: MultisignImg,
  },
};

export const formatVoteType = (metadata) => {
  if (!metadata) {
    return;
  }
  if (metadata.startsWith("transfer#")) {
    return VOTE_TYPE.TRANSFER_TOKEN;
  }
  if (metadata.startsWith("transferNFT#")) {
    return VOTE_TYPE.TRANSFER_NFT;
  }
  if (metadata.startsWith("grantmethod#")) {
    return VOTE_TYPE.APPROVE_MANAGER;
  }
  if (metadata.startsWith("impeach#")) {
    return VOTE_TYPE.REMOVE_MANAGER;
  }
  if (metadata.startsWith("acceptgp#")) {
    return VOTE_TYPE.ADD_MANAGER;
  }
  if (metadata.startsWith("transferManageFee#")) {
    return VOTE_TYPE.TRANSFER_MANAGE_FEE;
  }
  if (metadata.startsWith("transferperiod#")) {
    return VOTE_TYPE.TRANSFER_PERIOD;
  }
};

export default function VoteDetail() {
  const { t } = useTranslation();

  const { id } = useParams();
  const { state: lastNav } = useLocation();
  const { chainId, account, provider } = useWeb3React();

  const {
    state: {
      componentAddressMap,
      daoChainId,
      daoId,
      whoAmI: { isOwner, isGP },
    },
  } = useDaoContext();
  const {
    state: { exploreScan, txTransactions },
    dispatch,
  } = useAppContext();

  const vaultContract = useVaultContract(
    componentAddressMap.get(VAULT_MANAGER),
    daoChainId
  );
  const signer = useSigner();
  const orgMembersMap = useOrgMembers(componentAddressMap.get(ORG_MANAGER));

  const isLogin = useCheckLogin();
  const { getClient, providers } = useClientContext();

  const [type, setType] = useState(VOTE_TYPE.ADD_MANAGER);
  const [voteData, setVoteData] = useState({ agree_count: 0, oppose_count: 0 });
  const [optionlist, setoptionlist] = useState([]);
  const [voters, setVoters] = useState([]);
  const [voteId, setVoteId] = useState();
  const [action, setAction] = useState();
  const [isNewVote, setIsNewVote] = useState(false);
  const [txHash, setTxHash] = useState("");
  const [createAt, setCreateAt] = useState("");

  const [voteBytes, setVoteBytes] = useState();

  const network = useNetworkData(chainId);

  const multicall = useMemo(() => {
    if (!daoChainId || !providers[daoChainId]) {
      return;
    }
    const _config = {
      ethersProvider: provider,
      tryAggregate: true,
    };
    if (network.multicallCustomContractAddress) {
      _config.multicallCustomContractAddress =
        network.multicallCustomContractAddress;
    }
    return new Multicall(_config);
  }, [providers, daoChainId]);

  const [getVoteData, fetchLoading] = useMulticallTxs(
    multicall,
    componentAddressMap.get(VAULT_MANAGER),
    account
  );

  const getVoteFromGraph = async () => {
    const client = getClient();
    try {
      const resp = await client.request(SubgraphApiQuery, {
        chainId,
        query: getTxDetail(daoId, voteId),
      });
      const data = JSON.parse(resp.subgraphApi).data;
      console.log("=====> [getDetail]", data);
      setVoters(
        (data.voters || []).map((v) => ({
          address: v.sender,
          support: v.state,
        }))
      );
      if (data.info) {
        setTxHash(data.info.txHash);
        setCreateAt(formatTextTime(data.info.createAt));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!account) {
      return;
    }
    if (!voters.length && action) {
      setVoters([{ address: account, support: action === 1 }]);
    }
  }, [voters, account, action]);

  const voteStatusIcon = useMemo(() => {
    switch (voteData.state) {
      case 1:
        return AgreeImg;
      case 2:
        return RejectedImg;
      default:
        return OngoingImg;
    }
  }, [voteData]);

  const voteInfoByType = useMemo(() => {
    return VOTE_INFO[type] || {};
  }, [type]);

  const doChoose = async (flag, pending) => {
    if (pending) {
      return;
    }
    if (!isOwner && !isGP) {
      return;
    }
    try {
      const res = await api.vault.confirmTransaction(vaultContract, signer, {
        voteId,
        flag,
      });
      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t(flag ? "Notification.AgreeVote" : "Notification.DisagreeVote"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.VoteVote,
          data: {
            voteId,
            chainId,
            daoId,
            account,
            support: flag,
            voteType: type,
          },
        },
      });
      dispatch({
        type: "APPEND_MULTISIGN_TX",
        payload: {
          id: voteId,
          hash: txHash,
          daoId,
          chainId,
          account,
        },
      });
    } catch (error) {
      console.error(error);
      // dispatch({
      //   type: "MSGTYPE",
      //   payload: {
      //     msg: error?.error?.message || "vote failed",
      //     closable: true,
      //   },
      // });
      // showNotification(NotificationType.Fail, t("Notification.VoteFailed"));
    }
  };
  const getUserState = async () => {
    if (!account) {
      return
    }
    const data = await vaultContract.senderConfirm(voteId, account);
    setAction(data);
  }

  const getVoteDetail = async () => {
    const data = await vaultContract.proposals(ethers.BigNumber.from(voteId));
    setVoteData({
      state: data.period === 1 ? 2 : data.state, // 0-default,1-execute success,2-execute fail
      id: voteId,
      agree_count: data.confirmCount.toNumber(),
      oppose_count: data.rejectCount.toNumber(),
      source: data.desc,
      creator: data.creator,
      data: data.data,
      createAt: "Just now",
    });
    const { voteBytes: bytes, voteType } = parseVoteData(data.data);
    setType(voteType);
    setVoteBytes(bytes);
    // const txMap = await getVoteData([voteId]);
    // const data = txMap[voteId];
    // setVoteData({ ...data, createAt: "Just now" });
    // setAction(data.senderState);
    // const { voteBytes: bytes, voteType } = parseVoteData(data.data);
    // setType(voteType);
    // setVoteBytes(bytes);
  };

  useEffect(() => {
    setVoteId(Number(id));
  }, [id]);

  useEffect(() => {
    setoptionlist([
      { value: voteData.agree_count, name: "Agree" },
      { value: voteData.oppose_count, name: "Disagree" },
    ]);
  }, [voteData]);

  useEffect(() => {
    if (
      !provider ||
      voteId === undefined ||
      !componentAddressMap.get(ORG_MANAGER)
    ) {
      return;
    }
    getVoteFromGraph();
    getVoteDetail();
    getUserState();
  }, [provider, isNewVote, voteId, componentAddressMap]);

  useEffect(() => {
    dispatch({
      type: "LOADINGTYPE",
      payload: fetchLoading ? t("Loading") : null,
    });
  }, [fetchLoading]);

  // event
  useSubcribe(TransactionEvent.HandleVote, (_, data) => {
    if (data.daoId !== daoId) {
      return;
    }
    if (data && data.voteId !== voteId) {
      return;
    }
    getVoteDetail();
    getUserState();
    setVoters([...voters, { address: data.account, support: data.support }]);
  });

  const voteDetailByType = useMemo(() => {
    switch (type) {
      case VOTE_TYPE.TRANSFER_TOKEN:
        return <TransferTokenVote data={voteBytes} />;
      case VOTE_TYPE.TRANSFER_NFT:
        return <TransferNFTVote data={voteBytes} />;
      case VOTE_TYPE.ADD_MANAGER:
        return (
          <AddManagerVote data={voteBytes} orgMembersMap={orgMembersMap} />
        );
      case VOTE_TYPE.REMOVE_MANAGER:
        return (
          <RemoveManagerVote data={voteBytes} orgMembersMap={orgMembersMap} />
        );
      case VOTE_TYPE.TRANSFER_MANAGE_FEE:
        return <TransferManageFeeVote data={voteBytes} />;
      case VOTE_TYPE.APPROVE_MANAGER:
        return <ApproveVote orgMap={orgMembersMap} data={voteBytes} />;
      case VOTE_TYPE.MODIFY_MULTISIGN:
        return (
          <ModifyMultisign data={voteBytes} orgMembersMap={orgMembersMap} />
        );
      default:
        return <></>;
    }
  }, [type, orgMembersMap, voteData, voteBytes]);

  const getVotePending = (txId) => {
    console.log(">>txTransactions: ", txTransactions, daoId, txId);
    if (!txTransactions[daoId]) {
      return false;
    } else {
      return !!txTransactions[daoId][txId];
    }
  };

  const ActionButtons = memo(({ state, userState, isManager }) => {
    if (!isManager || state === 1 || state === 2) {
      return <></>;
    }
    if (userState === 0 && isManager) {
      const pending = getVotePending(voteId);
      return (
        <BtnGroup pending={pending}>
          <div onClick={() => doChoose(true, pending)}>{t("Vote.Agree")}</div>
          <div onClick={() => doChoose(false, pending)} className="disagree">
            {t("Vote.Disagree")}
          </div>
        </BtnGroup>
      );
    }
    return <></>;
  });

  return (
    <Box>
      <TopNav>
        <BackNav to={`vote/${lastNav || "queue"}`} />
      </TopNav>

      <ContentBox>
        <Header>
          <LftBox>
            <img src={voteStatusIcon} alt="" />
          </LftBox>
          <RhtLine>
            <dl>
              <dt>{t("Vote.queue.Type")}</dt>
              <dd className="font-demiBold">{t(voteInfoByType.name)}</dd>
            </dl>
            <dl>
              <dt>{t("Vote.queue.time")}</dt>
              <dd className="font-demiBold">{createAt || voteData.createAt}</dd>
            </dl>
            <dl>
              <dt>{t("Vote.queue.Agree")}</dt>
              <dd className="font-demiBold">
                {voteData.agree_count} : {voteData.oppose_count}
              </dd>
            </dl>
            {/* <dl>
              <dt>{t("Vote.queue.Expiry")}</dt>
              <dd>
                <FormatLeftTime leftTime={voteData.left} />
              </dd>
            </dl> */}
          </RhtLine>
        </Header>
        <MainBox>
          <LeftBox>
            <GlassBG />
            <BoxContainer>
              {voteDetailByType}
              {voteData.txHash && (
                <Block title={t("Vote.TxHash")}>
                  <HashBox>
                    {voteData.tx_hash}
                    <div className="optionGrp">
                      <CopyStr address={voteData.txHash} />
                      <Link hash={txHash} type="tx" />
                    </div>
                  </HashBox>
                </Block>
              )}
              {voteData.state === 0 && (
                <Block title={t("Vote.Executed")}>n/a</Block>
              )}
            </BoxContainer>
          </LeftBox>
          <RightBox>
            <GlassBG />
            <BoxContainer className="rightBox">
              <MidBox>
                <Block title={t("Vote.Creator")}>
                  <ItemBox>
                    <Avatar address={voteData.creator} size="39px" />
                    <div className="manager">
                      <div className="name">
                        {orgMembersMap[voteData.creator]}
                      </div>
                      <AddressBox address={voteData.creator} />
                    </div>
                  </ItemBox>
                </Block>
                <Block
                  title={`${t("Vote.Votes")} (${voteData.agree_count}:${
                    voteData.oppose_count
                  })`}
                >
                  <VotersBox>
                    {voters.map((item, i) => (
                      <ItemLine key={i}>
                        {item.support ? (
                          <img src={ConfirmImg} alt="" className="agreeImg" />
                        ) : (
                          <img src={WrongImg} alt="" className="agreeImg" />
                        )}
                        <ItemBox>
                          <Avatar address={item.address} size="39px" />
                          <div className="manager">
                            <div className="name">
                              {orgMembersMap[item.address]}
                            </div>
                            <AddressBox address={item.address} />
                          </div>
                        </ItemBox>
                      </ItemLine>
                    ))}
                  </VotersBox>
                </Block>
              </MidBox>
              <div className="chartBox">
                {optionlist && (
                  <VoteEcharts
                    optionlist={optionlist}
                    status={voteData.state}
                  />
                )}
              </div>
            </BoxContainer>
            <ActionButtons
              state={voteData.state}
              isManager={isOwner || isGP}
              userState={action}
            />
          </RightBox>
        </MainBox>
      </ContentBox>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 20px;
`;

const TopNav = styled.div`
  margin-left: -8px;
`;

const ContentBox = styled.div`
  flex-grow: 1;
  height: calc(100% - 56px);
  overflow-y: auto;
`;
const Header = styled.div`
  height: 72px;
  background: #234488;
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
`;
const RhtLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  dt {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 20px;
    margin-bottom: 4px;
  }
  dd {
    font-size: 18px;
    font-weight: 500;
    height: 25px;
    line-height: 27px;
    font-family: "Poppins-Medium";
  }
`;
const LftBox = styled.div`
  margin-right: 30px;
  height: 48px;
`;

const MainBox = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100% - 88px);
`;

const BoxContainer = styled.div`
  position: relative;
  width: 100%;
  &.rightBox {
    box-sizing: border-box;
    display: flex;
    & > div {
      width: 50%;
    }
  }
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
  line-height: 34px;
  position: absolute;
  right: 40px;
  bottom: 36px;
  div {
    text-align: center;
    cursor: pointer;
    margin-left: 20px;
    width: 111px;
    height: 36px;
    line-height: 36px;
    background: #79dfc4;
    font-size: 14px;
    font-weight: 400;
    color: #041735;
    box-shadow: 0px 8px 20px 0px rgba(12, 11, 48, 0.16);
    border-radius: 10px;
    cursor: ${({ pending }) => (pending ? "auto" : "pointer")};
    opacity: ${({ pending }) => (pending ? "0.5" : "1")};
    &.disagree {
      background: #ffa4d1;
    }
    &.action {
      background: #bfbfc1;
      color: #ffffff;
    }
  }
`;

const LeftBox = styled.div`
  position: relative;
  width: 406px;
  margin-right: 16px;
  border-radius: 16px;
  padding: 24px;
`;

const RightBox = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 1;
  border-radius: 16px;
  overflow: hidden;
  padding: 24px;
`;

const MidBox = styled.div``;

const ItemBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  .manager {
    margin-left: 8px;
  }
  .name {
    line-height: 20px;
  }
  .address {
    font-size: 14px;
    font-weight: 400;
    color: #10164b;
    line-height: 22px;
  }
`;
const ItemLine = styled.div`
  display: flex;
  align-items: center;
  img.agreeImg {
    width: 26px;
    margin-right: 14px;
  }
`;

const VotersBox = styled.div``;
