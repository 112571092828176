import mainAddress from './address'
// import ETH from 'img/network/ETH.png'
import ETH from 'img/newHome/eth.png'
import etherscan from 'img/network/etherscan.png'

export default  {
    name: 'Rinkeby',
    simple: 'rinkeby',
    mainAddress: mainAddress,
    serverApi: 'https://test-backend.subdao.org/rinkeby/query',
    graphApi: 'https://api.thegraph.com/subgraphs/name/noahcell/subdao-subgraph-rinkeby',
    daos: {
        url: 'https://api.gitrows.com/@github/SubDAO-Network/daos/rinkeby.json',
        key: 'testnet'
    },
    swapRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
    constant: {
        fundList: [
            {
                symbol: 'USDT',
                address: '0x9eabBB120ae7A9fb2d2B501C1Bff96555937Ce58',
                deci: 18,
            }
        ]
    },
    tokenLogo: ETH,
    scan: {
        img: etherscan,
        host: 'https://rinkeby.etherscan.io/'
    },
    chainId: 4, // '0x13881'
    chainName: "Rinkeby",
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
    },
    logo: ETH,
    rpcUrls: ["https://rinkeby.infura.io/v3/b76d7212db5141be96b1e9533e2ebd0a"],
    blockExplorerUrls: ["https://rinkeby.etherscan.io"],
}