import { memo } from "react";
import styled from "styled-components";
import General from "components/modal/general";
import HFContent from "./HFContent";
import LTVContent from "./LTVContent";
import { useTranslation } from "react-i18next";

const RiskModal = memo((props) => {
  const { t } = useTranslation();
  const { show, closeModal, data } = props;
  const {
    healthFactor,
    healthFactorDisplay,
    loanToValue,
    currentLoanToValue,
    currentLiquidationThreshold,
  } = data;

  return (
    <General width="482" show={show} closeModal={closeModal} glass>
      <TitleBox className="font-medium">{t("defi.LiquidationRisk")}</TitleBox>
      <Content>
        <Intro>{t("defi.HealthTip")}</Intro>

        <InfoWrapper
          topTitle={t("defi.Health")}
          topDescription={t("defi.HealthTop")}
          topValue={healthFactorDisplay}
          bottomText={t("defi.HealthBottom")}
          isWarning={+healthFactor <= 3 && +healthFactor > 1.1}
          isError={+healthFactor <= 1.1}
        >
          <HFContent
            healthFactor={healthFactor}
            healthFactorDisplay={healthFactorDisplay}
          />
        </InfoWrapper>

        <InfoWrapper
          topTitle={t("defi.CurrentLTV")}
          topDescription={t("defi.LTVTop")}
          topValue={`${loanToValue}%`}
          bottomText={t("defi.LTVBottom")}
          isWarning={
            +loanToValue < +currentLoanToValue &&
            +loanToValue > +currentLoanToValue - +currentLoanToValue / 2
          }
          isError={+loanToValue > +currentLiquidationThreshold}
        >
          <LTVContent
            loanToValue={loanToValue}
            currentLoanToValue={currentLoanToValue}
            currentLiquidationThreshold={currentLiquidationThreshold}
          />
        </InfoWrapper>
      </Content>
    </General>
  );
});

export default RiskModal;

const TitleBox = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 31px;
  padding-left: 6px;
  font-family: "Poppins-Medium";
  margin-top: 30px;
`;

const Content = styled.div`
  padding: 0 10px;
`;

const Intro = styled.div`
  margin-top: 20px;
`;

export const InfoWrapper = ({
  topValue,
  topTitle,
  topDescription,
  children,
  bottomText,
  isWarning,
  isError,
}) => {
  return (
    <WrapperBox
      style={{
        border: `1px solid 1px`,
        mb: 6,
        borderRadius: "6px",
        px: 4,
        pt: 4,
        pb: 6,
        "&:last-of-type": {
          mb: 0,
        },
      }}
    >
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ width: "calc(100% - 72px)" }}>
          <WrappTitle className="font-medium">{topTitle}</WrappTitle>
          <WrappDesc variant="caption" color="text.secondary">
            {topDescription}
          </WrappDesc>
        </Box>

        <Circle
          style={{
            width: "56px",
            height: "56px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: isError ? "red" : isWarning ? "#f89f1a" : "green",
          }}
        >
          {topValue}
        </Circle>
      </Box>

      <Box>{children}</Box>

      <WrappDesc>{bottomText}</WrappDesc>
    </WrapperBox>
  );
};

const Box = styled.div``;

const WrapperBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  padding: 16px;
  margin-top: 20px;
`;
const WrappTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  height: 23px;
  margin-bottom: 8px;
  font-family: "Poppins-SemiBold";
`;

const WrappDesc = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
`;

const Circle = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #f89f1a;
  color: #fff;
`;
