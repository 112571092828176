import mainAddress from "./address_dev";
import Logo from "../../assets/chain/zkSync.jpeg";
import ETH from "../../assets/chain/ETH.svg";
import Dapps from "../dapps";

export default {
  name: "zkSync testnet",
  simple: "zksync",
  mainAddress: mainAddress,
  // aave not support
  aaveGraph: "",
  aaveLendingPool: "",
  aaveLendingPoolProvider: "",
  multicall: "0xa4518917b2cff1c42455A102415C56238f090434",
  multicallCustomContractAddress: "0xBc8d584Ea3501A09CD2Be1326deC6BFeF1A0F987",
  // swap
  swapApp: "MuteSwap",
  swapRouter: "",
  constantHost: "https://raw.githubusercontent.com/SubDAO-Network/daos/main",
  // multi-sign default tokens
  multiSignDefaultSupportTokens: ["0x0faF6df7054946141266420b43783387A78d82A9"],
  tokenLogo: ETH,
  scan: {
    img: "",
    host: "https://explorer.zksync.io/",
  },
  chainId: 280,
  chainIdHex: "0x118",
  chainName: "zkSync testnet",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
    logoURI: "https://app.aave.com/icons/networks/ethereum.svg",
  },
  wrappedToken: "",
  logo: Logo,
  rpcUrls: ["https://testnet.era.zksync.dev"],
  blockExplorerUrls: ["https://explorer.zksync.io/"],
  walletConnectDapps: [],
};
