import styled from "styled-components";
import TipSVG from "assets/images/Tip.svg";

export const LinerReleaseTooltip = ({ t, start, end }) => {
  return (
    <div className="infoIcon">
      <img className="tipIcon" src={TipSVG} alt="" />
      <LinerStyled className="releaseTip">
        <div className="intro">{t("Create.PreviewLinerTip")}</div>
        <div className="bottom">
          <div style={{ flex: 1 }}>
            <div className="subTitle">{t("Create.ReleaseStartDate")}</div>
            <div className="subTime">{start}</div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="subTitle">{t("Create.ReleaseEndDate")}</div>
            <div className="subTime">{end}</div>
          </div>
        </div>
      </LinerStyled>
    </div>
  );
};

export const StageReleaseTooltip = ({ t, periods }) => {
  return (
    <div className="infoIcon">
      <img className="tipIcon" src={TipSVG} alt="" />
      <StageStyled className="releaseTip">
        <div className="intro">{t("Create.StateTip")}</div>
        <div>
          <div className="bottom">
            <div className="subTitle">{t("Create.VestingDate")}</div>
            <div className="subTitle">{t("Create.ReleaseRatio")}</div>
          </div>
          {periods.map((p, i) => (
            <div className="bottom period font-medium" key={p.random || i}>
              <div className="subTitle">
                <span className="no">{i + 1}</span>
                <span>{p.date}</span>
              </div>
              <div className="subTitle">{p.ratio}</div>
            </div>
          ))}
        </div>
      </StageStyled>
    </div>
  );
};

const ReleaseStyled = styled.div`
  display: none;
  position: absolute;
  border-radius: 16px;
  padding: 17px 20px;
  background-color: #1e2b3f;
  .intro {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 16px;
  }
  .bottom {
    display: flex;
    > div {
      flex: 5;
    }
    > div:last-child {
      flex: 2;
    }
    .no {
      margin-right: 15px;
    }
    &.period .subTitle {
      opacity: 1;
    }
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    height: 24px;
    opacity: 0.5;
  }
`;

const LinerStyled = styled(ReleaseStyled)`
  right: -240px;
  width: 395px;
  height: 140px;
  bottom: 30px;

  .subTime {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
`;

const StageStyled = styled(ReleaseStyled)`
  width: 366px;
  bottom: 30px;
  right: -220px;
`;
