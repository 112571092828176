import { useMemo } from "react";
import { useCallback, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import getIdentity from "utils/tokenIdentity";
import OpenArrow from "assets/images/Open.svg";
import AllTokenAvatar from "components/common/allTokenAvatar";

export default function TokenSelect({ list, selected, onSelect }) {
  const [show, setShow] = useState(false);

  const clickCallback = (e) => {
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", clickCallback);
    return () => {
      document.removeEventListener("click", clickCallback);
    };
  }, []);

  const showSelect = useCallback(
    (e) => {
      e.nativeEvent.stopImmediatePropagation();
      setShow(!show);
    },
    [show]
  );

  const choose = useCallback(
    (item, e) => {
      e.stopPropagation();
      setShow(false);
      if (item.address !== selected?.address) {
        onSelect(item);
      }
    },
    [selected]
  );

  const selectDisplay = useMemo(() => {
    if (selected === undefined) {
      return "";
    }
    const find = list.find((item) => item.address === selected?.address);
    return find;
  }, [selected]);

  return (
    <SelectBox onClick={showSelect}>
      <OptionDisplay fullExpand={show}>
        {selectDisplay && (
          <>
            {selectDisplay.all ? (
              <AllTokenAvatar />
            ) : (
              <Avatar src={selectDisplay?.logo || selectDisplay?.logoURI} />
            )}

            <ManagerInfo>
              <div className="symbol">
                {selectDisplay.symbol}
                <span className="identity">
                  {getIdentity(selectDisplay?.address)}
                </span>
              </div>
              <span className="name">
                {selectDisplay.balanceDisplay}{" "}
                {!selectDisplay.all && selectDisplay.symbol}
              </span>
            </ManagerInfo>
          </>
        )}
        <img className="arrow" src={OpenArrow} alt="" />
        {show && <Line />}
      </OptionDisplay>
      {show && (
        <OptionContainer>
          {list.map((item, i) => (
            <Option key={item.address} onClick={(e) => choose(item, e)}>
              {item.all ? (
                <AllTokenAvatar />
              ) : (
                <Avatar src={item.logo || item.logoURI} />
              )}
              <ManagerInfo>
                <div className="symbol">
                  {item?.symbol}
                  <span className="identity">{getIdentity(item?.address)}</span>
                </div>
                <span className="name">
                  {item.balanceDisplay} {!item.all && item.symbol}
                </span>
              </ManagerInfo>
            </Option>
          ))}
        </OptionContainer>
      )}
    </SelectBox>
  );
}

const BgStyled = css`
  background-color: #1e2b3f;
`;

const SelectBox = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;
  height: 44px;

  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #ffffff;
`;

const OptionContainer = styled.ul`
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  ${BgStyled};
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-top: none;
`;

const OptionContent = css`
  display: flex;
  align-items: center;
  .name {
    font-size: 12px;
    opacity: 0.5;
  }
  .identity {
    opacity: 0.5;
    margin-left: 8px;
  }
`;

const OptionDisplay = styled.div`
  height: 100%;
  line-height: 44px;
  padding-left: 23px;
  border-radius: ${(props) => (props.fullExpand ? "10px 10px 0 0" : "10px")};
  border: ${(props) =>
    props.fullExpand ? "1px solid rgba(255, 255, 255, 0.08)" : "unset"};
  overflow: hidden;
  position: relative;
  ${BgStyled};
  ${OptionContent};
  .arrow {
    width: 24px;
    opacity: 0.5;
    position: absolute;
    right: 20px;
    top: 10px;
    transform: ${(props) =>
      props.fullExpand ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const Line = styled.div`
  width: calc(100% - 46px);
  height: 1px;
  position: absolute;
  left: 23px;
  bottom: 1px;
  background: rgba(255, 255, 255, 0.08);
`;

const Option = styled.li`
  height: 44px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
  }

  ${OptionContent};
`;

const ManagerInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
`;

const Avatar = styled.img`
  width: 24px;
  border-radius: 50%;
  margin-right: 12px;
`;
