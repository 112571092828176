import { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {InputNumber, Input, Row, Col, Tooltip} from "antd";
import styled from "styled-components";
import EditImg from "../../assets/images/icon-edit.svg";
import TranshIcon from "../../assets/images/icon-delete.svg";
import WrongImg from "../../assets/images/Close.svg";
import ConfirmImg from "../../assets/images/Completion.svg";

import { useDaoContext } from "../../views/dao/provider";
import { VENTURE_MANAGER } from "../../utils/constant";
import ConfirmModal from "../modal/confirmModal";
import EmptyBox from "components/common/empty";

import { useMutation } from "@apollo/client";
import {
  CreateProject,
  QueryProjects,
  UpdateProject,
  DeleteProject,
} from "api/graphql/dao";
import { useWeb3React } from "@web3-react/core";
import { signProjectMessage, signDeleteProjectMessage } from "utils/sign";
import SelectToken from "./selectToken";
import UploadLogo from "components/upload/miniLogo";
import { useAppContext } from "components/provider/appProvider";
import { useClientContext } from "components/provider/clientProvider";
import LoadingBox from "components/common/loading";
import AddImg from "../../assets/images/icon-add.svg";
import Button from "components/common/button";

export default function Investment(props) {
  const { t } = useTranslation();
  const { chainId, provider, account } = useWeb3React();
  const { dispatch } = useAppContext();
  const { state: daoState } = useDaoContext();
  const {
    daoChainId,
    componentAddressMap,
    whoAmI: { isOwner },
  } = daoState;

  const ventureContractAddress = useMemo(
    () => componentAddressMap.get(VENTURE_MANAGER),
    [componentAddressMap]
  );
  const { getClient } = useClientContext();
  const client = useMemo(() => getClient(daoChainId), [daoChainId]);

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMap, setEditMap] = useState({});

  const getProjects = async () => {
    try {
      const data = await client.request(QueryProjects, {
        venture: componentAddressMap.get(VENTURE_MANAGER),
        chainId: daoChainId,
        page: 1,
        size: 10000,
      });
      try {
        setProjectList(
          data.projects.data.map((l) => ({
            id: l.id,
            name: l.name,
            logo: l.logo,
            round: l.round,
            amount: l.amount,
            token: l.valuationToken,
            valuationAmount: l.valuationAmount,
          }))
        );
      } catch (error) {
        console.error("parse queryProjects failed", error);
      }
    } catch (error) {
      console.error("====", error);
    }
    setLoading(false);
  };

  const [createProject] = useMutation(CreateProject, {
    onCompleted: async (data) => {
      getProjects();
      dispatch({ type: "LOADINGTYPE", payload: null });
    },
    onError: (error) => {
      dispatch({ type: "LOADINGTYPE", payload: null });
      dispatch({
        type: "MSGTYPE",
        payload: { msg: `${error}`, closable: true },
      });
    },
  });

  const [updateProject] = useMutation(UpdateProject, {
    onCompleted: async (data) => {
      getProjects();
      dispatch({ type: "LOADINGTYPE", payload: null });
    },
    onError: (error) => {
      dispatch({ type: "LOADINGTYPE", payload: null });
      dispatch({
        type: "MSGTYPE",
        payload: { msg: "update failed", closable: true },
      });
    },
  });

  const [deleteProject] = useMutation(DeleteProject, {
    onCompleted: async (data) => {
      getProjects();
      setDeleteData({});
      dispatch({ type: "LOADINGTYPE", payload: null });
    },
    onError: (error) => {
      dispatch({ type: "LOADINGTYPE", payload: null });
      dispatch({
        type: "MSGTYPE",
        payload: { msg: "update failed", closable: true },
      });
    },
  });

  useEffect(async () => {
    if (ventureContractAddress && daoChainId) {
      getProjects();
    }
  }, [ventureContractAddress, daoChainId]);

  const change2edit = useCallback(
    (item) => {
      const newEditMap = {
        ...editMap,
        [item.id]: { ...item },
      };
      setEditMap(newEditMap);
    },
    [editMap]
  );

  const handleCancel = useCallback(
    (id) => {
      // delete map id
      const newEditMap = { ...editMap };
      delete newEditMap[id];
      setEditMap(newEditMap);
      if (id === "-1") {
        // remove new from list;
        setProjectList([...projectList.slice(0, -1)]);
      }
    },
    [editMap, projectList]
  );

  const handleSave = useCallback(
    async (id) => {
      const editData = editMap[id];
      if (
        !editData ||
        !editData.logo ||
        !editData.name ||
        !editData.round ||
        !editData.amount ||
        !editData.valuationAmount ||
        !editData.token
      ) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: "require input all", closable: true },
        });
        return;
      }
      if (editData.amount <= 0 || editData.valuationAmount <= 0) {
        dispatch({
          type: "MSGTYPE",
          payload: { msg: "invalid amount", closable: true },
        });
        return;
      }
      dispatch({ type: "LOADINGTYPE", payload: t("Msg.Signing") });
      // sign
      const now = Date.now();
      let signData;
      try {
        signData = await provider.send("personal_sign", [
          signProjectMessage(
            {
              logo: editData.logo,
              name: editData.name,
              round: editData.round,
              amount: editData.amount.toFixed(6),
              valuationAmount: editData.valuationAmount.toFixed(6),
              token: editData.token,
            },
            now
          ),
          account,
        ]);
      } catch (error) {
        console.error("user denied?", error);
      }
      if (!signData) {
        return;
      }

      if (editData.isNew) {
        // add new
        createProject({
          variables: {
            logo: editData.logo,
            name: editData.name,
            round: editData.round,
            amount: editData.amount,
            valuationAmount: editData.valuationAmount,
            valuationToken: editData.token,
            sign: signData,
            timestamp: now,
            chainId,
            venture: componentAddressMap.get(VENTURE_MANAGER),
          },
        });
      } else {
        // save
        updateProject({
          variables: {
            id: editData.id,
            logo: editData.logo,
            name: editData.name,
            round: editData.round,
            amount: editData.amount,
            valuationAmount: editData.valuationAmount,
            valuationToken: editData.token,
            sign: signData,
            timestamp: now,
            chainId,
            venture: componentAddressMap.get(VENTURE_MANAGER),
          },
        });
      }
      const newEditMap = { ...editMap };
      delete newEditMap[id];
      setEditMap(newEditMap);
    },
    [provider, account, chainId, componentAddressMap, editMap]
  );

  const handleEdit = useCallback(
    (id, key, value) => {
      const editData = editMap[id];
      const newEditMap = {
        ...editMap,
        [id]: {
          ...editData,
          [key]: value,
        },
      };
      setEditMap(newEditMap);
    },
    [editMap]
  );

  const addNew = useCallback(() => {
    if (editMap["-1"]) {
      return;
    }
    setProjectList([
      ...projectList,
      {
        id: "-1",
      },
    ]);
    setEditMap({
      ...editMap,
      "-1": {
        isNew: true,
      },
    });
  }, [projectList, editMap]);

  const showConfirmModal = useCallback((data) => {
    setConfirmVisible(true);
    setDeleteData(data);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmVisible(false);
    setDeleteData({});
  }, []);

  const hanleConfirmDelete = useCallback(async () => {
    closeConfirmModal();
    dispatch({ type: "LOADINGTYPE", payload: t("Msg.Signing") });
    // sign
    try {
      const now = Date.now();
      const signData = await provider.send("personal_sign", [
        signDeleteProjectMessage(
          {
            id: deleteData.id,
            chainId,
          },
          now
        ),
        account,
      ]);
      deleteProject({
        variables: {
          id: deleteData.id,
          sign: signData,
          timestamp: now,
          chainId,
          venture: componentAddressMap.get(VENTURE_MANAGER),
        },
      });
    } catch (error) {
      console.error("delete failed", error);
    }
    dispatch({ type: "LOADINGTYPE", payload: null });
  }, [deleteData, chainId]);

  return (
    <>
      {isOwner && (
        <BtnBox>
          <Button primary onClick={addNew}>
            <span>{t("Add")}</span>
            <img src={AddImg} alt="" />
          </Button>
        </BtnBox>
      )}
      {loading ? (
        <LoadingBox loading={loading} />
      ) : !projectList.length ? (
        <EmptyBox />
      ) : (
        <TableBox isOwner={isOwner}>
          {confirmVisible && (
            <ConfirmModal
              show={confirmVisible}
              data={deleteData}
              handleClose={closeConfirmModal}
              message={t("Investment.DeleteMsg")}
              handleConfirm={hanleConfirmDelete}
            />
          )}
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>{t("Investment.Logo")}</th>
                <th className="name">{t("Investment.ProjectName")}</th>
                <th>{t("Investment.Round")}</th>
                <th className="valueAmount">{t("Investment.Valuation")}</th>
                <th>{t("Investment.Amount")}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {projectList.map((item, index) =>
                editMap[item.id] ? (
                  <tr key={index}>
                    <td>
                      <UploadLogo
                        imgCode={editMap[item.id].logo}
                        updateUrl={(url) => {
                          handleEdit(item.id, "logo", url);
                        }}
                      />
                    </td>
                    <td className="name">
                      <EditInput
                        value={editMap[item.id].name}
                        onChange={(e) => {
                          handleEdit(item.id, "name", e.target.value);
                        }}
                      />
                    </td>
                    <td>
                      <EditInput2
                        value={editMap[item.id].round}
                        onChange={(e) => {
                          handleEdit(item.id, "round", e.target.value);
                        }}
                      />
                    </td>
                    <td className="valueAmount">
                      <div className="col-amount">
                        <span className="amount">
                          <EditNumberInput
                            value={editMap[item.id].valuationAmount}
                            min={0}
                            controls={false}
                            precision={6}
                            onChange={(v) => {
                              handleEdit(item.id, "valuationAmount", v);
                            }}
                          />
                        </span>
                        <SelectToken
                          isEdit={true}
                          token={editMap[item.id].token}
                          onChange={(v) => {
                            handleEdit(item.id, "token", v);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <EditNumberInput
                        value={editMap[item.id].amount}
                        min={0}
                        controls={false}
                        precision={6}
                        onChange={(v) => {
                          handleEdit(item.id, "amount", v);
                        }}
                      />
                    </td>
                    <td>
                      <Operation>
                        <EditBg>
                          <Tooltip title={t("Cancel")}>
                            <img
                              className="confirmImg"
                              src={WrongImg}
                              alt=""
                              onClick={() => handleCancel(item.id)}
                            />
                          </Tooltip>
                          {/* <div className="edit">{t("Cancel")}</div> */}
                        </EditBg>
                        <EditBg>
                          <Tooltip title={t("Cancel")}>
                            <img
                              className="confirmImg"
                              src={ConfirmImg}
                              alt=""
                              onClick={() => handleSave(item.id)}
                            />
                          </Tooltip>
                          {/* <div className="edit">{t("Save")}</div> */}
                        </EditBg>
                      </Operation>
                    </td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td>
                      <UploadLogo disabled={true} imgCode={item.logo} />
                    </td>
                    <td className="name">{item.name}</td>
                    <td>{item.round}</td>
                    <td className="valueAmount">
                      <div className="col-amount">
                        <span className="amount bold">
                          {item.valuationAmount}
                        </span>
                        <SelectToken token={item.token} />
                      </div>
                    </td>
                    <td className="bold">{item.amount}</td>
                    {isOwner && (
                      <td>
                        <Operation>
                          <EditBg>
                            <Tooltip title={t("Edit")}>
                              <img
                                className="opImg"
                                src={EditImg}
                                alt=""
                                onClick={() => change2edit(item)}
                              />
                            </Tooltip>
                            {/* <div className="edit">{t("Edit")}</div> */}
                          </EditBg>
                          <EditBg>
                            <Tooltip title={t("Delete")}>
                              <img
                                className="opImg"
                                src={TranshIcon}
                                alt=""
                                onClick={() => {
                                  showConfirmModal(item);
                                }}
                              />
                            </Tooltip>
                            {/* <div className="edit">{t("Delete")}</div> */}
                          </EditBg>
                        </Operation>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </TableBox>
      )}
    </>
  );
}

const EditInput = styled(Input)`
  background: rgba(4, 23, 53, 0.9);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  height: 40px;
  padding: 0 16px;
  color: #ffffff;
  width: 196px;
  input {
    background: transparent;
  }
`;
const EditInput2 = styled(EditInput)`
  width: 80px;
`;
const EditNumberInput = styled(InputNumber)`
  background: rgba(4, 23, 53, 0.9);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 156px;
  input.ant-input-number-input {
    background: transparent;
    color: #ffffff !important;
    font-family: "Rubik-Medium";
    font-size: 18px;
    padding: 0;
  }
`;


const TableBox = styled.div`
  overflow-y: auto;
  height: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .bold {
    font-family: "Rubik-Medium";
    font-size: 18px;
  }
  table {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 16px;
  }
  .name {
    padding-right: 15px;
    padding-left: 40px;
  }
  th {
    font-size: 14px;
    font-weight: 400;
    color: #4a84ff;
    height: 72px;
    text-align: left;
    &:first-child {
      border-top-left-radius: 16px;
      padding-left: 32px;
    }
    &:last-child {
      border-top-right-radius: 16px;
      text-align: center;
    }
  }
  td {
    white-space: nowrap;
    height: 62px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    vertical-align: middle;
    &:first-child {
      text-align: left;
      padding-left: 32px;
    }
    &:last-child {
      text-align: center;
    }
  }
  tr:nth-child(odd) {
    td {
      background: rgba(255, 255, 255, 0.04);
    }
  }
  tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 16px;
      }
      &:last-child {
        border-bottom-right-radius: 16px;
      }
    }
  }
  .valueAmount {
    padding-left: 70px;
  }
  .col-amount {
    display: flex;
    align-items: center;
    .amount {
      margin-right: 12px;
    }
  }
`;



const Operation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-left: 70px;
`;

const EditBg = styled.div`
  position: relative;
  width: 24px;
  cursor: pointer;
  &:first-child {
    margin-right: 24px;
  }

  .confirmImg {
    width: 24px !important;
    padding-top: 10px;
  }
  .opImg{
    width: 24px;
  }
  .edit {
    position: absolute;
    left: -12px;
    top: -40px;
    box-shadow: 0 3px 4px rgba(16, 22, 75, 0.1);
    background: rgba(255,255,255,0.1);
    z-index: 99;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 16px;
    padding: 11px 14px;
    border-radius: 10px;
    display: none;

    &:before {
      position: absolute;
      bottom: -7px;
      left: 50%;
      content: "";
      margin-left: -7px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid rgba(255,255,255,0.1);
    }
  }

  &:hover {
    .edit {
      display: block;
    }
  }
`;

const BtnBox = styled.div`
  position: fixed;
  right: calc((100vw - 1240px) / 2);
  top: 235px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
  @media (max-width: 1440px) {
    top: 200px;
  }
  button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      margin-left: 4px;
    }
  }
`;
