import styled from "styled-components";

export const AddressBox = styled.div`
  width: 342px;
  height: 80px;
  word-break: break-all;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 12px 16px 0;
  position: relative;
  font-family: "Poppins-Light";
  font-size: 16px;
  line-height: 22px;
  .optionGrp {
    position: absolute;
    right: 16px;
    bottom: 8px;
    img {
      margin-right: 0;
      margin-left: 16px;
    }
  }
`;

export const PercentBox = styled.ul`
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .manager {
    display: flex;
    align-items: center;
    .name {

    }
  }
   .numBox {
    width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: rgba(4, 23, 53, 0.5);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(12px);
    font-family: "Rubik-Medium";
    position: relative;
    margin-right: 38px;
    span {
      position: absolute;
      right: -20px;
    }
  }
  .address {
    display: flex;
    align-items: center;
    span {
      white-space: nowrap;
    }
  }
`;

export const IndexTag = styled.div`
  width: 28px;
  height: 20px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  text-align: center;
  font-size: 10px;
  font-family: "Rubik-Medium";
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
`;

export const ItemBlock = styled.dl`
  margin-bottom: 28px;
  dt {
    height: 20px;
    font-size: 14px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: #4f88ff;
    line-height: 21px;
    margin-bottom: 12px;
  }
  dd.flexBox {
    display: flex;
    align-items: center;
  }
`;


export const Block = ({title, children, blockProps, ...rest}) => {
  return (
    <ItemBlock {...blockProps}>
      <dt>{title}</dt>
      <dd {...rest}>{children}</dd>
    </ItemBlock>
  );
}

export const NumberBox = styled.span`
  height: 36px;
  min-width: 36px;
  line-height: 36px;
  box-sizing: border-box;
  padding: 0 8px;
  background: rgba(4, 23, 53, 0.5);
  display: inline-block;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  font-family: "Rubik-Medium";
  margin-right: 12px;
`;