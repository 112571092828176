import {
  memo,
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { TokenAvatar } from "components/common/avatar";
import { FundraiseType } from "../fundraise/index";
import { ReleaseType } from "../fundraise/token";
import { useCallback } from "react";
import { Circulation } from "../fundraise/circulateRadio";
import { NFTTokenAvatar } from "components/common/avatar";
import { LinerReleaseTooltip, StageReleaseTooltip } from "components/modal/releaseTooltip";
import { MIN_DURATION } from "utils/constant";
import getIdentity from "utils/tokenIdentity";
import ClockAnim from "assets/gif/clock.gif";

import LftImg from "assets/images/left.png";
import RhtImg from "assets/images/right.png";
import CircleProgress from "components/common/circleProgress";
import CardLayout from "components/home/cardLayout";
import TokenDefault from "img/defaultToken.svg";
import Link from "components/common/LinkIcon";
import { Tooltip } from "antd";
import TipSVG from "assets/images/Tip.svg";
import getTarget from "../fundraise/accumulate";
import { useCreateContext } from "../provider";

const PreviewFund = memo(
  forwardRef(({ data, GpCarry, manageFee, gateway }, ref) => {
    const { t } = useTranslation();

    const { state: { nftImg } } = useCreateContext();

    const [fundraiseType, setFundraiseType] = useState();

    const [raiseTime, setRaiseTime] = useState(0);
    const [softCap, setSoftCap] = useState(0);
    const [hardCap, setHardCap] = useState(0);

    const [tokenData, setTokenData] = useState();
    const [nftData, setNftData] = useState();

    const closeDate = useMemo(() => {
      return raiseTime
        ? moment(raiseTime).format("dddd, MMMM Do YYYY, h:mm A")
        : "";
    }, [raiseTime]);

    useEffect(() => {
      if (!data) {
        return;
      }
      if (data.fundraiseType) {
        setFundraiseType(data.fundraiseType);
      } else {
        setFundraiseType(FundraiseType.Token);
      }
      data.tokenData && setTokenData(data.tokenData);
      data.nftData && setNftData(data.nftData);
      // setShowWhite(data.showWhite);
      data.raiseTime && setRaiseTime(data.raiseTime);
      // data.addressInput && setAddressInput(data.addressInput);
    }, [data]);

    const selectToken = useMemo(() => {
      if (fundraiseType === FundraiseType.Token) {
        return tokenData?.selectToken || {};
      } else if (fundraiseType === FundraiseType.NFT) {
        return nftData?.selectToken || {};
      } else {
        return {}
      }
    }, [tokenData, nftData, fundraiseType]);

    const equityToken = useMemo(() => {
      return tokenData?.equityToken || {};
    }, [tokenData]);

    const formatTime = useCallback((time) => {
      const m = moment(time);
      return m.format("YYYY-MM-DD HH:mm:ss");
    }, []);

    const vestData = useMemo(() => {
      const data = tokenData?.releaseData;
      switch (tokenData?.releaseType) {
        case ReleaseType.OneTime:
          return {
            title: t("Create.VestingDate"),
            time: formatTime(data),
            type: t("Create.OneTimeRelease"),
          };
        case ReleaseType.Liner:
          return {
            title: t("Create.VestingPeriod"),
            time: t("Create.Days", { period: data?.days }),
            startTime: formatTime(data?.startDate),
            endTime:
              data?.startDate && data?.days
                ? formatTime(data.startDate + data.days * MIN_DURATION)
                : "",
            type: t("Create.LinerRelease"),
          };
        case ReleaseType.Stage:
          return {
            title: t("Create.VestingPeriod"),
            time: t("Create.StagedShow", {
              num: data?.length || 0,
            }),
            periods: (data?.periods || []).map((p) => ({
              ...p,
              date: p.date && formatTime(p.date),
              ratio: p.ratio && `${p.ratio}%`,
            })),
            type: t("Create.StagedRelease"),
          };
        default:
          return {};
      }
    }, [tokenData]);

    const releaseTip = useMemo(() => {
      switch (tokenData?.releaseType) {
        case ReleaseType.Liner:
          return (
            <LinerReleaseTooltip
              t={t}
              start={vestData.startTime}
              end={vestData.endTime}
            />
          );
        case ReleaseType.Stage:
          return <StageReleaseTooltip t={t} periods={tokenData?.releaseData || []} />;
        default:
          return <></>;
      }
    }, [tokenData]);

    useEffect(() => {
      if (fundraiseType === FundraiseType.NFT) {
        if (nftData.priceSelect === 1) {
          const { soft, hard } = getTarget(
            selectToken,
            nftData.originalPrice,
            nftData.target,
            nftData.upper,
            nftData.discountTokenRate,
            nftData.discountRate,
            nftData.curveRate
          );
          setSoftCap(soft);
          setHardCap(hard);
        } else {
          if (nftData?.target && nftData?.price) {
            setSoftCap(nftData?.target * nftData?.price);
          }
          if (nftData?.upper && nftData?.price) {
            setHardCap(nftData?.upper * nftData?.price);
          }
        }
        
      } else {
        setSoftCap(tokenData?.softCap || 0);
        setHardCap(tokenData?.hardCap || 0);
      }
    }, [fundraiseType, tokenData]);

    const getCirculateText = (v) => {
      if (v === Circulation.No) {
        return t("Create.NoCirculable");
      } else {
        return t("Create.Circulable");
      }
    };

    useImperativeHandle(ref, () => ({
      fundraiseType,
      data: fundraiseType === FundraiseType.NFT ? nftData : tokenData,
      checkInvalid() {
        if (!selectToken.address) {
          return "todo: selectToken";
        }
        if (fundraiseType === FundraiseType.NFT) {
        } else {
        }
      },
    }));

    return (
      <>
        <Period>
          <div className="clock">
            <img src={ClockAnim} alt="" />
          </div>
          <div className="center">
            <span className="title">{t("Create.RaiseTime")}</span>
            <span className="date">{closeDate}</span>
          </div>
        </Period>
        <Fund>
          <LftBg />
          <RhtBg />
          <FirstLine>
            <div className="lft">
              <div className="titleBox">{t("Create.RaisedFunds")}</div>
              <div className="lftBtm">
                <div className="numBig">0.00</div>
                <Symbol>
                  <img src={selectToken.logo} alt="" />
                  <span>{selectToken.symbol}</span>
                  <span className="identity">
                    {getIdentity(selectToken?.address)}
                  </span>
                </Symbol>
              </div>
            </div>
            <div className="rht">
              <div className="cur">{t("Info.CurrentProgress")}</div>
              <div className="numRht">0%</div>
            </div>
          </FirstLine>
          <PercentBox />
          <LastLine>
            <div>
              <div className="tit">{t("Create.softcap")}</div>
              <div className="num">{softCap}</div>
            </div>
            {!!hardCap && (
              <div className="hard">
                <div className="tit">{t("Create.hardcap")}</div>
                <div className="num">{hardCap}</div>
              </div>
            )}
          </LastLine>
        </Fund>
        <TypeBox>
          <CardLayout hideAnimation={true} hideBlure={true}>
            <div className="logo">
              <img src={selectToken.logo || TokenDefault} alt="" />
            </div>
            <div className="token">
              <div className="symbol">{selectToken.symbol}</div>
              <div className="identity">{getIdentity(selectToken.address)}</div>
            </div>
            <Title>{t("Create.FundType")}</Title>
            {selectToken.address && (
              <Link hash={selectToken.address} type="token" className="link" />
            )}
          </CardLayout>
          {fundraiseType === FundraiseType.NFT ? (
            <CardLayout
              className="nftCard"
              hideAnimation={true}
              hideBlure={true}
            >
              <SymbolBox>
                <div className="topBox">
                  <NFTImg src={nftImg || gateway + nftData?.img} >
                    <div className="nftWrapper"></div>
                  </NFTImg>
                  <div className="right">
                    <TokenCirculation>
                      {getCirculateText(nftData?.circulation)}
                    </TokenCirculation>
                    <div className="label">
                      {nftData?.priceSelect === 1
                        ? `${t("BondingCurve.OriginalPrice")} ${
                            nftData?.originalPrice || 0
                          }`
                        : `${t("Create.Price")} ${nftData?.price || 0}`}{" "}
                      {selectToken.symbol}
                    </div>
                  </div>
                </div>
                <div className="token">{nftData?.name}</div>
                <Title>{t("Create.NFT")}</Title>
              </SymbolBox>
            </CardLayout>
          ) : (
            <CardLayout hideAnimation={true} hideBlure={true}>
              <SymbolBox>
                <div className="topBox">
                  <TokenAvatar size="48px" address="0x" />
                  <div className="right">
                    <TokenCirculation>
                      {getCirculateText(tokenData?.circulation)}
                    </TokenCirculation>
                    <div className="label">
                      {tokenData?.priceSelect === 1
                        ? `${t("BondingCurve.OriginalPrice")} ${
                            tokenData?.originalPrice || 0
                          }`
                        : `${t("Create.Price")} ${tokenData?.price || 0}`}{" "}
                      {selectToken.symbol}
                    </div>
                  </div>
                </div>
                <div className="token">{equityToken?.symbol}</div>
                <Title>{t("Create.TokenName")}</Title>
              </SymbolBox>
            </CardLayout>
          )}
          <CardLayout hideAnimation={true} hideBlure={true}>
            <LineBox>
              <CircleProgress progress={GpCarry} />
              <div className="first">
                <PercentNum>{GpCarry || 0}%</PercentNum>
              </div>
              <Title>{t("Create.TotalCarried")}</Title>
            </LineBox>
            <Tooltip title={t("Hover.CarriedInterest")}>
              <img src={TipSVG} alt="" className="tip" />
            </Tooltip>
          </CardLayout>
          <CardLayout hideAnimation={true} hideBlure={true}>
            <LineBox>
              <CircleProgress progress={manageFee} color="#45BEFF" />
              <div className="first">
                <PercentNum>{manageFee || 0}%</PercentNum>
              </div>
              <Title>{t("Create.TotalManageFee")}</Title>
            </LineBox>
          </CardLayout>
          {fundraiseType === FundraiseType.Token && vestData.title && (
            <CardLayout hideAnimation={true} hideBlure={true}>
              <Content>
                {releaseTip}
                <div className="time">{vestData.time}</div>
                <div className="release">
                  <span>{vestData.type}</span>
                </div>
                <Title>{vestData.title}</Title>
              </Content>
            </CardLayout>
          )}
        </TypeBox>
      </>
    );
  })
);

export default PreviewFund;

const Period = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  height: 64px;
  background: #234488;
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  .center {
    position: relative;
    display: flex;
    align-items: center;
    .title {
      font-size: 18px;
    }
    .date {
      font-family: "Rubik-Bold";
      font-size: 24px;
      margin-left: 19px;
      color: #45beff;
    }
  }

  .clock {
    position: absolute;
    height: 64px;
    width: 980px;
    left: 0;
    top: 0;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
`;

const Fund = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 20px 24px;
  margin-top: 16px;
  position: relative;
  height: 149px;
  box-sizing: border-box;
`;
const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  .lftBtm {
    display: flex;
    align-items: center;
  }
  .rht {
    display: flex;
    align-items: center;
    .numRht {
      font-size: 32px;
      font-family: "Rubik-Bold";
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
    }
  }
  .cur {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    opacity: 0.5;
    margin-right: 12px;
  }
  .numBig {
    font-size: 32px;
    font-family: "Rubik-Bold";
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
  }
  .titleBox {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    opacity: 0.5;
    margin-bottom: 4px;
  }
`;
const LastLine = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    width: 250px;
    color: #6e7398;
    &.hard {
      margin-left: 40px;
      padding-left: 40px;
      border-left: 1px solid rgba(255, 255, 255, 0.24);
    }
  }
  .tit {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    opacity: 0.5;
  }
  .num {
    font-size: 14px;
    font-family: "Rubik-Regular";
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
  opacity: 0.5;
  margin-top: 4px;
`;

const Symbol = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #6e7398;
  line-height: 16px;
  img {
    width: 16px;
    height: 16px;
    margin: 0 4px 0 12px;
    border-radius: 50%;
  }
  span {
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
  }
  .identity {
    font-size: 14px;
    margin-left: 8px;
  }
`;


const PercentBox = styled.div`
  height: 12px;
  width: 100%;
  background: #0e1827;
  border-radius: 8px;
`;
const TokenCirculation = styled.div`
  height: 20px;
  background: #326cff;
  border-radius: 13px;
  padding: 0 14px;
  line-height: 20px;
  margin-bottom: 6px;
  font-size: 10px;
`;

const TypeBox = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-wrap: wrap;
  padding: 0 1px;
  li {
    .logo {
      height: 48px;
      img {
        width: 48px;
        border-radius: 50%;
      }
    }
    .token,
    .token .symbol {
      font-size: 24px;
      font-family: "Poppins-Bold";
      line-height: 35px;
    }
    .token {
      height: 33px;
      margin-top: 16px;
      display: flex;
      .identity {
        margin-left: 8px;
      }
    }
    .tip,
    .link {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .link img,
    .tip {
      width: 24px;
      opacity: 0.5;
    }
  }
  .release {
    display: flex;
    justify-content: space-between;
  }
`;

const SymbolBox = styled.div`
  flex-grow: 1;

  .topBox {
    height: 48px;
    display: flex;
    .right {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .lft {
    img {
      width: 44px;
      height: 44px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
  .label {
    opacity: 0.5;
  }
`;
const PercentNum = styled.div`
  width: 56px;
  height: 28px;
  font-size: 24px;
  font-family: "Rubik-Bold";
  font-weight: bold;
  color: #ffffff;
  line-height: 28px;
  span {
    font-size: 14px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    margin-left: 11px;
    opacity: 0.5;
  }
`;
const LineBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
`;


const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`;
const RhtBg = styled.div`
  width: 245px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding-top: 26px;
  .time {
    font-family: "Rubik-Medium";
    font-size: 22px;
    line-height: 20px;
    height: 20px;
  }
  .release {
    height: 20px;
    margin-top: 12px;
    margin-bottom: 23px;
  }
  .infoIcon {
    position: absolute;
    right: 0;
    top: 0;
    img {
      width: 24px;
      opacity: 0.5;
    }
    &:hover {
      .releaseTip {
        display: block;
      }
    }
  }
`;

const NFTImg = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  background: #0e1827;
  border-radius: 6px;
  overflow: hidden;
  .nftWrapper {
    width: 100%;
    height: 100%;
    background: ${(props) => `url(${props.src})`} no-repeat center center;
    background-size: contain;
  }
`;