import Accounts from "./Account";
import publicJs from "../utils/publicJs";
import { ethers } from "ethers"
import abi from "../abi/web3Abi/VoteManager.json"
import grantAbi from "../abi/web3Abi/GrantMethodManager.json"
import imAbi from "../abi/web3Abi/VoteImpeachManager.json"
import eAbi from "../abi/web3Abi/VoteExecutionManager.json"


let votecontract;
const value = 0;
const gasLimit = -1;

const newVoteTransfer = async (votecontract,obj,cb) => {
    const AccountId = await Accounts.accountAddress();
    const injector = await Accounts.accountInjector();

    const { title, desc, vote_time, support_require_num, min_require_num, choices,erc20_address,to_address,valueAmount} = obj;

    if (votecontract === null || !votecontract || !votecontract.tx || !AccountId) return;
    let data;

   await votecontract.tx.newVoteWithTransfer({value, gasLimit}, title, desc, vote_time, support_require_num, min_require_num, choices,erc20_address,to_address,valueAmount)
       .signAndSend(AccountId, { signer: injector.signer }, (result) => {
        if (result.status.isFinalized || result.status.isInBlock) {
            cb(true)
        }
        });
    return data;
}


async function getContract(web3Instance, address) {
    return await new ethers.Contract(address, abi, web3Instance);
}

async function getGrantContract(web3Instance, address) {
    return await new ethers.Contract(address, grantAbi, web3Instance);
}

async function getExecuteContract(web3Instance, address) {
    return await new ethers.Contract(address, eAbi, web3Instance);
}

async function getVotesLength(voteContract) {
    // return await voteContract.votesLength
    return await voteContract.getVotesLength()
}

async function newVote(daoOrg, signer, obj) {
    const templateAction = ethers.utils.defaultAbiCoder.encode(
        ['address', 'bytes'],
        [
            window.mainAddress.actionVoteNew,
            ethers.utils.defaultAbiCoder.encode(
                ['string', 'string', 'uint256', 'uint256', 'uint64'],
                [
                    obj.title, 
                    obj.desc, 
                    !!obj.min_require_num ? parseInt(obj.min_require_num) : ethers.constants.MaxUint256,  // min_require
                    !!obj.support_require_percent ? parseInt(obj.support_require_percent) : ethers.constants.MaxUint256,   // min
                    obj.vote_time,
                ]
            )
        ]
    );

    const res =  await daoOrg.connect(signer).action(templateAction);
    await res.wait()
}

function newTransferTokenVote(obj) {
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "bytes"],
      [
        window.mainAddress.actionVaultTransferExe,
        ethers.utils.defaultAbiCoder.encode(
          ["address", "address", "uint256"],
          [obj.tokenAddress, obj.toAddress, obj.tokenAmount]
        ),
      ]
    );
}

function newTransferNFTVote(obj) {
    console.log(obj)
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "bytes"],
      [
        window.mainAddress.actionVaultTransferNFTExe,
        ethers.utils.defaultAbiCoder.encode(
          ["address", "address", "uint256", "uint256"],
          [
            obj.nftAddress,
            obj.toAddress,
            obj.tokenId,
            obj.tokenAmount, //721=>0,1155=>
          ]
        ),
      ]
    );
}

function newTransferManageFeeVote(obj) {
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "bytes"],
      [
        window.mainAddress.actionManageFeeTransferExe,
        ethers.utils.defaultAbiCoder.encode(
            ["address", "address", "uint256"],
            [obj.tokenAddress, obj.toAddress, obj.tokenAmount,]
        ),
      ]
    );
}

function newApproveGPVote(obj, noLimit) {
    console.log(obj)
    let params = [
      obj.gpAddress,
      obj.callee,
      obj.method,
      obj.approvTime,
      noLimit ? "0x" : ethers.utils.defaultAbiCoder.encode(
        ["address", "address", "uint256", "uint8", "address"],
        // token address, gp address, token amount
        [
          obj.limit.address,
          obj.gpAddress,
          obj.limit.amount,
          0,
          ethers.constants.AddressZero,
        ]
      ),
    ];
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "bytes"],
      [
        window.mainAddress.actionGrantMethodExe,
        ethers.utils.defaultAbiCoder.encode(
          ["address", "address", "string", "uint64", "bytes"],
          params
        ),
      ]
    );
}

function newKickGPVote(obj) {
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "bytes"],
      [
        window.mainAddress.actionOrgImpeachGPExe,
        ethers.utils.defaultAbiCoder.encode(
          ["address", "uint256", "uint256", "address[]", "uint256[]"],
          [
            obj.gpAddress,
            obj.gpList.length,
            obj.minSurportRequire,
            obj.gpList,
            obj.gpBonus,
          ]
        ),
      ]
    );
}

function newAddGPVote(obj) {
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "bytes"],
      [
        window.mainAddress.actionOrgAcceptGPExe,
        ethers.utils.defaultAbiCoder.encode(
          ["address", "string", "uint256", "uint256", "address[]", "uint256[]"],
          [
            obj.newGP,
            obj.newGPName || "",
            obj.gpList.length,
            obj.minSurportRequire,
            obj.gpList,
            obj.gpBonus,
          ]
        ),
      ]
    );
}

function newTransfrtPeriod() {
    return ethers.utils.defaultAbiCoder.encode(
      ["address", "bytes"],
      [
        window.mainAddress.actionVentureTransferPeriodExe,
        "0x",
      ]
    );
}

function newModifyMultisign(minRequire) {
  return ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionOrgUpdateMinRequireExe,
      ethers.utils.defaultAbiCoder.encode(["uint256"], [minRequire]),
    ]
  );
}

async function doVote(daoOrg, signer, vote_id, choice) {
    console.log("===", vote_id, choice);
    const templateAction = ethers.utils.defaultAbiCoder.encode(
        ['address', 'bytes'],
        [
            window.mainAddress.actionVoteVote,
            ethers.utils.defaultAbiCoder.encode(
                ['uint64', 'bool'],
                [
                    vote_id,
                    choice
                ]
            )
        ]
    );
    return daoOrg.connect(signer).action(templateAction);
}

async function hasVote(voteContract, vote_id, account) {
    return await voteContract.hasVote(vote_id, account)
}

async function getVote(voteContract, vote_id) {
    const res = await voteContract.votes(vote_id)
    const vdata = {
        title: res.title,
        desc: res.content,
        isExecuted: res.executed,

        yea: parseInt(res.yea.toString()),
        nay: parseInt(res.nay.toString()),

        start_date: parseInt(res.startDate.toString()) * 1000,
        vote_time: parseInt(res.voteTime.toString()) * 1000,

        minRequire: parseInt(res.minRequire.toString()),
        supportRequiredPct: parseInt(res.supportRequiredPct.toString()),
        vote_id: vote_id
    }
    vdata.isOpen = vdata.start_date + vdata.vote_time > (new Date()).getTime()

    vdata.startDisplay = publicJs.dateFormat(vdata.start_date)
    vdata.endDisplay = publicJs.dateFormat(vdata.start_date + vdata.vote_time)
    vdata.metadata = res.metadata

    vdata.isTransfer = res.metadata && res.metadata.startsWith("transfer#")
    vdata.isApprove = res.metadata && res.metadata.startsWith("grantmethod#")
    vdata.isKick = res.metadata && res.metadata.startsWith("impeach#")
    vdata.isPeriod = res.metadata && res.metadata.startsWith("transferperiod#")
    vdata.isNewGp = res.metadata && res.metadata.startsWith("acceptgp#")
    vdata.isTransferManageFee = res.metadata && res.metadata.startsWith("transferManageFee#")

    if (!!res.metadata) {
        // NOTE: transfer#{withdrawId}
        vdata.wid = parseInt(res.metadata.split('#')[1])
    }
    return vdata
}


async function executeVote (daoOrg, signer, voteId) {
    const templateAction = ethers.utils.defaultAbiCoder.encode(
        ['address', 'bytes'],
        [
            window.mainAddress.actionVoteExecute,
            ethers.utils.defaultAbiCoder.encode(
                ['uint256'],
                [voteId]
            )
        ]
    );
    const res =  await daoOrg.connect(signer).action(templateAction);
    await res.wait()
}
const executeVoteAction = async (daoOrg, signer, voteId, action) => {
    const templateAction = ethers.utils.defaultAbiCoder.encode(
        ['address', 'bytes'],
        [
          action,
          ethers.utils.defaultAbiCoder.encode(
            ['uint256'],
            [voteId]
          )
        ]
    );
    return daoOrg.connect(signer).action(templateAction);
}

async function executeWithdrawVote(daoOrg, signer, wid) {
    return executeVoteAction(daoOrg, signer, wid, window.mainAddress.actionVaultExeWithdrawToken)
}

async function executeApproveGPVote(daoOrg, signer, voteId) {
    return executeVoteAction(daoOrg, signer, voteId, window.mainAddress.actionGrantMethodApply)
}

async function executeKickGPVote(daoOrg, signer, voteId) {
    return executeVoteAction(daoOrg, signer, voteId, window.mainAddress.actionVoteImpeachApply)
}

async function executeAddGPVote(daoOrg, signer, voteId) {
    return executeVoteAction(daoOrg, signer, voteId, window.mainAddress.actionVoteAcceptGpApply)
}

async function executeTransferPeriodVote(daoOrg, signer, voteId) {
    return executeVoteAction(daoOrg, signer, voteId, window.mainAddress.actionVoteTransferPeriodApply)
}

async function executeTransferManageFeeVote(daoOrg, signer, voteId) {
    return executeVoteAction(daoOrg, signer, voteId, window.mainAddress.actionVoteTransferManageFeeApply)
}

async function executeTransferNFTVote(daoOrg, signer, wid) {
    return executeVoteAction(daoOrg, signer, wid, window.mainAddress.actionVoteTransferNFTApply)
}

async function getVoterState(voteContract, voteid, Account) {
    return await voteContract.getVoterState(voteid, Account)
}

export default {
    getContract,
    getGrantContract,
    getExecuteContract,
    newVote,
    newTransferTokenVote,
    newTransferNFTVote,
    newTransferManageFeeVote,
    newApproveGPVote,
    newKickGPVote,
    newAddGPVote,
    newTransfrtPeriod,
    newModifyMultisign,
    doVote,
    getVote,
    hasVote,
    getVotesLength,
    executeVote,
    executeWithdrawVote,
    executeApproveGPVote,
    executeKickGPVote,
    executeAddGPVote,
    executeTransferPeriodVote,
    executeTransferManageFeeVote,
    executeTransferNFTVote,
    getVoterState,

    // old api
    newVoteTransfer,
}



