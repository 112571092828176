import React, { memo, useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import EditDAO from "components/modal/EditDAO";
import { useDaoContext } from "./provider";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useOrgContract, useVaultContract } from "hooks/useContract";
import {
  VOTE_MANAGER,
  VAULT_MANAGER,
  PERIOD_NO,
  LIKE_STATUS,
  VENTURE_STOCK_MANAGER,
  ORG_MANAGER,
  DAO_ORG,
  VENTURE_MANAGER,
} from "utils/constant";
import { LikeDao } from "api/graphql/dao";
import publicJs from "utils/publicJs";
import CopyStr from "components/copy";
import CopyButton from "components/common/copy";
import NewVote from "components/modal/newVote";




import useGateway from "hooks/useGateway";
import useOrgMembers from "hooks/useOrgMembers";
import { useAppContext } from "components/provider/appProvider";
import useCheckLogin from "hooks/useCheckLogin";
import { useClientContext } from "components/provider/clientProvider";
import MultiClamp from "react-multi-clamp";

import { TransactionEvent } from "utils/constant";
import useSubcribe from "hooks/useSubscribe";
import { getShortDisplay } from "utils/publicJs";
import { NFTTokenAvatar, TokenAvatar } from "components/common/avatar";
import { Tooltip } from "antd";

import Headertop from "../../components/header";
import LikeImg from "../../assets/images/Like-Selected.svg";
import UnLikeImg from "../../assets/images/Like.svg"
import ShareImg from "../../assets/images/Share.svg";
import EditImg from "../../assets/images/Edit.svg";
import Telegram from "../../assets/images/Telegram.svg";
import Twitter from "../../assets/images/Twittter.svg";
import Discord from "../../assets/images/Discord.svg";
import LinkImg from "../../assets/images/link.svg";
import Button, { ButtonGroup } from "components/common/button";
import { VOTE_TYPE } from "components/newVersionVote/detail/index";
import api from "api";

const DaoHeader = () => {

  const { t } = useTranslation();

  const isLogin = useCheckLogin();

  const { fullhandle, network: networkSimple } = useParams();

  const {
    state: { exploreScan },
    dispatch: dispatchAppEvent,
  } = useAppContext();
  const {
    state: {
      daoId,
      daoChainId,
      period,
      baseData,
      componentAddressMap,
      whoAmI: { isOwner, isGP },
      stockToken,
      isMultiSignWallet,
    },
    dispatch,
  } = useDaoContext();
  const { provider, chainId } = useWeb3React();

  const vaultContract = useVaultContract(
    componentAddressMap.get(VAULT_MANAGER),
    daoChainId
  );
  const orgContract = useOrgContract(
    componentAddressMap.get(ORG_MANAGER),
    daoChainId
  );

  const [showModal, setShowModal] = useState(false);
  const [showVote, setShowVote] = useState(0);
  const [like, setLike] = useState(false);
  const [minRequire, setMinRequire] = useState();
  const [show, setShow] = useState(false)
  const [voteDisabled, setVoteDisabled] = useState(true);
  const [selectedType, setSelectedType] = useState(0);
  const [orgMembers, setOrgMembers] = useState([]);
  
  const gateway = useGateway();

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleEdit = useCallback(() => {
    setShowModal(true);
  }, []);

  const [likeDao, { loading }] = useMutation(LikeDao, {
    onCompleted: (data) => {
      console.log("====", data);
      if (data && data.likeDao) {
        dispatch({
          type: "SET_BASE_DATA",
          payload: {
            ...baseData,
            is_like: baseData.is_like ? LIKE_STATUS.NOT_LIKE : LIKE_STATUS.LIKE,
          },
        });
      }
    },
    onError: (error) => {
      console.error("BundDao failed", error);
    },
  });

  const clickLike = useCallback(() => {
    if (!isLogin) {
      dispatchAppEvent({ type: "CONNECT_WALLET", payload: true });
      return;
    }
    if (!baseData) {
      return;
    }
    likeDao({
      variables: {
        daoId: daoId,
        chainId: chainId,
        isLike: baseData.is_like ? LIKE_STATUS.NOT_LIKE : LIKE_STATUS.LIKE,
      },
    });
  }, [daoId, baseData, chainId, isLogin]);

  const getMinRequire = async () => {
    try {
      const minRequire = (await vaultContract.minRequire()).toNumber();
      dispatch({ type: "SET_VOTE_MIN_SUPPORT", payload: minRequire });
      setMinRequire(minRequire);
    } catch (error) {
      console.error("get vote config failed: ", error);
    }
  }

  useEffect(async () => {
    if (!vaultContract) {
      return;
    }
    getMinRequire();
  }, [vaultContract]);

  const vaultContractAddress = useMemo(() => {
    return baseData?.vault_addr || componentAddressMap.get(VAULT_MANAGER);
  }, [baseData, componentAddressMap]);

  const stockTokenAddress = useMemo(() => {
    return baseData?.et_token_address
  }, [baseData])
    
  const getOrgMembers = async () => {
    try {
      const data = await orgContract.getDaoMembersList(0, 5000);
      setOrgMembers(data.totalMembers)
    } catch (error) {
      console.log("getDaoMembersList failed", error);
    }
  }

  useEffect(() => {
    if (!orgContract) {
      return;
    }
    getOrgMembers();
  }, [orgContract]);

  const voteConfig = useMemo(() => {
    if (!minRequire || !orgMembers.length) {
      return;
    }
    return `${minRequire}/${orgMembers.length}`;
  }, [minRequire, orgMembers]);

  const PeriodBar = useMemo(() => {
    switch (period) {
      case PERIOD_NO.RAISE:
        return <DepositStatus className="font-bold">{t("Period.InDeposit")}</DepositStatus>;
      case PERIOD_NO.INRETURN:
        return (
          <ReturnStatus className="font-bold return">{t("Period.InReturn")}</ReturnStatus>
        );
      case PERIOD_NO.MANAGEMENT:
        return (
          <ManagementStatus className="font-bold management">
            {t("Period.InManagement")}
          </ManagementStatus>
        );
      case PERIOD_NO.REDEMPTION:
        return (
          <RedemptionStatus className="font-bold redemption">
            {t("Period.InRedemption")}
          </RedemptionStatus>
        );
      default:
        return <></>;
    }
  }, [period]);

  const socailUrls = useMemo(() => {
    if (!baseData) {
      return <></>;
    }
    return (
      <>
        {[
          {
            key: "telegram_url",
            icon: <img src={Telegram} alt="" />,
            prefix: "http://t.me/",
          },
          {
            key: "twitter_url",
            icon: <img src={Twitter} alt="" />,
            prefix: "https://twitter.com/",
          },
          {
            key: "discord_url",
            icon: <img src={Discord} alt="" />,
            prefix: "",
          },
        ].map((item) => {
          return baseData[item.key] ? (
            <a
              href={`${item.prefix}${baseData[item.key]}`}
              key={item.key}
              target="_blank"
              rel="noreferrer"
            >
              {item.icon}
            </a>
          ) : (
            <React.Fragment key={item.key} />
          );
        })}
      </>
    );
  }, [baseData]);

  useEffect(() => {
    if (baseData) {
      setLike(baseData.is_like);
    }
  }, [baseData]);

  const showTransfer = useCallback(() => {
    setShowVote(true);
  }, []);

  const shareLink = useMemo(() => {
    return `${window.location.protocol}//${window.location.host}/${networkSimple}/${fullhandle}/dashboard/info`;
  }, [fullhandle]);

  const closeVote = useCallback(() => {
    setShowVote(false);
  }, []);

  useEffect(()=>{
    document.addEventListener("click",()=>{
      setShow(false);
    })
  },[])
  const handleMore = (e) =>{
    e.nativeEvent.stopImmediatePropagation();
    setShow(!show);
  }

  const transferVisible = useMemo(() => {
    return (
      isMultiSignWallet ||
      ((isGP || isOwner) && period === PERIOD_NO.MANAGEMENT)
    );
  }, [isGP, isOwner, period, isMultiSignWallet]);

  useEffect(async () => {
    if (!isGP && !isOwner) {
      setVoteDisabled(true);
      return
    }
    if (isMultiSignWallet) {
      setVoteDisabled(false);
      setSelectedType("wallet");
      return;
    }
    if (period === 2) {
      setVoteDisabled(false);
      setSelectedType(0);
      return;
    }
    if (period === 3) {
      setVoteDisabled(false);
      setSelectedType("redemption");
      return;
    }
    setVoteDisabled(true);
  }, [isGP, isOwner, period, chainId, isMultiSignWallet]);

  const SwitchBal = (num) =>{
    if(num){
      return Number(num).toFixed(2);
    }else{
      return 0
    }
  }

  const updatePeriod = async () => {
    const ventureContract = await api.venture.getContract(provider, componentAddressMap.get(VENTURE_MANAGER));
    const period = await ventureContract.getVenturePeriod();
    dispatch({ type: "SET_PERIOD", payload: period });
  };

  useEffect(() => {
    componentAddressMap.get(VENTURE_MANAGER) && updatePeriod();
  }, [componentAddressMap, provider]);

  useSubcribe(
    TransactionEvent.CheckVote,
    (_, { daoId: _daoId, voteType, support }) => {
      if (!support) {
        return;
      }
      if (_daoId === daoId && voteType) {
        if (voteType === VOTE_TYPE.TRANSFER_PERIOD) {
          updatePeriod();
        } else if (
          [
            VOTE_TYPE.ADD_MANAGER,
            VOTE_TYPE.REMOVE_MANAGER,
            VOTE_TYPE.MODIFY_MULTISIGN,
          ].find((t) => t === voteType)
        ) {
          getMinRequire();
          if (VOTE_TYPE.MODIFY_MULTISIGN !== voteType) {
            getOrgMembers();
          }
        }
      }
    }
  );

  const DaoLogo = useMemo(() => {
    if (baseData.logo && gateway) {
      return <Logo src={gateway + baseData.logo} alt="" />;
    } else if (baseData.et_token_type === 1) {
      return (
        <TokenAvatar
          address={stockToken?.address}
          size="108px"
          style={{ marginRight: "24px" }}
        />
      );
    } else if (componentAddressMap.get(DAO_ORG)) {
      return (
        <TokenAvatar
          address={componentAddressMap.get(DAO_ORG)}
          size="108px"
          style={{ marginRight: "24px" }}
        />
      );
    } else {
      return <></>;
    }
  }, [baseData, gateway, stockToken, componentAddressMap]);

  const tavTip = useMemo(() => {
    switch (period) {
      case PERIOD_NO.INRETURN:
        return t("Period.TavInReturnTip");
      case PERIOD_NO.REDEMPTION:
        return t("Period.TavInRedemptionTip");
      default:
        return t("Period.TavTip");
    }
  }, [period])

  return (
    <TopBox>
      <Headertop />
      <DetailHeader>
        <DAOID>id-{daoId}</DAOID>
        {showVote === 1 && (
          <NewVote
            showVoteModal={showVote}
            selectedAction={true}
            selectedType={2}
            CloseModal={closeVote}
          />
        )}
        {showVote === 2 && (
          <NewVote
            showVoteModal={showVote}
            selectedType={selectedType}
            CloseModal={closeVote}
          />
        )}
        {showModal && <EditDAO data={baseData} closeModal={closeModal} />}
        {DaoLogo}
        <Rht>
          <div style={{ width: "720px" }}>
            <TitleLine>
              <TitleBox>{baseData.name}</TitleBox>
              <RatioBox>{voteConfig}</RatioBox>
              {!isMultiSignWallet && PeriodBar}

              <ImgUl>
                {like ? (
                  <img src={LikeImg} alt="" onClick={clickLike} />
                ) : (
                  <img src={UnLikeImg} alt="" onClick={clickLike} />
                )}
                <CopyButton value={shareLink} className="copy">
                  {/*<ShareSvg />*/}
                  <img src={ShareImg} alt="" />
                </CopyButton>
                {isOwner && (
                  <img src={EditImg} alt="" onClick={() => handleEdit()} />
                )}
                {socailUrls}
              </ImgUl>
            </TitleLine>
            <UlBox>
              <AddressBox>
                {/* <div>{t("Home.Address")}</div> */}
                <span>{publicJs.AddresstoShow(vaultContractAddress)}</span>
                <CopyStr address={vaultContractAddress} />
              </AddressBox>
              <a
                href={`${exploreScan}address/${vaultContractAddress}`}
                rel="noreferrer"
                target="_blank"
                style={{ height: "24px" }}
              >
                <img
                  src={LinkImg}
                  style={{ width: "24px", marginLeft: "10px" }}
                  alt=""
                />
              </a>
              {!isMultiSignWallet && (
                <>
                  <Holders>
                    {/* <span className="num">{baseData.et_holders}</span> */}
                    <span className="tit">{t("Home.Holders")}</span>
                  </Holders>
                  <a
                    href={`${exploreScan}token/${stockTokenAddress}#balances`}
                    rel="noreferrer"
                    target="_blank"
                    style={{ height: "24px" }}
                  >
                    <img
                      src={LinkImg}
                      style={{ width: "24px", marginLeft: "10px" }}
                      alt=""
                    />
                  </a>
                </>
              )}
            </UlBox>
            <Description>
              {show && <DescAll>{baseData.desc}</DescAll>}
              {
                <div>
                  {/*<MultiClamp clamp="2" splitByWords={false} ellipsis={<span className="more" onClick={handleMore} >*/}
                  <MultiClamp
                    clamp="1"
                    splitByWords={false}
                    ellipsis={
                      <span className="more" onClick={handleMore}>
                        <strong>...</strong>
                        <span>{t("Home.More")}</span>
                      </span>
                    }
                  >
                    {baseData.desc}
                  </MultiClamp>
                </div>
              }
            </Description>
          </div>
          <RhtBox>
            <Tooltip title={tavTip} placement="top">
              <PriceBox
                isGrey={period === PERIOD_NO.INRETURN || period === PERIOD_NO.REDEMPTION}
              >
                <span className="pri">{getShortDisplay(baseData.tav, 2)}</span>
                <span className="symbol">USDT</span>
              </PriceBox>
            </Tooltip>
            <>
              <ButtonGroup gap="17px">
                {!voteDisabled && (
                  <VoteButton
                    onClick={() => setShowVote(2)}
                    width="unset"
                    height="32"
                  >
                    {t("Vote.NewVote")}
                  </VoteButton>
                )}
                {transferVisible && (
                  <VoteButton onClick={() => setShowVote(1)} height="32">
                    {t("Home.Transfer")}
                  </VoteButton>
                )}
              </ButtonGroup>
            </>
          </RhtBox>
        </Rht>
      </DetailHeader>
    </TopBox>
  );
};

export default DaoHeader;


const Logo = styled.img`
  width: 108px;
  height: 108px;
  border-radius: 50%;
  object-fit: cover;
`;

const TopBox = styled.div`
  //background: rgba(255,255,255,0.1);
  //backdrop-filter: blur(32px);
`;
const DetailHeader = styled.div`
    width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  box-sizing: border-box;
  padding:20px 0;
  @media (max-width: 1440px) {
    padding-top: 10px;
    padding-bottom: 0;
  }
`;
const Rht = styled.div`
  flex-grow: 1;
  padding-left: 20px;
  display: flex;
  justify-content:space-between;
`;

const TitleBox = styled.div`
  font-size: 30px;
  font-family: "Poppins-Bold";
  font-weight: bold;
  color: #FFFFFF;
  line-height: 46px;
`;
const Description = styled.div`
  margin-top: 11px;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  .more {
    span {
      color: #45beff;
      font-family: "Poppins-SemiBold";
      cursor: pointer;
      margin-left: 8px;
    }
  }
`;
const PriceBox = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  .pri {
    font-size: 32px;
    font-family: "Rubik-Bold";
    font-weight: bold;
    line-height: 30px;
    margin-right: 12px;
    color: ${({ isGrey }) => (isGrey ? "rgba(255, 255, 255, 0.5)" : "#45beff")};
  }
  .symbol {
    opacity: 0.5;
  }
`;
const UlBox = styled.div`
  display: flex;
  align-items: center;
  li{
    display: flex;
    align-items: center;
  }
`;

const AddressBox = styled.li`
  justify-content: flex-start;
  border-radius: 16px;
  border: 1px solid rgba(255,255,255,0.16);
  padding: 5px 16px;
  span{
    font-size: 14px;
    font-family: "Poppins-Light";
    font-weight: 300;
    color: #FFFFFF;
    line-height: 21px;
  }
  img{
    width: 24px;
  }
`

const Holders = styled.li`
    padding-left: 30px;
    border-left: 1px solid rgba(255,255,255,0.24);
  margin-left: 30px;
  .num{
    font-size: 14px;
    font-family: "Rubik-Medium";
    font-weight: 500;
    color: #FFFFFF;
    line-height: 17px;
  }
  .tit{
    font-size: 14px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    opacity: 0.5;
    margin-left: 8px;
  }
`
const DescAll = styled("div")`
  position: absolute;
  width: 100%;
  top: 30px;
  z-index: 9999;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(12px);
  font-size: 12px;
  padding: 12px;
`;

const TitleLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
const ImgUl = styled.div`
  display: flex;
  align-items: center;
  img{
    width: 24px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer;
  }
  .copy {
    height: 24px;
  }
`;
const RatioBox = styled.div`
  background: linear-gradient(90deg, rgba(255,255,255,0.32) 0%, rgba(255,255,255,0.5) 100%);
  border-radius: 20px;
  padding: 4px 16px;
  font-size: 14px;
  font-family: "Rubik-Medium";
  font-weight: 500;
  color: #FFFFFF;
  margin: 0 18px;
`;

const TagBox = styled.div`
  border-radius: 20px;
  font-size: 14px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #ffffff;
  padding: 4px 16px;
  margin-right: 40px;
`;

const DepositStatus = styled(TagBox)`
  background: #1bb572;
`;
const ManagementStatus = styled(TagBox)`
  background: #328aff;
`;
const RedemptionStatus = styled(TagBox)`
  background: #d51172;
`;
const ReturnStatus = styled(TagBox)`
  background: #778192;
`;

const ButtonTag = styled.div`
  padding: 6px 24px;
  background:rgba(50, 108, 255, 0.28);
  border-radius: 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF!important;
  line-height: 21px;
  cursor: pointer;
`;

const RhtBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
`;

const DAOID = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  color: transparent;
`

const VoteButton = styled(Button)`
  border-radius: 16px;
`;