import React, { memo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../components/common/button";
import { GlassBG } from "assets/styles/common";
import BackIcon from "assets/images/Back.svg";
import VerticalScroller from "components/common/verticalScroller";
import navBorderIcon from "assets/images/create/navSide.svg";
import navTailIcon from "assets/images/create/navTail.svg";
import navHeadIcon from "assets/images/create/navHead.svg";
import RadioOnIcon from "assets/images/checkbox/radio_on.svg";
import BackSvg from "assets/images/Back.svg";
import { NavLink } from "react-router-dom"

const CircleNumber = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  text-align: center;
`;

const NavItem = ({ isFirst, isLast, active, children, ...rest }) => {
  return (
    <NavItemStyled {...rest} active={active} isFirst={isFirst}>
      {active && !isFirst && <img src={navTailIcon} alt="" className="left" />}
      <div className="center" />
      {!isLast && <img className="border" src={navBorderIcon} alt="" />}
      {active && !isLast && <img src={navHeadIcon} alt="" className="right" />}
      {active && isLast && <div className="tail" />}
      {children}
    </NavItemStyled>
  );
};

const SideNavLayout = memo((props) => {
  const { t } = useTranslation();
  const { navs, onChangeNav, showNav, step, isPreview, from } = props;
  const [curNav, setCurNav] = useState();

  useEffect(() => {
    if (navs.length <= 0) return;
    setCurNav(step);
  }, [step, navs]);

  const handleNext = () => {
    const cur = navs.findIndex((item) => item.key === step);
    const curStr = navs[cur + 1].key;
    onChangeNav(curStr);
  };
  const handleBack = () => {
    const cur = navs.findIndex((item) => item.key === step);
    const curStr = navs[cur - 1].key;
    onChangeNav(curStr);
  };

  return (
    <SideNavLayoutStyled className="container">
      <SideNav>
        <BackNavButton to={from || "/home"}>
          <img src={BackSvg} alt="" />
          {t("Back")}
        </BackNavButton>
        {showNav && (
          <NavUl>
            {navs.map((n, i) => (
              <NavItem
                key={i}
                onClick={() => {
                  if (n.key === curNav) {
                    return;
                  }
                  setCurNav(n.key);
                  onChangeNav && onChangeNav(n.key);
                }}
                active={n.key === curNav}
                isLast={i === navs.length - 1}
                isFirst={i === 0}
              >
                {n.key !== curNav && n.available ? (
                  <img src={RadioOnIcon} alt="" />
                ) : (
                  <CircleNumber>{i + 1}</CircleNumber>
                )}
                <span
                  className={`title ${n.available ? "availableTitle" : ""} ${
                    n.key === curNav ? "activeTitle" : ""
                  } `}
                >
                  {t(n.name)}
                </span>
              </NavItem>
            ))}
          </NavUl>
        )}
      </SideNav>
      <LayoutContainer className={isPreview ? "isPreview" : "notPreview"}>
        {!isPreview && <GlassBG />}
        <VerticalScroller
          className={
            curNav === "choose_type" ? "scrollBox choose" : "scrollBox"
          }
          style={{ position: "relative" }}
        >
          {props.children}
        </VerticalScroller>
      </LayoutContainer>
      {curNav !== "preview" && curNav !== "choose_type" && (
        <ButtonGroup>
          <PrevButton onClick={handleBack}>
            <img src={BackIcon} alt="" />
          </PrevButton>
          <Button primary onClick={handleNext} width="110" className="next">
            {t("Next")}
            <img src={BackIcon} alt="" />
          </Button>
        </ButtonGroup>
      )}
    </SideNavLayoutStyled>
  );
});

export default SideNavLayout;

const SideNavLayoutStyled = styled.div`
  width: 1240px;
  margin: 0 auto;
  height: 100%;
  position: relative;
`;

const SideNav = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
const BackNavButton = styled(NavLink)`
  width: 110px;
  height: 36px;
  line-height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(32px);
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
  }
`;

const LayoutContainer = styled.div`
  position: relative;
  flex: 1;
  border-radius: 16px;
  height: calc(100% - 54px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &.notPreview {
    padding: 30px 10px;
    .scrollBox {
      padding: 30px 70px;
    }
    .scrollBox.choose {
      @media (max-width: 1440px) {
        padding: 0 70px;
      }
    }
  }
  &.isPreview {
    .scrollBox {
      overflow-y: hidden;
    }
  }
`;

const NavUl = styled.ul`
  flex: 1;
  display: flex;
  height: 36px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 10px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(32px);
  cursor: pointer;
`;

const ButtonGroup = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  position: absolute;
  right: 25px;
  bottom: 25px;
  .next {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 24px;
      transform: rotate(180deg);
      opacity: 0.5;
      margin-left: 4px;
    }
  }
`;

const PrevButton = styled.a`
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(24px);
  margin-right: 16px;
  cursor: pointer;
  img {
    width: 24px;
    opacity: 0.4;
    margin-top: 6px;
    margin-left: 6px;
  }
`;

const NavItemStyled = styled.li`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: ${(props) => (props.active ? "#000" : "unset")}; */
  .left {
    position: absolute;
    left: 0;
  }
  .center {
    width: ${(props) => (props.isFirst ? "100%" : "calc(100% - 48px)")};
    height: 100%;
    position: absolute;
    right: 15px;
    top: 0;
    background-color: ${(props) =>
      props.active ? "rgba(255, 255, 255, 0.1)" : "unset"};
  }
  .border,
  .right {
    position: absolute;
    right: -19px;
    top: 0;
    z-index: 99;
  }
  .border {
    right: -20px;
  }
  .title {
    margin-left: 16px;
    color: rgba(255, 255, 255, 0.5);
  }
  .availableTitle {
    color: #45beff;
  }
  .activeTitle {
    color: #ffffff;
  }
  .tail {
    width: 15px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
