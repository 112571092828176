import React, { memo, useEffect } from "react";
import styled from "styled-components";
import CreateProvider from "./provider";
import CreateContainer from "./create";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckLogin from "hooks/useCheckLogin";
import Headertop from "components/header";
import BgImg from "assets/Bg.png";

const CreatePage = memo(() => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const isLogin = useCheckLogin();

  useEffect(() => {
    if (!isLogin) {
      navigate("/");
    }
  }, [isLogin]);


  return (
    <CreatePageStyled className="container">
      <HeaderBox>
        <Headertop />
      </HeaderBox>
      <CreateProvider>
        <MainContent>
          <CreateContainer from={state} />
        </MainContent>
      </CreateProvider>
    </CreatePageStyled>
  );
});

export default CreatePage;

const HeaderBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
`;

const MainContent = styled.div`
  margin-top: 88px;
  width: 100%;
  height: calc(100vh - 128px);
`;

const CreatePageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${BgImg});
  background-size: 100%;
`;
