import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BaseModal from "./general";
import Button, { ButtonGroup } from "components/common/button";

export default function UpgradeContractsModal({ show, handleClose }) {
  const { t } = useTranslation();

  return (
    <BaseModal
      glass
      title={t("Home.UpgradeModalTitle")}
      show={show}
      width="440"
      closeModal={handleClose}
      titleBold
    >
      <ModalBody>
        <Tip>Check your wallet for a signature request</Tip>
        <ButtonGroup>
          <Button width="180" height="44" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button width="180" height="44" primary>
            {t("Confirm")}
          </Button>
        </ButtonGroup>
      </ModalBody>
    </BaseModal>
  );
}

const ModalBody = styled.div`
  margin: 30px 10px 6px;
`;

const Tip = styled.div`
  height: 34px;
  line-height: 34px;
  background: rgba(255, 164, 209, 0.15);
  border-radius: 8px;
  font-size: 12px;
  padding-left: 12px;
  color: #ffa4d1;
  margin-bottom: 20px;
`;
