import styled from "styled-components";

export const VoteItem = styled.div`
    margin-bottom: 32px;
`

export const Label = styled.div`
    font-size: 14px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    opacity: 0.5;
    margin-bottom: 12px;
`;
