export default {
    "DaoFactory": "0xA5d9661A9266AEBe5543A9285F859bf2659A1a6C",
    "actionGnosisConfirmProposal": "0xB31ca1Ff22B1F167f8766A529307BD22B722AF44",
    "actionGnosisSubmitProposal": "0xF5968FAB3C05e75bF8fF37821C31Dcf558D12f2d",
    "actionGrantMethodApply": "0x5f0F2C9Fb0B3F591A4865AA09D304937D63Df9B3",
    "actionGrantMethodRegister": "0x2a70C79E927f8a4Dd9A34871554c095F356c24f2",
    "actionGrantMethodVoteNewAuth": "0xfe4116A5Fb3878E8F7819fc80a7Bb440F2D9a30A",
    "actionOrgApplyJoin": "0x3983C87D3d41eE170D71da9EB25155E29c1D603a",
    "actionOrgMemberJoin": "0xcA96D7AB0C00ccAf5F05890CFAc06Ce905601b2e",
    "actionOrgMemberQuit": "0x970a25eaCC96a2228197958A927F39399C88C197",
    "actionOrgMgr": "0x3A8913E2F8302454c920d8E0EaA1fC2a986F6C32",
    "actionOrgSetJoinMode": "0x0da95aEB005A66DB5A55A6c3519bFa58e9252fdb",
    "actionOrgTransferOwner": "0x42AE400AB92405A8592f7F845753ED2A26DeC659",
    "actionOrgUpdateMembers": "0xDB8AF35E4854eD82dBc293E45367F095292b5D76",
    "actionVaultDepositNFT": "0x6AF65088664bfF233d154eCE1C930a2c7Ff937d1",
    "actionVaultDepositToken": "0xf55c070ca4332dbeBFD74B8188FF996E78636807",
    "actionVaultExeWithdrawToken": "0x3b2e199BfDBAE188Bf179089DAedFEcBC41bBf33",
    "actionVaultGPAuthorize": "0x020b8aB8A485A0BAA2d7886966af41F5d5Ebf3FD",
    "actionVaultOptToken": "0x6d16532933FD596d16F407d0F74EBcb04aD18b2a",
    "actionVaultUniswapV2Router02Swap": "0xb485bAe38dFBAeC89Ddd46b0d9486b6ceC861b20",
    "actionVaultWithdrawToken": "0x97070a52236690e4e0f485037E93C05001Be08F6",
    "actionVenturesClaimBonus": "0x4A013F564145E7D619df9990b3A7092231E19f1f",
    "actionVenturesClaimShare": "0xa7662A6183f7823cBFEd2718a164b2140F3069fE",
    "actionVenturesRaiseFailClaim": "0x199417d58fe03033d8294610aC561b8a9e1087A1",
    "actionVenturesRaiseMoney": "0x411a705143b525fDa410b1E8A98b3F49748E8753",
    "actionVenturesTestToSettlementPeriod": "0x43Cc3A24A860bAda436D1B8A96481D942b4B42CF",
    "actionVenturesToFreezingPeriod": "0x3adaf8D04c621eC3d64Fa998DB47Fd5042E51c52",
    "actionVenturesToRaiseFail": "0xa778848E57C1780cd9Cfa4a61cEdc3169c7f3078",
    "actionVoteAcceptGpApply": "0x81D7ED87F8cBaD781A3E7CF63E9BAbb49a69E10B",
    "actionVoteAcceptGpRegister": "0xA05db94D27AfF41a129e87C71a213D2D9F446A8A",
    "actionVoteCancel": "0x3752F01B84E0638465f8925f504208D421b89238",
    "actionVoteExecute": "0x51195cbdD3eadbCCF493A2aE23B0DFdAD94B8800",
    "actionVoteImpeachApply": "0x50323A462079d55C532940951a8Ae5729aD9B8d5",
    "actionVoteImpeachRegister": "0x5f3e13C4CE9141Daf8f661297229fBeD0A870FCb",
    "actionVoteNew": "0x6D9217120c94F0ce923fb5FdE92d5668d189E8a9",
    "actionVoteTransferManageFeeApply": "0x266cD418426b7CF8f45f261AC7aC117573BCA700",
    "actionVoteTransferManageFeeRegister": "0xd9a0d1f302C4Caa291e59954029A4559fe27EAf3",
    "actionVoteTransferNFTApply": "0x46865f4288b86D9398617593afcc6A86767F6944",
    "actionVoteTransferNFTRegister": "0xC8A84aab1c26512AE8615ae9a81C42858F50b3A2",
    "actionVoteTransferPeriodApply": "0x6425016a92508Bc1F0e2a58E91e7A4C0Bf4e9257",
    "actionVoteTransferPeriodRegister": "0xA805A7cE343d8822d5C168c0BA2326e807C7e9E5",
    "actionVoteVote": "0xe2AFb995a194f5F13b768Ddb12649becD07Ba52f",
    "actionVaultAave": "0x8C42A48eC3A5BB5a3346F69952D7693CF509F51d",
    "actionVaultAddNFT": "0x651B98fF983BCaa110abD95b87A621077a535e4d",
    "actionVaultUniswapV3Router": "0xB4889FEA7039b7cF23eB84338Aa163eb4c5BDecF",
    "actionVenturesSetRaiseSubTime": "0x9f842D092155402957548DD88C10c895aD1e7260",
    "actionVenturesVestingWithdraw": "0xbDbf5ffFBd89e06f0cE0ff1820105C1034736f43",
    "actionGrantMethodExe": "0xE1e1f2Fd22b32216056CA0fbB7f71C6Aaae668dD",
    "actionManageFeeTransferExe": "0x87F686DD7c7553d3ef464185f787969728133799",
    "actionOrgAcceptGPExe": "0xfF24001d372e2c1D6723B3a12201197cfdf360A3",
    "actionOrgImpeachGPExe": "0x3fc670bC8c76F8a6f231B1609CCd9cd62e1a596d",
    "actionVaultTransferExe": "0xdfC1BE14fDC82A26eB1A2b955f43cc98187E9D36",
    "actionVaultTransferNFTExe": "0xbE2cD2e4C37f3c0E1803721cdE149577CE97FFF4",
    "actionVentureTransferPeriodExe": "0xc03fFE9A06412426F38C68980aCEA6b3DD97280E",
    "actionOrgUpdateMinRequireExe": "0x632f7c08d0fe2da589590638E719DEd061b8623d"
}