import { useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import SearchImg from "assets/images/Search.svg";

import { useAppContext } from "components/provider/appProvider";
import { useTranslation } from "react-i18next";
import useSigner from "hooks/useSigner";
import { useDaoOrgContract } from "hooks/useContract";
import { useDaoContext } from "views/dao/provider";
import { DAO_ORG } from "utils/constant";
import api from "api";
import { ethers } from "ethers";
import { TokenLogo } from "components/common/avatar";
import { useWeb3React } from "@web3-react/core";
import TokenDefault from "../../img/defaultToken.svg";
import { COMMON_BASES } from "tokens/recommend";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import BaseModal from "components/modal/general";

import VerticalScroller from "components/common/verticalScroller";
import EmptyBox from "components/common/empty";

export default function AddToken(props) {
  const { handleClose, checkExist, show } = props;
  const { t } = useTranslation();

  const { provider, chainId } = useWeb3React();
  const {
    state: { tokens, exploreScan },
    dispatch,
  } = useAppContext();
  const {
    state: { daoId, componentAddressMap },
  } = useDaoContext();
  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const signer = useSigner();

  const [keyword, setKeyword] = useState("");
  const [displayList, setDisplayList] = useState([]);
  const [recommendDisplayList, setRecommendDisplayList] = useState([]);

  const recommend = useMemo(() => {
    let arrList = [];
    COMMON_BASES[chainId].forEach((item) => {
      const temp = tokens.find((t) => {
        return t.symbol === item;
      });
      if (temp) {
        arrList.push(temp);
      }
    });
    return arrList;
  }, [tokens, chainId]);

  const handleSearchToken = useCallback(async (address) => {
    try {
      const contract = await api.erc20.getContract(provider, address);
      const deci = await contract.decimals();
      setDisplayList([
        {
          name: await contract.name(),
          symbol: await contract.symbol(),
          deci,
          address,
        },
      ]);
    } catch (error) {
      console.error("handleSearchToken failed", error);
      setDisplayList([]);
    }
  }, []);

  useEffect(() => {
    const key = keyword.trim().toLowerCase();
    if (key) {
      const filterList = tokens.filter(
        (t) =>
          t.name.toLowerCase().indexOf(key) > -1 ||
          t.symbol.toLowerCase().indexOf(key) > -1 ||
          t.address.toLowerCase() === key
      );
      if (!filterList.length && ethers.utils.isAddress(key)) {
        handleSearchToken(key);
      } else {
        setDisplayList(
          filterList.sort((a, b) => a.symbol.length - b.symbol.length)
        );
      }

      // recommend
      setRecommendDisplayList(recommend.filter(
        (t) =>
          t.name.toLowerCase().indexOf(key) > -1 ||
          t.symbol.toLowerCase().indexOf(key) > -1 ||
          t.address.toLowerCase() === key
      ));
    } else {
      setDisplayList([...tokens]);
      setRecommendDisplayList(recommend);
    }
  }, [keyword]);

  const addToken = async (address, symbol) => {
    if (checkExist(address)) {
      return;
    }
    let txHash;
    const params = { symbol };
    try {
      const res = await api.vault.addToken(daoOrg, signer, address);

      txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.AddToken", params),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.AddToken,
          data: { ...params, daoId },
        },
      });

      handleClose(true);
    } catch (error) {
      console.error("addVaultToken failed", error);
      // dispatch({
      //   type: "MSGTYPE",
      //   payload: {
      //     msg: `add failed: ${
      //       (error?.error?.message) || "unknown"
      //     }`,
      //     closable: true,
      //   },
      // });
      // showNotification(
      //   NotificationType.Fail,
      //   t("Notification.AddTokenFailed", params)
      // );
    }
  };
  const handleError = (e) => {
    e.target.src = TokenDefault;
    e.target.οnerrοr = null;
  };
  return (
    <BaseModal
      show={show}
      title={t("Portfolio.Add")}
      closeModal={handleClose}
      width={440}
      glass
    >
      <SearchBox>
        <img src={SearchImg} alt="" />
        <input
          type="text"
          placeholder={t("Portfolio.search")}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </SearchBox>
      {!!recommendDisplayList.length && (
        <BtnGroup>
          {recommendDisplayList.map((item, index) => (
            <Btn
              key={index}
              onClick={() => addToken(item.address, item.symbol)}
              className={checkExist(item.address) ? "disabled" : ""}
            >
              <TokenLogo src={item.logoURI} onError={handleError} />
              {item.symbol}
            </Btn>
          ))}
        </BtnGroup>
      )}

      <VerticalScroller height={recommend.length > 3 ? "290px" : "330px"}>
        <UlBox>
          {displayList.length === 0 && (
            <EmptyBox className="empty" msg={t("Empty")} pure={true} />
          )}
          {displayList.map((item, index) => (
            <li
              key={`list_${index}`}
              onClick={() => addToken(item.address, item.symbol)}
              className={checkExist(item.address) ? "disabled" : ""}
            >
              <TokenLogo src={item.logoURI} onError={handleError} />
              <div>
                <div className="name">{item.symbol}</div>
                <div className="tips">{item.name}</div>
              </div>
            </li>
          ))}
        </UlBox>
      </VerticalScroller>
    </BaseModal>
  );
}

const SearchBox = styled.div`
  background: rgba(4, 23, 53, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(12px);
  border-radius: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  margin: 20px auto;
  padding-left: 12px;
  img {
    width: 18px;
    margin-right: 7px;
    opacity: 0.5;
  }
  input {
    border: 0;
    flex-grow: 1;
    background: transparent;
    color: #ffffff;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const BtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

const Btn = styled.div`
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px 10px 0;

  height: 40px;
  background: rgba(69, 190, 255, 0.24);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);

  cursor: pointer;
  padding: 0 11px;
  &.active {
  }
  img {
    width: 24px;
    height: 24px;
    border-radius: 14px;
    margin-right: 12px;
  }
  &:hover {
    border: 1px solid #45beff;
  }
  &.disabled {
    opacity: 0.5;
    border: 1px solid rgba(255, 255, 255, 0.08);
    cursor: not-allowed;
  }
`;

const UlBox = styled.ul`
  li {
    display: flex;
    align-items: center;
    padding: 13px 30px;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.04);
    }
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .name {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    .tips {
      font-size: 10px;
      line-height: 16px;
      opacity: 0.5;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .empty {
    min-height: unset;
    img {
      margin-top: 30px;
    }
  }
`;
