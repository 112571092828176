import { memo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
// import DropSvg from "components/svg/common/select";
import DropSvg from "../../assets/images/Open.svg";
import { useDaoContext } from "views/dao/provider";
import { useMemo } from "react";
import { useAppContext } from "components/provider/appProvider";
import SelectedImg from "../../img/selectedRight.svg";

const Select = memo((props) => {
  const { token, onChange, selected, isEdit, ...rest } = props;


  const {
    state: { fundList, tokens },
  } = useAppContext();

  const choose = useCallback(
    (newAddress, e) => {
      setShow(false);
      if (newAddress !== token) {
        onChange && onChange(newAddress);
      }
    },
    [token, onChange]
  );
  const [current, setCurrent] = useState();
  const [show, setShow] = useState(false);

  const tokenList = useMemo(() => {
    return fundList.map((f) => {
      const _t = tokens?.find(
        (t) => t.address.toLowerCase() === f.address.toLowerCase()
      );
      const d = { ...f };
      if (_t) {
        d.logo = _t.logoURI;
      }
      return d;
    });
  }, [fundList, tokens]);

  useEffect(() => {
    if (token && tokenList) {
      const fund = tokenList.find(
        (f) => f.address.toLowerCase() === token.toLowerCase()
      );
      setCurrent(fund);
    } else if (tokenList && tokenList.length) {
      setCurrent(tokenList[0]);
      onChange && onChange(tokenList[0].address);
    }
  }, [tokenList, token]);

  const handleShow = () =>{
    setShow(true);
  }

  //   const currentSelect = useMemo(() => {
  //     return fundList[selected];
  //   }, [fundList, selected]);
  if (!isEdit) {
    return (
      <DisplayBox>
        {current && (
          <>
            <img src={current.logo} alt="" className="logoImg" />
            <span>{current.symbol}</span>
            {/*<img src={DropSvg} alt="" className="down"/>*/}
          </>
        )}
      </DisplayBox>
    );
  }

  return (
    <DropBoxBg {...rest}>
      {
        !show &&<FlexLine onClick={()=>handleShow()}>
          {current && (
              <>
                <img src={current.logo} alt="" className="logoImg" />
                <span>{current.symbol}</span>
              </>
          )}
          <img src={DropSvg} alt="" className="down" />
        </FlexLine>
      }

      {/*<DropSvg />*/}
      {isEdit && show &&(
        <DropBox>
          {tokenList.map((item, index) => (
            <li key={index} onClick={(e) => choose(item.address.toLowerCase(), e)}>
              <div>
                <img src={item.logo} alt="" className="logoImg" />
                {item.symbol}
              </div>
              {/*{*/}
              {/*  current?.symbol.toLowerCase() === item.symbol.toLowerCase() && <img src={SelectedImg} alt="" className="rht"/>*/}
              {/*}*/}

            </li>
          ))}
        </DropBox>
      )}
    </DropBoxBg>
  );
});

export default Select;

const DisplayBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 38px;
  width: 131px;
  
  font-size: 14px;
  font-family: "Poppins-Light";
  font-weight: 300;
  color: #FFFFFF;
  line-height: 21px;
  .logoImg{
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
  }
  .down{
    width: 20px;
    margin-left: 15px;
    opacity: 0.5;
  }

`;

const DropBoxBg = styled(DisplayBox)`
  border-radius: 8px;
  padding: 0 10px;
  position: relative;
  cursor: pointer;

  .down{
    width: 20px;
    margin-left: 15px;
    opacity: 0.5;
  }
  .logoImg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const DropBox = styled.ul`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 4px 0;
  width: 131px;
  background: rgba(255,255,255,0.08);
  border-radius: 8px;
  border: 1px solid #328AFF;
  //backdrop-filter: blur(12px);

  li {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 10px;
    padding: 0 10px;
    &>div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    img {
      margin-right: 18px;
    }
    &:hover {
      background:rgba(255,255,255,0.1);
      border-radius: 8px;
    }
  }
`;
const FlexLine = styled.div`
display: flex;
  align-items: center;
    `