import { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import TableList from "./tokenTable";
import Button from "components/common/button";
import AddTokenModal from "components/modal/addTokenModal";
import EmptyBox from "components/common/empty";

import { useLazyQuery } from "@apollo/client";
import { QueryMyTokens } from "api/graphql/explore";
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import api from "api";
import { ethers } from "ethers";
import { getShortDisplay } from "utils/publicJs";
import LoadingBox from "components/common/loading";

import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import { useMulticallContract } from "hooks/useContract";
import { useNetworkData } from "hooks/useNetwork";
import TokenGetter from "./getTokens";
import AddImg from "../../assets/images/icon-add.svg";

const MyTokens = memo(() => {
  const { t } = useTranslation();
  const { chainId, provider, account } = useWeb3React();

  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [balanceMap, setBalanceMap] = useState({});
  const [tokenV2Map, setV2TokenMap] = useState({});
  const [nftMap, setNftMap] = useState({});

  const network = useNetworkData(chainId);
  const multiContract = useMulticallContract(network?.multicall, chainId);

  const [queryMyTokens] = useLazyQuery(QueryMyTokens, {
    onCompleted(data) {
      console.log("===data", data);
      setList(
        (data.myTokenList.data || []).map((d) => {
          if (d.et_type === 3) {
            return {
              ...d,
              name_display: d.name,
              balance: 0.0,
            };
          } else {
            return {
              ...d,
              name_display: d.symbol,
              balance: 0.0,
            };
          }
        })
      );
      setLoading(false);
    },
    onError(error) {},
  });

  const doQueryTokens = useCallback(() => {
    queryMyTokens({
      variables: {
        chainId,
        page: 1,
        size: 10000,
      },
      fetchPolicy: "network-only",
    });
  }, [chainId]);

  useEffect(() => {
    if (!chainId) {
      return;
    }
    doQueryTokens();
  }, [chainId]);

  const getBalance = useCallback(
    async (address) => {
      const contract = await api.erc20.getContract(provider, address);
      const deci = await contract.decimals();
      const balance = await contract.balanceOf(account);
      const balanceDisplay = ethers.utils.formatUnits(balance, deci);
      return {
        address,
        balance: Number(balanceDisplay),
        deci,
        balanceDisplay: getShortDisplay(balanceDisplay),
      };
    },
    [provider, account]
  );

  const getNFTBalance = async (address) => {
    const contract = await api.erc20.getNFTStockContract(provider, address);
    const balance = await contract.balanceOf(account, 0);
    const balanceDisplay = balance.toString();
    return {
      address,
      balance,
      balanceDisplay,
    };
  };

  const handleModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    doQueryTokens();
    setShowModal(false);
  }, [doQueryTokens]);

  const generateTokenMap = (tokens) => {
    if (!tokens) {
      return [];
    }
    const map = {};
    tokens.forEach((t) => {
      map[t.address] = { ...t };
    });
    return map;
  };

  const handleNFTTokenBalance = async () => {
    const nftAddress = list
      .filter((a) => !!a.address && a.et_type === 3)
      .map((a) => a.address);
    const nftData = await TokenGetter.getNFTTokens(
      multiContract,
      nftAddress,
      account
    );
    console.log("nft:", nftData);
    setNftMap(generateTokenMap(nftData));
  };

  const handleV1TokenBalance = async () => {
    const tokenAddressV1 = list
      .filter((a) => !!a.address && a.et_type === 1 && a.dao_version === 1)
      .map((a) => a.address);
    const tokenDataV1 = await TokenGetter.getErc20TokensV1(
      multiContract,
      tokenAddressV1,
      account
    );
    console.log("tokenDataV1:", tokenDataV1);
    setBalanceMap(generateTokenMap(tokenDataV1));
  };

  const handleTokenBalance = async () => {
    const tokenAddress = list
      .filter((a) => !!a.address && a.et_type === 1 && a.dao_version > 1)
      .map((a) => a.address);
    const tokenData = await TokenGetter.getErc20Tokens(
      multiContract,
      tokenAddress,
      account
    );
    console.log("tokenData:", tokenData);
    setV2TokenMap(generateTokenMap(tokenData));
  };

  useEffect(() => {
    if (!multiContract || !list.length) {
      return;
    }
    handleV1TokenBalance();
    handleTokenBalance();
    handleNFTTokenBalance();
    console.log(">>", list.length, account, multiContract);
  }, [list, account, multiContract]);

  const refreshTokenBalance = async (token) => {
    if (token.et_token === 3) {
      const data = await getNFTBalance(token.address);
      const tokenData = nftMap[token.address] || {};
      const newBalanceMap = {
        ...nftMap,
        [token.address]: { ...tokenData, ...data },
      };
      setNftMap(newBalanceMap);
    } else {
      const data = await getBalance(token.address);
      if (token.dao_version === 2) {
        const tokenData = balanceMap[token.address] || {};
        const newBalanceMap = {
          ...balanceMap,
          [token.address]: { ...tokenData, ...data },
        };
        setBalanceMap(newBalanceMap);
      } else {
        const tokenData = tokenV2Map[token.address] || {};
        const newBalanceMap = {
          ...tokenV2Map,
          [token.address]: { ...tokenData, ...data },
        };
        setV2TokenMap(newBalanceMap);
      }
    }
  }

  useSubcribe(TransactionEvent.ClaimToken, (_, token) => {
    const t = list.find((l) => l.address === token?.address?.toLowerCase());
    if (t) {
      refreshTokenBalance(t);
    }
  });

  useSubcribe(TransactionEvent.SendToken, async (_, token) => {
    refreshTokenBalance(token);
  });

  return (
    <ContentBox>
      <AddTokenModal
        show={showModal}
        closeModal={closeModal}
        refresh={doQueryTokens}
      />
      <LoadingBox loading={loading} />
      {!loading && !list.length && <EmptyBox />}
      {list.length > 0 && (
        <TableList
          list={list}
          balanceMap={balanceMap}
          tokenV2Map={tokenV2Map}
          nftMap={nftMap}
        />
      )}

      <ButtonBoxBg>
        <Button onClick={handleModal} primary width="150" height="40">
          {t("Explore.AddToken")}
          <img src={AddImg} alt="" />
        </Button>
      </ButtonBoxBg>
    </ContentBox>
  );
});

export default MyTokens;

const ButtonBoxBg = styled.div`
  position: absolute;
  right: 0;
  top: -70px;
  display: flex;
  justify-content: flex-end;
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 24px;
    }
  }
`;

const ContentBox = styled.div`
  position: relative;
  height: 100%;
`
const EmptyHeight= styled(EmptyBox)`
  margin-top: 30px;
`