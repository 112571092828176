import { ethers } from "ethers";
import abi from "../abi/web3Abi/VaultManager.json";
import NFTabi from "../abi/web3Abi/MockERC721.json";
import { getHigherGas } from "utils/utils";

const getContract = async (web3Instance, vaultAddress) => {
  return await new ethers.Contract(vaultAddress, abi, web3Instance);
};

const getNFTContract = async (web3Instance, vaultAddress) => {
  return await new ethers.Contract(vaultAddress, NFTabi, web3Instance);
};

const getManageFeeContract = async (web3Instance, address) => {
  return await new ethers.Contract(address, abi, web3Instance);
};

const getTokenList = async (vaultContract) => {
  return await vaultContract.getTokenList();
};

const getTokenBalance = async (vaultContract, tokenAddress) => {
  return await vaultContract.getBalanceOf(tokenAddress);
};

const transfer = async (daoOrg, signer, obj) => {
  const { tokenAddress, account, amount, deposit } = obj;
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultDepositToken,
      ethers.utils.defaultAbiCoder.encode(
        ["address", "address", "uint256", "bool"],
        [
          tokenAddress,
          account,
          amount,
          deposit, // true is deposit; false is withdraw
        ]
      ),
    ]
  );

  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
};

const transfer2 = async (erc20_rw, to_address, amount, deci) => {
  const res = await erc20_rw.transfer(
    to_address,
    ethers.utils.parseUnits(String(amount), deci)
  );
  await res.wait();
};

const addToken = async (daoOrg, signer, tokenAddress) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultOptToken,
      ethers.utils.defaultAbiCoder.encode(
        ["address", "bool"],
        [tokenAddress, true]
      ),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
};

async function getTransferDetail(vaultcontract, widthdrawId) {
  return await vaultcontract.withdrawApply(widthdrawId);
}

const submitTransaction = async (daoOrg, signer, data) => {
  const { vaultContractAddress, value, source, bytes } = data;
  const v = parseInt(value, 10) || 0;
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionGnosisSubmitProposal,
      ethers.utils.defaultAbiCoder.encode(
        ["address", "uint256", "string", "bytes"],
        [vaultContractAddress, v, source, bytes]
      ),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
};


const confirmTransaction = async (vaultContract, signer, data) => {
  const { voteId, flag } = data;

  const estimation = await vaultContract
    .connect(signer)
    .estimateGas.confirmTransaction(voteId, flag);

  const higherGas = getHigherGas(estimation);
  return vaultContract
    .connect(signer)
    .confirmTransaction(voteId, flag, { gasLimit: higherGas });
};


const executeTransaction = async (daoOrg, signer, id) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionGnosisExecutedPp,
      ethers.utils.defaultAbiCoder.encode(["uint256"], [id]),
    ]
  );
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
};

const addVoter = async (daoOrg, signer, gp) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionGnosisAddVoter,
      ethers.utils.defaultAbiCoder.encode(["address"], [gp]),
    ]
  );
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
};

async function addNFT(daoOrg, signer, obj) {
  const { tokenAddress, type } = obj;
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultAddNFT,
      ethers.utils.defaultAbiCoder.encode(
        // tokenaddress, tokenID, amount, is721=>1, is1155=2
        ["address", "uint256"],
        [tokenAddress, type]
      ),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
}

export default {
  getContract,
  getNFTContract,
  getManageFeeContract,
  getTokenList,
  getTokenBalance,
  transfer,
  transfer2,
  addToken,
  getTransferDetail,

  submitTransaction,
  confirmTransaction,
  executeTransaction,
  addVoter,
  addNFT,
};
