import { useWeb3React } from "@web3-react/core/dist";
import { useAuthContext } from "components/provider/authProvider";
import { useMemo } from "react";

export default function useCheckLogin() {
  const {
    state: { token, chainId: authChainId, account: authAccount },
    dispatchAuth,
  } = useAuthContext();
  const { chainId, account, connector } = useWeb3React();

  return useMemo(() => {
    if (!token || !chainId || !account) {
      return;
    }
    if ((chainId && authChainId && authChainId === chainId) &&  (account && authAccount && authAccount === account)) {
      return true;
    }
    if (chainId && authChainId && authChainId !== chainId) {
      dispatchAuth({ type: "CLEAR_AUTH" });
    }
    if (account && authAccount && authAccount !== account) {
      dispatchAuth({ type: "CLEAR_AUTH" });
    } 
    
    console.warn(token, authChainId, authAccount, chainId, account);
    return false;
  }, [token, authChainId, authAccount, chainId, account]);
}
