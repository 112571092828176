import MerkleTree from 'merkle-tree-solidity'
import { bufferToHex, keccak256, setLengthLeft, toBuffer } from "ethereumjs-util"
import { uploadByFetch } from "api/graphql/dao";

function toMaterializable(merklizedSetup) {
    return {
        root: merklizedSetup.root,
        records: merklizedSetup.records.map(a => {
            return {
                address: a.address,
                proof: a.proof
            }
        })
    };
}

function merklize(addressArray) {
    let records = addressArray.reduce((prev, curr) => {
        const address = curr;
        const existing = prev.find(u => u.toLowerCase() === address.toLowerCase());
        if (!existing) {
            prev.push(address);
        }
        return prev;
    }, []);


    const hashBuffers = records.map(r => {
        console.log('r:',r)
        const addressBuffer = toBuffer(r);
        const paddingBuffer = setLengthLeft(toBuffer("0x" + 1), 32);
        const preimage = Buffer.concat([addressBuffer, paddingBuffer]);
        const hashBuffer = keccak256(preimage);
        return hashBuffer;
    });


    const merkleTree = new MerkleTree(hashBuffers);
    const root = bufferToHex(merkleTree.getRoot());

    let data = records.map(r => {
        return {address:r}
    })

    data.forEach((record, idx) => {
        let proof = merkleTree.getProof(hashBuffers[idx]).map(p => bufferToHex(p));
        record.proof = proof;
        return record;
    })

    // console.log(`root:`, root);
    // console.log('records:', data);

    return { root: root, records: data };
}

export default function goMerklizedSetup(addressArray) {
    if (addressArray.length === 0) {
        return { root: '', records: [] };
    }
    return toMaterializable(merklize(addressArray))
}

export async function createAndUpload(records, chainId) {
    const resp = await uploadByFetch(new Blob([JSON.stringify(records)], { type: 'text/json' }), chainId)
    if (resp.status === 200) {
        const data = await resp.json();
        return data.data.fileUpload
      } else {
        throw new Error(`status is ${resp.status}`);
      }
}
