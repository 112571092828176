import { useContext, useCallback, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";
import { useTranslation } from "react-i18next";

const NUM_POINTS = 10;
export const DEFAULT_BONDING_CURVE_RATE_PERCENTAGE = 100;

export default function BondingCurveChart({
  graphSize,
  value,
  tokenAmount,
  tokenValue,
  isInitPriceLower,
  initPrice
}) {
  const { t } = useTranslation();
  // const {
  //   theme: { colors },
  // } = useContext(ThemeContext);

  const labelStyle = {
    fontSize: "12px",
    color: "rgba(255,255,255,0.5)",
  };

  const axisProps = {
    domain: [0, 10],
    //   stroke: colors.stroke.secondary,
    tick: false,
  };

  // Calculate the redemption rate using the below formula:
  // Formula: https://www.desmos.com/calculator/sp9ru6zbpk
  const calculateRedemptionDataPoints = useCallback(
    (_value) => {
      if (_value === undefined) return;

      const overflow = NUM_POINTS;
      const supply = NUM_POINTS;
      const normValue = _value / 100;

      const dataPoints = [];

      if (!isInitPriceLower) {
        for (let i = 0; i <= supply; i++) {
          dataPoints.push({ x: i, y: 5 });
        }
        return dataPoints;
      }

      let y;
      for (let i = 0; i <= supply; i++) {
        y =
          overflow * (i / supply) * (normValue + (i - i * normValue) / supply) +
          (isInitPriceLower ? 1 : 0);
        dataPoints.push({
          x: i,
          y,
        });
      }
      for (let i = 0; i <= 5; i++) {
        dataPoints.push({ x: NUM_POINTS + i + 1, y });
      }

      return dataPoints;
    },
    [isInitPriceLower]
  );

  const initialDataPoints = useMemo(
    () => calculateRedemptionDataPoints(DEFAULT_BONDING_CURVE_RATE_PERCENTAGE),
    [calculateRedemptionDataPoints]
  );

  const dataPoints = calculateRedemptionDataPoints(value);

  return (
    <ChartBox style={{ width: `${graphSize}px` }}>
      <div className="label">
        <div>
          {t("BondingCurve.TokenAmount")}: {tokenAmount || 0}
        </div>
        <div>
          {t("BondingCurve.TotalValue")}: {tokenValue || 0}
        </div>
      </div>
      {isInitPriceLower && <span className="initPrice">{initPrice}</span>}
      <LineChart width={graphSize} height={graphSize} data={dataPoints}>
        <defs>
          <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
            <stop offset="30%" stopColor="#326CFF" stopOpacity={1} />
            <stop offset="95%" stopColor="#A8ECFF" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid stroke="1" />
        <XAxis
          {...axisProps}
          dataKey="x"
          label={
            <Label value={t("BondingCurve.TokenAmount")} style={labelStyle} />
          }
        />
        <YAxis
          {...axisProps}
          dataKey="y"
          label={
            <Label
              transform="rotate(-90)"
              value={t("BondingCurve.TokenPrice")}
              angle={-90}
              style={labelStyle}
            />
          }
        />
        <Line
          type="monotone"
          data={initialDataPoints}
          dataKey="y"
          strokeDasharray="8 4"
          stroke="#999"
          strokeWidth={1}
          dot={false}
          activeDot={false}
        />
        <Line
          type="monotone"
          dataKey="y"
          stroke="url(#colorUv)"
          strokeWidth={4}
          dot={false}
          activeDot={false}
        />
      </LineChart>
    </ChartBox>
  );
}

const ChartBox = styled.div`
  position: relative;
  .label {
    position: absolute;
    color: #45beff;
    line-height: 20px;
    left: 350px;
    top: 0;
    width: 100%;
  }
  .initPrice {
    position: absolute;
    right: 244px;
    bottom: 50px;
    opacity: 0.5;
    font-size: 12px;
  }
`;
