import { memo, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";

import styled from "styled-components";

import Twitter from "assets/images/moreBox/Twittter.svg";
import Medium from "assets/images/moreBox/Medium.svg";
import Github from "assets/images/moreBox/Github.svg";

import DocSVG from "assets/images/moreBox/doc.svg";
import Button from "components/common/button";
import useNativeCurrency from "hooks/useNativeCurrency";
import {Tooltip} from "antd";
import MoreImg from "../../assets/images/more.svg";
import { useAuthContext } from "components/provider/authProvider";
import useCheckLogin from "hooks/useCheckLogin";

const MoreBox = memo(() => {
  const { t } = useTranslation();

  const { dispatchAuth } = useAuthContext();

  const { provider, account, chainId, connector } = useWeb3React();
  const native = useNativeCurrency(provider, account, chainId);
  const isLogin = useCheckLogin();

  const onLogout = useCallback(() => {
    dispatchAuth({ type: "CLEAR_AUTH" });
    // dispatchAuth({ type: "CHANGE_WALLET", payload: undefined });
    window.location.reload();
  }, [connector]);

  const FormatNum = useCallback((size) => {
    console.log("==FormatNum===", size);
    const stringData = String(size)
      return stringData;
    // if (stringData.length > 18) {
    //   return stringData.slice(0, 18) + "..."
    // } else {
    //   return stringData
    // }
  }, []);

  const Contact = useMemo(() => {
    return (
      <ContactBox>
        <span>{t("Header.Contact")}</span>
        <a href="https://medium.com/@subdao" target="_blank" rel="noreferrer">
          <img src={Medium} alt="" />
        </a>
        <a
          href="https://github.com/SubDAO-Network"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Github} alt="" />
        </a>
        <a
          href="https://twitter.com/subdao_network"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Twitter} alt="" />
        </a>
      </ContactBox>
    );
  }, []);

  const Doc = useMemo(() => {
    return (
      <>
        <img src={DocSVG} alt="" className="doc" />
        <a href="https://wiki.subdao.network/" target="_blank" rel="noreferrer">
          Docs
        </a>
      </>
    );
  }, []);

  const loginBox = useMemo(() => {
    return (
      <>
        <FullLine>{Doc}</FullLine>
        {Contact}
      </>
    );
  }, []);

  const logoutBox = useMemo(() => {
    return (
      <>
        <HalfBox>
          <HalfLine>
            <img
              src={native.logo}
              className="logo"
              alt=""
              style={{ marginTop: "-0.2rem" }}
            />
              <Tooltip title={native.balance}>
                 <div className="balance">{FormatNum(native.balance)}</div>
              </Tooltip>

            {/*<span>{native.balance}</span>*/}
          </HalfLine>
          {/*<HalfLine>{Doc}</HalfLine>*/}
        </HalfBox>
        {Contact}
        <DisconnectBtn>
          <Button height={54} primary onClick={onLogout}>
            {t("Header.Disconnect")}
          </Button>
        </DisconnectBtn>
      </>
    );
  }, [native, onLogout]);

  return (
    <MoreBoxStyled>
      {/*<MoreSVG color={"#FFFFFF"} style={{ cursor: "pointer" }} />*/}
        <img src={MoreImg} alt=""/>
      <BoxContent className="boxContent">
        <DropBox>{(isLogin && account) ? logoutBox : loginBox}</DropBox>
      </BoxContent>
    </MoreBoxStyled>
  );
});

export default MoreBox;

const MoreBoxStyled = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border-color: rgba(255, 255, 255, 0.2);
  }
  & > img {
    cursor: pointer;
  }

  &:hover {
    //svg #color {
    //  fill: #efefef;
    //}
    .boxContent {
      display: block;
    }
  }
`;
const BoxContent = styled.div`
  display: none;
  position: absolute;
  top: 36px;
  right: 0;
  padding-top: 17px;
  z-index: 999;
`;

const DropBox = styled.div`
  width: 312px;
  background: rgba(255,255,255,0.08);
  border-radius: 8px;
  border: 1px solid rgba(255,255,255,0.08);
  backdrop-filter: blur(32px)!important;
  box-sizing: border-box;
  padding: 10px;
`;

const BaseLine = styled.div`
  background:rgba(255,255,255,0.1);
  border-radius: 8px;
  height: 54px;
  display: flex;
  align-items: center;
  &,
  a {
    font-size: 14px;
    color: #fff;
  }
  padding: 0 20px;
  .doc {
    margin-right: 14px;
  }
`;

const FullLine = styled(BaseLine)``;

const HalfLine = styled(BaseLine)`
  width: 100%;
  position: relative;
  .logo {
    margin-right: 8px;
    width: 24px;
  }
  &:hover {
    //span {
    //  display: block;
    //}
  }
  .balance {
    flex-grow: 1;
    margin-left: 10px;
    text-align: left;
    //position: absolute;
    //top: -10px;
    //left: 42px;
    //display: none;
  }
`;

const HalfBox = styled.div`
  display: flex;
`;

const ContactBox = styled(FullLine)`
  margin-top: 10px;
  span {
    margin-right: 15px;
    white-space: nowrap;
  }
  a {
    margin-right: 17px;
    height: 24px;
    img {
      width: 24px;
    }
  }
`;

const DisconnectBtn = styled.div`
  margin-top: 10px;
  &,
  button {
    width: 100%;
    &:hover{
      background: linear-gradient(311deg, #61e1ff 0%, #326cff 100%);
      box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
      border-radius: 10px;
      border: 0;
    }
  }
`;
