import { useEffect, memo, useState } from "react";
import { ethers } from "ethers";
import moment from "moment";
import styled from "styled-components";
import { useDaoContext } from "views/dao/provider";
import api from "api";
import { getShortDisplay } from "utils/publicJs";
import { useMemo } from "react";
import Avatar from "components/common/avatar";
import AllTokenAvatar from "components/common/allTokenAvatar";

import UniswapLogo from "assets/images/vote/Uniswap.svg";
import AaveLogo from "assets/images/vote/aave.svg";
import { useAppContext } from "../../provider/appProvider";
import { useClientContext } from "components/provider/clientProvider";
import TokenDefault from "img/defaultToken.svg";
import { useTranslation } from "react-i18next";
import { useNetworkData } from "hooks/useNetwork";
import getIdentity from "utils/tokenIdentity";
import { Block } from "./styles";
import AddressBox from "components/common/addressBox";

const SuperVote = memo(({ orgMap, data }) => {
  const { t } = useTranslation();

  const { providers } = useClientContext();
  const {
    state: { daoChainId },
  } = useDaoContext();
  const {
    state: { tokens },
  } = useAppContext();
  const network = useNetworkData(daoChainId);

  const [toAddress, setToAddress] = useState();

  const [amount, setAmount] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [identity, setIdentity] = useState("");

  const [expire, setExpire] = useState();
  const [method, setMethod] = useState("");
  const [logo, setLogo] = useState("");

  const [unlimited, setUnlimited] = useState(false);

  const addressName = useMemo(() => {
    return orgMap && toAddress && orgMap[toAddress];
  }, [toAddress, orgMap]);

  const dapp = useMemo(() => {
    if (method.startsWith("gp_buy")) {
      if (network?.swapApp) {
        return <DappText>{network?.swapApp}</DappText>;
      } else {
        return <Dapp src={UniswapLogo} />;
      }
    } else if (method.startsWith("gp_loan")) {
      return <Dapp src={AaveLogo} />;
    } else {
      return <></>;
    }
  }, [method, network]);

  const handleToken = async (address, amount) => {
    if (address.toLowerCase() === network.wrappedToken) {
      const nativeCurrency = network.nativeCurrency;
      setAmount(
        getShortDisplay(ethers.utils.formatUnits(amount, nativeCurrency.decimals))
      );
      setSymbol(nativeCurrency.symbol)
      setLogo(network.logo);
      return;
    }
    const erc20 = await api.erc20.getContract(providers[daoChainId], address);
    const token = {
      symbol: await erc20.symbol(),
      deci: await erc20.decimals(),
    };
    setAmount(getShortDisplay(ethers.utils.formatUnits(amount, token.deci)));
    setSymbol(token.symbol);
    const imgArr = tokens.find(
      (item) => item.address.toLowerCase() === address.toLowerCase()
    );
    setLogo(imgArr?.logo || imgArr?.logoURI || TokenDefault);
    setIdentity(getIdentity(address));
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const wdata = ethers.utils.defaultAbiCoder.decode(
      ["address", "address", "string", "uint64", "bytes"],
      data
    );
    setToAddress(wdata[0]);
    setMethod(wdata[2].toLowerCase());
    setExpire(
      moment(wdata[3].toNumber() * 1000).format("MMMM Do YYYY, h:mm A")
    );

    const bytes = wdata[4];
    if (bytes === "0x") {
      setUnlimited(true)
     } else {
      const tokenData = ethers.utils.defaultAbiCoder.decode(
        ["address", "address", "uint256", "uint8", "address"],
        bytes
      );
      handleToken(tokenData[0], tokenData[2]);
    }
  }, [data]);

  return (
    <Box>
      <Block title={t("Vote.Address")} className="flexBox">
        <Avatar address={toAddress} size="39px" />
        <Account>
          {addressName && <div className="name">{addressName}</div>}
          <AddressBox address={toAddress} />
        </Account>
      </Block>
      <Block title={t("Vote.AvailableDApp")}>{dapp}</Block>
      <Block title={t("Vote.approveTime")}>{expire}</Block>
      <SpendBox title={t("Vote.SpendLimit")} className="flexBox">
        {unlimited ? (
          <>
            <span className="limit">{t("Vote.Unlimited")}</span>
            <AllTokenAvatar />
            <span className="symbol">{t("Vote.AllTokens")}</span>
          </>
        ) : (
          <>
            <span className="num">{amount}</span>
            <img src={logo} alt="" className="logo" />
            <span className="symbol">{symbol}</span>
            {identity && <span className="identity">{identity}</span>}
          </>
        )}
      </SpendBox>
    </Box>
  );
});

export default SuperVote;

const SpendBox = styled(Block)`
  .limit {
    font-size: 16px;
  }
  span:nth-child(2) {
    margin-left: 20px;
    margin-right: 8px;
  }
  .logo {
    margin: 0 5px;
  }
`;

const Account = styled.div`
  margin-left: 10px;
  .name {
    line-height: 20px;
  }
`;
const Dapp = styled.img`
  height: 24px;
`;
const DappText = styled.span`
  line-height: 24px;
  font-size: 18px;
`;
const Box = styled.div`
  .flexBox {
    img {
      width: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .identity {
    margin-left: 5px;
    opacity: 0.5;
  }
`;
