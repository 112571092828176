import ClaimModal from "./claimToken";
import { useDaoOrgContract, useVentureContract } from "hooks/useContract";
import { useEffect, useState } from "react";
import moment from "moment";
import { ContractVestingType } from "utils/constant";

const ClaimWrapper = ({ chainId, token, closeModal }) => {
  const ventureContract = useVentureContract(token?.ventures_addr, chainId);
  const daoOrg = useDaoOrgContract(token?.dao_address, chainId);

  const [vestingType, setVestingType] = useState();
  const [vestingData, setVestingData] = useState();

  const formatTime = (time) => {
    const m = moment(time * 1000);
    return m.format("YYYY/MM/DD HH:mm:ss");
  };

  const getVest = async () => {
    const vesting = await ventureContract.vesting();
    console.log("Vesting:", vesting);
    const type = vesting.vestingType;
    setVestingType(type);
    if (type === ContractVestingType.OneTimeRelease) {
      const time = formatTime(vesting.endTime.toNumber());
      setVestingData({
        endDate: time,
      });
    } else if (type === ContractVestingType.LinearRelease) {
      setVestingData({
        startDate: formatTime(vesting.startTime.toNumber()),
        endDate: formatTime(vesting.endTime.toNumber()),
      });
    } else if (type === ContractVestingType.StagedRelease) {
      const data = await ventureContract.getScheduleItem();
      let prevSum = 0;
      const lst = data.map((d) => {
        const currentPercent = d.percent.toNumber();
        const usePercent = currentPercent - prevSum;
        prevSum = currentPercent;
        return {
          date: formatTime(d.timeStamp.toNumber()),
          ratio: `${usePercent / 100}%`,
        };
      });
      setVestingData(lst);
    }
  };

  useEffect(() => {
    if (!token || !ventureContract) {
      return;
    }
    getVest();
  }, [token, ventureContract]);

  return token ? (
    <ClaimModal
      show={!!token}
      ventureContract={ventureContract}
      closeModal={closeModal}
      daoOrg={daoOrg}
      stockToken={token}
      vestingType={vestingType}
      vestingData={vestingData}
    />
  ) : (
    <></>
  );
};

export default ClaimWrapper;
