import { useAppContext } from "components/provider/appProvider";
import { memo, useEffect } from "react";
import { parseVoteEvent } from "utils/utils";
import showNotification, {
  showVoteNotification,
  closeNotification,
  NotificationType,
} from "components/common/notification";

import {
  CaseVote,
  CreateVote,
  ExecuteVote,
} from "api/graphql/vote";

import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PubSub from "pubsub-js";
import { TransactionEvent } from "utils/constant";
import { useClientContext } from "components/provider/clientProvider";
import { getTxIdFromEvent } from "utils/utils";
import { putTx } from "api/graphql/tx";

export const TransType = {
  New_Vote: "new_vote",
  ExecuteVote: "execute_vote",
  VoteVote: "vote_vote",
  SendToken: "send_token",
  AddToken: "add_token",
  AddNFT: "add_nft",
  EditName: "edit_name",
  Refund: "refund",
  Freeze: "freeze",
  Withdraw: "withdraw",
  WithdrawBonus: "withdraw_bonus",
  DefiWithdraw: "defi_withdraw",
  DefiSupply: "defi_supply",
  DefiBorrow: "defi_borrow",
  DefiRepay: "defi_repay",
  CreateProposal: "create_proposal",
  ConfirmProposal: "confirm_proposal",
  Deposit: "deposit",
  EditCloseDate: "edit_close_date",
  ClaimToken: "claim_token"
};

const TransStatus = {
  Default: 0,
  Pending: 1,
  Success: 2,
  Failed: 3,
};

const TransactionCheck = memo(() => {
  const { t } = useTranslation();
  const {
    state: { transactions, exploreScan },
    dispatch,
  } = useAppContext();

  const handleNewVote = async (txResult, data, hash, hashLink) => {
    const event = txResult.events.find((e) => e.event === "CreateTransaction");
    let id = undefined;
    if (!event && txResult.events.length === 1) {
      id = getTxIdFromEvent(txResult.events[0]);
    } else if (event) {
      id = getTxIdFromEvent(event);
    }
    if (id === undefined) {
      return
    }

    // request bind vote
    try {
      // put cache
      await putTx(data.chainId, data.daoId, "vote", id, {
        txHash: hash,
        createAt: Math.floor(Date.now() / 1000),
        data: data.bytes,
        txID: id,
        source: "vote",
      });
    } catch (error) {
      console.error("bind create vote failed", error);
    } finally {
      showNotification(
        NotificationType.Success,
        t("Notification.VoteSuccess"),
        hashLink,
        `/${data.networkSimple}/${data.fullhandle}/vote/${id}`,
        undefined,
        { duration: 8000 }
      );
      PubSub.publish(TransactionEvent.NewVote, { daoId: data.daoId });
    }
  };

  const handleVoteVote = async(tx, hashLink) => {
    const data = tx.data;
    PubSub.publish(TransactionEvent.HandleVote, data);
    PubSub.publish(TransactionEvent.CheckVote, data);
    showNotification(
      NotificationType.Success,
      data.support
        ? t("Notification.AgreeVoteSuccess")
        : t("Notification.DisagreeVoteSuccess"),
      hashLink,
      undefined,
      undefined,
      { duration: 8000 }
    );
  };

  const handleSendToken = useCallback((token) => {
    PubSub.publish(TransactionEvent.SendToken, token);
  }, []);

  const handleAddToken = useCallback((daoId) => {
    PubSub.publish(TransactionEvent.AddToken, { daoId });
  }, []);

  const handleAddNFT = useCallback((daoId) => {
    PubSub.publish(TransactionEvent.AddNFT, { daoId });
  }, []);

  const handleEditName = useCallback((daoId) => {
    PubSub.publish(TransactionEvent.EditName, { daoId });
  }, []);

  const handleRefund = useCallback((daoId) => {
    PubSub.publish(TransactionEvent.Refund, { daoId });
  }, []);

  const handleFreeze = useCallback((daoId) => {
    PubSub.publish(TransactionEvent.Freeze, { daoId });
  }, []);

  const handleReturn = useCallback((daoId) => {
    PubSub.publish(TransactionEvent.Return, { daoId });
  }, []);

  const handleDefi = useCallback((daoId) => {
    PubSub.publish(TransactionEvent.Defi, { daoId });
  }, []);

  const handleWCT = useCallback(async (txResult, data, hash) => {
    console.log(">>>handleWCT", txResult, data, hash);
    const event = txResult.events.find((e) => e.event === "CreateTransaction");
    let id = undefined;
    if (!event && txResult.events.length === 1) {
      id = getTxIdFromEvent(txResult.events[0]);
    } else if (event) {
      id = getTxIdFromEvent(event);
    }
    if (id === undefined) {
      return;
    }
    // request bind vote
    try {
      // put cache
      await putTx(data.chainId, data.daoId, "tx", id, {
        txHash: hash,
        createAt: Math.floor(Date.now() / 1000),
        data: data.bytes,
        txID: id,
        source: data.source,
      });
    } catch (error) {
      console.error("bind create proposal failed", error);
    }
  
    PubSub.publish(TransactionEvent.WalletConnect, {
      newId: id,
      ...data,
    });
    return id
  }, []);

  const handleWCTProposal = useCallback((data) => {
    PubSub.publish(TransactionEvent.DoWalletConnect, data);
  }, []);

  const handleModifyDate = useCallback((data) => {
    PubSub.publish(TransactionEvent.ModifyCloseDate, data);
  }, []);

  const handleClaimToken = useCallback((data) => {
    PubSub.publish(TransactionEvent.ClaimToken, data);
  }, []);

  const checkStatus = async (tx) => {
    console.log("tx", tx);

    if (!tx.trans) {
      return;
    }

    let r;
    try {
      r = await tx.trans.wait();
      console.log("r:", r);
      dispatch({
        type: "UPDATE_TRANSACTION",
        payload: { ...tx, status: TransStatus.Success },
      });

      const hashLink = `${exploreScan}tx/${tx.hash}`;

      let msg, go2where, buttonText;
      switch (tx.type) {
        case TransType.New_Vote:
          handleNewVote(r, tx.data, tx.hash, hashLink);
          return;
        case TransType.VoteVote:
          handleVoteVote(tx, hashLink);
          return;
        case TransType.SendToken:
          handleSendToken(tx.data);
          msg = t("Notification.SendTokenSuccess", tx.data);
          break;
        case TransType.AddToken:
          handleAddToken(tx.data?.daoId);
          msg = t("Notification.AddTokenSuccess", tx.data);
          break;
        case TransType.AddNFT:
          handleAddNFT(tx.data?.daoId);
          msg = t("Notification.AddNFTSuccess");
          break;
        case TransType.EditName:
          handleEditName(tx.data?.daoId);
          msg = t("Notification.EditNameSuccess");
          break;
        case TransType.Refund:
          handleRefund(tx.data.daoId);
          msg = t("Notification.RefundSuccess");
          break;
        case TransType.Freeze:
          handleFreeze(tx.data.daoId);
          msg = t("Notification.FreezeSuccess");
          break;
        case TransType.Return:
          handleReturn(tx.data.daoId);
          msg = t("Notification.ReturnSuccess");
          break;
        case TransType.Withdraw:
          msg = t("Notification.WithdrawSuccess", tx.data);
          break;
        case TransType.WithdrawBonus:
          msg = t("Notification.WithdrawBonusSuccess", tx.data);
          break;
        case TransType.DefiSupply:
          handleDefi(tx.data.daoId);
          msg = t("Notification.DefiSupplySuccess", tx.data);
          break;
        case TransType.DefiWithdraw:
          handleDefi(tx.data.daoId);
          msg = t("Notification.DefiWithdrawSuccess", tx.data);
          break;
        case TransType.DefiBorrow:
          handleDefi(tx.data.daoId);
          msg = t("Notification.DefiBorrowSuccess", tx.data);
          break;
        case TransType.DefiRepay:
          handleDefi(tx.data.daoId);
          msg = t("Notification.DefiRepaySuccess", tx.data);
          break;
        case TransType.CreateProposal:
          const newId = await handleWCT(r, tx.data, tx.hash);
          buttonText = t("Notification.GotoPropose");
          msg = t("Notification.proposalSuccess");
          go2where = `${tx.data.pathname}?tx=${newId}&txHash=${tx.hash}`;
          break
        case TransType.ConfirmProposal:
          handleWCTProposal(tx.data);
          go2where = `${tx.data.pathname}?tx=${tx.data.txId}`;
          buttonText = t("Notification.GotoCheck");
          msg = tx.data.support
            ? t("Notification.proposalApproveSuccess")
            : t("Notification.proposalRejectSuccess");
          break;
        case TransType.EditCloseDate:
          handleModifyDate(tx.data);
          msg = t("Notification.ModifyCloseTimeSuccess");
          break;
        case TransType.ClaimToken:
          go2where = "/explore?tab=tokens";
          buttonText = t("Explore.MyTokens");
          handleClaimToken(tx.data);
          msg = t("Notification.ClaimTokenSuccess", tx.data);
          break;
        default:
          return;
      }
      
      showNotification(NotificationType.Success, msg, hashLink, go2where, buttonText, { duration: 8000 });
    } catch (error) {
      console.error("wait trans failed", error);
      dispatch({
        type: "UPDATE_TRANSACTION",
        payload: { ...tx, status: TransStatus.Failed },
      });
      let msg = "";
      switch (tx.type) {
        case TransType.New_Vote:
          msg = t("Notification.VoteFailed");
          break;
        case TransType.VoteVote:
          msg = tx.data.support
            ? t("Notification.AgreeVoteFailed")
            : t("Notification.DisagreeVoteFailed");
          dispatch({
            type: "REMOVE_MULTISIGN_TX",
            payload: { daoId: tx.data.daoId, id: tx.data.voteId },
          });
          break;
        case TransType.ExecuteVote:
          msg = t("Notification.ExecuteVoteFailed");
          break;
        case TransType.SendToken:
          msg = t("Notification.SendTokenFailed", tx.data);
          break;
        case TransType.AddToken:
          msg = t("Notification.AddTokenFailed", tx.data);
          break;
        case TransType.AddNFT:
          msg = t("Notification.AddNFTFailed");
          break;
        case TransType.EditName:
          msg = t("Notification.EditNameFailed");
          break;
        case TransType.Refund:
          msg = t("Notification.RefundFailed");
          break;
        case TransType.Freeze:
          msg = t("Notification.FreezeFailed");
          break;
        case TransType.Return:
          msg = t("Notification.ReturnFailed");
          break;
        case TransType.Withdraw:
          msg = t("Notification.WithdrawFailed");
          break;
        case TransType.WithdrawBonus:
          msg = t("Notification.WithdrawBonusFailed");
          break;
        case TransType.DefiSupply:
          msg = t("Notification.DefiSupplyFailed", tx.data);
          break;
        case TransType.DefiWithdraw:
          msg = t("Notification.DefiWithdrawFailed", tx.data);
          break;
        case TransType.DefiBorrow:
          msg = t("Notification.DefiBorrowFailed", tx.data);
          break;
        case TransType.DefiRepay:
          msg = t("Notification.DefiRepayFailed", tx.data);
          break;
        case TransType.CreateProposal:
          msg = t("Notification.proposalFailed");
          break;
        case TransType.ConfirmProposal:
          msg = tx.data.support
            ? t("Notification.proposalApproveFailed")
            : t("Notification.proposalRejectFailed");
          dispatch({
            type: "REMOVE_MULTISIGN_TX",
            payload: { daoId: tx.data.daoId, id: tx.data.txId },
          });
          break;
        case TransType.EditCloseDate:
          msg = t("Notification.ModifyCloseTimeFailed");
          break;
        case TransType.ClaimToken:
          msg = t("Notification.ClaimTokenFailed");
          break
        default:
          msg = "failed";
      }
      showNotification(
        NotificationType.Fail,
        msg,
        `${exploreScan}tx/${tx.hash}`,
        undefined,
        undefined,
        { duration: 8000 }
      );
    } finally {
      closeNotification(tx.notifyId);
    }
  };

  const checker = () => {
    transactions.forEach((tx) => {
      if (tx.status === TransStatus.Default) {
        tx.status = TransStatus.Pending;
        checkStatus(tx);
        dispatch({ type: "UPDATE_TRANSACTION", payload: { ...tx } });
      }
    });
  };

  useEffect(() => {
    console.log("transaction changed!", transactions);
    const timer = setInterval(checker, 2000);
    return () => clearInterval(timer);
  }, [transactions]);

  return <></>;
});

export default TransactionCheck;
