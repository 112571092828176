import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { getShortDisplay } from "utils/publicJs";

const TokenOneAmount = 1;

const _accumulatedPrice = (
  x,
  TokenSupply,
  initialPrice,
  originalPrice,
  deci,
  curveRate
) => {
  const s = TokenSupply.multipliedBy(TokenOneAmount);
  const o = BigNumber(originalPrice)
    .multipliedBy(BigNumber(10 ** deci))
    .minus(initialPrice);

  return o
    .multipliedBy(x)
    .multipliedBy(
      x
        .exponentiatedBy(2)
        .dividedBy(3)
        .dividedBy(s)
        .minus(
          BigNumber(curveRate)
            .multipliedBy(x.exponentiatedBy(2))
            .dividedBy(300)
            .dividedBy(s)
        )
    )
    .dividedBy(s)
    .plus(
      o
        .multipliedBy(BigNumber(curveRate).multipliedBy(x.exponentiatedBy(2)))
        .dividedBy(200)
        .dividedBy(s)
    );
};

const getTarget = (
  selectToken,
  originalPrice,
  target,
  upper,
  discountTokenRate,
  discountRate,
  curveRate
) => {
  if (
    !originalPrice ||
    !target ||
    !discountRate ||
    !discountTokenRate ||
    !selectToken ||
    !selectToken.deci
  ) {
    return { softCap: 0, hardCap: undefined };
  }
  const selectTokenDeci = BigNumber(10 ** selectToken.deci);

  const tokenOriginalPrice =
    BigNumber(originalPrice).multipliedBy(selectTokenDeci);

  const tokenTarget = BigNumber(target);
  const TokenSupply = tokenTarget.multipliedBy(
    BigNumber(discountTokenRate).dividedBy(BigNumber(100))
  );

  const initialPrice = tokenOriginalPrice
    .multipliedBy(BigNumber(discountRate))
    .dividedBy(100);

  let totalPrice = _accumulatedPrice(
    TokenSupply,
    TokenSupply,
    initialPrice,
    originalPrice,
    selectToken.deci,
    curveRate
  ).minus(
    _accumulatedPrice(
      BigNumber(0),
      TokenSupply,
      initialPrice,
      originalPrice,
      selectToken.deci,
      curveRate
    )
  );

  totalPrice = totalPrice
    .plus(initialPrice.multipliedBy(TokenSupply))
    .dividedBy(TokenOneAmount);

  const leftSoftAmount = tokenTarget.minus(TokenSupply);
  const leftSoftPrice = leftSoftAmount.multipliedBy(tokenOriginalPrice);
  totalPrice = totalPrice.plus(leftSoftPrice);

  const soft = ethers.utils
    .formatUnits(ethers.BigNumber.from(totalPrice.toFixed(0)), selectToken.deci)
    .toString();
  let hard;

  if (upper) {
    const leftAmount = BigNumber(upper).minus(leftSoftAmount);
    const leftPrice = leftAmount.multipliedBy(tokenOriginalPrice);
    totalPrice = totalPrice.plus(leftPrice);
    hard = ethers.utils
      .formatUnits(
        ethers.BigNumber.from(totalPrice.toFixed(0)),
        selectToken.deci
      )
      .toString();
  }

  return { soft, hard };
};

export default getTarget;