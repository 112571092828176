import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useWeb3React } from "@web3-react/core";
import { useNetworkData } from "hooks/useNetwork";
import { useMemo } from "react";
import { DEV_ENDPOINT, PROD_ENDPOINT } from "utils/constant";
import { useAuthContext } from "./authProvider";
import { SupportedChainId } from "network";

const getUri = (chainId) => {
  return process.env.NODE_ENV === "production" ? PROD_ENDPOINT : DEV_ENDPOINT;
  // if (process.env.NODE_ENV === "production") {
  //   return PROD_ENDPOINT
  // } else {
  //   switch (chainId) {
  //     case SupportedChainId.POLYGON:
  //       return DEV_ENDPOINT
  //     case SupportedChainId.MAINNET:
  //     case SupportedChainId.BSC:
  //     case SupportedChainId.MOONBEAM:
  //     default:
  //       return PROD_ENDPOINT;
  //   }
  // }
};

const HttpProvider = ({ children }) => {
  const { chainId } = useWeb3React();

  const {
    state: { token },
  } = useAuthContext();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: getUri(chainId),
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: token,
        },
      };
    });
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [chainId, token]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default HttpProvider;
