import { memo } from "react";
import { Radio } from "antd";
import styled from "styled-components";
import { InputLabel } from "components/common/inputComp";
import { FlexRow } from "./style";

export const Circulation = {
  Yes: "circulation",
  No: "no-circulation",
};

const CirculateRatio = memo(({ t, value, onChange }) => {
  return (
    <>
      <InputLabel required title={t("Create.Circulation")} />

      <FlexRow>
        <Radio.Group onChange={onChange} value={value}>
          <RadioBox value={Circulation.Yes}>{t("Create.Circulable")}</RadioBox>
          <RadioBox value={Circulation.No}>{t("Create.NoCirculable")}</RadioBox>
        </Radio.Group>
      </FlexRow>
    </>
  );
});

export default CirculateRatio;

const RadioBox = styled(Radio)`
  color: #ffffff;
  margin-left: 40px;
  &:first-child {
    margin-left: 0;
  }
  .ant-radio-inner {
    border-color: rgba(255, 255, 255, 0.08);
    background-color: rgba(255, 255, 255, 0.06);
  }
`;
