import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PERIOD_NO } from "utils/constant";
import LikeSvg from "assets/images/Like-Selected.svg";

import LogoSVG from "assets/images/logo/defaultDaoLogo.svg";
import UsdtSVG from "img/token/usdt.svg";
import { TokenAvatar } from "./avatar";
import LftImg from "../../assets/images/left.png";
import RhtImg from "../../assets/images/right.png";

const DaoItem = memo((props) => {
  const navigator = useNavigate();
  const { data, networkSimple, gateway } = props;
  const {
    handle,
    name,
    logo,
    period,
    tav,
    et_holders,
    is_like,
    dao_version,
    dao_type,
    et_token_address,
    et_token_type,
    dao_address,
    gp_member_count,
  } = data;
  const { t } = useTranslation();

  const statusBox = useMemo(() => {
    switch (period) {
      case PERIOD_NO.RAISE:
        return (
          <DepositStatus className="font-bold" data-nosnippet>
            {t("Period.InDeposit")}
          </DepositStatus>
        );
      case PERIOD_NO.MANAGEMENT:
        return (
          <ManagementStatus className="font-bold" data-nosnippet>
            {t("Period.InManagement")}
          </ManagementStatus>
        );
      case PERIOD_NO.INRETURN :
        return (
          <ReturnStatus className="font-bold" data-nosnippet>
            {t("Period.InReturn")}
          </ReturnStatus>
        );
      case PERIOD_NO.REDEMPTION:
        return (
          <RedemptionStatus className="font-bold" data-nosnippet>
            {t("Period.InRedemption")}
          </RedemptionStatus>
        );
      default:
        return <></>;
    }
  }, [period]);

  const jumpDetail = useCallback(() => {
    if (dao_version === 1 || dao_version === 2) {
      window.open(
        `${window.location.protocol}//${window.location.host}/v${dao_version}/dao/${networkSimple}:${handle}/dashboard/info`,
        "_self"
      );
    } else {
      navigator(`/${networkSimple}/${handle}/dashboard/info`);
    }
  }, [networkSimple, handle, dao_version]);

  const SwitchBal = (num) =>{
    if(num){
      return Number(num).toFixed(2);
    }else{
      return 0
    }
  }

  const LOGO = useMemo(() => {
    if (gateway && logo) {
      return (
        <img src={gateway + logo} alt="" className="avatar" />
      );
    } else if (dao_address && (et_token_type === 3 || dao_type === 2)) {
      return <TokenAvatar address={dao_address} className="avatar" />;
    } else if (et_token_address && et_token_type !== 3) {
      return <TokenAvatar address={et_token_address} className="avatar" />;
    } else {
      return <img src={LogoSVG} alt="" className="avatar" />;
    }
  }, [gateway, logo, et_token_address])

  return (
    <DaoStyled onClick={jumpDetail}>
      <LftBg />
      <RhtBg />
      <div className="top">
        {LOGO}
        {is_like && <Like src={LikeSvg} alt="" />}
      </div>
      <DaoName data-nosnippet>{name}</DaoName>
      {dao_type === 1 && (
        <>
          {statusBox}
          {/* <HolderBox data-nosnippet>
            <span className="num" data-nosnippet>
              {et_holders}
            </span>
            <span className="ho">{t("Explore.Holders")}</span>
          </HolderBox> */}
        </>
      )}
      {dao_type === 2 && (
          <MultiSign className="font-bold" data-nosnippet>
            {t("Period.MultiSign")}
          </MultiSign>
      )}

      {/* {dao_type === 2 && (
          <>
            <HolderBox data-nosnippet>
            <span className="num" data-nosnippet>
              {gp_member_count}
            </span>
              <span className="ho">{t("Explore.Managers")}</span>
            </HolderBox>
          </>
      )}
      <LastLine>
        {
          !!SwitchBal(tav) &&
            <div>
              <img src={UsdtSVG} alt="" />
              <span>{SwitchBal(tav)}</span>
            </div>

        }
      </LastLine> */}


    </DaoStyled>
  );
});

export default DaoItem;

const DaoStyled = styled.li`
  width: 222px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(20px);
  margin-bottom: 32px;
  padding: 28px;
  box-sizing: border-box;
  position: relative;
  margin-right: 2.5%;
  &:nth-child(5n) {
    margin-right: 0;
  }
  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    &.avatar {
      object-fit: cover;
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const Like = styled.img`
  width: 24px !important;
`;

const DaoName = styled.div`
  font-size: 20px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  margin: 16px 0 8px;
  width: 100%;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Status = styled.div`
  background: #45BEFF;
  border-radius: 12px;
  padding: 2px 16px;
  display: inline-block;
  margin-bottom: 26px;
`;

const DepositStatus = styled(Status)`
  background: #1BB572;
`;
const ManagementStatus = styled(Status)`
  background: #328aff;
`;
const RedemptionStatus = styled(Status)`
  background: #d51172;
`;
const ReturnStatus = styled(Status)`
  background: #778192;
`;

const MultiSign = styled(Status)`
background: #FFB112;
`;

const HolderBox = styled.div`
  border-bottom: 1px solid rgba(238, 234, 243, 0.12);
  padding-bottom: 10px;
  margin-bottom: 15px;
  min-height: 21px;
  .num{
    width: 24px;
    height: 17px;
    font-size: 14px;
    font-family: "Rubik-Medium";
    font-weight: 500;
    color: #FFFFFF;
    line-height: 17px;
    padding-right: 5px;
  }
  .ho{
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    opacity: 0.5;
  }

`;
const LastLine = styled.div`
  min-height: 24px;
  &>div{
    display: flex;
    align-items: center;

  }
  img{
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  span{
    font-size: 20px;
    font-family: "Rubik-Regular";
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }
`

const LftBg = styled.div`
  width: 222px;
  height: 133px;
  background: url(${LftImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`;
const RhtBg = styled.div`
  width: 222px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -13px;
`;
