import React, { memo } from "react";
import styled from "styled-components";
import { Pagination } from "antd";

const PaginationBox = memo((props) => {
  const { ...rest } = props;
  // return <PaginationStyled {...rest} size="small" pageSizeOptions={[10, 50, 100]} />;
  return (
    <PaginationStyled
      {...rest}
      size="small"
      hideOnSinglePage
      showSizeChanger
      showQuickJumper
    />
  );
});

export default PaginationBox;

const PaginationStyled = styled(Pagination)`
  .ant-pagination-item a,
  .ant-pagination-item-link {
    color: #ffffff !important;
  }
  .ant-pagination-item.ant-pagination-item-active {
    width: 28px;
    height: 28px;
    min-width: 28px;
    border-radius: 28px;
    background-color: rgba(255, 255, 255, 0.13) !important;
    border: 1px solid rgba(255, 255, 255, 0.08);
    line-height: 28px;
  }
  .ant-pagination-options-quick-jumper {
    color: #ffffff;
    input {
      width: 48px;
      height: 24px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 12px !important;
      border: 1px solid rgba(255, 255, 255, 0.08);
      color: #ffffff;
      text-align: center;
      &:focus {
        outline: none;
        border: 1px solid rgba(255, 255, 255, 0.08);
        box-shadow: none;
      }
    }
  }
  .ant-select.ant-select-single.ant-select-show-arrow {
    height: 40px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    padding: 0 10px;
    .ant-select-selector {
      background: none;
      border: none;
      box-shadow: none !important;
      height: 100%;
      display: flex;
      color: #fff;
      align-items: center;
    }
    .ant-select-arrow {
      color: #fff;
    }
    .ant-select-selection-item {
      color: #fff !important;
    }
  }
  .ant-pagination-options-quick-jumper {
    margin-top: 7px;
  }

  .ant-select-dropdown {
    background-color: #1e2b3f;
    border: 1px solid rgba(255, 255, 255, 0.08);
    padding: 10px;
    .ant-select-item-option-content {
      color: #fff;
      text-align: center;
      font-family: "Poppins-Regular";
    }
    .ant-select-item-option-selected {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;
