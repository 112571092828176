import { memo } from "react";
import styled, { css } from "styled-components";

export const Button = memo((props) => {
  const { children, width, height, className, disabledBg, loading, onClick, ...rest } = props;
  const handleClick = (e) => {
    if (loading) {
      return;
    }
    onClick && onClick()
  }
  return (
    <ButtonStyled
      width={width}
      height={height}
      className={`font-medium ${className}`}
      {...rest}
      disabledBg={disabledBg}
      loading={loading}
      onClick={handleClick}
    >
      <div className="mask" />
      {children}
    </ButtonStyled>
  );
});

export default Button;

export const ButtonGroup = memo((props) => {
  const { children, gap, ...rest } = props;
  return (
    <ButtonGroupStyled gap={gap} {...rest}>
      {children}
    </ButtonGroupStyled>
  );
});

const ButtonGroupStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 36px 0 20px 0; */
  button {
    &:last-child {
      margin-left: ${(props) => props.gap || "20px"};
    }
  }
`;

const DefaultStyled = css`
  background: rgba(50, 108, 255, 0.28);
  &:hover {
    background: #326cff;
  }
`;

const PrimaryStyled = css`
  background: linear-gradient(311deg, #61e1ff 0%, #326cff 100%);
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  &:hover {
    .mask {
      display: block;
    }
    box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.5);
    line-height: ${(props) =>
      props.height ? `${props.height + "px"}` : "34px"};
  }
`;

const ButtonStyled = styled.button`
  width: ${(props) => (props.width ? `${props.width + "px"}` : "114px")};
  height: ${(props) => (props.height ? `${props.height + "px"}` : "36px")};
  line-height: ${(props) => (props.height ? `${props.height + "px"}` : "36px")};
  box-sizing: border-box;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({loading}) => (loading ? "wait" : "pointer")};
  position: relative;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  border: none;
  overflow: hidden;
  padding: 0 20px;
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .mask {
    background: linear-gradient(
      326deg,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0.02) 42%,
      #ffffff 100%
    );
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
  }
  ${(props) => (props.primary ? PrimaryStyled : DefaultStyled)}
`;
