import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";

const useSigner = () => {
  const { provider, account } = useWeb3React();

  return useMemo(() => {
    return provider && account && provider.getSigner(account);
  }, [provider, account]);
};

export default useSigner;
