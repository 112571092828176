import React, { memo } from "react";
import DefiProvider from "components/provider/defiProvider";
import Container from "./container";

const DEFI = memo(() => {
  return (
    <DefiProvider>
      <Container />
    </DefiProvider>
  );
});
export default DEFI;