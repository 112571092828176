import { gql } from "@apollo/client";

/******** QUERY ********/
export const QueryAllDaos = gql`
  query MyQuery(
    $page: Int!
    $size: Int!
    $chainId: Int!
    $sort: String
    $active: Boolean!
  ) {
    allDaoList(
      page: { page: $page, size: $size, order: "desc", sort_field: $sort }
      req: { chain_id: $chainId, only_active: $active }
    ) {
      page
      size
      total
      data {
        dao_id
        desc
        handle
        et_holders
        is_like
        tav
        period
        name
        logo
        dao_version
        dao_type
        et_token_address
        et_token_type
        dao_address
        gp_member_count
      }
    }
  }
`;

export const QueryMyDaos = gql`
  query MyQuery($page: Int!, $size: Int!, $chainId: Int!, $sort: String) {
    myDaoList(
      page: { page: $page, size: $size, order: "desc", sort_field: $sort }
      req: { chain_id: $chainId }
    ) {
      page
      size
      total
      data {
        dao_id
        desc
        handle
        et_holders
        tav
        period
        name
        logo
        dao_version
        dao_type
        et_token_address
        et_token_type
        dao_address
        gp_member_count
      }
    }
  }
`;

export const QueryLikeDaos = gql`
  query MyQuery($page: Int!, $size: Int!, $chainId: Int!, $sort: String) {
    likeDaoList(
      page: { page: $page, size: $size, order: "desc", sort_field: $sort }
      req: { chain_id: $chainId }
    ) {
      page
      size
      total
      data {
        dao_id
        desc
        handle
        et_holders
        tav
        period
        name
        logo
        dao_version
        dao_type
        et_token_address
        et_token_type
        dao_address
        gp_member_count
      }
    }
  }
`;

export const SearchDaos = gql`
  query MyQuery($page: Int!, $size: Int!, $chainId: Int!, $keyword: String!) {
    searchDao(
      page: { page: $page, size: $size }
      req: { chain_id: $chainId, search_txt: $keyword }
    ) {
      page
      size
      total
      data {
        dao_id
        desc
        handle
        et_holders
        is_like
        tav
        period
        name
        logo
        dao_version
        dao_type
        et_token_address
        et_token_type
        dao_address
        gp_member_count
      }
    }
  }
`;

export const QueryMyTokens = gql`
  query MyQuery($page: Int!, $size: Int!, $chainId: Int!) {
    myTokenList(
      page: { page: $page, size: $size, order: "desc", sort_field: "create_at" }
      req: { chain_id: $chainId }
    ) {
      data {
        address
        dao_id
        symbol
        dao_name
        dao_logo
        dao_handle
        dao_version
        et_type
        et_logo
        et_data
        name
        dao_address
        et_could_transfer
        ventures_addr
      }
    }
  }
`;

export const QueryTokens = gql`
  query MyQuery($page: Int!, $size: Int!, $chainId: Int!) {
    tokenList(page: { page: $page, size: $size }, req: { chain_id: $chainId }) {
      data {
        address
        symbol
        dao_id
        name
        dao_version
        et_type
        et_logo
        et_data
      }
    }
  }
`;

export const QuerySearchTokens = gql`
  query MyQuery($page: Int!, $size: Int!, $chainId: Int!, $search_txt: String) {
    tokenList(
      page: { page: $page, size: $size }
      req: { chain_id: $chainId, search_txt: $search_txt }
    ) {
      data {
        address
        symbol
        dao_id
        dao_version
      }
    }
  }
`;

export const AddMyToken = gql`
  mutation MyMutation(
    $chainId: Int!
    $daoId: Int!
    $address: String!
    $symbol: String!
    $name: String!
  ) {
    addMyToken(
      req: {
        chain_id: $chainId
        dao_id: $daoId
        address: $address
        symbol: $symbol
        name: $name
        dao_version: 3
      }
    )
  }
`;
