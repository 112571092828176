import styled from "styled-components";
import UpgradeIcon from "assets/images/Upgrade.svg";

const TabsBox = styled.ul`
  display: flex;
  padding: 0 28px;
  li {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 25px;
    min-width: 48px;
    text-align: center;
    margin-right: 60px;
    cursor: pointer;
    align-items: center;
    .content {
      position: relative;
      img {
        position: absolute;
        right: -30px;
        top: 2px;
      }
    }
    .line {
      background: transparent;
      border-radius: 3px;
      width: 40px;
      margin: 5px auto;
      height: 3px;
      margin-bottom: 0;
    }
    &.active {
      color: rgba(255, 255, 255, 1);
      .line {
        background: linear-gradient(123deg, #61e1ff 0%, #326cff 100%);
        border-radius: 30px;
      }
    }
  }
`;

export default function Tabs(props){
    const {navList,current,handleCurrent, showUpgrade} = props;

    const handleSelected = (num) =>{
        handleCurrent(num)
    }

    return (
      <TabsBox>
        {navList.map((item, index) => (
          <li
            key={`nav_${index}`}
            className={current === index ? "active" : ""}
            onClick={() => handleSelected(index)}
          >
            <div className="content">
              {item} {showUpgrade === index && <img src={UpgradeIcon} alt="" />}
            </div>
            <div className="line" />
          </li>
        ))}
      </TabsBox>
    );
}