import styled from "styled-components";
import RectImg from "img/Rectangle.png";
import SupplyModal from "./modals/supplyModal";
import { memo, useEffect, useState, useMemo, useCallback } from "react";
import { useDaoContext } from "views/dao/provider";
import { VAULT_MANAGER } from "utils/constant";
import { useDefiContext } from "components/provider/defiProvider";
import useVaultTokens from "hooks/useVaultTokens";
import { getShortDisplay } from "utils/publicJs";
import LoadingBox from "components/common/loading";
import okIcon from "img/voteNV/Confirm.png";

import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import { useTranslation } from "react-i18next";
import Button, { ButtonGroup } from "components/common/button";

const AssetsToSupply = memo((props) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const {
    state: { daoId, componentAddressMap },
  } = useDaoContext();
  const {
    state: { reserves, reqState },
  } = useDefiContext();

  const [updateFlag, setUpdateFlag] = useState(0);
  const { tokens: vaultTokens, loading } = useVaultTokens(
    componentAddressMap.get(VAULT_MANAGER),
    true,
    updateFlag
  );

  const [list, setList] = useState([]);
  const [openReserve, setOpenReserve] = useState({});

  const balanceMap = useMemo(() => {
    if (!vaultTokens.length) {
      return {};
    }
    const map = {};
    vaultTokens.forEach((token) => {
      map[token.address.toLowerCase()] = {
        balance: Number(token.balance),
        balanceDisplay: token.balanceDisplay,
      };
    });
    return map;
  }, [vaultTokens]);

  const closeModal = () => {
    setShowModal(false);
    setOpenReserve({});
  };

  const openSupply = useCallback((reserve) => {
    if (!reserve.balance) {
      return;
    }
    setOpenReserve({ ...reserve });
    setShowModal(true);
  }, []);

  useEffect(async () => {
    const lst = [];
    reserves.forEach((r) => {
      const walletBalance = balanceMap[r.underlyingAsset];
      if (walletBalance) {
        lst.push({
          ...r,
          ...walletBalance,
          supplyAPYDisplay: getShortDisplay(r.supplyAPY * 100, 2),
        });
      }
    });
    setList(lst);
  }, [reserves, balanceMap]);

  useSubcribe(TransactionEvent.Defi, (_, { daoId: _daoId }) => {
    if (daoId === _daoId) {
      setUpdateFlag(updateFlag + 1);
    }
  });

  if (loading || reqState === 2) {
    return <LoadingBox loading={true} />
  }

  return (
    <>
      {showModal && (
        <SupplyModal
          show={showModal}
          reserve={openReserve}
          closeModal={closeModal}
        />
      )}
      <TableBox>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>{t("defi.assets.Asset")}</th>
              <th>{t("defi.assets.balance")}</th>
              <th style={{ textAlign: "center" }}>{t("defi.assets.APY")}</th>
              <th style={{ textAlign: "center" }}>
                {t("defi.assets.collateral")}
              </th>
              <th style={{ textAlign: "center" }}>{t("defi.assets.Action")}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((r, i) => (
              <tr key={i}>
                <td className="info">
                  <img className="logo" src={r.logo} alt="" />
                  <span>{r.symbol}</span>
                </td>
                <td className="number">
                  {r.balanceDisplay}
                  {/* <div className="tips font-medium">$8,981.61</div> */}
                </td>
                <td className="number" style={{ textAlign: "center" }}>
                  {r.supplyAPYDisplay} %
                </td>
                <td style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "14px" }}
                    src={r.usageAsCollateralEnabled ? okIcon : RectImg}
                    alt=""
                  />
                  {/* <img src={SelectedImg} alt="" /> */}
                </td>
                <td style={{ textAlign: "center" }}>
                  <ButtonGroup>
                    <Button primary onClick={() => openSupply(r)} width="112">
                      {t("defi.assets.Supply")}
                    </Button>
                    <Button className="borderLine" width="112">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://app.aave.com/reserve-overview/?underlyingAsset=${r.underlyingAsset}`}
                      >
                        {t("defi.assets.Details")}
                      </a>
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableBox>
    </>
  );
});

export default AssetsToSupply;

const TableBox = styled.div`
  position: relative;
  padding-bottom: 30px;

  table {
    width: 100%;
  }
  th {
    height: 65px;
    color: #328aff;
    font-size: 16px;
    white-space: nowrap;
    text-align: left;
    &:first-child {
      padding-left: 30px;
    }
  }
  td {
    text-align: left;
    white-space: nowrap;
    height: 65px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;

    &:first-child {
      padding-left: 30px;
    }
    &.info {
      text-align: left;
      font-size: 16px;
      display: flex;
      align-items: center;
      line-height: 22px;
    }
    .logo {
      margin-right: 13px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    .tips {
      font-size: 14px;
      font-weight: 500;
      color: #959bb4;
      line-height: 19px;
    }
    &.number {
      font-family: "Rubik-SemiBold";
      font-size: 18px;
      font-weight: 600;
    }
  }
  tbody tr:nth-child(2n + 1) {
    background: rgba(255, 255, 255, 0.04);
  }

  @media (max-width: 1440px) {
    th {
      height: 45px;
    }
  }
`;
