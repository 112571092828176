import styled from "styled-components";

export default function AllTokenAvatar() {
    return <AllAvatar>All</AllAvatar>;
}

const AllAvatar = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: linear-gradient(311deg, #61e1ff 0%, #326cff 100%);
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  margin-right: 12px;
`;