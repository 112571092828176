import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ethers } from "ethers";

import { useDaoContext } from "views/dao/provider";
import { useDaoOrgContract } from "hooks/useContract";
import useManageFeeBalance from "hooks/useManageFee";
import useSigner from "hooks/useSigner";

import { DAO_ORG } from "utils/constant";
import { VoteTxStatus } from "./loading";
import api from "../../../api";
import CheckInput from "components/common/checkInput";
import { useAppContext } from "components/provider/appProvider";

import { useWeb3React } from "@web3-react/core";

import { useParams } from "react-router-dom";
import { VoteItem, Label } from "./components/style";
import { InputBackground } from "assets/styles/common";
import Max from "components/common/max";
import TokenDefault from "img/defaultToken.svg";

export default forwardRef(function Fee(props, ref) {

  const { t } = useTranslation();
  const { chainId } = useWeb3React();

  const {
    state: { exploreScan, tokens },
    dispatch,
  } = useAppContext();
  const { state: daoState } = useDaoContext();
  const { componentAddressMap, daoId, manageFeeToken } = daoState;

  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const manageFee = useManageFeeBalance();
  const signer = useSigner();

  const [status, setStatus] = useState(VoteTxStatus.Default);
  const [txHash, setTxHash] = useState();

  const [transferAddress, setTransferAddress] = useState();
  const [addressInvalidMsg, setAddressInvalidMsg] = useState();

  const [tokenAmount, setTokenAmount] = useState();
  const [newVoteId, setVoteId] = useState();
  const [duration, setDuration] = useState();

  const maxValue = useMemo(() => {
    return {
      value: manageFee.balance,
      display: manageFee.balanceDisplay,
    };
  }, [manageFee]);

  const manageFeeTokenLogo = useMemo(() => {
    if (!manageFeeToken || !tokens) {
      return TokenDefault;
    }
    const img = tokens.find(
      (item) =>
        item.address.toLowerCase() === manageFeeToken.address.toLowerCase()
    );
    return img ? img.logoURI : TokenDefault;
  }, [manageFeeToken, tokens]);

  const onChangeAddress = useCallback((e) => {
    const v = e.target.value;
    setTransferAddress(v);
    if (!ethers.utils.isAddress(v)) {
      setAddressInvalidMsg(t("Msg.InvalidAddress"));
    } else {
      setAddressInvalidMsg(null);
    }
  }, []);

  const handleTokenAmount = async (e) => {
    setTokenAmount(Number(e.target.value));
  };

  const setMax = useCallback(() => {
    setTokenAmount(Number(manageFee.balance));
  }, [manageFee]);

  const checkValid = () => {
    if (!transferAddress || !ethers.utils.isAddress(transferAddress)) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: "invalid address", closable: true },
      });
      return;
    }
    if (tokenAmount > Number(manageFee.balance) || tokenAmount <= 0) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: "invalid amount", closable: true },
      });
      return;
    }
    return true;
  };

  useImperativeHandle(ref, () => ({
    name: "fee",
    checkValid,
    getVoteBytes() {
      return api.vote.newTransferManageFeeVote({
        toAddress: transferAddress,
        tokenAddress: manageFeeToken.address,
        tokenAmount: ethers.utils.parseUnits(
          String(tokenAmount),
          manageFeeToken.deci
        ),
      });
    },
  }));

  return (
    <FeeBox>
      <Tips>
        {/* <img src={TipsImg} alt="" /> */}
        <div className="tips">{t("Vote.ManageFeeTip")}</div>
      </Tips>
      <VoteItem>
        <Label>{t("Vote.RecipientAddress")}</Label>
        <CheckInput
          autoFocus
          value={transferAddress}
          onChange={onChangeAddress}
          errorMsg={addressInvalidMsg}
        />
      </VoteItem>
      <VoteItem>
        <Label>{t("Vote.Amount")}</Label>
        <AmountBox>
          <input
            type="number"
            onWheel={() => document.activeElement.blur()}
            value={tokenAmount}
            onChange={handleTokenAmount}
            autoComplete="off"
          />
          <div className="right">
            <div className="tokenBox">
              <img src={manageFeeTokenLogo} alt="" />
              <span className="symbol">{manageFeeToken?.symbol}</span>
            </div>
            <div className="balanceBox">
              <span className="balance">
                {t("Balance")} : {maxValue.display}
              </span>
              <Max t={t} onMax={setMax} />
            </div>
          </div>
        </AmountBox>
      </VoteItem>
    </FeeBox>
  );
});

const Tips = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  padding: 11px;
  margin-top: -20px;
  margin-bottom: 28px;
  .tips {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-left: 5px;
    opacity: 0.5;
  }
`;
const FeeBox = styled.div`
  dt {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 19px;
    margin-bottom: 10px;
  }
  dd {
    position: relative;
  }
  dl {
    margin-bottom: 20px;
  }
`;

const AmountBox = styled.div`
  ${InputBackground};
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  min-height: 44px;
  input {
    flex-grow: 1;
    border: 0;
    background-color: transparent;
    color: #ffffff;
    font-family: "Rubik-Medium";
    font-size: 20px;
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .tokenBox {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      img {
        width: 24px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .symbol {
        line-height: 25px;
        font-size: 16px;
      }
    }

    .balanceBox {
      line-height: 20px;
      display: flex;
      align-items: center;
      .balance {
        font-size: 12px;
        font-weight: 400;
        color: #94949a;
        line-height: 19px;
        margin-right: 6px;
      }
    }
  }
`;
