import { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { InputLabel } from "components/common/inputComp";
import { useTranslation } from "react-i18next";
import { Radio, Input } from "antd";
import { useCSVReader } from "react-papaparse";
import DateBox from "components/common/closeDate";
import { ethers } from "ethers";
import TokenFundraising, { ReleaseType } from "./token";
import NFTFundraising from "./nft";
import { FlexRow, Block } from "./style";
import { LOCAL_KEY as BASIC_LOCAL_KEY } from "../basicInfo";
import Button from "components/common/button";
import moment from "moment";

const KEY = "fundraising";
export const LOCAL_KEY = `CreateDao_${KEY}`;

export const FundraiseType = {
  Token: "token",
  NFT: "NFT",
};

const Whitelist = {
  notUse: 0,
  use: 1,
}

const Fundraising = memo(() => {
  const { t } = useTranslation();
  const [fundraiseType, setFundraiseType] = useState();

  const { CSVReader } = useCSVReader();
  const [addressInput, setAddressInput] = useState("");
  const [showWhite, setShowWhite] = useState(Whitelist.notUse);
  const [raiseTime, setRaiseTime] = useState();
  const [tokenData, setTokenData] = useState()
  const [nftData, setNftData] = useState();
  const [init, setInit] = useState(true);
  const [vestStartTime, setVestStartTime] = useState();

  const savePage = () => {
    const localData = {
      fundraiseType,
      tokenData,
      nftData,
      raiseTime,
      showWhite,
      addressInput,
    };
    console.log("save - localData:", localData);
    sessionStorage.setItem(LOCAL_KEY, JSON.stringify(localData))
  };

  useEffect(() => {
    if (init) {
      return;
    }
    savePage();
  }, [tokenData, nftData, showWhite, addressInput, raiseTime, fundraiseType]);

  useEffect(() => {
    if (fundraiseType === FundraiseType.NFT) {
      setVestStartTime()
    } else {
      const tp = tokenData?.releaseType;
      const d = tokenData?.releaseData;
      if (tp === ReleaseType.OneTime) {
        setVestStartTime(d)
      } else if (tp === ReleaseType.Liner) {
        setVestStartTime(d?.startDate);
      } else {
        setVestStartTime();
      }
    }
  }, [tokenData, fundraiseType]);

  const onChangeSelectWhite = (e) => {
    setShowWhite(e.target.value);
    if (!e.target.value) {
      setAddressInput("")
    }
  };

  const handleCSVfile = (data) => {
    const addressList = [];
    data.forEach((address) => {
      if (ethers.utils.isAddress(address[0])) addressList.push(address[0]);
    });

    const arr = Array.from(new Set(addressList));
    setAddressInput(arr.join("\n"));
  };

  useEffect(() => {
    let initEquity;
    try {
      const data = JSON.parse(sessionStorage.getItem(BASIC_LOCAL_KEY) || "");
      if (data.name) {
        initEquity = { name: data.name };
      }
    } catch (error) { }
    
    try {
      const data = JSON.parse(sessionStorage.getItem(LOCAL_KEY) || "");
      if (data.fundraiseType) {
        setFundraiseType(data.fundraiseType);
      } else {
        setFundraiseType(FundraiseType.Token);
      }
      if (data.tokenData) {
        let td = data.tokenData;
        if (
          !data.tokenData.equityToken ||
          Object.keys(data.tokenData.equityToken).length === 0
        ) {
          td.equityToken = initEquity;
        }
        setTokenData(td);
      }
      data.nftData && setNftData(data.nftData);
      setShowWhite(data.showWhite);
      data.raiseTime && setRaiseTime(data.raiseTime);
      data.addressInput && setAddressInput(data.addressInput);
    } catch (error) {
      console.error("parse error", error)
      setFundraiseType(FundraiseType.Token);
    } finally {
      setInit(false);
    }
  }, []);

  const disabledDate = (current) => {
    if (vestStartTime) {
      return (
        (current && current < moment().startOf("day")) ||
        (current && current > moment(vestStartTime).endOf("day"))
      );
    }
    return current && current < moment().startOf("day");
  };

  return (
    <>
      <InputLabel title={t("Create.TypesFundraising")} />
      <TypeButtons>
        <TypeButton
          selected={fundraiseType === FundraiseType.Token}
          onClick={() => setFundraiseType(FundraiseType.Token)}
        >
          {t("Create.TypeToken")}
        </TypeButton>
        <TypeButton
          selected={fundraiseType === FundraiseType.NFT}
          onClick={() => setFundraiseType(FundraiseType.NFT)}
        >
          {t("Create.TypeNFT")}
        </TypeButton>
      </TypeButtons>
      {fundraiseType === FundraiseType.NFT ? (
        <NFTFundraising data={nftData} savePage={setNftData} />
      ) : (
          <TokenFundraising data={tokenData} savePage={setTokenData} raiseTime={raiseTime} />
      )}
      <FlexRow>
        <Block>
          <InputLabel required title={t("Create.RaiseTime")} />
          <DateBox
            targetTime={raiseTime}
            updateTargetTime={setRaiseTime}
            disabledDate={disabledDate}
          />
        </Block>
        <Block />
        <Block />
      </FlexRow>
      <FlexRow>
        <RadioGroup onChange={(e) => onChangeSelectWhite(e)} value={showWhite}>
          <div>
            <RadioBox value={Whitelist.notUse}>
              {t("Create.WhitelistOption")}
            </RadioBox>
          </div>
          <Top>
            <div>
              <RadioBox value={Whitelist.use}>{t("Create.AddWhite")}</RadioBox>
            </div>
            {!!showWhite && (
              <BtmBgBox>
                {/*<InputLabel title={t("Create.AddWhite")} />*/}
                <WhiteInput
                  rows={6}
                  name="Address"
                  value={addressInput}
                  placeholder={t("Create.Enter")}
                  onChange={(e) => setAddressInput(e.target.value)}
                  onBlur={savePage}
                />

                <CSVReader
                  onUploadAccepted={(results) => {
                    handleCSVfile(results.data);
                  }}
                >
                  {({ getRootProps }) => (
                    <>
                      <ChooseBox {...getRootProps()}>
                        <Button primary width={129}>
                          {t("Create.ChooseFile")}
                        </Button>
                        <FileTip>{t("Create.whilelistFileTip")}</FileTip>
                      </ChooseBox>
                    </>
                  )}
                </CSVReader>
              </BtmBgBox>
            )}
          </Top>
        </RadioGroup>
      </FlexRow>
    </>
  );
});

export default Fundraising;

const TypeButtons = styled.div`
  margin-top: 11px;
  display: flex;
  position: relative;
`;

const SelectButton = css`
  background: linear-gradient(311deg, #61e1ff 0%, #326cff 100%);
  box-shadow: 0px 8px 20px 0px rgba(71, 107, 249, 0.04);
`;
const DefaultButton = css`
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(24px);
`;

const TypeButton = styled.div`
  width: 134px;
  height: 36px;
  line-height: 36px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  ${(props) => (props.selected ? SelectButton : DefaultButton)}
  &:first-child {
    margin-right: 27px;
  }
`;

const ChooseBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;


const FileTip = styled.span`
  margin-left: 10px;
  line-height: 35px;
  flex: 1;
  color: #ffffff!important;
  font-size: 14px;
`;

const WhiteInput = styled(Input.TextArea)`
  width: 406px;
  height: 150px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 4px 0px rgba(16, 22, 75, 0.1);
  border-radius: 8px;
  border: none;
  padding: 11px 14px;
  color: #fff;
`;

const BtmBgBox = styled("div")`
  padding: 20px 0 0 20px;
`;
const Top = styled("div")`
  margin-top: 20px;
`;
const RadioBox = styled(Radio)`
  color: #ffffff;
  .ant-radio-inner {
    border-color: rgba(255, 255, 255, 0.08);
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const RadioGroup = styled(Radio.Group)`
  &.ant-radio-group span {
    color: "#fff" !important;
  }
`;