import ABI from "abi/web3Abi/VaultManager.json"
import { ethers } from "ethers";
import { useState } from "react";

export default function useMulticallTxs(multicall, vaultAddress, account) {
  const [loading, setLoading] = useState(false);

  const getTxDatas = async (txIds) => {
    if (!txIds || txIds.length === 0) {
      return;
    }
    setLoading(true);
    try {
      const calls = [];
      txIds.forEach((txId) => {
        calls.push({
          reference: `${txId}_detail`,
          methodName: "proposals",
          methodParameters: [txId],
        });
        if (account) {
          calls.push({
            reference: `${txId}_senderState`,
            methodName: "senderConfirm",
            methodParameters: [txId, account],
          });
        }
      });
      const result = await multicall.call([
        {
          reference: "vault",
          contractAddress: vaultAddress,
          abi: ABI,
          calls,
        },
      ]);
      const r = {};

      result.results.vault.callsReturnContext.forEach((d) => {
        r[d.reference] = d.returnValues;
      });
      
      console.log("r", r);

      const idDataMap = {};
      txIds.forEach((txId) => {
        const detail = r[`${txId}_detail`];
        const senderState = r[`${txId}_senderState`];
        if (detail) {
          idDataMap[txId] = {
            state: detail[0] === 1 ? 2 : detail[1], // 0-default,1-execute success,2-execute fail
            id: txId,
            agree_count: ethers.BigNumber.from(detail[4]).toNumber(),
            oppose_count: ethers.BigNumber.from(detail[5]).toNumber(),
            source: detail[6],
            creator: detail[8],
            data: detail[9],
            senderState: senderState && senderState[0],
          };
        }
      });
      return idDataMap;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return [getTxDatas, loading];
}
