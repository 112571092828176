import styled from "styled-components";

export const ReceiveBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(16, 22, 75, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  position: relative;
  height: 44px;
  .box {
    display: flex;
    align-items: center;
  }
  .lft {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-right: 10px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #10164b;
    line-height: 24px;
  }
  .Total {
    font-size: 14px;
    font-weight: 500;
    color: #969cb4;
    line-height: 24px;
  }
  .tips {
    font-size: 14px;
    font-family: AvenirNext-Regular, AvenirNext;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 19px;
    padding: 10px 0;
  }
`;
export const DropBox = styled.ul`
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 9999;
  padding: 10px 0;
  background: #ffffff;
  border-radius: 8px 8px 14px 14px;
  border: 1px solid #edeef3;
  width: 100%;
  li {
    padding: 6px 23px;
    cursor: pointer;
    &:hover {
      background: #fbfbfa;
    }
  }
`;
export const DropRC = styled(DropBox)`
  top: 80px;
  li {
    display: flex;
    margin-bottom: 10px;
  }
`;
