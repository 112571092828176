import vote from './vote';
import vault from './vault';
import org from './org';
import dao from './dao';
import erc20 from './erc20';
import daoOrg from './daoOrg'
import venture from './venture'
import aave from './aave'

export default {
    vote,
    vault,
    org,
    dao,
    erc20,
    daoOrg,
    venture,
    aave,
};
