import { useWeb3React } from "@web3-react/core";
import React, { useReducer, createContext, useContext } from "react";
import { useEffect } from "react";
import { SWAP_CENTER } from "utils/constant";

const SwapContext = createContext();

export const Default_Tolerance = 0.5;

const reducer = (state, action) => {
  switch (action.type) {
    case "SWITCH_CENTER":
      return { ...state, center: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    
    case "CLEAR_TX":
      localStorage.removeItem(`SUBDAO_SWAP_${action.payload}`)
      return { ...state, transactions: {} };
    case "SET_CURRTX":
      return { ...state, currentTx: action.payload };

    case "SET_TXS":
      return { ...state, transactions: action.payload };
    case "UPDATE_TX":
      const updateTx = action.payload;
      const updateTransactions = { ...state.transactions, [updateTx.hash]: updateTx.data };
      setTransactionsToLocal(updateTx.chainId, updateTransactions)
      let newCurrentTx
      console.log("(state.currentTx: ", state.currentTx)
      if (state.currentTx && state.currentTx.hash === updateTx.hash) {
        newCurrentTx = { ...state.currentTx, status: updateTx.data.status };
      }
      return {...state, transactions: updateTransactions, currentTx: newCurrentTx}

    case "ADD_NEW_TX":
      const newTx = action.payload;
      const newTransactions = { ...state.transactions };
      newTransactions[newTx.hash] = newTx.data;
      setTransactionsToLocal(newTx.chainId, newTransactions);
      return { ...state, transactions: newTransactions };
    case "SET_Tolerance":
      const val =
        action.payload.val === undefined
          ? Default_Tolerance
          : action.payload?.val;
      
        localStorage.setItem(
        `SUBDAO_SWAP_${action.payload?.chainId}_tolerance`,
        val
        );
      return { ...state, tolerance: val };

    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};

const INIT_STATE = {
  center: SWAP_CENTER.INDEX,
  transactions: {},
};

const SwapProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const { chainId } = useWeb3React();

  useEffect(() => {
    if (chainId) {
      const data = getTransactionsFromLocal(chainId);
      dispatch({ type: "SET_TXS", payload: data });
      const localTolerance = localStorage.getItem(`SUBDAO_SWAP_${chainId}_tolerance`);
      try {
        dispatch({
          type: "SET_Tolerance",
          payload: {
            chainId,
            val: Number(localTolerance) || Default_Tolerance,
          },
        });
      } catch (error) {
        dispatch({
          type: "SET_Tolerance",
          payload: {
            chainId,
            val: Default_Tolerance,
          },
        });
      }
    }
  }, [chainId]);

  return (
    <SwapContext.Provider value={{ state, dispatchEvent: dispatch }}>
      {props.children}
    </SwapContext.Provider>
  );
};

export const useSwapContext = () => ({ ...useContext(SwapContext) });

export default SwapProvider;

function getTransactionsFromLocal(chainId) {
  const trans = localStorage.getItem(`SUBDAO_SWAP_${chainId}`);
  try {
    const data = JSON.parse(trans);
    if (data) {
      return data;
    }
  } catch (error) {}
  return {};
}

function setTransactionsToLocal(chainId, data) {
  try {
    const datastr = JSON.stringify(data);
    localStorage.setItem(`SUBDAO_SWAP_${chainId}`, datastr);
  } catch (error) {}
}

