// import millify from "millify";

import {toChecksumAddress} from "ethereumjs-util";

const formatResult =  (result) =>{
    let str;
    if (result && result.output) {
        str = result.output.toHuman();
    }
    return str;
}

const dateFormat = (dateTime) => {
    const t = new Date(dateTime);
    const format = 'Y-m-d h:i:s';
    const year = t.getFullYear();
    const month = t.getMonth() + 1;
    const day = t.getDate();
    const hours = t.getHours();
    const minutes = t.getMinutes();
    const seconds = t.getSeconds();
    const hash = {
        'Y': year,
        'm': month>=10?month:`0${month}`,
        'd': day>=10?day:`0${day}`,
        'h': hours>=10?hours:`0${hours}`,
        'i': minutes>=10?minutes:`0${minutes}`,
        's': seconds>=10?seconds:`0${seconds}`
    };
    return format.replace(/\w/g, o => {
        return hash[o]
    })
}

const formatvoteDateTime = (dateTime,endTime) =>{
    dateTime = parseInt(dateTime.replace(/,/g, ""));
    endTime = parseInt(endTime.replace(/,/g, ""));

    return dateFormat(dateTime + endTime)
}


const AddresstoShow = (addressBf) => {
    if (!addressBf) return "..."
    const address = toChecksumAddress(addressBf);

    let frontStr = address.substring(0, 6);

    let afterStr = address.substring(address.length - 4, address.length);

    return `${frontStr}...${afterStr}`

}

const NumbertoShow = (num,decimals,address) =>{
    if(address === window.mainAddress.native_token){
        let numInt = parseInt(num.replace(/,/g,""));
        let numAfter = numInt / Math.pow(10,decimals);
        return numAfter.toFixed(4);
    }else{
        return num;
    }

    // return millify(numAfter,{
    //     precision: 4,
    // })
}

export const getShortDisplay = (v, num)=>{
    if (!v) return v
    const tp = typeof v
    if (tp === 'number') v = String(v)
    const arr = v.split('.')
    let res = arr[0]
    if (arr[1]) {
        res += `.${arr[1].slice(0, num||6)}`
    }
    return res
}


export const copyData = (value) => {
    const cInput = document.createElement("input");
    cInput.setAttribute("id", "copyLayer");
    cInput.value = value;
    document.body.appendChild(cInput);
    cInput.select();
    document.execCommand("Copy");
    cInput.parentNode.removeChild(cInput);
}

function toThousands(str) {
    let newStr = "";
    let count = 0;
    if (str.indexOf(".") === -1) {
        for (let i = str.length - 1; i >= 0; i--) {
            if (count % 3 === 0 && count !== 0) {
                newStr = str.charAt(i) + "," + newStr;
            } else {
                newStr = str.charAt(i) + newStr;
            }
            count++;
        }
        str = newStr ;
        return str;
    }
    else {
        for (let i = str.indexOf(".") - 1; i >= 0; i--) {
            if (count % 3 === 0 && count !== 0) {
                newStr = str.charAt(i) + "," + newStr;
            } else {
                newStr = str.charAt(i) + newStr;
            }
            count++;
        }
        str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
        return str;
    }
}


export const formatBalanceDisplay = (balance) => {
    if (!balance) {
      return "0.0"
    }
    if (balance < 0.0000001) {
      return "< 0.0000001"
    }
    return getShortDisplay(balance, 7)
}
  
function encodeUrl(url) {
  return encodeURIComponent(url)
    .replace(/!/g, "%21")
    .replace(/'/g, "%27")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\*/g, "%2A")
    .replace(/%20/g, "+");
}

export default {
  formatResult,
  dateFormat,
  formatvoteDateTime,
  AddresstoShow,
  NumbertoShow,
  encodeUrl,
};
