import React, { memo, useMemo, useEffect, useState } from "react";
import { useCallback } from "react";
import styled from "styled-components";
import SideNavLayout from "./layout";
import BasicInfoStep, { LOCAL_KEY as BasicKey } from "./basicInfo";
import AddManager, { LOCAL_KEY as AddManagerKey } from "./addManager";
import Fundraising from "./fundraise/index";
import ChooseType, { DaoType } from "./chooseType";
import Preivew from "./preivew/index";

import { useWeb3React } from "@web3-react/core";
import { useCreateContext } from "./provider";



const STEPS = [
  {
    name: "Create.ChooseType",
    key: "choose_type",
  },
  {
    name: "Create.Basicinformation",
    key: "basic_info",
  },
  {
    name: "Create.AddManager",
    key: "add_manager",
  },
  {
    name: "Create.Fundraising",
    key: "fundraising",
  },
  {
    name: "Create.Preview",
    key: "preview",
  },
];

const CreateContainer = ({ from }) => {
  const [step, setStep] = useState(STEPS[0].key);
  const [navs, setNavs] = useState([STEPS[0]]);

  const { chainId } = useWeb3React();

  const {
    state: { navStatus },
    dispatch,
  } = useCreateContext();

  const onChangeStep = (key) => {
    console.log("[onChangeStep]", key);
    setStep(key);
  };

  const showFullNavs = useCallback((v, goNext) => {
    const nav_arr = [];

    STEPS.forEach((s) => {
      if (
        (v === DaoType.wallet && s.key !== "fundraising") ||
        v === DaoType.venture
      ) {
        nav_arr.push({ ...s, available: s.key === "choose_type" });
      }
    });
    goNext && setStep("basic_info");
    setNavs(nav_arr);
  }, []);

  const handlePreviewBack = () => {
    const cur = navs.findIndex((item) => item.key === "preview");
    const curStr = navs[cur - 1].key;
    onChangeStep(curStr);
  };

  const showStep = (key) => {
    switch (key) {
      case "basic_info":
        return <BasicInfoStep />;
      case "add_manager":
        return <AddManager />;
      case "fundraising":
        return <Fundraising />;
      case "preview":
        return <Preivew handleBack={handlePreviewBack} />;
      case "choose_type":
        return <ChooseType showFullNavs={showFullNavs} />;
      default:
        return <></>;
    }
  };

  useEffect(async () => {
    if (navs.length === 1) {
      return;
    }
    dispatch({ type: "CheckNav" });
  }, [step, chainId]);

  useEffect(() => {
    if (!navStatus) {
      return;
    }
    const newNavs = [...navs];
    newNavs.forEach((n) => {
      if (n.key !== "choose_type") {
        n.available = navStatus[n.key] === "ok";
      }
    });
    setNavs(newNavs);
  }, [navStatus]);

  return (
    <SideNavLayout
      step={step}
      navs={navs}
      onChangeNav={onChangeStep}
      isPreview={step === "preview"}
      showNav={navs.length > 1}
      from={from}
    >
      {showStep(step)}
    </SideNavLayout>
  );
};

export default CreateContainer;
