import styled from "styled-components";
import { Progress } from "antd";

const RectSVG = ({ progress }) => (
  <svg className="box" width="100%" height={12}>
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style={{
            stopColor: "#4880FF",
            stopOpacity: 1,
          }}
        />
        <stop
          offset="50%"
          style={{
            stopColor: "#1DC6FF",
            stopOpacity: 1,
          }}
        />
        <stop
          offset="100%"
          style={{
            stopColor: "#20DEC0",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
    <rect width="100%" height={12} rx="8" ry="8" fill="#000" />
    <rect
      height="12"
      rx="8"
      ry="8"
      fill="url(#grad1)"
      width={progress ? `${progress}%` : 0}
    ></rect>
  </svg>
);

export default function RectProgress({ progress }) {
  return (
    <PercentBox>
      {/* <RectSVG progress={progress} /> */}
      <ProcessBar
        percent={progress}
        status="active"
        showInfo={false}
        trailColor="#000"
      />
    </PercentBox>
  );
}

const PercentBox = styled.div`
  width: 100%;
  margin-bottom: 16px;
  position: relative;
`;

const ProcessBar = styled(Progress)`
  .ant-progress-inner {
    height: 12px;
  }
  .ant-progress-bg {
    height: 12px !important;
    background: linear-gradient(90deg, #4880ff 0%, #1dc6ff 55%, #20dec0 100%);
  }
`;
