import { memo, useState, useEffect, Children, useCallback } from "react";
import styled from "styled-components";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";

import DownArrow from "img/swap/downArrow.png";
import TopArrowImg from "img/swap/topArrow.png";
import InfoImg from "../../../img/swap/info.png";
import OilImg from "../../../img/swap/oil.png";
import priceTip from "assets/images/Tip.svg";

import BigNumber from "bignumber.js";

import api from "api";
import { getShortDisplay } from "utils/publicJs";
import { SupportedChainId } from "network";
import SwapLoading from "components/apps/swap/swaploading";
import SwapRoute from "./swapRoute";

import TokensMap from "tokens/tokens";
import { find_path } from "dijkstrajs";

const SwapPrice = memo(
  ({
    children,
    fromToken,
    toToken,
    fromAmount,
    updateSwapPriceValue,
    updatePath,
    setVersion,
    swapRouterAddress,
    updatePriceError,
    updateGasUSD,
  }) => {
    const { t } = useTranslation();
    const { chainId, provider } = useWeb3React();

    const [swapPrice, setSwapPrice] = useState();
    const [loading, setLoading] = useState(false);
    const [showOutput, setShowOutput] = useState(false);

    const handleErrorText = (error) => {
      if (
        error === "ESTIMATED_LOSS_GREATER_THAN_MAX_IMPACT" ||
        error === "No routes found with enough liquidity"
      ) {
        return t("Swap.InsufficientLiquidity");
      } else {
        return error;
      }
    };

    const handleMainnet = async () => {
      const { data, code, version } = await api.venture.fetchMainnetPrice({
        chainId,
        srcToken: fromToken.address,
        srcDecimals: fromToken.deci,
        srcAmount: BigNumber(fromAmount).shiftedBy(fromToken.deci).toFixed(),
        targetToken: toToken.address,
        targetDecimals: toToken.decimals,
      });
      console.log("fetch: ", data, code, version);

      if (code !== 0) {
        if (data.error) {
          updatePriceError(handleErrorText(data.error));
        }
        setLoading(false);
        return;
      }
      setVersion(version);

      const priceRoute = data.priceRoute;
      const targetAmount = priceRoute.destAmount;
      const BN = BigNumber.clone({ DECIMAL_PLACES: 0 });

      setSwapPrice(
        getShortDisplay(
          BigNumber(targetAmount)
            .dividedBy(fromAmount)
            .shiftedBy(-toToken.decimals)
            .toString(),
          6
        )
      );
      updateSwapPriceValue(BN(targetAmount).dividedBy(fromAmount).toString());

      if (version === "v2") {
        const paths =
          priceRoute.bestRoute[0]?.swaps[0]?.swapExchanges[0]?.data.path;
        console.log("paths: ", paths);
        updatePath(paths);
        updatePriceError("");
      } else if (version === "v3") {
        const paths =
          priceRoute.bestRoute[0]?.swaps[0]?.swapExchanges[0]?.data.path;
        let types = [],
          values = [];
        paths.forEach((p, i) => {
          if (i === 0) {
            types = types.concat(["address", "uint24", "address"]);
            values = values.concat([p.tokenIn, parseInt(p.fee), p.tokenOut]);
          } else {
            types = types.concat(["uint24", "address"]);
            values = values.concat([parseInt(p.fee), p.tokenOut]);
          }
        });
        console.log("types:", types);
        console.log("values:", values);

        const pathBytes = ethers.utils.solidityPack(types, values);
        console.log("pathBytes:", pathBytes);
        updatePath(pathBytes);
      }
    };

    useEffect(async () => {
      if (!fromAmount) {
        setSwapPrice(undefined);
        return;
      }
      setSwapPrice(undefined);
      updateSwapPriceValue("");

      // if (!checkCount(fromAmount, fromToken.deci)) {
      //   return;
      // }
      setLoading(true);
      try {
        if (chainId === SupportedChainId.MOONBEAM) {
          // stellaSwap [work around]
          // tokensMap is from stellaswap online code
          const pathGraph = {};
          for (const k in TokensMap) {
            const value = TokensMap[k];
            const token0 = value.token0;
            const token1 = value.token1;
            if (!token0 || !token1) {
              continue;
            }

            if (!pathGraph[token0.id.toLowerCase()]) {
              pathGraph[token0.id.toLowerCase()] = {};
            }
            pathGraph[token0.id.toLowerCase()][token1.id.toLowerCase()] = 1;

            if (!pathGraph[token1.id.toLowerCase()]) {
              pathGraph[token1.id.toLowerCase()] = {};
            }
            pathGraph[token1.id.toLowerCase()][token0.id.toLowerCase()] = 1;
          }
          var paths = find_path(
            pathGraph,
            fromToken.address.toLowerCase(),
            toToken.address.toLowerCase()
          );
          console.log("path: ", paths);
          updatePath(paths);

          const num = await api.venture.getAmountOutMin(provider, {
            swapRouterAddress,
            path: paths,
            amountIn: ethers.utils.parseUnits(
              String(fromAmount),
              fromToken.deci
            ),
          });

          const BN = BigNumber.clone({ DECIMAL_PLACES: 0 });
          const targetAmount = BigNumber(num.toString());

          setSwapPrice(
            getShortDisplay(
              BigNumber(targetAmount)
                .dividedBy(fromAmount)
                .shiftedBy(-toToken.decimals)
                .toString(),
              6
            )
          );

          updateSwapPriceValue(
            BN(targetAmount).dividedBy(fromAmount).toString()
          );
        } else if (chainId === SupportedChainId.ZKSYNC) {
          const paths = [
            fromToken.address.toLowerCase(),
            toToken.address.toLowerCase(),
          ];
          const num = await api.venture.getAmountOutMin(provider, {
             swapRouterAddress,
             path: paths,
             amountIn: ethers.utils.parseUnits(
               String(fromAmount),
               fromToken.deci
             ),
           });

           const BN = BigNumber.clone({ DECIMAL_PLACES: 0 });
           const targetAmount = BigNumber(num.toString());

           setSwapPrice(
             getShortDisplay(
               BigNumber(targetAmount)
                 .dividedBy(fromAmount)
                 .shiftedBy(-toToken.decimals)
                 .toString(),
               6
             )
           );

           updateSwapPriceValue(
             BN(targetAmount).dividedBy(fromAmount).toString()
           );
        } else if (chainId === SupportedChainId.MAINNET) {
          await handleMainnet();
        } else if (
          [
            SupportedChainId.POLYGON,
            SupportedChainId.BSC,
            SupportedChainId.ARBITRUM_ONE,
          ].includes(chainId)
        ) {
          let swap;
          if (
            chainId === SupportedChainId.BSC ||
            chainId === SupportedChainId.BSC_TESTNET
          ) {
            swap = "pancake";
          } else if (chainId === SupportedChainId.POLYGON) {
            swap = "quickswap";
          } else if (chainId === SupportedChainId.ARBITRUM_ONE) {
            swap = "uniswapv3";
          }
          const data = await api.venture.fetchPrice({
            chainId: chainId,
            srcToken: fromToken.address,
            srcDecimals: fromToken.deci,
            srcAmount: BigNumber(fromAmount).shiftedBy(fromToken.deci),
            targetToken: toToken.address,
            targetDecimals: toToken.decimals,
            swap,
          });

          if (data.code !== 0) {
            if (data.data?.error) {
              updatePriceError(handleErrorText(data.data?.error));
            }
            setLoading(false);
            return;
          }
          console.log("==res:", data);
          const priceRoute = data.data.priceRoute;
          const targetAmount = priceRoute.destAmount;
          const BN = BigNumber.clone({ DECIMAL_PLACES: 0 });
          updateGasUSD(
            priceRoute.bestRoute[0]?.swaps[0]?.swapExchanges[0]?.data.gasUSD
          );
          setSwapPrice(
            getShortDisplay(
              BigNumber(targetAmount)
                .dividedBy(fromAmount)
                .shiftedBy(-toToken.decimals)
                .toString(),
              6
            )
          );
          updateSwapPriceValue(
            BN(targetAmount).dividedBy(fromAmount).toString()
          );
          let paths =
            priceRoute.bestRoute[0]?.swaps[0]?.swapExchanges[0]?.data.path;
          if (chainId === SupportedChainId.ARBITRUM_ONE) {
            let types = [],
              values = [];
            paths.forEach((p, i) => {
              if (i === 0) {
                types = types.concat(["address", "uint24", "address"]);
                values = values.concat([
                  p.tokenIn,
                  parseInt(p.fee),
                  p.tokenOut,
                ]);
              } else {
                types = types.concat(["uint24", "address"]);
                values = values.concat([parseInt(p.fee), p.tokenOut]);
              }
            });
            console.log("types:", types);
            console.log("values:", values);

            const pathBytes = ethers.utils.solidityPack(types, values);
            console.log("pathBytes:", pathBytes);
            paths = pathBytes;
          }

          console.log("paths: ", paths);
          updatePath(paths);
          updatePriceError("");
          if (chainId === SupportedChainId.ARBITRUM_ONE) {
            setVersion("v3");
          } else {
            setVersion();
          }
        } else {
          const num = await api.venture.getAmountOutMin(provider, {
            swapRouterAddress,
            path: [fromToken.address, toToken.address],
            amountIn: ethers.utils.parseUnits("1", fromToken.deci),
          });
          setSwapPrice(
            ethers.utils.formatUnits(num, toToken.deci || toToken.decimals)
          );
          updateSwapPriceValue(num.toString());
          setVersion();
        }
      } catch (error) {
        console.error("getPriceFailed", error);
        setVersion();
      }
      setLoading(false);
    }, [fromAmount]);

    const handleOutput = useCallback(() => {
      setShowOutput(!showOutput);
    }, [showOutput]);
    if (loading) {
      return (
        <FirstLine>
          <div className="lft">
            <SwapLoading foreColor="#6EDAFF" backColor="#041735" />
            <span className="tips">{t("Swap.FetchPrice")}</span>
          </div>
        </FirstLine>
      );
    }
    if (!loading && !swapPrice) {
      return <></>;
    }

    return (
      <>
        <FirstLine onClick={handleOutput}>
          <div className="lft">
            <img src={priceTip} alt="" />1 {fromToken.symbol} = {swapPrice}{" "}
            {toToken.symbol}
          </div>
          <div className="rht">
            {/* <img src={OilImg} alt="" /> */}
            {/* <span>$1.77</span> */}
            {!showOutput && <img src={DownArrow} alt="" />}
            {showOutput && <img src={TopArrowImg} alt="" />}
          </div>
        </FirstLine>
        {showOutput && children}
        {/* <SwapRoute tokenPath={paths} tokens={tokens} /> */}

        {/*<FirstLine onClick={()=>handlePending()}>*/}
        {/*    <div className="lft"><SwapLoading /><span className="tips">Fetching best price…</span></div>*/}
        {/*    <div className="rht"><img src={DownArrow} alt=""/></div>*/}
        {/*</FirstLine>*/}
      </>
    );
  }
);

export default SwapPrice;

const FirstLine = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  & > div {
    display: flex;
    align-items: center;
  }
  .lft {
    font-family: "Poppins-Light";
    img {
      width: 24px;
      margin-right: 10px;
    }
    font-size: 14px;
    font-weight: 400;
    .tips {
      margin-left: 17px;
    }
  }
  .rht {
    font-size: 12px;
    font-weight: 400;
    margin-right: 13px;
    span {
      padding: 0 10px;
    }
  }
`;
