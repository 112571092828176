import styled from "styled-components";
import { VoteInputBg } from "assets/styles/common";

export default function AmountBox({ value, onChange, children, ...rest }) {
  return (
    <AmountBoxStyled {...rest}>
      <input
        type="number"
        value={value}
        onChange={onChange}
        onWheel={() => document.activeElement.blur()}
      />
      {children}
    </AmountBoxStyled>
  );
}

const AmountBoxStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0 20px;
  min-height: 44px;
  ${VoteInputBg};
  position: relative;
  input {
    background: transparent;
    flex: 1;
    color: #fff;
  }
`;

