import axios from 'axios';
import { ethers } from 'ethers';
import { PROD_ENDPOINT, DEV_ENDPOINT } from "utils/constant";

const BACKEND_URL =
  process.env.NODE_ENV === "production" ? PROD_ENDPOINT : DEV_ENDPOINT;

const POST = (data)=>{
    return axios({
      method: "POST",
      url: BACKEND_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
}

const POST_GRAPH = data=>{
    return axios({
        method: 'POST',
        url: window.graphApi,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export const getFavorite =async () => {
 let data = await axios({
        method: "GET",
        url: `https://raw.githubusercontent.com/SubDAO-Network/daos/main/daos.json`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
return data;
};



export const getInvestProjects = async (contractAddress, page, size) => {
    const data = {
        query: `{
            projects(cond: {ventures_contract_address: "${contractAddress}"}, page:{ page: ${page}, size: ${size}, sort_field:"create_at", order:"desc"}) {
                page
                size
                total
                data {
                    name
                    logo
                    desc
                    amount
                    create_at
                    homepage
                }
            }
        }`
    }
    return POST(data)
}

export const requestSign = async(data, account) => {
    const msg = `${data.logo}:${data.name}:${data.desc}:${data.homepage}:${data.amount}:${data.timestamp}`
    
    try {
        const from = account;
        const sign_1 = `0x${Buffer.from(msg, 'utf8').toString('hex')}`;
        const sign_2 = ethers.utils.keccak256(sign_1)
        const sign_3 = `0x${Buffer.from(sign_2, 'utf8').toString('hex')}`;

        const sign = await window.ethereum.request({
            method: 'personal_sign',
            params: [sign_3, from],
        });
        return sign
    } catch (err) {
        console.error(err);
    }
}

export const uploadProject = async (data) => {
    let project = `name: "${data.name}", desc: "${data.desc}", logo: "${data.logo}", amount: ${data.amount}, timestamp:${data.timestamp}`
    if (!!data.homepage) project += `, homepage: "${data.homepage}"`
    return POST({
        query: `mutation{
            createProject(sign: {sign: "${data.signer}", ventures_contract_address: "${data.daoAddress}"}, project: {${project}}) {
                create_at
                desc
                logo
                name
                amount
                homepage
            }
        }`
    })
}


export const uploadImg = async (fileData) => {
    const formData = new FormData();

    const query = 'mutation ($file: Upload!) { fileUpload(file: $file) }'
    const variables = { file: null }

    formData.append("operations", JSON.stringify({ query, variables}));
    formData.append("map", JSON.stringify({"0": ["variables.file"]}));
    formData.append("0", fileData);

    return POST(formData)
}

export const getVaultHistory = async (vaultAddress, page, size) => {
    return POST_GRAPH({
        query: `{
            totalCount(id: "${vaultAddress}") {
              count
            }
            vaults(where: {vaultContractAddress: "${vaultAddress}"}, orderBy:blockNumber, orderDirection:desc, first: ${size}, skip: ${page*size}) {
              id
              vaultType
              vaultContractAddress
              tokenAddress
              fromAddress
              toAddress
              amount
              voteId
              blockNumber
              createTime
            }
          }`
    })
}


export const getDaoList = async (account, page, size) => {
    account = account.toLocaleLowerCase()
    const query = `{
        totalCount(id: "${account}") {
            count
        }
        orgMembers(where: {memberAddress: "${account}"}, first: ${size}, skip: ${page*size}, orderBy: addAtBlock, orderDirection: desc) {
            daoId
            memberAddress
            daoname
            daoalias
        }
    }`
   
    const resp = await POST_GRAPH({
        query: query
    })
    if (resp.status !== 200) {
        return {error: `Request failed, status is ${resp.status}`}
    }
    return resp.data.data
}

export const filterDaos = async (key, keyword, sort) => {
    keyword = keyword.toLocaleLowerCase()
    const query = `{
        totalCount(id: "dao") {
            count
        }
        daoOrgInstances(where:{${key}_contains:"${keyword}"}, orderBy:${sort}, orderDirection:desc, first:20, skip:0) {
          id
          owner
          daoAddress
          raisedAmount
          createTime
          daoname
          daoalias
        }
    }`
    console.log(query)
    const resp = await POST_GRAPH({
        query: query
    })
    if (resp.status !== 200) {
        return {error: `Request failed, status is ${resp.status}`}
    }
    return resp.data.data
}

export const getAllDaos = async (account, page, size, sort, name_filter) => {
    let query
    if (name_filter) {
        query = `{
            totalCount(id: "dao") {
                count
            }
            daoOrgInstances(where:{search_contains:"${name_filter.toLocaleLowerCase()}"}, orderBy:${sort}, orderDirection:desc) {
              id
              owner
              daoAddress
              raisedAmount
              createTime
              daoname
              daoalias
            }
        }`
    } else {
        query = `{
            totalCount(id: "dao") {
                count
            }
            daoOrgInstances(orderBy:${sort}, orderDirection:desc, first:${size}, skip:${page*size}) {
              id
              owner
              daoAddress
              raisedAmount
              createTime
              daoname
              daoalias
            }
        }`
    }
    const resp = await POST_GRAPH({
        query: query
    })
    if (resp.status !== 200) {
        return {error: `Request failed, status is ${resp.status}`}
    }
    return resp.data.data
}

export const getGateway = async ()=>{
    const result = await POST({
        query: `query MyQuery{gateway}`
    })
    return result.data.data.gateway

}

export const getJsonFile = async(url)=>{
    const response = await axios({
        method: 'GET',
        url: url,
    })
    return response.data
}

export const getJsonFileByFetch = async (url) => {
    const response = await fetch(url, {
        method: 'GET',
    })
    return response.json()
}