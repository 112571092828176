import { gql } from "@apollo/client";

export const GEM_SOURCE = "Gem - The NFT Marketplace Aggregator";
const ELEMENT = "Element Market";

const VERSION = 3;

// [NOTE] 
// key in NFT_SOURCE should be consistent with network config file
// value in NFT_SOURCE is from app's name
export const NFT_SOURCE = {
  Gem: GEM_SOURCE,
  Element: ELEMENT,
};

export const CHAIN_NFT_SOURCE = {
  1: GEM_SOURCE,
  137: ELEMENT,
};

export const SubgraphApiQuery = gql`
  query MyQuery($query: String!, $chainId: Int!) {
    subgraphApi(query: $query, chainID: $chainId)
  }
`;

/*********** QUERY **********/

export const testSubGraphql = () => {
  return JSON.stringify({
    query: `query myquery {
        daoFactoryCompCreateEvents(first: 5) {
            id
        }
      }`,
  });
};

export const getVaultHistory = (vaultAddress, page, size) => {
  return JSON.stringify({
    query: `{
      totalCount(id: "${vaultAddress}") {
        count
      }
      vaults:vaultRecords(where: {vaultContractAddress: "${vaultAddress}"}, orderBy:blockNumber, orderDirection:desc, first: ${size}, skip: ${
      (page - 1) * size
    }) {
        id
        vaultType
        vaultContractAddress
        tokenAddress
        fromAddress
        toAddress
        amount
        voteID
        txHash
        blockNumber
        blockTimestamp
      }
    }`,
  });
};

export const getWCTlist = (daoId, page, size, filterSource, sourceName, version) => {
  if (!version) {
    version = 3;
  }
  const id = `tx-active-${daoId}-${version}`;
  let where;
  if (filterSource) {
    where = `{ state: 0, source: "${NFT_SOURCE[sourceName]}", daoID: "${daoId}", daoVersion: ${version}}`;
  } else {
    where = `{ state: 0, source_not: "${NFT_SOURCE[sourceName]}", daoID: "${daoId}", daoVersion: ${version} }`;
  }
  return JSON.stringify({
    query: `{
      totalCount(id: "${id}"){
        count
      }
      transactionRecords(first: ${size}, skip: ${
      (page - 1) * size
    }, where: ${where}, orderBy: txID, orderDirection: desc) {
        txID
        source
        createAt
        state
        agree_count
        oppose_count
      }
    }`,
  });
};

export const getWCTall = (daoId, page, size, version) => {
  if (!version) {
    version = 3;
  }
  const where = `{ state: 0, daoID: "${daoId}", daoVersion: ${version}}`;
  return JSON.stringify({
    query: `{
      transactionRecords(first: ${size}, skip: ${
      (page - 1) * size
    }, where: ${where}) {
        txID
        source
        state
      }
    }`,
  });
};

export const getConfirmedStatus = (daoId, txIdx, account, version) => {
  if (!version) {
    version = 3;
  }
  const parseArray = txIdx.join(",");
  return JSON.stringify({
    query: `{
      transactionConfirmRecords(where: {txID_in: [${parseArray}], daoID: ${daoId}, daoVersion: ${version}, sender: "${account}"}){
        txID
        state
      }
    }`,
  });
};

export const getWCTHistory = (
  daoId,
  page,
  size,
  filterSource,
  sourceName,
  version
) => {
  if (!version) {
    version = 3;
  }
  const id = `tx-inactive-${daoId}-${version}`;
  let where;
  if (filterSource) {
    where = `{ state_not: 0, source: "${NFT_SOURCE[sourceName]}", daoID: "${daoId}", daoVersion: ${version}}`;
  } else {
    where = `{ state_not: 0, source_not_in: ["vote", "${NFT_SOURCE[sourceName]}"], daoID: "${daoId}", daoVersion: ${version} }`;
  }
  return JSON.stringify({
    query: `{
      totalCount(id: "${id}"){
        count
      }
      transactionRecords(first: ${size}, skip: ${
      (page - 1) * size
    }, where: ${where}, orderBy: txID, orderDirection: desc) {
        txID
        source
        createAt
        state
        agree_count
        oppose_count
      }
    }`,
  });
};

// use for: check if wct has data
export const checkWCTlist = (daoId, page, size, filterSource, version) => {
  if (!version) {
    version = 3;
  }
  const where = `{ source_not: "${NFT_SOURCE[filterSource]}", daoID: ${daoId}, daoVersion: ${version} }`;
  return JSON.stringify({
    query: `{
      transactionRecords(first: ${size}, skip: ${
      (page - 1) * size
    }, where: ${where}) {
        txID
      }
    }`,
  });
};

export const getTxDetail = (daoId, txId, version) => {
  if (!version) {
    version = 3;
  }
  return JSON.stringify({
    query: `{
      voters:transactionConfirmRecords(where: {txID: ${txId}, daoID: ${daoId}, daoVersion: ${version}}){
        sender
        state
      }
      info:transactionRecord(id: "${daoId}-${version}-${txId}"){
        creator
        createAt
        executor
        state
        source
        agree_count
        oppose_count
        txHash
        txID
        data
      }
    }`,
  });
};

export const getSuperList = (daoId, page, size, version) => {
  if (!version) {
    version = 3;
  }
  const id = `spend-limit-${daoId}-${version}`;
  return JSON.stringify({
    query: `{
      totalCount(id: "${id}") {
        count
      }
      spendLimitRecords(where: {daoID: ${daoId}},first: ${size}, daoVersion: ${version}, skip: ${
      (page - 1) * size
    }, orderBy:blockNumber, orderDirection:desc,) {
        gp
        method
        expire
        limit
        spend
        tokenIn
        tokenOut
      }
    }`,
  });
};


export const getClaimRecords = (daoId, address) => {
  return JSON.stringify({
    query: `{
      equityTokenFreeRecords(
        where: { daoID: ${daoId}, address: "${address}", daoVersion: 2 }
      ) {
        amount
        blockTimestamp
      }
    }`,
  });
};

export const getWctCount = (daoId, type) => {
  const id = `tx-active-${type || "wct"}-${daoId}-${VERSION}`;
  return JSON.stringify({
    query: `{
      totalCount(id: "${id}"){
        count
      }
    }`,
  });
};

export const getNftHistry = (daoId, page, size) => {
  const id = `tx-inactive-nft-${daoId}-${VERSION}`;
  return JSON.stringify({
    query: `{
      totalCount(id: "${id}"){
        count
      }
      transactionRecords(first: ${size}, skip: ${
        (page - 1) * size
      }, where: { source: "nft", daoID: ${daoId}, daoVersion: ${VERSION}, state_not: 0 }, orderBy: txID, orderDirection: desc) {
        txID
        source
        createAt
        state
        agree_count
        oppose_count
      }
    }`,
  });
};

export const getWctHistry = (daoId, page, size) => {
  const id = `tx-inactive-wct-${daoId}-${VERSION}`;
  return JSON.stringify({
    query: `{
      totalCount(id: "${id}"){
        count
      }
      transactionRecords(first: ${size}, skip: ${
        (page - 1) * size
      }, where: { source_not_in: ["nft", "vote"], daoID: ${daoId}, daoVersion: ${VERSION}, state_not: 0 }, orderBy: txID, orderDirection: desc) {
        txID
        source
        createAt
        state
        agree_count
        oppose_count
    }
    }`,
  });
};

export const getVoteHistry = (daoId, page, size) => {
  const id = `tx-inactive-vote-${daoId}-${VERSION}`;
  return JSON.stringify({
    query: `{
      totalCount(id: "${id}"){
        count
      }
      transactionRecords(first: ${size}, skip: ${
      (page - 1) * size
    }, where: { source: "vote", daoID: ${daoId}, daoVersion: ${VERSION}, state_not: 0 }, orderBy: txID, orderDirection: desc) {
        txID
        source
        createAt
        state
        agree_count
        oppose_count
        data
        txHash
      }
    }`,
  });
}