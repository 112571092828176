import React, { memo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LiBg from "../../assets/gif/Rectangle.svg";

const ImgBox = styled.img`
  margin: auto 30px;
  width: 30px;
  opacity: 0.5;
  position: relative;
`;
const HoverImg = styled.img`
  margin:auto 30px;
  width: 27px;
  opacity: 1;
  position: relative;
  
`
const ImgCur = styled.img`
  margin: auto 30px;
  width: 30px;
  opacity: 1;
  position: relative;
`;

const SideNavLayout = memo((props) => {
  const { t } = useTranslation();
  const { navs, onChangeNav, containerStyle, step } = props;
  const [curNav, setCurNav] = useState();
  const [hoverNav, setHoverNav] = useState();

  useEffect(()=>{
      if(navs.length <= 0) return;
      setCurNav(step);
  },[step,navs]);

  return (
    <SideNavLayoutStyled>
      <NavUl>
        {navs.map((n, i) => (
          <NavLi
            key={i}
            onMouseOver={() => setHoverNav(n.key)}
            onMouseOut={() => setHoverNav(null)}
            onClick={() => {
              if (n.key === curNav) {
                return;
              }
              setCurNav(n.key);
              onChangeNav && onChangeNav(n.key);
            }}
            className={n.key === curNav ? "selected" : ""}
          >
            <BgGlass />

            {/*{*/}
            {/*  !n.icon && <span className="dot" />*/}
            {/*}*/}
            {!!n.icon && n.key !== curNav && n.key !== hoverNav && (
              <ImgBox src={n.icon} alt="" />
            )}
            {!!n.icon && n.key === curNav && n.key !== hoverNav && (
              <ImgCur src={n.icon} alt="" />
            )}
            {!!n.icon && n.key === hoverNav && (
              <HoverImg src={n.iconHover} alt="" />
            )}
            <div className="text">{t(n.name)}</div>
          </NavLi>
        ))}
      </NavUl>
      <LayoutContainer style={containerStyle}>{props.children}</LayoutContainer>
    </SideNavLayoutStyled>
  );
});

export default SideNavLayout;

const SideNavLayoutStyled = styled.div`
  display: flex;
  align-items: stretch;
  margin: 24px auto 0;
  width: 1240px;
  height: calc(100vh - 230px);
`;

const LayoutContainer = styled.div`
flex-grow: 1;
`;

const NavUl = styled.ul`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const BgGlass = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  /* &:hover, */
  &.selected {
    background: url(${LiBg});
    background-size: 100% 100%;
    backdrop-filter: unset;
  }
`;

const NavLi = styled.li`
  width: 52px;
  height: 52px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  position: relative;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }

  .text {
    min-width: 104px;
    height: 48px;
    background: #041735;
    border: 2px solid rgba(255, 255, 255, 0.08);
    left: 62px;
    top: 2px;
    position: absolute;
    line-height: 44px;
    text-align: center;
    z-index: 99999;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 10px;
    display: none;
    white-space: nowrap;
  }
  &:hover {
    .text {
      display: block;
    }
  }
  @media (max-height: 730px) {
    margin-bottom: 20px;
  }
`;
