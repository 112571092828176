import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M13.333 9.667H7.167a1 1 0 0 0-.994.883l-.006.117v6.166a1 1 0 0 0 .883.994l.117.006h6.166a1 1 0 0 0 .994-.883l.006-.117v-6.166a1 1 0 0 0-1-1Zm2.167 4.666h1.333a1 1 0 0 0 .994-.883l.006-.117V7.167a1 1 0 0 0-1-1h-6.166a1 1 0 0 0-.994.883l-.006.117L9.666 8.5H13.5a2 2 0 0 1 2 2v3.833Zm0 2.667a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-6.5a2 2 0 0 1 2-2h1.5V7a2 2 0 0 1 2-2H17a2 2 0 0 1 2 2v6.5a2 2 0 0 1-2 2h-1.5V17Z"
        fill="#0E164E"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
