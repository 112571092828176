import { memo, useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import LinkImg from "../../assets/images/link.svg";
import AddToken from "./addToken";
import { useDaoContext } from "../../views/dao/provider";
import { VAULT_MANAGER, DAO_ORG } from "../../utils/constant";
import useVaultTokens from "../../hooks/useVaultTokens";
import { TokenLogo } from "components/common/avatar";
import { useAppContext } from "components/provider/appProvider";
import EmptyBox from "components/common/empty";
import LoadingBox from "components/common/loading";
import GoldSVG from "components/svg/shape/gold";
import useSubcribe from "hooks/useSubscribe";
import { TransactionEvent } from "utils/constant";
import { useTranslation } from "react-i18next";
import GoldImg from "../../assets/images/token.svg";
import AddImg from "../../assets/images/icon-add.svg";
import Button from "components/common/button";
import MultiClamp from "react-multi-clamp";
import { GlassBG } from "assets/styles/common";
import { Tooltip } from "antd";

const Tokens = memo(() => {
  const { t } = useTranslation();
  const {
    state: { exploreScan },
  } = useAppContext();

  const { state: daoState, dispatch } = useDaoContext();
  const {
    componentAddressMap,
    whoAmI: { isOwner },
    daoId,
  } = daoState;
  const [count, setCount] = useState(0);
  const { tokens, loading } = useVaultTokens(
    componentAddressMap.get(VAULT_MANAGER),
    true,
    count
  );
  // const tokens = Array.from(
  //   { length: 40 },
  //   (item) =>
  //     (item = {
  //       address: "0x9ED55eBE818C3c973b562b3D8681883d4F27534D",
  //       balance: "10.0",
  //       balanceDisplay: "10.0",
  //       deci: 6,
  //       symbol: "USDT",
  //     })
  // );

  const [show, setShow] = useState(false);

  const handleAdd = async () => {
    setShow(true);
  };
  const handleClose = (updateFlag) => {
    setShow(false);
    if (updateFlag) {
      dispatch({ type: "UPDATE_FUND_OR_PERIOD", payload: "tokens" });
    }
  };

  const checkExist = useCallback(
    (address) => {
      return (tokens || []).find(
        (t) => t.address.toLowerCase() === address.toLowerCase()
      );
    },
    [tokens]
  );

  useSubcribe(TransactionEvent.AddToken, (_, { daoId: _daoId }) => {
    if (_daoId === daoId) {
      setCount(count + 1);
    }
  });

  return (
    <Box>
      {isOwner && (
        <BtnBox>
          <Button primary onClick={() => handleAdd()}>
            <span>{t("Portfolio.Add")}</span>
            <img src={AddImg} alt="" />
          </Button>
        </BtnBox>
      )}
      {show && (
        <AddToken
          show={show}
          handleClose={handleClose}
          checkExist={checkExist}
          refreshList={() => setCount(count + 1)}
        />
      )}
      <TypeBox>
        <LoadingBox loading={loading} />
        {!loading && !tokens.length && <EmptyBox />}
        {!loading &&
          tokens.map((token, index) => (
            <>
              <li key={index}>
                <CardGlassBG className="bg" />
                <SymbolBox>
                  <div className="lft">
                    {/*<TokenLogo size="32px" />*/}
                    <LogoBg>
                      <img src={GoldImg} className="gold" alt="" />
                      <img src={token.logoURI} alt="" className="logo" />
                    </LogoBg>
                    <Title>
                      <MultiClamp
                        clamp="1"
                        splitByWords={false}
                        ellipsis={
                          <Tooltip title={`${token.balanceDisplay} ${token.symbol}`}>
                            <strong>...</strong>
                          </Tooltip>
                        }
                      >
                        {token.balanceDisplay} {token.symbol}
                      </MultiClamp>
                    </Title>
                    <div className="token">
                      <span className="symbol">{token.name}</span>
                      <span className="identity">{token.identity}</span>
                    </div>
                  </div>
                  <LinkIcon
                    href={`${exploreScan}token/${token.address}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={LinkImg} alt="" />
                  </LinkIcon>
                </SymbolBox>
              </li>
            </>
          ))}
      </TypeBox>
    </Box>
  );
});

export default Tokens;

const Box = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
`;
const CardGlassBG = styled(GlassBG)`
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  
`;
const TypeBox = styled.ul`
  height: 100%;
  overflow: auto;
  li {
    float: left;
    width: 281px;
    height: 161px;
    margin-right: 16px;
    padding: 17px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:hover .bg {
      background: linear-gradient(
        136deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.13) 100%
      );
    }
  }
`;
const Title = styled.div`
  margin-bottom: 5px;
  font-size: 24px;
  font-family: "Rubik-Bold";
  font-weight: bold;
  color: #ffffff;
  line-height: 28px;
  margin-top: 16px;
`;
const SymbolBox = styled.div`
  flex-grow: 1;
  position: relative;
  .logo {
    width: 48px;
    height: 48px;
    border-radius: 48px;
  }
  .token {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .symbol {
      opacity: 0.5;
    }
    .identity {
      color: rgba(14, 22, 78, 0.5);
      font-size: 12px;
    }
  }
`;
const BtnBox = styled.div`
  position: fixed;
  right: calc((100vw - 1240px) / 2);
  top: 235px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
  @media (max-width: 1440px) {
    top: 200px;
  }
  button {
    width: unset;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    img {
      width: 24px;
      margin-left: 4px;
    }
  }
`;

const LinkIcon = styled.a`
  opacity: 0.72;
  img {
    width: 24px;
  }
  position: absolute;
  right: -2px;
  top: -2px;
`;
const LogoBg = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  .gold {
    position: absolute;
    right: -5px;
    bottom: 0;
    width: 20px;
  }
`;
