import styled from "styled-components";

import CopyStr from "../../copy";
import React, { useCallback, useState } from "react";

import publicJs from "utils/publicJs";
import Avatar, { TokenAvatar } from "components/common/avatar";
import useOrgMembers from "hooks/useOrgMembers";
import { useAppContext } from "../../provider/appProvider";
import TokenDefault from "../../../img/defaultToken.svg";
import UniswapLogo from "assets/images/vote/Uniswap.svg";
import AaveLogo from "assets/images/vote/aave.svg";

import { useDaoContext } from "views/dao/provider";
import { ORG_MANAGER } from "utils/constant";
import { useMemo } from "react";
import { SupportedChainId } from "network";
import { useNetworkData } from "hooks/useNetwork";
import { useTranslation } from "react-i18next";
import getIdentity from "utils/tokenIdentity";
import AllTokenAvatar from "components/common/allTokenAvatar";

export default function SuperManagerTable({ list }) {
  const { t } = useTranslation();
  const {
    state: { componentAddressMap, daoChainId },
  } = useDaoContext();
  const orgMembers = useOrgMembers(componentAddressMap.get(ORG_MANAGER));

  const networkData = useNetworkData(daoChainId);

  const handleError = (e) => {
    e.target.src = TokenDefault;
    e.target.οnerrοr = null;
  };

  const showDappLogo = useCallback(
    (method) => {
      if (method === "GP_Buy" || method === "GP_Buy_unlimited") {
        return networkData?.swapApp ? (
          <span style={{ fontSize: "16px" }}>{networkData?.swapApp}</span>
        ) : (
          <img src={UniswapLogo} alt="" style={{ height: "24px" }} />
        );
      } else if (method === "GP_Loan" || method === "GP_Loan_unlimited") {
        return <img src={AaveLogo} alt="" style={{ height: "24px" }} />;
      }
      return "";
    },
    [networkData]
  );

  return (
    <TableBox cellPadding="0" cellSpacing="0">
      <thead>
        <tr>
          <th>{t("Super.SuperManager")}</th>
          <th style={{ textAlign: "left" }}>{t("Super.SpendingLimit")}</th>
          <th>{t("Super.DApp")}</th>
          <th>{t("Super.Expiry")}</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, index) => (
          <tr key={index}>
            <td>
              <AddressBox>
                <Avatar size="24px" address={item.address} />
                <div>
                  <div className="name">{orgMembers[item.address]}</div>
                  <div className="address manager">
                    {publicJs.AddresstoShow(item.address)}{" "}
                    <CopyStr address={item.address} />
                  </div>
                </div>
              </AddressBox>
            </td>
            <td>
              {item.all === "-1" ? (
                <SymbolBox>
                  <AllTokenAvatar />
                  <div>
                    <div className="name">{t("Vote.AllTokens")}</div>
                    <div className="address">{t("Vote.Unlimited")}</div>
                  </div>
                </SymbolBox>
              ) : (
                <SymbolBox>
                  <img
                    src={item.logo || TokenDefault}
                    alt=""
                    onError={handleError}
                  />
                  <div>
                    {}
                    <div className="name">
                      {item.symbol}
                      <span className="identity">
                        {getIdentity(item.tokenAddress)}
                      </span>
                    </div>
                    <div className="address">
                      <span>{item.spent}</span> {t("of")} {item.all}
                    </div>
                  </div>
                </SymbolBox>
              )}
            </td>
            <td>{showDappLogo(item.method)}</td>
            <td>{item.expireAt}</td>
          </tr>
        ))}
      </tbody>
    </TableBox>
  );
}

const TableBox = styled.table`
  /* height: 100%;
  padding-top: 3px;
  height: calc(100% - 80px);
  overflow-y: auto;
  table { */
    width: 100%;
  /* } */
  th {
    height: 70px;
    color: #328aff;
    font-size: 16px;
    &:first-child {
      text-align: left;
      padding-left: 80px;
    }
    &:last-child {
      padding-right: 80px;
    }
  }
  td {
    height: 70px;
    text-align: center;
    white-space: nowrap;

    font-size: 14px;
    font-weight: 400;
    &:first-child {
      padding-left: 53px;
    }
    &:last-child {
      padding-right: 70px;
    }
  }
  tbody tr:nth-child(2n + 1) {
    background: rgba(255, 255, 255, 0.04);
  }
  .edit {
    margin-right: 12px;
  }
`;

const AddressBox = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 0;
  text-align: left;
  img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 12px;
  }
  .name {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .address {
    font-weight: 400;
    line-height: 21px;
    &.manager {
      img {
        position: relative;
        top: 6px;
      }
    }
  }
`;
const SymbolBox = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 0;
  text-align: left;
  img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 12px;
  }
  .name {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .identity {
    margin-left: 5px;
    opacity: 0.5;
  }
  .address {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
`;
