import * as React from "react"

const SvgComponent = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g fill="#959BB4" fillRule="nonzero">
        <path d="M12 21a9 9 0 1 1 0-18 9 9 0 0 1 0 18Zm0-16.875a7.875 7.875 0 1 0 0 15.75 7.875 7.875 0 0 0 0-15.75Z" />
        <path d="M14.194 8.018c.523.478.807 1.164.776 1.873.02.571-.15 1.133-.484 1.597-.354.363-.73.705-1.125 1.024-.228.185-.42.412-.562.67-.143.27-.215.571-.208.877v.225h-1.373v-.225c-.015-.428.07-.854.248-1.243a7.172 7.172 0 0 1 1.496-1.626l.225-.248c.223-.269.348-.606.354-.956.018-.394-.119-.78-.382-1.074a1.49 1.49 0 0 0-1.125-.394 1.468 1.468 0 0 0-1.288.563c-.26.4-.385.873-.355 1.35H9.03a3.02 3.02 0 0 1 .838-2.25 3.043 3.043 0 0 1 2.25-.838 2.964 2.964 0 0 1 2.076.675Zm-1.587 7.239a.86.86 0 0 1 .276.68.928.928 0 0 1-.275.681 1.03 1.03 0 0 1-.698.265.962.962 0 0 1-.95-.945.838.838 0 0 1 .286-.67.877.877 0 0 1 .67-.264.94.94 0 0 1 .691.253Z" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
