import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg width={34} height={34} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#C71313" strokeWidth={2} cx={17} cy={17} r={16} />
      <path
        d="M22.657 12.757 18.414 17l4.243 4.243-1.414 1.414L17 18.414l-4.243 4.243-1.414-1.414L15.586 17l-4.243-4.243 1.414-1.414L17 15.586l4.243-4.243 1.414 1.414Z"
        fill="#C71313"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
