import styled from "styled-components";
import { InputNumber } from "antd";
import LoadingNew from "components/loadingNEW";

export const DepositContent = styled.div`
  width: 100%;
  padding-top: 24px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .left {
    height: 19px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.5;
  }
  .price {
  }
  .minAmount {
    height: 25px;
    line-height: 21px;
    font-size: 18px;
    color: #45beff;
    font-family: "Poppins-Medium";
  }
  .balance {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
`;

export const InputAmountStyled = styled(InputNumber)`
  width: 120px;
  margin-left: 13px;

  &.ant-input-number-focused {
    box-shadow: none;
  }

  &.ant-input-number {
    border: none;
    background: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-number-input {
    height: 40px;
    color: #ffffff;
    font-family: "Rubik-Regular";
    background: transparent;
    padding: 0;
    margin: 0;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .ant-input-number-input-wrap {
    line-height: 40px;
  }
`;

export const LoadingStyled = styled(LoadingNew)`
  margin-right: 5px;
`;