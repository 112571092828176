import { useEffect, memo, useState } from "react";
import { ethers } from "ethers";
import styled from "styled-components";

import { NumberBox } from "./styles";
import Avatar from "components/common/avatar";
import { useTranslation } from "react-i18next";
import { Block } from "./styles";
import AddressBox from "components/common/addressBox";

const ModifyMultisign = memo(({ data, orgMembersMap }) => {
  const { t } = useTranslation();

  const [gplist, setGplist] = useState([]);
  const [minSuportRequire, setMinSuportRequire] = useState();

  useEffect(() => {
    if (!data || !Object.keys(orgMembersMap).length) {
      return;
    }
    const wdata = ethers.utils.defaultAbiCoder.decode(["uint256"], data);
    setMinSuportRequire(wdata[0].toNumber());
  }, [data, orgMembersMap]);

  return (
    <>
      <Block title={t("Vote.VoteConfigTip")} className="flexBox">
        <NumberBox className="confirmBox">{minSuportRequire}</NumberBox>{" "}
        <span>
          {t("Vote.VoteConfigNumber", {
            gp: Object.keys(orgMembersMap).length,
          })}
        </span>
      </Block>
    </>
  );
});

export default ModifyMultisign;


