import React, {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { InputNumber } from "antd";

import { useDaoContext } from "views/dao/provider";
import { useOrgContract } from "hooks/useContract";
import { useAppContext } from "components/provider/appProvider";

import { ORG_MANAGER } from "utils/constant";
import publicJs from "utils/publicJs";
import { checkBonus, checkInvalidBonus, formatBonus } from "utils/check";

import api from "../../../api";
import VentureVoteConfig from "./voteConfig";
import ManagersSelect from "./components/managersSelect";
import { VoteItem, Label } from "./components/style";
import { InputBackground } from "assets/styles/common";

export default forwardRef(function RemoveManager(props, ref) {
  const { t } = useTranslation();
  const { state: daoState } = useDaoContext();
  const { componentAddressMap, daoId, owner, baseData } = daoState;
  const {
    dispatch,
  } = useAppContext();

  const orgContract = useOrgContract(componentAddressMap.get(ORG_MANAGER));

  const [gplist, setGplist] = useState([]);
  const [removeGpAddress, setRemoveGpAddress] = useState();
  const [minSupportNumber, setMinSupportNumber] = useState();

  const getGpList = useCallback(async () => {
    const { lst } = await api.org.getDaoMembersList(orgContract, 0, 1000);
    console.log(lst);
    setGplist(lst);
  }, [orgContract]);

  useEffect(() => {
    if (orgContract) {
      getGpList();
    }
  }, [orgContract]);

  const handleChangeBonus = useCallback(
    (i, v) => {
      const newArray = [...gplist];
      newArray[i].bonus = Number(v);
      setGplist(newArray);
    },
    [gplist]
  );

  const choose = (v) => {
    setRemoveGpAddress(v);
  };

  const checkValid = () => {
    if (!removeGpAddress) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidAddressTip"), closable: true },
      });
      return;
    }
    if (
      !minSupportNumber ||
      minSupportNumber <= 0 ||
      minSupportNumber > gplist.length + 1
    ) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.InvalidMinSupport"), closable: true },
      });
      return;
    }
    if (baseData?.dao_type === 2) {
      return true;
    }
    const checkList = gplist.filter((g) => g.address !== removeGpAddress);
    if (checkInvalidBonus(checkList)) {
      dispatch({
        type: "MSGTYPE",
        payload: {
          msg: t("Msg.InvalidBonus"),
          closable: true,
        },
      });
      return;
    }
    if (!checkBonus(checkList)) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.BonusMatch"), closable: true },
      });
      return;
    }

    return true;
  };

  useImperativeHandle(ref, () => ({
    name: "remove",
    checkValid,
    getVoteBytes() {
      const lastGplist = gplist.filter((g) => g.address !== removeGpAddress);
      return api.vote.newKickGPVote({
        gpAddress: removeGpAddress,
        minSurportRequire: minSupportNumber,
        gpList: lastGplist.map((g) => g.address),
        gpBonus:
          baseData?.dao_type === 2
            ? [formatBonus(100)].concat(
                lastGplist.slice(1).map((g) => formatBonus(0))
              )
            : lastGplist.map((g) => formatBonus(g.bonus)),
      });
    },
  }));

  return (
    <AddBox>
      <VoteItem>
        <Label>{t("Vote.RemoveAddressOf")}</Label>
        <ManagersSelect
          list={gplist.filter(
            (gp) => gp.address.toLowerCase() !== owner.toLowerCase()
          )}
          onSelect={choose}
          selected={removeGpAddress}
        />
      </VoteItem>
      {baseData?.dao_type !== 2 && (
        <dl>
          <dt>{t("Vote.ResetInterest")}</dt>
          <dd>
            <UlBox>
              {gplist.map((gp, index) =>
                removeGpAddress === gp.address ? (
                  <React.Fragment key={index}></React.Fragment>
                ) : (
                  <li key={index}>
                    <div className="lft">
                      <span>{t("Vote.Address")}</span>
                      <div className="address">
                        {publicJs.AddresstoShow(gp.address)}
                      </div>
                    </div>
                    <div className="rht">
                      <span>{t("Vote.Bonus")}</span>
                      <BonusInput>
                        <InputNumberStyled
                          value={gp.bonus}
                          controls={false}
                          precision={2}
                          max={100}
                          onChange={(v) => handleChangeBonus(index, v)}
                        />
                        <span>%</span>
                      </BonusInput>
                    </div>
                  </li>
                )
              )}
            </UlBox>
          </dd>
        </dl>
      )}
      <VentureVoteConfig
        minSupportNumber={minSupportNumber}
        updateMinSupportNumber={setMinSupportNumber}
        gpLength={Math.max(gplist.length - 1, 1)}
      />
    </AddBox>
  );
});

const BonusInput = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  input {
    text-align: right;
    flex: 1;
    margin-left: 0 !important;
    margin-right: 20px !important;
  }
`;

const AddBox = styled.div`
  dt {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6b9;
    line-height: 19px;
    margin-bottom: 10px;
  }
  dd {
    position: relative;
  }
  dl {
    margin-bottom: 20px;
  }
`;

const UlBox = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  input {
    flex-grow: 1;
    margin: 0 30px;
    width: 50px;
    border: 0;
    background: transparent;
    font-size: 14px;
  }
  .lft,
  .rht {
    ${InputBackground};
    flex: 1;
    padding: 12px;
    display: flex;

    align-items: center;
  }
  .lft {
    justify-content: flex-start;
    margin-right: 20px;
  }
  .rht {
    justify-content: space-between;
  }
  .address {
    padding-left: 10px;
  }
  span {
    font-size: 12px;
    opacity: 0.5;
    line-height: 12px;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  flex: 1;
  border: none;
  background: transparent;
  .ant-input-number-input-wrap {
    height: 44px;
    line-height: 44px;
  }
  &.ant-input-number-focused {
    border: none;
    box-shadow: none;
  }
  &.ant-input-number .ant-input-number-input {
  }
  input.ant-input-number-input {
    width: 100%;
    height: 44px;
    line-height: 44px;
    color: #ffffff;
  }
`;
