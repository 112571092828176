import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h22v22H0z" />
      <path
        d="M14.5 5.75h2.333c.645 0 1.167.522 1.167 1.167v9.916c0 .645-.522 1.167-1.167 1.167H5.167A1.167 1.167 0 0 1 4 16.833V6.917c0-.645.522-1.167 1.167-1.167H7.5V4.583a.583.583 0 1 1 1.167 0V5.75h4.666V4.583a.583.583 0 0 1 1.167 0V5.75Zm0 1.167v1.166a.583.583 0 0 1-1.167 0V6.917H8.667v1.166a.583.583 0 1 1-1.167 0V6.917H5.167v9.916h11.666V6.917H14.5ZM5.167 11h11.666v1.167H5.167V11Z"
        fill="#CCCCD8"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
