import { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Managers = memo(({ addressList, showBonus }) => {
  const { t } = useTranslation();
  return (
    <ManagerStyled>
      <ManagerTitle>{t("Left.Managers")}</ManagerTitle>
      <ul className="font-medium">
        {addressList.map((gp, i) => (
          <GPItem key={i}>
            <div className="block name-block">{gp.name}</div>
            <div className="block address-block">{gp.address}</div>
            {showBonus && (
              <>
                <div className="block bonus-block">{gp.bonus}</div>
                <div className="bonus-symbol">%</div>
              </>
            )}
          </GPItem>
        ))}
      </ul>
    </ManagerStyled>
  );
});

export default Managers;

const ManagerStyled = styled.div``;
const ManagerTitle = styled.div`
  font-family: "Poppins-Medium";
  margin-bottom: 12px;
  font-size: 16px;
`;

const GPItem = styled.li`
  display: flex;
  margin-bottom: 14px;
  .block {
    line-height: 44px;
    text-align: center;
    height: 44px;
    background: rgba(4, 23, 53, 0.2);
    border-radius: 10px;
  }
  .name-block {
    width: 150px;
  }
  .address-block {
    width: 400px;
    margin-left: 8px;
    margin-right: 14px;
  }
  .bonus-block {
    width: 73px;
  }
  .bonus-symbol {
    margin-left: 20px;
    line-height: 44px;
  }
`;
