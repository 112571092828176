import { useCallback, useState, memo } from "react";
import { Tooltip } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { UserTxSTATUS } from "../TxDetail";
import EmptyBox from "components/common/empty";

import Tip from "assets/images/Tip.svg";
import { useLazyQuery } from "@apollo/client";
import { SubgraphApiQuery, getWctHistry } from "api/graphql/subQuery";
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDaoContext } from "views/dao/provider";
import PaginationBox from "components/common/pagination";
import useCheckLogin from "hooks/useCheckLogin";
import { useTranslation } from "react-i18next";
import { useNetworkData } from "hooks/useNetwork";
import { IndexTag } from "assets/styles/common";

import PassIcon from "assets/images/vote/pass.svg";
import RejectIcon from "assets/images/vote/reject.svg";
import { formatTextTime } from "utils/utils";
import { useWCTContext } from "components/provider/WCTProvider";
import { GlassBG } from "assets/styles/common";

export default function TxList(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fullhandle, network: networkSimple } = useParams();
  const { chainId } = useWeb3React();
  const isLogin = useCheckLogin();
  const network = useNetworkData(chainId);

  const {
    state: {
      daoId,
      whoAmI: { isOwner, isGP },
    },
  } = useDaoContext();

  const { dispatch: dispatchEvent } = useWCTContext();

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [list, setList] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const toGo = (id) => {
    navigate(
      `/${networkSimple}/${fullhandle}/wallet_connect?tab=history&tx=${id}`,
      {
        state: "history",
      }
    );
  };

  const VoteStatus = memo(({ status }) => {
    switch (status) {
      case UserTxSTATUS.Confirmed:
        return (
          <>
            <img className="confirm" src={PassIcon} alt="" />
            <span className="success">{t("WCT.Success")}</span>
          </>
        );
      case UserTxSTATUS.Rejected:
        return (
          <>
            <img className="reject" src={RejectIcon} alt="" />
            <span className="rejected">{t("WCT.Rejected")}</span>
          </>
        );
      default:
        return <></>;
    }
  });

  const [getHistory, { loading }] = useLazyQuery(SubgraphApiQuery, {
    onCompleted(resp) {
      try {
        const data = JSON.parse(resp.subgraphApi).data;
        console.log("====", data);
        const lst = data.transactionRecords || [];
        lst.forEach((t) => {
          t.createAt = formatTextTime(Number(t.createAt));
        });
        setList(lst);
        setTotal(data.totalCount?.count);
      } catch (error) {
        console.error(error);
      }
    },
    onError(error) {},
  });

  useEffect(() => {
    if (!chainId || !daoId) {
      return;
    }
    if (!isLogin) {
      return;
    }
    getHistory({
      variables: {
        query: getWctHistry(daoId, page, pageSize),
        chainId,
      },
      fetchPolicy: "network-only",
    });
  }, [chainId, page, pageSize, daoId, isLogin]);

  useEffect(() => {
    if (loading) {
      dispatchEvent({ type: "SWITCH_LOADING", payload: true });
    } else {
      dispatchEvent({ type: "SWITCH_LOADING", payload: false });
    }
  }, [loading]);

  const onChangePage = useCallback((v) => {
    setPage(v);
  }, []);

  const onShowSizeChange = useCallback((c, size) => {
    setPageSize(size);
  }, []);

  return (
    <TableBox>
      <GlassBG />
      <table cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <th>{t("WCT.No")}</th>
            <th>{t("WCT.Source")}</th>
            <th>{t("WCT.Created")}</th>
            <th className="Left">
              {t("WCT.CR")}
              <Tooltip title={t("WCT.tip")}>
                <img className="tip" src={Tip} alt="" />
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((tx, index) => (
            <tr key={`history_${index}`} onClick={() => toGo(tx.txID)}>
              <td>
                <IndexTag>{tx.txID}</IndexTag>
              </td>
              <td>
                <span>
                  {tx.source?.length > 30
                    ? tx.source.slice(0, 30) + "..."
                    : tx.source}
                </span>
              </td>
              <td>{tx.createAt}</td>
              <td className="Left">
                <StatusBox>
                  <span className="percent">
                    {tx.agree_count} : {tx.oppose_count}
                  </span>{" "}
                  <VoteStatus status={tx.state} />
                </StatusBox>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination>
        <PaginationBox
          showQuickJumper
          pageSize={pageSize}
          defaultCurrent={page}
          total={total}
          onChange={onChangePage}
          onShowSizeChange={onShowSizeChange}
        />
      </Pagination>
      {!list.length && <Empty pure={true} />}
    </TableBox>
  );
}

const TableBox = styled.div`
  position: relative;
  padding-bottom: 30px;
  min-height: calc(100vh - 500px);
  table {
    width: 100%;
    margin-bottom: 12px;
    position: relative;
  }
  th {
    height: 65px;
    color: #328aff;
    white-space: nowrap;
    &:first-child {
      padding-left: 30px;
      text-align: left;
    }
    &.status {
      text-align: left;
      margin-left: 70px;
    }
    .tip {
      position: relative;
      top: 7px;
      left: 5px;
      opacity: 0.5;
      width: 24px;
    }
  }

  td {
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    height: 70px;
    font-weight: 400;

    &:first-child {
      padding-left: 30px;
    }
  }
  .Left {
    text-align: left;
    padding-left: 30px;
  }
  .percent {
    width: 80px;
    text-align: center;
    display: inline-block;
  }
  .symbol {
    width: 46px;
    height: 46px;
    border-radius: 46px;
    margin: 8px 0;
  }
  .symbolLft {
    width: 22px;
    height: 22px;
    border-radius: 22px;
    margin: 0 3px;
  }
  .edit {
    margin-right: 12px;
  }
  tbody tr:nth-child(2n + 1) {
    background: rgba(255, 255, 255, 0.04);
  }
`;

const Pagination = styled.div`
  text-align: right;
  margin: 0 40px;
`;

const StatusBox = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
  .success {
    color: #79dfc4;
    font-size: 12px;
  }
  .rejected {
    color: #ffa4d1;
    font-size: 12px;
  }
`;

const Empty = styled(EmptyBox)`
  position: relative;
  min-height: unset;
  padding-top: 30px;
`;
