import styled from "styled-components";
import Empty from "assets/images/create/img.svg";
import { useAppContext } from "components/provider/appProvider";
import { uploadByFetch } from "api/graphql/dao";
import { useMemo, useState, useEffect } from "react";
import ImageCropper from "components/common/imageCropper";
import axios from "axios";
import LoadingNew from "components/loadingNEW";

const UploadLogo = ({ t, path, blob, onChange }) => {
  const {
    state: { gateway },
    dispatch,
  } = useAppContext();

  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [imgData, setImgData] = useState("");

  const getBase64 = async (url) => {
    try {
      setLoading(true);
      setImgData("");
      const response = await axios.get(url, {
        responseType: "arraybuffer",
      });
      const bs64 = Buffer.from(response.data, "binary").toString("base64");
      setImgData(`data:image/png;base64,${bs64}`);
    } catch (error) {
      
    } finally {
      setLoading(false)
    }
    
  }

  const checkFile = (f) => { 
    if (f.type === "image/gif") {
      uploadAction(f);
      return true;
    }
    if (f.type !== "image/jpeg" && f.type !== "image/png") {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Upload.NFTErrorType"), closable: true },
      });
      return true
    }
    // 1M = 1024KB
    // f.size = x bytes
    if (f.size / 1024 > 102400) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: `${t("Create.NFTLimit", {size: "100 MB"})}`, closable: true },
      });
      return true
    }
  };
  const closeCropper = () => {
    setFile();
  };
  
  const uploadAction = async (blob) => {
    try {
      dispatch({ type: "LOADINGTYPE", payload: t("Create.UploadLogo") });
      const resp = await uploadByFetch(blob);
      if (resp.status === 200) {
        const data = await resp.json();
        
        const reader = new FileReader();
        reader.onload = function (e) {
          onChange && onChange(data.data.fileUpload, e.target.result);
        };
        reader.readAsDataURL(blob);

      } else {
        throw new Error(`status is ${resp.status}`);
      }
      closeCropper();
    } catch (error) {
      console.error("Upload logo failed: ", error);
      dispatch({
        type: "MSGTYPE",
        payload: { msg: `${t("Create.UploadLogoFailed")}`, closable: true },
      });
    } finally {
      dispatch({ type: "LOADINGTYPE", payload: null });
    }
  }

  const onChangeImage = async (e) => {
    const files = e.target.files;
    if (files == null) return;
    const f = files[0];
    if (checkFile(f)) {
      return;
    }
    setFile(f);
  };

  const imgPath = useMemo(() => {
    if (loading) {
      return ""
    }
    if (imgData) {
      return imgData;
    }
    return gateway && path ? gateway + path : "";
  }, [path, gateway, loading]);

  useEffect(() => {
    if (blob) {
      setImgData(blob)
    } else if (path && gateway) {
      getBase64(gateway + path);
    } 
  }, [path, gateway, blob]);

  return (
    <Logo className="imgBox" path={imgPath} loading={loading}>
      {file && (
        <ImageCropper
          handleCloseCropper={closeCropper}
          file={file}
          uploadAction={uploadAction}
          t={t}
        />
      )}
      <label htmlFor="file-input" />
      <input
        type="file"
        id="file-input"
        onChange={onChangeImage}
        accept="image/*"
      />
      {loading && (
        <LoadingNew
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "250px",
            height: "250px",
            minHeight: "unset",
          }}
        />
      )}
    </Logo>
  );
};

export default UploadLogo;

const Logo = styled.div`
  width: 254px;
  height: 254px;
  border-radius: 16px;
  position: relative;
  background: ${({ path, loading }) =>
    `rgba(4, 23, 53, 0.5) url(${loading ? "" : path ? path : Empty}) no-repeat center center`};
  background-size: ${({ path }) => (path ? "contain" : "64px")};
  #file-input {
    display: none;
  }
  label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .loading .ant-spin-dot-item {
    background-color: #666;
  }
`;
