import { memo } from "react";
import styled from "styled-components";
import SwapIndex from "./swapIndex";
import BackNav from "components/common/backNav";
import Pending from "./pending";
import SwapStatus from "./statusModal";
import { useSwapContext } from "components/provider/swapProvider";

import SwapProvider from "components/provider/swapProvider";
import { useTranslation } from "react-i18next";
import { GlassBG } from "assets/styles/common";

const CenterContent = memo(() => {
  const {
    state: { currentTx },
  } = useSwapContext();
  return currentTx ? <SwapStatus /> : <SwapIndex />;
})

export default function Swap() {
  const { t } = useTranslation();
  return (
    <SwapProvider>
      <Box>
        <BackNav to="app_settings">
          <Pending />
        </BackNav>
        <ContentBox id="contentBox">
          <GlassBG />
          <div id="inner">
            <CenterContent />
          </div>
        </ContentBox>
      </Box>
    </SwapProvider>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 24px;
`;

const ContentBox = styled.div`
  flex-grow: 1;
  position: relative;
  #inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
  }
`;
