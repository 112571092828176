import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path opacity={0.405} d="M0 0h32v32H0z" />
      <path
        d="M22.636 10.408a1 1 0 0 1 0 1.414L17.958 16.5l4.678 4.679a1 1 0 0 1 0 1.414l-.544.544a1 1 0 0 1-1.414 0L16 18.457l-4.678 4.679a1 1 0 0 1-1.414 0l-.544-.544a1 1 0 0 1 0-1.414l4.678-4.679-4.678-4.677a1 1 0 0 1 0-1.414l.544-.544a1 1 0 0 1 1.414 0L16 14.541l4.678-4.677a1 1 0 0 1 1.414 0l.544.544Z"
        fill="#CCCCD8"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
