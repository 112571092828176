import { ethers } from "ethers";
import { VOTE_TYPE } from "./constant";

export const parseAddManagerVote = (bytes) => {
  return ethers.utils.defaultAbiCoder.decode(
    ["address", "string", "uint256", "uint256", "address[]", "uint256[]"],
    bytes
  );
};

export const parseVoteData = (data) => {
  const iface = new ethers.utils.Interface([
    "function action(bytes calldata data)",
  ]);
  const decodeData = iface.decodeFunctionData("action", data);
  const actionData = ethers.utils.defaultAbiCoder.decode(
    ["address", "bytes"],
    decodeData.data
  );

  const voteBytes = actionData[1];
  let voteType;

  switch (actionData[0]) {
    case window.mainAddress.actionOrgAcceptGPExe:
      voteType = VOTE_TYPE.ADD_MANAGER;
      break;
    case window.mainAddress.actionOrgImpeachGPExe:
      voteType = VOTE_TYPE.REMOVE_MANAGER;
      break;
    case window.mainAddress.actionGrantMethodExe:
      voteType = VOTE_TYPE.APPROVE_MANAGER;
      break;
    case window.mainAddress.actionVaultTransferExe:
      voteType = VOTE_TYPE.TRANSFER_TOKEN;
      break;
    case window.mainAddress.actionVaultTransferNFTExe:
      voteType = VOTE_TYPE.TRANSFER_NFT;
      break;
    case window.mainAddress.actionManageFeeTransferExe:
      voteType = VOTE_TYPE.TRANSFER_MANAGE_FEE;
      break;
    case window.mainAddress.actionVentureTransferPeriodExe:
      voteType = VOTE_TYPE.TRANSFER_PERIOD;
      break;
    case window.mainAddress.actionOrgUpdateMinRequireExe:
      voteType = VOTE_TYPE.MODIFY_MULTISIGN;
      break;
    default:
      break;
  }
  return { voteBytes, voteType };
};
