import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import ClaimModal from "../modal/claimModal";
import { ContractVestingType, MIN_DURATION } from "utils/constant";
import {
  LinerReleaseTooltip,
  StageReleaseTooltip,
} from "components/modal/releaseTooltip";
import { PERIOD_NO } from "utils/constant";
import { useAppContext } from "components/provider/appProvider";
import useCheckLogin from "hooks/useCheckLogin";

import CardLayout from "./cardLayout";
import { useDaoContext } from "views/dao/provider";

const ClaimCard = ({ t, ventureContract, fundToken, stockToken, period, isReady }) => {
  const [showClaim, setShowClaim] = useState(false);

  const [vestingType, setVestingType] = useState();
  const [vestingData, setVestingData] = useState();

  const [vestingTitle, setVestingTitle] = useState("");
  const [vestingSubText, setVestingSubText] = useState("");
  const [vestingTypeText, setVestingTypeText] = useState("");

  const { dispatch } = useAppContext();
  const { dispatch: dispatchEvent } = useDaoContext();
  const isLogin = useCheckLogin();

  const formatTime = (time) => {
    const m = moment(time * 1000);
    return m.format("YYYY/MM/DD HH:mm:ss");
  };

  const getVest = async () => {
    const vesting = await ventureContract.vesting();
    console.log("Vesting:", vesting);
    const type = vesting.vestingType;
    setVestingType(type);
    if (type === ContractVestingType.OneTimeRelease) {
      const time = formatTime(vesting.endTime.toNumber());
      setVestingData({
        endDate: time,
      });
      setVestingSubText(time);
      setVestingTitle(t("Create.VestingDate"));
      setVestingTypeText(t("Create.OneTimeRelease"));
    } else if (type === ContractVestingType.LinearRelease) {
      setVestingData({
        startDate: formatTime(vesting.startTime.toNumber()),
        endDate: formatTime(vesting.endTime.toNumber()),
      });
      setVestingSubText(
        t("Create.Days", {
          period: parseInt(
            ((vesting.endTime.toNumber() - vesting.startTime.toNumber()) *
              1000) /
              MIN_DURATION
          ),
        })
      );
      setVestingTitle(t("Create.VestingPeriod"));
      setVestingTypeText(t("Create.LinerRelease"));
    } else if (type === ContractVestingType.StagedRelease) {
      const data = await ventureContract.getScheduleItem();
      let prevSum = 0;
      const lst = data.map((d) => {
        const currentPercent = d.percent.toNumber();
        const usePercent = currentPercent - prevSum;
        prevSum = currentPercent;
        return {
          date: formatTime(d.timeStamp.toNumber()),
          ratio: `${usePercent / 100}%`,
        };
      });
      setVestingData(lst);
      setVestingSubText(t("Create.StageLength", { len: lst.length }));
      setVestingTitle(t("Create.VestingPeriod"));
      setVestingTypeText(t("Create.StagedRelease"));
    }
    dispatchEvent({ type: "SET_CLAIM_STATUS", payload: true });
  };

  const releaseTip = useMemo(() => {
    switch (vestingType) {
      case ContractVestingType.LinearRelease:
        return (
          <LinerReleaseTooltip
            t={t}
            start={vestingData.startDate}
            end={vestingData.endDate}
          />
        );
      case ContractVestingType.StagedRelease:
        return <StageReleaseTooltip t={t} periods={vestingData} />;
      default:
        return <></>;
    }
  }, [vestingData]);

  useEffect(() => {
    if (!ventureContract) {
      return;
    }
    getVest();
  }, [ventureContract]);

  const openClaim = () => {
    if (period === PERIOD_NO.RAISE) {
      dispatch({
        type: "MSGTYPE",
        payload: { msg: t("Msg.NotManagemetPeriod") },
      });
      return;
    }
    setShowClaim(true);
  };

  return (
    <CardLayout isReady={isReady}>
      {showClaim && (
        <ClaimModal
          show={showClaim}
          ventureContract={ventureContract}
          fundToken={fundToken}
          stockToken={stockToken}
          vestingType={vestingType}
          vestingData={vestingData}
          closeModal={() => setShowClaim(false)}
        />
      )}
      <Content>
        {releaseTip}
        <div className="time">{vestingSubText}</div>
        <div className="release">
          <span>{vestingTypeText}</span>
        </div>
      </Content>
      <Bottom>
        <Title>{vestingTitle}</Title>
        {isLogin && (
          <ClaimButton onClick={openClaim}>{t("Claim.Claim")}</ClaimButton>
        )}
      </Bottom>
    </CardLayout>
  );
};

export default ClaimCard;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding-top: 26px;
  .time {
    font-family: "Rubik-Medium";
    font-size: 22px;
    line-height: 20px;
    height: 20px;
  }
  .release {
    height: 20px;
    margin-top: 12px;
    margin-bottom: 23px;
  }
  .infoIcon {
    position: absolute;
    right: 0;
    top: 0;
    .tipIcon {
      width: 24px;
      opacity: 0.75;
    }
    &:hover {
      .releaseTip {
        display: block;
      }
    }
  }
`;

const ClaimCardStyled = styled.li`
  flex-direction: column;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Title = styled.div`
  font-weight: 400;
  margin-right: 15px;
  opacity: 0.5;
`;

const ClaimButton = styled.div`
  width: 68px;
  height: 24px;
  line-height: 24px;
  background: #ffa4d1;
  border-radius: 13px;
  color: #041735;
  text-align: center;
  cursor: pointer;
`;
