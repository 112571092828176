import { useMemo } from "react";
import { useAppContext } from "components/provider/appProvider";
import { useDaoContext } from "views/dao/provider";

export default function useFundLogo() {
  const {
    state: { fundList },
  } = useAppContext();
  const {
    state: { fundToken },
  } = useDaoContext();
  return useMemo(() => {
    if (!fundToken || !fundList.length) {
      return "";
    }
    const token = fundList.find((f) => f.address.toLowerCase() === fundToken.address.toLowerCase());
    return token?.logo;
  }, [fundList, fundToken]);
}
