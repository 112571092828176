import {
  memo,
  useMemo,
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDaoContext } from "views/dao/provider";
import useVaultTokens from "hooks/useVaultTokens";
import { VAULT_MANAGER } from "utils/constant";
import { ethers } from "ethers";
import { useAppContext } from "components/provider/appProvider";
import BigNumber from "bignumber.js";
import AmountBox from "../components/amountBox";
import Max from "components/common/max";
import { Label, VoteItem } from "../components/style";
import TokenSelect from "components/common/tokenBalanceSelect";

const TransferToken = memo(
  forwardRef((props, ref) => {
    const { t } = useTranslation();

    const { dispatch } = useAppContext();
    const { state: dapState } = useDaoContext();
    const { componentAddressMap } = dapState;

    const { tokens, loading } = useVaultTokens(
      componentAddressMap.get(VAULT_MANAGER)
    );
    const [tokenAmount, setTokenAmount] = useState();

    const [currentToken, setCurrentToken] = useState();

    const list = useMemo(() => {
      return tokens ? tokens.map((t) => ({ ...t })) : [];
    }, [tokens]);

    const chooseToken = useCallback((t) => {
      setCurrentToken(t);
    }, []);

    const setMaxAmount = useCallback(() => {
      if (currentToken) {
        setTokenAmount(currentToken.balance);
      }
    }, [currentToken]);

    const updateTokenAmount = useCallback(
      (e) => {
        const arr = e.target.value.split(".");
        if (arr[1] && arr[1].length > currentToken.deci) {
          setTokenAmount(`${arr[0]}.${arr[1].slice(0, currentToken.deci)}`);
        } else {
          setTokenAmount(e.target.value);
        }
      },
      [currentToken]
    );

    useImperativeHandle(ref, () => ({
      data: currentToken && {
        tokenAddress: currentToken.address,
        tokenAmount: ethers.utils.parseUnits(
          tokenAmount || "0",
          currentToken.deci
        ),
      },
      checkValid: () => {
        if (
          !tokenAmount ||
          !currentToken ||
          BigNumber(currentToken.balance).lt(BigNumber(tokenAmount))
        ) {
          dispatch({
            type: "MSGTYPE",
            payload: { msg: t("Msg.InvalidAmount"), closable: true },
          });
          return;
        }
        return true;
      },
    }));

    return (
      <VoteItem>
        <Label>{t("Vote.Amount")}</Label>
        <TokenBox>
          <TokenSelect
            list={list}
            selected={currentToken}
            onSelect={chooseToken}
          />
          <AmountBox
            value={tokenAmount}
            onChange={updateTokenAmount}
            className="amountBox"
          >
            <SymbolBox>
              <span className="symbol">
                {currentToken && currentToken.symbol}
              </span>
              <Max t={t} onMax={setMaxAmount} />
            </SymbolBox>
          </AmountBox>
        </TokenBox>
      </VoteItem>
    );
  })
);

export default TransferToken;

const TokenBox = styled.div`
  display: flex;
  & > div {
    flex: 1;
  }
  .amountBox {
    margin-left: 20px;
  }
`;
const SymbolBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  .symbol {
    opacity: 0.5;
    margin-right: 8px;
  }
`;