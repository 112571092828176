import styled, { css } from "styled-components";

export const InputBackground = css`
  background: rgba(4, 23, 53, 0.5);
  backdrop-filter: blur(12px);
  border-radius: 10px;
  padding: 0 20px;
  line-height: 44px;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
`;

export const VoteInputBg = css`
  background-color: #1e2b3f;
  border-radius: 10px;
  padding: 0 20px;
  line-height: 44px;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
`;

export const InputLabel = css`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 12px;
`;

export const GlassBG = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  z-index: 0;
`;

export const IndexTag = styled.div`
  width: 28px;
  height: 20px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  text-align: center;
  font-size: 10px;
  font-family: "Rubik-Medium";
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
`;

export const AnimationBox = styled.div`
  overflow: hidden;
  width: 0;
  &.animation {
    animation: 0.5s ease-in-out 1 fromleft;
    will-change: grow;
    animation-fill-mode: forwards;
  }
  @keyframes fromleft {
    from {
      width: 0;
    }
    to {
      width: 100%;
      overflow: visible;
    }
  }
`;