import styled from "styled-components";
import {useState} from "react";
import OpenImg from "../../assets/images/Open.svg";

const Box = styled.div`
  position: relative;
  min-height: 42px;
`

const Nor = styled.div`
  background: rgba(255,255,255,0.08);
  border-radius: 20px;
  border: 1px solid rgba(255,255,255,0.08);
  padding: 9px 20px 9px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span{
    display: inline-block;
    padding: 0 20px;
  }
  img{
    width: 20px;
    opacity: 0.32;
  }
`
const DropDown = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background: rgba(255,255,255,0.08);
  border-radius: 8px;
  border: 1px solid rgba(255,255,255,0.08);
  backdrop-filter: blur(16px);
  padding: 12px 16px;
  .tit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,0.08);
    padding: 0 10px 10px;
  }
  ul{
    margin-top: 5px;
  }
  li{
    line-height: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 5px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    &:hover{
      background:rgba(255,255,255,0.1);
    }
  }
`

export default function SelectBox(){
    const [ show, setShow ] = useState(false);
    const [ list] = useState(["In management","In deposit"]);
    const [current,setCurrent] = useState(null)

    const handleShow = () =>{
        setShow(true);
    }
    const handleClose= (index) =>{
        setShow(false);
        setCurrent(index);
    }

    return <Box>
        {
            !show &&<Nor onClick={()=>handleShow()}>
                <span>{current == null ? "Date Create": list[current]}</span>
                <img src={OpenImg} alt=""/>
            </Nor>
        }
        {
            show &&<DropDown>
                <div className="tit">
                    <span>Date Create</span>
                </div>
                <ul>
                    {
                        list.map((item,index)=>(<li onClick={()=>handleClose(index)} key={index}>{item}</li>))
                    }
                </ul>
            </DropDown>
        }

    </Box>
}