import { useEffect } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import {
  useConnectors,
  getConnectorForWallet,
} from "components/wallet/connector";
import { useAuthContext } from "./authProvider";

const AUTH_WALLET = "SUBDAO_WALLET";

const connect = async (connector) => {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate();
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`);
  }
};

export default function Web3Provider(props) {
  const selectedWallet = localStorage.getItem(AUTH_WALLET)

  const connectors = useConnectors(selectedWallet);

  // const isMetaMask = !!window.ethereum?.isMetaMask;

  useEffect(() => {
    // if (isMobile && isMetaMask) {
    //   injected.activate()
    // } else
    if (selectedWallet) {
      connect(getConnectorForWallet(selectedWallet));
    }
  }, []);

  return (
    <Web3ReactProvider connectors={connectors}>
      {props.children}
    </Web3ReactProvider>
  );
}
