import { gql } from "@apollo/client";
import { DEV_ENDPOINT, PROD_ENDPOINT } from "utils/constant";

export const GetGateway = gql`
  query MyQuery {
    gateway
  }
`;

// chianId, handle
export const QueryDaoByHandle = gql`
  query MyQuery($chainId: Int!, $handle: String!) {
    is_like: checkDaoLikedByHandleAndChainId(chainID: $chainId, handle: $handle)
    dao: daoByHandleAndChainId(chainID: $chainId, handle: $handle) {
      dao_id
      logo
      name
      desc
      handle
      twitter_url
      discord_url
      telegram_url
      et_holders
      period
      tav
      ventures_addr
      vault_addr
      raised_funds
      fund_token_address
      fund_token_decimal
      fund_token_name
      fund_token_symbol
      et_token_address
      et_token_decimal
      et_token_name
      et_token_symbol
      et_token_type
      et_token_logo
      dao_type
      dao_version
    }
  }
`;

export const QueryUnAuthDaoByHandle = gql`
  query MyQuery($chainId: Int!, $handle: String!) {
    dao: daoByHandleAndChainId(chainID: $chainId, handle: $handle) {
      dao_id
      logo
      name
      desc
      handle
      twitter_url
      discord_url
      telegram_url
      et_holders
      period
      tav
      ventures_addr
      vault_addr
      raised_funds
      fund_token_address
      fund_token_decimal
      fund_token_name
      fund_token_symbol
      et_token_address
      et_token_decimal
      et_token_name
      et_token_symbol
      et_token_type
      et_token_logo
      dao_type
    }
  }
`;

export const QueryDaos = gql`
  query MyQuery($chainId: Int!, $daoIds: [Int!]) {
    daoListByIds(chainID: $chainId, daoIDs: $daoIds) {
      handle
      et_holders
      logo
      name
      period
      tav
    }
  }
`;

// 02-1-1
export const CheckHandleUnique = gql`
  query MyQuery($chainId: Int!, $handle: String!, $isCreate: Boolean!) {
    checkDaoHandleAvailable(req: { chain_id: $chainId, handle: $handle, is_create_dao: $isCreate })
  }
`;

export const QueryProjects = gql`
  query MyQuery($chainId: Int!, $venture: String!, $page: Int!, $size: Int!) {
    projects(
      cond: { chain_id: $chainId, ventures_contract_address: $venture }
      page: { page: $page, size: $size }
    ) {
      data {
        id
        amount
        logo
        name
        round
        valuationAmount
        valuationToken
      }
    }
  }
`;


export const RefreshTav = gql`
  query MyQuery($chainId: Int!, $daoId: Int!) {
    refreshTAV(chainID: $chainId, daoID: $daoId, daoVersion: 3)
  }
`

export const TavGraph = gql`
  query MyQuery($chainId: Int!, $daoId: Int!, $scope: String!) {
    daoTav(chainID: $chainId, daoID: $daoId, scope: $scope, daoVersion: 3) {
      date
      value
    }
  }
`;

export const DaoNFTs = gql`
  query MyQuery($chainId: Int!, $daoId: Int!) {
    daoNfts(chainID: $chainId, daoID: $daoId, daoVersion: 3) {
      amount
      contract_address
      contract_name
      id
      is_erc721
      price
      metadata_json
      image_uri
    }
  }
`;

export const QueryTransferList = gql`
  query Myquery($chainId: Int!, $vaultAddress: String!) {
    nftTransferHistory(
      chainID: $chainId
      limit: 1000
      nextPageKey: ""
      vaultContractAddr: $vaultAddress
    ) {
      next_page_key
      total
      data {
        amount
        contract_name
        erc_type
        from
        contract_address
        to
        timestamp
        token_id
        txHash
      }
    }
  }
`;

/******** MUTATION ********/

export const createDaoV2 = gql`
  mutation MyMutation(
    $chainId: Int!
    $owner: String!
    $handle: String!
    $name: String!
    $logo: String
    $desc: String!
    $twitter_url: String
    $discord_url: String
    $telegram_url: String
    $daoType: Int!
  ) {
    createDaoV2(
      req: {
      chain_id: $chainId
      owner_addr: $owner
      handle: $handle
      name: $name
      desc: $desc
      logo: $logo
      twitter_url: $twitter_url
      discord_url: $discord_url
      telegram_url: $telegram_url
      dao_version: 3
      dao_type: $daoType
    }
    ) {
      handle
    }
  }
`;
export const BindDaoV2 = gql`
  mutation MyMutation(
    $daoId: Int!
    $chainId: Int!
    $handle: String!
    $daoType: Int!
  ) {
    bindDaoV2(
      req: {
        dao_id: $daoId
        chain_id: $chainId
        handle: $handle
        dao_version: 3
        dao_type: $daoType
      }
    )
  }
`;

// 02-2-1
export const CreateDao = gql`
  mutation MyMutation(
    $daoId: Int!
    $chainId: Int!
    $logo: String
    $desc: String!
    $handle: String!
    $name: String!
  ) {
    createDao(
      req: {
        dao_id: $daoId
        chain_id: $chainId
        logo: $logo
        name: $name
        desc: $desc
        handle: $handle
      }
    ) {
      handle
    }
  }
`;

// 02-2-2
export const UpdateDao = gql`
  mutation MyMutation(
    $daoId: Int!
    $chainId: Int!
    $logo: String
    $desc: String!
    $name: String!
    $handle: String!
    $twitter_url: String
    $discord_url: String
    $telegram_url: String
  ) {
    updateDao(
      req: {
        dao_id: $daoId
        dao_version: 3
        chain_id: $chainId
        logo: $logo
        name: $name
        desc: $desc
        handle: $handle
        twitter_url: $twitter_url
        discord_url: $discord_url
        telegram_url: $telegram_url
      }
    )
  }
`;

// 02-2-3
export const LikeDao = gql`
  mutation MyMutation($daoId: Int!, $chainId: Int!, $isLike: Int!) {
    likeDao(
      req: {
        dao_id: $daoId
        chain_id: $chainId
        is_like: $isLike
        dao_version: 3
      }
    )
  }
`;

export const FileUpload = gql`
  mutation MyMutation($file: Upload!) {
    fileUpload(file: $file)
  }
`;

export const uploadByFetch = (fileData, chain_id) => {
  const formData = new FormData();

  const query =
    "mutation MyMutation ($file: Upload!) { fileUpload(file: $file) }";
  const variables = { file: null };

  formData.append("operations", JSON.stringify({ query, variables }));
  formData.append("map", JSON.stringify({ 0: ["variables.file"] }));
  formData.append("0", fileData);

  return fetch(process.env.NODE_ENV === "production" ? PROD_ENDPOINT : DEV_ENDPOINT, {
    method: "POST",
    body: formData,
  });
};

// 00-2-1
export const CreateProject = gql`
  mutation MyMutation(
    $logo: String!
    $name: String!
    $amount: Float!
    $round: String!
    $valuationAmount: Float!
    $valuationToken: String!
    $timestamp: Int!
    $sign: String!
    $chainId: Int!
    $venture: String!
  ) {
    createProject(
      project: {
        logo: $logo
        name: $name
        amount: $amount
        round: $round
        valuationAmount: $valuationAmount
        valuationToken: $valuationToken
        timestamp: $timestamp
      }
      sign: {
        sign: $sign
        chain_id: $chainId
        ventures_contract_address: $venture
      }
    ) {
      id
    }
  }
`;

export const UpdateProject = gql`
  mutation MyMutation(
    $id: String!
    $logo: String!
    $name: String!
    $round: String!
    $amount: Float!
    $valuationAmount: Float!
    $valuationToken: String!
    $timestamp: Int!
    $venture: String!
    $sign: String!
    $chainId: Int!
  ) {
    updateProject(
      project: {
        logo: $logo
        name: $name
        amount: $amount
        round: $round
        valuationAmount: $valuationAmount
        valuationToken: $valuationToken
        timestamp: $timestamp
      }
      projectID: $id
      sign: {
        sign: $sign
        chain_id: $chainId
        ventures_contract_address: $venture
      }
    )
  }
`;

export const DeleteProject = gql`
  mutation MyMutation(
    $id: String!
    $timestamp: Int!
    $venture: String!
    $sign: String!
    $chainId: Int!
  ) {
    deleteProject(
      projectID: $id
      timestamp: $timestamp
      sign: {
        sign: $sign
        chain_id: $chainId
        ventures_contract_address: $venture
      }
    )
  }
`;
