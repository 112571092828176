import styled from "styled-components";
import RectImg from "img/Rectangle.png";
import InfoImg from "img/note.png";
import { Tooltip } from "antd";
import { useState, useEffect, useCallback, memo } from "react";
import { ethers } from "ethers";

import BorrowModal from "./modals/borrowModal";
import { useDefiContext } from "components/provider/defiProvider";
import { getShortDisplay } from "utils/publicJs";
import { getValueDisplay } from "utils/aave";
import { useMemo } from "react";
import Bignumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import Button, { ButtonGroup } from "components/common/button";
import LoadingBox from "components/common/loading";
import PoolABI from "abi/web3Abi/ILendingPool.json";
import { useWeb3React } from "@web3-react/core";
import { useNetworkData } from "hooks/useNetwork";
import SLoading from "components/common/sloading";

const AssetsToBorrow = memo(() => {
  const {
    state: { reserves, userData, reqState },
  } = useDefiContext();
  const { t } = useTranslation();

  const { provider, chainId } = useWeb3React();
  const networkData = useNetworkData(chainId);

  const [list, setList] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [openReserve, setOpenReserve] = useState({});
  const [stableBorrowEnable, setStableBorrowEnable] = useState();
  const [loadingMap, setLoadingMap] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setOpenReserve({});
  };

  const openBorrow = async (reserve) => {
    if (!reserve.canBorrow) {
      return;
    }
    setOpenReserve(reserve);
    setShowModal(true);
  }
  const getStableEnable = async (lst) => {
    setLoadingMap(true);
    const contract = new ethers.Contract(
      networkData.aaveLendingPool,
      PoolABI,
      provider
    );
    const request = lst.map((a) =>
      contract.getReserveData(a)
    );
    Promise.all(request).then(res => {
      const m = {}
      res.forEach((data, i) => {
        const bitmask = data.configuration.data.toString();
        m[lst[i]] = !bitmask.substr(16, 1);
      })
      setStableBorrowEnable(m);
    }).catch(err => {
      console.error("getReserveData failed",  err);
    }).finally(() => {
      setLoadingMap(false);
    });
  }

  useEffect(async () => {
    if (!reserves.length || !userData.availableBorrowsETH) {
      return;
    }
    const availableBorrowsETH = Bignumber(
      userData.availableBorrowsETH.toString()
    );
    const newList = [];
    const addresses = []
    for (const r of reserves) {
      if (r.variableBorrowAPY === 0 || r.stableBorrowAPY === 0) {
        continue;
      }
      const priceInEth = Bignumber(r.price.priceInEth);
      let canBorrowNB = Bignumber("0");
      if (!priceInEth.eq(canBorrowNB)) {
        /**
         * CAUTION: special handle
         * ref: https://github.com/aave/interface/blob/f56e90a8f946ef9780f179030ea72e4076031ab9/src/utils/getMaxAmountAvailableToBorrow.ts#L39
         */
        canBorrowNB = availableBorrowsETH
          .dividedBy(priceInEth)
          .multipliedBy("0.99");
        const display = getShortDisplay(canBorrowNB.toString(), 7);
        newList.push({
          ...r,
          canBorrow: Number(display),
          canBorrowDisplay: display,
          priceInUsd: 0,
          variableBorrowAPYDisplay: getValueDisplay(r.variableBorrowAPY * 100),
          stableBorrowAPYDisplay: getValueDisplay(r.stableBorrowAPY * 100),
        });
        addresses.push(r.underlyingAsset);
      }
    }
    getStableEnable(addresses);
    setList(newList);
  }, [reserves, userData]);

  const showTip = useMemo(() => {
    return !list.find((r) => r.canBorrow > 0);
  }, [list]);

  if (reqState === 2) {
    return <LoadingBox loading={true} />;
  }

  return (
    <>
      {showModal && (
        <BorrowModal
          reserve={openReserve}
          stableEnbale={
            stableBorrowEnable &&
            stableBorrowEnable[openReserve?.underlyingAsset]
          }
          closeModal={closeModal}
          show={showModal}
        />
      )}
      <TableBox>
        {showTip && (
          <NoCanBorrowTip>
            {t("defi.AssetsToBorrow.NoCanBorrowTip")}
          </NoCanBorrowTip>
        )}
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                {t("defi.AssetsToBorrow.Asset")}
              </th>
              <th>{t("defi.AssetsToBorrow.Available")}</th>
              <th>
                <div>
                  {t("defi.AssetsToBorrow.APYVariable")}
                  <Tooltip title={t("defi.AssetsToBorrow.Variable")}>
                    <img src={InfoImg} alt="" />
                  </Tooltip>
                </div>
              </th>
              <th>
                <div>
                  {t("defi.AssetsToBorrow.APYStable")}
                  <Tooltip title={t("defi.AssetsToBorrow.Stable")}>
                    <img src={InfoImg} alt="" />
                  </Tooltip>
                </div>
              </th>
              <th>{t("defi.AssetsToBorrow.Action")}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((r, i) => (
              <tr key={i}>
                <td style={{ textAlign: "left" }} className="info">
                  <img className="logo" src={r.logo} alt="" />
                  <span>{r.symbol}</span>
                </td>
                <td className="number">
                  {r.canBorrowDisplay}
                  {/* <div className="tips font-medium">$8,981.61</div> */}
                </td>
                <td className="number">{r.variableBorrowAPYDisplay}%</td>
                <td className="number">
                  {loadingMap ? (
                    <SLoading />
                  ) : stableBorrowEnable ? (
                    stableBorrowEnable[r.underlyingAsset] ? (
                      `${r.stableBorrowAPYDisplay}%`
                    ) : (
                      "-"
                    )
                  ) : (
                    `${r.stableBorrowAPYDisplay}%`
                  )}
                </td>
                <td>
                  <ButtonGroup>
                    <Button primary onClick={() => openBorrow(r)}>
                      {t("defi.AssetsToBorrow.Borrow")}
                    </Button>
                    <Button className="borderLine">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://app.aave.com/reserve-overview/?underlyingAsset=${r.underlyingAsset}`}
                      >
                        {t("defi.AssetsToBorrow.Details")}
                      </a>
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableBox>
    </>
  );
});

export default AssetsToBorrow;

const TableBox = styled.div`
  position: relative;
  height: 100%;
  table {
    width: 100%;
  }
  th {
    height: 65px;
    color: #328aff;
    font-size: 16px;
    white-space: nowrap;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:first-child {
      padding-left: 30px;
    }
    img {
      margin-left: 6px;
    }
  }
  td {
    text-align: center;
    white-space: nowrap;
    height: 65px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
    }
    &.info {
      text-align: left;
      font-size: 16px;
      display: flex;
      align-items: center;
      line-height: 22px;
    }
    .logo {
      margin-right: 13px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    .tips {
      font-size: 14px;
      font-weight: 500;
      color: #959bb4;
      line-height: 19px;
    }
    &.number {
      font-family: "Rubik-SemiBold";
      font-size: 18px;
      font-weight: 600;
    }
  }
  tbody tr:nth-child(2n + 1) {
    background: rgba(255, 255, 255, 0.04);
  }

  @media (max-width: 1440px) {
    th {
      height: 45px;
    }
  }
`;

const GrayLine = styled.ul`
  display: flex;
  align-items: center;
  height: 55px;
  background: #f5f5f7;
  border-radius: 14px;
  padding: 0 20px;
  li {
    margin-right: 50px;
    font-size: 16px;
    font-weight: 600;
    color: #10164b;
    line-height: 22px;
  }
`;

const NoCanBorrowTip = styled.div`
  height: 36px;
  background: rgba(255, 164, 209, 0.15);
  border-radius: 8px;
  font-size: 16px;
  color: #ffa4d1;
  line-height: 36px;
  padding-left: 18px;
  margin: 0 30px;
  /* margin: 0 25px; */
`;
