import * as React from "react";

const SvgComponent = ({ selected, ...props }) => {
  return selected ? (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0Zm0 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
        fill="#326CFF"
        fillRule="evenodd"
      />
    </svg>
  ) : (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx={606}
        cy={395}
        r={7.5}
        transform="translate(-598 -387)"
        fill="rgba(255,255,255,0.1)"
        stroke="rgba(255,255,255,0.08)"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgComponent;
