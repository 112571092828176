import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ethers } from "ethers";

import { useDaoContext } from "../../views/dao/provider";
import api from "../../api";
import { useDaoOrgContract } from "../../hooks/useContract";
import { DAO_ORG } from "../../utils/constant";
import useSigner from "hooks/useSigner";

import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "components/provider/appProvider";
import useFundLogo from "hooks/useFundLogo";

import showNotification, {
  NotificationType,
} from "components/common/notification";
import { TransType } from "components/transaction/checkTransaction";
import BaseModal from "./general";
import Button from "components/common/button";
import Max from "components/common/max";
import SwapIcon from "assets/images/Swap.svg";
import TipIcon from "assets/images/Remarks.svg";

export default function WithdrawBonus(props) {
  const { show, close } = props;

  const { t } = useTranslation();
  const { provider, account } = useWeb3React();
  const {
    dispatch,
    state: { exploreScan },
  } = useAppContext();
  const { state: daoState } = useDaoContext();
  const { daoId, componentAddressMap, fundToken } = daoState;

  const [amount, setAmount] = useState();
  const [maxAmount, setMaxAmount] = useState(0);

  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const signer = useSigner();

  const handleClose = () => {
    close();
  };

  const confirm = async () => {
    handleClose();
    try {
      const res = await api.venture.withdrawBonus(
        daoOrg,
        signer,
        ethers.utils.parseUnits(String(amount), fundToken.deci)
      );

      const txHash = res.hash;

      const nid = showNotification(
        NotificationType.Loading,
        t("Notification.WithdrawBonus"),
        `${exploreScan}tx/${txHash}`
      );

      dispatch({
        type: "PUT_TRANSACTION",
        payload: {
          notifyId: nid,
          hash: txHash,
          trans: res,
          status: 0,
          type: TransType.Withdraw,
          data: { daoId, symbol: fundToken.symbol, amount },
        },
      });
    } catch (error) {
      console.error(error);
      // showNotification(
      //   NotificationType.Fail,
      //   t("Notification.WithdrawBonusFailed")
      // );
      // dispatch({
      //   type: "MSGTYPE",
      //   payload: {
      //     msg: "withdraw failed",
      //     closable: true,
      //   },
      // });
    }
  };

  const handleChangeAmount = useCallback((e) => {
    setAmount(e.target.value);
  }, []);

  useEffect(async () => {
    if (!show) {
      return;
    }
    if (!daoOrg || !provider || !account || !fundToken) {
      return;
    }
    try {
      const templateConfigAddress = await daoOrg.templateConfig();
      const res = await api.venture.getClaimBonusEstimate(provider, signer, {
        account,
        template: templateConfigAddress,
        amountClaim: 0,
      });
      setMaxAmount(ethers.utils.formatUnits(res, fundToken.deci));
    } catch (error) {
      console.error("getClaimBonusEstimate failed", error);
    }
  }, [show, daoOrg, account, provider, fundToken]);

  return (
    <BaseModal
      show={show}
      closeModal={handleClose}
      width={440}
      title={t("Venture.Bonus")}
    >
      <ModalContent className="font-regular">
        <DisplayBox>
          <InputLabel>
            <span>{t("Period.BonusUnlocked")}</span>
            <Tooltip
              placement="topLeft"
              title={t("Venture.BonusTip")}
              arrowPointAtCenter
            >
              <img src={TipIcon} alt="" className="tip" />
            </Tooltip>
          </InputLabel>
          <TokenBox className="top">
            <span className="amount">{maxAmount}</span>
            <img src={fundToken?.logoURI} alt="" />
            <span>{fundToken && fundToken.symbol}</span>
          </TokenBox>
        </DisplayBox>
        <Arrow>
          <img src={SwapIcon} alt="" />
        </Arrow>
        <InputBox>
          <div>
            <InputLabel>{t("Venture.Amount")}</InputLabel>
            <InputStyled
              autoFocus
              placeholder={t("Create.Enter")}
              value={amount}
              autoComplete="off"
              type="number"
              onChange={handleChangeAmount}
            />
          </div>
          <div className="right">
            <TokenBox>
              <img src={fundToken?.logoURI} alt="" />
              <span>{fundToken && fundToken.symbol}</span>
            </TokenBox>
            <Max
              t={t}
              onMax={() => {
                setAmount(maxAmount);
              }}
              style={{ marginTop: "5px" }}
            />
          </div>
        </InputBox>
      </ModalContent>

      <Footer>
        <Button
          disabled={!amount || amount < 0 || amount > maxAmount}
          primary
          onClick={confirm}
          height={56}
          style={{ width: "100%" }}
        >
          {t("Venture.Withdraw")}
        </Button>
      </Footer>
    </BaseModal>
  );
}

const ModalContent = styled.div`
  margin-top: 30px;
`;

const Footer = styled.div`
  margin-top: 31px;
  button {
    border-radius: 8px !important;
  }
`;

const InputBox = styled.div`
  width: calc(100% - 40px);
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 13px 20px;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  .right {
    text-align: right;
    .max {
      color: #0e164e;
      line-height: 19px;
      font-size: 14px;
      margin-top: 3px;
      cursor: pointer;
    }
  }
`;

const DisplayBox = styled(InputBox)`
  display: block;
`;

const Arrow = styled.div`
  img {
    width: 24px;
    transform: rotate(180deg);
  }
  margin: 8px auto;
  text-align: center;
`;

const InputLabel = styled.div`
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 19px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  .tip {
    margin-left: 6px;
    width: 24px;
    opacity: 0.5;
  }
`;

const InputStyled = styled(Input)`
  &.ant-input {
    background: transparent !important;
    height: 27px !important;
    padding: 0;
    font-size: 20px;
    border-radius: 0 !important;
    border: none;
    color: #ffffff;
    font-family: "Rubik-Medium";
    &:focus {
      box-shadow: none;
    }
  }
`;

const TokenBox = styled.div`
  display: flex;
  align-items: center;
  &.top {
    margin-top: 14px;
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 10px;
  }
  span {
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
  }
  span.amount {
    font-size: 20px;
    font-family: "Rubik-Medium";
  }
`;
