import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"copy"}</title>
    <g fill="none" fillRule="evenodd">
      <rect width={props.width || 24} height={props.height || 24} rx={6} />
      <path
        d="M13 10H8a1 1 0 0 0-.993.883L7 11v5a1 1 0 0 0 .883.993L8 17h5a1 1 0 0 0 .993-.883L14 16v-5a1 1 0 0 0-1-1Zm2 4h1a1 1 0 0 0 .993-.883L17 13V8a1 1 0 0 0-1-1h-5a1 1 0 0 0-.993.883L10 8v1h3a2 2 0 0 1 2 2v3Zm0 2a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h1V8a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-1v1Z"
        fill={props.color || "#878AA7"}
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
