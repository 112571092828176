import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import TxDetail from "./TxDetail";
import BackNav from "components/common/backNav";
import { SETTINGS_KEY } from "views/appAndsettings";
import ConnectInput from "./connectInput";

import Tabs from "../../layout/tabs";
import TxQueue from "./NFT/txQueue";
import TxHistory from "./NFT/txHistory";
import { useTranslation } from "react-i18next";
import { GlassBG } from "assets/styles/common";
import LoadingBox from "components/common/loading";
import { useWCTContext } from "components/provider/WCTProvider";

export default function SettingList() {
  const { t } = useTranslation();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const txId = query.get("tx");
  const txHash = query.get("txHash");

  const { state: { loading } } = useWCTContext();
  const [current, setCurrent] = useState(0);

  const backTo = useMemo(() => {
    return txId ? `app/${SETTINGS_KEY.NFT}` : "app_settings";
  }, [txId]);

  const nav = useMemo(() => {
    return ["Queue", "History"];
  }, []);
  const navList = useMemo(() => {
    return [t("WCT.Queue"), t("WCT.History")];
  }, []);

  const handleCurrent = (num) => {
    setCurrent(num);
  };

  return (
    <Box>
      <BackNav to={backTo} state={txId ? "" : nav[current]} />
      <ContentBox id="contentBox">
        {txId ? (
          <TxDetail id={txId} txHash={txHash} />
        ) : (
          <TableContent>
            <ConnectInput />
            <Container>
              <GlassBG />
              <FirstLine>
                <Tabs
                  navList={navList}
                  current={current}
                  handleCurrent={handleCurrent}
                />
              </FirstLine>
              {current === 0 && <TxQueue />}
              {current === 1 && <TxHistory />}
            </Container>
          </TableContent>
        )}
      </ContentBox>
      <LoadingStyle loading={loading} type="data" />
    </Box>
  );
}

const Box = styled.div`
  height: 100%;
  position: relative;
`;

const ContentBox = styled.div`
  height: calc(100% - 56px);
`;

const TableContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  position: relative;
`;

const Container = styled.div`
  position: relative;
  min-height: calc(100% - 219px);
`;


const LoadingStyle = styled(LoadingBox)`
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
`;