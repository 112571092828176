import { ethers } from "ethers";
import abi from "../abi/web3Abi/OrgManager.json";
import { getHigherGas } from "utils/utils";

const getDaoModeratorList = async (orgcontract, page, size) => {
  const data = await orgcontract.getDaoModeratorList(page, size);
  console.log("*** [getDaoModeratorList] ***", data);
  const { totalModerators, totalNames, total } = data;
  let lst = [];
  for (let i = 0; i < totalModerators.length; i++) {
    lst.push({
      name: totalNames[i],
      address: totalModerators[i],
    });
  }
  let len = parseInt(total.toString());
  return { lst, len };
};

const getDaoMembersList = async (orgcontract, page, size) => {
  const data = await orgcontract.getDaoMembersList(page, size);
  console.log("*** [getDaoMembersList] ***", data);
  const { totalMembers, totalNames, total } = data;
  let lst = [];
  for (let i = 0; i < totalMembers.length; i++) {
    lst.push({
      name: totalNames[i],
      address: totalMembers[i],
    });
  }
  let len = parseInt(total.toString());
  return { lst, len };
};

const whoAmI = async (orgcontract, account) => {
  const data = await orgcontract.checkRoleByAccount(account);
  console.log("*** checkRoleByAccount ***", data);
  return data;
};

const getFreeAddMember = async (orgContract) => {
  return orgContract.canFreeAddMember();
};

function manageMember(daoOrg, signer, obj, type) {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionOrgMgr,
      ethers.utils.defaultAbiCoder.encode(
        ["string[]", "address[]", "uint"], // unit 0:add Moderator 1:remove Moderator 2:add member 3:remove member
        [obj.name, obj.address, type]
      ),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
}

function addDaoMember(daoOrg, signer, obj) {
  return manageMember(daoOrg, signer, obj, 2);
}

function addDaoModerator(daoOrg, signer, obj) {
  return manageMember(daoOrg, signer, obj, 0);
}

function removeDaoMember(daoOrg, signer, obj) {
  return manageMember(daoOrg, signer, obj, 3);
}
function removeDaoModerator(daoOrg, signer, obj) {
  return manageMember(daoOrg, signer, obj, 1);
}

async function transferOwnership(daoFactory, signer, daoId, newOwner) {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionOrgTransferOwner,
      ethers.utils.defaultAbiCoder.encode(["address"], [newOwner]),
    ]
  );
  const res = await daoFactory
    .connect(signer)
    .transferOrgOwnerShip(daoId, newOwner, templateAction);
  await res.wait();
}

async function joinDao(daoOrg, signer, obj) {
  console.log(obj);
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionOrgApplyJoin,
      ethers.utils.defaultAbiCoder.encode(
        ["string", "address"],
        [obj.name, obj.address]
      ),
    ]
  );
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
}

async function leaveDao(daoOrg, signer) {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionOrgMemberQuit,
      ethers.utils.defaultAbiCoder.encode([], []),
    ]
  );
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
}

function getApplyList(daoContract, page, size) {
  return daoContract.getDaoApplyingMemberList(page, size);
}

async function configJoin(daoOrg, signer, config) {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionOrgSetJoinMode,
      ethers.utils.defaultAbiCoder.encode(["bool"], [config]),
    ]
  );
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
}

const getAuthTemplateAction = (type, address) => {
  return ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionGrantMethodVoteNewAuth,
      ethers.utils.defaultAbiCoder.encode(
        // type 1: add, 2: remove
        ["uint256", "address"],
        [type, address]
      ),
    ]
  );
};

async function addNewVoteAuth(daoOrg, signer, moderator) {
  const templateAction = getAuthTemplateAction(1, moderator);
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
}

async function removeNewVoteAuth(daoOrg, signer, moderator) {
  const templateAction = getAuthTemplateAction(2, moderator);
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
}

async function getContract(web3Instance, daoOrgAddress) {
  return await new ethers.Contract(daoOrgAddress, abi, web3Instance);
}

async function editMemberName(daoOrg, signer, gpAddress, name) {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionOrgUpdateMembers,
      ethers.utils.defaultAbiCoder.encode(
        ["string[]", "address[]"],
        [[name], [gpAddress]]
      ),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
}


export default {
  getContract,
  whoAmI,
  addDaoModerator,
  addDaoMember,
  removeDaoMember,
  removeDaoModerator,
  getDaoModeratorList,
  getDaoMembersList,
  transferOwnership,
  joinDao,
  leaveDao,
  configJoin,
  getFreeAddMember,
  getApplyList,
  addNewVoteAuth,
  removeNewVoteAuth,
  editMemberName,
};
