import styled from "styled-components";
import { useEffect, useState, useMemo, useCallback } from "react";
import * as echarts from "echarts";

import Select from "components/common/select";
import { useTranslation } from "react-i18next";

import { TavGraph } from "api/graphql/dao";
import { useWeb3React } from "@web3-react/core";
import { useDaoContext } from "views/dao/provider";
import { useClientContext } from "components/provider/clientProvider";
import { getShortDisplay } from "utils/publicJs";
import LineSvg from "assets/images/TavLine.svg";
import LftImg from "../../assets/images/left.png";
import RhtImg from "../../assets/images/right.png";
import { AnimationBox } from "assets/styles/common";

export default function Tav({isReady}) {
  const { t } = useTranslation();

  const { chainId } = useWeb3React();
  const {
    state: {
      daoId,
      daoChainId,
     
    },
    dispatch,
  } = useDaoContext();

  const { getClient } = useClientContext();

  const [myChart, setMyChart] = useState();
  const [selected, setSelected] = useState(0);
  const [showZero, setShowZero] = useState(false);
  const filters = useMemo(() => {
    return [
      { label: t("Info.Filter1m"), value: "1M" },
      { label: t("Info.Filter3m"), value: "3M" },
      { label: t("Info.Filter6m"), value: "6M" },
      { label: t("Info.Filter1y"), value: "1Y" },
    ];
  }, []);
  const [currentOption, setCurrentOption] = useState({});
  const option = useMemo(() => {
    return {
      color: ["#326CFF"],

      title: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      grid: {
        x: 0,
        y: 30,
        x2: 10,
        y2: 10,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: false,
            lineStyle: {
              color: "(65,97,128,0.45)",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255, 255, 255, 0.5)",
              margin: 15,
            },
          },
          data: [],
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "rgba(44, 53, 66, 0.4500)",
              margin: 15,
            },
            formatter(val) {
              return getShortDisplay(val, 5);
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
      ],
      series: [
        {
          name: "TAV",
          type: "line",
          stack: "Total",
          smooth: true,
          lineStyle: {
            width: 5,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              global: false
            }
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(50, 108, 255, 0.3)",
              },
              {
                offset: 1,
                color: "rgba(255,255,255, 0.2)",
              },
            ]),
          },
          itemStyle: {
            normal: {
              lineStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#326CFF",
                  },
                  {
                    offset: 1,
                    color: "#A8ECFF",
                  },
                ]),
              },
            },
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
      timeline: {
        show: false,
        autoPlay: true,
        loop: true,
        playInterval: 350,
      },

      options: []
    };
  }, []);

  const getZeroOption = () => {
    return {
      color: ["#326CFF"],
      title: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      grid: {
        x: 0,
        y: 30,
        x2: 10,
        y2: 10,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: false,
            lineStyle: {
              color: "(65,97,128,0.45)",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            textStyle: {
              color: "rgba(12, 22, 81, .45)",
              margin: 15,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "rgba(255, 255, 255, 0.2)"
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          min: -2,
          max: 3,
          interval: 1,
          axisLabel: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "TAV",
          type: "line",
          stack: "Total",
          smooth: true,
          lineStyle: {
            width: 2,
          },
          showSymbol: false,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    };
  }

  useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        const chart = echarts.init(document.getElementById("main"));
        window.onresize = function () {
          chart.resize();
        };


        let arr = [];
        let a = new Array(5)
        a = a.map((item, index)=>index);
        [...Array(5)].forEach((item,index)=>{
          let colorArr = [];
          [...Array(4)].forEach((inner, innerIndex) => {
            let num = (index + 1) / 10 + 0.1 * 2 * innerIndex;
            colorArr.push({
              offset: num > 1 ? 1 : num,
              color: innerIndex % 2 ? "#326CFF" : "#A8ECFF",
            });
          });
          arr.push( {
            series: [
              {
                lineStyle: {
                  color: {
                    colorStops: colorArr
                  }
                }
              }
            ]
          })
        })

        option.options = arr;
        option.timeline.data = a;
        chart.setOption(option);
        setMyChart(chart);
      }, 1000);
    }
  }, [isReady]);

  useEffect(() => {
    if (myChart && currentOption) {
      myChart.setOption(currentOption)
    }
  }, [myChart, currentOption]);

  const handleData = useCallback((list) => {
    const SplitNumber = 5;
    const values = [], lables = [];
    let ymax, ymin = -1;
    list.forEach((d) => {
      values.push(d.value);
      if (!ymax || d.value > ymax) {
        ymax = d.value;
      }
      if (ymin === -1 || d.value < ymin) {
        ymin = d.value;
      }

      lables.push(d.date)
    });

    ymax = ymax ? ymax : SplitNumber;
    ymin = ymin ? ymin : 0;
    // let left = ymax % SplitNumber;
    // ymax += SplitNumber - left;
    return { values, lables, max: ymax, min: ymin, };
  }, [])

  useEffect(async () => {
    if (!daoChainId || !daoId) {
      return;
    }
    const client = getClient(daoChainId)
    try {
      const data = await client.request(TavGraph, {
        chainId: daoChainId,
        daoId,
        scope: filters[selected].value,
      });
      const graphData = data?.daoTav || [];
      console.log("graphData:", graphData);

      if (graphData.length === 0 || graphData.every((d) => d.value === 0)) {
        const newOption = getZeroOption();
        setCurrentOption(JSON.parse(JSON.stringify(newOption)));
        // myChart.setOption(JSON.parse(JSON.stringify(newOption)));
        setShowZero(true);
      } else {
        const { values, lables, max, min } = handleData(graphData);
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.xAxis[0].data = lables;
        newOption.series[0].data = values;
        newOption.yAxis[0].max = max;
        newOption.yAxis[0].min = min;
        if (values.length === 1) {
          newOption.series[0].showSymbol = true;
        }
        setCurrentOption(newOption);
        // myChart.setOption(newOption);
        setShowZero(false);
      }
      dispatch({ type: "SET_TAV_STATUS", payload: true });
    } catch (error) {}
  }, [chainId, selected, daoId]);

  const changeFilter = useCallback((index) => {
    setSelected(index);
  }, []);

  return (
    <AnimationBox className={isReady ? "animation": ""}>
      <ChartBox>
        <LftBg />
        <RhtBg />
        <TitleBox>
          <div className="lft"> {t("Portfolio.TAV")} </div>
          <Select
            list={filters}
            onChange={changeFilter}
            selected={selected}
            style={{ marginTop: 0 }}
          />
        </TitleBox>
        <div id="main" style={{ width: "100%", height: "300px" }} />
        {showZero && (
          <ZeroPanel path={LineSvg}>
            <div className="border"></div>
            <div className="line" />
            <span>0</span>
          </ZeroPanel>
        )}
      </ChartBox>
    </AnimationBox>
  );
}

const LftBg = styled.div`
  width: 253px;
  height: 133px;
  background: url(${LftImg});
  background-size: 100%;
  position: absolute;
  left: -1px;
  top: -1px;
`
const RhtBg = styled.div`
  width: 245px;
  height: 132px;
  background: url(${RhtImg}) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -1px;
  bottom: -1px;

`

const ChartBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(32px);
  padding: 24px 27px;
  position: relative;
  margin: 0 1px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  .lft {
    display: flex;
    align-items: center;
  
    width: 185px;
    height: 28px;
    font-size: 20px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
    img {
      margin-left: 8px;
    }

  }
`;
const DropBox = styled.ul`
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 9999;
  width: 190px;
  padding: 10px 0;
  background: #ffffff;
  border-radius: 8px 8px 14px 14px;
  border: 1px solid #edeef3;
  li {
    padding: 6px 23px;
    cursor: pointer;
    &:hover {
      background: #fbfbfa;
    }
  }
`;

const ZeroPanel = styled.div`
  width: 100%;
  height: 300px;
  position: absolute;
  left: 0;
  bottom: 0;
  span {
    position: absolute;
    bottom: 175px;
    left: 12px;
    color: #aaa;
  }
  .border {
    width: calc(100% - 64px);
    height: 1px;
    border-top: 4px solid;
    border-image: linear-gradient(
        225deg,
        rgba(50, 108, 255, 1),
        rgba(168, 236, 255, 1)
      )
      4 4;
    position: absolute;
    bottom: 185px;
    left: 27px;
    height: 4px;
  }
  .line {
    width: calc(100% - 64px);
    height: 158px;
    background: ${(props) => `url(${props.path}) no-repeat`};
    background-size: contain;
    position: absolute;
    left: 27px;
    bottom: 35px;
    &:hover {
      opacity: 0.7;
    }
  }
`;