import { valueToBigNumber } from "@aave/math-utils";
import BigNumber from "bignumber.js";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const HFContent = ({ healthFactor, healthFactorDisplay }) => {
  const { t } = useTranslation();

  const dotPosition = +healthFactor > 10 ? 100 : +healthFactor * 10;

  return (
    <Box style={{ position: "relative" }}>
      <div
        style={{
          height: "4px",
          background:
            "linear-gradient(90deg, #46BC4B 0%, #F89F1A 52.08%, #BC0000 100%)",
          borderRadius: "1px",
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        }}
      />

      <div
        style={{
          position: "absolute",
          bottom: "calc(100%)",
          left: `${dotPosition > 100 ? 100 : dotPosition}%`,
          zIndex: 3,
        }}
      >
        <div
          style={{
            position: "relative",
            whiteSpace: "nowrap",
            "&:after": {
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "6px 4px 0 4px",
              borderColor: `transparent transparent transparent`,
              content: "''",
              position: "absolute",
              left: dotPosition > 75 ? "auto" : "50%",
              right: dotPosition > 75 ? 0 : "auto",
              transform:
                dotPosition > 75 ? "translateX(0)" : "translateX(-50%)",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              left: dotPosition > 75 ? "auto" : dotPosition < 15 ? "0" : "50%",
              transform:
                dotPosition > 75 || dotPosition < 15
                  ? "translateX(0)"
                  : "translateX(-50%)",
              right: dotPosition > 75 ? 0 : "auto",
              flexDirection: "column",
              alignItems:
                dotPosition > 75
                  ? "flex-end"
                  : dotPosition < 15
                  ? "flex-start"
                  : "center",
              textAlign:
                dotPosition > 75
                  ? "right"
                  : dotPosition < 15
                  ? "left"
                  : "center",
              bottom: "calc(100% + 2px)",
            }}
          >
            {healthFactorDisplay}
          </div>
        </div>
      </div>

      <div
        style={{
          maxWidth: "20%",
          textAlign: "center",
          pt: 1.5,
          "&:after": {
            content: "''",
            position: "absolute",
            bottom: "85%",
            left: "10%",
            transform: "translateX(-50%)",
            height: "10px",
            width: "2px",
            bgcolor: "error.main",
          },
        }}
      >
        <span style={{ color: "#BC0000B8" }}>1</span>
        {/* <FormattedNumber value={1} visibleDecimals={2} color="error.main" variant="subheader2" /> */}
        <div
          style={{ display: "flex", lineHeight: "12px", color: "#BC0000B8" }}
        >
          {t("defi.LiquidationValue")}
        </div>
      </div>
    </Box>
  );
};

export default HFContent;

const Box = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
`;
