import styled from "styled-components";
import React, { memo, useState, useCallback, useEffect } from "react";
import LinkImg from "img/common/link.png";
import CopyStr from "components/copy";
import Link from "components/common/LinkIcon";

import PassIcon from "assets/images/vote/pass.svg";
import RejectIcon from "assets/images/vote/reject.svg"

import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";

import { useLazyQuery } from "@apollo/client";
import { QueryVoteHistory } from "api/graphql/vote";
import { useDaoContext } from "views/dao/provider";
import publicJs from "utils/publicJs";
import { VOTE_INFO, VOTE_TYPE, formatVoteType } from "./detail";

import PaginationBox from "components/common/pagination";
import EmptyBox from "components/common/empty";
import moment from "moment";
import LoadingBox from "components/common/loading";
import { useClientContext } from "components/provider/clientProvider";
import useCheckLogin from "hooks/useCheckLogin";
import { IndexTag } from "./detail/styles";
import { getVoteHistoryList } from "api/graphql/tx";
import useMulticallTxs from "components/apps/walletConnect/useMulticallTxs";
import { parseVoteData } from "utils/parseVoteDataUtil";
import { SubgraphApiQuery, getVoteHistry } from "api/graphql/subQuery";
import { formatTextTime } from "utils/utils";

export default function History() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { fullhandle, network: networkSimple } = useParams();

  const {
    state: { daoId, daoChainId },
  } = useDaoContext();

  const { getClient } = useClientContext();
  const isLogin = useCheckLogin();

  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [total, setToal] = useState(0);
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState([
    // {
    //   vote_id: 1,
    //   create_at: "May 24th 2022, 11:59 PM",
    //   agree_count: 1,
    //   oppose_count: 1,
    //   vote_type: VOTE_TYPE.TRANSFER_TOKEN,
    //   tx_hash: "0x",
    //   status: 1,
    // },
    // {
    //   vote_id: 2,
    //   create_at: "May 24th 2022, 11:59 PM",
    //   agree_count: 10,
    //   oppose_count: 2,
    //   vote_type: VOTE_TYPE.TRANSFER_TOKEN,
    //   tx_hash: "0x",
    //   status: 2,
    // },
  ]);

  const queryUnAuthVotes = async () => {
    const client = getClient(daoChainId);
    try {
      const resp = await client.request(SubgraphApiQuery, {
        chainId: daoChainId,
        query: getVoteHistry(daoId, page, pageSize),
      });
      const data = JSON.parse(resp.subgraphApi).data;
      console.log("===data", data);
      setList(
        (data.transactionRecords || []).map((v) => {
          const { voteType } = parseVoteData(v.data);
          return {
            ...v,
            createAt: formatTextTime(v.createAt),
            vote_type: voteType,
          };
        })
      );
      setToal(data.totalCount.count);
    } catch (error) {
      console.error("===error", error);
    }
    setLoading(false);
  }

  const onChangePage = useCallback((v) => {
    setPage(v);
  }, []);

  const onShowSizeChange = useCallback((c, size) => {
    setPagesize(size);
  }, []);

  useEffect(() => {
    if (!daoId || !daoChainId) {
      return;
    }
    queryUnAuthVotes();
    
  }, [daoId, daoChainId, page, pageSize, isLogin]);

  const go2detail = (id) => {
    navigate(`/${networkSimple}/${fullhandle}/vote/${id}`, { state: "history" });
  };

  const VoteStatus = memo(({ status }) => {
    switch (status) {
      case 1:
        return (
          <>
            <img className="confirm" src={PassIcon} alt="" />
            <span className="success">{t("Vote.queue.Success")}</span>
          </>
        );
      case 2:
        return (
          <>
            <img className="reject" src={RejectIcon} alt="" />
            <span className="rejected">{t("Vote.queue.Rejected")}</span>
          </>
        );
      default:
        return <></>;
    }
  });

  const VoteType = memo(({ type }) => {
    const data = VOTE_INFO[type];
    return <span>{t(data.name)}</span>;
  });

  return (
    <>
      <TableBox>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>{t("Vote.queue.No")}</th>
              <th>{t("Vote.queue.Type")}</th>
              <th>{t("Vote.queue.time")}</th>
              <th className="center">{t("Vote.queue.Agree")}</th>
              <th className="center">{t("Vote.queue.Hash")}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, i) => (
              <tr key={i} onClick={() => go2detail(item.txID)}>
                <td>
                  <IndexTag>{item.txID}</IndexTag>
                </td>
                <td className="flexBox">
                  <img src={VOTE_INFO[item.vote_type].icon} alt="" />
                  <VoteType type={item.vote_type} />
                </td>
                <td className="time">{item.createAt}</td>
                <td className="flexBox center">
                  <span className="num">
                    {item.agree_count} : {item.oppose_count}
                  </span>
                  <div className="right">
                    <VoteStatus status={item.state} />
                  </div>
                </td>
                <OptionTD>
                  <div className="flexBox">
                    <span>{publicJs.AddresstoShow(item.txHash)}</span>{" "}
                    <div className="optionRight">
                      <CopyStr value={item.txHash} />
                      <Link hash={item.txHash} type="tx" />
                    </div>
                  </div>
                </OptionTD>
              </tr>
            ))}
          </tbody>
        </table>
        {!loading && !list.length && (
          <EmptyBox pure={true} style={{ marginTop: "100px" }} />
        )}
        {!!list.length && (
          <Pagination>
            <PaginationBox
              showQuickJumper
              pageSize={pageSize}
              defaultCurrent={page}
              total={total}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
            />
          </Pagination>
        )}
      </TableBox>
      <LoadingStyle loading={loading} type="data" />
    </>
  );
}

const TableBox = styled.div`
  min-height: 100%;
  scrollbar-width: none;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 16px;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  table {
    width: 100%;
    overflow: hidden;
  }
  th {
    font-size: 14px;
    font-weight: 400;
    color: #4a84ff;
    height: 72px;
    text-align: left;
    &:first-child {
      border-top-left-radius: 16px;
      padding-left: 30px;
    }
    &:last-child {
      border-top-right-radius: 16px;
    }
    &.center {
      text-align: center;
    }
  }
  td {
    white-space: nowrap;
    font-weight: 400;
    vertical-align: middle;
    &:first-child {
      text-align: left;
      padding-left: 30px;
    }
    &:last-child {
      text-align: center;
    }
    &.time {
      font-family: "Poppins-Light";
    }
    &.flexBox {
      display: flex;
      align-items: center;
      &.center {
        justify-content: center;
      }
      img {
        width: 24px;
        margin-right: 8px;
      }
      span.num {
        font-family: "Rubik-Medium";
        font-size: 18px;
      }
      .right {
        display: flex;
        margin-left: 30px;
      }
      .success {
        color: #79dfc4;
        font-size: 12px;
      }
      .rejected {
        color: #ffa4d1;
        font-size: 12px;
      }
    }
  }
  .walletIcon {
    width: 24px;
  }
  tbody tr {
    height: 62px;
    line-height: 62px;
  }
  tbody tr:nth-child(odd) {
    background: rgba(255, 255, 255, 0.04);
  }
`;

const OptionTD = styled.td`
  .flexBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    margin: 0 !important;
  }
  .optionRight {
    height: 24px;
    margin-top: -22px;
    margin-left: 7px;
  }
`;

const Pagination = styled.div`
  text-align: right;
  margin: 10px 80px 0 0;
  height: 70px;
`;

const StatusIcon = styled.img`
  margin-right: 10px;
  &.confirm {
    width: 14px !important;
  }
  &.reject {
    width: 22px !important;
  }
`;

const LoadingStyle = styled(LoadingBox)`
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 99;
`;