import React, { useEffect, useState } from "react";
import Web3ReactProvider from "components/provider/web3Provider";
import AppProvider from "components/provider/appProvider";
import HttpProvider from "components/provider/httpProvider";
import AuthProvider from "components/provider/authProvider";
import ClientProvider from "components/provider/clientProvider";
import Version from "./version";
import Routerlink from "./router";
import GlobalStyle from "./utils/GlobalStyle";

import { ThemeProvider } from "styled-components";
import { BaseTheme, THEME_MAP } from "assets/styles/theme";
import { ThemePreferenceContext } from "components/provider/themeContext";

import "react-notifications-component/dist/theme.css";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  const [currentTheme, setCurrentTheme] = useState("dark");
  const theme = { ...BaseTheme, colors: THEME_MAP[currentTheme] };

  useEffect(() => {
    document.body.className = i18n.language;
  }, [i18n.language]);

  return (
    <AppProvider>
      <AuthProvider>
        <Web3ReactProvider>
          <ClientProvider>
            <HttpProvider>
              <ThemePreferenceContext.Provider
                value={{ currentTheme, setCurrentTheme }}
              >
                <ThemeProvider theme={theme}>
                  <GlobalStyle />
                  <Routerlink />
                  <Version />
                </ThemeProvider>
              </ThemePreferenceContext.Provider>
            </HttpProvider>
          </ClientProvider>
        </Web3ReactProvider>
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
