import publicJs from "../utils/publicJs";
import { ethers } from "ethers";
import abi from "../abi/web3Abi/VenturesManager.json";
import stockAbi from "../abi/web3Abi/VenturesStockManager.json";
import actionAbi from "../abi/web3Abi/ActionVenturesClaimShare.json";
import actionClaimBonusAbi from "../abi/web3Abi/ActionVenturesClaimBonus.json";
import actionClaimFailAbi from "../abi/web3Abi/ActionVenturesRaiseFailClaim.json";
import swapAbi from "../abi/web3Abi/ActionVaultUniswapV2Router02Swap.json";
import { getHigherGas } from "utils/utils";

const Router = "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3";

const getStockContract = async (web3Instance, stockAddress) => {
  return new ethers.Contract(stockAddress, stockAbi, web3Instance);
};

const getStockToken = (stockContract) => {
  // return stockContract
};

const getClaimShareActionContract = async (web3Instance) => {
  return new ethers.Contract(
    window.mainAddress.actionVenturesClaimShare,
    actionAbi,
    web3Instance
  );
};
const getClaimShareBonusActionContract = async (web3Instance) => {
  return new ethers.Contract(
    window.mainAddress.actionVenturesClaimBonus,
    actionClaimBonusAbi,
    web3Instance
  );
};

const getSwapContract = async (web3Instance) => {
  return new ethers.Contract(
    window.mainAddress.actionVaultUniswapV2Router02Swap,
    swapAbi,
    web3Instance
  );
};

const getContract = async (web3Instance, ventureAddress) => {
  return new ethers.Contract(ventureAddress, abi, web3Instance);
};

const configGPraise = async (daoOrg, signer, obj) => {
  console.log("configGPraise:", obj);
  const { height, ratio, minDep, maxDep } = obj;
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesOpenGPRaise,
      ethers.utils.defaultAbiCoder.encode(
        // time, percent, GPMinSoftTop, GPMaxHardTop
        ["uint256", "uint256", "uint256", "uint256"],
        [height, ratio, minDep, maxDep]
      ),
    ]
  );
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
};

const configLPraise = async (daoOrg, signer, obj) => {
  const { height, minDep, maxDep } = obj;
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesOpenLPRaise,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "uint256", "uint256", "bytes32", "string"],
        [height, minDep, maxDep, obj.root, obj.recordsCode]
      ),
    ]
  );
  const res = await daoOrg.connect(signer).action(templateAction);
  await res.wait();
};

const deposit = async (daoOrg, signer, amount) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesRaiseMoney,
      ethers.utils.defaultAbiCoder.encode(["uint256"], [amount]),
    ]
  );
  return await daoOrg.connect(signer).action(templateAction);
};

const raise = async (daoOrg, signer, amount, proof) => {
  console.log("[raise]", proof);
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesRaiseMoney,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "bytes32[]"],
        [amount, proof]
      ),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
};

const mint = (daoOrg, signer, amount, proof, useNative) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesRaiseMoney,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "bytes32[]"],
        [amount, proof]
      ),
    ]
  );
  return useNative
    ? daoOrg
        .connect(signer)
        .action_with_payable(templateAction, { value: amount })
    : daoOrg.connect(signer).action_with_payable(templateAction);
};

const mintNew = (
  daoOrg,
  signer,
  amount,
  minOutPutAmount,
  valueAmount,
  proof,
  useNative
) => {
  console.log(
    "amount, minOutPutAmount: ",
    amount.toString(),
    minOutPutAmount.toString()
  );
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    // uint256 payAmount, uint256 minOutPutAmount, bytes32[] memory proof
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesRaiseMoney,
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "uint256", "bytes32[]"],
        [amount, minOutPutAmount, proof]
      ),
    ]
  );
  return useNative
    ? daoOrg
        .connect(signer)
        .action_with_payable(templateAction, { value: valueAmount })
    : daoOrg.connect(signer).action_with_payable(templateAction);
};

const closeVenture = async (daoOrg, signer) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [window.mainAddress.actionVenturesToRaiseFail, "0x"]
  );
  return daoOrg.connect(signer).action(templateAction);
};

const refund = async (daoOrg, signer) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [window.mainAddress.actionVenturesRaiseFailClaim, "0x"]
  );
  return daoOrg.connect(signer).action(templateAction);
};

const freeze = async (daoOrg, signer) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [window.mainAddress.actionVenturesToFreezingPeriod, "0x"]
  );
  return daoOrg.connect(signer).action(templateAction);
};

const buyToken = async (daoOrg, signer, obj) => {
  // 0x9eabBB120ae7A9fb2d2B501C1Bff96555937Ce58
  // 0x5d2f54b9E0099E88e1a58B99b92B4ec42E329fca
  const { swapRouterAddress, paths, fromAmount, toAmount } = obj;
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultUniswapV2Router02Swap,
      ethers.utils.defaultAbiCoder.encode(
        // address router, address[] path, uint256 amountIn, uint256 amountOutMin
        ["address", "address[]", "uint256", "uint256"],
        [swapRouterAddress, paths, fromAmount, toAmount]
      ),
    ]
  );
  const estimation = await daoOrg
    .connect(signer)
    .estimateGas.action(templateAction);
  const higherGas = getHigherGas(estimation);
  return daoOrg.connect(signer).action(templateAction, { gasLimit: higherGas });
};

const withdraw = async (daoOrg, signer, amount) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesClaimShare,
      ethers.utils.defaultAbiCoder.encode(["uint256"], [amount]),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
};

const withdrawBonus = async (daoOrg, signer, amount) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesClaimBonus,
      ethers.utils.defaultAbiCoder.encode(["uint256"], [amount]),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
};

const getClaimEarningEstimate = async (web3Instance, signer, obj) => {
  const contract = await getClaimShareActionContract(web3Instance);
  return await contract
    .connect(signer)
    .claimShareEstimate(obj.account, obj.template, obj.amount);
};

const getClaimBonusEstimate = async (web3Instance, signer, obj) => {
  const contract = await getClaimShareBonusActionContract(web3Instance);
  return await contract
    .connect(signer)
    .claimBonusEstimate(obj.account, obj.template, 0);
};

const getRefundEstimate = async (web3Instance, account, template) => {
  const contract = await new ethers.Contract(
    window.mainAddress.actionVenturesRaiseFailClaim,
    actionClaimFailAbi,
    web3Instance
  );
  return contract.claimEarningEstimate(account, template, 0);
};

const getAmountOutMin = async (web3Instance, obj) => {
  const contract = await getSwapContract(web3Instance);
  return await contract.getAmountOutMin(
    obj.swapRouterAddress,
    obj.path,
    obj.amountIn
  );
};

const getFundInfo = async (contract) => {
  return {
    raised: (await contract.getRaisedAmount()).toString(),
    goal: (await contract.RaiseMinSoftCap()).toString(),
    hard: (await contract.RaiseMaxHardCap()).toString(),
    address: await contract.TokenType(),
  };
};

const fetchPrice = async ({
  chainId,
  srcToken,
  srcDecimals,
  srcAmount,
  targetToken,
  targetDecimals,
  swap,
}) => {
  try {
    const resp = await fetch(
      `https://api.paraswap.io/prices/?srcToken=${srcToken}&destToken=${targetToken}&network=${chainId}&partner=quickswapv3&includeDEXS=${swap}&srcDecimals=${srcDecimals}&destDecimals=${targetDecimals}&amount=${srcAmount}&side=SELL&maxImpact=50`
    );
    let data;
    try {
      data = await resp.json();
    } catch (error) {}
    if (resp.status === 200) {
      return { code: 0, data };
    } else if (resp.status === 400) {
      return { code: 400, data };
    }
    throw Error("http code", resp.status);
  } catch (error) {
    return { code: -1, msg: error };
  }
};

const getUrl = ({
  chainId,
  srcToken,
  srcDecimals,
  srcAmount,
  targetToken,
  targetDecimals,
  swap,
}) => {
  return `https://api.paraswap.io/prices/?srcToken=${srcToken}&destToken=${targetToken}&network=${chainId}&partner=quickswapv3&includeDEXS=${swap}&srcDecimals=${srcDecimals}&destDecimals=${targetDecimals}&amount=${srcAmount}&side=SELL&maxImpact=30`;
};

const fetchMainnetPrice = async (params) => {
  try {
    const v2 = getUrl({ ...params, swap: "uniswapv2" });
    const respV2 = await fetch(v2);
    if (respV2.status === 200) {
      const data = await respV2.json();
      console.log("respV2 data:", data);

      const routeArr = data.priceRoute?.bestRoute;
      if (routeArr && routeArr.length) {
        return { code: 0, data, version: "v2" };
      }
    }

    const v3 = getUrl({ ...params, swap: "uniswapv3" });
    const respV3 = await fetch(v3);
    let data;
    try {
      data = await respV3.json();
    } catch (error) {}
    if (respV3.status === 200) {
      console.log("respV3 data:", data);

      const routeArr = data?.priceRoute?.bestRoute;
      if (routeArr && routeArr.length) {
        return { code: 0, data, version: "v3" };
      }
    } else if (respV2.status === 400) {
      return { code: 400, data };
    } else {
      return { code: respV2.status, data };
    }
  } catch (error) {
    return { code: -1, msg: error };
  }
};

const buyTokenV3 = async (daoOrg, signer, obj) => {
  const { swapRouterAddress, paths, fromAmount, toAmount } = obj;
  try {
    console.log("swapRouterAddress:", swapRouterAddress);
    console.log("paths:", paths);
    console.log("fromAmount:", fromAmount?.toString());
    console.log("toAmount:", toAmount?.toString());
  } catch (error) {
    console.error("console params failed:", error);
  }

  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVaultUniswapV3Router,
      ethers.utils.defaultAbiCoder.encode(
        ["address", "bytes", "uint256", "uint256"],
        [swapRouterAddress, paths, fromAmount, toAmount]
      ),
    ]
  );
  const estimation = await daoOrg
    .connect(signer)
    .estimateGas.action(templateAction);
  const higherGas = getHigherGas(estimation);
  return daoOrg.connect(signer).action(templateAction, { gasLimit: higherGas });
};

const editCloseDate = (daoOrg, signer, newtime) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesSetRaiseSubTime,
      ethers.utils.defaultAbiCoder.encode(["uint256"], [newtime]),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
};

const claimToken = (daoOrg, signer, amount) => {
  const templateAction = ethers.utils.defaultAbiCoder.encode(
    ["address", "bytes"],
    [
      window.mainAddress.actionVenturesVestingWithdraw,
      ethers.utils.defaultAbiCoder.encode(["uint256"], [amount]),
    ]
  );
  return daoOrg.connect(signer).action(templateAction);
};

export default {
  getContract,
  getStockContract,
  getStockToken,

  raise,
  mint,
  mintNew,
  configGPraise,
  configLPraise,
  buyToken,
  buyTokenV3,
  closeVenture,
  refund,
  freeze,
  withdraw,
  withdrawBonus,
  getFundInfo,
  getClaimEarningEstimate,
  getClaimBonusEstimate,
  getAmountOutMin,
  fetchPrice,
  fetchMainnetPrice,
  editCloseDate,
  claimToken,
  getRefundEstimate,

  deposit,
};
