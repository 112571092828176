import styled from "styled-components";
import { useTranslation } from "react-i18next";

import TransactionBox from "components/common/transactionBox";
import SuccessIcon from "assets/images/Success.svg";
import FailIcon from "assets/images/Failed.svg";
import LogoImg from "assets/images/DAOlogo.svg";
import Loading from "assets/gif/Loading.gif";

export const STATUS = {
  Default: null,
  Depositing: "Depositing",
  Success: "Success",
  Failed: "Failed",
};


export const TransictionHash = (props) => {
  const { TxHash } = props;
  return (
    <TransictionHashStyled>
      <div className="txTitle">Your transaction:</div>
      <TransactionBox address={TxHash} type="tx" />
    </TransictionHashStyled>
  );
};

export const DepositFailed = (props) => {
  return (
    <DepositStatusStyled className="fail">
      <img src={FailIcon} alt="" />
      <StatusTitle className="font-medium">
        Deposit failed: {props.failReason}
      </StatusTitle>
    </DepositStatusStyled>
  );
};

export const DepositSuccess = (props) => {
  const { t } = useTranslation();
  const { fund, token } = props;
  return (
    <DepositStatusStyled className="success">
      <img src={SuccessIcon} alt="" />
      <StatusTitle className="font-medium">
        {t("Period.DepositedTitle", {
          amount: fund.amount,
          symbol: fund.symbol,
        })}
      </StatusTitle>
      {/* TODO support text */}
      {/* <p>
        {t("Period.Received", { amount: token.amount, symbol: token.symbol })}{" "}
      </p>
      <p>
        DAO token can be circulated and transferred. It is the only proof of
        redemption. During redemption period, the redeemed tokens will be
        burned, and the total supply of equity tokens will be reduced
        accordingly.
      </p> */}
    </DepositStatusStyled>
  );
};

export const Depositing = (props) => {
  const { t } = useTranslation();
  const { fund } = props;
  return (
    <DepositStatusStyled className="depositing">
      <img src={Loading} className="loading" alt="" />
      <StatusTitle className="font-medium">
        {t("Period.DepositingTitle", {
          amount: fund.amount,
          symbol: fund.symbol,
        })}
      </StatusTitle>
      <StatusContent>{t("Create.Step1Text")}</StatusContent>
    </DepositStatusStyled>
  );
};


const TransictionHashStyled = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin-top: 27px;
  .txTitle {
    margin-bottom: 4px;
    margin-top: 8px;
    font-size: 12px;
  }
`;

const DepositStatusStyled = styled.div`
  text-align: center;
  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: #10164b;
    &:first-child {
      margin-top: 7px;
    }
  }

  &.success {
    margin-top: 33px;
    /* margin-bottom: 40px; */
  }
  &.fail {
    margin-top: 33px;
    /* margin-bottom: 41px; */
  }
  &.depositing {
    margin-top: 56px;
    /* margin-bottom: 27px; */
  }
  img.loading {
    width: 44px;
  }
`;
const StatusTitle = styled.div`
  line-height: 22px;
  margin-top: 16px;
`;

const StatusContent = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  margin-top: 8px;
`;
