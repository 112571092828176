import { createGlobalStyle } from "styled-components";
import "../scss/fonts.scss";



const GlobalStyle = createGlobalStyle`
  body,html{
    min-height: 100%;
    padding: 0;
    margin: 0;
    background: #041735;
    color: #FFF;
    font-family: "Poppins-Regular";
    font-size: 14px;
  }
  * {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0 ;
      display: none;
    }
  }
  ul,li,dl,dt,dd{
    padding: 0;
    margin: 0;
    list-style: none;
  }
  a {
    text-decoration: none;
    color: unset;
    &:hover {
      color: unset;
    }
  }
  input, textarea {
    caret-color: #328aff;
    border: none;
    font-family: "Poppins-Regular";
    font-size: 14px;
    &:focus-visible {
      outline: none;
    }
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0; 
  }
  input[type=number] {
      -moz-appearance:textfield;
  }

  .ant-tooltip {
    --antd-arrow-background-color: rgba(255,255,255,0.14);
    .ant-tooltip-inner {
      background: rgba(255,255,255,0.14);
      backdrop-filter: blur(12px);
      font-size: 12px;
      padding: 12px;
    }
  }
  .ant-picker-panel-container {
    //background: rgba(4,23,53,1) !important;
    background: #2e3b51 !important;
    backdrop-filter: blur(50px);
    width: 500px;
  }
  .ant-picker-date-panel{
    width: 333px!important;
  }
  .ant-picker-cell .ant-picker-cell-inner{
    color: rgba(255,255,255, 0.12);
  }
  .ant-picker-time-panel{
    background: rgba(255,255,255,0.06);
    backdrop-filter: blur(24px);
    //padding: 10px 0;
  }
  .ant-picker-content{
    width: 100%!important;
  }
  .ant-picker-content th, .ant-picker-header-view {
    color: #fff !important;
  }
  .ant-picker-header{
    padding: 6px 20px!important;
  }
  .ant-picker-header, .ant-picker-dropdown, .ant-picker-cell-in-view .ant-picker-cell-inner, .ant-picker-time-panel-cell-inner {
    color: rgba(255,255,255, 0.5) !important;
  }
  .ant-picker-dropdown .ant-picker-header button {
    color: #fff;
  }
  .ant-picker-cell-inner{
    padding: 0 10px;
    height: 35px!important;
    line-height: 35px!important;
    min-width: 35px!important;
  }
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background: #45BEFF !important;
    color: #fff !important;
    &::before {
      border: none !important;
    }
  }
  .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: unset !important;
    color: #45BEFF !important;
  }
  .ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid rgba(255,255,255,0.08);
    .ant-picker-ranges {
      padding: 12px 16px;
    }
  }
  .ant-btn.ant-btn-sm.ant-btn-primary {
    background: linear-gradient(311deg, #61E1FF 0%, #326CFF 100%);
    box-shadow: 0px 8px 20px 0px rgba(71,107,249,0.04);
    border-radius: 16px;
    width: 67px;
    height: 32px;
    border: none;
    &:disabled {
      opacity: 0.4;
      border: none;
      color: unset;
    }
  }

  .rnc__notification-item {
    box-shadow: none;
    overflow: hidden;
  }
  .version{
    position: fixed;
    left: 0;
    bottom: 0px;
    z-index: 99999;
    padding:5px;
    font-size: 10px;
    span{
      margin-right: 6px;
    }
  }
  .version{
    width: 100%;
    text-align: left;
    color: #ccc;
  }
`;

export default GlobalStyle;
