import React, {
  memo,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import VentureVoteConfig from "./ventureVoteConfigFixed";
import { useWeb3React } from "@web3-react/core";
import { useCSVReader } from "react-papaparse";
import BatchIcon from "assets/images/create/batch.svg";
import DeleteIcon from "assets/images/create/Close.svg";
import { useCreateContext } from "./provider";
import { DaoType } from "./chooseType";
import { ethers } from "ethers";
import { InputItem, InputNumberItem, InputLabel } from "components/common/inputComp";
import { AddButton } from "./fundraise/style";

const KEY = "add_manager";
export const LOCAL_KEY = `CreateDao_${KEY}`;

const AddManager = memo((props) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();

  const [init, setInit] = useState(true);

  const [configInvalid, setConfigInvalid] = useState(false);
  const [minSupportNumber, setMinSupportNumber] = useState();
  const [GpCarry, setGpCarry] = useState();
  const [manageFee, setManageFee] = useState();
  const [addressList, setAddressList] = useState([]);

  const { CSVReader } = useCSVReader();

  const {
    state: { chooseType },
  } = useCreateContext();

  const addAddress = () => {
    const newArray = [...addressList];
    newArray.push({
      address: "",
      bonus: "",
      name: t("Create.Manager"),
    });
    setAddressList(newArray);
  };

  const handleSetGpInfo = (name, value, index) => {
    if (name === "bonus" && value < 0) {
      return
    } 
    let newArray = [...addressList];
    newArray[index][name] = value;
    setAddressList(newArray);
  };

  const deleteAddress = (e, index) => {
    let newArray = [...addressList];
    newArray.splice(index, 1);
    setAddressList(newArray);
  };

  // const getValidGpLength = useMemo(() => {
  // let i = 0;
  // addressList.forEach((a) => a.address && i++);
  // return i;
  //   return addressList.length;
  // }, [addressList]);

  const handleCSVfile = (data) => {
    const lst = [];
    for (const arr of data) {
      const len = arr.length;
      console.log(arr, len);

      if (len === 0) {
        continue
      }
      if (len === 1 && ethers.utils.isAddress(arr[0])) {
        lst.push({ address: arr[0], name: "" });
        continue
      }
      const isAddr = ethers.utils.isAddress(arr[1]);
      if (len === 2) {
        if (isAddr) {
          lst.push({ address: arr[1], name: arr[0] });
        }
        continue
      }
      if (isAddr) {
        const item = {
          address: arr[1],
          name: arr[0],
          bonus: arr[2] && Number(arr[2]),
        };
        if (arr[2] && Number(arr[2])) {
          item.bonus = Number(arr[2]);
        }
        lst.push(item);
      }
    }
    const uniqueMap = {};

    const allList = addressList.concat(lst);
    const lastList = []
    allList.forEach(l => {
      if (l.addAddress || l.name || l.bonus) {
        const addr = l.address.toLowerCase();
        if (!uniqueMap[addr]) {
          lastList.push(l);
          uniqueMap[addr] = l;
        }
      }
    });
    setAddressList(lastList);
  };

  const savePage = () => {
    const data = {
      addressList,
      GpCarry,
      manageFee,
      minSupportNumber,
    };
    sessionStorage.setItem(LOCAL_KEY, JSON.stringify(data));
  };

  useEffect(() => {
    if (!init) {
      savePage();
    }
  }, [minSupportNumber]);

  useEffect(() => {
    if (!init) {
      if (!addressList.length) {
        setAddressList([
          {
            address: account,
            bonus: 100,
            name: t("Create.Owner"),
          },
        ]);
      } else {
        savePage();
      }
    }
  }, [init, addressList]);

  useEffect(() => {
    try {
      const data = JSON.parse(sessionStorage.getItem(LOCAL_KEY) || "");
      if (data.GpCarry !== undefined) {
        setGpCarry(data.GpCarry);
      }
      if (data.manageFee !== undefined) {
        setManageFee(data.manageFee);
      }
      if (data.addressList) {
        setAddressList(data.addressList);
      }
      if (data.minSupportNumber) {
        setMinSupportNumber(data.minSupportNumber);
      }
    } catch (error) {
    } finally {
      setInit(false);
    }
  }, []);

  return (
    <>
      <EditArea>
        {chooseType === DaoType.venture && (
          <Intro>
            {t("Create.AddManagerIntro")}
            <span className="font-bold">
              {t("Create.CarriedInterest")}
            </span>{" "}
            {t("Create.AddManagerIntro2")}{" "}
            <span className="font-bold">{t("Create.ManagementFee")}</span>
            {t("Create.AddManagerIntro3")}
          </Intro>
        )}
        {chooseType === DaoType.venture && (
          <FlexRow>
            <Block>
              <InputNumberItem
                required
                width="210px"
                overlayClass="block"
                title={t("Create.GP")}
                onBlur={savePage}
                outSuffix="%"
                onChange={(v) => {
                  (v >= 0 || v === null) && setGpCarry(v);
                }}
                value={GpCarry}
                controls={false}
                precision={2}
                max={100}
                toolTip={t("Hover.Create.CarriedInterest")}
                placeholder={t("Create.CarriedInterestPlaceholder")}
              />
            </Block>
            <Block>
              <InputNumberItem
                required
                width="210px"
                overlayClass="block"
                title={t("Create.Manage")}
                onBlur={savePage}
                onChange={(v) => {
                  (v >= 0 || v === null) && setManageFee(v);
                }}
                value={manageFee}
                outSuffix="%"
                controls={false}
                precision={2}
                max={100}
                toolTip={t("Hover.Create.ManagementFee")}
                placeholder={t("Create.ManagementFeePlaceholder")}
              />
            </Block>
          </FlexRow>
        )}
        <FlexRow>
          <FlexRowItemLeft>
            <GPAddressItem style={{ marginBottom: 0 }}>
              <InputLabel
                className="name-block"
                title={t("Create.ManagerName")}
              />
              <InputLabel
                className="address-block"
                required
                title={t("Create.Address")}
              />
            </GPAddressItem>
            {addressList.map((item, idx) => {
              return (
                <GPAddressItem key={idx}>
                  <div className="name-block">
                    <InputItem
                      placeholder={t("Create.Enter")}
                      value={addressList[idx].name}
                      autoComplete="off"
                      onChange={(e) =>
                        handleSetGpInfo("name", e.target.value, idx)
                      }
                      onBlur={savePage}
                    />
                  </div>
                  <div className="address-block">
                    <InputItem
                      placeholder={t("Create.Enter")}
                      value={addressList[idx].address}
                      autoComplete="off"
                      onChange={(e) =>
                        handleSetGpInfo("address", e.target.value, idx)
                      }
                      onBlur={savePage}
                      disabled={idx === 0}
                    />
                  </div>
                  <div className="bonus-block">
                    {chooseType === DaoType.venture && (
                      <InputNumberItem
                        width="150px"
                        placeholder={t("Create.Bonus")}
                        value={addressList[idx].bonus}
                        controls={false}
                        precision={2}
                        max={100}
                        outSuffix="%"
                        onChange={(v) => handleSetGpInfo("bonus", v, idx)}
                        onBlur={savePage}
                        toolTipStyle={{ top: "-10px" }}
                        toolTip={t("Hover.Create.Bonus")}
                      />
                    )}
                    {!!idx && (
                      <DeleteButton
                        src={DeleteIcon}
                        close={chooseType === DaoType.wallet}
                        onClick={(e) => deleteAddress(e, idx)}
                      />
                    )}
                  </div>
                </GPAddressItem>
              );
            })}
            <GPAddressItem>
              <AddButton onClick={addAddress}>
                {t("Create.AddMore")} +
              </AddButton>
              <CSVReader
                onUploadAccepted={(results) => {
                  handleCSVfile(results.data);
                }}
              >
                {({ getRootProps }) => (
                  <div {...getRootProps()} className="address-block batch">
                    <ChooseBtn>
                      <img src={BatchIcon} alt="" />
                      <span>{t("Create.Batch")}</span>
                    </ChooseBtn>
                  </div>
                )}
              </CSVReader>
            </GPAddressItem>
          </FlexRowItemLeft>
        </FlexRow>
        <FlexRow>
          <VentureVoteConfig
            inputWidth="82px"
            setInvalid={setConfigInvalid}
            minSupportNumber={minSupportNumber}
            updateMinSupportNumber={setMinSupportNumber}
            gpLength={addressList.length}
          />
        </FlexRow>
      </EditArea>
    </>
  );
});

export default AddManager;

const Intro = styled.div`
  width: 100%;
  line-height: 19px;
  margin-bottom: 40px;
  .font-bold {
    font-family: "Poppins-Bold";
  }
`;

const EditArea = styled.div`
  position: relative;
`;
const FlexRow = styled.div`
  display: flex;
  margin-bottom: 28px;
`;

const Block = styled.div`
  &:first-child {
    margin-right: 95px;
  }
`;

const FlexRowItemLeft = styled.div`
  flex: 2;
  .block {
    flex: 1;
    &:last-child {
      margin-left: 50px;
    }
  }
  &.flex {
    display: flex;
  }
`;

const FlexRowItemRight = styled.div`
  flex: 1;
  margin-left: 50px;
`;

const GPAddressItem = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  .name-block {
    width: 122px;
    margin-right: 18px;
  }
  .bonus-block {
    min-height: 44px;
    margin-left: 22px;
    position: relative;
  }
  .address-block {
    width: 413px;
  }
  .batch {
    text-align: left;
    padding-left: 16px;
  }
`;


const ChooseBtn = styled.span`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  opacity: 0.5;
  cursor: pointer;
`;

const DeleteButton = styled.img`
  position: absolute;
  right: ${props=>props.close ? "-20px" : "-70px"};
  top: 10px;
  cursor: pointer;
  width: 24px;
`;