import React, { useState } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import CopySvg from "components/svg/dao/copy";

export default function CopyButton(props) {
  const { value, ...rest } = props;
  const [show, setShow] = useState(false);

  const copyAddress = (e) => {
    e && e.stopPropagation();
    setShow(!show);
    const cInput = document.createElement("input");
    cInput.setAttribute("id", "copyLayer");
    cInput.value = value;
    document.body.appendChild(cInput);
    cInput.select();
    document.execCommand("Copy");

    const thisNode = document.getElementById("copyLayer");
    thisNode.parentNode.removeChild(thisNode);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  return (
    <Tooltip title="Copied" open={show} placement="top" zIndex="999">
      <Tooltip
        title={value}
        trigger={["hover", "click"]}
        placement="top"
        zIndex="999"
      >
        <CopyContent onClick={(e) => copyAddress(e)} {...rest}>
          {props.children || <CopySvg style={ {position: "relative", top: "7px"}} className={props.className} />}
        </CopyContent>
      </Tooltip>
    </Tooltip>
  );
}

const CopyContent = styled.span`
  svg {
    margin-top: 4px;
    cursor: pointer;
  }
`;
