import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg width={36} height={36} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle  fill="#ffffff10" cx={17} cy={17} r={16.5} />
      <path
        d="M17 11a.75.75 0 0 1 .75.75v4.5h4.5a.75.75 0 1 1 0 1.5h-4.501l.001 4.5a.75.75 0 1 1-1.5 0l-.001-4.5H11.75a.75.75 0 1 1 0-1.5h4.5v-4.5A.75.75 0 0 1 17 11Z"
        fill="#0E164E"
      />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
