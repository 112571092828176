import React, { useState } from "react";
import CopyGray from "../assets/images/Copy.svg";
import CopyIcon from "../assets/images/icon-copy.svg";
import styled from "styled-components";
import { Tooltip } from "antd";
import {useTranslation} from "react-i18next";

const IMG = styled.img`
  width: 24px !important;
  height: 24px !important;
  margin-left: 10px !important;
  cursor: pointer;
`;

export default function CopyStr(props) {
  const { address, gray, hideTip } = props;
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const copyAddress = (e) => {
    e.stopPropagation();
    setShow(!show);
    const cInput = document.createElement("input");
    cInput.setAttribute("id", "copyLayer");
    cInput.value = address;
    document.body.appendChild(cInput);
    cInput.select();
    document.execCommand("Copy");

    const thisNode = document.getElementById("copyLayer");
    thisNode.parentNode.removeChild(thisNode);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  return (
    <Tooltip title={t("Copied")} open={show} placement="top" zIndex="999">
      <Tooltip
        title={address}
        trigger={hideTip ? [] : ["hover", "click"]}
        placement="top"
        zIndex="999"
      >
        {gray ? (
          <IMG
            src={CopyGray}
            alt=""
            onClick={(e) => copyAddress(e)}
            className={props.className}
            style={{width: "24px",opacity:"0.5"}}
          />
        ) : (
          <IMG src={CopyIcon} alt="" onClick={(e) => copyAddress(e)} className={props.className} />
        )}
      </Tooltip>
    </Tooltip>
  );
}
