import styled from "styled-components";
import Telegram from "assets/images/Telegram.svg";
import Twitter from "assets/images/Twittter.svg";
import Medium from "assets/images/Medium.svg";
import LogoWhite from "assets/images/DAOlogo.svg";

const Box = styled.div`
    width: 100%;
  background: rgba(255,255,255,0.06);
  border-radius: 16px;
  backdrop-filter: blur(24px);
  padding: 49px 66px;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  dl{
    max-width: 25%;
  }
  dt{
    font-size: 20px;
    font-family: "Poppins-SemiBold";
    color: #FFFFFF;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  .content{
    font-size: 14px;
    opacity: 0.5;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
  }
  .libox{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    div{
      margin-bottom: 10px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
const LogoBox = styled.div`
  width: 25%;
  font-size: 24px;
  font-family: "Rubik-SemiBold";
  display: flex;
  align-items: center;
  img {
    width: 44px;
    margin-right: 10px;
  }
`;
const SocialItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
  img {
    width: 24px;
    margin-right: 10px;
  }
  a {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`;

export default function FooterBox({t}){
    return (
      <Box>
        <LogoBox>
          <img src={LogoWhite} alt="" />
          <span>SubDAO</span>
        </LogoBox>
        <dl>
          <dt>{t("About")}</dt>
          <dd className="content">{t("AboutContent")}</dd>
        </dl>
        <dl>
          <dt>{t("More")}</dt>
          <dd className="libox">
            <div>
              <a
                href="https://wiki.subdao.network/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Wiki
              </a>
            </div>
            <div>
              <a
                href="https://github.com/SubDAO-Network/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </div>
            <div>
              <a
                href="https://github.com/slowmist/Knowledge-Base/blob/master/open-report-V2/smart-contract/SlowMist%20Audit%20Report%20-%20SubDao_en-us.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Smart Contract Audit
              </a>
            </div>
            <div>
              <a
                href="https://etherscan.io/token/0x274e7eb07b485cfde53d02270555213447570ac6"
                target="_blank"
                rel="noopener noreferrer"
              >
                Governance Token
              </a>
            </div>
            <div>
              <a
                href="https://www.coingecko.com/en/coins/subdao"
                target="_blank"
                rel="noopener noreferrer"
              >
                CoinGecko
              </a>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>{t("Contacts")}</dt>
          <dd>
            <SocialItem>
              <img src={Twitter} alt="" />
              <a
                href="https://twitter.com/subdao_network"
                target="_blank"
                rel="noopener noreferrer"
              >
                @subdao_network
              </a>
            </SocialItem>
            <SocialItem>
              <img src={Telegram} alt="" />
              <a
                href="https://t.me/s/subdaoann"
                target="_blank"
                rel="noopener noreferrer"
              >
                @subdao
              </a>
            </SocialItem>
            <SocialItem>
              <img src={Medium} alt="" />
              <a
                href="https://subdao.medium.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @subdao
              </a>
            </SocialItem>
          </dd>
        </dl>
      </Box>
    );
}