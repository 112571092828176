import React, { useEffect, useState, useMemo, useRef } from "react";
import { Input, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

import { useDaoContext } from "views/dao/provider";
import api from "api";
import {
  useDaoOrgContract,
  useErc20StockContract,
  useVentureContract,
} from "hooks/useContract";
import {
  DAO_ORG,
  NATIVE_TOKEN,
  VAULT_MANAGER,
  VENTURE_MANAGER,
} from "utils/constant";

import { useWeb3React } from "@web3-react/core";
import useSigner from "hooks/useSigner";

import { useAppContext } from "components/provider/appProvider";
import { useNavigate } from "react-router-dom";
import useGateway from "hooks/useGateway";
import { getJsonFileByFetch } from "api/apiHTTP";
import { getShortDisplay } from "utils/publicJs";
import { useMutation } from "@apollo/client";
import { AddMyToken } from "api/graphql/explore";
import { RefreshTav } from "api/graphql/dao";
import { useClientContext } from "components/provider/clientProvider";

import showNotification, {
  NotificationType,
  closeNotification,
} from "components/common/notification";
import BaseModal from "../general";
import Button from "components/common/button";

import NftToken from "./nft";
import NFTBoundingCurve from "./nftBondingCurve";
import FixPrice from "./fixPrice";
import BondingCurve from "./bondingCurve";
import {
  TransictionHash,
  DepositFailed,
  DepositSuccess,
  Depositing,
  STATUS,
} from "./status";

export default function Deposit(props) {
  const { show, closeDeposit } = props;

  const navigate = useNavigate();
  const { account, chainId, provider } = useWeb3React();
  const {
    state: { daoId, componentAddressMap, fundToken, stockToken, daoChainId },
    dispatch,
  } = useDaoContext();

  const {
    state: { exploreScan },
  } = useAppContext();

  const daoOrg = useDaoOrgContract(componentAddressMap.get(DAO_ORG));
  const ventureContract = useVentureContract(
    componentAddressMap.get(VENTURE_MANAGER)
  );
  const StcokContract = useErc20StockContract(
    stockToken && stockToken.address,
    daoChainId
  );

  const signer = useSigner();

  const gateway = useGateway();
  const { getClient } = useClientContext();

  const [status, setStatus] = useState(STATUS.Default);
  const [TxHash, setTxHash] = useState();
  const [failReason, setFailReason] = useState("");

  const [fundTokenList, setFundTokenList] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingFund, setLoadingFund] = useState(false);

  const ref = useRef();

  const { t } = useTranslation();

  const fundDisplay = useMemo(() => {
    if (!fundToken || !ref || !ref.current) {
      return {};
    }
    const data = ref.current.data;
    return {
      symbol: fundToken.symbol,
      amount: data.amount,
    };
  }, [fundToken, ref]);

  const showMintToken = useMemo(() => {
    return stockToken?.type === "nft";
  }, [stockToken]);

  const tokenDisplay = useMemo(() => {
    if (!stockToken || !ref || !ref.current) {
      return {};
    }
    const data = ref.current.data
    return {
      symbol: stockToken.symbol,
      amount: data.tokenAmount,
    };
  }, [stockToken, ref]);

  useEffect(() => {
    if (!show) {
      setStatus(STATUS.Default);
      setTxHash();
      // todo clear amount
    }
  }, [show]);

  const [saveAddToken] = useMutation(AddMyToken, {
    onCompleted(data) {
      navigate("/explore?tab=tokens");
    },
    onError(error) {
      navigate("/explore?tab=tokens");
    },
  });

  const checkToken = () => {
    // add token
    saveAddToken({
      variables: {
        chainId,
        daoId: daoId,
        symbol: stockToken.symbol || "",
        address: stockToken.address,
        name: stockToken.name,
      },
    });
  };

  const showStatus = useMemo(() => {
    switch (status) {
      case STATUS.Depositing:
        return <Depositing fund={fundDisplay} token={tokenDisplay} />;
      case STATUS.Success:
        return <DepositSuccess fund={fundDisplay} token={tokenDisplay} />;
      case STATUS.Failed:
        return <DepositFailed failReason={failReason} />;
      default:
        return <></>;
    }
  }, [status, fundDisplay, tokenDisplay, failReason]);

  const canInput = useMemo(() => {
    return status === STATUS.Default || status === STATUS.Failed;
  }, [status]);

  const getErc20Balance = async (address) => {
    const contract = await api.erc20.getContract(provider, address)
    const balance = await contract.balanceOf(account);
    console.log("balance:", balance);
    return {
      balance,
      balanceDisplay: getShortDisplay(
        ethers.utils.formatUnits(balance, fundToken.deci),
        2
      ),
    };
  };

  const getNativeBalance = async () => {
    const balance = await provider.getBalance(account);
    return {
      balance,
      balanceDisplay: getShortDisplay(
        ethers.utils.formatUnits(balance, fundToken.origin.deci),
        2
      ),
    };
  };

  const checkFundList = async () => {
    if (!fundToken) {
      setFundTokenList([]);
    }
    setLoadingFund(true);
    const fundTokenData = {
      name: fundToken.name,
      symbol: fundToken.symbol,
      deci: fundToken.deci,
      logo: fundToken.logo,
    };
    if (fundToken.isWrapped) {
      setFundTokenList([
        {
          ...fundTokenData,
          isNative: true,
          ...(await getNativeBalance()),
        },
        {
          ...fundToken.origin,
          ...(await getErc20Balance(fundToken.address)),
        },
      ]);
    } else {
      setFundTokenList([
        {
          ...fundToken,
          ...(await getErc20Balance(fundToken.address)),
        },
      ]);
    }
    setLoadingFund(false);
  };

  useEffect(() => {
    if (!show) {
      setLoadingFund(false);
      return;
    }
    checkFundList();
  }, [fundToken, show]);

  const getProof = async (code) => {
    try {
      const url = gateway + code;
      const records = await getJsonFileByFetch(url);
      const recodeData = records.find((r) => r.address === account);
      return recodeData ? recodeData.proof : [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const approve = async (token, amount) => {
    const contract = await api.erc20.getContract(provider, token.address);
    
    const allowanceAmount = await contract
      .connect(signer)
      .allowance(account, componentAddressMap.get(VAULT_MANAGER));
    const allowance = Number(
      ethers.utils.formatUnits(allowanceAmount, token.deci)
    );
    console.log("allowanceAmount:", allowance);
    if (!allowance || allowance < amount) {
      const res = await contract
        .connect(signer)
        .approve(
          componentAddressMap.get(VAULT_MANAGER),
          ethers.utils.parseUnits(String(amount), token.deci)
        );
      const txHash = res.hash;
      setTxHash(res.hash);

      const params = { symbol: token.symbol, amount };

      const pid = showNotification(
        NotificationType.Loading,
        t("Notification.Approve", params),
        `${exploreScan}tx/${txHash}`
      );

      await res.wait();

      closeNotification(pid);
      showNotification(
        NotificationType.Success,
        t("Notification.ApproveSuccess", params),
        `${exploreScan}tx/${txHash}`,
        undefined,
        undefined,
        { duration: 8000 }
      );
    }
  };

  const pay = async (token, amount, minAmount, valueAmount, proof, useNative) => {
    const res = await api.venture.mintNew(
      daoOrg,
      signer,
      ethers.utils.parseUnits(String(amount), token.deci),
      ethers.utils.parseUnits(String(minAmount), stockToken.type === "nft" ? 0 : stockToken.deci),
      ethers.utils.parseUnits(String(valueAmount), token.deci),
      proof,
      useNative
    );
    const txHash = res.hash;
    setTxHash(txHash);

    const params = { symbol: token.symbol, amount };

    const pid = showNotification(
      NotificationType.Loading,
      t("Notification.Depositing", params),
      `${exploreScan}tx/${txHash}`
    );

    await res.wait();

    closeNotification(pid);
    showNotification(
      NotificationType.Success,
      t("Notification.DepositSuccess", params),
      `${exploreScan}tx/${txHash}`,
      undefined,
      undefined,
      { duration: 8000 }
    );
  };

  const recordData2backend = async () => {
    getClient(chainId).request(RefreshTav, {
      chainId: chainId,
      daoId,
    });
  };

  const confirm = async () => {
    // 2. check fund target

    setStatus(STATUS.Depositing);
    // 3. proof
    let proof = [];
    const code = await ventureContract.whiteListDataURI();
    if (code) proof = await getProof(code);

    const data = ref.current.data;
    if (!data.useNative) {
      // approve
      try {
        await approve(data.token, data.amount);
      } catch (error) {
        setFailReason("");
        setStatus(STATUS.Failed);
        return;
      }
    }
    // pay
    try {
      await pay(
        data.token,
        data.amount,
        data.minAmount,
        data.amount,
        proof,
        data.useNative
      );
      try {
        await recordData2backend();
      } catch (error) {
        console.error("refresh tav failed", error);
      }
      setStatus(STATUS.Success);
      // update home info
      dispatch({ type: "UPDATE_FUND_OR_PERIOD", payload: "fund" });
    } catch (error) {
      console.error(error);
      setFailReason(error?.message || error?.error?.message || "unknown");
      setStatus(STATUS.Failed);
    }
  };

  const getNewPrice = async () => {
    const data = await StcokContract.estimateExByDemand(
      fundToken.address,
      ethers.utils.parseUnits(
        "1",
        stockToken.type === "nft" ? 0 : stockToken.deci
      )
    );
    const v = ethers.utils.formatUnits(data, fundToken.deci);
    const isDisount = Number(v) !== Number(stockToken.price);
    dispatch({ type: "SET_TOKEN_DISCOUNT", payload: isDisount });
  };

  useEffect(() => {
    if (!fundToken || !StcokContract || !stockToken) return;
    if (stockToken.BuyMode !== 1) {
      return;
    }
    getNewPrice();
  }, [fundToken, StcokContract, stockToken]);


  const getMintContent = () => {
    if (stockToken?.type === "nft") {
      if (stockToken?.BuyMode === 1) {
        return (
          <NFTBoundingCurve
            t={t}
            ref={ref}
            status={status}
            stockToken={stockToken}
            fundToken={fundToken}
            fundTokenList={fundTokenList}
            setBalanceEnough={setSubmitDisabled}
            stockContract={StcokContract}
            loadingFund={loadingFund}
          />
        );
      } else {
        return (
          <NftToken
            t={t}
            ref={ref}
            status={status}
            stockToken={stockToken}
            fundToken={fundToken}
            fundTokenList={fundTokenList}
            setBalanceEnough={setSubmitDisabled}
            loadingFund={loadingFund}
          />
        );
      }
    } else {
      if (stockToken?.BuyMode === 1) {
        return (
          <BondingCurve
            t={t}
            ref={ref}
            status={status}
            stockToken={stockToken}
            fundToken={fundToken}
            fundTokenList={fundTokenList}
            setBalanceEnough={setSubmitDisabled}
            stockContract={StcokContract}
            loadingFund={loadingFund}
          />
        );
      } else {
        return (
          <FixPrice
            t={t}
            ref={ref}
            canInput={canInput}
            stockToken={stockToken}
            fundToken={fundToken}
            fundTokenList={fundTokenList}
            setBalanceEnough={setSubmitDisabled}
            loadingFund={loadingFund}
          />
        );
      }
    }
  };
  return (
    <DepositModal
      show={show}
      closeModal={closeDeposit}
      width={440}
      title={t("Period.DepositModal")}
    >
      <MintContent>
        {getMintContent()}
        <DepositStatus>
          {showStatus}
          {TxHash && <TransictionHash TxHash={TxHash} />}
        </DepositStatus>
      </MintContent>
      {status === STATUS.Default && (
        <Footer>
          <Button
            disabled={submitDisabled}
            primary
            onClick={confirm}
            height={44}
            style={{ width: "100%" }}
          >
            {t("Submit")}
          </Button>
        </Footer>
      )}
      {showMintToken && status === STATUS.Success && (
        <Footer>
          <Button primary onClick={checkToken} style={{ width: "100%" }}>
            {t("CheckMyTokens")}
          </Button>
        </Footer>
      )}
    </DepositModal>
  );
}

const DepositModal = styled(BaseModal)`
  /* min-height: 380px; */
`;

const DepositStatus = styled.div`
  padding: 0 20px 20px;
`;

const Footer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  button {
    border-radius: 8px !important;
  }
`;

const MintContent = styled.div`
  min-height: 300px;
  max-height: 636px;
  overflow: auto;
  box-sizing: border-box;
  @media (max-width: 1440px) {
    max-height: 492px;
  }
`;